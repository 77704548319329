<p>Im Bereich „Einsätze“ können z.B. anstehende Kundentermine oder Serviceeinsätze erfasst und verwaltet werden. Die Konfiguration der Erfassungsmaske erfolgt über den Punkt „Ansichten“ (siehe Kapitel CRM > Ansichten). „Einsätze“ können sowohl von Desktop-Nutzern als auch von Tablet-Nutzern angelegt und bearbeitet werden.</p>
<h4>Neuen Kundenkontakt erstellen</h4>  
<ol class="list">
    <li>Um einen neuen Einsatz anzulegen, klicken Sie im Menu auf „CRM“ und wählen den Punkt „Ansprechpartner“ aus. Dann klicken Sie auf den Button „Ansprechpartner hinzufügen“.</li>
    <li>Es öffnet sich ein Eingabefenster.</li>
</ol>
<h4>Kundenkontakt bearbeiten</h4>  
<ol class="list">
    <li>Um einen Einsatz zu bearbeiten, wählen Sie diesen in der Liste aus. Alternativ können Sie über Aktionen, die Bearbeitung auswählen.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>