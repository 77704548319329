import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-push-create',
  templateUrl: './de-push-create.component.html'
})
export class DePushCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
