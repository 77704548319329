import { Component, OnInit } from '@angular/core';
import { classToClass, plainToClass } from 'class-transformer';
import { ThemeMapping } from 'src/app/branding/theme-mapping';
import { HelperService } from '../helper/helper.service';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { SettingService } from '../settings/setting.service';
import { BrandingSetting } from './branding-setting.model';
import { Branding } from './branding.model';
import { Theme } from './theme.model';
import { BrandingService } from './branding.service';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnInit {
  theme = new Theme();
  resetTheme = new Theme();
  branding = new Branding();
  themeMapping = ThemeMapping;
  expanded = true;
  activePage = [true, false, false, false, false, false];
  highlighted: string;
  loading = false;
  saving = false;

  open = [
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]

  constructor(public serverService: ServerService, private helper: HelperService, public settingService: SettingService,
    public localization: LocalizationService, public brandingService: BrandingService) {
  }

  ngOnInit(): void {
    this.loadSettings();
  }

  async loadSettings(name = 'Standard') {
    let res = await this.serverService.get('branding/getDefault');
    this.branding = plainToClass(Branding, res);
    let theme = {};
    for (let setting of this.branding.settings) {
      theme[setting.selector] = setting.value;
    }
    this.theme = plainToClass(Theme, theme);
    this.resetTheme = plainToClass(Theme, theme)
  }
  
  
  async uploadImage(setting: { selector: string, preview: number[]}) {
    let imgType: 'background' | 'logo' | 'loginLogo' | 'product' = 'loginLogo';
    if (setting.selector == '--amsel-background-image' ) {
      imgType = 'background' ;
    } else if (setting.selector == '--amsel-header-logo-bg') {
      imgType = 'logo';
    }
    const base64 = 'url(' + await this.helper.compressFile(imgType) + ')';
    this.theme[setting.selector] = base64;
    this.previewChange(setting.preview);
    }
    

/* 
  async imageChange(files: FileList, setting: { selector: string, preview: number[] }) {
    if (files) {
      const base64 = 'url(' + await this.helper.getBase64(files.item(0)) + ')';
      this.theme[setting.selector] = base64;
    }
    this.previewChange(setting.preview)
  } */

  previewChange(preview: number[]) {
    const index = this.activePage.findIndex(value => value)
    if(!preview.includes(index)) {
      this.activePage = [false, false, false, false, false, false];
      this.activePage[preview[0]] = true
    }
  }

  async saveTheme(name = 'Standard') {
    this.loading = true;
    this.saving = true;
    /* for (let i = 0; i < this.branding.settings.length; i++) {
      if (this.branding.settings[i].type === 'blur') {
        this.branding.settings[i].value = this.theme[this.branding.settings[i].selector] + ' 0% 0% no-repeat padding-box';
      } else {
        this.branding.settings[i].value = this.theme[this.branding.settings[i].selector];
      }
    } */
    for (let [sel, value] of Object.entries(this.theme)) {
      let setting = this.branding.settings.find((setting) =>
        setting.selector === sel
      )
      if (setting) {
        if (setting.type === 'blur') {
          setting.value = value + ' 0% 0% no-repeat padding-box';
        } else {
          setting.value = value;
        }
      } else {
        let newSetting = new BrandingSetting();
        newSetting.selector = sel;
        newSetting.value = value;
        newSetting.type = 'color';
        this.branding.settings.push(newSetting);
      }
    }
    this.branding.version++;
    await this.serverService.put('branding', this.branding);
    await this.settingService.update();
    this.saving = false;
    this.loading = false;
  }

  reset() {
    this.theme = classToClass(this.resetTheme)
  }

  jumpTo(event: { selector: string, page: number }) {
    this.open[event.page] = true;
    setTimeout(() => {
      const inputs: HTMLElement[] = []
      inputs.push(document.getElementById(event.selector));
      if(event.selector == '--clr-btn-primary-color') {
        inputs.push(document.getElementById('--clr-btn-primary-bg-color'));
        inputs.push(document.getElementById('--clr-btn-primary-border-color'));
        inputs.push(document.getElementById('--clr-btn-primary-hover-bg-color'));
      }
      if(event.selector == '--clr-btn-default-outline-color') {
        inputs.push(document.getElementById('--clr-btn-default-outline-border-color'));
        inputs.push(document.getElementById('--clr-btn-default-outline-hover-bg-color'));
        inputs.push(document.getElementById('--clr-btn-default-outline-hover-color'));
      }
      if(event.selector == '--clr-vertical-nav-bg-color') {
        inputs.push(document.getElementById('--clr-vertical-nav-hover-bg-color'));
      }
      if(event.selector == '--amsel-background-image') {
        inputs.push(document.getElementById('--amsel-background-color'));
      }
      if(event.selector == 'table'){
        inputs.pop();
        inputs.push(document.getElementById('--clr-vertical-nav-bg-color'));
        inputs.push(document.getElementById('--clr-vertical-nav-active-bg-color'));
      }
      if(event.selector == '--clr-header-hover-font-color') {
        inputs.push(document.getElementById('--clr-header-font-color'));
      }


      for (let input of inputs) {
        if (input) {
          input.scrollIntoView({ behavior: 'smooth', block: 'center' });
          if (JSON.parse(localStorage.getItem('theme')).name == 'Dark') {
            input.classList.add('highlight-dark');
          } else {
            input.classList.add('highlight-light');
          }
          setTimeout(() => {
            input.classList.remove('highlight-dark');
            input.classList.remove('highlight-light');
          }, 1000)
        }
      }
    })
  }

}
