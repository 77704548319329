<div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutAlign.lt-md="space-between space-between"
    fxLayoutAlign.gt-sm="space-between flex-start" *ngIf="!loading">
    <div class="card" fxFlex.gt-sm="55">
        <div class="card-header">
            {{ helper.getCardHeader(mode, 'field', field.label) }}
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                    <p class="card-text icon-offset">
                      {{localization.dictionary.fieldCreateEdit.infoViewCreation}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                    (click)="collapse[0] = !collapse[0]"></clr-icon>
            </div>
            <div [hidden]="collapse[0]" class="card-text">
                <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset" autocomplete="off">
                    <clr-input-container>
                        <label>{{localization.dictionary.field.label + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="field.label" maxlength="80"
                            (ngModelChange)="calcName()" name="label" required (blur)="calcName()"/>
                            <clr-control-helper class="label-error " *ngIf="duplicate && !editName">
                              {{localization.dictionary.fieldCreateEdit.nameForFieldExistsAndEdit}}
                            </clr-control-helper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{localization.dictionary.general.name + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="field.name" duplicate name="name" maxlength="250" autocomplete="nopeName"
                            [disabled]="!editName || !canBeDeleted" pattern="^u_([a-z0-9]|_)+" required/>
                        <clr-control-error *clrIfError="'duplicate'">
                          {{localization.dictionary.fieldCreateEdit.nameForFieldExists}}
                        </clr-control-error>
                        <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.fieldCreateEdit.mustStartWithU}} </clr-control-error>
                    </clr-input-container>

                    <div class="clr-form-control clr-row">
                        <div class="clr-control-container clr-control-label clr-col-12 clr-col-md-2"></div>
                        <button class="btn btn-sm btn-outline" style="margin-left: 0.6rem;"
                            (click)="editName = !editName" [disabled]="!field.label || field.label.length == 0 || field.name == 'u_date' || field.name == 'u_email'">
                            {{ (editName) ? localization.dictionary.fieldCreateEdit.automatic : localization.dictionary.button.edit }}
                        </button>
                    </div>

                    <clr-checkbox-container>
                        <label>{{localization.dictionary.fieldCreateEdit.usedFor + '*'}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="customer" value="customer" [required]="!field.appliesToContact && !field.appliesToCall"
                                [(ngModel)]="field.appliesToCustomer" [disabled]="field.name == 'u_date' || field.name == 'u_email'"/>
                            <label>{{localization.dictionary.customer.nameSingular}}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="contact" value="contact" [required]="!field.appliesToCustomer && !field.appliesToCall"
                                [(ngModel)]="field.appliesToContact" [disabled]="field.name == 'u_date' || field.name == 'u_email'"/>
                            <label>{{localization.dictionary.contact.name}}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="call" value="call" [required]="!field.appliesToCustomer && !field.appliesToContact"
                                [(ngModel)]="field.appliesToCall" [disabled]="field.name == 'u_date' || field.name == 'u_email'"/>
                            <label>{{localization.dictionary.call.name}}</label>
                        </clr-checkbox-wrapper>
                        <clr-control-helper>{{localization.dictionary.fieldCreateEdit.selectTablesForField}}</clr-control-helper>
                        <clr-control-error>{{localization.dictionary.fieldCreateEdit.requiredTableForField}}</clr-control-error>
                    </clr-checkbox-container>
                    <clr-select-container>
                        <label>{{localization.dictionary.type.nameSingular}}</label>
                        <select clrSelect name="type" [(ngModel)]="field.type" (ngModelChange)="resetPattern()" required
                            [disabled]="['DATE','TIME', 'BOOLEAN', 'NUMBER'].includes(fieldType) || field.name == 'u_date' || field.name == 'u_email' && mode == 'edit'">
                            <option *ngIf="mode === 'create' || ['TEXTAREA','TEXT'].includes(fieldType)" value="TEXT">
                              {{localization.dictionary.fieldType.TEXT}}
                            </option>
                            <option *ngIf="mode === 'create' || ['TEXTAREA','TEXT'].includes(fieldType)"
                                value="TEXTAREA">
                                {{localization.dictionary.fieldType.TEXTAREA}}</option>
                            <option *ngIf="mode === 'create' || fieldType =='NUMBER' " value="NUMBER">{{localization.dictionary.fieldType.NUMBER}}</option>
                            <option *ngIf="mode === 'create' || ['CHOICE','SELECTION', 'MULTIPLE_CHOICE','CHECKBOX'].includes(fieldType)"
                                value="CHECKBOX">{{localization.dictionary.fieldType.CHECKBOX}}</option>
                            <option
                                *ngIf="mode === 'create' || ['CHOICE','SELECTION','MULTIPLE_CHOICE','CHECKBOX' ].includes(fieldType)"
                                value="SELECTION">{{localization.dictionary.fieldType.SELECTION}}</option>
                            <option
                                *ngIf="mode === 'create' || ['CHOICE','SELECTION', 'MULTIPLE_CHOICE','CHECKBOX'].includes(fieldType)"
                                value="CHOICE">{{localization.dictionary.fieldType.CHOICE}}</option>
                            <option *ngIf="mode === 'create' || ['CHOICE','SELECTION', 'MULTIPLE_CHOICE','CHECKBOX'].includes(fieldType)"
                                value="MULTIPLE_CHOICE">{{localization.dictionary.fieldType.MULTIPLE_CHOICE}}</option>
                            <option *ngIf="mode === 'create' || fieldType == 'DATE'"  value="DATE">{{localization.dictionary.fieldType.DATE}}</option>
                            <option *ngIf="mode === 'create' || fieldType == 'BOOLEAN'" value="BOOLEAN" >{{localization.dictionary.fieldType.BOOLEAN}}</option>
                            <option *ngIf="mode === 'create' || fieldType == 'TIME'" value="TIME">{{localization.dictionary.fieldType.TIME}}</option>

                        </select>
                    </clr-select-container>

                    <div *ngIf="field.type === 'CHOICE' || field.type === 'MULTIPLE_CHOICE' || field.type === 'SELECTION' || field.type === 'CHECKBOX'"
                        cdkDropList (cdkDropListDropped)="drop($event)" cdkScrollable>
                        <div cdkDragData="fieldOption" *ngFor="let fieldOption of field.fieldOptions; let i = index"
                            style="position: relative;" cdkDrag>
                            <div class="cdk-placeholder" *cdkDragPlaceholder style="max-width: 37%;"></div>
                            <div *cdkDragPreview style="width: 15%; display: flex; justify-content: space-around;">

                              {{localization.dictionary.fieldCreateEdit.answer}} {{i + 1}}
                                <!-- <clr-icon shape="cursor-move" style="margin-left: 1rem;" cdkDragHandle></clr-icon>
                                <clr-icon *ngIf="field.fieldOptions.length > 1"
                                    style="margin-left: 1rem; margin-right: 1rem;" shape="trash" (click)="delete(i)">
                                </clr-icon> -->
                                <div>{{fieldOption.label}}</div>

                            </div>
                            <div>
                                <clr-input-container>
                                    <label style="display: flex; justify-content: flex-start; padding-left: 2vw;">
                                        <div style="display: flex; flex-wrap: wrap">
                                            <div style="white-space: nowrap; margin-right: 1rem; min-width: 5vw;">
                                              {{localization.dictionary.fieldCreateEdit.answer}} {{ i + 1 }}
                                            </div>
                                            <span style="display: flex; flex-wrap: nowrap ">
                                                <clr-icon shape="cursor-move" cdkDragHandle>
                                                </clr-icon>
                                                <div *ngIf="field.fieldOptions?.length == 1"
                                                    style="height: 16px; width: 16px">
                                                </div>
                                                <clr-icon *ngIf="field.fieldOptions.length > 1"
                                                    style="margin-left: 1rem;" shape="trash"
                                                    (click)="delete(i)"></clr-icon>
                                            </span>
                                        </div>
                                    </label>
                                    <input [(ngModel)]="field.fieldOptions[i].label" required
                                        (ngModelChange)="fieldOptionChanged($event, i, field.fieldOptions)" clrInput
                                        type="text" [name]="'answer' + field.fieldOptions[i].id" maxlength="100">
                                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                </clr-input-container>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="field.type === 'CHOICE' || field.type === 'MULTIPLE_CHOICE' || field.type === 'SELECTION' || field.type === 'CHECKBOX'"
                        class="clr-form-control clr-row">
                        <div class="clr-control-container clr-control-label clr-col-12 clr-col-md-2"></div>
                        <button class="btn btn-sm btn-outline" style="margin-left: 0.6rem;" (click)="createAnswers(field.fieldOptions ? field.
                        fieldOptions?.length : 0)">
                        {{localization.dictionary.fieldCreateEdit.addAnswer}}
                        </button>
                    </div>

                    <!-- <clr-input-container>
                    <label>Pattern {{ validRegEx }}</label>
                    <input clrInput type="text" [(ngModel)]="field.pattern" (ngModelChange)="checkRegEx(field.pattern)" name="pattern" required />
                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                </clr-input-container> -->
                    <clr-select-container *ngIf="field.type === 'TEXT' || field.type === 'TEXTAREA'">
                        <label>
                          {{localization.dictionary.formCreateEdit.check}}
                        </label>

                        <select clrSelect name="pattern" [(ngModel)]="tempRegEx" (ngModelChange)="setPattern()">
                            <option [value]="'NONE'">{{localization.dictionary.pattern.NONE}}</option>
                            <option [value]="'NAME'">{{localization.dictionary.pattern.NAME}}</option>
                            <option [value]="'MAIL'">{{localization.dictionary.pattern.MAIL}}</option>
                            <option [value]="'POSTALCODE'">{{localization.dictionary.pattern.POSTALCODE}}</option>
                            <option [value]="'PHONENUMBER'">{{localization.dictionary.pattern.PHONENUMBER}}</option>
                            <option [value]="'CUSTOM'">{{localization.dictionary.pattern.CUSTOM}}</option>
                        </select>
                        <clr-control-helper>

                            <ng-container [ngSwitch]="tempRegEx">

                                <span *ngSwitchCase="'NONE'"> {{localization.dictionary.fieldCreateEdit.checkNotFound}} </span>
                                <span *ngSwitchCase="'NAME'">
                                  {{localization.dictionary.fieldCreateEdit.checksIfItsValidName}}
                                </span>
                                <span *ngSwitchCase="'MAIL'">
                                  {{localization.dictionary.fieldCreateEdit.checkMail}}
                                </span>
                                <span *ngSwitchCase="'POSTALCODE'">
                                  {{localization.dictionary.fieldCreateEdit.checkZip}}
                                </span>
                                <span *ngSwitchCase="'PHONENUMBER'">
                                  {{localization.dictionary.fieldCreateEdit.checkPhone}}
                                </span>
                                <span *ngSwitchDefault> {{localization.dictionary.fieldCreateEdit.customRegularExpression}} </span>
                            </ng-container>
                        </clr-control-helper>
                        <!--<clr-control-error>This field is required!</clr-control-error>-->
                    </clr-select-container>
                    <clr-input-container *ngIf="tempRegEx == 'CUSTOM'" >
                        <input clrInput type="text" [(ngModel)]="field.pattern"
                            name="customRegEx" regexValidator [required]="tempRegEx == 'CUSTOM'" maxlength="100" />
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error *clrIfError="'wrongRegex'">{{localization.dictionary.fieldCreateEdit.inputNoValidRegExPattern}}
                        </clr-control-error>
                    </clr-input-container>

                    <clr-toggle-container>
                        <label>{{localization.dictionary.general.active}}</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="active" [(ngModel)]="field.isActive" [disabled]="!canBeDeleted()"/>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-toggle-container>
                        <label>{{localization.dictionary.fieldCreateEdit.trackChanges}}</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="trackChanges" [(ngModel)]="field.trackChanges" />
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-toggle-container>
                        <label>{{localization.dictionary.fieldCreateEdit.displayValue}}</label>
                        <clr-toggle-wrapper>
                            <input [disabled]="!toggleDV || ['BOOLEAN', 'DATE', 'TIME'].includes(field.type)"  type="checkbox" clrToggle name="display"
                                [(ngModel)]="field.display" />
                        </clr-toggle-wrapper>
                        <clr-control-helper *ngIf="!toggleDV && !field.display">
                          {{localization.dictionary.fieldCreateEdit.fieldNotReadOnlyForDisplayValue1}} <br> {{localization.dictionary.fieldCreateEdit.fieldNotReadOnlyForDisplayValue2}}
                        </clr-control-helper>
                        <clr-control-helper *ngIf="!toggleDV && field.display">
                          {{localization.dictionary.fieldCreateEdit.FieldUsedInViewsAsDisplayValue}}
                        </clr-control-helper>
                    </clr-toggle-container>



                </form>
            </div>
        </div>
        <div stickyFooter class="card-footer">
            <button class="btn btn-primary" (click)="formValid(formData)">
                {{ ( mode === 'create' ) ? localization.dictionary.button.create : localization.dictionary.button.save }}</button>
            <!---->
            <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
        </div>
    </div>


    <div fxFlex.gt-sm="40" class="field" fxLayout="column" fxLayoutAlign="space-between">
        <div class="card" fxFlexAlign="start">
            <div class="card-header">{{localization.dictionary.fieldCreateEdit.usedView}} </div>
            <div *ngIf="fieldViews.length < 1">
                <clr-icon class="alert-icon" shape="exclamation-circle" style="margin:3%;"></clr-icon>
                <span class="alert-text">{{localization.dictionary.fieldCreateEdit.FieldNotUsedInView}}</span>
            </div>
            <div class="card-block" *ngFor="let view of this.fieldViews">
                <clr-tree>
                    <clr-tree-node [clrExpanded]="false">
                        {{view.name}}
                        <div [pTooltip]="localization.dictionary.fieldCreateEdit.openViewInNewTab" 
                            [tooltipPosition]="'bottom'" style="margin-left: 1rem;">
                            <a 
                                [routerLink]="'/crm/view/edit/' + view.id" 
                                target="_blank">
                                <clr-icon shape="pop-out" style="margin-right: 0;"></clr-icon>
                            </a>
                        </div>

                        <clr-tree-node *ngFor="let section of view.sections">
                            <clr-icon shape="note"></clr-icon>
                            {{section.name}}
                        </clr-tree-node>
                    </clr-tree-node>
                </clr-tree>
            </div>

        </div>
        <div class="card" fxFlexAlign="end">
            <div class="card-header"> {{localization.dictionary.form.name}}</div>
            <div *ngIf="fieldForm.length < 1">
                <clr-icon class="alert-icon" shape="exclamation-circle" style="margin:3%;"></clr-icon>
                <span class="alert-text">{{localization.dictionary.fieldCreateEdit.FieldNotUsedInForm}}</span>
            </div>
            <div class="card-block" *ngFor="let fieldFormOne of this.fieldForm">
                <clr-tree>
                    <clr-tree-node [clrExpanded]="false">
                        {{fieldFormOne.name}}
                        <div [pTooltip]="localization.dictionary.fieldCreateEdit.openFormInNewTab" 
                            [tooltipPosition]="'bottom'" style="margin-left: 1rem;">
                            <a 
                                [routerLink]="'/forms/edit/' + fieldFormOne.id" 
                                target="_blank">
                                <clr-icon shape="pop-out" style="margin-right: 0;"></clr-icon>
                            </a>
                        </div>

                        <clr-tree-node *ngFor="let doc of fieldFormOne.documents">
                            <clr-icon shape="file"></clr-icon>
                            {{doc.name}}
                        </clr-tree-node>
                    </clr-tree-node>
                </clr-tree>
            </div>
        </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="openViewModal" [clrModalSize]="'xl'">
    <h3 class="modal-title">
      {{localization.dictionary.fieldCreateEdit.FieldUsedInMinOneView}}
    </h3>
    <div class="modal-body">
      {{localization.dictionary.fieldCreateEdit.deleteFieldFromViewToSave}}
        <br>
        <li *ngFor="let view of viewList">
            {{ view.name }}
        </li>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeViewModal()">{{localization.dictionary.button.ok}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="sureToEdit" [clrModalSize]="'xl'">
    <h3 class="modal-title">
      {{localization.dictionary.fieldCreateEdit.reallySaveChanges}}
    </h3>
    <div class="modal-body">
        <div *ngIf="publishedForms.length < 1">
            {{ unpublishedForms.length > 1 ? localization.dictionary.fieldCreateEdit.FieldUsedInSeveralFormsPL.replace('${number}', unpublishedForms.length.toString()) : localization.dictionary.fieldCreateEdit.FieldUsedInSeveralFormsSG}}
          <!-- {{localization.dictionary.fieldCreateEdit.FieldUsedInServeralForms1}} {{ unpublishedForms.length }} {{localization.dictionary.form.nameSingular}}{{ unpublishedForms.length > 1 ? localization.dictionary.fieldCreateEdit.plural :
            ''}} {{localization.dictionary.fieldCreateEdit.FieldUsedInServeralForms2}}.

            {{localization.dictionary.fieldCreateEdit.since}} {{ unpublishedForms.length > 1 ? localization.dictionary.form.accusative : localization.dictionary.fieldCreateEdit.theForms }} {{localization.dictionary.fieldCreateEdit.notPublishedYet}} {{
            unpublishedForms.length > 1 ? localization.dictionary.fieldCreateEdit.are : localization.dictionary.fieldCreateEdit.is}}, <br>
            {{localization.dictionary.fieldCreateEdit.theFieldWillBe}} {{ unpublishedForms.length > 1 ? localization.dictionary.fieldCreateEdit.dativPluralForm : localization.dictionary.form.dative }} {{localization.dictionary.fieldCreateEdit.updated}}. -->
        </div>
        <div *ngIf="publishedForms.length > 0">
          {{localization.dictionary.fieldCreateEdit.FieldUsedInOnePublishedForm1}} <br>
          {{localization.dictionary.fieldCreateEdit.FieldUsedInOnePublishedForm2}}<br>
          {{localization.dictionary.fieldCreateEdit.FieldUsedInOnePublishedForm3}} <br>
          {{localization.dictionary.fieldCreateEdit.FieldUsedInOnePublishedForm4}} <br>
          {{localization.dictionary.fieldCreateEdit.FieldUsedInOnePublishedForm5}} <br>
          {{localization.dictionary.fieldCreateEdit.FieldUsedInOnePublishedForm6}}
            <br>
            <div *ngFor="let form of publishedForms; let i = index;"
                style="display: flex; justify-content: space-between; width: 30vw;">
                <div style="display: flex;">
                    <input type="checkbox" clrCheckbox [(ngModel)]="form.createNew">
                    <div style="margin-left: 1vw;">{{localization.dictionary.form.nameSingular}} {{i +1}}</div>
                </div>
                <div>{{form.name}}</div>
            </div>
        </div>
    </div>
    <div class="modal-footer">

        <!-- <button type="button" class="btn btn-outline" [disabled]="createNewForm && recreateForm.length < 1"
            (click)="!createNewForm && forms.length < 2 ? getForms(): createForm()">{{ recreateForm.length
            < 1 ? 'Formular' : 'Formulare' }} erstellen</button>
                <button *ngIf="!createNewForm" type="button" class="btn btn-primary" (click)="deleteConnection()"> Nur
                    Verlinkung entfernen</button> -->
        <button type="button" class="btn btn-primary" (click)="saveChanges()">{{localization.dictionary.button.save}}</button>
        <button type="button" class="btn btn-outline" (click)="unsureToEdit()">{{localization.dictionary.button.cancel}}</button>

    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="selectViews" [clrModalSize]="'xl'">
    <h3 class="modal-title">
      {{ localization.dictionary.fieldCreateEdit.addToView }}
    </h3>
    <div class="modal-body">
        <div>{{ localization.dictionary.fieldCreateEdit.selectView }}</div>
        <div>{{ localization.dictionary.fieldCreateEdit.openViewEditor }}</div>
        <br>
        <clr-radio-wrapper *ngFor="let view of views; let i = index" >
            <input type="radio" [ngModel] (ngModelChange)="selectedView = view" clrRadio [value]="'radio' + i" name="view">
            <label >{{ view.name }}</label>
        </clr-radio-wrapper>
    </div>
    <div class="modal-footer">
        <button [disabled]="!selectedView" type="button" class="btn btn-primary" (click)="addField(false)">{{ localization.dictionary.fieldCreateEdit.attachField }}</button>
        <button [disabled]="!selectedView" type="button" class="btn btn-outline" (click)="addField(true)">{{ localization.dictionary.fieldCreateEdit.attachEdit }}</button>
        <button type="button" class="btn btn-warning-outline" (click)="selectViews = false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>
