import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DoCheck, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../app/auth/auth.service';
import { BrandingService } from '../../../../app/branding/branding.service';
import { Document } from '../../../../app/documents/document.model';
import { HelperService } from '../../../../app/helper/helper.service';
import { ListSelectorComponent } from '../../../../app/helper/list-selector/list-selector.component';
import { ServerService } from '../../../../app/server.service';
import { User } from '../../../../app/users/user.model';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { PreventUnload } from '../../../helper/guards/unsaved.guard';
import { LocalizationService } from '../../../localization/localization.service';
import { Conflict } from '../../conflict/conflict.model';
import { Contact } from '../../contacts/contact.model';
import { CrmService } from '../../crm.service';
import { Customer } from '../../customers/customer.model';
import { FieldValue } from '../../fields/field-value.model';
import { Field } from '../../fields/field.model';
import { Product } from '../../products/product.model';
import { View } from '../../views/view.model';
import { Call } from '../call.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-create-edit-call',
  templateUrl: './create-edit-call.component.html',
  styleUrls: ['./create-edit-call.component.css']
})
export class CreateEditCallComponent extends PreventUnload implements OnInit, DoCheck, OnDestroy {
  private forms: ClrForm[]
  @ViewChildren(ClrForm) set contentForms(content: QueryList<ClrForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.forms || this.forms.length == 0)
        this.forms = content.toArray();
      else
        this.forms.push(...content.toArray());
    } else {
      if (!this.forms || this.forms.length == 0)
        this.forms = [];
    }
  }
  private formDatas: NgForm[];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = content.toArray();
      else
        this.formDatas.push(...content.toArray());
    } else {
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = [];
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  call: Call;
  callIds: string[];
  mode: 'edit' | 'create' | 'conflict';
  comboLoading = true;
  loading = true;
  customer: Customer;
  customers: Customer[] = [];
  user: User;
  users: User[];
  product: Product;
  products: Product[] = [];
  views: View[];
  activeView: View;
  firstTabInvalid = false;
  ownUserId: string;
  ownUser: string;
  errTemp = {};
  invalid = false;
  editable = true;
  date: string;
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
  name: string = null;
  collapse = {};
  selectedCustomer: Customer;
  selectedCustomerList: Customer[];
  initialContact: Contact[] = [];
  initialDocument: Document[] = [];
  contactIds: string[];
  callIdHelper: string;
  fileUploadActive = false;
  auditActive = false;

  callRequired = false;
  // conflict
  conflict: Conflict;
  conflictId: string;
  conflictCall: Call;
  conflicts = {};
  conflictsSearched = false;
  conflictHandled = false;
  conflictAccepted = false;
  customerConflict: { value: Customer, user: User, date: Date };
  contactConflict: { value: Contact[], user: User, date: Date };
  documentConflict: { value: Document[], user: User, date: Date };
  productConflict: { value: Product[], user: User, date: Date };
  userConflict: { value: User[], user: User, date: Date };
  //
  tempCustomer: Customer;
  tempProducts: Product[];
  tempUsers: User[];

  customerQueryChanged: Subject<string> = new Subject<string>();

  @ViewChild('callForm') callForm: any;
  @ViewChild('documentList') documentList: ListSelectorComponent;


  constructor(public server: ServerService,
    public auth: AuthService,
    private route: ActivatedRoute,
    public router: Router,
    public crm: CrmService,
    public helper: HelperService,
    public branding: BrandingService,
    public localization: LocalizationService,
    private changeRef: ChangeDetectorRef) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        if (params.id) {
          this.mode = 'edit';
          this.callIdHelper = params.id;
        } else if (params.conflictId) {
          this.mode = 'conflict';
          this.conflictId = params.conflictId;
        } else {
          this.mode = 'create'
        }
        if (this.mode === 'edit') {
          this.call = plainToClass(Call, await this.server.get('crm/call/byId/' + params.id));
          this.name = this.crm.getDisplayValue(this.call);
          /* this.date = this.call.startTime.toLocaleDateString('de-DE', { day: 'numeric', month: '2-digit', year: 'numeric' });;
          this.startTime = this.call.startTime?.getHours() +  ':' + this.call.startTime?.getMinutes();
          this.startDateTime = this.call.startTime;
          this.endTime = this.call.endTime?.getHours() +  ':' + this.call.endTime?.getMinutes();
          this.endDateTime = this.call.endTime; */
        }
        if (this.mode === 'conflict') {
          this.conflict = await this.server.get('crm/conflict/byId/' + params.conflictId) as any;
          this.call = plainToClass(Call, this.conflict.call)
          this.conflictCall = plainToClass(Call, JSON.parse(this.conflict.json));
        }
        else if (this.mode === 'create') {
          this.call = new Call();
          this.call.users = [auth.user]
          this.dirty = true;
          // Setze customer
          if (params.customerId) {
            const customer = plainToClass(Customer, await this.server.get('crm/customer/byId/' + params.customerId));
            if (customer) {
              await this.customerChanged([customer]);
            }
            if (params.contactId) {
              const contact = plainToClass(Contact, await this.server.get('crm/contact/byId/' + params.contactId));
              this.call.contacts = [contact];
            }
          }
        }
        this.initialContact = [...this.call.contacts];
        this.initialDocument = [...this.call.documents];

        this.contactIds = this.call.contacts.map(contact => contact.id)
        this.loading = false;

        if (this.call.customer) {
          this.selectedCustomer = plainToClass(Customer, await this.server.get('crm/customer/byId/' + this.call.customer.id));
          this.selectedCustomerList = [this.selectedCustomer]
        }
        if (this.call.products) {
          this.call.products = plainToClass(Product, (await this.server.get('crm/product?id={"type":"multiOr","value":' + JSON.stringify(this.call.products.map(product => product.id)) + "}"))?.rows);
        }
        this.changeUnload();
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );

    this.subscriptions.add(
      this.customerQueryChanged.pipe(debounceTime(500))
      .subscribe(async (event) => {
        this.customers = [];
        await this.getCustomers(event);
      })
    )
  }



  async ngOnInit(): Promise<void> {
    await this.crm.setActiveViewsFromStorage(['call']);
    this.views = this.crm.views.call;
    this.activeView = this.crm.activeViews.call;

    for (let section of this.activeView.sections) {
      this.collapse[section.id] = false;
    }
    this.collapse['customer'] = false;
    this.collapse['products'] = false;
    this.collapse['users'] = false;
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('call');
    } else {
      this.changeUnloadEdit('call', this.crm.getDisplayValue(this.call));
    }
  }

  async activeViewChanged(view: View){
    this.activeView = view;
    this.crm.activeViews.call = view;
  }

  ngOnDestroy() {
    localStorage.setItem('call-active-view', JSON.stringify(this.activeView));
  }



  ngDoCheck() {
    if (!this.conflictsSearched && this.mode == 'conflict' && this.crm.fields.call && this.call && this.conflictCall) {
      for (let key of this.crm.fields.call.map((field) => field.name)) {
        let conflictFound = false;
        if (this.call[key] && this.conflictCall[key]) {
          if (this.call[key].length !== this.conflictCall[key].length) {
            conflictFound = true;
          } else if (this.call[key].length == 1 && this.call[key][0].value !== this.conflictCall[key][0].value) {
            conflictFound = true;
          } else {
            for (let fieldValue of this.call[key]) {
              if (!this.conflictCall[key].find(conValue => conValue.fieldOptionId == fieldValue.fieldOptionId)) {
                conflictFound = true;
              }
            }
          }
        } else if (!this.call[key] && this.conflictCall[key]) {
          conflictFound = true;
        }
        if (conflictFound) {
          this.conflicts[key] = { value: this.conflictCall[key], user: this.conflict.user, date: this.conflict.date };
        }
      }
      let contactConflictFound = false;
      if (this.call.contacts && this.conflictCall.contacts) {
        if (this.call.contacts.length !== this.conflictCall.contacts.length) {
          contactConflictFound = true;
        } else {
          for (let contact of this.call.contacts) {
            if (!this.conflictCall.contacts.find(conValue => conValue.id == contact.id)) {
              contactConflictFound = true;
            }
          }
        }
      } else if (!this.call.contacts && this.conflictCall.contacts) {
        contactConflictFound = true;
      }
      if (contactConflictFound) {
        this.contactConflict = { value: this.conflictCall.contacts, user: this.conflict.user, date: this.conflict.date };
      }
      if (this.conflictCall.customer.id != this.call.customer.id) {
        this.customerConflict = { value: this.conflictCall.customer, user: this.conflict.user, date: this.conflict.date };
      }
      /*  this.conflictsSearched = true; */

      let docuConflictFound = false;
      if (this.call.documents && this.conflictCall.documents) {
        if (this.call.documents.length !== this.conflictCall.documents.length) {
          docuConflictFound = true;
        } else {
          for (let document of this.call.documents) {
            if (!this.conflictCall.documents.find(docValue => docValue.id == document.id)) {
              docuConflictFound = true;
            }
          }
        }
      } else if (!this.call.documents && this.conflictCall.documents) {
        docuConflictFound = true;
      }
      if (docuConflictFound) {
        this.documentConflict = { value: this.conflictCall.documents, user: this.conflict.user, date: this.conflict.date };
      }


      let productConflictFound = false;
      if (this.call.products && this.conflictCall.products) {
        if (this.call.products.length !== this.conflictCall.products.length) {
          productConflictFound = true;
        } else {
          for (let product of this.call.products) {
            if (!this.conflictCall.products.find(prodValue => prodValue.id == product.id)) {
              productConflictFound = true;
            }
          }
        }
      } else if (!this.call.products && this.conflictCall.products) {
        productConflictFound = true;
      }
      if (productConflictFound) {
        this.productConflict = { value: this.conflictCall.products, user: this.conflict.user, date: this.conflict.date };
      }

      let userConflictFound = false;
      if (this.call.users && this.conflictCall.users) {
        if (this.call.users.length !== this.conflictCall.users.length) {
          userConflictFound = true;
        } else {
          for (let user of this.call.users) {
            if (!this.conflictCall.users.find(userValue => userValue.id == user.id)) {
              userConflictFound = true;
            }
          }
        }
      } else if (!this.call.users && this.conflictCall.users) {
        userConflictFound = true;
      }
      if (userConflictFound) {
        this.userConflict = { value: this.conflictCall.users, user: this.conflict.user, date: this.conflict.date };
      }


      this.conflictsSearched = true;

    }



    if (!this.name && (this.mode === 'edit' || this.mode == 'conflict') && this.call && this.crm.fields.call) {
      this.name = this.crm.getDisplayValue(this.call);
      this.changeUnloadEdit('call', this.name);
    }
    if (this.invalid && this.forms && this.formDatas) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();

        if (formData.invalid) {
          this.collapse[formData.name] = false;
        }
        if (!this.call.customer) {
          this.collapse['customer'] = false;
        }
      }
      for (let form of this.forms) {
        form.markAsTouched();
      }
      this.invalid = false;
    }
    if (this.formDatas) {
      this.firstTabInvalid = false;
      for (let form of this.formDatas) {
        if (!form.valid && !form.disabled) {
          this.firstTabInvalid = true;
        }
      }
    }

    /* if (!this.firstTab && (this.forms.length > 0 || this.formDatas.length > 0)) {
      this.forms = [];
      this.formDatas = [];
    } */
    if (Object.keys(this.errTemp).length > 0 && this.formDatas?.length > 0 && this.formDatas.find(formData => Object.keys(formData.form.controls).length > 0)) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
      }
      for (let form of this.forms) {
        form.markAsTouched();
      }
      this.markFieldsAsInvalid(this.errTemp);

      this.errTemp = {};
    }
  }

  addCustomForms(forms: ClrForm[]) {
    if (!this.forms || this.forms.length == 0)
      this.forms = [];
    this.forms.push(...forms);
  }

  addCustomFormDatas(formDatas: NgForm[]) {
    if (!this.formDatas)
      this.formDatas = [];
    this.formDatas.push(...formDatas);
  }

  stringToDate($event?) {
    let datePieces = this.date.split('.');
    const date = new Date(Date.UTC(+datePieces[2], (+datePieces[1] - 1), +datePieces[0]));
    return this.setTime(date, $event);
  }

  setTime(date: Date, time: string) {
    let dateMs = date
    let timePieces = time.split(':');
    dateMs.setMinutes(+timePieces[1]);
    dateMs.setHours(+timePieces[0]);
    return dateMs;
  }

  setStartDateTime($event, inputField) {
    if (!this.endTime || $event <= this.endTime) {
      this.startTime = $event;
      this.startDateTime = this.stringToDate($event);
    } else {
      this.startTime = this.endTime;
      inputField.value = this.endTime;
      inputField.blur();
    }
  }

  setEndDateTime($event, inputField) {
    if (!this.startTime || $event >= this.startTime) {
      this.endTime = $event;
      this.endDateTime = this.stringToDate($event);
    } else {
      this.endTime = this.startTime;
      inputField.value = this.startTime;
      inputField.blur();
    }
  }


  async validateAndSave(stay?: boolean) {
    this.callRequired = true;
    if (this.call.customer && !this.firstTabInvalid) {
      await this.save(stay);
    }
    else {
      this.invalid = true;
    }
  }


  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
      }
      if (this.forms.length > 0)
        for (let form of this.forms) {
          form.markAsTouched();
        }
      for (let field of Object.keys(errors)) {
        if (this.formDatas.length > 0) {
          const controlFormDatas = this.formDatas.filter(formData => formData.form.controls[field])
          for (let controlFormData of controlFormDatas) {
            controlFormData.form.controls[field].markAsDirty();
            controlFormData.form.controls[field].setErrors(errors[field]);
          }
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  }

  async getCustomers(event?: string) {
    this.comboLoading = true;
     
    const res = await this.server.get('crm/customer/combobox/' + this.crm.activeViews.customer.id  + '/' + event);
   
    this.customers = plainToClass(Customer, res as unknown) as any; 
    this.customers = this.customers.filter((customer) => {

      return !this.call?.customer || customer.id != this.call.customer.id
    });
    this.comboLoading = false;
  }

  async customerChanged(event: any[]) {
    if (event) {
      this.selectedCustomer = event.pop();
      this.selectedCustomerList = [this.selectedCustomer]
      if (this.selectedCustomer.id != this.call.customer?.id) {
        this.call.customer = this.selectedCustomer;
        this.call.contacts = [];
        /* this.call.contacts = this.call.contacts.filter(callContact => event.find(customer => customer.contscts.find((cusContact) => cusContact.id == callContact.id))) */

      }
    } else {
      this.selectedCustomer = undefined;
      this.call.customer = this.selectedCustomer;
      this.selectedCustomerList = []
    }
  }


  async getProducts(event?: string) {
    this.comboLoading = true;
    const res = await this.server.get('crm/product' + (event ? '?name=' + event : ''))
    this.products = plainToClass(Product, res.rows).filter((product) => {
      return !this.call.products || !this.call.products.find(callProduct => callProduct.id == product.id)
    });
    this.comboLoading = false;
  }

  /* async productChanged($event) {
    if (this.selectedProduct) {
      await this.getProducts()
    }
  } */


  onChangeProduct(event) {
    this.getProducts();
    this.call.documents = this.call.documents.filter(callDocument => event.find(product => product.documents.find((prodDocument) => prodDocument.id == callDocument.id)))
  }

  onChangeUser(event) {
    this.getUsers();
  }

  documentQuery(products) {
    if (this.call.products) {
      return this.call.products.map(product => product.id).join(',')
    } else {
      return '';
    }

  }


  async getUsers(event?: string) {
    this.comboLoading = true;
    const res = await this.server.get('user' + (event ? '?name=' + event : ''))
    this.users = plainToClass(User, res.rows).filter((user) => {
      return !this.call?.users || !this.call.users.find(callUser => callUser.id == user.id)
    });
    this.comboLoading = false;
  }

  async save(stay?: boolean) {
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    this.call.customerId = this.selectedCustomer.id;
    if (this.mode === 'create') {
      let res = await this.server.post('crm/call', this.call);
      this.call = plainToClass(Call, res);
    } else {
      await this.server.put('crm/call', this.call);
    }
    if (this.mode == 'conflict') {
      await this.server.delete('crm/conflict?toDelete=' + this.conflictId)
    }
    this.server.addAlert(new AmselError(
      undefined,
      'success',
      this.localization.dictionary.toastr.successSaved
      .replace('${componentName}', this.localization.dictionary.call.nameSingular)
      .replace('${entryName}', this.crm.getDisplayValue(this.call))
    ));
    this.saving = false;
    this.loading = false;
    if(!stay){
      this.server.back();
    } else {
      this.name = this.crm.getDisplayValue(this.call);
      this.router.navigate(['crm/call/edit/' + this.call.id]);
    }
  }




  //Umgang mit Konflikten



  /* Kunden-Customer-Konflikt */

  displayCustomerConflict(customer: Customer) {
    if (!customer) {
      this.tempCustomer = JSON.parse(JSON.stringify(this.call.customer));
      customer = this.call.customer;
    }
    return customer;

  }

  async onConflictChange(customer: Customer, conflictAccepted: boolean) {
    const res = await this.server.get('crm/customer?id=' + customer.id)
    this.customerChanged(res.rows);
    this.conflictHandled = true;
    this.conflictAccepted = conflictAccepted;
  }



  /* Produkt-Konflikt */

  displayProductConflict(product: Product[]) {
    if (!product) {
      this.tempProducts = JSON.parse(JSON.stringify(this.call.products));
      product = this.call.products;
    }

    return product.map((field) => field.name).join(',');

  }

  async onConflictChangeByProduct(product: any, conflictAccepted: boolean, type: 'conflict' | 'model') {
    let clone: Product[];
    if (type == 'conflict') {
      clone = JSON.parse(JSON.stringify(this.conflictCall.products));
    } else {
      clone = JSON.parse(JSON.stringify(this.tempProducts));
    }
    this.call.products = clone;
    this.conflictHandled = true;
    this.conflictAccepted = conflictAccepted;
  }


  /* User-Konflikt */

  displayUserConflict(user: User[]) {
    if (!user) {
      this.tempUsers = JSON.parse(JSON.stringify(this.call.users));
      user = this.call.users;
    }

    return user.map((field) => field.name).join(',');

  }

  async onConflictChangeByUser(user: any, conflictAccepted: boolean, type: 'conflict' | 'model') {
    let clone: User[];
    if (type == 'conflict') {
      clone = JSON.parse(JSON.stringify(this.conflictCall.users));
    } else {
      clone = JSON.parse(JSON.stringify(this.tempUsers));
    }
    this.call.users = clone;
  }

}
