import { Inject, Injectable, Injector } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

 /*  customCustomerFields: Field[];
  customContactFields: Field[];
  customCallFields: Field[];
  displayCustomerFields: Field[];
  displayContactFields: Field[];
  displayCallFields: Field[];
  contactView: View;
  customerView: View;
  callView:View; */
  private _loading = new BehaviorSubject<{loading: boolean, id: any}>({loading: false, id: ''});
  loading$ = this._loading.asObservable();
  
  resolutions = {
    background: {
      width: 1920,
      height: 1080
    },
    logo: {
      width: 480,
      height: 240
    },
    loginLogo: {
      width: 640,
      height: 480
    },
    product: {
      width: 480,
      height: 640
    },
  }

  constructor(@Inject(Injector) private readonly injector: Injector,
              public server: ServerService,
              private imageCompress: NgxImageCompressService,
              public localization: LocalizationService,
              ) {  }

  /**
   * Konvertiert Date zu base64 string
   * @param file 
   * @returns base64 string
   */
  async getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * 
   * @param date 
   * @param inclUhr 
   * @returns 
   */
  formatDate(date: Date, inclUhr = true): string {
    if (date) {
      let dateString = `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
      if (inclUhr) dateString += ' Uhr';
      return dateString
    } else {
      return '';
    }
  }

  getDateStringShort(date: string): string {
    let dt = new Date();
    let output = '';
    const dateArr = date.split('-');

    output = this._getMonth(dateArr[1]);
    if (dt.getFullYear() > parseInt(dateArr[0])) {
      output += ` (${dateArr[0].slice(2)})`;
    } else if (dt.getFullYear() < parseInt(dateArr[0])) {
      output += ` (${dateArr[0].slice(2)})`;
    }
    return output;
  }

  getCardHeader(mode: 'create' | 'edit' | 'conflict', type: string, name?: string): string {
    if (mode === 'create') {
      if (name && name.length > 0) {
        return this.localization.dictionary.createEdit.createComponent.replace('${componentName}', `${this.localization.dictionary[type].new} "${name}"`);
      } else {
        return this.localization.dictionary.createEdit.createComponent.replace('${componentName}', this.localization.dictionary[type].new);
      }
    } else if (mode === 'conflict') {
      return this.localization.dictionary.createEdit.editComponentConflict.replace('${componentName}', this.localization.dictionary[type].nameSingular).replace('${entryName}', name);
    } else {
      return this.localization.dictionary.createEdit.editComponentEntry.replace('${componentName}', this.localization.dictionary[type].nameSingular).replace('${entryName}', name);
    }
  }

  copyCanvasToClipboard(canvasElem: HTMLCanvasElement) {
    try {
      canvasElem.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]));
      this.toastr.success(this.localization.dictionary.toastr.copyDiagram)
    } catch (err) {
      console.error(err)
    }
  }

  
  _getMonth(idx) {
    var dt = new Date();
    dt.setDate(1);
    dt.setMonth(idx - 1);
    return dt.toLocaleString('de-de', { month: "long" });;
  }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }
  
  async compressFile(imgType: 'background' | 'logo' | 'loginLogo' | 'product') {
    let res = '';
    await this.imageCompress
      .uploadFile().then(async ({ image, orientation }) => {
        await this.imageCompress.compressFile(image, orientation, 100, 85, this.resolutions[imgType].width, this.resolutions[imgType].height).
          then(
            (compressedImage) => {
              res = compressedImage;
            }
          );
      });
    return res;
  }

  checkInstanceSubdomain(name: string): boolean {
    return name == location.href.replace('https://', '').split('.')[0];
  }

  widgetFullTicks(val): string {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
  setLoading(loading: boolean, id: any) {
    this._loading.next({loading, id});
}
}


