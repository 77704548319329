import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  startOfDay,
  isSameDay,
  isSameMonth,
  parse,
  differenceInMinutes,
  startOfHour,
} from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarView} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { Call } from '../crm/calls/call.model';
import { plainToClass } from 'class-transformer';
import { ServerService } from '../server.service';
import { Router } from '@angular/router';
import { BrandingService } from '../branding/branding.service';
import { LocalizationService } from '../localization/localization.service';
import { AuthService } from '../auth/auth.service';
import { User } from '../users/user.model';
import { threadId } from 'worker_threads';
import { AmselError } from '../helper/error/amsel-error.model';

 interface AmselEvent extends CalendarEvent {
  callid?: string;
  description?: string;
  users: string[];
}

const lightColors: Record<string, EventColor> = {
  main: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  mainCE: {
    primary: '#04B0B0',
    secondary: '#B0E0E0',
  },
  secondary: {
    primary: '#19c62e',
    secondary: '#20aa30',
  },
  secondaryCE: {
    primary: '#19c62e',
    secondary: '#20aa30',
  },
};
const darkColors: Record<string, EventColor> = {
  main: {
    primary: '#1e90ff',
    secondary: '#263B59',
  },
  mainCE: {
    primary: '#04B0B0',
    secondary: '#227373',
  },
  secondary: {
    primary: '#19c62e',
    secondary: '#20aa30',
  },
  secondaryCE: {
    primary: '#19c62e',
    secondary: '#20aa30',
  },
};
@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  view: CalendarView;

  CalendarView = CalendarView;
  language: string;
  viewDate = new Date();
  activeDayIsOpen = false;

  addModalOpen: boolean = false
  // sends a refresh signal to the calendar when the data is updated
  refresh = new Subject<void>();

  events: AmselEvent[] = [];
  calls: Call[] = [];
  users: User[] = [];
  currentUser: User = null;

  //Add Event Form
  addTitle: string;
  addDescription: string;
  addStartDate: string;
  addStartTime: string;
  addEndDate: string;
  addEndTime: string;
  allDay: boolean = false;
  crrEvent: AmselEvent = null;


  constructor(
    private server: ServerService,
    private router: Router,
    private branding: BrandingService,
    public auth: AuthService,
    public local: LocalizationService,
    public localization: LocalizationService,
    private cdr: ChangeDetectorRef,
  ) {
    // Updates the colors of all events when the theme changes
    this.branding.themChanged.subscribe(() => {
      this.events.forEach(event => {
        if (this.branding.darkMode){
          if (event.color.primary === lightColors.main.primary)
            event.color = darkColors.main
          else if (event.color.primary === lightColors.mainCE.primary)
            event.color = darkColors.mainCE 
          else if (event.color.primary === lightColors.secondary.primary)
            event.color = darkColors.secondary 
          else if (event.color.primary === lightColors.secondaryCE.primary)
            event.color = darkColors.secondaryCE 
        }
        else {
          if (event.color.primary === darkColors.main.primary)
            event.color = lightColors.main 
          else if (event.color.primary === darkColors.mainCE.primary)
            event.color = lightColors.mainCE 
          else if (event.color.primary === darkColors.secondary.primary)
            event.color = lightColors.secondary 
          else if (event.color.primary === darkColors.secondaryCE.primary)
            event.color = lightColors.secondaryCE 
        }
      });
      this.refresh.next();
    });
  }

  ngOnInit(): void {
    this.currentUser = this.auth.user
    // Wandelt den Selektor von 'de' in 'de-DE' um
    this.language = this.local.language.selector + '-' + this.local.language.selector.toUpperCase()
    this.local.languageChanged.subscribe(() => {
      this.language = this.local.language.selector + '-' + this.local.language.selector.toUpperCase()
      this.refresh.next();
    });
    this.init()
    // Kalenderansicht aus dem Lokalenspeicher laden
    const storedCalendarView = localStorage.getItem('CalendarView');
    if (storedCalendarView && Object.values(CalendarView).includes(storedCalendarView as CalendarView))
      this.setView(storedCalendarView as CalendarView);
    else 
      this.setView(CalendarView.Week);
  }

  ngAfterViewInit() {
    this.scrollToCurrentView();
  }

  ngOnDestroy(): void {
    localStorage.setItem('CalendarView', this.view);
  }

  /**
   * Inizialisiert die Kalender Events in dem Calls und Events vom Server geladen und in Events umgewandelt werden.
   * 
   * @async
   * @returns {void}
   */
  async init(){
    await Promise.all([
      this.refreshCalls(),
      this.refreshEvents(),
      this.refreshUsers()
    ])
    this.calls.forEach(call => {
      this.events.push(this.calltoEvent(call))
    });
    this.refresh.next();

  }

  /**
  * Aktualisiert die Liste der Calls, indem Daten vom Server abgerufen werden.
  * 
  * @async
  * @returns {void}
  */
  async refreshCalls(){
    let query = new URLSearchParams()
    query.append('users', `{"value":["${this.currentUser.id}"], "type":"multiAnd"}`)
    const call = await this.server.get(`crm/call?` + query.toString());
    this.calls = plainToClass(Call, call.rows);
  }

  /**
   * Aktualisiert die Liste der User, es werden nur User geladen die in einem untergeordnenen Gebiet des aktuellen Benutzers liegen.
   *  
   * @async
   * @returns {void} 
   */
  async refreshUsers(){
    if (this.auth.hasRole(['admin', 'crm-editor'])){
      const users = await this.server.get('user');
      this.users = plainToClass(User, users.rows);
    } else {
      const users = await this.server.get('crm/territory/subordinates');
      this.users = plainToClass(User, users.rows);
      if (!this.users.some( user => user.id === this.currentUser.id))
        this.users.push(this.auth.user)
    }
  }

  /**
   * Aktualisiert die Liste der Events, indem Daten vom Server abgerufen werden.
   * 
   * @async
   * @returns {void}
   */
  async refreshEvents(){
    let query = new URLSearchParams()
    query.append('userId', this.currentUser.id)
    const event = await this.server.get('calendar/event' + '?' + query.toString());    
    this.events = event.rows.map((e) => {
      return {
        id: e.id,
        title: e.title,
        description: e.description,
        callid: e.callid,
        users: e.users,
        start: new Date(e.start),
        end: new Date(e.end? e.end : e.start),
        color: this.branding.darkMode ? darkColors.mainCE : lightColors.mainCE,
        allDay: e.allDay,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      } as AmselEvent
    });
    return true
  }

  /**
  * Konvertiert den übergebenen Call in ein Event, das im Kalender angezeigt werden kann.
  * 
  * @param {Call} call - Der Call, der in ein Event konvertiert werden soll.
  * @returns {AmselEvent} - Das konvertierte Event.
  */
  calltoEvent(call: Call){

    let event: AmselEvent = {
      id: null,
      callid: call.id,
      description: call['u_summary'] ? call['u_summary'][0].value : null,
      users: call.users.map((user: User) => user.id),
      start: null,
      title: null,
      color: this.branding.darkMode ? darkColors.main : lightColors.main ,
      allDay: true,
      // Disabling the ability to change the event´s time inside the calendar
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    }

    if(call['u_start_time']){
      let date = new Date(call['u_date'][0].value)
      let time = new Date(call['u_start_time'][0].value)
      date.setHours(time.getHours())
      date.setMinutes(time.getMinutes())
      date.setSeconds(time.getSeconds())
      event.start = date
      event.allDay = false
    }
    else
      event.start = startOfDay(new Date(call['u_date'][0].value))

    if (call['u_end_time']) {
      let date = new Date(call['u_date'][0].value)
      let time = new Date(call['u_end_time'][0].value)
      date.setHours(time.getHours())
      date.setMinutes(time.getMinutes())
      date.setSeconds(time.getSeconds())
      event.end = date
      event.allDay = false
    } else {
      event.end = new Date(event.start.getTime() + 60*60*1000);
    }
    
    event.title = call['u_title'] ? call['customer']['fieldValues'][0].value + ', ' + call['u_title'][0].value : call['customer']['fieldValues'][0].value
    // Hängt Start und Endzeit an den Titel an, wenn die Monatsansicht aktiv ist
    if (this.view === CalendarView.Month){
      let start: Date = call['u_start_time'] ? new Date(call['u_start_time'][0].value) : null
      let end: Date = call['u_end_time'] ? new Date(call['u_end_time'][0].value) : null
      if (start)
        event.title = event.title + ', ' + 
          start.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}) + 
          (end ? ( ' - ' + end.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})) : '')
    }
    return event;
  }

  /**
  * Setzt die viewDate und activeDayIsOpen Eigenschaften auf das angeklickte Datum. Dies öffnet das Dropdown in der Monatsansicht.
  * 
  * @param {{ date: Date; events: CalendarEvent[] }} param0
  * @param {Date} param0.date
  * @param {CalendarEvent[]} param0.events
  * @returns {void}
  */
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  /**
  * Öffnet das Event, wenn es angeklickt wird. Wenn es sich um einen Call handelt, wird der Benutzer zur Bearbeitungsseite des Calls weitergeleitet.
  * 
  * @param {CalendarEvent} event
  * @returns {void}
  */
  handleEvent(event: AmselEvent): void {
    if (event.callid)
      this.router.navigate(['/crm/call/edit/', event.callid]);
    else{
      this.crrEvent = event
      this.addTitle = event.title;
      this.addDescription = event.description;
      this.addStartDate = new Date(event.start).toLocaleDateString('de-DE');
      this.addStartTime = event.start.toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'});
      this.addEndDate = event.end.toLocaleDateString('de-DE');
      this.addEndTime = event.end.toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'});
      this.allDay = event.allDay
      this.addModalOpen = true
    }
      
  }
  
  /**
  * Setzt die Kalenderansicht und aktualisiert die Eventtitel basierend auf der Ansicht.
  * Es fügt die Start- und Endzeit im Monatsansicht zum Titel hinzu und entfernt sie in den anderen Ansichten.
  * @param {CalendarView} [view] - Die Kalenderansicht, die gesetzt werden soll.
  * @returns {void}
  */
  setView(view?: CalendarView) {
    // Hängt Start und Endzeit an den Titel an, wenn die Monatsansicht aktiv ist
    if (view === CalendarView.Month || this.view === CalendarView.Month){
      for (let event of this.events){
        if (!event.allDay && view === CalendarView.Month && this.view !== CalendarView.Month){
          if (event.start.getDate() === event.end.getDate()){
            event.title = event.title + ', ' + event.start.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}) + ' - ' + event.end.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
          }
        }
        else if (!event.allDay && view !== CalendarView.Month && this.view === CalendarView.Month){
          event.title = event.title.substring(0, event.title.lastIndexOf(','));
        }
      }
    }
    this.view = view;
    this.cdr.detectChanges();
    this.scrollToCurrentView();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  /**
   * Erstellt ein neues oder aktualisiert ein bestehendes Event.
   * 
   * @async
   * @param form 
   * @return {void}
   */
  async onCreate(form: any) {

    let start = parse(this.addStartDate, "dd.MM.yyyy", new Date());
    let end: Date = null
    let allDay = this.allDay

    if (this.addStartTime != null){
      start.setHours(+this.addStartTime.slice(0, 2))
      start.setMinutes(+this.addStartTime.slice(3, 5))
      start.setSeconds(0)
    }
    else {
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
    }
      
    if (this.addEndDate){
      end = parse(this.addEndDate, "dd.MM.yyyy", new Date());
      if (this.addEndTime){
        end.setHours(+this.addEndTime.slice(0, 2))
        end.setMinutes(+this.addEndTime.slice(3, 5))
        end.setSeconds(0)
      }
      else {
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
      }
    }
    else {
      end = new Date(start)
      if (this.addEndTime){
        end.setHours(+this.addEndTime.slice(0, 2))
        end.setMinutes(+this.addEndTime.slice(3, 5))
        end.setSeconds(0)
      }
      else {
        end.setHours(start.getHours() + 1)
      }

    }

    if (this.crrEvent){
      let sendEvent: AmselEvent = {
        id: this.crrEvent.id,
        title: this.addTitle,
        description: this.addDescription,
        callid: this.crrEvent.callid,
        users: this.crrEvent.users,
        start,
        end,
        allDay,
        color: this.branding.darkMode ? darkColors.mainCE : lightColors.mainCE,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      }
      await this.server.put('calendar/event/' + this.crrEvent.id, {...sendEvent, userId: this.currentUser.id}).then( (resp) => {
        resp = resp[0]
        let index = this.events.findIndex((e) => e.id === this.crrEvent.id)
        let newEvent = {
          id: resp.id,
          title: resp.title,
          description: resp.description,
          callid: resp.callid,
          users: resp.users,
          start: new Date(resp.start),
          end: new Date(resp.end? resp.end : resp.start),
          color: this.branding.darkMode ? darkColors.mainCE : lightColors.mainCE,
          allDay: resp.allDay,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
        } as AmselEvent
        this.events[index] = newEvent;
        form.reset();
        this.refresh.next();
        this.addModalOpen = false;
      });
    } else {
      let sendEvent: AmselEvent = {
        title: this.addTitle,
        description: this.addDescription,
        callid: null,
        users: [this.currentUser.id],
        start,
        end,
        allDay,
        color: this.branding.darkMode ? darkColors.mainCE : lightColors.mainCE,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      }
      await this.server.post('calendar/event', {...sendEvent, userId: this.currentUser.id}).then( (resp) => {
        let newEvent: AmselEvent = {
          id: resp.id,
          title: resp.title,
          description: resp.description,
          callid: resp.callid,
          users: resp.users,
          start: new Date(resp.start),
          end: new Date(resp.end ? resp.end : resp.start),
          color: this.branding.darkMode ? darkColors.mainCE : lightColors.mainCE,
          allDay: resp.allDay,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
        } as AmselEvent
        this.events.push(newEvent);
        console.log("Events: ", this.events);
        form.reset();
        this.refresh.next();
        this.addModalOpen = false;
      });
    }
    this.crrEvent = null
  }

  /**
   * Löscht das aktuelle Event.
   * 
   * @async
   * @returns {void}
   */
  async deleteEvent(){
    await this.server.delete('calendar/event/' + this.crrEvent.id).then( (resp) => {
      let index = this.events.findIndex((e) => e.id === this.crrEvent.id)
      this.events.splice(index, 1)
      this.refresh.next();
      this.addModalOpen = false;
    });
  }

  /**
   * Ändert die Start- und Endzeit des Events, wenn es verschoben / gezogen wird.
   * 
   * @param changeEvent 
   * @returns {void}
   */
  eventTimesChanged(changeEvent: { event: AmselEvent; newStart?: Date; newEnd?: Date , type: string}){
    let oldStart = changeEvent.event.start
    let oldEnd = changeEvent.event.end
    let i = this.events.indexOf(changeEvent.event);
    if (changeEvent.newStart) this.events[i].start = changeEvent.newStart
    if (changeEvent.newEnd) this.events[i].end = changeEvent.newEnd
    this.refresh.next();

    if (changeEvent.event.callid){
      if (changeEvent.newStart.getDate() !== changeEvent.newEnd.getDate()){
        this.server.addAlert(new AmselError(null, 'danger', this.localization.dictionary.calendar.errorCallOnlyOneDay));
        this.events[i].start = oldStart
        this.events[i].end = oldEnd
        this.refresh.next();
      }
      else {
        let changedCall = this.calls.find(call => call.id === changeEvent.event.callid)
        let u_start_time = new Date(changeEvent.newStart)
        u_start_time.setFullYear(1970, 0, 1)
        let u_end_time = new Date(changeEvent.newEnd)
        u_end_time.setFullYear(1970, 0, 1)

        changedCall['u_date'][0].value = changeEvent.newStart.toISOString()
        changedCall['u_start_time'][0].value = u_start_time.toISOString()
        changedCall['u_end_time'][0].value = u_end_time.toISOString()
        console.log("Changed Call: ", changedCall);

        this.server.put('crm/call', {...changedCall}).catch((err) => {
          console.error(err);
          this.events[i].start = oldStart
          this.events[i].end = oldEnd
          this.refresh.next();
          this.server.addAlert(new AmselError(null, 'danger', err.message));
        });
      }
    }
    else {
      this.server.put('calendar/event/' + changeEvent.event.id, {...this.events[i], userId: this.currentUser.id}).catch((err) => {
        console.error(err);
        this.events[i].start = oldStart
        this.events[i].end = oldEnd
        this.refresh.next();
        this.server.addAlert(new AmselError(null, 'danger', err.message));
      });
    }
  }

  /**
   * Aktualisiert die Events, wenn sich der ausgewähle Benutzer geändert wird.
   * 
   * @async
   * @returns {void}
   */
  async onUserChange(){
    Promise.all([
      this.refreshEvents(),
      this.refreshCalls()
    ]).then(() => {
      this.calls.forEach(call => {
        this.events.push(this.calltoEvent(call))
      });
      this.refresh.next();
    });
  }

  /**
   * Scrollt den Kalender-View zur aktuellen Uhrzeit.
   * 
   * @returns {void}
   */
  private scrollToCurrentView() {
    let scrollContainer = document.getElementsByClassName('cal-time-events')[0] ;
    if (this.view === CalendarView.Week || CalendarView.Day) {
      // each hour is 60px high, so to get the pixels to scroll it's just the amount of minutes since midnight
      const minutesSinceStartOfDay = differenceInMinutes(
        startOfHour(new Date()),
        startOfDay(new Date())
      );
      const headerHeight = this.view === CalendarView.Week ? 60 : 0;
      scrollContainer.scrollTop =
        minutesSinceStartOfDay + headerHeight - 60;
    }
  }

  /**
   * Gibt das minimale Datum zurück, das im Datepicker ausgewählt werden kann.
   * Das Format ist YYYY-MM-DD.
   * @returns {string} - YYYY-MM-DD
   */
  minDate(): string {
    if (!this.addStartDate)
      return null
    const parts = this.addStartDate.split('.')
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Format: YYYY-MM-DD
  }
  /**
   * Gibt das maximale Datum zurück, das im Datepicker ausgewählt werden kann.
   * Das Format ist YYYY-MM-DD.
   * @returns {string} - YYYY-MM-DD
   */
  maxDate(): string {
    if (!this.addEndDate)
      return null
    const parts = this.addEndDate.split('.')
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Format: YYYY-MM-DD
  }
}