import { Exclude, Expose, Type } from 'class-transformer';
import { BrandingSetting } from './branding-setting.model';

@Exclude()
export class Branding {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    isDefault: boolean;

    @Expose()
    version: number;

    @Expose()  
    @Type( () => BrandingSetting)  
    settings: BrandingSetting[];

    @Expose()
    getSettingValue(selector: string) {
        const setting =  this.settings.find( (setting) => setting.selector === selector);
        if (setting) return setting.value;
        return undefined;
    }
}