import { Expose, Type } from "class-transformer";
import { Territory } from "../crm/territories/territory.model";
import { Role } from "../roles/role.model";
import { User } from "../users/user.model";

export class Group {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    description: string;

    @Expose()
    roles: Role[] = [];

    @Expose()
    users: User[] = [];

    @Expose()
    territories: Territory[] = [];

    @Expose()
    @Type(() => Date)
    createdAt: Date

    @Expose()
    @Type(() => Date)
    updatedAt: Date

    @Expose()
    @Type(() => Date)
    deletedAt: Date

    hasRole(roles: string[]): boolean {
        let hasRole = false;
        for (let role of roles) {
            if (this.roles.find( (userRole) => { userRole.name === role})) {
                hasRole = true;
            }
        }
        return hasRole;
    }

}