<clr-icon *ngIf="isActive()" class="refresh-filter is-solid" shape="filter-off" (click)="onClick(true)"></clr-icon>
<clr-radio-container>
    <label>{{localization.dictionary.filter.searchedProperties}}</label>
    <!-- <clr-radio-wrapper>
        <input type="radio" clrRadio value="noFilter" name="options" [checked]="!filter || filter === 'noFilter'"
            (change)="onChange($event)" />
        <label>Keine Filterung</label>
    </clr-radio-wrapper> -->
    <clr-radio-wrapper>
        <input type="radio" clrRadio [value]="binary? '1' : 'true'" name="options" [checked]="filter === (binary? '1' : 'true')"
            (change)="onChange($event)" />
        <label>{{nameTrue}}</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input type="radio" clrRadio [value]="binary? '0' : 'false'" name="options" [checked]="filter === (binary? '0' : 'false')"
            (change)="onChange($event)" />
        <label>{{nameFalse}}</label>
    </clr-radio-wrapper>
</clr-radio-container>
<button *ngIf="confirm" class="btn btn-primary btn-sm" (click)="onClick(); open = false">{{localization.dictionary.button.confirm}}</button>
