<div class="login-wrapper">
    <form class="login" *ngIf="settings.title">
        <section class="title">
            <h3 class="welcome">{{localization.dictionary.general.welcomeToThe}}</h3>
            {{ settings.title }}
        </section>
        <div class="login-group">
            <clr-input-container>
                <label class="clr-sr-only">{{localization.dictionary.user.username}}</label>
                <input type="text" name="username" clrInput [placeholder]="localization.dictionary.user.username" [(ngModel)]="username" maxlength="100"/>
            </clr-input-container>
            <clr-password-container>
                <label class="clr-sr-only">{{localization.dictionary.user.password}}</label>
                <input type="password" name="password" clrPassword [placeholder]="localization.dictionary.user.password" [(ngModel)]="password" maxlength="100"/>
            </clr-password-container>
            <button class="btn btn-primary" (click)="login()">{{ localization.dictionary.button.login }}</button>
        </div>
    </form>
</div>
