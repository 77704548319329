import { v1 } from 'uuid';

export class AmselError {

    constructor(public id = v1(),
                public type: 'success' | 'info' | 'warning' | 'danger' = 'info',
                public msg = '',
                public loglevel: 0 | 1 | 2 | 3 = 0,
                public closable = true
    ) {}

}