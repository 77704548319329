import { Expose, Type } from "class-transformer";
import { Field } from "./field.model";
import { v4 } from 'uuid';

export class FieldOption {
    @Expose()
    id: string = v4();

    @Expose()
    label: string;

    @Expose()
    fieldId: string;

    @Type(() => Field)
    field: Field;

    @Expose()
    order: number
}