import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function regexValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value) {
            let pattern = control.value;
            let parts = pattern.split('/'),
                regex = pattern,
                options = "";
            if (parts.length > 1) {
                regex = parts[1];
                options = parts[2];
            }
            try {
                new RegExp(regex)
                return null;
            }
            catch (e) {
                return { wrongRegex: { value: control.value } };
            }
        } else {
            return null;
        }


    }
}