import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.css']
})
export class NoPermissionComponent implements OnInit {

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
  }

}
