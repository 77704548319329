import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Category } from '../../categories/category.model';
import { ServerService } from '../../server.service';
import { SettingService } from '../../settings/setting.service';
import { Theme } from '../theme.model';

const umlautMap = {
  '\u00dc': 'U',
  '\u00c4': 'A',
  '\u00d6': 'O'
}

@Component({
  selector: 'app-branding-preview',
  templateUrl: './branding-preview.component.html',
  styleUrls: ['./branding-preview.component.css']
})
export class BrandingPreviewComponent implements OnInit {

  time = new Date();

  @Input() theme: Theme;
  @Input() activePage: boolean[];
  @Output() jumpTo: EventEmitter<{selector: string, page: number, previewChange?: string}> = new EventEmitter()
  previewCategory = new Category();
  activeLetters: boolean[] = new Array(26).fill(false);
  alphabet = new Array(26).fill(1).map((_, i) => String.fromCharCode(65 + i));
  crmCategories = [{name: this.localization.dictionary.customer.name, icon: 'building' }, {name:this.localization.dictionary.contact.name, icon: 'user' },{name: this.localization.dictionary.call.name, icon:'talk-bubbles' }]
  tableData: any[] = []
  constructor(public serverService: ServerService, public settingService: SettingService, public localization: LocalizationService ) {
    this.localization.languageChanged.subscribe(()=> {
      this.crmCategories = [{name: this.localization.dictionary.customer.name, icon: 'building' }, {name:this.localization.dictionary.contact.name, icon: 'user' },{name: this.localization.dictionary.call.name, icon:'talk-bubbles' }]
    })
  }

  async ngOnInit() {
    if (this.serverService.categories && this.serverService.categories.length > 0) {
      const mainCats = this.serverService.categories.filter(cat => cat.inMainMenu)
      if(mainCats.length > 0) {
        let res = await this.serverService.get('category/byId/' + mainCats[0].id + "?includeDocuments=true")
        this.previewCategory = plainToClass(Category, res.rows[0])
      }
      this.buildActiveLetters()
    }
    this.setTableData();
  }

  buildActiveLetters() {
    this.activeLetters = new Array(26).fill(false);
    if (this.previewCategory.documents && this.previewCategory.documents.length > 0) {
      for (let i = 0; i < this.alphabet.length; i++) {
        if (this.previewCategory.documents.find((item) => this.replaceUmlaute(item.name[0].toUpperCase()) === this.alphabet[i])) {
          this.activeLetters[i] = true;
        }
      }
    } else {
      this.activeLetters[4] = true
      this.activeLetters[25] = true
    }
  }

  /**
   * @param str input to replace umlauts from
   * @returns "normalized" string
   */
  replaceUmlaute(str: string): string {
    return str
      .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
        const big = umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', "g"),
        (a) => umlautMap[a]
      );
  }

  someFunction(value) {
    return !value.inMainMenu
  }

  setTableData(){
    if(this.localization.language.selector == 'de') {
      this.tableData = [
        { name: 'Johann Müller', contacts: 'Anna Schmidt', territory: 'Berlin', date: '2023-03-15' },
        { name: 'Lena Fischer', contacts: 'Michael Braun', territory: 'München', date: '2023-04-20' },
        { name: 'Robert Wagner', contacts: 'Emilia Weiss', territory: 'Frankfurt', date: '2023-05-10' },
        { name: 'Karin Becker', contacts: 'David Meier', territory: 'Köln', date: '2023-06-25' },
        { name: 'Jan Schneider', contacts: 'Olivia Martin', territory: 'Hamburg', date: '2023-07-30' }
    ];
    }else{
      this.tableData = [
        { name: 'John Ray', contacts: 'Jane Smith', territory: 'Berlin', date: '2023-03-15' },
        { name: 'Alice Lee', contacts: 'Michael Brown', territory: 'Munich', date: '2023-04-20' },
        { name: 'Robert Davis', contacts: 'Emily White', territory: 'Frankfurt', date: '2023-05-10' },
        { name: 'Karen Fox', contacts: 'David Miller', territory: 'Cologne', date: '2023-06-25' },
        { name: 'James Doe', contacts: 'Olivia Martin', territory: 'Hamburg', date: '2023-07-30' }
    ];
    }

  }

  layout: {
    outerAlign: 'space-between flex-start' | 'space-between center',
    innerAlign: 'center end' | 'end center',
    flexDir: 'row' | 'column',
    gap: '5%' | '0.5rem',
    firstFlex: '0 1 100%' | '1 10 100%',
    outerFlex: '0 1 100%' | '50' | '60',
    innerFlex: '60'
    small: boolean,
    medium: boolean,
  }

}
