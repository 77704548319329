import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { TrackingService } from '../../tracking.service';
import { WidgetInterface } from '../widget.interface';
import { ModalService } from '../../dashboards/diagram-popup/modal-data.service';

export type MailData = {
  deletedAt: Date
  name: string,
  count: number
}

@Component({
  selector: 'app-widget-mail',
  templateUrl: './widget-mail.component.html',
  styleUrls: ['./widget-mail.component.css']
})
export class WidgetMailComponent implements OnInit, WidgetInterface {
  @ViewChild('chart') chart: ElementRef;

  mailEvents: MailData[] = [];
  total: number;
  loading = false;  
  data = [];
  id: string;

  widgetTitel: string = '';

  constructor(private tracking: TrackingService, 
    public helper: HelperService,
    public localization: LocalizationService,
    public modalService: ModalService) {
      this.helper.loading$.subscribe(({ id, loading }) => {
        if (id === 'mail') {
          this.loading = loading;
        }
      });

    }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }

  async refresh(from?: Date, to?: Date) {  
    this.loading = true;     
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
    const mails = await this.tracking.getMailEvents('?' + query.toString())
    this.mailEvents = mails.rows;
    this.total = mails.count
    
    for (let mailEvent of this.mailEvents) {
      this.data.push({
        name: mailEvent.name + (mailEvent.deletedAt? ' (' + this.localization.dictionary.list.deleted + ')' : ''),
        value: mailEvent.count
      })
    }
    this.loading = false;
  }
  
}
