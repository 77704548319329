import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-territory-create',
  templateUrl: './de-territory-create.component.html'
})
export class DeTerritoryCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
