import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { plainToClass } from 'class-transformer';
import { Branding } from 'src/app/branding/branding.model';
import { BrandingService } from 'src/app/branding/branding.service';
import { ServerService } from 'src/app/server.service';
import * as qr from 'awesome-qr';
import { environment } from '../../../environments/environment';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { LocalizationService } from '../../localization/localization.service';

declare var G_vmlCanvasManager;

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.css'],
  animations: [
    trigger("FadeIn", [
      transition(':enter', [
        animate('200ms', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ])
    ])
  ]
})
export class InstallComponent implements OnInit {
  loading = true;

  style = {
    bg: '',
    logo: '',
    boxBg: '',
    textColor: '',
    button: {
      'background-color': '',
      'color': ''
    },
    headerBgColor: ''
  }

  uri: string;
  enterprise: boolean;
  appName: string;
  appVersion: string;
  qrCode: string;

  copied = false;

  branding: Branding;

  origin: string;
  os: 'android' | 'windows' | 'ipados' | 'unsupported';
  
  constructor(private server: ServerService,
              @Inject(DOCUMENT) private document: Document,
              public sanitizer: DomSanitizer ,
              public localization: LocalizationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.origin = window.location.href.replace('install', 'download/');    
    // Get Settings
    const appNameRes = await this.server.get('setting/bySelector/appName');
    this.appName = appNameRes['value'];
    const appVersionRes = await this.server.get('setting/bySelector/appVersion');
    this.appVersion = appVersionRes['value'];
    const enterpriseModeRes = await this.server.get('setting/bySelector/enterpriseMode');
    this.enterprise = enterpriseModeRes['value'] == 'true';
    this.uri = environment.uri
    // Get Branding Settings
    const brandingRes = await this.server.get('branding/getDefault');
    this.branding = plainToClass(Branding, brandingRes);
    this.style.bg = this.branding.getSettingValue('--amsel-background-image');
    this.style.logo = this.branding.getSettingValue('--amsel-logo-background-image');
    this.style.boxBg = this.branding.getSettingValue('--amsel-blur-box-bg-color');
    this.style.button['background-color'] = this.branding.getSettingValue('--clr-btn-primary-bg-color');
    this.style.button['color'] = this.branding.getSettingValue('--clr-btn-primary-color');
    this.style.textColor = this.branding.getSettingValue('--amsel-document-item-font-color');
    this.style.headerBgColor = this.branding.getSettingValue('--clr-header-6-bg-color');
    this.checkOs();
    let logo = this.branding.getSettingValue('--amsel-header-logo-bg').replace('url(', '').replace(/\)$/, '');    
    await this.generateQr(logo);
    this.loading = false;
  }

  checkOs() {
    if (/iPad/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      this.os = 'ipados';
    } else if (navigator.appVersion.indexOf("Win") !== -1) {
      this.os = 'windows'
    } else if (/android/i.test(navigator.userAgent) || /samsungbrowser/i.test(navigator.userAgent)) {
      this.os = 'android'
    } else {
      this.os = 'unsupported'
    }
  }

  async generateQr(logo: string) {
    //const test = await this._fileReader();
    this.qrCode = await new qr.AwesomeQR({
      text: window.location.href,
      size: 400,
      //backgroundImage: logo
      backgroundImage: this.createBgColor('#aaaaaa'),
      //logoImage: logo,
      //whiteMargin: false
    }).draw() as string;
  }

  public get qrCodeSanitized(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(this.qrCode);
  }

  createBgColor(hex: string): string {
    let canvas = document.createElement('canvas');
    
    if (!canvas.getContext) G_vmlCanvasManager.initElement(canvas);

    var ctx = canvas.getContext('2d');
    canvas.width = 1;
    canvas.height = 1;

    ctx.fillStyle = hex;
    ctx.fillRect(0,0,1,1);
    return 'data:image/png;base64,' + canvas.toDataURL('image/png','').substring(22);
    
  }

  uriToClipboard(tooltipElement: HTMLDivElement) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.uri;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showCopied(tooltipElement);
  }

  showCopied(tooltipElement: HTMLDivElement) {
    if(!this.copied) {
      tooltipElement.focus()
      setTimeout(()=>{
        tooltipElement.blur()
      }, 1000)
    }
  }

}
