import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Customer } from 'src/app/crm/customers/customer.model';
import { Field } from 'src/app/crm/fields/field.model';
import { Territory } from 'src/app/crm/territories/territory.model';
import { View } from 'src/app/crm/views/view.model';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { Unsubscribable } from '../unsubscribable/unsubscribable';
import { Subject, debounceTime } from 'rxjs';
import { CrmService } from 'src/app/crm/crm.service';
import { Product } from 'src/app/crm/products/product.model';
import { User } from 'src/app/users/user.model';

@Component({
  selector: 'app-multi-update-crm',
  templateUrl: './multi-update-crm.component.html',
  styleUrls: ['./multi-update-crm.component.css']
})
export class MultiUpdateCrmComponent extends Unsubscribable implements OnInit, DoCheck {
  territoryModel = Territory;
  private forms: ClrForm[] = []
  @ViewChildren(ClrForm) set contentForms(content: QueryList<ClrForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.forms || this.forms.length == 0)
        this.forms = content.toArray();
      else
        this.forms.push(...content.toArray());
    } else {
      if (!this.forms || this.forms.length == 0)
        this.forms = [];
    }
  }
  private formDatas: NgForm[] = [];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = content.toArray();
      else
        this.formDatas.push(...content.toArray());
      if (content.toArray()[0]) this.controls = content.toArray()[0].form.controls
    } else {
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = [];
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  @Input() multiUpdateOpen: boolean;
  @Output() multiUpdateOpenChange = new EventEmitter<boolean>();

  @Input() model: any = {};
  modelCopy = {};
  @Input() activeView: View;

  @Input() rows: any[];
  @Output() rowsChange = new EventEmitter<any[]>();

  @Input() type: 'customer' | 'contact' | 'call';

  controls: {[key: string]: AbstractControl} = {}

  dirty: boolean;

  openWarning = false;

  collapse = {};
  unlocked = {};
  anyUnlocked = false;
  changes: {field: Field | {label: string}, input: string}[] = [];

  invalid = false;
  markInvalid = false;
  errTemp = {};

  territories: Territory[] = [];

  customers: Customer[] = [];
  customerQueryChanged: Subject<string> = new Subject<string>();
  comboLoading = true;
  selectedCustomer: Customer;
  selectedCustomerList: Customer[];
  products: Product[] = [];
  users: User[] = [];

  toggleDependencies = {
    call: {
      customer: 'contacts',
      contacts: 'customer',
      products: 'documents',
      documents: 'products'
    }
  }

  constructor(public localization: LocalizationService, private server: ServerService, private crm: CrmService, private cdr: ChangeDetectorRef) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.type == 'customer') {
      const res = await this.server.get('crm/territory/forUser');
      this.territories = plainToClass(Territory, res.rows);
    }
    if (this.type == 'call') {
      this.subscriptions.add(
        this.customerQueryChanged.pipe(debounceTime(500))
        .subscribe(async (event) => {
          this.customers = [];
          await this.getCustomers(event);
        })
      )
    }

  }

  addCustomForms(forms: ClrForm[]) {
    if (!this.forms || this.forms.length == 0)
      this.forms = [];
    this.forms.push(...forms);
  }

  addCustomFormDatas(formDatas: NgForm[]) {
    if (!this.formDatas)
      this.formDatas = [];
    this.formDatas.push(...formDatas);
  }

  ngDoCheck() {
    if (this.markInvalid && this.forms && this.formDatas) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();

        if (formData.invalid) {
          this.collapse[formData.name] = false;
        }
      }
      for (let form of this.forms) {
        form.markAsTouched();
      }
      this.markInvalid = false;
    }
    if (this.formDatas) {
      this.invalid = false;
      for (let form of this.formDatas) {
        if (!form.valid && !form.disabled) {
          this.invalid = true;
        }
      }
    }

    /* if (!this.firstTab && (this.forms.length > 0 || this.formDatas.length > 0)) {
      this.forms = [];
      this.formDatas = [];
    } */
    if (Object.keys(this.errTemp).length > 0 && this.formDatas?.length > 0 && this.formDatas.find(formData => Object.keys(formData.form.controls).length > 0)) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
      }
      for (let form of this.forms) {
        form.markAsTouched();
      }
      this.markFieldsAsInvalid(this.errTemp);

      this.errTemp = {};
    }
    // store the variable in crm.service to check in custom-input-component if the multi-update Modal is opened
    // if it is open, answer edit should not be possible.
    this.crm.multiUpdateOpen = this.multiUpdateOpen;

  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
      }
      if (this.forms.length > 0)
        for (let form of this.forms) {
          form.markAsTouched();
        }
      for (let field of Object.keys(errors)) {
        if (this.formDatas.length > 0) {
          const controlFormDatas = this.formDatas.filter(formData => formData.form.controls[field])
          for (let controlFormData of controlFormDatas) {
            controlFormData.form.controls[field].markAsDirty();
            controlFormData.form.controls[field].setErrors(errors[field]);
          }
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  }

  validateAndOpenWarningModal() {
    if (this.invalid) {
      this.markInvalid = true;
      return;
    }
    this.changes = [];
    this.modelCopy = {};
    this.addFieldValues()
    if (this.type == 'customer') {
      this.addReference('territory', this.localization.dictionary.territory.nameSingular);
      this.addReference('products', this.localization.dictionary.product.name, true);
    }
    if (this.type == 'call') {
      this.addReference('customer', this.localization.dictionary.customer.nameSingular, false, true);
      this.addReference('contacts', this.localization.dictionary.contact.name, true, true);
      this.addReference('products', this.localization.dictionary.product.name, true);
      this.addReference('documents', this.localization.dictionary.document.name, true);
      this.addReference('users', this.localization.dictionary.user.name, true);
    }
    this.openWarning = true;
  }

  addFieldValues() {
    const unlockedFields = this.activeView.sections
      .map(section => section.fields)
      .reduce((acc, value) => acc.concat(value), [])
      .filter(field => this.unlocked[field.name]);
    for (let field of unlockedFields) {
      this.modelCopy[field.name] = this.model[field.name] || [];
      this.changes.push({ field, input: this.modelCopy[field.name].map(item => item.value).join(', ') });
    }
  }

  addReference(name: string, label: string, multi = false, crm = false) {
    if (this.unlocked[name]) {
      this.modelCopy[name] = this.model[name] || (multi ? [] : null);
      this.changes.push({
        field: { label },
        input: multi
          ? this.model[name].map(reference => this.getSingleReferenceName(reference, crm)).join(', ')
          : this.getSingleReferenceName(this.model[name], crm)
      })
    }
  }

  getSingleReferenceName(value: any, crm = false) {
    if (crm) {
      return this.crm.getDisplayValue(value);
    }
    return value.name;
  }

  async sendUpdate() {
    await this.server.put('crm/' + this.type + '/updateMultiple?toUpdate=' + this.rows.map(row => row.id).join(','), this.modelCopy);
    this.multiUpdateOpen = false;
    this.openWarning = false;
    this.rowsChange.emit([])
  }

  toggleLock(inputName: string) {
    this.unlocked[inputName] = !this.unlocked[inputName];
    this.model[inputName] = [];
    this.cdr.detectChanges();
    delete this.model[inputName];
    this.controls[inputName]?.reset();

    if (
      this.toggleDependencies[this.type] && this.toggleDependencies[this.type][inputName] &&
      this.unlocked[inputName] != this.unlocked[this.toggleDependencies[this.type][inputName]]
    ) {
      this.toggleLock(this.toggleDependencies[this.type][inputName]);
    }
    this.checkAnyUnlocked();
  }

  checkAnyUnlocked() {
    this.anyUnlocked = Object.values(this.unlocked).some(value => value);
  }

  async getCustomers(event?: string) {
    this.comboLoading = true;

    const res = await this.server.get('crm/customer/combobox/' + this.crm.activeViews.customer.id  + '/' + event);

    this.customers = plainToClass(Customer, res as unknown) as any;
    this.customers = this.customers.filter((customer) => {

      return !this.model.customer || customer.id != this.model.customer.id
    });
    this.comboLoading = false;
  }

  async customerChanged(event: any[]) {
    if (event) {
      this.selectedCustomer = event.pop();
      this.selectedCustomerList = [this.selectedCustomer]
      if (this.selectedCustomer.id != this.model.customer?.id) {
        this.model.customer = this.selectedCustomer;
      }
    } else {
      this.selectedCustomer = undefined;
      this.model.customer = this.selectedCustomer;
      this.selectedCustomerList = []
    }
  }

  onChangeProduct(event) {
    this.getProducts();
  }

  async getProducts(event?: string) {
    this.comboLoading = true;
    const res = await this.server.get('crm/product' + (event ? '?name=' + event : ''))
    this.products = plainToClass(Product, res.rows).filter((product) => {
      return !this.model.products || !this.model.products.find(callProduct => callProduct.id == product.id)
    });
    this.comboLoading = false;
  }

  documentQuery() {
    if (this.model.products) {
      return this.model.products.map(product => product.id).join(',')
    } else {
      return '';
    }
  }

  async getUsers(event?: string) {
    this.comboLoading = true;
    const res = await this.server.get('user' + (event ? '?name=' + event : ''))
    this.users = plainToClass(User, res.rows).filter((user) => {
      return !this.model?.users || !this.model.users.find(callUser => callUser.id == user.id)
    });
    this.comboLoading = false;
  }

  getFieldWidth() {
    if (this.changes.length > 0) {
      const changesCopy = [...this.changes];
      const longestLabel = changesCopy.sort((a, b) => b.field.label.length - a.field.label.length)[0].field.label;
      const div = document.getElementById('label-' + longestLabel);
      if (div?.clientWidth) {
        return 'calc(1.4rem + ' + div.clientWidth + 'px)';
      }
    }
    return '2px';
  }

}
