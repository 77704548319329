<div class="filter" fxLayout="row wrap" fxLayoutAlign="left center">
    <div class="clr-control-label" style="margin-right: 2rem;">{{localization.dictionary.dashboard.timeFilter}}</div>
    <form clrForm #fromDateForm="ngForm" class="clr-form calenderForm" clrLayout="horizontal">
        <clr-date-container class="dateInputContainer">
            <label class="clr-control-label" for="fromDate">{{localization.dictionary.dashboard.timeFrom}}</label>
            <input type="date" clrDate [(clrDate)]="fromDate"
                [attr.format]="localization.dictionary.dateFormat.date"
                (clrDateChange)="sendUpdate()" [max]="maxDate()"
                (keyup.enter)="open = false" #searchFrom name="fromDate" autocomplete="off" />
        </clr-date-container>
    </form>
    <form clrForm #toDateForm="ngForm" class="clr-form-compact calenderForm">
        <clr-date-container class="dateInputContainer">
            <label class="clr-control-label" for="toDate">{{localization.dictionary.dashboard.timeTo}}</label>
            <input type="date" clrDate [(clrDate)]="toDate"
                [attr.format]="localization.dictionary.dateFormat.date"
                (clrDateChange)="sendUpdate()" [min]="minDate()"
                (keyup.enter)="open = false" #searchto name="to" autocomplete="off" />
        </clr-date-container>
    </form>
    <button class="btn btn-sm btn-primary" (click)="sendUpdate()"
        [disabled]="!fromDateForm.valid || !toDateForm.valid">{{localization.dictionary.button.filter}}</button>
    <button *ngIf="fromDate || toDate" class="btn btn-sm btn-outline" (click)="clear()">{{localization.dictionary.button.reset}}</button>
</div>