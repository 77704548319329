import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { ExcelService } from '../excel.service';
import { AmselError } from '../helper/error/amsel-error.model';
import { ErrorService } from '../helper/error/error.service';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { Call } from './calls/call.model';
import { Contact } from './contacts/contact.model';
import { Customer } from './customers/customer.model';
import { Field } from './fields/field.model';
import { View } from './views/view.model';
import { User } from '../users/user.model';

@Injectable({
  providedIn: 'root'
})
export class CrmService {
  types = ['customer', 'contact', 'call'];

  fields: {
    customer: Field[],
    contact: Field[],
    call: Field[]
  } = {
    customer: [],
    contact: [],
    call: []
  }

  displayFields: {
    customer: string[],
    contact: string[],
    call: string[],
  } = {
    customer: [],
    contact: [],
    call: [],
  }

  sortField: {
    customer: string,
    contact: string,
    call: string
  } = {
    customer: 'value',
    contact: 'value',
    call: 'value'
  }

  activeViews: {
    customer: View,
    contact: View,
    call: View
  } = {
    customer: undefined,
    contact: undefined,
    call: undefined
  }

  views: {
    customer: View[],
    contact: View[],
    call: View[]
  } = {
    customer: [],
    contact: [],
    call: []
  }
  multiUpdateOpen = false;

  constructor(private server: ServerService,
              private errorService: ErrorService,
              private excelService: ExcelService,
              private localization: LocalizationService
  ) { }

  /**
   * Initialisiert den CRM Services: lädt alle Felder, Views und die aktiven / ausgewählten Views
   */
  async init() {
    await this.setFields();
    await this.setViews();
    await this.setActiveViewsFromStorage();
  }

  /**
   * Lädt Felder vom Server für anzugebende Tabellen
   * @param types Array mit zu ladenden Tabellen ('customer' | 'contact' | 'call')
   */
  async setFields(types = this.types) {
    for (let type of types) {
      let res = await this.server.get('crm/field/byTable/' + type);
      this.fields[type] = plainToClass(Field, res.rows);
    }
  }

  /**
   * Lädt Ansichten vom Server für anzugebende Tabllen
   * @param types Array mit zu ladenden Tabellen ('customer' | 'contact' | 'call')
   */
  async setViews(types = this.types) {
    for (let type of types) {
      let res = await this.server.get('crm/view/type/'+ type);
      this.views[type] = plainToClass(View, res.rows);
    }
  }

  activeViewChanged(view:View, table: 'call' | 'customer' | 'contact'){
    this.activeViews[table] = view;
  }

  /**
   * Lädt die aktive/ausgewählte Ansicht des Users aus dem LocalStorage, setzt ansonsten die erste View
   * @param types Array mit zu ladenden Tabellen ('customer' | 'contact' | 'call')
   */
  async setActiveViewsFromStorage(types = this.types) {
    for (let type of types) {
      const localView = localStorage.getItem(type + '-active-view');
      let activeView: View;
      if (localView){
        try {
          let viewJSON = JSON.parse(localView);
          activeView = this.views[type].find(view => view.id == viewJSON.id);
        } catch (err) {
          //console.log('fehler beim Lande der views: ', err);
        }
      }
      if (!activeView && this.views[type].length > 0) {
        activeView = this.views[type][0];
      } else if (!activeView) {
        let errorType = this.localization.dictionary[type].name;
        this.errorService.addError(new AmselError(undefined, 'danger',
          this.localization.dictionary.toastr.noView.replace('${componentName}', errorType))
        );
      }
      this.activeViews[type] = activeView;
      await this.setDisplayFields([type]);
    }
  }

  /**
   * Setzt die Namen der Felder in sortierter Reihenfolge (je nach aktivem View) als string Array
   * @param types Array mit zu ladenden Tabellen ('customer' | 'contact' | 'call')   *
   */
  async setDisplayFields(types = this.types) {
    for (let type of types) {
      this.displayFields[type] = []
      delete this.sortField[type];
      if (!this.activeViews[type] || !this.activeViews[type].sections) {
        continue;
      }
      for (let section of this.activeViews[type].sections){
        let fieldArr = section.fields
          .filter(field => field.display);
        if (fieldArr && fieldArr.length > 0) {
          this.displayFields[type].push(...fieldArr.map( (field) => field.name));
          if (!this.sortField[type]) {
            this.sortField[type] = fieldArr[0].id;
          }
        }
      }
    }
  }

  /**
   * Hilfsfunktion, um DisplayValue eines gegebenen Objekts anzuzeigen. Für Rendering in HTML bitte display-name Component nutzen!
   * @param obj Objekt, wessen Displayvalue angezeigt werden soll
   * @returns Anzuzeigenden Wert des Objektes
   */
  getDisplayValue(obj: Call | Contact | Customer | User): string {
    let type;
    if (obj instanceof Call) {
      type = 'call';
    } else if (obj instanceof Contact) {
      type = 'contact';
    } else if (obj instanceof User) {
      type = 'user';
      return obj.name;
    } else {
      type = 'customer';
    }
    let outArr = [];
    for (let fieldName of this.displayFields[type]) {
      if (obj[fieldName]) {
        outArr.push(obj[fieldName][0].value)
      } else if (obj['fieldValues']) {
        let value = obj['fieldValues'].find( (fv) => fv.field?.name == fieldName)?.value;
        if (value) {
          outArr.push(value);
        }
      }
    }
    return outArr.join(' ');
  }

  /**
   * Gibt gespeicherte Filter, Sortierung und ausgeblendete Spalten zurück
   * @param type Tabellenname
   * @returns
   */
  getDatagridOptions(type: 'contact' | 'call' | 'customer' | 'audit-log') {
    const filter = localStorage.getItem(type + '-filters');
    const sorting = localStorage.getItem(type + '-sorting');
    const hidden = localStorage.getItem(type + '-columns');
    let out = {
      filters: {},
      sorting: {},
      hidden: {
        id: true,
        createdAt: true,
        updatedAt: true,
        createdBy: true,
        updatedBy: true,
        deletedBy: true,
      }
    };

    if (filter) {
      out.filters = JSON.parse(filter);
    }
    if (sorting) {
      out.sorting = JSON.parse(sorting);
    }
    if (hidden) {
      out.hidden = JSON.parse(hidden);
    }
    return out;
  }

  async downloadExcel(type: 'contact' | 'call' | 'customer', query: string, showDeleted: boolean) {
    const typeListe = this.localization.dictionary[type].name;
    let activeViewIds = [ this.activeViews.customer.id, this.activeViews.contact.id, this.activeViews.call.id ];
    let buffer = await this.server.download('crm/' + type + '/excel' + (showDeleted ? '/all/' : '/') + activeViewIds + '/' + this.localization.language.id + query);
    let excelName = typeListe + '_' + new Date().toLocaleDateString();
    this.excelService.downloadExcel(buffer, excelName);
  }

  async downloadExcelTemplate(type: 'contact' | 'call' | 'customer'| 'view', views? : View[]) {
    let buffer = null;
    let typeListe = '';
    if(type != 'view'){
    typeListe = this.localization.dictionary[type].name + '_';
    let activeViewIds = [ this.activeViews.customer.id, this.activeViews.contact.id, this.activeViews.call.id ];

    let activeViewId = activeViewIds.find( (id) => id == this.activeViews[type].id);

     buffer = await this.server.download('crm/' + type + '/excel/template/' + activeViewId + '/' + this.localization.language.id);
    }else{
      for (let view of views) {
        typeListe += view.name + '_';
      }

      buffer = await this.server.download('crm/customer/excel/initialTemplate/' + views.map(view => view.id) + '/' + this.localization.language.id);
    }
     let excelName = typeListe + 'Template';
     if (buffer){
      this.excelService.downloadExcel(buffer, excelName);
     }

  }

  getView(type: 'contact' | 'call' | 'customer') {
    let activeView: View;
    if (type === 'contact') {
      activeView = this.activeViews.contact;
    } else if (type === 'call') {
      activeView = this.activeViews.call;
    } else {
      activeView = this.activeViews.customer;
    }

    return activeView;
  }

  async checkForTrackableFields(entityType: 'Call' | 'Customer' | 'Contact'): Promise<boolean> {
    try {
      const response = await this.server.get<{ hasTrackableFields: boolean }, false>(`crm/field/has-trackable-fields?entityType=${entityType}`);
      return response.hasTrackableFields;
    } catch (error) {
      console.error('Error checking for trackable fields:', error);
      return false;
    }
  }

}
