<p>Im Bereich 'Kunden' können Sie die Stammdaten der Leads oder bestehender Kunden verwalten. Über den Punkt Felder (siehe Kapitel CRM > Felder) können Sie Eingabefelder neu anlegen und über Ansichten können Sie diese individuell für die Erfassung der Stammdaten zusammenstellen (siehe Kapitel CRM > Ansichten).</p>
<h4>Neuen Kunden erstellen</h4>
<ol class="list">
    <li>Um einen neuen Datensatz anzulegen, klicken Sie im Menu auf CRM und wählen den Punkt Kunden aus. Dann klicken Sie auf den Button „Kunden hinzufügen“.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>
<h4>Kunden bearbeiten</h4>
<ol class="list">
    <li>Um einen Kunden zu bearbeiten, wählen Sie diesen in der Liste aus. Alternativ können Sie über Aktionen, die Bearbeitung auswählen.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>