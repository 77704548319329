import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-contact-create',
  templateUrl: './de-contact-create.component.html',
})
export class DeContactCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
