import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { ToastrModule } from 'ngx-toastr';
import { GridsterModule } from 'angular-gridster2';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';

import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { RolesComponent } from './roles/roles.component';
import { ErrorComponent } from './helper/error/error.component';
import { HomeComponent } from './home/home.component';
import { CreateEditCategoryComponent } from './categories/create-edit-category/create-edit-category.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { ListCategoriesComponent } from './categories/list-categories/list-categories.component';
import { BrandingComponent } from './branding/branding.component';
import { ListDocumentsComponent } from './documents/list-documents/list-documents.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
import { CreateEditUserComponent } from './users/create-edit-user/create-edit-user.component';
import { CreateEditDocumentComponent } from './documents/create-edit-document/create-edit-document.component';
import { CreateEditGroupComponent } from './groups/create-edit-group/create-edit-group.component';
import { ListGroupsComponent } from './groups/list-groups/list-groups.component';
import { ListSelectorComponent } from './helper/list-selector/list-selector.component';
import { TrackingComponent } from './analytics/tracking/tracking.component';
import { WidgetLoginComponent } from './analytics/widgets/widget-login/widget-login.component'
import { WidgetMailComponent } from './analytics/widgets/widget-mail/widget-mail.component';
import { WidgetUserInteractionComponent } from './analytics/widgets/widget-user-interaction/widget-user-interaction.component';
import { SettingsComponent } from './settings/settings.component';
import { BooleanComponent } from './helper/filters/boolean/boolean.component';
import { DateComponent } from './helper/filters/date/date.component';
import { NumberComponent } from './helper/filters/number/number.component';
import { StringComponent } from './helper/filters/string/string.component';
import { SpinnerComponent } from './helper/spinner/spinner.component';
import { MultiselectComponent } from './helper/filters/multiselect/multiselect.component';
import { ListFormsComponent } from './forms/list-forms/list-forms.component';
import { CreateEditFormsComponent } from './forms/create-edit-forms/create-edit-forms.component';
import { NotFoundComponent } from './helper/not-found/not-found.component';
import { WidgetQuestionComponent } from './forms/widget-question/widget-question.component';
import { EventlogComponent } from './eventlog/eventlog.component';
import { SignatureComponent } from './forms/widget-question/signature/signature.component';
import { ChoiceComponent } from './forms/widget-question/choice/choice.component';
import { TextComponent } from './forms/widget-question/text/text.component';
import { RangeComponent } from './forms/widget-question/range/range.component';
import { ShowTopicComponent } from './user-doc/show-topic/show-topic.component';
import { HelpFormsCreateComponent } from './user-doc/help-forms-create/help-forms-create.component';
import { ProfileComponent } from './users/profile/profile.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { NoPermissionComponent } from './auth/no-permission/no-permission.component';
import { ListPushNotificationsComponent } from './notifications/push/list-push-notifications/list-push-notifications.component';
import { CreateEditPushNotificationComponent } from './notifications/push/create-edit-push-notification/create-edit-push-notification.component';
import { ErrorService } from './helper/error/error.service';
import { SendModalComponent } from './notifications/push/send-modal/send-modal.component';
import { ListResultsComponent } from './forms/list-results/list-results.component';
import { ServerService } from './server.service';
import { InstallComponent } from './settings/install/install.component';
import { NoDataComponent } from './analytics/no-data/no-data.component';
import { BrandingPreviewComponent } from './branding/branding-preview/branding-preview.component';
import { ListReportsComponent } from './analytics/reports/list-reports/list-reports.component';
import { WidgetPresentationComponent } from './analytics/widgets/widget-presentation/widget-presentation.component';
import { FileNameDirective } from './helper/validators/file-name.directive';
import { EventsByOsComponent } from './analytics/reports/events-by-os/events-by-os.component';
import { FilterFromToComponent } from './analytics/filter-from-to/filter-from-to.component';
import { GermanDateDirective } from './helper/validators/german-date.directive';
import { ScrollDirective } from './helper/scroll.directive';
import { FooterWidgetsComponent } from './analytics/footer-widgets/footer-widgets.component';
import { BrandingService } from './branding/branding.service';
import { CreateEditFieldComponent } from './crm/fields/create-edit-field/create-edit-field.component';
import { ListFieldsComponent } from './crm/fields/list-fields/list-fields.component';
import { RefSelectorComponent } from './forms/widget-question/ref-selector/ref-selector.component';
import { CreateEditCustomerComponent } from './crm/customers/create-edit-customer/create-edit-customer.component';
import { ListCustomersComponent } from './crm/customers/list-customers/list-customers.component';
import { ListContactsComponent } from './crm/contacts/list-contacts/list-contacts.component';
import { CreateEditContactsComponent } from './crm/contacts/create-edit-contacts/create-edit-contacts.component';
import { CreateEditTerritoryComponent } from './crm/territories/create-edit-territory/create-edit-territory.component';
import { OverviewTerritoriesComponent } from './crm/territories/overview-territories/overview-territories.component';
import { SublistCustomersComponent } from './crm/customers/sublist-customers/sublist-customers.component';
import { SublistContactsComponent } from './crm/contacts/sublist-contacts/sublist-contacts.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { ListProductsComponent } from './crm/products/list-products/list-products.component';
import { CreateEditProductComponent } from './crm/products/create-edit-product/create-edit-product.component';
import { CreateEditCallComponent } from './crm/calls/create-edit-call/create-edit-call.component';
import { ListCallComponent } from './crm/calls/list-call/list-call.component';
import { ListViewsComponent } from './crm/views/list-views/list-views.component';
import { CreateEditViewsComponent } from './crm/views/create-edit-views/create-edit-views.component';
import { CustomInputComponent } from './helper/custom-input/custom-input.component';
import { DisplayNameComponent } from './helper/display-name/display-name.component';
import { WidgetCountComponent } from './analytics/widgets/widget-count/widget-count.component';
import { LinksComponent } from './home/links/links.component';
import { DashboardsComponent } from './analytics/dashboards/dashboards.component';
import { HelpStartComponent } from './user-doc/help-start/help-start.component';
import { WidgetCallsComponent } from './analytics/widgets/widget-calls/widget-calls.component';
import { RegexDirective } from './helper/validators/regex.directive';
import { TimeComponent } from './helper/filters/time/time.component';
import { ListConflictsComponent } from './crm/conflict/list-conflicts/list-conflicts.component';
import {NgxImageCompressService} from "ngx-image-compress";
import { DuplicateValidator } from './helper/validators/duplicate-validator';
import { LocalizationService } from './localization/localization.service';
import { WidgetCrmCustomerByTerritoryComponent } from './analytics/widgets/widget-crm-customer-by-territory/widget-crm-customer-by-territory.component';
import { WidgetCrmCallByUserComponent } from './analytics/widgets/widget-crm-call-by-user/widget-crm-call-by-user.component';
import { WidgetCrmCallByProductComponent } from './analytics/widgets/widget-crm-call-by-product/widget-crm-call-by-product.component';
import { WidgetCrmCallByTerritoryComponent } from './analytics/widgets/widget-crm-call-by-territory/widget-crm-call-by-territory.component';
import { CrmService } from './crm/crm.service';
import { ListLanguagesComponent } from './localization/list-languages/list-languages.component';
import { CreateEditLanguageComponent } from './localization/create-edit-language/create-edit-language.component';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import {DropdownModule} from 'primeng/dropdown';
import { ComboboxComponent } from './helper/filters/combobox/combobox.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StickyFooterDirective } from './helper/directives/stickyfooter.directive';
import { FileUploadListComponent } from './crm/file-upload/file-upload-list/file-upload-list.component';
import { FileUploadModalComponent } from './crm/file-upload/file-upload-modal/file-upload-modal.component';
import { DeHelpComponent } from './user-doc/german/de-help/de-help.component';
import { DeFormsCreateComponent } from './user-doc/german/de-forms-create/de-forms-create.component';
import { EnFormsCreateComponent } from './user-doc/english/en-forms-create/en-forms-create.component';
import { EnHelpComponent } from './user-doc/english/en-help/en-help.component';
import { DeCatCreateComponent } from './user-doc/german/de-cat-create/de-cat-create.component';
import { DeCatEditComponent } from './user-doc/german/de-cat-edit/de-cat-edit.component';
import { DeCatLinkComponent } from './user-doc/german/de-cat-link/de-cat-link.component';
import { DeListComponent } from './user-doc/german/de-list/de-list.component';
import { DeCatComponent } from './user-doc/german/de-cat/de-cat.component';
import { DeHelpNotfoundComponent } from './user-doc/german/de-help-notfound/de-help-notfound.component';
import { DeActionsEditComponent } from './user-doc/german/de-actions-edit/de-actions-edit.component';
import { DeActionsDeleteComponent } from './user-doc/german/de-actions-delete/de-actions-delete.component';
import { DeActionsComponent } from './user-doc/german/de-actions/de-actions.component';
import { DeActionsDownloadComponent } from './user-doc/german/de-actions-download/de-actions-download.component';
import { DeActionsLinkComponent } from './user-doc/german/de-actions-link/de-actions-link.component';
import { DeActionsUnlinkComponent } from './user-doc/german/de-actions-unlink/de-actions-unlink.component';
import { DeDocumentComponent } from './user-doc/german/de-document/de-document.component';
import { DeDocumentCreateComponent } from './user-doc/german/de-document-create/de-document-create.component';
import { DeDocumentEditComponent } from './user-doc/german/de-document-edit/de-document-edit.component';
import { DeActionsOrderComponent } from './user-doc/german/de-actions-order/de-actions-order.component';
import { DeFormsComponent } from './user-doc/german/de-forms/de-forms.component';
import { DeActionsShowresultsComponent } from './user-doc/german/de-actions-showresults/de-actions-showresults.component';
import { DeActionsDeactivateComponent } from './user-doc/german/de-actions-deactivate/de-actions-deactivate.component';
import { DeUserComponent } from './user-doc/german/de-user/de-user.component';
import { DeUserCreateComponent } from './user-doc/german/de-user-create/de-user-create.component';
import { DeGroupsComponent } from './user-doc/german/de-groups/de-groups.component';
import { DeGroupsCreateComponent } from './user-doc/german/de-groups-create/de-groups-create.component';
import { DePushComponent } from './user-doc/german/de-push/de-push.component';
import { DeLanguageComponent } from './user-doc/german/de-language/de-language.component';
import { DeLanguageCreateComponent } from './user-doc/german/de-language-create/de-language-create.component';
import { DeActionsUploadComponent } from './user-doc/german/de-actions-upload/de-actions-upload.component';
import { DeBrandingComponent } from './user-doc/german/de-branding/de-branding.component';
import { DePushCreateComponent } from './user-doc/german/de-push-create/de-push-create.component';
import { DeCustomerComponent } from './user-doc/german/de-customer/de-customer.component';
import { DeCustomerCreateComponent } from './user-doc/german/de-customer-create/de-customer-create.component';
import { DeContactComponent } from './user-doc/german/de-contact/de-contact.component';
import { DeContactCreateComponent } from './user-doc/german/de-contact-create/de-contact-create.component';
import { DeCallComponent } from './user-doc/german/de-call/de-call.component';
import { DeCallCreateComponent } from './user-doc/german/de-call-create/de-call-create.component';
import { DeCallEditComponent } from './user-doc/german/de-call-edit/de-call-edit.component';
import { DeProductComponent } from './user-doc/german/de-product/de-product.component';
import { DeProductCreateComponent } from './user-doc/german/de-product-create/de-product-create.component';
import { DeProductEditComponent } from './user-doc/german/de-product-edit/de-product-edit.component';
import { DeTerritoryComponent } from './user-doc/german/de-territory/de-territory.component';
import { DeTerritoryCreateComponent } from './user-doc/german/de-territory-create/de-territory-create.component';
import { DeCustomerEditComponent } from './user-doc/german/de-customer-edit/de-customer-edit.component';
import { DeContactEditComponent } from './user-doc/german/de-contact-edit/de-contact-edit.component';
import { DeTerritoryEditComponent } from './user-doc/german/de-territory-edit/de-territory-edit.component';
import { DeConflictComponent } from './user-doc/german/de-conflict/de-conflict.component';
import { DeFieldComponent } from './user-doc/german/de-field/de-field.component';
import { DeFieldCreateComponent } from './user-doc/german/de-field-create/de-field-create.component';
import { DeViewComponent } from './user-doc/german/de-view/de-view.component';
import { DeViewCreateComponent } from './user-doc/german/de-view-create/de-view-create.component';
import { DeViewEditComponent } from './user-doc/german/de-view-edit/de-view-edit.component';

import { DeActionsAddComponent } from './user-doc/german/de-actions-add/de-actions-add.component';
import { MultiUpdateCrmComponent } from './helper/multi-update-crm/multi-update.component-crm';
import { AuditComponent } from './audit/audit.component';
import { InteractionCustomerComponent } from './crm/customers/interaction-customer/interaction-customer.component';
import { DiagramPopupComponent } from './analytics/dashboards/diagram-popup/diagram-popup.component';
import { TooltipDirective } from './tooltip.directive';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomDateFormatter } from './calendar/calendar-date-formatter';
import { AddCustomerContactComponent } from './crm/customers/add-customer-contact/add-customer-contact.component';
import { SettingService } from './settings/setting.service';

registerLocaleData(localeDe);

function getSettings(settings: SettingService, server: ServerService, branding: BrandingService, crm: CrmService, localization:  LocalizationService) {
  return async () => {      
    await settings.loadConfig();
    server.uri = settings.getConfig('url') + 'api/';
    await settings.update();    
    await branding.applyTheme();
    await localization.init();
    if (settings.settings.find( (setting) => setting.selector == 'crmActive').value == 'true') {
      try {
        await crm.init()
      } catch(err) {
        
      }
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RolesComponent,
    ErrorComponent,
    HomeComponent,
    CreateEditCategoryComponent,
    SidenavComponent,
    HeaderComponent,
    ListCategoriesComponent,
    BrandingComponent,
    BooleanComponent,
    ListDocumentsComponent,
    ListUsersComponent,
    CreateEditUserComponent,
    CreateEditDocumentComponent,
    CreateEditGroupComponent,
    ListGroupsComponent,
    ListSelectorComponent,
    TrackingComponent,
    WidgetLoginComponent,
    WidgetMailComponent,
    WidgetUserInteractionComponent,
    SettingsComponent,
    SpinnerComponent,
    MultiselectComponent,
    NumberComponent,
    StringComponent,
    DateComponent,
    ListFormsComponent,
    CreateEditFormsComponent,
    NotFoundComponent,
    WidgetQuestionComponent,
    EventlogComponent,
    SignatureComponent,
    ChoiceComponent,
    TextComponent,
    RangeComponent,
    ShowTopicComponent,
    HelpFormsCreateComponent,
    ProfileComponent,
    NoPermissionComponent,
    ListPushNotificationsComponent,
    CreateEditPushNotificationComponent,
    SendModalComponent,
    ListResultsComponent,
    InstallComponent,
    NoDataComponent,
    BrandingPreviewComponent,
    ListReportsComponent,
    WidgetPresentationComponent,
    FileNameDirective,
    DuplicateValidator,
    EventsByOsComponent,
    FilterFromToComponent,
    GermanDateDirective,
    ScrollDirective,
    FooterWidgetsComponent,
    CreateEditFieldComponent,
    ListFieldsComponent,
    RefSelectorComponent,
    CreateEditCustomerComponent,
    ListCustomersComponent,
    ListContactsComponent,
    CreateEditContactsComponent,
    CreateEditTerritoryComponent,
    OverviewTerritoriesComponent,
    SublistCustomersComponent,
    SublistContactsComponent,
    ListProductsComponent,
    CreateEditProductComponent,
    CreateEditCallComponent,
    ListCallComponent,
    ListViewsComponent,
    CreateEditViewsComponent,
    CustomInputComponent,
    DisplayNameComponent,
    WidgetCountComponent,
    LinksComponent,
    DashboardsComponent,
    HelpStartComponent,
    WidgetCallsComponent,
    RegexDirective,
    TimeComponent,
    ListConflictsComponent,
    WidgetCrmCustomerByTerritoryComponent,
    WidgetCrmCallByUserComponent,
    WidgetCrmCallByProductComponent,
    WidgetCrmCallByTerritoryComponent,
    ListLanguagesComponent,
    CreateEditLanguageComponent,
    ComboboxComponent,
    StickyFooterDirective,
    FileUploadListComponent,
    FileUploadModalComponent,
    InteractionCustomerComponent,
    DeHelpComponent,
    DeFormsCreateComponent,
    EnFormsCreateComponent,
    EnHelpComponent,
    DeCatCreateComponent,
    DeCatEditComponent,
    DeCatLinkComponent,
    DeListComponent,
    DeCatComponent,
    DeHelpNotfoundComponent,
    DeActionsEditComponent,
    DeActionsDeleteComponent,
    DeActionsComponent,
    DeActionsDownloadComponent,
    DeActionsLinkComponent,
    DeActionsUnlinkComponent,
    DeDocumentComponent,
    DeDocumentCreateComponent,
    DeDocumentEditComponent,
    DeActionsOrderComponent,
    DeFormsComponent,
    DeActionsShowresultsComponent,
    DeActionsDeactivateComponent,
    DeUserComponent,
    DeUserCreateComponent,
    DeGroupsComponent,
    DeGroupsCreateComponent,
    DePushComponent,
    DeLanguageComponent,
    DeLanguageCreateComponent,
    DeActionsUploadComponent,
    DeBrandingComponent,
    DePushCreateComponent,
    DeCustomerComponent,
    DeCustomerCreateComponent,
    DeContactComponent,
    DeContactCreateComponent,
    DeCallComponent,
    DeCallCreateComponent,
    DeCallEditComponent,
    DeProductComponent,
    DeProductCreateComponent,
    DeProductEditComponent,
    DeTerritoryComponent,
    DeTerritoryCreateComponent,
    DeCustomerEditComponent,
    DeContactEditComponent,
    DeTerritoryEditComponent,
    DeConflictComponent,
    DeFieldComponent,
    DeFieldCreateComponent,
    DeViewComponent,
    DeViewCreateComponent,
    DeViewEditComponent,
    DeActionsAddComponent,
    MultiUpdateCrmComponent,
    AuditComponent,
    AddCustomerContactComponent,
    DiagramPopupComponent,
    TooltipDirective,
    CalendarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    DragDropModule,
    ColorPickerModule,
    ClarityModule,
    ToastrModule.forRoot(),
    OrganizationChartModule,
    TableModule,
    GridsterModule,
    SliderModule,
    InputTextModule,
    TooltipModule,
    ProgressBarModule,
    DropdownModule,
    NgxChartsModule, 
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
  providers: [
    Title,
    NgxImageCompressService,    
    {
      provide: APP_INITIALIZER,
      useFactory: getSettings,
      deps: [SettingService, ServerService, BrandingService, CrmService, LocalizationService],
      multi: true,
    },
    
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
