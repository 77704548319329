import { Expose, Type } from "class-transformer";
import { User } from "../users/user.model";

export class AuditLog {
  @Expose()
  id: string;

  @Expose()
  fieldId: string;

  @Expose()
  fieldName: string;

  @Expose()
  entityId: string;

  @Expose()
  oldValue: string;

  @Expose()
  newValue: string;

  @Expose()
  timestamp: Date;

  @Expose()
  @Type(() => User)
  user: User;

  @Expose()
  userName: string;
}
