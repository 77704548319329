import { Exclude, Expose, Type } from 'class-transformer';
import * as uuid from 'uuid';
import { CategoryDocument } from '../categories/category-document.model';
import { Category } from '../categories/category.model';
import { Product } from '../crm/products/product.model';
import { Form } from '../forms/form.model';
import { Group } from '../groups/group.model';
import { MetaData } from '../helper/meta-data.class';
import { DocumentType } from './documenttype';

@Exclude()
export class Document extends MetaData {

    constructor(categories?: Category[], groups?: Group[]) {
        super();
        if (categories) this.categories = categories;
        if (groups) this.groups = groups;
    }
    @Expose()
    id: string = uuid.v4();

    @Expose()
    name: string;

    @Expose()
    description: string;

    @Expose()
    filename: string;

    @Expose()
    icon: string;

    @Expose()
    isActive: boolean = true;

    @Expose()
    type: DocumentType = DocumentType.FILE;

    @Expose()
    viewType: 'single' | 'multiple' | 'book' = 'multiple';

    @Expose()
    form: Form;

    @Expose()
    formId: string;

    @Expose()
    shareable: boolean = true;

    @Expose()
    users: any[] = [];

    @Expose()
    groups: Group[] = [];

    @Expose()
    products: Product[] = [];

    @Expose()
    categories: Category[] = [];

    @Expose()
    CategoryDocument: CategoryDocument = new CategoryDocument();

    @Expose()
    @Type(() => Date)
    createdAt: Date;

    @Expose()
    @Type(() => Date)
    updatedAt: Date;

    @Expose()
    @Type(() => Date)
    deletedAt: Date;
}