import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { LocalizationService } from '../../../localization/localization.service';


@Component({
  selector: 'date-filter',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent implements ClrDatagridFilterInterface<any>, OnChanges {

  @Input() property: string;
  @Input() confirm = false
  @Input() filterFrom: Date;
  @Output() filterFromChange = new EventEmitter<Date>()
  @Input() filterTo: Date;
  @Output() filterToChange = new EventEmitter<Date>()
  changes = new Subject<any>();
  open = false
  focus = true

  activeTo = false
  activeFrom = false

  textQueryChanged: Subject<string> = new Subject<string>();

  value: { value: Date, type: string };
  value2: { column: string, value: Date, type: string };

  @ViewChild("searchFrom") inputFromRef: ElementRef<HTMLInputElement>
  @ViewChild("searchTo") inputToRef: ElementRef<HTMLInputElement>

  constructor(private filterContainer: ClrDatagridFilter, public localization: LocalizationService) {
    filterContainer.setFilter(this);
  }


  document = document

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.open && this.focus && (this.inputFromRef || this.inputToRef)) {
      this.focus = false
      setTimeout(() => {
        if (this.inputFromRef)
          this.inputFromRef.nativeElement.focus()
        else
        this.inputToRef.nativeElement.focus()
      })
    }
    if (!this.open && !this.focus) {
      this.focus = true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "filterFrom" && !this.filterFrom) {
          this.value = undefined
          this.activeFrom = true
        }
        if (propName == "filterFrom" && this.filterFrom) {
          if(!this.value)
            this.value = { value: this.filterFrom, type: 'from' };
          this.value.value = this.filterFrom
          this.activeFrom = true
        }
        if (propName == "filterTo" && !this.filterTo) {
          this.value2 = undefined
          this.activeTo = true
        }
        if (propName == "filterTo" && this.filterTo) {
          if(!this.value2)
            this.value2 = { column: this.property, value: this.filterTo, type: 'to' };
          this.value2.value = this.filterTo
          this.activeTo = true
        }
      }
    }
  }

  onChange() {
    if (this.confirm)
      return
    this.setValue()
  }

  onClick(reset?: boolean) {
    if (reset) {
      this.filterFrom = undefined
      this.filterTo = undefined
    }
    this.setValue()
    this.open = false;
    return false
  }

  setValue() {
    if (this.filterFrom)
      this.value = { value: new Date(this.filterFrom), type: "from" };
    this.filterFromChange.emit(this.filterFrom)
    if (this.filterTo) {
      const toDate = new Date(this.filterTo)
      toDate.setDate(toDate.getDate() + 1)
      this.value2 = { column: this.property, value: toDate, type: "to" };
    }
    this.filterToChange.emit(this.filterTo)
    this.changes.next('')
  }

  isActive(): boolean {
    return (this.confirm ? !!this.value || !!this.value2 : !!this.filterFrom || !!this.filterTo);
  }
  accepts(): boolean {
    return true
  }
}
