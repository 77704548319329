<clr-modal [(clrModalOpen)]="multiUpdateOpen" (clrModalOpenChange)="multiUpdateOpenChange.emit(false); unlocked = {}" clrModalSize="xl">
    <h3 class="modal-title">{{localization.dictionary.list.updateMultiTitle}}</h3>
    <div class="modal-body">
        <div class="card-block" *ngFor="let section of activeView?.sections">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon [attr.shape]="section.icon" class="clr-icon-heading" role="img"></clr-icon>
                    <span>{{ section.name }}</span>
                    <p class="card-text icon-offset">
                        {{ section.description }}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[section['id']] ? 'down' : 'left'"
                    (click)="collapse[section['id']] = !collapse[section['id']]"></clr-icon>
            </div>
            <div [hidden]="collapse[section['id']]" class="card-text">
                <app-custom-input [model]="model" [fields]="section.fields" (change)="dirty = true" [multi]="true" [unlocked]="unlocked" (unlockedChange)="checkAnyUnlocked()"
                    (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)">
                </app-custom-input>
            </div>
        </div>
        <ng-container *ngIf="type == 'customer'">
            <div class="card-block" >
                <div class="card-title collapse-card">
                    <span>
                        <clr-icon shape="map" class="clr-icon-heading"></clr-icon>
                        <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.territory.nameSingular)}}</span>
                        <p class="card-text icon-offset">
                            {{localization.dictionary.customerCreateEdit.assignTerDescription}}
                        </p>
                    </span>
                    <clr-icon shape="angle" class="arrow" [dir]="!collapse['territory'] ? 'down' : 'left'"
                        (click)="collapse['territory'] = !collapse['territory']"></clr-icon>
                </div>
                <div [hidden]="collapse['territory']" class="card-text">
                    <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                        <div class="single-input-wrapper" [class.edit]="unlocked['territory']">
                            <clr-icon (click)="toggleLock('territory')" [attr.shape]="unlocked['territory'] ? 'undo' : 'pencil'"></clr-icon>
                            <clr-combobox-container>
                                <label>{{localization.dictionary.territory.nameSingular}}*</label>
    
                                <clr-combobox [disabled]="!unlocked['territory']" [(ngModel)]="model.territory" name="territory" required>
                                    <clr-options>
                                        <clr-option *clrOptionItems="let territory of territories; field:'name'"
                                            [clrValue]="territory">
                                            <div [pTooltip]="territory.description" [tooltipPosition]="'right'">
                                                {{ territory.name }}
                                            </div>
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-block">
                <div class="card-title collapse-card">
                    <span>
                        <clr-icon shape="briefcase" class="clr-icon-heading"></clr-icon>
                        <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.product.nameSingular)}}</span>
                        <p class="card-text icon-offset">
                            {{localization.dictionary.customerCreateEdit.assignProdDescription}}
                        </p>
                    </span>
                    <clr-icon shape="angle" class="arrow" [dir]="!collapse['products'] ? 'down' : 'left'"
                        (click)="collapse['products'] = !collapse['products']"></clr-icon>
                </div>
                <div [hidden]="collapse['products']" class="card-text icon-offset">
                    <div class="single-input-wrapper" [class.edit]="unlocked['products']">
                        <clr-icon (click)="toggleLock('products')" [attr.shape]="unlocked['products'] ? 'undo' : 'pencil'" style="top: 1.4rem;"></clr-icon>
                        <app-list-selector [excludeAdmin]="true" [preLoaded]="model.products"
                        (selectedChanged)="model.products = $event" [disabled]="!unlocked['products']"
                            [label]="localization.dictionary.product.name" [query]="'crm/product'">
                        </app-list-selector>
                    </div>
                </div>

            </div>
        </ng-container>
        <ng-container *ngIf="type == 'call'">
            <div class="card-block">
                <div class="card-title collapse-card">
                    <span>
                        <clr-icon shape="employee-group" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.callCreateEdit.customerContact}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.callCreateEdit.contactPerson}}
                    </p>
                    </span>
                    <clr-icon shape="angle" class="arrow" [dir]="!collapse['customer'] ? 'down' : 'left'"
                (click)="collapse['customer'] = !collapse['customer']"></clr-icon>
                </div>
                <div [hidden]="collapse['customer']" class="card-text">
                    <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                        <div class="single-input-wrapper" [class.edit]="unlocked['customer']">
                            <clr-icon (click)="toggleLock('customer')" [attr.shape]="unlocked['customer'] ? 'undo' : 'pencil'"></clr-icon>
                            <clr-combobox-container>
                                <label>  {{localization.dictionary.customer.nameSingular}}*</label>
            
                                <clr-combobox #customerbox [ngModel]="selectedCustomerList" [disabled]="!unlocked['customer']"
                                    (ngModelChange)="customerbox.searchText = ''; customerChanged($event)" name="customer" required [clrLoading]="comboLoading"
                                    (clrInputChange)="customerQueryChanged.next($event)" (clrOpenChange)="customerQueryChanged.next('')" clrMulti="true">
                                    <ng-container *clrOptionSelected="let selected">
                                        <app-display-name style="cursor: pointer;" [model]="selected" [prePostFix]="''"></app-display-name>
                                    </ng-container>
                                    <clr-options>
                                        <clr-option *ngFor="let customer of customers" [clrValue]="customer">
                                            <app-display-name [model]="customer" [prePostFix]="''"></app-display-name>
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
                        </div>
        
                        <div *ngIf="model.customer && selectedCustomer" class="single-input-wrapper" [class.edit]="unlocked['contacts']">
                            <clr-icon (click)="toggleLock('contacts')" [attr.shape]="unlocked['contacts'] ? 'undo' : 'pencil'" style="top: 1.4rem;"></clr-icon>
                            <app-list-selector [property]="'contacts'"  [query]="'crm/contact/filter?customers.id='+selectedCustomer.id"
                                [displayFields]="[]" [disabled]="!unlocked['contacts']"
                                [preLoaded]="model.contacts"
                                (selectedChanged)="model.contacts = $event"
                                [label]="localization.dictionary.contact.name" required="true"
                                (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)">
                            </app-list-selector>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-block">
                <div class="card-title collapse-card">
                    <span>
                        <clr-icon shape="view-cards" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.callCreateEdit.productsDocuments}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.callCreateEdit.presentedDocsProducts}}
                    </p>
                    </span>
                    <clr-icon shape="angle" class="arrow" [dir]="!collapse['product'] ? 'down' : 'left'"
                (click)="collapse['products'] = !collapse['products']"></clr-icon>
                </div>
                <div [hidden]="collapse['products']" class="card-text ">
                    <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                        <div class="single-input-wrapper" [class.edit]="unlocked['products']">
                            <clr-icon (click)="toggleLock('products')" [attr.shape]="unlocked['products'] ? 'undo' : 'pencil'"></clr-icon>
                            <clr-combobox-container>
                                <label>{{localization.dictionary.product.name}}</label>
                                <clr-combobox #prodbox (ngModelChange)="prodbox.searchText = ''; onChangeProduct($event)" [disabled]="!unlocked['products']"
                                    [clrLoading]="comboLoading" [(ngModel)]="model.products" name="products" clrMulti="true"
                                    (clrInputChange)="getProducts($event)" (clrOpenChange)="$event ? getProducts() : null">
                                    <ng-container *clrOptionSelected="let selected">
                                        {{selected?.name}}
                                    </ng-container>
                                    <clr-options>
                                        <clr-option *clrOptionItems="let product of products" [clrValue]="product">
                                            {{ product.name }}
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
                        </div>
                        
                        <div *ngIf="model.products && model.products.length > 0" class="single-input-wrapper" [class.edit]="unlocked['documents']">
                            <clr-icon (click)="toggleLock('documents')" [attr.shape]="unlocked['documents'] ? 'undo' : 'pencil'" style="top: 1.4rem;"></clr-icon>
                            <app-list-selector
                                #documentList [query]="'document/byProductIds?ids='+documentQuery()" [disabled]="!unlocked['documents']"
                                [attrToShow]="'name'" (selectedChanged)="model.documents = $event"
                                [preLoaded]="model.documents"
                                [label]="localization.dictionary.document.name" 
                                (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)">
                            </app-list-selector>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-block">
                <div class="card-title collapse-card">
                    <span>
                        <clr-icon shape="users" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.user.name}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.callCreateEdit.listOfUserByCall}}
                    </p>
                    </span>
                    <clr-icon shape="angle" class="arrow" [dir]="!collapse['users'] ? 'down' : 'left'"
                (click)="collapse['users'] = !collapse['users']"></clr-icon>
                </div>
                <div [hidden]="collapse['users']" class="card-text">
                    <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                        <div class="single-input-wrapper" [class.edit]="unlocked['users']">
                            <clr-icon (click)="toggleLock('users')" [attr.shape]="unlocked['users'] ? 'undo' : 'pencil'"></clr-icon>
                            <clr-combobox-container>
                                <label> {{localization.dictionary.user.name}}*</label>
                                <clr-combobox #userbox (ngModelChange)="userbox.searchText = ''; getUsers()" required [disabled]="!unlocked['users']"
                                    [clrLoading]="comboLoading" [(ngModel)]="model.users" name="multiSelected" clrMulti="true"
                                    (clrInputChange)="getUsers($event)" (clrOpenChange)="$event ? getUsers() : null">
                                    <ng-container *clrOptionSelected="let selected">
                                        {{selected.name}}
                                    </ng-container>
                                    <clr-options>
                                        <clr-option *clrOptionItems="let user of users" [clrValue]="user">
                                            {{ user.name }}
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
                        </div>
                    </form>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="!anyUnlocked"
            (click)="validateAndOpenWarningModal()">{{localization.dictionary.button.save}}</button>
        <button type="button" class="btn btn-outline"
            (click)="multiUpdateOpen = false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="openWarning">
    <h3 class="modal-title">{{localization.dictionary.list.updateMultiPopup}}</h3>
    <div class="modal-body">
        {{localization.dictionary.list.updateMultiWarning.replace('${total}', rows?.length)}}
        <clr-datagrid>
            <clr-dg-column [style.width]="getFieldWidth()">{{localization.dictionary.field.nameSingular}}</clr-dg-column>
            <clr-dg-column>{{localization.dictionary.formResultSummary.value}}</clr-dg-column>
            <clr-dg-row *ngFor="let change of changes">
                <clr-dg-cell [style.width]="getFieldWidth()"><div style="width: fit-content;" [id]="'label-' + change.field.label">{{change.field.label}}</div></clr-dg-cell>
                <clr-dg-cell>
                    <span *ngIf="change.field.type == 'BOOLEAN'">{{ change.input == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no }}</span>
                    <span *ngIf="change.field.type == 'DATE'">{{ change.input | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}</span>
                    <span *ngIf="change.field.type == 'TIME'">{{ change.input | date: localization.dictionary.dateFormat.time : undefined : localization.language.selector }}</span>
                    <span *ngIf="change.field.type != 'DATE' && change.field.type != 'TIME' && change.field.type != 'BOOLEAN'">{{ change.input || '-' }}</span>
                </clr-dg-cell>
            </clr-dg-row>
        </clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="sendUpdate()">{{localization.dictionary.button.save}}</button>
        <button type="button" class="btn btn-outline"
            (click)="openWarning = false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>