import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { SettingService } from '../settings/setting.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  collapsed = false;
  featuresToCheck = ['formActive', 'analyticsActive', 'mailActive', 'pushActive', 'crmActive']
  // featureActiveList = {};

  conflictCount = 0;

  constructor(public helper: HelperService,
    public server: ServerService,
    public auth: AuthService,
    public settings: SettingService,
    public localization: LocalizationService) { }

  async ngOnInit() {
    await this.server.getCategories();
    this.settings.updateFeatureActiveList();
    this.settings.settingsChanged.subscribe((val) => {
      if (val) {
        this.settings.updateFeatureActiveList();
      }
    })
    if (this.settings.featureActiveList['crmActive']) {
      const count = await this.server.get('crm/conflict/count') as any;
      if (count) {
        this.conflictCount = count;
      } else {
        this.conflictCount = 0;
      }
      this.server.getConflictCountObservable().addEventListener('message', ({ data }) => {
        try {
          this.conflictCount = JSON.parse(data).count;
        } catch (e) {
          this.conflictCount = 0;
          console.error(e);
        }
      })
    }
  }

  // updateFeatureActiveList() {
  //   this.helper.featureActiveList = {}
  //   for (let feature of this.featuresToCheck) {
  //     const setting = this.server.settings.find((setting) => setting.selector === feature);
  //     this.featureActiveList[feature] = setting.value == 'true';
  //   }
  // }

}
