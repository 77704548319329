import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { WidgetInterface } from '../widget.interface';
import { HelperService } from 'src/app/helper/helper.service';
import { ModalService } from '../../dashboards/diagram-popup/modal-data.service';

@Component({
  selector: 'app-widget-presentation',
  templateUrl: './widget-presentation.component.html',
  styleUrls: ['./widget-presentation.component.css']
})
export class WidgetPresentationComponent implements OnInit, AfterViewInit, WidgetInterface {
  @ViewChild('chart') chart;
  @Input('isDarkMode') isDarkMode: boolean;

  layer = 0;
  total = 0;

  from: Date;
  to: Date;

  docName: string;
  docId: string;

  loading = false;
  presentationData: {document: {name: string, id: string}, duration: number, value?: string}[];

  data = [];
  yTicks = [];

  constructor(private server: ServerService,
    public localization: LocalizationService,
    public modalService: ModalService,
    public helper: HelperService) { 
      this.helper.loading$.subscribe(({ id, loading }) => {
        if (id === 'presentation') {
          this.loading = loading;
        }
      });
      
    }

  async ngOnInit(): Promise<void> {
    await this.refresh();  
    //this.drillDown = this.drillDown.bind(this);
  }

  ngAfterViewInit() {
    if (this.chart) {
      this.chart.id = 'Hilfe'
    }
  }

  async refresh(from?: Date, to?: Date, newLayer = 0, docId?: string, docName?: string) {
    this.loading = true;
    this.data = [];    
    this.yTicks = [];
    let uri = '';
    
    if (docName) {
      this.docName = docName;
      this.docId = docId;    
    }

    this.from = from;
    this.to = to;
    
    let query = new URLSearchParams();
    if (from) {
      query.append('from', from.toISOString());
    }
    if (to) {
      query.append('to', to.toISOString());
    }
    
    if (newLayer == 0) { 
      this.docName = undefined;
      this.docId = undefined;      
      uri = 'tracking/presentationEvents?' + query.toString();     
    } else if (newLayer === 1) {   
      query.append('documentId', this.docId)
      uri = 'tracking/slideEvents?' + query.toString();
    }
    
    this.presentationData = await this.server.get(uri) as any;
    this.total = this.presentationData.length;

    for (let presentationEvent of this.presentationData) {     
      if (presentationEvent.document && newLayer === 0) {    
        this.data.push({
          name: presentationEvent.document.name,
          value: Math.round(presentationEvent.duration / 60 * 100) / 100,
          extra: {
            presId: presentationEvent.document.id
          }
        });
      } else if (newLayer === 1) {
        this.data.push({
          name: presentationEvent.value,
          value: Math.round(presentationEvent.duration / 60 * 100) / 100
        })
      }    
    }

    // calc ticks
    if (newLayer == 1 && this.data[0]) {
      for (let i=0; i < this.data[0].value; i += 60) {        
        this.yTicks.push(i);
      }   
    }
    this.loading = false;
  }

  async drillDown(event) {
    if (this.layer == 0) {      
      await this.refresh(this.from, this.to, 1, event.extra.presId, event.name);      
      this.layer = 1;
    } else {      
      await this.refresh(this.from, this.to);
      this.layer = 0;
    }    
  }

  // drillDown(event){
  //   this.modalService.drillDown(event);
  // }

  // refresh(from?: Date, to?: Date) {
  //   this.modalService.refresh(this.from, this.to, this.layer, this.docId, this.docName);
  // }
  
}
