<h2>{{ localization.dictionary.documentList.documentsIn.replace('${componentName}', localization.dictionary.document.name).replace('${containerName}', category?.name) }}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid class="bound" (clrDgRefresh)="refresh($event)" 
            [clrDgLoading]="loading" 
            cdkDropList 
            [cdkDropListDisabled]="!order" 
            (cdkDropListDropped)="drop($event, pagination)"             
            [(clrDgSelected)]="selected"
            (clrDgSelectedChange)="selectionChange($event)"
            #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.document.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'catDoc.order.' + catId" [(clrDgSortOrder)]="sorting['order']" (clrDgSortedChange)="selected = [];
     order = false">
        {{localization.dictionary.categoryDocumentList.order}}
        <clr-dg-filter [clrDgFilter]="orderFilter" [(clrDgFilterOpen)]="orderFilter.open">
            <number-filter #orderFilter [property]="'catDoc.order'" [(filter)]="filters['order']" [offset]="-1"></number-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="selected = []; order = false">
        {{localization.dictionary.general.name}}
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'description'" [(clrDgSortOrder)]="sorting['description']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['description']">
            {{localization.dictionary.general.description}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="descriptionFilter" [(clrDgFilterOpen)]="descriptionFilter.open">
            <string-filter #descriptionFilter [property]="'description'" [(filter)]="filters['description']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'type'" [(clrDgSortOrder)]="sorting['type']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['type']">
            {{localization.dictionary.type.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="typeFilter" [(clrDgFilterOpen)]="typeFilter.open">
            <multiselect-filter #typeFilter 
                [name]="localization.dictionary.type.name" 
                [property]="'type'"
                [(filter)]="filters['type']" 
                [array]="['FILE', 'FORM']"
                [localizationKey]="'documentType'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="setting.featureActiveList['crmActive']" [clrDgSortBy]="'products.name'" [(clrDgSortOrder)]="sorting['products']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['products']">
            {{localization.dictionary.product.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="productFilter" [(clrDgFilterOpen)]="productFilter.open">
            <multiselect-filter #productFilter [name]="localization.dictionary.product.name" [property]="'products'" [query]="'crm/product/filter'"
                [(filter)]="filters['products']"></multiselect-filter>
    </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'viewType'" [(clrDgSortOrder)]="sorting['viewType']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['viewType']">
            {{localization.dictionary.document.view}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="viewTypeFilter" [(clrDgFilterOpen)]="viewTypeFilter.open">
            <multiselect-filter #viewTypeFilter 
                [name]="localization.dictionary.document.view" 
                [property]="'viewType'"
                [(filter)]="filters['viewType']" 
                [array]="['multiple', 'single', 'book']"
                [localizationKey]="'pdfType'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'isActive'" [(clrDgSortOrder)]="sorting['isActive']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['isActive']">
            {{localization.dictionary.general.active}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="isActiveFilter" [(clrDgFilterOpen)]="isActiveFilter.open">
            <boolean-filter #isActiveFilter [property]="'isActive'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['isActive']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'shareable'" [(clrDgSortOrder)]="sorting['shareable']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['shareable']">
            {{localization.dictionary.document.shareMail}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="mailFilter" [(clrDgFilterOpen)]="mailFilter.open">
            <boolean-filter #mailFilter [property]="'shareable'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['shareable']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'form.name'" [(clrDgSortOrder)]="sorting['form']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['form']">
            {{localization.dictionary.form.nameSingular}}
        </ng-template>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="createdFilter"
                        [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter  #createdFilter
                            [property]="'createdAt'" 
                            [(filterFrom)]="filters['createdAtFrom']"
                            [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'updatedAt'" [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="updatedFilter"
                        [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter  #updatedFilter
                            [property]="'updatedAt'" 
                            [(filterFrom)]="filters['updatedAtFrom']"
                            [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'" [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="deletedFilter"
                        [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter  #deletedFilter
                            [property]="'deletedAt'" 
                            [(filterFrom)]="filters['deletedAtFrom']"
                            [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let doc of category?.documents" [clrDgItem]="doc" [clrDgSelectable]="columnChange || !doc.deletedAt" [class.deleted]="doc.deletedAt" cdkDrag cdkDragBoundary=".bound">
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            <clr-icon *ngIf="order" shape="cursor-move" class="cursor-grab" cdkDragHandle></clr-icon>
            {{ doc.deletedAt ? '' : doc.CategoryDocument.order + 1 }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ doc.id }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            <clr-icon [attr.shape]="doc.icon"></clr-icon>
            {{ doc.name }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ doc.description }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ localization.dictionary.documentType[doc.type] }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="setting.featureActiveList['crmActive']" [routerLink]="['/documents/edit/' + doc.id]">
             
            <span *ngFor="let product of doc.products" class="label label-light-blue"
                [pTooltip]="product.description"
                tooltipPosition="top">
                {{ product.name }}
            </span> 
            
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            <ng-container *ngIf="doc.viewType != null">
                {{ localization.dictionary.pdfType[doc.viewType] }}
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ (doc.isActive) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            <ng-container *ngIf="doc.shareable!= null">
                {{ (doc.shareable) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell class="labels" [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            <span *ngIf="doc.form"
                class="label label-light-blue" 
                [pTooltip]="doc.form?.description" 
                [tooltipPosition]="'top'">
                {{ doc.form.name }}
           </span>

        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ doc.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ doc.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="doc.deletedAt? null : ['/documents/edit/' + doc.id]">
            {{ doc.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>

        <!-- Actions-->
        <div *cdkDragPreview class="grid-drag-preview" fxLayoutAlign="start center" fxLayoutGap="1rem">
            <clr-icon shape="cursor-move"></clr-icon>
        </div>

        <clr-dg-cell *ngIf="!doc.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon 
                *ngIf="doc.type === 'FORM'"
                shape="line-chart" 
                [routerLink]="doc.deletedAt ? null : ['/forms/results/' + doc.form.id]"
                [pTooltip]="localization.dictionary.documentList.showResults"
                tooltipPosition="left">
            </clr-icon>
            
            <span *ngIf="doc.type === 'FILE'">
                <clr-spinner *ngIf="downloading[doc.id]" [clrInline]="true"></clr-spinner>
                <clr-icon 
                    *ngIf="!downloading[doc.id]" 
                    shape="download" 
                    (click)="download(doc)" 
                    [pTooltip]="localization.dictionary.documentList.assignGroupUser.replace('${documentName}', doc.name)"
                    tooltipPosition="left">
                </clr-icon>
            </span>

            <clr-icon 
                shape="link" 
                (click)="openAssignModal(doc.id)" 
                [pTooltip]="localization.dictionary.documentList.assignGroupUser.replace('${documentName}', doc.name)" 
                tooltipPosition="left">
            </clr-icon>

            <clr-icon 
                [class.disabled]="doc.categories.length <= 1" 
                shape="unlink" 
                (click)="toUnlink = doc" 
                [pTooltip]="localization.dictionary.documentList.unlink.replace('${documentName}', doc.name)"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon 
                shape="pencil" 
                [routerLink]="['/documents/edit/' + doc.id]" 
                [pTooltip]=" localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.document.nameSingular).replace('${entryName}', doc.name)"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon 
                [attr.shape]="doc.isActive ? 'ban' : 'success-standard'" 
                (click)="onToggleActive(doc)"
                [class.disabled]="!canReactivate(doc)"
                [pTooltip]="canReactivate(doc)?
                    doc.isActive?(localization.dictionary.documentList.deactivateEntry.replace('${entryName}', doc.name)):(localization.dictionary.documentList.activateEntry.replace('${entryName}', doc.name)):
                    (localization.dictionary.documentList.reactivateForbiddenForm.replace('${entryName}', doc.name))"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon 
                shape="trash" 
                (click)="toDelete = [doc]" 
                [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.document.nameSingular).replace('${entryName}', doc.name)"
                tooltipPosition="left">
            </clr-icon>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="doc.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon 
                *ngIf="doc.type === 'FORM'" 
                shape="line-chart" 
                [routerLink]="['/forms/results/' + doc.form.id]" 
                [pTooltip]="localization.dictionary.documentList.showResults"
                tooltipPosition="left">
            </clr-icon>

            <ng-container *ngIf="doc.type === 'FILE' && doc?.filename">
                <clr-spinner *ngIf="downloading[doc.id]" [clrInline]="true"></clr-spinner>
                <clr-icon 
                    *ngIf="!downloading[doc.id]" 
                    shape="download" 
                    (click)="download(doc)" 
                    [pTooltip]="localization.dictionary.documentList.downloadFile.replace('${documentName}', doc.name)" 
                    tooltipPosition="left">
                </clr-icon>
            </ng-container>
        </clr-dg-cell>

        <div *cdkDragPlaceholder class="grid-drag-placeholder"></div>
        <div *cdkDragPreview class="grid-drag-preview" fxLayoutAlign="start center" fxLayoutGap="1rem">
            <clr-icon shape="cursor-move"></clr-icon>
            <clr-icon [attr.shape]="doc.icon"></clr-icon>
            <span>{{ doc.name }}</span>
        </div>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.document.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.document.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.document.nameSingular : localization.dictionary.document.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" [routerLink]="'/documents/create/' + catId">
                {{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.document.nameSingular) }}
            </button>
            <button class="btn btn-outline" *ngIf="selected.length > 0"
                    (click)="openAssignModal()">{{localization.dictionary.documentList.assign}}</button>
            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-outline" *ngIf="!showDeleted && category?.documents?.length > 1 && selected.length === 0 && !state.filters"
                (click)="toggleOrder()">{{ (!order) ? localization.dictionary.button.arrange : localization.dictionary.button.closeArrange}}</button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{ localization.dictionary.button.delete }}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['document-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted" (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.document.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.documentList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="toUnlink">
    <h3 class="modal-title">{{localization.dictionary.documentList.unlinkModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.documentList.unlinkModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="unlink()">{{localization.dictionary.button.confirm}}</button>
        <button type="button" class="btn btn-outline" (click)="toUnlink = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="assignModal">
    <h3 class="modal-title">{{localization.dictionary.documentList.assignModalTitle}}</h3>
    <div class="modal-body" *ngIf="assignModal">
        <clr-alert *ngIf="!assignOne" clrAlertType="warning">{{localization.dictionary.documentList.assignModalWarning}}</clr-alert>
        <app-list-selector (selectedChanged)="selectedDocument.groups = $event" [preLoaded]="selectedDocument?.groups" [label]="localization.dictionary.group.name" [query]="'group'"></app-list-selector>
        <app-list-selector (selectedChanged)="selectedDocument.users = $event" [preLoaded]="selectedDocument?.users" [label]="localization.dictionary.user.name" [query]="'user/filter?sortBy=name'"></app-list-selector>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveAssign()">{{localization.dictionary.button.save}}</button>
        <button type="button" class="btn btn-outline" (click)="assignModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.document.nameSingular).replace('${entryName}', selectedDocument?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        {{localization.dictionary.documentList.restoreModalDescription}}
        <app-list-selector (selectedChanged)="selectedDocument.categories = $event" [preLoaded]="selectedDocument?.categories" [label]="localization.dictionary.category.name" [query]="'category'"></app-list-selector>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="reactivateModal">
    <h3 class="modal-title">{{ localization.dictionary.documentList.reactivateModalTitel }}</h3>
    <div class="modal-body" *ngIf="reactivateModal">
        {{ localization.dictionary.documentList.reactivateModalDescription.replace('${entryName}', selectedDocument?.name)}}
        <p>{{ selectedDocument.form.name }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="toggleActive(this.selectedDocument)">{{ localization.dictionary.button.reactivate }}</button>
        <button type="button" class="btn btn-outline" (click)="reactivateModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>