
<div class="card" *ngIf="!loading">
    <div class="card-header space-between-flex">
        <div fxLayout="column" >
            {{ localization.dictionary.view.name }}
            <clr-control-helper *ngIf="sameDV && (sameDV.length == crm.displayFields.customer.length)" style="color: red" fxLayoutAlign=" center" >
                <cds-icon shape="exclamation-circle"></cds-icon>
                {{ localization.dictionary.createEdit.sameDV.replace('${componentName}', localization.dictionary.contact.nameSingular) }}
                <clr-tooltip style="margin-left: 0.5vw">
                    <a href="" target="_blank" [routerLink]="'/crm/contact/list'" [queryParams]="setQueryParams()">
                        <clr-icon clrTooltipTrigger role="img" (click)="openList()" shape="pop-out" size="10" aria-label="Uniq tooltip 1"></clr-icon>
                    </a>
                    <clr-tooltip-content clrPosition="top-right" clrSize="xl" *clrIfOpen>
                        <span> {{ localization.dictionary.createEdit.filteredList }} </span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </clr-control-helper>
        </div>

        <clr-dropdown>
            <button class="dropdown-toggle" clrDropdownTrigger>
                <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                <clr-icon shape="caret down" size="20"></clr-icon>
            </button>
            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                <label class="dropdown-header" aria-hidden="true">{{localization.dictionary.view.name}}</label>
                <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                <div *ngFor="let view of views" (click)="activeView=view; crm.activeViewChanged(view, 'contact')" clrDropdownItem>
                    <clr-icon [ngStyle]="{'visibility': (view === activeView) ? 'visible' : 'hidden' }"
                        style="margin-right: 0.3rem" shape="check"></clr-icon>
                    {{ view.name }}
                </div>
            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
    <div class="card-block" *ngFor="let section of activeView?.sections">
        <div class="card-title collapse-card">
            <span>
                <clr-icon [attr.shape]="section.icon" class="clr-icon-heading"
                    role="img"></clr-icon>
                <span>{{ section.name }}</span>
                <p class="card-text icon-offset">
                    {{ section.description }}
                </p>
            </span>
            <clr-icon shape="angle" class="arrow" [dir]="!collapse[section['id']] ? 'down' : 'left'"
                (click)="collapse[section['id']] = !collapse[section['id']]"></clr-icon>
        </div>
        <div [hidden]="collapse[section['id']]" class="card-text">
            <app-custom-input [model]="contact" [modelSnapshot]="contactSnapshot" [modelString]="'contact'" [fields]="section.fields" (change)="dirty = true"
                    [(choices)]="conflictChoices" (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)"
                [conflicts]="conflicts" (sameDV)="checkDV($event)">
            </app-custom-input>
        </div>
    </div>
    <!-- <div class="card-footer">
        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
    </div> -->
</div>

<!-- <ng-template #actionBar>
    <div style="display: flex; justify-content: flex-end;">
        <button class="btn btn-primary" (click)="validateAndSave()">{{ localization.dictionary.button.create }}</button>
        <button class="btn btn-outline" style="margin-right: 0px;" (click)="createdContact.next(null)">{{localization.dictionary.button.cancel}}</button>
    </div>
</ng-template> -->


