import { style } from '@angular/animations';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { BrandingService } from 'src/app/branding/branding.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { Customer } from '../../customers/customer.model';
import { Product } from '../../products/product.model';
import { Territory } from '../../territories/territory.model';


@Component({
  selector: 'app-create-edit-product',
  templateUrl: './create-edit-product.component.html',
  styleUrls: ['./create-edit-product.component.css']
})
export class CreateEditProductComponent extends PreventUnload implements OnInit, DoCheck {


  @ViewChild(ClrForm) form: ClrForm;
  public formData: NgForm;

  @ViewChild('formData', { static: false }) set content(content: NgForm) {
    if (content) { // initially setter gets called with undefined      
      this.formData = content;
    }
  }

  product: Product;
  customerIds: string[] = [];
  territories: Territory[]
  mode: 'edit' | 'create';
  loading = true;

  errTemp = {};

  firstTab = true;
  secondTab = false;
  invalid = false;
  allowedFiles = '.jpg, .jpeg, .png, .eps, .gif, .bmp, .tif, .tiff, .raw, .svg';
  collapse: boolean[] = [
    false,
    false
  ];

  productPrice: string ='';

  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public helper: HelperService,
    public branding: BrandingService,
    public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.mode = (params.id) ? 'edit' : 'create';
        if (this.mode === 'edit') {
          this.product = plainToClass(Product, await this.server.get('crm/product/byId/' + params.id));
          this.productPrice = this.product?.price?.toString();
        } else {
          this.product = new Product();
          this.dirty = true;
        }
        this.changeUnload();
        this.customerIds = this.product.customers?.map(customer => customer.id);
        this.loading = false;
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );
  }

  async ngOnInit(): Promise<void> {
    const res = await this.server.get('crm/territory');
    this.territories = plainToClass(Territory, res.rows);
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('product');
    } else {
      this.changeUnloadEdit('product', this.product.name);
    }
  }

  addCustomer(customer: Customer) {
    // if(!this.product.customers)
    //   this.product.customers = [];
    this.product.customers.push(customer);
    this.customerIds = this.product.customers.map(customer => customer.id);
  }

  removeCustomer(customer: Customer) {
    this.product.customers = this.product.customers.filter(productCustomer => productCustomer.id != customer.id);
    this.customerIds = this.product.customers.map(customer => customer.id);
  }

    async uploadImage() {
    const base64 = 'url(' + await this.helper.compressFile('product') + ')';
        this.product.picture = base64;
    }

 /*  async imageChange(files: FileList) {
    if (files) {
      const base64 = 'url(' + await this.helper.getBase64(files.item(0)) + ')';
      this.product.picture = base64;
    }
  }
 */

  ngDoCheck() {
    if (this.form && this.formData && this.invalid) {
      this.formData.form.markAsDirty();
      this.formData.form.markAsTouched();
      this.form.markAsTouched();
      this.invalid = false;
    }
    if (Object.keys(this.errTemp).length > 0 && this.formData && Object.keys(this.formData.form.controls).length > 0) {
      this.formData.form.markAsDirty();
      this.form.markAsTouched();
      this.markFieldsAsInvalid(this.errTemp);
      this.errTemp = {};
    }
  }

  /*addContact(contact: Contact) {
    this.product.contacts.push(contact);
    this.contactIds = this.product.contacts.map(contact => contact.id)
  }
  
  removeContact(contact: Contact) {
    this.product.contacts = this.product.contacts.filter(productContact => productContact.id != contact.id);
    this.contactIds = this.product.contacts.map(contact => contact.id)
  }
*/
  // async validateAndSave() {
  //   if (this.product.name /* && this.product.address && this.product.postalCode && this.product.city && this.product.country && this.product.territory */)
  //     await this.save();
  //   else {
  //     this.firstTab = true;
  //     this.invalid = true;
  //   }
  // }

  unfold(){
    this.firstTab = true;
    this.invalid = true;
    this.collapse[0] = false;
    this.form.markAsTouched();
  }

  async save() {
    if (this.productPrice) {
      try {
        this.product.price = parseFloat(this.productPrice.replace(',', '.'));
      } catch(err) {}
    }
    
    
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    if (this.mode === 'create') {
      const res = await this.server.post('crm/product', this.product);

    } else {
      const res = await this.server.put('crm/product', this.product);
    }
    this.server.addAlert(new AmselError(undefined, 'success',
    this.localization.dictionary.toastr.successSaved
      .replace('${componentName}', this.localization.dictionary.product.nameSingular)
      .replace('${entryName}', this.product.name)));
    this.saving = false;
    this.server.back();
  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      this.formData.form.markAsDirty();
      if (this.form) this.form.markAsTouched();
      for (let field of Object.keys(errors)) {
        if (this.formData && this.formData.form.controls[field]) {
          this.formData.form.controls[field].markAsDirty();
          this.formData.form.controls[field].setErrors(errors[field]);
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  }

}

