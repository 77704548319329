import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagrid, ClrDatagridSortOrder } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { HelperService } from '../../../helper/helper.service';
import { CrmService } from '../../crm.service';
import { Product } from '../product.model';
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.css']
})
export class ListProductsComponent implements OnInit, OnDestroy, DatagridFunctions {
  products: Product[];
  selected: Product[] = [];
  toDelete: Product[];


  showDeleted = false;
  selectedProduct: Product;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  exportModal = false;
  importModal = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  loading = true;
  saving = false;
  total: number;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  }
  state: ClrDatagridStateInterface

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(
    public server: ServerService,
    public auth: AuthService,
    public excelService: ExcelService,
    public helper: HelperService,
    public crm: CrmService,
    public localization: LocalizationService) {
  }

  ngOnInit(): void {
    const filter = localStorage.getItem('product-filters');
    const sorting = localStorage.getItem('product-sorting');
    const hidden = localStorage.getItem('product-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
  }

  ngOnDestroy() {
    localStorage.setItem('product-filters', JSON.stringify(this.filters));
    localStorage.setItem('product-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('product-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('product-deleted', 'true')
    else
      localStorage.removeItem('product-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const product = await this.server.get('crm/product/' + (this.showDeleted ? 'all' : '') + query);
    this.total = product.count;
    this.products = plainToClass(Product, product.rows);
    this.loading = false;
  }

  async loadProducts() {
    this.showDeleted = !!localStorage.getItem('product-deleted');
    const res = await this.server.get('crm/product/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.products = plainToClass(Product, res.rows);
  }

  selectionChange(products: Product[]) {
    if (products.length > 0) {
      this.order = false;
    }
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('crm/product/')
    }
    this.order = !this.order;
  }

  async openRestoreModal(product: Product) {
    this.selectedProduct = product;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('crm/product/restore', this.selectedProduct);
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successRestored
        .replace('${componentName}', this.localization.dictionary.product.nameSingular)
        .replace('${entryName}', this.selectedProduct.name)));
    this.refresh();
    this.restoreModal = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const productIds = this.toDelete.map((product) => product.id);
    await this.server.delete('crm/product?toDelete=' + productIds);
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successDeleted
        .replace('${componentName}',
          this.toDelete.length == 1 ? this.localization.dictionary.product.nameSingular : this.localization.dictionary.product.name)));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = [];
    await this.refresh();
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  async onDownloadExcel() {
    let buffer = await this.excelService.downloadInitialExcel([this.localization.dictionary.general.name, this.localization.dictionary.general.description, this.localization.dictionary.general.itemNumber, this.localization.dictionary.general.unit, this.localization.dictionary.general.price]);
    let fileName = this.localization.dictionary.product.name;
    this.excelService.downloadExcel(buffer, fileName);
  }

  openInitialTemplateImport() {
    const fileInput = document.getElementById('initialTempImp') as HTMLInputElement;
    fileInput.click();
    fileInput.onchange = (e) => {
      this.onImportInitialExcel(e);
      this.importModal = false;
    }
  }

  async onImportInitialExcel(event: Event) {

    this.loading = true;
    let answer = await this.excelService.importProducts(event);
    if (answer === 'success') {
      this.refresh()
    } else {
      this.loading = false;
    }
  }

}

//