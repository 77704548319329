import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { BrandingService } from '../branding/branding.service';
import { CrmService } from '../crm/crm.service';
import { LocalizationService } from '../localization/localization.service';
import { SettingService } from '../settings/setting.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  
  constructor(private auth: AuthService,
              private router: Router,
              public branding: BrandingService,
              public settings: SettingService,
              private crm: CrmService,
              public localization: LocalizationService) {
                
              }

  async ngOnInit(): Promise<void> {
    
  }

  async login() {
    const index = await this.auth.login(this.username, this.password);
    await this.crm.init();
    this.router.navigateByUrl(this.auth.routes[index]);
  }

}
