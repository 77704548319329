<h2>{{localization.dictionary.links.usefulLinks}}</h2>

<div class="link-wrapper" fxLayout="row" fxLayoutGap="1rem">
    <ng-container *ngFor="let link of links">
        <div *ngIf="isActive(link)" class="link-item" fxLayout="column" (click)="routeTo(link)">
            <div fxFlex="50" fxLayoutAlign="center center">
                <clr-icon [attr.shape]="link.icon" size="50"></clr-icon>
            </div>

            <div class="link-text" fxFlex="50" fxLayoutAlign="center center">
                {{ link.text }}
            </div>
        </div>
    </ng-container>
</div>

<clr-modal [(clrModalOpen)]="noCatsModal">
    <h3 class="modal-title">{{localization.dictionary.links.noCategories}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.links.noCreatedCategories}}</p>
        <p>{{localization.dictionary.links.categoryNow}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="noCatsModal = false">{{localization.dictionary.button.cancel}}</button>
        <button type="button" class="btn btn-primary" (click)="noCatsModal = false">{{localization.dictionary.links.createCategory}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="selectCatsModal">
    <h3 class="modal-title">{{localization.dictionary.links.selectCategory}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.links.categoryDocument}}</p>
        <select clrSelect name="categories" [(ngModel)]="selectedCatId">
            <option [value]="'default'" disabled selected>{{localization.dictionary.links.selectCategory}}</option>
            <option *ngFor="let cat of server.categories" [value]="cat.id">{{ cat.name }}</option>
        </select>
        <p>{{localization.dictionary.links.categoryAssign}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="selectCatsModal = false; selectedCatId = 'default'">{{localization.dictionary.button.cancel}}</button>
        <button *ngIf="selectedCatId != 'default'" type="button" class="btn btn-primary" (click)="selectCatsModal = false; router.navigateByUrl('documents/create/' + selectedCatId); selectedCatId = 'default'">{{localization.dictionary.links.createNewDocument}}</button>
    </div>
</clr-modal>
