
<ol class="list">
<h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet.</li>
    <li>Vergeben Sie einen Namen für das Gebiet und ergänzen Sie eine Beschreibung, z.B. Region Nord als Name und die zugeordneten Postleitzahlen 17… - 29… als Beschreibung.</li>
    <li>Über den Punkt „Übergebiet“ können Sie eine Hierarchie in den Gebieten erstellen, z.B. können Sie dem Gebiet Nord das Gebiet Deutschland überordnen.</li>
<h4 style="position: relative; right: 1.1em;">Benutzer & Gruppen Zuordnen</h4>    
    <li>Anschließend weisen Sie das Gebiet Benutzern bzw. Gruppen zu. Benutzer könnte der für das Gebiet zuständige Vertriebler sein und als Gruppe könnten Sie Service hinzufügen.</li>
<h4 style="position: relative; right: 1.1em;">Untergeordnete Gebiete zuordnen</h4>  
    <li>Unter dem Punkt „Untergeordnete Gebiete zuordnen“ können Sie weitere Gebiete unterordnen, z.B. wenn Sie eine Organisationsstruktur mit Regionalleitern haben. Dem Gebiet Region Nord könnten Sie dann z.B. die Region Schleswig-Holstein unterordnen.</li>
    <li>Zum Speichern klicken Sie auf den Button „Erstellen“.</li>
</ol>