import { Exclude, Expose, Transform, Type } from "class-transformer";
import { AnsweredForm } from "src/app/forms/answered-form.model";
import { GivenAnswer } from "src/app/forms/given-answer.model";
import { CustomerContact } from "../customers/customer-contact.model";
import { Customer } from "../customers/customer.model";
import { Territory } from "../territories/territory.model";

export class Contact {
    @Expose()
    id: string;
    
    @Expose()
    @Type( () => Customer)
    customers: Customer[] = [];

    @Expose()
    answeredForms: AnsweredForm[] = [];

    @Expose()
    @Transform((val, obj) => {
        let result: Territory[] = []
        if (obj.customers) {
            obj.customers.forEach(customer => {
                if (!customer.deletedAt && customer.territory) {
                    const index = result.find(value => value.id == customer.territory.id)
                    if (!index)
                        result.push(customer.territory)
                }
            })
            result.sort((a, b)=> a.name > b.name? 1 : -1 )
            return result
        }       
    })
    territories: Territory[]

    @Expose()
    @Type( () => CustomerContact)
    CustomerContact: CustomerContact = new CustomerContact();
}