import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { SettingService } from 'src/app/settings/setting.service';
import { Unsubscribable } from '../../helper/unsubscribable/unsubscribable';
import { Link } from './link.model';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent extends Unsubscribable implements OnInit {
  noCatsModal = false;
  selectCatsModal = false;
  selectedCatId = 'default';

  links: Link[] = [
    {
      text: this.localization.dictionary.links.createNewCategory,
      localizationPath: 'createNewCategory',
      icon: 'tree-view',
      path: 'category/create',
      rolesToCheck: ['admin', 'category-editor']
    },
    {
      text: this.localization.dictionary.links.createNewDocument,
      localizationPath: 'createNewDocument',
      icon: 'file',
      path: 'documents/create',
      rolesToCheck: ['admin', 'document-editor']
    },
    {
      text: this.localization.dictionary.links.createNewForm,
      localizationPath: 'createNewForm',
      icon: 'form',
      path: 'forms/create',
      featureToCheck: 'formActive',
      rolesToCheck: ['admin', 'form-editor']
    },
    {
      text: this.localization.dictionary.links.createNewCall,
      localizationPath: 'createNewCall',
      icon: 'talk-bubbles',
      path: 'crm/call/create',
      featureToCheck: 'crmActive',
      rolesToCheck: ['crm-editor']
    },
    {
      text: this.localization.dictionary.links.createNewUser,
      localizationPath: 'createNewUser',
      icon: 'id-badge',
      path: 'user/create',
      rolesToCheck: ['admin', 'user-editor']
    },
    {
      text: this.localization.dictionary.links.helpInfo,
      localizationPath: 'helpInfo',
      icon: 'help',
      path: 'help'
    }
  ]

  constructor(public router: Router, 
              public server: ServerService, 
              private settings: SettingService,
              private auth: AuthService, 
              public localization: LocalizationService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(() => {
        for (let link of this.links) {
          link.text = this.localization.dictionary.links[link.localizationPath];
        }/* 
        this.links = [
          {
            text: this.localization.dictionary.links.createNewCategory,
            icon: 'tree-view',
            path: 'category/create',
            rolesToCheck: ['admin', 'category-editor']
          },
          {
            text: this.localization.dictionary.links.createNewDocument,
            icon: 'file',
            path: 'documents/create',
            rolesToCheck: ['admin', 'document-editor']
          },
          {
            text: this.localization.dictionary.links.createNewForm,
            icon: 'form',
            path: 'forms/create',
            featureToCheck: 'formActive',
            rolesToCheck: ['admin', 'form-editor']
          },
          {
            text: this.localization.dictionary.links.createNewCall,
            icon: 'talk-bubbles',
            path: 'crm/call/create',
            featureToCheck: 'crmActive'
          },
          {
            text: this.localization.dictionary.links.createNewUser,
            icon: 'id-badge',
            path: 'user/create',
            rolesToCheck: ['admin', 'user-editor']
          },
          {
            text: this.localization.dictionary.links.helpInfo,
            icon: 'help',
            path: 'help'
          }
        ] */
      })
    );
  }

  async routeTo(link: Link) {
    // Sonderfall Dokumente
    if (link.path == 'documents/create') {
      if (this.server.categories.length == 0) {
        // noch keine Kategorien angelegt
        this.noCatsModal = true;
        return;
      } else {
        this.selectCatsModal = true;
        return;
      }
    }
    // route zu Ziel
    await this.router.navigateByUrl(link.path);
  }

  isActive(link) {
    let isActive = true;
    // Feature aktiviert?
    if (link.featureToCheck && !this.settings.featureActiveList[link.featureToCheck]) {
      isActive = false;
    }
    // Rolle vorhanden?
    if (link.rolesToCheck && !this.auth.hasRole(link.rolesToCheck)) {
      isActive = false;
    }
    return isActive;
  }

}
