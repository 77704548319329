import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagrid } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { AuthService } from '../../../auth/auth.service';
import { DatagridFunctions } from '../../../helper/datagrid-functions.class';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { CrmService } from '../../crm.service';
import { Contact } from '../contact.model';
import { View } from '../../views/view.model';
import { Field } from '../../fields/field.model';

@Component({
  selector: 'app-sublist-contacts',
  templateUrl: './sublist-contacts.component.html',
  styleUrls: ['./sublist-contacts.component.css']
})
export class SublistContactsComponent implements OnInit, OnChanges, DatagridFunctions {
  contacts: Contact[];
  selected: Contact[] = [];
  toDelete: Contact[];
  loading = true;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    createdAt: true,
    updatedAt: true
  }
  state: ClrDatagridStateInterface
  restoreModal = false;

  columnChanged = false;
  columnChange = false;

  views: View[];
  activeView: View;
  sectionFields: Field[];

  @Input() current: boolean;
  @Input() conflict: boolean;
  @Input() dataIds: string[];
  @Input() positions;


  @Output() selectedContact = new EventEmitter<Contact>();
  @Output() editContact = new EventEmitter<{ contact: Contact, position: string }>();
  @Output() createCall = new EventEmitter<Contact>();

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  constructor(public server: ServerService,
              public auth: AuthService,
              public crm: CrmService,
              public helper: HelperService,
              public localization: LocalizationService,
              private cdr: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    const sorting = localStorage.getItem('contact-sorting');
    const hidden = localStorage.getItem('contact-columns');
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
    this.crm.setActiveViewsFromStorage(['contact']);
    this.views = this.crm.views.contact;
    this.activeView = this.crm.activeViews.contact;
    this.activeViewChange();
  }

  activeViewChange(ev?) {
    this.sectionFields = [];
    this.cdr.detectChanges();
    if (ev){
      this.activeView = ev;
    }
    if (!this.activeView){
      this.activeView = this.views[0];
    }
    for (let section of this.activeView.sections){
      if (section.fields.length > 0){
        this.sectionFields.push(...section.fields);
      }
    }
    this.crm.activeViews.contact = this.activeView;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "dataIds" && this.datagrid) {
          this.refresh(this.state)
        }
      }
    }
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    if (query.length > 0)
      query += '&' + (this.current ? '&includeIds' : 'excludeIds') + '=' + this.dataIds.join(',');
    else
      query = '?' + (this.current ? '&includeIds' : 'excludeIds') + '=' + this.dataIds.join(',');
    const contact = await this.server.get('crm/contact/sub/' + query);
    this.total = contact.count;
    this.contacts = plainToClass(Contact, contact.rows);
    this.loading = false;
  }

  async delete() {
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }
}
