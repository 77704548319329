<ol class="list">
    <h4 style="position: relative; right: 1.1em;">Neue Ansicht erstellen</h4>
        <li>Alle Pflichtfelder sind mit einem * gekennzeichnet.</li>
        <li>Im oberen Teil der Eingabemaske vergeben Sie den Namen und erstellen eine Beschreibung für die Ansicht. Zum Beispiel „Kunden-Standardansicht“ als Name und „Standardansicht für Kundenstammdaten“ als Beschreibung.</li>
    <h4 style="position: relative; right: 1.1em;">Felder</h4>
        <li>Anschließend wählen Sie den Bereich aus, für den die Ansicht erstellt wird, z.B. Kunde.</li>
    <h4 style="position: relative; right: 1.1em;">Neue Sektion</h4>
        <li>Unter dem Punkt „neue Sektion“ legen Sie den ersten Bereich der Ansicht fest und überschreiben den Titel, z.B. mit „Stammdaten“.</li>
        <li>Anschließend vergeben Sie ein aussagekräftiges Icon und ergänzen eine Beschreibung zu den hier zu erfassenden Daten.</li>
        <li>Zur Konfiguration der Ansicht ziehen Sie per Drag & Drop (Feld anklicken, Maustaste gedrückt halten, mit der Maus in den Bereich ziehen) die gewünschten Felder in die rechteckige Markierung unterhalb der Sektion.</li>
        <li>Die weiteren Felder können Sie über oder unter den bereits ausgewählten Feldern platzieren.</li>
        <li>Um eine weitere Sektion und damit einen neuen Themenbereich in der Ansicht anzulegen, klicken Sie auf das Plus-Symbol in der Zeile der Sektions-Übersicht. Anschließend gehen Sie wie in der Einrichtung der 1. Sektion vor.</li>
        <li>Wenn Sie alle Felder ausgewählt haben, klicken Sie auf den Button „Erstellen“ zum Abspeichern.</li>
</ol>