import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ServerService } from '../server.service';
import { User } from '../users/user.model';
import { SettingService } from '../settings/setting.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User;
  impersonater: User;
  adminName = 'admin';

  roles = ['admin', 'analytics-user', 'category-editor', 'form-editor', 'crm-editor', 'view-editor', 'field-editor', 'user-editor', 'group-editor', 'notification-editor', 'mail-editor', 'settings-editor', 'language-editor', 'branding-editor', 'document-editor', 'user'];
  routes = ['home', 'home', 'category/list', 'forms/list', 'crm/customer/list', 'crm/view/list', 'crm/field/list', 'user/list', 'groups/list', 'notifications/push/list', 'notifications/mail', 'settings', 'language/list', 'branding', 'no-permission', 'crm/call/list']

  constructor(private server: ServerService, 
              private router: Router, 
              private http: HttpClient,
              private settings: SettingService) {
    this.loadFromLocal();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await this.server.get('auth/getLogin');
      return true;
    } catch (err) {
      return false;
    }
  }

  refreshToken(): Observable<{ access_token: string; refresh_token: string }> {
    if (!this.impersonater) {
      const user = JSON.parse(localStorage.getItem('amsel-user'));
      this.user = user;
    }

    return this.http.post<{ access_token: string; refresh_token: string }>(
      `${this.server.uri}auth/refresh`,
      {
        refreshToken: this.user.refreshToken,
        userId: this.user.id,
        username: this.user.name,
        deviceType: 'WEB'
      }).pipe(
        tap(response => {
          this.user.accessToken = response['access_token'];
          this.user.refreshToken = response['refresh_token'];
          if (!this.impersonater)
            this.saveToLocal();
        })
      );
  }

  async login(username: string, password: string, impersonate = false): Promise<number> {
    let res
    if (impersonate) {
      res = await this.server.post('auth/loginAs', { username: username, deviceType: 'WEB', deviceToken: 'impersonation' });
      if(!this.impersonater)
        this.impersonater = this.user;
    } else {
      res = await this.server.post('auth/login', { username: username, password: password, deviceType: 'WEB', deviceToken: '' });
    }
    const user = plainToClass(User, res);
    if (user) {
      this.user = user;
      if(!impersonate)
        this.saveToLocal()
      const index = this.roles.findIndex(role => this.hasRole([role]))
      if (index < 0) {
        if (impersonate) {
          this.endImpersonation(true)
          throw new HttpErrorResponse({ error: { statusCode: 401 } })
        } else {
          this.logout()
          throw new HttpErrorResponse({ error: { statusCode: 403 } })
        }
      }
      return index
    }
    return -1;
  }

  async logout() {
    localStorage.removeItem('amsel-user');
    this.user = undefined;
    this.impersonater = undefined;
    //this.server.setAccessToken(undefined);
    this.router.navigate(['/login']);
  }

  loadFromLocal() {
    const user = localStorage.getItem('amsel-user');
    if (user) {
      this.user = JSON.parse(user);
    }
  }

  saveToLocal() {
    localStorage.setItem('amsel-user', JSON.stringify(this.user));
  }

  hasRole(roles?: string[], inverse = false) {
    let hasRole = false;
    if (!roles || roles.length == 0) {
      return true;
    }
    roles.push('admin');
    if (this.user) {      
      for (let role of roles) {
        if (this.user.fullRoles.findIndex((userRole) => userRole.name === role) !== -1) hasRole = true;
      }
    }
    return hasRole;
  }

  async startImpersonation(user: User) {
    const index = await this.login(user.name, null, true);
    if (index > 0) {
      this.router.navigateByUrl(this.routes[index]);
    }
  }

  endImpersonation(abort = false) {
    this.user = this.impersonater
    this.impersonater = undefined;
    this.saveToLocal();
    if (!abort)
      this.router.navigateByUrl('user/list');
  }

  featureIsActivated(selector: string): boolean {
    const setting = this.settings.settings.find((setting) => setting.selector === selector);

    if (!setting || setting.value === 'false') {
      return false;
    }
    return true;
  }
}
