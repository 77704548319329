import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagrid, ClrDatagridSortOrder } from '@clr/angular';
import { AuthService } from 'src/app/auth/auth.service';
import { plainToClass } from 'class-transformer';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { ServerService } from 'src/app/server.service'
import { Call } from '../call.model';
import { HelperService } from 'src/app/helper/helper.service';
import { SettingService } from 'src/app/settings/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelService } from '../../../excel.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { View } from '../../views/view.model';
import { Field } from '../../fields/field.model';
import { CrmService } from '../../crm.service';
import { Contact } from '../../contacts/contact.model';
import { Customer } from '../../customers/customer.model';


@Component({
  selector: 'app-list-call',
  templateUrl: './list-call.component.html',
  styleUrls: ['./list-call.component.css']
})
export class ListCallComponent implements OnInit, OnDestroy, DatagridFunctions {
  contactType = Contact;
  customerType = Customer;
  calls: Call[];
  selected: Call[] = [];
  toDelete: Call[];
  loading = true;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  prefiltered: boolean = false;
  hidden: { [column: string]: boolean } = {
    id: true,
    createdAt: true,
    updatedAt: true,
    createdBy: true,
    updatedBy: true,
    deletedBy: true,
  }
  state: ClrDatagridStateInterface;
  exportState: ClrDatagridStateInterface;
  showDeleted = false;
  selectedCall: Call;
  restoreModal = false;
  exportModal = false;
  startInput = false;
  columnChange = false;
  columnChanged = false;
  views: View[] = [];
  contactViews: View[] = [];
  customerViews: View[] = [];
  activeView: View;
  contactView: View;
  customerView: View;
  sectionFields: Field[];
  saving = false;

  multiUpdate = false;
  multiModel = {};

  auditLogModalOpen = false;
  entityId: string;
  entityType = 'Call';

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }


  constructor(public server: ServerService,
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public helper: HelperService,
    public setting: SettingService,
    public crm: CrmService,
    public excelService: ExcelService,
    public localization: LocalizationService,
    private cdr: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    const dgOptions = this.crm.getDatagridOptions('call');
    this.filters = dgOptions.filters;
    this.hidden = dgOptions.hidden;
    this.sorting = dgOptions.sorting;
    if (this.route.queryParams['_value'] && Object.keys(this.route.queryParams['_value']).length > 0) {
      this.prefiltered = true;
      this.filters = Object.assign({}, this.route.queryParams['_value']);
    }
    this.crm.setActiveViewsFromStorage(['call']);
    this.views = this.crm.views.call;
    this.activeView = this.crm.activeViews.call;
    this.contactViews = this.crm.views.contact;
    this.contactView = this.crm.activeViews.contact;
    this.customerViews = this.crm.views.customer;
    this.customerView = this.crm.activeViews.customer;
    this.activeViewChange();
  }

  async activeViewChange(ev?) {
    this.sectionFields = [];
    this.cdr.detectChanges();
    if (ev) {
      this.activeView = this.views.find(view => view.id == ev.id);
    }
    if (!this.activeView) {
      this.activeView = this.views[0];
    }
    if (!this.contactView) {
      this.contactView = this.contactViews[0];
    }
    if (!this.customerView) {
      this.customerView = this.customerViews[0];
    }
    for (let section of this.activeView.sections) {
      if (section.fields.length > 0) {
        this.sectionFields.push(...section.fields);
      }
    }
    this.crm.activeViews.call = this.activeView;
  }

  ngOnDestroy() {
    if (!this.prefiltered) {
      localStorage.setItem('call-filters', JSON.stringify(this.filters));
      localStorage.setItem('call-sorting', JSON.stringify(this.sorting));
      localStorage.setItem('call-columns', JSON.stringify(this.hidden));
    }
    if (this.activeView) {
      localStorage.setItem('call-active-view', JSON.stringify(this.activeView));
    }
    if (this.showDeleted)
      localStorage.setItem('call-deleted', 'true')
    else
      localStorage.removeItem('call-deleted')
  }

  ngOnChanges() {
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const call = await this.server.get('crm/call/' + (this.showDeleted ? 'all' : '') + query);
    this.total = call.count;
    this.calls = plainToClass(Call, call.rows);
    this.loading = false;

    // Testing Filter Link
    const newParams = Object.assign({}, this.filters);
    const filterLink = (location.href.replace(location.search, '') + '?' + encodeURI(Object.keys(newParams).map(key => {
      if (newParams[key]?.value && !newParams[key]?.toggleNeeded) {
        newParams[key] = newParams[key].value;
      } else if (newParams[key]?.toggleNeeded) {
        delete newParams[key].toggleNeeded;
      }
      newParams[key] = typeof newParams[key] == 'string' ? newParams[key] : JSON.stringify(newParams[key]);
      if (newParams[key])
      return key + '=' + newParams[key];
    }).filter(value => value).join('&'))).replace(/\?$/, '');
  }

  async loadCalls() {
    this.showDeleted = !!localStorage.getItem('call-deleted');
    const res = await this.server.get('crm/call/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.calls = plainToClass(Call, res.rows);
  }


  selectionChange(calls: Call[]) {
    if (calls.length > 0) {
      this.order = false;
    }
  }


  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('crm/call/')
    }
    this.order = !this.order;
  }


  async openRestoreModal(call: Call) {
    this.selectedCall = call;
    this.restoreModal = true;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const callIds = this.toDelete.map((call) => call.id);
    await this.server.delete('crm/call?toDelete=' + callIds);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
      this.toDelete.length == 1 ? this.localization.dictionary.call.nameSingular : this.localization.dictionary.call.name)));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
  }



  async restore() {
    await this.server.put('crm/call/restore', this.selectedCall);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.call.name).replace("${entryName}", this.crm.getDisplayValue(this.selectedCall))));
    this.refresh();
    this.restoreModal = false;
  }


  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  editable(call: Call, deleted = true) {
    return (this.auth.hasRole(['admin']) && (deleted ? !call.deletedAt : true));
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }



  async checkExportType(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.exportState = state;
    this.exportState.page.size = 0;
    if (this.exportState.filters) {
      this.exportModal = true;
    } else {
      await this.downloadExcel("?skip=0&limit=0");
    }
  }

  async downloadExcel(query?: string) {
    this.loading = true;
    this.exportModal = false;
    if (!query) {
      query = this.server.buildQueryFromGrid(this.exportState);
    }
    this.crm.downloadExcel('call', query, this.showDeleted);
    this.loading = false;
  }

  async downloadPharmxpert(customer = true) {
    this.loading = true;
    let uri = (customer) ? 'analytics/crm/callsWithCustomer' : 'analytics/crm/callsWithContacts';
    let buffer = await this.server.download(uri);
    let excelName = 'Kundenkontakte mit Zusatzinfos ' + (customer) ? '(Kunden)' : '(Ansprechpartner)' + '_' + new Date().toLocaleDateString();
    this.loading = false;
    await this.excelService.downloadExcel(buffer, excelName);
  }

  openAuditLogModal(entityId: string) {
    this.entityId = entityId;
    this.auditLogModalOpen = true;
  }
}
