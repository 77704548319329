<p>Im Bereich Ansprechpartner können Sie neue Kontaktpersonen anlegen und einem Kunden zuordnen. Die Konfiguration der Erfassungsmaske erfolgt über den Punkt „Ansichten“ (siehe Kapitel CRM > Ansichten).</p>
<h4>Neuen Ansprechpartner erstellen</h4>
<ol class="list">
    <li>Um einen neuen Ansprechpartner anzulegen, klicken Sie im Menu auf CRM und wählen den Punkt Ansprechpartner aus. Dann klicken Sie auf den Button „Kunden hinzufügen“.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>
<h4>Ansprechpartner bearbeiten</h4>
<ol class="list">
    <li>Um einen Ansprechpartner zu bearbeiten, wählen Sie diesen in der Liste aus. Alternativ können Sie über Aktionen, die Bearbeitung auswählen.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>
