<h4>Basis Informationen</h4>
<p style="margin-top: 0.6rem"><span style="font-weight: bold; color: var(--warning);">Hinweis: </span>Bevor Sie einen neuen Benutzer anlegen sollten Sie sicherstellen, dass Sie über ausreichend freie Lizenzen verfügen.</p>
<ol class="list">
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Vergeben Sie einen Benutzernamen und ergänzen Sie eine E-Mail-Adresse.</li>
    <li>Vergeben Sie anschließend ein Passwort für den Benutzer.</li>
    <li>Ergänzen Sie Anrede, Vorname und Nachname.</li>
<h4 style="position: relative; right: 1.1em;">Gruppen zuordnen</h4>  
    <li>Im nächsten Abschnitt können Sie dem Benutzer eine Gruppe zuordnen und damit die mit der Gruppe verbundenen Leserechte (siehe Benutzerverwaltung > Gruppen)</li>
<h4 style="position: relative; right: 1.1em;">Gebiete zuordnen</h4>
    <li>Bei Benutzern, die einem bestimmten Gebiet zugeordnet sind wie z.B. Vertriebsmitarbeiter und Servicetechniker, können Sie dies im nächsten Abschnitt zuordnen. </li>
<h4 style="position: relative; right: 1.1em;">Rollen zuordnen</h4>
    <li>Im letzten Abschnitt vergeben Sie die Benutzerrollen. Diese regeln die Rechte einzelner Benutzer. Eine Übersicht der Rollen und der damit verbundenen Berechtigungen finden Sie im Hauptmenü unter „Benutzerverwaltung“ > „Rollen“.</li>
</ol>
<p><span style="font-weight: bold; font-size: larger; color: var(--danger);">Bitte beachten: </span>Unabhängig von den weiteren Berechtigungen muss jedem Benutzer die Rolle „user“ zugeordnet werden. Ohne diese Rolle ist eine Anmeldung im System nicht möglich.</p>
