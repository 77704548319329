import { Expose, Type } from "class-transformer";
import { Customer } from "../customers/customer.model";
import { Call } from "../calls/call.model";

export class FileUpload {
    @Expose()
    id: string;
    
    @Expose()
    filename: string;

    @Expose()
    desc: string;

    @Expose()
    fileType: string;

    @Expose()
    fileExtension: string;

    @Expose()
    userId: string;

    @Expose()
    customerId: string;

    @Expose()
    callId: string;

    @Expose()
    @Type(()=>Customer)
    customer: Customer;

    @Expose()
    @Type(()=>Call)
    call: Call;
}