<clr-tabs>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.general.general}}</button>
        <ng-template [(clrIfActive)]="firstTab">
            <clr-tab-content *clrIfActive>
                <div class="card" *ngIf="!loading">
                    <div class="card-header space-between-flex">
                        <div fxLayout="column" >
                            {{ helper.getCardHeader(mode, 'contact', name) }}
                        </div>

                        <clr-dropdown>
                            <button class="dropdown-toggle" clrDropdownTrigger>
                                <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                                <clr-icon shape="caret down" size="20"></clr-icon>
                            </button>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">{{localization.dictionary.view.name}}</label>
                                <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                                <div *ngFor="let view of views" (click)="activeView=view; crm.activeViewChanged(view, 'contact')" clrDropdownItem>
                                    <clr-icon [ngStyle]="{'visibility': (view === activeView) ? 'visible' : 'hidden' }"
                                        style="margin-right: 0.3rem" shape="check"></clr-icon>
                                    {{ view.name }}
                                </div>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </div>
                    <div class="card-block" *ngFor="let section of activeView?.sections">
                        <div class="card-title collapse-card">
                            <span>
                                <clr-icon [attr.shape]="section.icon" class="clr-icon-heading"
                                    role="img"></clr-icon>
                                <span>{{ section.name }}</span>
                                <p class="card-text icon-offset">
                                    {{ section.description }}
                                </p>
                            </span>
                            <clr-icon shape="angle" class="arrow" [dir]="!collapse[section['id']] ? 'down' : 'left'"
                                (click)="collapse[section['id']] = !collapse[section['id']]"></clr-icon>
                        </div>
                        <div [hidden]="collapse[section['id']]" class="card-text">
                            <app-custom-input [model]="contact" [modelSnapshot]="contactSnapshot" [modelString]="'contact'" [fields]="section.fields" (change)="dirty = true"
                                 [(choices)]="conflictChoices" (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)"
                                [conflicts]="conflicts" (sameDV)="checkDV($event)">
                            </app-custom-input>
                        </div>
                    </div>
                    <div stickyFooter class="card-footer">
                        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                    </div>
                </div>
            </clr-tab-content>
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.customer.name}}</button>
        <ng-template [(clrIfActive)]="secondTab">
            <clr-tab-content *clrIfActive>
                <div class="card" *ngIf="!loading">
                    <div class="card-header">
                        {{ helper.getCardHeader(mode, 'contact', name) }}
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            <clr-icon shape="building" class="clr-icon-heading is-solid"></clr-icon>
                            <span>{{localization.dictionary.createEdit.assignedComponent.replace('${componentName}', localization.dictionary.customer.name)}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.contactCreateEdit.deleteModalDescriptionCus}}
                            </p>
                        </div>
                        <p class="card-text icon-offset" *ngIf="this.customerInvalid && this.contact.customers.length == 0">
                            <clr-control-error
                                [style.color]="branding.darkMode? '#f55047' : 'var(--clr-forms-invalid-color)'">
                                {{localization.dictionary.contactCreateEdit.associatingRequired}}</clr-control-error>
                        </p>
                        <div class="card-text">
                         <!--assigned customers-->
                            <app-sublist-customers [conflict]="customerConflict" [current]="contact.id || mode == 'create'" [dataIds]="customerIds" [positions]="positions"
                                (selectedCustomer)="removeCustomer($event)" (editCustomer)="toAdd = $event.customer;
                                 tempPosition = $event.position; editPositionMode = true"
                                 (createCall)="router.navigate(['/crm/call/create/' + $event.id + '/' + contact.id])"></app-sublist-customers>
                                 
                            <form *ngIf="customerConflict" clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                                <div class="clr-form-control list-conflict">
                                    <div class="clr-subtext-wrapper">
                                        <clr-control-helper>
                                            <div>{{localization.dictionary.conflict.manageConflict}}</div>
                                            <clr-radio-container class="conflict">
                                                <clr-radio-wrapper>
                                                    <input type="radio" required clrRadio [value]="'customerconflict' + 1"
                                                        [name]="'customerconflict'" [(ngModel)]="conflictChoices['customers']"
                                                        (ngModelChange)="onConflictChangeCustomer(tempCustomers, 'model')" />
                                                    <label>{{ displayCustomerConflict(tempCustomers) }} ({{localization.dictionary.createEdit.current}})</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input type="radio" required clrRadio [value]="'customerconflict' + 2"
                                                        [name]="'customerconflict'" [(ngModel)]="conflictChoices['customers']"
                                                        (ngModelChange)="onConflictChangeCustomer(customerConflict?.value, 'conflict')" />
                                                    <label>{{ displayCustomerConflict(customerConflict.value) }}
                                                        ({{customerConflict.user?.name}},
                                                        {{customerConflict.date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                                                </clr-radio-wrapper>
                                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                            </clr-radio-container>
                                        </clr-control-helper>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            <clr-icon shape="building" class="clr-icon-heading"></clr-icon>
                            <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.customer.name)}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.contactCreateEdit.assignModalDescriptionCont}}
                            </p>
                        </div>
                        <div class="card-text">
                          <!--assign customers-->
                            <app-sublist-customers [current]="false" [dataIds]="customerIds"
                                (selectedCustomer)="toAdd = $event; tempPosition = positions[toAdd.id]">
                            </app-sublist-customers>
                        </div>
                    </div>
                    <div stickyFooter class="card-footer">
                        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                    </div>
                </div>

            </clr-tab-content>
        </ng-template>
    </clr-tab>
    <clr-tab *ngIf="mode == 'edit' && contact">
        <button clrTabLink>{{ localization.dictionary.auditLogging.auditLogPlural}}</button>
        <clr-tab-content *clrIfActive="auditActive">
            <div class="card">
                <div class="card-header">{{ localization.dictionary.auditLogging.changeHistory }}</div>
                <div class="card-block">
                    <app-audit [entityId]="contact.id" [entityType]="'Contact'"></app-audit>
                </div>
            </div>
            <div stickyFooter class="card-footer">
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
            </div>
        </clr-tab-content>
    </clr-tab>

    <app-spinner *ngIf="loading"></app-spinner>

    <ng-template #actionBar>
        <button class="btn btn-primary" (click)="validateAndSave()">{{ ( mode === 'create' ) ? localization.dictionary.button.create
            : localization.dictionary.button.save }}</button>
        <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
        <!-- Call erstellen -->
        <button class="btn btn-success btn-outline" (click)="validateAndSave(true)">
            {{ localization.dictionary.button.saveAndCall }}
        </button>
    </ng-template>
</clr-tabs>

<clr-modal [(clrModalOpen)]="toAdd">
    <h3 class="modal-title">{{localization.dictionary.contactCreateEdit.conPosition}}</h3>
    <div class="modal-body">
        <form clrForm>
            <clr-input-container>
                <label>{{localization.dictionary.customerContact.position}}</label>
                <input clrInput [(ngModel)]="tempPosition" name="position" maxlength="100">
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button *ngIf="!editPositionMode" type="button" class="btn btn-primary"
            (click)="addCustomer(toAdd)">{{localization.dictionary.button.add}}</button>
        <button *ngIf="editPositionMode" type="button" class="btn btn-primary"
            (click)="changeCustomerPosition(toAdd)">{{localization.dictionary.button.ok}}</button>
        <button type="button" class="btn btn-outline"
            (click)="toAdd = undefined; tempPosition = undefined; editPositionMode=false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="callFromContactModal">
    <h3 class="modal-title">{{localization.dictionary.call.whichCustomer}}</h3>
    <div class="modal-body">
    <div>
        {{ localization.dictionary.call.whichCustomerText }}
    </div>
        <form clrForm>
            <clr-select-container>
                <label>{{localization.dictionary.customer.nameSingular}}</label>
                <select clrSelect [(ngModel)]="selectedCustomerId" name="customer">
                    <option *ngFor="let customer of contact?.customers" [value]="customer.id">{{crm.getDisplayValue(customer)}}</option>
                </select>
            </clr-select-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline"
            (click)="callFromContactModal=false; selectedCustomerId=undefined">{{localization.dictionary.button.cancel}}</button>
        <button *ngIf="!editPositionMode" type="button" class="btn btn-primary"
            (click)="router.navigate(['/crm/call/create/' + selectedCustomerId + '/' + contact.id])" [disabled]="!selectedCustomerId">{{localization.dictionary.button.ok}}</button>
    </div>
</clr-modal>
