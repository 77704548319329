<clr-modal #modal [(clrModalOpen)]="isOpen" (clrModalOpenChange)="onModalStateChange($event)" clrModalSize="xl">
  <div class="modal-title">{{ widgetTitle }}</div>
  <div class="diagram-popup modal-body">
    <!-- <div #modalDiagram class="modal-diagram" [innerHTML]="chartHTML"></div> -->
    <div #modalDiagram  style="width: 90%; justify-content: center;">
      <ng-container  *ngIf="widgetName == 'userInteraction'">
        <ngx-charts-line-chart
          [legend]="true"
          [legendTitle]="''"
          [xAxis]="true"
          [yAxis]="true"
          [timeline]="true"
          [results]="data"
          class ="light-mode-legend popup"
        >
      </ngx-charts-line-chart>
      </ng-container>
      <ng-container *ngIf="widgetName == 'presentation'">
        <clr-icon class="modal-icon" *ngIf="layer === 1" shape="undo" (click)="drillDown()"></clr-icon>
          <ngx-charts-pie-chart
            *ngIf="layer === 0"
            [results]="data"
            [legend]="true"
            [labels]="true"
            [doughnut]="false"
            [legendTitle]="''"
            class="card widget-card text-color light-mode-legend popup"
            (select)="drillDown($event)"
            [view]="[988, 450]"
          >
          </ngx-charts-pie-chart>
          <ngx-charts-bar-vertical
            *ngIf="layer === 1"
            [results]="data"
            [showDataLabel]="true"
            [showGridLines]="false"
            [xAxis]="true"
            [yAxis]="true"
            [yAxisTicks]="yTicks"
            class="card text-color light-mode-legend popup"
            [view]="[988, 450]"
          >
          </ngx-charts-bar-vertical>
        <!-- </div> -->
      </ng-container>
      <ng-container *ngIf="widgetName === 'mail'">
      <ngx-charts-bar-vertical
        [legend]="true"
        [legendTitle]="''"
        [results]="data"
        [xAxis]="true"
        [yAxis]="true"
        class="text-color light-mode-legend popup"
      >
      </ngx-charts-bar-vertical>
    </ng-container>
    <ng-container *ngIf="widgetName === 'customerByTerritory'">
      <ngx-charts-pie-chart
        [results]="data"
        [legend]="true"
        [labels]="false"
        [doughnut]="false"
        [legendTitle]="''"
        class="text-color light-mode-legend popup"
      >
      </ngx-charts-pie-chart>
      </ng-container>
      <ng-container *ngIf="widgetName === 'callByProduct'" >
      <ngx-charts-bar-vertical
        [legend]="true"
        [legendTitle]="''"
        [results]="data"
        [xAxis]="true"
        [yAxis]="true"
        [yAxisTickFormatting]="round"
        class="text-color light-mode-legend popup"
      >
      </ngx-charts-bar-vertical>
    </ng-container>
    <ng-container *ngIf="widgetName === 'callByTerritory'">
      <ngx-charts-pie-chart            
        [results]="data"
        [legend]="true"
        [labels]="false"
        [doughnut]="false"
        [legendTitle]="''"
        class="text-color light-mode-legend popup"  
            
        >
      </ngx-charts-pie-chart>
    </ng-container>
    <ng-container *ngIf="widgetName === 'callByUser'">
      <ngx-charts-pie-chart            
        [results]="data"
        [legend]="true"
        [labels]="false"
        [doughnut]="false"
        [legendTitle]="''"
        class="widget-text light-mode-legend popup"
              
        >
      </ngx-charts-pie-chart>
    </ng-container>

    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-outline"
      (click)="closeModal()"
    >
      {{localization.dictionary.button.close}}
    </button>
    <button
      type="button"
      class="btn btn-sm btn-outline"
      (click)="convertChart()"
    >
      {{ localization.dictionary.button.copyChart }}
    </button>
  </div>
</clr-modal>
