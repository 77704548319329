import { Exclude, Expose, Type } from "class-transformer";
import { Contact } from "../crm/contacts/contact.model";
import { Customer } from "../crm/customers/customer.model";
import { User } from "../users/user.model";
import { Form } from "./form.model";
import { GivenAnswer } from "./given-answer.model";

@Exclude()
export class AnsweredForm {
    @Expose()
    id: string;

    @Expose()
    formId: string; 

    @Expose()
    form: Form;

    @Expose()
    user: User;

    @Expose()
    givenAnswers: GivenAnswer[];

    @Expose()
    @Type( () => Date)
    submittedAt: Date;

    @Expose()
    contactId: string;

    @Expose()
    @Type( () => Contact)
    contact: Contact;

    @Expose()
    customerId: string;

    @Expose()
    @Type( () => Customer)
    customer: Customer;

    @Expose()
    getAnswersByQuestion(questionId: string): string {
        const answers = this.givenAnswers.filter( (answer) =>  answer.questionId === questionId);
        let answerValues = [];
        for (let answer of answers) {
            answerValues.push(answer.value);
        }
        return answerValues.join(', ');
    }
    
}