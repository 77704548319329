import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../../localization/localization.service';

@Component({
  selector: 'app-de-list',
  templateUrl: './de-list.component.html',
  styleUrls: ['./de-list.component.css']
})
export class DeListComponent implements OnInit {

  constructor(
   public localization: LocalizationService
  ) { }

  ngOnInit(): void {
  }

}
