import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClrDatagridFilter, ClrForm } from '@clr/angular';
import { Subject } from 'rxjs';
import { Form } from 'src/app/forms/form.model';

import { ServerService } from 'src/app/server.service';
import { LocalizationService } from '../../../localization/localization.service';
import { AmselError } from '../../error/amsel-error.model';

@Component({
  selector: 'time-filter',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.css']
})
export class TimeComponent implements OnInit {

  @Input() property: string;
  @Input() confirm = false
  @Input() filterFrom: string;
  @Output() filterFromChange = new EventEmitter<string>()
  @Input() filterTo: string;
  @Output() filterToChange = new EventEmitter<string>()
  changes = new Subject<any>();
  open = false
  focus = true
  modelFrom: string;
  modelTo: string;

  activeTo = false
  activeFrom = false

  textQueryChanged: Subject<string> = new Subject<string>();

  value: { value: string, type: string };
  value2: { column: string, value: string, type: string };

  @ViewChild("searchFrom") inputFromRef: ElementRef<HTMLInputElement>
  @ViewChild("searchTo") inputToRef: ElementRef<HTMLInputElement>

  constructor(private filterContainer: ClrDatagridFilter, private server: ServerService,
    public localization: LocalizationService) {
    filterContainer.setFilter(this);
  }


  document = document

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.open && this.focus && (this.inputFromRef || this.inputToRef)) {
      this.focus = false
      setTimeout(() => {
        if (this.inputFromRef)
          this.inputFromRef.nativeElement.focus()
        else
          this.inputToRef.nativeElement.focus()
      })
    }
    if (!this.open && !this.focus) {
      this.focus = true
    }
  }


  ngOnChanges(changes: SimpleChanges) {
   
    
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
       
        if (propName == "filterFrom" && !this.filterFrom) {
          this.value = undefined
          this.modelFrom = undefined;
          this.activeFrom = true
        }
        if (propName == "filterFrom" && this.filterFrom) {
          if (!this.value)
            this.value = { value: this.filterFrom, type: 'from' };
          this.value.value = this.filterFrom;
          const modelDate = new Date(this.filterFrom);
          this.modelFrom = (modelDate.getHours() + ':').padStart(3, '0') + (modelDate.getMinutes() + '').padStart(2, '0')
          this.activeFrom = true;
        }
        if (propName == "filterTo" && !this.filterTo) {
          this.value2 = undefined
          this.modelTo = undefined;
          this.activeTo = true
        }
        if (propName == "filterTo" && this.filterTo) {
          if (!this.value2)
            this.value2 = { column: this.property, value: this.filterTo, type: 'to' };
          this.value2.value = this.filterTo
          const modelDate = new Date(this.filterTo);
          this.modelTo = (modelDate.getHours() + ':').padStart(3, '0') + (modelDate.getMinutes() + '').padStart(2, '0')
          this.activeTo = true
        }
        
      }
    }
  }

  onChange() {
    if (this.confirm) {
      return;
    }
    if(this.modelTo < this.modelFrom ||  this.modelFrom > this.modelTo){
      this.server.addAlert(new AmselError(undefined, 'warning', 
      this.localization.dictionary.toastr.chooseTime
      // 'Bitte andere Start- oder Endzeit wählen!'
      ));
      return;
    } 
    
    if (this.modelFrom) {
      let helperFrom = new Date();
      helperFrom.setTime(0);
      let helper2 = this.modelFrom.split(':');
      helperFrom.setHours(+helper2[0]);
      helperFrom.setMinutes(+helper2[1]);
      this.filterFrom = helperFrom.toISOString();
    }
    if (this.modelTo){
      let helperTo = new Date('01.01.1970');
      let helperTo2 = this.modelTo.split(':');
      helperTo.setHours(+helperTo2[0]);
      helperTo.setMinutes(+helperTo2[1]);
      this.filterTo = helperTo.toISOString();
    }
    this.setValue();
  }

  onClick(reset?: boolean) {
    if (reset) {
      this.filterFrom = undefined
      this.modelFrom = undefined
      this.filterTo = undefined
      this.modelTo = undefined
    }
    this.setValue()
    this.open = false;
    return false
  }

  setValue() {
    if (this.filterFrom) {
      this.value = { value: this.filterFrom, type: "from" };
      this.filterFromChange.emit(this.filterFrom);
    }
    if (this.filterTo) {
      this.value2 = { column: this.property, value: this.filterTo, type: "to" };
      this.filterToChange.emit(this.filterTo);
    }
    this.changes.next('')
  }

  isActive(): boolean {
    return (this.confirm ? !!this.value || !!this.value2 : !!this.filterFrom || !!this.filterTo);
  }
  accepts(): boolean {
    return true
  }

}
