<!--
<div fxFill *ngIf="form && form.answeredForms.length === 0" fxLayoutAlign="center center">
    <h1>
        Noch keine Ergebnisse vorhanden
    </h1>
</div>
-->

<clr-tabs *ngIf="form">
  <clr-tab>
    <button clrTabLink>{{localization.dictionary.formResult.listView}}</button>
    <clr-tab-content *clrIfActive>
      <ng-container *ngTemplateOutlet="listView"></ng-container>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>{{localization.dictionary.formResult.summary}}</button>
    <clr-tab-content *clrIfActive>
      <ng-container *ngTemplateOutlet="summaryView"></ng-container>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>

<ng-template #listView >
  <ng-container *ngTemplateOutlet="actionBar"></ng-container>
  <clr-datagrid *ngIf="questions.length > 0 || form.questions.length == 0" 
              class="bound" (clrDgRefresh)="refresh($event)" 
              [clrDgLoading]="loading"                  
              [(clrDgSelected)]="selected"         
              #datagridRef>
      <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.formResult.name) }}</clr-dg-placeholder>
      <clr-dg-column [clrDgSortBy]="'submittedAt'" [(clrDgSortOrder)]="sorting['submittedAt']" 
      (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['date']">
          {{localization.dictionary.general.date}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="submittedFilter" [(clrDgFilterOpen)]="submittedFilter.open">
          <date-filter #submittedFilter [property]="'submittedAt'" [(filterFrom)]="filters['submittedAtFrom']"
              [(filterTo)]="filters['submittedAtTo']"></date-filter>
        </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column *ngFor="let question of questions" [clrDgSortBy]="getQuestionSortBy(question)" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden[question.id]">
          {{ question.name }}
        </ng-template>
        <clr-dg-filter
            *ngIf="question.type == 'MULTIPLE_CHOICE' || question.type == 'CHECKBOX' || question.type == 'CHOICE' || question.type === 'SELECTION'"
            [clrDgFilter]="multiselectFilter" [(clrDgFilterOpen)]="multiselectFilter.open">
            <multiselect-filter #multiselectFilter [name]="question.name" [searchBy]="'value'"
                [property]="'givenAnswers.' + question.id" [query]="'form/offeredAnswers/byId/' + question.id"
                [(filter)]="filters[question.id]"
                [toggle]="question.type == 'MULTIPLE_CHOICE' || question.type == 'CHECKBOX'">
            </multiselect-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'NUMBER' || question.type == 'RANGE'" [clrDgFilter]="numberFilter"
            [(clrDgFilterOpen)]="numberFilter.open">
            <number-filter #numberFilter [property]="'givenAnswers.' + question.id"
                [(filter)]="filters[question.id]" [offset]="0"></number-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'BOOLEAN'" [clrDgFilter]="booleanFilter"
            [(clrDgFilterOpen)]="booleanFilter.open">
            <boolean-filter #booleanFilter [property]="'givenAnswers.' + question.id" [binary]="true"
                [(filter)]="filters[question.id]" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"></boolean-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'TEXT' || question.type == 'TEXTAREA' || question.type == 'EMAIL'" [clrDgFilter]="stringFilter"
            [(clrDgFilterOpen)]="stringFilter.open">
            <string-filter #stringFilter [property]="'givenAnswers.' + question.id"
                [(filter)]="filters[question.id]"></string-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'DATE'" [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'givenAnswers.' + question.id"
                [(filterFrom)]="filters[question.id + 'From']" [(filterTo)]="filters[question.id + 'To']">
            </date-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'TIME'" [clrDgFilter]="timeFilter"
            [(clrDgFilterOpen)]="timeFilter.open">
            <time-filter #timeFilter [property]="'givenAnswers.' + question.id"
                [(filterFrom)]="filters[question.id + 'From']" [(filterTo)]="filters[question.id + 'To']">
            </time-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'CONTACT'" [clrDgFilter]="contactFilter" [(clrDgFilterOpen)]="contactFilter.open">
          <combobox-filter #contactFilter [property]="'contact'" [name]="localization.dictionary.contact.name"
              [customFields]="true" [query]="'crm/contact/combobox/' + contactView.id" [type]="contactType"
              [(filter)]="filters['contact']" [toggle]="false">
          </combobox-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="question.type == 'CUSTOMER'" [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
          <combobox-filter #customerFilter [property]="'customer'" [name]="localization.dictionary.customer.name"
              [customFields]="true" [query]="'crm/customer/combobox/' + customerView.id" [type]="customerType"
              [(filter)]="filters['customer']" [toggle]="false">
          </combobox-filter>
        </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column [clrDgSortBy]="'user.name'" [(clrDgSortOrder)]="sorting['user']"
      (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['user']">
          {{localization.dictionary.formResultList.capturedByUser}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
          <multiselect-filter #userFilter [name]="localization.dictionary.user.name" [property]="'user.id'" [(filter)]="filters['user']"
              [query]="'user/filter'" [toggle]="false">
          </multiselect-filter>
        </clr-dg-filter>
      </clr-dg-column>
      
      <clr-dg-row *ngFor="let answeredForm of form.answeredForms" [clrDgItem]="answeredForm" [clrDgSelected]="(answeredForm.id == routeQueryAF)? true: false">
        <clr-dg-cell>
          {{ answeredForm.submittedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
          <clr-dg-cell *ngFor="let question of questions">
            <ng-container *ngIf="question.type !== 'SIGNATURE' && question.type !== 'DATE' && question.type != 'TIME' && question.type != 'CC' && question.type != 'BOOLEAN'">
              {{ answeredForm.getAnswersByQuestion(question.id) }}
            </ng-container>
            <ng-container *ngIf="(question.type === 'CUSTOMER' && answeredForm.customer) || (question.type === 'CONTACT' && answeredForm.contact)" class="label label-light-blue" >
              <app-display-name [model]="question.type === 'CUSTOMER' ? answeredForm.customer : answeredForm.contact" [prePostFix]="''"></app-display-name>
            </ng-container>
            <ng-container *ngIf="question.type === 'SIGNATURE'">
              <img class="signature" [src]="answeredForm.getAnswersByQuestion(question.id)">
            </ng-container>
            <ng-container *ngIf="question.type === 'DATE'">
              {{ answeredForm.getAnswersByQuestion(question.id) | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
            </ng-container>
            <ng-container *ngIf="question.type === 'TIME'">
              {{ answeredForm.getAnswersByQuestion(question.id) | date: localization.dictionary.dateFormat.time  : undefined : localization.language.selector }}
            </ng-container>
            <ng-container *ngIf="question.type === 'BOOLEAN'">
              {{ answeredForm.getAnswersByQuestion(question.id) == '1' ? localization.dictionary.general.yes : localization.dictionary.general.no }}
            </ng-container>
          </clr-dg-cell>
        <clr-dg-cell>
          {{ answeredForm.user?.name }}
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-column-toggle></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.formResult.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                  .replace('${first}', '' + (pagination.firstItem + 1))
                  .replace('${last}', '' + (pagination.lastItem + 1))
                  .replace('${total}', total == 1? localization.dictionary.formResult.one : '' + total)
                  .replace('${componentName}', total == 1 ? localization.dictionary.formResult.nameSingular : localization.dictionary.formResult.name)}}
            </span>
        </clr-dg-pagination>
        <span>
          
        </span>
      </clr-dg-footer>
  </clr-datagrid> 
</ng-template>

<ng-template #summaryView>  
  <ng-container *ngTemplateOutlet="actionBar"></ng-container>
  <ng-container *ngFor="let question of form.questions">
    <ng-container *ngIf="!ignoreTypes.includes(question.type)">
      <div class="card">
        <div class="card-header">
          {{ question.name }}
        </div>
        <!-- CHOICE -->
        <div class="card-block" *ngIf="question.type === 'CHOICE' || question.type === 'MULTIPLE_CHOICE' || question.type === 'SELECTION' || question.type == 'BOOLEAN' || question.type === 'CHECKBOX'">
          <div class="card-title">
            {{localization.dictionary.formResultSummary.answer}} {{ form.getCountOfGivenAnswers(question.id) }}
          </div>
          <div class="card-text">    
            <ngx-charts-pie-chart     
            [attr.id]="'chart-' + question.id"       
            [results]="cummAnswers[question.id]"
            [legend]="true"
            [labels]="false"
            [doughnut]="false"
            [legendTitle]="''"
            #chart
            class="widget-text"            
            >
          </ngx-charts-pie-chart>   
          </div>          
        </div>

        <!-- TEXT -->
        <div class="card-block" *ngIf="question.type === 'TEXT' || question.type === 'TEXTAREA' || question.type === 'DATE' || question.type === 'TIME'">
          <div class="card-title">
             {{localization.dictionary.formResultSummary.answer}} {{ form.getCountOfGivenAnswers(question.id) }}
          </div>
          <!-- <div class="card-text">
            
          </div> -->
        </div>        
        <!-- NUMBER & RANGE -->
        <div class="card-block" *ngIf="question.type === 'NUMBER' || question.type === 'RANGE'">
          <div class="card-title" fxLayout="column">
            <div> {{localization.dictionary.formResultSummary.answer}} {{ form.getCountOfGivenAnswers(question.id) }}</div>
            <div>{{localization.dictionary.formResultSummary.average}} {{ getMinMaxAvg(question.id).avg }}</div>
            <!--<div>Min: {{ getMinMaxAvg(question.id).min }}</div>
            <div>Max: {{ getMinMaxAvg(question.id).max }}</div>-->
          </div>
          <div class="card-text">
            <div class="card-text">  
              <ngx-charts-bar-vertical
                [attr.id]="'chart-' + question.id" 
                class="widget-text"
                [legend]="true"
                [legendTitle]="''"           
                [results]="cummAnswers[question.id]"            
                [xAxis]="true"
                [yAxis]="true"  
                [yAxisTickFormatting]="widgetFullTicks"         
                >
              </ngx-charts-bar-vertical>          
                                 
          </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-sm" *ngIf="listTypes.includes(question.type)" (click)="questionDetail = question">  {{localization.dictionary.button.show}} </button>
          <button *ngIf="diagramTypes.includes(question.type) && form.getCountOfGivenAnswers(question.id) > 0" class="btn btn-outline btn-sm" (click)="copyToClipboard(question.id)"> {{localization.dictionary.button.copyChart}}</button>
        </div>
      </div>
      
    </ng-container>
    
  </ng-container>
</ng-template>

<ng-template #actionBar>
  <h2>{{localization.dictionary.formResultList.resultOf.replace('${formName}', form?.name)}}</h2>
  <span>{{ form?.description }}</span><br>
  <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.back}} </button>
  <button class="btn btn-outline" (click)="excelExport()">{{localization.dictionary.button.excelExport}}</button>
  <button *ngIf="selected.length > 0" class="btn btn-danger-outline" (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>
</ng-template>

<clr-modal [(clrModalOpen)]="this.questionDetail">
  <h3 class="modal-title">{{localization.dictionary.formResultSummary.resultOfquestion.replace('${questionName}', this.questionDetail?.name)}}</h3>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="(listTypes.includes(questionDetail?.type)) ? showListDetail : null"></ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="this.questionDetail = undefined">{{localization.dictionary.button.close}}</button>
  </div>
</clr-modal>

<ng-template #showListDetail>
  <table class="table">
    <thead>
      <tr>
        <th class="left">{{localization.dictionary.formResultSummary.value}}</th>
        <th>{{localization.dictionary.formResultSummary.count}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of cummAnswers[this.questionDetail?.id]">
        <td class="left">
          {{ data.name }}
        </td>
        <td>{{ data.value }}</td>          
      </tr>       
    </tbody>
  </table>   
</ng-template>

<clr-modal [(clrModalOpen)]="toDelete">
  <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
  <div class="modal-body">
      <p>{{localization.dictionary.list.deleteModalDescription}}</p>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
      <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
  </div>
</clr-modal>