<h2>{{ localization.dictionary.push.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" 
    [clrDgLoading]="loading"     
    [(clrDgSelected)]="selected"
    #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.push.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="idFilter"
                        [(clrDgFilterOpen)]="idFilter.open">
            <string-filter  #idFilter
                            [property]="'id'" 
                            [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'title'" [(clrDgSortOrder)]="sorting['title']" (clrDgSortedChange)="this.selected = []">
        {{localization.dictionary.push.title}}
        <clr-dg-filter  [clrDgFilter]="titleFilter"
                        [(clrDgFilterOpen)]="titleFilter.open">
            <string-filter  #titleFilter
                            [property]="'title'" 
                            [(filter)]="filters['title']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'msg'" [(clrDgSortOrder)]="sorting['msg']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['msg']">
            {{localization.dictionary.push.message}}
        </ng-template>
        
        <clr-dg-filter  [clrDgFilter]="msgFilter"
                        [(clrDgFilterOpen)]="msgFilter.open">
            <string-filter  #msgFilter
                            [property]="'msg'"
                            [(filter)]="filters['msg']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>    
    <clr-dg-column  [clrDgSortBy]="'updateType'" [(clrDgSortOrder)]="sorting['updateType']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updateType']">
            {{localization.dictionary.push.updateType}}
        </ng-template>
        
        <clr-dg-filter [clrDgFilter]="updateTypeFilter" [(clrDgFilterOpen)]="updateTypeFilter.open">
            <multiselect-filter #updateTypeFilter 
                [name]="localization.dictionary.push.updateType"
                [property]="'updateType'"
                [(filter)]="filters['updateType']" 
                [array]="['NONE', 'APP', 'CATEGORY']"
                [localizationKey]="'pushType'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'groups.name'" [(clrDgSortOrder)]="sorting['groups']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['groups']">
            {{localization.dictionary.group.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="groupsFilter" [(clrDgFilterOpen)]="groupsFilter.open">
            <multiselect-filter #groupsFilter [name]="localization.dictionary.group.name" [property]="'groups'" [query]="'group'"
                [(filter)]="filters['groups']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
   
    <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['users']">
            {{localization.dictionary.pushList.usersNumber}}
        </ng-template>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'sentAt'" [(clrDgSortOrder)]="sorting['sentAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['sentAt']">
            {{localization.dictionary.push.lastDispatch}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="sentFilter"
                        [(clrDgFilterOpen)]="sentFilter.open">
            <date-filter  #sentFilter
                            [property]="'sentAt'" 
                            [(filterFrom)]="filters['sentAtFrom']"
                            [(filterTo)]="filters['sentAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="createdFilter"
                        [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter  #createdFilter
                            [property]="'createdAt'" 
                            [(filterFrom)]="filters['createdAtFrom']"
                            [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'" [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="updatedFilter"
                        [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter  #updatedFilter
                            [property]="'updatedAt'" 
                            [(filterFrom)]="filters['updatedAtFrom']"
                            [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'" [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="deletedFilter"
                        [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter  #deletedFilter
                            [property]="'deletedAt'" 
                            [(filterFrom)]="filters['deletedAtFrom']"
                            [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>    

    <clr-dg-row *ngFor="let push of pushes" [clrDgItem]="push" [clrDgSelectable]="columnChange || !push.deletedAt" [class.deleted]="push.deletedAt">       
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">            
            {{ push.id }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">            
            {{ push.title }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ push.msg }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ push.updateType + ' (' + localization.dictionary.pushType[push.updateType] + ')' }}
        </clr-dg-cell>
        <clr-dg-cell class="labels">
            <span *ngFor="let group of push.groups"
                [pTooltip]="group.description"
                tooltipPosition="top"
                [routerLink]="['/groups/edit/' + group.id]" class="label label-light-blue">
                {{ group.name }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ push.userCount }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ (push.sentAt) ? helper.formatDate(push.sentAt) : localization.dictionary.pushList.notSent }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ helper.formatDate(push.createdAt) }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ helper.formatDate(push.updatedAt) }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="push.deletedAt? null : ['/notifications/push/edit/' + push.id]">
            {{ helper.formatDate(push.deletedAt) }}
        </clr-dg-cell>
       
        <clr-dg-cell *ngIf="!push.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon shape="envelope" *ngIf="push.userCount > 0"
                [pTooltip]="localization.dictionary.pushList.sendMessage
                    .replace('${componentName}', localization.dictionary.push.nameSingular)
                    .replace('${entryName}', push.title)"
                tooltipPosition="left" (click)="pushToSend = push; sendModal = true">
            </clr-icon>

            <clr-icon shape="envelope" *ngIf="push.userCount == 0" style="fill: var(--clr-color-neutral-600)"
                [pTooltip]="localization.dictionary.push.noRecipientsSelected"
                tooltipPosition="left">
            </clr-icon>
            
            <clr-icon shape="pencil" 
                [pTooltip]="localization.dictionary.list.editComponentEntry
                    .replace('${componentName}', localization.dictionary.push.nameSingular)
                    .replace('${entryName}', push.title)"
                tooltipPosition="left" [routerLink]="['/notifications/push/edit/' + push.id]">
            </clr-icon>
            
            <clr-icon shape="trash"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry
                    .replace('${componentName}', localization.dictionary.push.nameSingular)
                    .replace('${entryName}', push.title)"
                tooltipPosition="left" (click)="toDelete = [push]">
            </clr-icon>
        </clr-dg-cell>  
        <clr-dg-cell *ngIf="push.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon shape="backup-restore"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry
                    .replace('${componentName}', localization.dictionary.push.nameSingular)
                    .replace('${entryName}', push.title)"
                tooltipPosition="left" (click)="openRestoreModal(push)">
            </clr-icon>
        </clr-dg-cell>         

    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.push.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.push.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.push.nameSingular : localization.dictionary.push.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.pushList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', selectedPush?.title) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        {{localization.dictionary.pushList.restoreModalDescription}}
        <app-list-selector (selectedChanged)="selectedPush.groups = $event" [preLoaded]="selectedPush?.groups" [label]="localization.dictionary.group.name" [query]="'group'"></app-list-selector>
        <app-list-selector (selectedChanged)="selectedPush.users = $event" [preLoaded]="selectedPush?.users" [label]="localization.dictionary.user.name" [query]="'user/filter?sortBy=name'"></app-list-selector>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/notifications/push/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.push.nameSingular) }}</button>
            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">{{ localization.dictionary.button.resetFilters }}</button>        
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0" (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['notification-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted" (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.push.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>

<app-send-modal *ngIf="sendModal" [sendModal]="sendModal" [push]="pushToSend" (sendPush)="send()" (abort)="sendModal = false; pushToSend = undefined"></app-send-modal>
