import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';
import { TrackingService } from '../../tracking.service';
import { WidgetInterface } from '../widget.interface';
import { HelperService } from 'src/app/helper/helper.service';
import { ModalService } from '../../dashboards/diagram-popup/modal-data.service';
import { BrandingService } from 'src/app/branding/branding.service';

@Component({
  selector: 'app-widget-crm-call-by-user',
  templateUrl: './widget-crm-call-by-user.component.html',
  styleUrls: ['./widget-crm-call-by-user.component.css']
})
export class WidgetCrmCallByUserComponent implements OnInit, WidgetInterface {
  @ViewChild('chart') chart: ElementRef;

  loading: boolean;

  from: Date;
  to: Date;

  data = [];
  isDarkMode: boolean;
  widgetTitel: string;

  constructor(private tracking: TrackingService, 
    public localization: LocalizationService,
    public helper: HelperService,
    private brandingService: BrandingService,
    public modalService: ModalService) { 
      this.helper.loading$.subscribe(({ id, loading }) => {
        if (id === 'crmCallByUser') {
          this.loading = loading;
        }
      });
    }

  async ngOnInit(): Promise<void> {
    await this.refresh();
    this.isDarkMode = this.brandingService.darkMode;
    this.widgetTitel = this.localization.dictionary.crmDashboard.callByUserDashboard
  }

  async refresh(from?: Date, to?: Date) {
    this.loading = true;
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
    const res = await this.tracking.getCallsCountByUser('?' + query.toString()) as any;
    this.data = [];
    for (let data of res) {
      this.data.push({
        name: data.name,
        value: data.count
        }
      );
    }
    this.loading = false;
  }

}
