import { Component, ErrorHandler, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, NgControl, NgForm } from '@angular/forms';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { FieldOption } from '../../crm/fields/field-option.model';
import { FieldValue } from '../../crm/fields/field-value.model';
import { Field } from '../../crm/fields/field.model';
import { LocalizationService } from '../../localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { CrmService } from 'src/app/crm/crm.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SettingService } from 'src/app/settings/setting.service';
import { User } from '../../users/user.model';
import * as uuid from 'uuid';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Dictionary } from '../../localization/dictionary.model';
import { plainToClass } from 'class-transformer';
import { AmselError } from '../error/amsel-error.model';


@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.css']
})
export class CustomInputComponent implements OnInit {

  @ViewChildren(ClrForm) set contentForms(content: ClrForm[]) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      this.customForms.emit(content);
    }
  }

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      content.forEach(con => con.name = this.fields && this.fields.length > 0 ? this.fields[0].SectionField.sectionId : '');
      if (content.toArray()[0]) this.controls = content.toArray()[0].form.controls
      this.customFormDatas.emit(content.toArray());
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  @Input() model: any;
  @Input() modelSnapshot: any
  @Input() fields: Field[];
  @Input() modelString: string;
  @Input() conflicts = {};
  @Input() multi: boolean;
  @Input() unlocked = {};
  @Input() choices = {};
  @Input() newOptionInput: string;
  @Output() choicesChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() unlockedChange: EventEmitter<void> = new EventEmitter();
  @Output() change: EventEmitter<void> = new EventEmitter()
  @Output() customForms: EventEmitter<ClrForm[]> = new EventEmitter<ClrForm[]>()
  @Output() customFormDatas: EventEmitter<NgForm[]> = new EventEmitter<NgForm[]>()
  @Output() sameDV: EventEmitter<[string, FieldValue[]]> = new EventEmitter<[string, FieldValue[]]>();

  multiFieldInput = {}
  filteredFieldOptions = {}
  timeFieldInput = {}
  notReadonly = {}
  dvCount: Field [];
  savedDVs: FieldValue [] = [];
  maxPattern: string;
  minPattern: string;

  handledConflicts = {};
  tempFieldValues = {};
  editOverride = false;

  controls: {[key: string]: AbstractControl} = {}
  allowedToEdit: boolean = false;
  addOption: boolean = false;
  fieldId: string;
  currentField: Field;
  fieldSnapshot: Field;

  constructor(public localization: LocalizationService,
              public server: ServerService,
              public crm: CrmService,
              private auth: AuthService,
              private settings: SettingService,
              ) { }

  async ngOnInit(): Promise<void> {
    // Check if user is allowed to edit field values in create-edit mode
    const allowedRoles = ['field-editor', 'admin', 'crm-editor'];
    if (this.auth.user.roles.some(role => allowedRoles.includes(role.name.toString()))) {
      this.allowedToEdit = true;
    }
    let overrideActive = await this.settings.getBySelector('crmEditorOverride')
    this.editOverride = (this.auth.hasRole(['crm-editor']) && overrideActive.value  == 'true' ) || this.auth.hasRole(['admin']);
    for (let field of this.fields) {
      if (field.fieldOptions && field.fieldOptions.length > 0 && this.model[field.name]) {
        this.model[field.name] = this.model[field.name].filter(fieldValue => fieldValue.fieldOption);
      }
    }
    for (let field of this.fields) {
      if (field.SectionField.readOnly == true && field.SectionField.mandatory) {
        if (!this.model[field.name] || !this.model[field.name][0].value) {
          this.notReadonly[field.name] = true;
        } else if (this.model[field.name].length == 0) {
          this.notReadonly[field.name] = true;
        } else if (this.model[field.name].length == 1 && (!this.model[field.name][0].value || this.model[field.name][0].value == '')) {
          this.notReadonly[field.name] = true;
        }
      }
    }
  }

  setInput(field: Field, value: string) {
    if (this.model[field.name] && this.model[field.name].length > 0) {
      this.model[field.name][0].value = value;
      this.model[field.name].splice(1);
    } else {
      const fieldValue = new FieldValue();
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = value;
      this.model[field.name] = [fieldValue];
    }
  }

  setChoiceInput(field: Field, option: FieldOption, input?: any, ev?) {
    if (this.model[field.name] && this.model[field.name].length > 0) {
      this.model[field.name][0].value = option.label;
      this.model[field.name][0].fieldOption = option;
      this.model[field.name][0].fieldOptionId = option.id;
      this.model[field.name].splice(1);
    } else {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = option.label;
      fieldValue.fieldOption = option;
      fieldValue.fieldOptionId = option.id;
      this.model[field.name] = [fieldValue]
    }
  }

  setBooleanInput(field: Field, value: boolean) {
    if (this.model[field.name] && this.model[field.name].length > 0) {
      this.model[field.name][0].value = value ? 'true' : 'false'
      this.model[field.name].splice(1);
    } else {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = value ? 'true' : 'false';
      this.model[field.name] = [fieldValue]
    }
  }

  setSelectioneInput(field: Field, event: string, input?: any) {
    if (!event) {
      this.model[field.name] = [];
      input && input.triggerValidation && input.triggerValidation();
    }
    const option = field.fieldOptions.find(fieldOption => fieldOption.label == event)
    if (this.model[field.name] && this.model[field.name].length > 0) {
      this.model[field.name][0].value = option.label;
      this.model[field.name][0].fieldOption = option;
      this.model[field.name][0].fieldOptionId = option.id;
      this.model[field.name].splice(1);
    } else {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = option?.label;
      fieldValue.fieldOption = option;
      fieldValue.fieldOptionId = option?.id;
      this.model[field.name] = [fieldValue]
    }
  }


  getCheckboxInput(field: Field, option: FieldOption) {
    if (this.model[field.name]) {
      return this.model[field.name].find(fieldValue =>
        fieldValue.fieldOption && fieldValue.fieldOption.id === option.id)
    }
    return false;
  }

  setCheckboxInput(field, option, event) {
    if (this.model[field.name] && this.model[field.name].length > 0) {
      if (event) {
        const fieldValue = new FieldValue()
        fieldValue.field = field;
        fieldValue.fieldId = field.id;
        fieldValue.value = option.label;
        fieldValue.fieldOption = option;
        fieldValue.fieldOptionId = option.id;
        this.model[field.name].push(fieldValue)
      } else {
        this.model[field.name] = this.model[field.name].filter(fieldValue => fieldValue.fieldOption && fieldValue.fieldOption.id != option.id);
      }
    } else if (event) {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = option.label;
      fieldValue.fieldOption = option;
      fieldValue.fieldOptionId = option.id;
      this.model[field.name] = [fieldValue]
    }
  }

  async checkDV(field: Field, event){
    if (field.display) {
      if (this.savedDVs.length > 0 && !this.savedDVs.find(dv => dv.value == event) ) {
        this.dvCount.push(field);
        let filteredDV = this.savedDVs.filter(dv => dv.fieldId == field.id);
        this.savedDVs = this.savedDVs.filter(dv => dv.fieldId != field.id);
        this.sameDV.emit(['-1', filteredDV]);
      }
      let res;
      if (event.length > 0) {
        res = await this.server.get<FieldValue[], false>('crm/field/values/' + field.id + '/' + this.modelString + '/' + event);
      }
      if (res && res.length > 0) {
        this.dvCount = this.dvCount.filter(fieldDV => fieldDV.id != field.id);
        this.savedDVs.push(...res);
        if (this.dvCount.length == 0) {
          if (this.savedDVs.length > this.dvCount.length) {
            let counter = {};
            for (const dv of this.savedDVs) {
              counter[dv[this.modelString + 'Id']] = counter[dv[this.modelString + 'Id']] ? counter[dv[this.modelString + 'Id']] + 1 : 1;
            }
            this.savedDVs = this.savedDVs.filter(dv => dv[this.modelString + 'Id'] == Object.keys(counter).sort((a, b) => counter[a] + counter[b])[0]);
          }
          this.sameDV.emit(['+1',this.savedDVs]);
        } else {
          this.sameDV.emit(null);
        }
      } else {
        this.sameDV.emit(null);
      }
    }

  }


  getDateInput(field: Field) {
    let tempoDate = new Date();
    let datestring = '';
    if (this.model[field.name] && this.model[field.name].length > 0) {
      tempoDate = new Date(this.model[field.name][0].value);
      datestring = this._formatDateString(tempoDate);
    } else if (field && !this.multi) {
      datestring = this._formatDateString(tempoDate);
      this.setDateInput(field, datestring);
    }
    return datestring;
  }

  _formatDateString(date: Date) {
    return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear();
  }

  setDateInput(field: Field, value: string) {
    let tempoDate = new Date()
    let datePieces = value.split('.');
    tempoDate.setDate(parseInt(datePieces[0]));
    tempoDate.setMonth(parseInt(datePieces[1]) - 1);
    tempoDate.setFullYear(parseInt(datePieces[2]));
    if (this.model[field.name] && this.model[field.name].length > 0) {
      this.model[field.name][0].value = tempoDate.toISOString();
      this.model[field.name].splice(1);
    } else {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = tempoDate.toISOString();
      this.model[field.name] = [fieldValue]
      return fieldValue;
    }
  }



  getMultiChoiceInput(field: Field) {
    if (!this.multiFieldInput[field.name]) {
      if (!this.model[field.name]) {
        this.multiFieldInput[field.name] = [];
      } else {
        this.multiFieldInput[field.name] = this.model[field.name].map((fieldValue) => fieldValue.fieldOption);
      }
    }
    this.filteredFieldOptions[field.name] = field.fieldOptions.filter(option =>
      !this.multiFieldInput[field.name].find(inputOption => inputOption.id == option.id))
    return this.multiFieldInput[field.name]
  }


  setMultiChoiceInput(field: Field, options: FieldOption[], input?: any) {
    if (!options || options.length < 1) {
      this.model[field.name] = [];
      input.triggerValidation();
    } else {
      this.model[field.name] = options.map(option => {
        const fieldValue = new FieldValue()
        fieldValue.field = field;
        fieldValue.fieldId = field.id;
        fieldValue.value = option.label;
        fieldValue.fieldOption = option;
        fieldValue.fieldOptionId = option.id;
        return fieldValue;
      })
    }
    delete this.multiFieldInput[field.name]
    this.getMultiChoiceInput(field);
  }


  getTimeInput(field: Field) {
    if (this.model[field.name] && this.model[field.name].length > 0) {
      let tempoTime = new Date(this.model[field.name][0].value)
      const timeString = tempoTime.getHours().toString().padStart(2, '0') + ':' + tempoTime.getMinutes().toString().padStart(2, '0');
      if (field.name == 'u_start_time' && !this.minPattern) {
        let timePieces = timeString.split(':') as [string, string];
        this.minPattern = this.createTimePattern(timePieces, false);
      }
      if (field.name == 'u_end_time' && !this.maxPattern) {
        let timePieces = timeString.split(':') as [string, string];
        this.maxPattern = this.createTimePattern(timePieces, true);
      }
      return timeString;
    }
  }



  setTimeInput(field: Field, time: any) {
    let tempoTime = new Date()
    tempoTime.setTime(0);
    let timePieces = time.split(':');
    tempoTime.setHours(+timePieces[0]);
    tempoTime.setMinutes(+timePieces[1]);
    if (this.model[field.name] && this.model[field.name].length > 0) {
      this.model[field.name][0].value = tempoTime.toISOString()
      this.model[field.name].splice(1);
    } else {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = tempoTime.toISOString();
      this.model[field.name] = [fieldValue]
      return fieldValue;
    }
    if (field.name == 'u_start_time') {
      this.minPattern = this.createTimePattern(timePieces, false);
    }

    if (field.name == 'u_end_time') {
      this.maxPattern = this.createTimePattern(timePieces, true);
    }
  }

  createTimePattern([hours, minutes]: [string, string], max: boolean) {
    const [hour1, hour2] = hours.split('').map(hourPart => parseInt(hourPart));
    const [minute1, minute2] = minutes.split('').map(minutePart => parseInt(minutePart));
    let pattern = '';
    if (max) {
      pattern += '(' + hour1 + hour2 + ':' + (minute1 != 0 ? '(' : '') + minute1 + '[0-' + minute2 + ']' + (minute1 != 0 ? ('|[0-' + (minute1 - 1) + '][0-9])') : '');
      if (hour2 != 0) {
        pattern += '|' + hour1 + '[0-' + (hour2 - 1) + ']:[0-9]{2}';
      }
      if (hour1 != 0) {
        pattern += '|[0-' + (hour1 - 1) + '][0-9]:[0-9]{2}';
      }
      pattern += ')'
    } else {
      pattern += '(' + hour1 + hour2 + ':' + (minute1 != 9 ? '(' : '') + minute1 + '[' + minute2 + '-9]' + (minute1 != 9 ? ('|[' + (minute1 + 1) + '-9][0-9])') : '');
      if (hour1 == 2 && hour2 != 3 || hour2 != 9) {
        pattern += '|' + hour1 + '[' + (hour2 + 1) + '-9]:[0-9]{2}';
      }
      if (hour1 != 2) {
        pattern += '|[' + (hour1 + 1) + '-2][0-9]:[0-9]{2}';
      }
      pattern += ')'
    }
    return pattern
  }

  //Umgang mit Konflikten


  displayFieldValue(fieldValue: FieldValue[], fieldName?: string) {
    if (!fieldValue) {
      try {
        this.tempFieldValues[fieldName] = JSON.parse(JSON.stringify((this.modelSnapshot ? this.modelSnapshot : this.model)[fieldName]));
      } catch (err) {
        this.tempFieldValues[fieldName] = []
      }
      fieldValue = this.model[fieldName] || [];
    }
    return fieldValue.map((value) => value.value).join(',');
  }

  onConflictChange(fieldName: string, type: 'conflict' | 'model') {
    let clone: FieldValue[];
    if (type == 'conflict') {
      clone = JSON.parse(JSON.stringify(this.conflicts[fieldName].value));
    } else {
      clone = JSON.parse(JSON.stringify(this.tempFieldValues[fieldName]));
    }
    this.model[fieldName] = clone;
    this.choicesChange.emit(this.choices);
  }

  toggleLock(field: Field) {
    if (this.multi) {
      this.unlocked[field.name] = !this.unlocked[field.name];
      delete this.model[field.name];
      this.controls[field.name]?.reset();
      this.unlockedChange.emit();
    }
  }

    // open field option modal and set the current field; also create a snapshot of the current field options
    openFieldOptionModal(fieldId: string) {
      this.addOption = true;
      this.fieldId = fieldId;
      this.currentField = this.fields.find(field => field.id == fieldId);
      this.fieldSnapshot = plainToClass(Field, this.currentField);
    }

  //close field value edit modal and clear input field
  closeModal() {
    this.addOption = false;
    this.newOptionInput = '';
  }

  async saveModalChanges() {
    this.addOption = false;
    this.newOptionInput = '';
    let fieldIndex = this.fields.findIndex(field => field.id == this.fieldId);
     // update the field (options) in the database
    const res = await this.server.put<Field>('crm/field', this.fieldSnapshot);
    // get the updated field options from the response and sort them by order
    this.fieldSnapshot.fieldOptions = res.fieldOptions.sort((a, b) => a.order - b.order);
    // to trigger the change detection, so it's updated in the view
    // change the value of the current field with the changes made to the snapshot
    this.fields[fieldIndex] = this.fieldSnapshot;
    this.server.addAlert(new AmselError(undefined, 'success',  this.localization.dictionary.toastr.successSaved.replace("${componentName}", this.localization.dictionary.field.nameSingular).replace('${entryName}', this.currentField.label)));
  }

  // create a new field option
  createOption(i: number) {
    this.fieldSnapshot.fieldOptions[i] = new FieldOption();
    this.fieldSnapshot.fieldOptions[i].label = this.localization.dictionary.fieldCreateEdit.answer + ' ' + (i + 1);
    this.fieldSnapshot.fieldOptions[i].order = i;
  }


  deleteOption(i: number) {
    this.fieldSnapshot.fieldOptions.splice(i, 1);
    if (i < this.fieldSnapshot.fieldOptions.length) {
      for (let j = 0; j < this.fieldSnapshot.fieldOptions.length; j++) {
        this.fieldSnapshot.fieldOptions[j].order = j;
      }
    }
  }

  // for drag and drop of field options to change the order
  async drop(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.fieldSnapshot.fieldOptions, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.fieldSnapshot.fieldOptions.length; i++) {
      this.fieldSnapshot.fieldOptions[i].order = i;
    }
  }
}
