import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-actions-delete',
  templateUrl: './de-actions-delete.component.html',
  styleUrls: ['./de-actions-delete.component.css']
})
export class DeActionsDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
