<div class="card">
    <div class="card-header">{{localization.dictionary.help.helpTopics}}</div>
    <div class="card-block">
        <h4 class="card-title">{{localization.dictionary.help.generalDescription}}</h4>
        <div class="card-text">
            <clr-tree>
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="file"></clr-icon>
                    {{localization.dictionary.help.documentsForms}}
                    <clr-tree-node>
                        <a (click)="openTopic('forms-create')" class="clr-treenode-link">
                            {{localization.dictionary.help.createForms}}
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
                
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="id-badge"></clr-icon>
                    {{localization.dictionary.sidenav.userManagement}}
                    <clr-tree-node>
                        <a (click)="openTopic('users-create')" class="clr-treenode-link">
                            {{localization.dictionary.help.createUser}}
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
            
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="data-cluster"></clr-icon>
                    {{localization.dictionary.sidenav.crm}}
                    <clr-tree-node>
                        <a (click)="openTopic('contact-create')" class="clr-treenode-link">
                            {{localization.dictionary.customer.name}}
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
            
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="line-chart"></clr-icon>
                    {{localization.dictionary.sidenav.analysis}}
                    <clr-tree-node>
                        <a (click)="openTopic('')" class="clr-treenode-link">
                            {{localization.dictionary.help.trackingData}}
                        </a>
                    </clr-tree-node>
                    
                    <clr-tree-node [clrExpanded]="true">
                        <a (click)="openTopic('')" class="clr-treenode-link">
                            {{localization.dictionary.dashboard.name}}
                        </a>
                    </clr-tree-node>
            
                    <clr-tree-node [clrExpanded]="true">
                        <a (click)="openTopic('')" class="clr-treenode-link">
                            {{localization.dictionary.report.name}}
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
            </clr-tree>
            
        </div>
    </div>
</div>