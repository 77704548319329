import { Exclude, Expose } from "class-transformer";
import { Role } from "../roles/role.model";

@Exclude()
export class Setting {
    @Expose()
    selector: string;

    @Expose()
    name: string;

    @Expose()
    value: string;

    @Expose()
    order: number;

    @Expose()
    type: 'BOOLEAN' | 'STRING' | 'NUMBER' | 'TIME' | 'DATE';

    @Expose()
    description: string;

    @Expose()
    category: string;

    @Expose()
    roles: Role[];

    @Expose()
    get roleNames() {
        return this.roles.map(role => role.name)
    }
}
