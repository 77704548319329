<h2>{{localization.dictionary.event.nameSingular}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" 
            [clrDgLoading]="loading"             
            #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.event.altName) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'date'" [(clrDgSortOrder)]="sorting['date']">
        {{localization.dictionary.general.date}}
        <clr-dg-filter [clrDgFilter]="dateFilter" [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'date'" [(filterFrom)]="filters['dateFrom']"
                [(filterTo)]="filters['dateTo']">
            </date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']">
        {{localization.dictionary.general.name}}
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'type'" [(clrDgSortOrder)]="sorting['type']">
        {{localization.dictionary.type.nameSingular}}
        <clr-dg-filter [clrDgFilter]="typeFilter" [(clrDgFilterOpen)]="typeFilter.open">
            <multiselect-filter #typeFilter [name]="localization.dictionary.type.name" [property]="'type'"
                [(filter)]="filters['type']" [array]="['INFO', 'WARNING', 'ERROR', 'DEBUG']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'msg'" [(clrDgSortOrder)]="sorting['msg']">
        {{localization.dictionary.general.description}}
        <clr-dg-filter [clrDgFilter]="msgFilter" [(clrDgFilterOpen)]="msgFilter.open">
            <string-filter #msgFilter [property]="'msg'" [(filter)]="filters['msg']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'origin'" [(clrDgSortOrder)]="sorting['origin']">
        {{localization.dictionary.event.origin}}
        <clr-dg-filter [clrDgFilter]="originFilter" [(clrDgFilterOpen)]="originFilter.open">
            <string-filter #originFilter [property]="'origin'" [(filter)]="filters['origin']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'table'" [(clrDgSortOrder)]="sorting['table']">
        {{localization.dictionary.general.table}}
        <clr-dg-filter [clrDgFilter]="tableFilter" [(clrDgFilterOpen)]="tableFilter.open">
            <string-filter #tableFilter [property]="'table'" [(filter)]="filters['table']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>  
    <clr-dg-column [clrDgSortBy]="'username'" [(clrDgSortOrder)]="sorting['username']">
        {{localization.dictionary.user.username}}
        <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
            <string-filter #userFilter [property]="'username'" [(filter)]="filters['username']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column> 

    <clr-dg-row *ngFor="let event of events" [clrDgItem]="event" [ngClass]="getClass(event.type)">
        <clr-dg-cell>            
            {{ event.date | date: localization.dictionary.dateFormat.full: undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell>
            {{ event.name }}
        </clr-dg-cell>
        <clr-dg-cell>
            {{ event.type }}
        </clr-dg-cell>
        <clr-dg-cell>            
            {{ event.msg }}
        </clr-dg-cell>
        <clr-dg-cell>
            {{ event.origin }}
        </clr-dg-cell>
        <clr-dg-cell>            
            {{ event.table }}
        </clr-dg-cell>  
        <clr-dg-cell>            
            {{ (event.username) ? event.username : 'System' }}
        </clr-dg-cell>    
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.event.altName)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.event.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.event.nameSingular : localization.dictionary.event.name)}}
            </span>
          </clr-dg-pagination>
       
    </clr-dg-footer>
</clr-datagrid>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button 
                class="btn btn-primary" 
                (click)="checkExportType()"
                [disabled]="total === 0"
                [pTooltip]="total === 0 ? localization.dictionary.list.noDataToExport : null"
                tooltipPosition="bottom">
                {{ localization.dictionary.button.excelExport }}
            </button>
            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">{{ localization.dictionary.button.resetFilters }}</button>
        </clr-dg-action-bar>
    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="exportModal">
    <h3 class="modal-title">{{ localization.dictionary.event.excelModalTitle.replace('${filters}', exportState?.filters?.length > 0 ? localization.dictionary.event.withTheseFilters : localization.dictionary.event.withoutFilter) }}</h3>
    <div class="modal-body">
        <p>{{ localization.dictionary.event.excelModalDescription.replace('${filterStrength}', exportState?.filters?.length > 0 ? localization.dictionary.event.tooWeak : localization.dictionary.event.noFilter) }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="downloadExcel();">{{localization.dictionary.button.confirm}}</button>
        <button type="button" class="btn btn-outline" (click)="exportModal = false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>
