<header class="header header-6">
  <div class="branding">
    <a class="nav-link">
      <!--<clr-icon shape="vm-bug"></clr-icon>-->
      <div class="header-icon" [style.background-image]="settings.header"></div>
      <span class="title">
        {{ settings.title }}
      </span>
    </a>
  </div>
  <div class="header-actions" fxLayout="row" fxLayoutAlign="flex-end center">
    <div class="action" style="opacity: .85;"
     [pTooltip]="branding.darkMode ? localization.dictionary.header.changeLightMode : localization.dictionary.header.changeDarkMode"
     tooltipPosition="bottom">
     
  <clr-toggle-container class="clr-toggle-right" style="margin: 0;">
    <clr-toggle-wrapper>
      <input type="checkbox" clrToggle name="deleted" [ngModel]="!branding.darkMode"
             (ngModelChange)="branding.toggleTheme()" />
      <label>
        <clr-icon [attr.shape]="branding.darkMode ? 'moon' : 'sun'"></clr-icon>
      </label>
    </clr-toggle-wrapper>
  </clr-toggle-container>
</div>
    <div class="action" routerLink="/install" style="opacity: .65;">
      <clr-icon shape="application" size="32"></clr-icon>
      <span class="nav-text">App</span>
    </div>

    <clr-dropdown *ngIf="localization.languages && localization.language">
        <div fxLayoutAlign="center center" clrDropdownTrigger style="opacity: .65">
            <clr-icon shape="language" direction="down" size="32"></clr-icon>
            {{localization.language.selector}}
        </div>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
          <button clrDropdownItem  (click)="localization.localize(language)"
            *ngFor="let language of localization.languages">{{language.name}}
          </button>
        </clr-dropdown-menu>
    </clr-dropdown>

    <div class="action" style="opacity: .65;" (click)="userHelp.helpWanted.next({componentName: null, display: userHelp.isActive? 'off' : 'sidebar'})">
      <clr-icon shape="help" size="30" style="margin-right: 0.25rem;"></clr-icon>
      <span class="nav-text">{{localization.dictionary.sidenav.help}}</span>
    </div>

    <clr-dropdown>
      <div fxLayoutAlign="center center" class="action" clrDropdownTrigger>
        <clr-icon shape="user" size="32"></clr-icon>
        <span class="nav-text">{{ auth?.user?.name }} {{ auth?.impersonater? '(' + auth.impersonater.name + ')':
          ''}}</span>
      </div>
      <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
        <div clrDropdownItem routerLink="/profile">{{localization.dictionary.header.profile}}</div>
        <div clrDropdownItem (click)="logoutModal = true">{{localization.dictionary.button.logOut}}</div>
        <div [hidden]="!auth.impersonater" clrDropdownItem (click)="auth.endImpersonation()"> {{localization.dictionary.header.endImpersonation}} </div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
  <!--
      <div class="header-actions" fxLayout="row" fxLayoutAlign="flex-end center" >   
        <div class="action" routerLink="/profile">
          <clr-icon shape="user" size="32"></clr-icon>
          <span class="nav-text">{{ auth?.user?.name }}</span> 
        </div> 
      </div>
      -->
</header>

<clr-modal [(clrModalOpen)]="logoutModal">
  <h3 class="modal-title">{{localization.dictionary.header.sureLogOut}}</h3>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="logoutModal = false">{{localization.dictionary.button.cancel}}</button>
    <button type="button" class="btn btn-primary" (click)="logoutModal = false; auth.logout()">{{localization.dictionary.button.logOut}}</button>
  </div>
</clr-modal>