import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ModalService } from './modal-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ServerService } from 'src/app/server.service';
import { toCanvas } from 'html-to-image';

@Component({
  selector: 'app-diagram-popup',
  templateUrl: './diagram-popup.component.html',
  styleUrls: ['./diagram-popup.component.css']
})
export class DiagramPopupComponent implements OnInit {
  layer = 0;
  total = 0; 

  from: Date;
  to: Date;

  docName: string;
  docId: string;

  loading = false;
  presentationData: {document: {name: string, id: string}, duration: number, value?: string}[];

  data = [];
  yTicks = [];

  isOpen: boolean;
  widgetName: string;
  chart: any;
  chartHTML: any;
  titelHTML: any;
  widget: { name: string } = { name: '' };

  widgetTitle: string;
  view: any = [823, 426];

  @ViewChild('modalDiagram') chartElement: ElementRef;
  @ViewChild('isDarkMode') isDarkMode: ElementRef;

  constructor(public helper: HelperService,
    public localization: LocalizationService,
    public modalService: ModalService,
    public server: ServerService,
    ) { 
      this.helper.loading$.subscribe(({ id, loading }) => {
        if (id === 'presentation') {
          this.loading = loading;
        }
      });
    }

  async ngOnInit() {
    this.modalService.widget.subscribe(widget => {
      this.isOpen = !!widget;
      this.chart = widget?.chart;
      this.widgetName = widget?.widgetName;
      this.widgetTitle = widget?.title;
      this.data = widget?.data;
      this.chart.legend = true;   
    });
    
    await this.refresh();  
  }
  
  async convertChart() {
    if (this.chart) {
      let canvas = await toCanvas(this.chartElement.nativeElement);
      this.helper.copyCanvasToClipboard(canvas);  
      this.chart.legend = false;   
  }
}

 getWidgetName() {
   return this.widgetName;
 }


round(val: number): string {
  if (val % 1 === 0) {
    return val.toLocaleString();
  } else {
    return '';
  }
}

async refresh(from?: Date, to?: Date, newLayer = 0, docId?: string, docName?: string) {
    this.loading = true;
    this.data = [];    
    this.yTicks = [];
    let uri = '';
    
    if (docName) {
      this.docName = docName;
      this.docId = docId;    
    }

    this.from = from;
    this.to = to;
    
    let query = new URLSearchParams();
    if (from) {
      query.append('from', from.toISOString());
    }
    if (to) {
      query.append('to', to.toISOString());
    }
    
    if (newLayer == 0) { 
      this.docName = undefined;
      this.docId = undefined;      
      uri = 'tracking/presentationEvents?' + query.toString();     
    } else if (newLayer === 1) {   
      query.append('documentId', this.docId)
      uri = 'tracking/slideEvents?' + query.toString();
    }
    
    this.presentationData = await this.server.get(uri) as any;
    this.total = this.presentationData.length;

    for (let presentationEvent of this.presentationData) {     
      if (presentationEvent.document && newLayer === 0) {    
        this.data.push({
          name: presentationEvent.document.name,
          value: Math.round(presentationEvent.duration / 60 * 100) / 100,
          extra: {
            presId: presentationEvent.document.id
          }
        });
      } else if (newLayer === 1) {
        this.data.push({
          name: presentationEvent.value,
          value: Math.round(presentationEvent.duration / 60 * 100) / 100
        })
      }    
    }

    // calc ticks
    if (newLayer == 1 && this.data[0]) {
      for (let i=0; i < this.data[0].value; i += 60) {        
        this.yTicks.push(i);
      }   
    }
    this.loading = false;
  }
  
  async drillDown(event?) {
    if (this.layer == 0) {      
      await this.refresh(this.from, this.to, 1, event.extra.presId, event.name);      
      this.layer = 1;
    } else {      
      await this.refresh(this.from, this.to);
      this.layer = 0;
    }    
    this.view = [823, 426];
  }
  
  closeModal() {
    this.isOpen = false;
    if(this.widget) {
      this.chart.legend = false;
    }
  }
  
  onModalStateChange(open: boolean) {
    if(!open && this.chart){
      this.chart.legend = false;
    } 
  }
}