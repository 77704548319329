<clr-icon *ngIf="isActive()" class="refresh-filter is-solid" shape="filter-off" (click)="onClick(true)"></clr-icon>
<clr-combobox-container>
    <label>{{this.localization.dictionary.filter.searched.replace("${name}",name)}}</label>
    <clr-combobox #combobox [(ngModel)]="selectedList" (ngModelChange)="selectionChanged($event)" name="combobox"
        [clrLoading]="loading" (clrInputChange)="comboboxQueryChanged.next($event)"
        (clrOpenChange)="$event ? comboboxQueryChanged.next('') : null" clrMulti="true">
        <ng-container *clrOptionSelected="let selected">
            <app-display-name [model]="selected" [prePostFix]="''"></app-display-name>
        </ng-container>
        <clr-options>
            <clr-option *ngFor="let entry of availableList" [clrValue]="entry">
                <app-display-name [model]="entry" [prePostFix]="''"></app-display-name>
            </clr-option>
        </clr-options>
    </clr-combobox>
</clr-combobox-container>
<clr-toggle-container *ngIf="!array && toggle">
    <label>{{this.localization.dictionary.filter.allRequired.replace("${name}", name)}}</label>
    <clr-toggle-wrapper>
        <input type="checkbox" clrToggle name="options" value="AndOr" [(ngModel)]="andToggle"
            (ngModelChange)="andOrChange($event)" />
        <label>{{ andToggle? localization.dictionary.general.yes : localization.dictionary.general.no}}</label>
    </clr-toggle-wrapper>
</clr-toggle-container>
<button *ngIf="confirm" class="btn btn-primary btn-sm"
    (click)="onClick()">{{localization.dictionary.button.confirm}}</button>