import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-view-edit',
  templateUrl: './de-view-edit.component.html'
})
export class DeViewEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
