import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ServerService } from 'src/app/server.service';

@Directive({
  selector: '[duplicate]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: DuplicateValidator, multi: true}]
})
export class DuplicateValidator implements AsyncValidator {

  constructor(private server: ServerService) { }

 async validate(control: AbstractControl): Promise<ValidationErrors | null> {   
    const validRes = await this.server.get('crm/field/byName/' + control.value);
    if (!!validRes)
        return {'duplicate': true};
  }
}
