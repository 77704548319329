<p><span style="font-weight: bold; color: var(--warning);">Hinweis:</span> Zu Dokumenten zählen sowohl Dateien als auch Formulare.</p>
<h4>Dokumenten Maske aufrufen</h4>
<ol class="list">
    <li>Klicken Sie im Menü unter auf den Punkt <span style="font-weight: bold;">„<clr-icon style="cursor: text;" shape="tree-view"></clr-icon> Kategorien“</span> und wählen sie die entsprechende Kategorie aus.</li>
    <li>Zum hinzufügen eines neuen Dokumentes klicken Sie auf den Button „Dokument Hinzufügen“.</li>
    <li>Es öffnet sich ein neues Fenster zur Eingabe der Informationen.</li>
</ol>
<h4>Dokumenten Informationen befüllen</h4>
<ol class="list">
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Vergeben Sie einen aussagekräftigen Namen für das Dokument.</li>
    <li>Fügen Sie anschließend eine Beschreibung hinzu, die das Dokument genauer bezeichnet. Hiernach kann später auch gesucht bzw. gefiltert werden. </li>
    <li>Wählen Sie den Typ des Dokumentes:</li>
    <ul class="list">
        <li><span style="font-weight: bold;">Datei: </span>Für bereits erstellte Dokumente auf Ihrem Gerät. Klicken Sie hierfür auf „Durchsuchen“ und wählen Sie das gewünschte Dokument.</li>
        <li><span style="font-weight: bold;">Formular: </span>Für bereits erstellte Formulare in Ihrer amSel. Wählen Sie das gewünschte Formular aus der Dropdownliste</li>
    </ul>
    <li>Abhängig von Ihrem Dokumententyp können Sie weitere Auswahlmöglichkeiten für die Dokumentensicht erhalten.</li>
    <ul class="list">
        <li><span style="font-weight: bold;">Untereinander: </span></li>
        <li><span style="font-weight: bold;">Einzelseite: </span></li>
        <li><span style="font-weight: bold;">Buchansicht: </span></li>
    </ul>
    <li>Anschließend können Sie ein Icon für das Dokument zuordnen. Wenn Sie keines auswählen, erhält das Dokument automatisch das Icon „ <clr-icon shape="folder"></clr-icon>Ordner“ </li>
</ol>