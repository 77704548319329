<h4>Basis Informationen</h4>
<ol class="list">
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Vergeben Sie einen aussagekräftigen Namen für die Kategorie.</li>
    <li>Ergänzen Sie eine Beschreibung, die den Inhalt der dort abgelegten Dokumente näher definiert. Dies verhindert, dass Dokumente falsch abgelegt werden. </li>
    <li>Wählen Sie ein Icon aus der Dropdownliste aus, z.B. Klemmbrett für Checklisten oder Protokolle. Dies gibt dem App-Nutzer eine zusätzliche optische Orientierung auf der Startseite.</li>
    <li>Um die Kategorie auf der Startseite der App anzeigen zu lassen setzen Sie einen Haken bei „Aktiv“ und „Im Hauptmenü“. Zum Ausblenden der Kategorie entfernen Sie den Haken bei „Aktiv“. </li>
<h4 style="position: relative; right: 1.1em;">Gruppen zuordnen</h4>
    <li>Zum Schluss ordnen Sie der Kategorie noch eine oder mehrere Benutzergruppen zu, um die Kategorie für diese Gruppen in der App sichtbar zu machen. </li>
<h4 style="position: relative; right: 1.1em;">Aktionen</h4>
    <li>Zum Speichern der erstellten Kategorie klicken Sie auf Erstellen</li>
</ol>


<!-- <h4>Zum Speichern der erstellten Kategorie klicken Sie auf<button class="btn btn-primary btn-help">Erstellen</button></h4> -->
<p><span style="font-weight: bold; font-size: larger; color: var(--danger);">Bitte beachten:</span>  Die Kategorie wird erst dann in der App sichtbar, wenn ihr auch mindestens ein Dokument zugeordnet wurde. </p>