import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { LocalizationService } from "../localization/localization.service";
import { AuditLog } from "./audit.model";
import { Customer } from "../crm/customers/customer.model";
import { ServerService } from "../server.service";
import { plainToClass } from "class-transformer";
import { CrmService } from "../crm/crm.service";
import { ClrDatagrid, ClrDatagridStateInterface, ClrDatagridStringFilterInterface } from "@clr/angular";
import { DatagridFunctions } from "../helper/datagrid-functions.class";


@Component({
  selector: "app-audit",
  templateUrl: "./audit.component.html",
  styleUrls: ["./audit.component.css"]
})
export class AuditComponent implements OnInit, OnChanges {
  @Input() entityId: string;
  @Input() entityType: "Customer" | "Contact" | "Call";

  auditLogs: AuditLog[] = [];
  auditHidden: { [column: string]: boolean } = {};
  selectedAuditLogs: AuditLog[] = [];
  sorting = {};
  filters = {};
  columnChange = false;
  columnChanged = false;
  total = 0;
  loading: boolean;
  state: ClrDatagridStateInterface;
  hasTrackableFields = true;
  trackableFieldsMessage: string;




  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  deleteFilters: boolean;

  constructor(
    public localization: LocalizationService,
    public server: ServerService,
    public crm: CrmService,
  ) {
    this.loading = true;
  }

  stringFilter(item: AuditLog, search: string) {
  }

  async ngOnInit(): Promise<void> {
    this.trackableFieldsMessage = this.getTrackableFieldsMessage(this.entityType);
    this.hasTrackableFields = await this.crm.checkForTrackableFields(this.entityType);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "entityId") {
          this.resetFilters();
        }
      }
    }
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = (<any>this.datagrid)?.stateProvider?.state;
    } if (!state) {
      return;
    }
    if (state.page.from == -1 || state.page.to == -1) {
      state.page.from = 0;
      state.page.to = 10;
      state.page.size = 10;
    }
    this.state = state;
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const audit = await this.server.get<AuditLog>(`audit-logs/${this.entityId}` + query);
    this.total = audit.count;
    this.auditLogs = plainToClass(AuditLog, audit.rows);
    this.loading = false;
  }

  async resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid?.columns?.find(column => column.sorted);
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    if (this.state) {
      delete this.state.filters;
      delete this.state.sort;
    }
    this.deleteFilters = true;
    await this.refresh(this.state);
  }

  makeStrong(value: string) {
    return value.replace(/^/, "<strong>").replace(/$/, "</strong>");
  }

  onSelectionChange(selectedItems: AuditLog[]) {
    this.selectedAuditLogs = selectedItems;
  }

  hidChange() {
    if (this.columnChanged) return;
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false;
    });
  }

  private getTrackableFieldsMessage(entityType: 'Customer' | 'Contact' | 'Call'): string {
    switch (entityType) {
      case 'Customer':
        return this.localization.dictionary.auditLogging.noTrackableFieldsForCustomer;
      case 'Contact':
        return this.localization.dictionary.auditLogging.noTrackableFieldsForContact;
      case 'Call':
        return this.localization.dictionary.auditLogging.noTrackableFieldsForCall;
      default:
        return this.localization.dictionary.auditLogging.noTrackableFields;
    }
  }

  localizeBoolean(value: boolean): string {
    return value ? this.localization.dictionary.general.yes : this.localization.dictionary.general.no;
  }
}
