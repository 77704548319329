export const mapIcons = {
    'SECTION': 'add-text',
    'CHOICE': 'unknown-status',
    'MULTIPLE_CHOICE': 'checkbox-list',
    'SELECTION': 'view-list',
    'TEXT': 'note',
    'TEXTAREA': 'details',
    'NUMBER': 'number-list',
    'RANGE': 'slider',
    'SIGNATURE': 'highlighter',
    'DATE': 'event',
    'TIME': 'clock',
    'DESCRIPTION': 'text',
    'EMAIL': 'envelope',
    'REFERENCE': 'link',
    'BOOLEAN': 'check',
    'CHECKBOX': 'check-circle'
}