<h2>{{localization.dictionary.role.name}}</h2>
<table class="table">
    <thead>
      <tr>
        <th class="left">{{localization.dictionary.general.name}}</th>
        <th class="left">{{localization.dictionary.general.description}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let role of roles">
        <td class="left">{{ role.name }}</td>
        <td class="left">
          {{localization.dictionary.roleDescription[role.name]}}
        </td>
      </tr>
    </tbody>
  </table>
