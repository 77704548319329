import { group } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryDocument } from 'src/app/categories/category-document.model';
import { Category } from 'src/app/categories/category.model';
import { Document } from 'src/app/documents/document.model';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { SettingService } from 'src/app/settings/setting.service';
import { Unsubscribable } from '../../helper/unsubscribable/unsubscribable';
import { Doc } from 'prettier';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.css']
})
export class ListDocumentsComponent extends Unsubscribable implements OnInit, DatagridFunctions {
  category: Category;
  catId: string;
  showDeleted = false;
  selected: Document[] = [];
  toDelete: Document[];
  toUnlink: Document;

  saving = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  loading = true;
  downloading = {};
  total: number;
  order: boolean;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    viewType: true,
    sendPerMail: true,
    printable: true,
    form: true,
    createdAt: true,
    updatedAt: true
  };
  state: ClrDatagridStateInterface;

  restoreModal = false;
  assignModal = false;
  reactivateModal = false;
  assignOne: boolean;
  selectedDocument: Document;

  columnChange = false;
  columnChanged = false;

  constructor(
    private route: ActivatedRoute, 
    public server: ServerService, 
    public auth: AuthService, 
    public setting: SettingService,
    public localization: LocalizationService) {
    super();
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.ngOnDestroy();
        this.catId = params.catId || this.catId;
        this.getLocalStorage();
        this.showDeleted = !!localStorage.getItem('doc-deleted');
        const resC = await this.server.get('category/byId/' + this.catId);
        this.category = plainToClass(Category, resC.rows.find(cat => cat.id == this.catId));
        await this.refresh();
        if (localStorage.getItem('doc-cat-id') != this.catId) {
          localStorage.setItem('doc-cat-id', this.catId)
          this.selected = [];
          // this.resetFilters();
        }
      })
    );
  }

  ngOnInit(): void {
    

  }

  getLocalStorage(){
    //gets the local storage based on the param/catId in the header
    const filter = localStorage.getItem(`${this.catId}-filters`);
    const sorting = localStorage.getItem(`${this.catId}-sorting`);
    const hidden = localStorage.getItem(`${this.catId}-columns`);
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
    this.showDeleted = !!localStorage.getItem('doc-deleted');
  }

  ngOnDestroy() {
    //sets the local storage based on the param/catId in the header
    if(this.catId){
      localStorage.setItem(`${this.catId}-filters`, JSON.stringify(this.filters));
      localStorage.setItem(`${this.catId}-sorting`, JSON.stringify(this.sorting));
      localStorage.setItem(`${this.catId}-columns`, JSON.stringify(this.hidden));
      localStorage.setItem('doc-cat-id', this.catId)
    }

    if (this.showDeleted)
      localStorage.setItem('doc-deleted', 'true')
    else
      localStorage.removeItem('doc-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    if (this.order && state.filters) {
      this.order = false
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    // if (!this.showDeleted)
    //   query += '&includeDocuments=true';
    // const res = await this.server.get('category/byId/' + (this.showDeleted ? 'allDocuments/' : '') + this.catId + '/' + query);
    const res = await this.server.get('document/byCategoryIds/' + (this.showDeleted ? 'allDocuments/' : '') + this.catId + query);
    if (res.count > 0) {
      //this.category = plainToClass(Category, res.rows[0].categories[0]);
      const documents = plainToClass(Document, res.rows);
      this.category.documents = documents;
      this.category.documents.forEach((doc) => doc.CategoryDocument = doc.categories.find(cat => cat.id == this.catId).CategoryDocument);
    } else {
      this.category.documents = [];
    }
    this.total = res.count;
    this.loading = false;
  }

  selectionChange(documents: Document[]) {
    if (documents.length > 0) {
      this.order = false;
    }
  }

  async toggleDeleted() {
    this.order = false;
    this.selected = [];
    await this.refresh();
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting = {};
      const sortedColumn = this.datagrid.columns.find(column => column.sorted)
      if (sortedColumn) {
        sortedColumn.sorted = false;
      }
      this.refresh();
    } else {
      this.server.getCategories();
    }
    this.order = !this.order;
  }

  async drop(event: CdkDragDrop<Document[]>, pagination) {
    this.loading = true;
    this.saving = true;
    const docOrder = [];
    moveItemInArray(this.category.documents, event.previousIndex, event.currentIndex);
    this.category.documents.forEach((doc, index) => {
      index = ( pagination.currentPage - 1 ) * pagination.pageSize + index;
      doc.CategoryDocument.order = index;
      docOrder.push({ docId: doc.id, order: index });
    })
    await this.server.put('document/updateOrder/' + this.category.id, docOrder);
    this.saving = false;
    this.loading = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const docIds = this.toDelete.map((doc) => doc.id);
    await this.server.delete('document?toDelete=' + docIds);
    this.saving = false;
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
        this.toDelete.length == 1 ? this.localization.dictionary.document.nameSingular : this.localization.dictionary.document.name)));
    this.toDelete = undefined;
    this.selected = [];
    this.refresh();
  }

  async openAssignModal(id?: string) {
    if (id) {
      this.selectedDocument = (await this.server.get("document/includeCats/" + id)) as any;
      this.assignOne = true
    } else {
      this.selectedDocument = new Document()
      this.assignOne = false
    }
    this.assignModal = true
  }

  async unlink(){
    this.loading = true;
    this.saving = true;
    this.toUnlink.categories = this.toUnlink.categories.filter(cat => cat.id != this.catId);
    await this.server.put(`document/unlink/${this.catId}/${this.toUnlink.id}`, {})
    this.saving = false;
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successUnlinked.replace("${componentName}", this.localization.dictionary.document.nameSingular)));
    this.toUnlink = undefined;
    this.refresh();
  }

  async openRestoreModal(id: string) {
    this.selectedDocument = (await this.server.get("document/deletedIncludeCats/" + id)) as any;
    this.restoreModal = true
  }

  async saveAssign() {
    this.saving = true;
    this.selectedDocument.groups = this.selectedDocument.groups || []
    this.selectedDocument.users = this.selectedDocument.users || []
    if (this.assignOne)
      await this.server.put('document', { documentString: JSON.stringify(this.selectedDocument) })
    else
      await this.server.put('document/updateAssign', {
        documentIds: this.selected.map(item => item.id),
        userIds: this.selectedDocument.users.map(user => user.id),
        groupIds: this.selectedDocument.groups.map(group => group.id)
      })
    this.saving = false;
    this.assignModal = false
    this.selectedDocument = undefined
    if (!this.assignOne)
      this.selected = [];
  }

  async restore() {
    await this.server.put('document/restore', { documentString: JSON.stringify(this.selectedDocument) });
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.document.name).replace("${entryName}", this.selectedDocument.name)));
    this.refresh();
    this.restoreModal = false;
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters
    this.refresh(this.state)
  }

  async testSync() {
    const res = await this.server.get('user/sync');
  }

  async download(document: Document) {
    this.downloading[document.id] = true;
    await this.server.documentDownload(document, !!document.deletedAt);
    this.downloading = {};
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  canReactivate(doc: Document) {
    if (!doc.form || doc.form.isActive || doc.isActive){
      return true;
    }
    if (this.auth.hasRole(['form-editor', 'admin'])) {
      return true;
    }
    return false;
  }

  onToggleActive(doc: Document) {
    if (doc.isActive || !doc.form || doc.form.isActive)
      this.toggleActive(doc);
    else {
    this.reactivateModal = true;
    this.selectedDocument = doc;
    }
  }

  async toggleActive(doc: Document) {
    this.reactivateModal = false;
    await this.server.put('document/toggleActive/' + doc.id, {});
    if (doc.form && !doc.isActive){
      await this.server.put('form/reactivate/' +  doc.formId, doc.form);
    }
    this.refresh();
  }
}
