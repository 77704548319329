<h2 *ngIf="mode=='default'">{{ localization.dictionary.file.name }}</h2>

<app-file-upload-modal [mode]='mode' (uploaded)="refresh($event)"></app-file-upload-modal>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>
<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selectedUFiles"
    (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'filename'" [(clrDgSortOrder)]="sorting['filename']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['filename']">
            {{ localization.dictionary.file.filename }}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'filename'" [(filter)]="filters['filename']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'desc'" [(clrDgSortOrder)]="sorting['desc']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['desc']">
            {{ localization.dictionary.general.description }}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="descFilter" [(clrDgFilterOpen)]="descFilter.open">
            <string-filter #descFilter [property]="'desc'" [(filter)]="filters['desc']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'fileExtension'" [(clrDgSortOrder)]="sorting['fileExtension']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['fileExtension']">
            {{ localization.dictionary.file.types }}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="fileExtensionFilter" [(clrDgFilterOpen)]="fileExtensionFilter.open">
            <multiselect-filter #fileExtensionFilter [name]="localization.dictionary.file.types" [property]="'fileExtension'" [toggle]="false" [array]="queryArray"
                [(filter)]="filters['parent']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="mode=='default'" [clrDgSortBy]="'customers.fieldValues.' + crm.sortField.customer"
        [(clrDgSortOrder)]="sorting['customers']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['customers']">
            {{localization.dictionary.customer.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <combobox-filter #customerFilter [property]="'customer'" [name]="localization.dictionary.customer.nameSingular"
                [customFields]="true" [query]="'crm/customer/combobox/' + customerView.id" [type]="customerType"
                [(filter)]="filters['customer']" [toggle]="false">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="mode=='default'" [clrDgSortBy]="'calls.fieldValues.' + crm.sortField.call"
        [(clrDgSortOrder)]="sorting['calls']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['calls']">
            {{localization.dictionary.call.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="callFilter" [(clrDgFilterOpen)]="callFilter.open">
            <combobox-filter #callFilter [property]="'call'" [name]="localization.dictionary.call.nameSingular"
                [customFields]="true" [query]="'crm/call/combobox/' + callView.id" [type]="callType"
                [(filter)]="filters['call']" [toggle]="false">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selectedUFiles = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
          <clr-dg-filter [clrDgFilter]="createdAtFilter" [(clrDgFilterOpen)]="createdAtFilter.open">
            <date-filter #createdAtFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
          </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let uFile of uFiles" [clrDgItem]="uFile">
        <clr-dg-cell (click)="getFile(uFile)">{{ uFile.id }}</clr-dg-cell>
        <clr-dg-cell (click)="getFile(uFile)">{{ uFile.filename }}</clr-dg-cell>
        <clr-dg-cell (click)="getFile(uFile)">{{ uFile.desc }}</clr-dg-cell>
        <clr-dg-cell (click)="getFile(uFile)">
            <span [ngSwitch]="uFile.fileExtension">
                <clr-icon shape="pdf" *ngSwitchCase="'pdf'"></clr-icon>
                <clr-icon shape="image" *ngSwitchCase="'png'"></clr-icon>
                <clr-icon shape="image" *ngSwitchCase="'jpg'"></clr-icon>
                <clr-icon shape="image" *ngSwitchCase="'jpeg'"></clr-icon>
                <clr-icon shape="text" *ngSwitchCase="'txt'"></clr-icon>
                <clr-icon shape="text" *ngSwitchCase="'doc'"></clr-icon>
                <clr-icon shape="text" *ngSwitchCase="'docx'"></clr-icon>
                <clr-icon shape="table" *ngSwitchCase="'xls'"></clr-icon>
                <clr-icon shape="table" *ngSwitchCase="'xlsx'"></clr-icon>
                <clr-icon shape="pie-chart" *ngSwitchCase="'ppt'"></clr-icon>
                <clr-icon shape="pie-chart" *ngSwitchCase="'pptx'"></clr-icon>
                <clr-icon shape="file" *ngSwitchDefault></clr-icon>
            </span>
            {{ uFile.fileExtension }}</clr-dg-cell>
        <clr-dg-cell *ngIf="mode=='default'" (click)="getFile(uFile)">
            <span class="label label-light-blue" *ngIf="uFile.customer">
                <app-display-name [model]="uFile.customer" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="mode=='default'" (click)="getFile(uFile)">
            <span class="label label-light-blue"  *ngIf="uFile.call">
                <app-display-name [model]="uFile.call" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>
        <clr-dg-cell (click)="getFile(uFile)">
            {{ uFile.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>

        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="download"
                [pTooltip]="localization.dictionary.language.download.replace('${entryName}', uFile.filename)"
                tooltipPosition="left"
                (click)="getFile(uFile)">
            </clr-icon>
            <clr-icon 
                shape="trash"
                [pTooltip]="localization.dictionary.file.delete.replace('${entryName}', uFile.filename)"
                tooltipPosition="left"
                (click)="toDelete = [uFile]">
            </clr-icon>            
        </clr-dg-cell>

    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="total">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.documentCreateEdit.filePlural)}}</clr-dg-page-size>
          <span>
            {{localization.dictionary.file.filesTotal
                .replace('${first}', '' + (pagination.firstItem + 1))
                .replace('${last}', '' + (pagination.lastItem + 1))
                .replace('${total}', '' + pagination.totalItems)
                .replace('${component}', pagination.totalItems == 1? localization.dictionary.file.nameSingular : localization.dictionary.file.name)}}
          </span>
        </clr-dg-pagination>
      </clr-dg-footer>

    <ng-template #actionBar>
        <div style="height: 2.4rem" fxLayout="row" fxLayoutAlign="space-between flex-end">
            <clr-dg-action-bar>
                <button *ngIf="mode!='default'" class="btn btn-primary" (click)="fus.open.next(componentId)">{{ localization.dictionary.file.add }}</button>

                <button *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()" class="btn btn-outline">
                    {{ localization.dictionary.button.resetFilters }}
                </button>
                <button class="btn btn-danger-outline" *ngIf="selectedUFiles.length > 0" (click)="toDelete = selectedUFiles">{{ localization.dictionary.button.delete }}</button>
            </clr-dg-action-bar>

        </div>

        <clr-modal [(clrModalOpen)]="toDelete">
            <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
            <div class="modal-body">
                <p>{{ localization.dictionary.file.deleteModalDescription }}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="delete()">{{ localization.dictionary.button.delete }}</button>
                <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{ localization.dictionary.button.cancel }}</button>
            </div>
        </clr-modal>

    </ng-template>
