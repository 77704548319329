<h2>{{ localization.dictionary.field.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)"
            [clrDgLoading]="loading"
            [(clrDgSelected)]="selected"
            #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.field.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'label'" [(clrDgSortOrder)]="sorting['label']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['label']">
            {{localization.dictionary.field.label}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="labelFilter" [(clrDgFilterOpen)]="labelFilter.open">
            <string-filter #labelFilter [property]="'label'" [(filter)]="filters['label']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['name']">
            {{localization.dictionary.general.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'type'" [(clrDgSortOrder)]="sorting['type']" (clrDgSortedChange)="selected = []">
        {{localization.dictionary.type.nameSingular}}
        <clr-dg-filter [clrDgFilter]="typeFilter" [(clrDgFilterOpen)]="typeFilter.open">
            <multiselect-filter #typeFilter
                [name]="localization.dictionary.type.name"
                [property]="'type'"
                [(filter)]="filters['type']"
                [array]="['TEXT', 'TEXTAREA', 'CHECKBOX', 'NUMBER', 'BOOLEAN', 'CHOICE', 'MULTIPLE_CHOICE', 'DATE', 'TIME', 'SELECTION']"
                [localizationKey]="'fieldType'"
                >
                </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <!-- <clr-dg-column [clrDgSortBy]="'category.name'" [(clrDgSortOrder)]="sorting['category']" (clrDgSortedChange)="selected = [];">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['category']">
            Kategorie
        </ng-template>
        <clr-dg-filter [clrDgFilter]="categoryFilter" [(clrDgFilterOpen)]="categoryFilter.open">
            <string-filter #categoryFilter [property]="'category.name'" [(filter)]="filters['category']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>  -->
    <clr-dg-column [clrDgSortBy]="'appliesToCustomer'" [(clrDgSortOrder)]="sorting['appliesToCustomer']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['appliesToCustomer']">
            {{localization.dictionary.customer.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="appliesToCustomerFilter" [(clrDgFilterOpen)]="appliesToCustomerFilter.open">
            <boolean-filter #appliesToCustomerFilter [property]="'appliesToCustomer'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['appliesToCustomer']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'appliesToContact'" [(clrDgSortOrder)]="sorting['appliesToContact']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['appliesToContact']">
            {{localization.dictionary.contact.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="appliesToContactFilter" [(clrDgFilterOpen)]="appliesToContactFilter.open">
            <boolean-filter #appliesToContactFilter [property]="'appliesToContact'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['appliesToContact']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'appliesToCall'" [(clrDgSortOrder)]="sorting['appliesToCall']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['appliesToCall']">
            {{localization.dictionary.call.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="appliesToCallFilter" [(clrDgFilterOpen)]="appliesToCallFilter.open">
            <boolean-filter #appliesToCallFilter [property]="'appliesToCall'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['appliesToCall']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'isActive'" [(clrDgSortOrder)]="sorting['isActive']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['isActive']">
            {{localization.dictionary.general.active}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="isActiveFilter" [(clrDgFilterOpen)]="isActiveFilter.open">
            <boolean-filter #isActiveFilter [property]="'isActive'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['isActive']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'trackChanges'" [(clrDgSortOrder)]="sorting['trackChanges']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['trackChanges']">
            {{localization.dictionary.auditLogging.auditLogSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="trackChangesFilter" [(clrDgFilterOpen)]="trackChangesFilter.open">
            <boolean-filter #trackChangesFilter [property]="'trackChanges'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['trackChanges']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'display'" [(clrDgSortOrder)]="sorting['display']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['display']">
            {{localization.dictionary.fieldCreateEdit.displayValue}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="displayFilter" [(clrDgFilterOpen)]="displayFilter.open">
            <boolean-filter #displayFilter [property]="'display'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [(filter)]="filters['display']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>
    <!--
    <clr-dg-column [clrDgSortBy]="'appliesTo'" [(clrDgSortOrder)]="sorting['appliesTo']" (clrDgSortedChange)="selected = []">
        Verwendet für
        <clr-dg-filter [clrDgFilter]="appliesToFilter" [(clrDgFilterOpen)]="appliesToFilter.open">
            <multiselect-filter #appliesToFilter
                [name]="'Verwendet für'"
                [property]="'appliesTo'"
                [(filter)]="filters['appliesTo']"
                [array]="['CUSTOMER', 'FORM']">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    -->
    <clr-dg-row *ngFor="let field of fields" [clrDgItem]="field" [clrDgSelectable]="!field.deletedAt && field.name != 'u_date' && field.name != 'u_email'" [class.deleted]="field.deletedAt" >
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.id }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.label }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.name }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.type }} ({{localization.dictionary.fieldType[field.type]}})
        </clr-dg-cell>
        <!-- <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.category?.name }}
        </clr-dg-cell> -->
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ (field.appliesToCustomer) ? localization.dictionary.general.yes : localization.dictionary.general.no  }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ (field.appliesToContact) ? localization.dictionary.general.yes : localization.dictionary.general.no  }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ (field.appliesToCall) ? localization.dictionary.general.yes : localization.dictionary.general.no  }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ (field.isActive) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ (field.trackChanges) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ (field.display) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted"
            [routerLink]="field.deletedAt? null : ['/crm/field/edit/' + field.id]">
            {{ field.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>

        <!-- Actions-->
        <clr-dg-cell *ngIf="!field.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="pencil"
                [routerLink]="['/crm/field/edit/' + field.id]" 
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.field.nameSingular).replace('${entryName}', field.name)" 
                tooltipPosition="left">
            </clr-icon>
            
            <clr-icon *ngIf="field.name != 'u_date' || field.name != 'u_email'"
                shape="trash"
                (click)="onDelete([field])" 
                [pTooltip]=" localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.field.nameSingular).replace('${entryName}', field.name)" 
                tooltipPosition="left">
            </clr-icon>
            
        </clr-dg-cell>

        <clr-dg-cell *ngIf="field.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">          
            <clr-icon 
                shape="backup-restore" 
                (click)="openRestoreModal(field)" 
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.field.nameSingular).replace('${entryName}', field.name)" 
                tooltipPosition="left">
            </clr-icon>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.field.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.field.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.field.nameSingular : localization.dictionary.field.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" [routerLink]="'/crm/field/create'">
                {{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.field.nameSingular) }}
            </button>
            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="onDelete(selected)">{{localization.dictionary.button.delete}}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['field-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted" (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.field.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <div *ngIf="toDeleteSections && toDeleteSections.length > 0">
            {{localization.dictionary.fieldList.followingSections}}
        <ul>
            <li *ngFor="let section of toDeleteSections">{{'"' + section.name + '"'}}
                {{localization.dictionary.fieldList.ofView}}
               {{'"' + section.view.name + '"'}}
            </li>
        </ul> {{localization.dictionary.fieldList.willBeDeleted}}
        </div>

        <div *ngIf="referencedFields.length > 0">
            {{ localization.dictionary.fieldList.alreadyInForm.replace('${field}', referencedFields.length > 1 ? localization.dictionary.fieldList.atLeastOne : localization.dictionary.fieldList.theSelected)  }}<br>
            {{localization.dictionary.fieldList.linksRemoved}} <br>
            {{localization.dictionary.fieldList.dataLost}}
        </div>
        <p>{{localization.dictionary.fieldList.deleteModalDescription}}</p>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined; referencedFields = [];">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.field.nameSingular).replace('${entryName}', selectedField?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{localization.dictionary.button.restore}}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>
