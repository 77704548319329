import { CalendarNativeDateFormatter, DateFormatterParams } from "angular-calendar";

/**
 * Ändert die Dartstellung der Stunden im Kalender auf 24h Format
 */
export class CustomDateFormatter extends CalendarNativeDateFormatter {
    public weekViewHour({ date, locale }: DateFormatterParams): string {
      return new Intl.DateTimeFormat('de-DE', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(date);
    }
    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return new Intl.DateTimeFormat('de-DE', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(date);
      }
  }