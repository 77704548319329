import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-territory-edit',
  templateUrl: './de-territory-edit.component.html'
})
export class DeTerritoryEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
