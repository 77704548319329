import { Injectable } from '@angular/core';
import { ClrCommonStringsService } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Language } from 'src/app/localization/language.model';
import { ServerService } from 'src/app/server.service';

export type AmselIcon = {
  element: string,
  label: string
}

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  language: Language;
  languages: Language[];


  dictionary: { [category: string]: { [element: string]: string } } = {};

  languageChanged = new Subject();
  languageModified = new Subject<Language>();
  languagesDeleted = new Subject<string[]>();
  notDelete = new Subject<Language[]>();

  amselIconList: AmselIcon[] = []

  constructor(
    public server: ServerService,
    private commonStrings: ClrCommonStringsService
  ) {
    this.languageModified.subscribe((language) => {
      if (language.id != "13bdf866-eb10-4794-a19e-dadc87a4365b"|| '3fcfd0e6-cd71-4a6e-a5bd-54c25588792e') {
        this.languages.push(language);
      }      
    });

    this.notDelete.subscribe((languages: []) => {
      this.languages = languages.find(lang => lang == this.language.id)
      this.init();
    });

    this.languagesDeleted.subscribe((langIds: string[]) => {
      this.languages = this.languages.filter(lang => langIds.find(langId => langId != lang.id))
      this.init();
    })
  }

  async init() {
    const res = await this.server.get('language?active=' + JSON.stringify({ type: 'boolean', value: true }));
    this.languages = plainToClass(Language, res.rows);
    const langId = localStorage.getItem('language-id');
    this.language = this.languages.find(lang => lang.id == (langId || "13bdf866-eb10-4794-a19e-dadc87a4365b"));
    if (!this.language) {
      this.language = this.languages.find(lang => lang.id == "13bdf866-eb10-4794-a19e-dadc87a4365b");
    }
    await this.localize();
  }

  async localize(language = this.language) {
    this.language = language;
    localStorage.setItem('language-id', language.id);
    this.amselIconList = [];
    const res = await this.server.get('dictionary/byLang/' + language.id) as any;
    /*     console.log(clarityDic); */
    for (let dictionaryEntry of res.filter(dictionary => dictionary.languageCategory.name != 'amselIcons')) {
      this.dictionary[dictionaryEntry.languageCategory.name] = this.dictionary[dictionaryEntry.languageCategory.name] || {}
      this.dictionary[dictionaryEntry.languageCategory.name][dictionaryEntry.element] = dictionaryEntry.label
    }

    // Fill all empty dictionary entries that have a base category defined
    for (let dictionaryEntry of res.filter(dictionary => !dictionary.label && dictionary.base)) {
      this.dictionary[dictionaryEntry.languageCategory.name][dictionaryEntry.element] = this.dictionary[dictionaryEntry.base][dictionaryEntry.element]
    }
    this.amselIconList = res.filter(dictionary => dictionary.languageCategory.name == 'amselIcons').sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0));
    this.commonStrings.localize(this.dictionary.clarity);
    this.languageChanged.next('');

  }

}
