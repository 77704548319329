import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { LocalizationService } from '../../../localization/localization.service';


@Component({
  selector: 'boolean-filter',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.css']
})
export class BooleanComponent implements ClrDatagridFilterInterface<Boolean>, OnChanges {
  value: { value: 'true' | 'false' | '1' | '0', type: "boolean" } = { value: undefined, type: "boolean" }
  changes = new Subject<any>();
  @Input() nameTrue: string;
  @Input() nameFalse: string;
  @Input() property: string;
  @Input() confirm = false;
  @Input() filter: 'true' | 'false' | '1' | '0';
  @Input() binary = false;
  @Output() filterChange = new EventEmitter<'true' | 'false' | '1' | '0'>()
  open = false

  constructor(private filterContainer: ClrDatagridFilter, public localization: LocalizationService) {
    filterContainer.setFilter(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "filter") {
          if (!this.filter) {
            this.value.value = undefined;
          }
          else {
            this.value.value = this.filter;
            this.filterChange.next(this.filter)
            this.changes.next('')
          }
        }
      }
    }
  }

  ngOnInit() {
    
  }

  onChange(event) {
    this.filter = event.target.value;
    if (!this.confirm) {
      this.value.value = this.filter
      this.filterChange.next(this.filter)
      this.changes.next('')
    }
  }

  onClick(reset?: boolean) {
    if (reset) {
      this.value.value = undefined
      this.filter = undefined;
    }
    this.filterChange.next(this.filter)
    this.changes.next('')
    this.open = false
  }

  isActive(): boolean {
    return !!this.value.value
  }

  accepts() {
    return true;
  }
}