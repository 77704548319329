import { Exclude, Expose, Type } from "class-transformer";
import { User } from "../users/user.model";
import { Widget } from "./widgets/widget.model";

@Exclude()
export class Dashboard {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    description: string;

    @Expose()
    active: boolean;

    @Type(() => Widget)
    @Expose()
    widgets: Widget[];

    @Type(() => User)
    @Expose()
    users: User[];
}