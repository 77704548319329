import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NoPermissionComponent } from './auth/no-permission/no-permission.component';
import { BrandingComponent } from './branding/branding.component';
import { CreateEditCategoryComponent } from './categories/create-edit-category/create-edit-category.component';
import { ListCategoriesComponent } from './categories/list-categories/list-categories.component';
import { CreateEditDocumentComponent } from './documents/create-edit-document/create-edit-document.component';
import { ListDocumentsComponent } from './documents/list-documents/list-documents.component';
import { EventlogComponent } from './eventlog/eventlog.component';
import { CreateEditFormsComponent } from './forms/create-edit-forms/create-edit-forms.component';
import { ListFormsComponent } from './forms/list-forms/list-forms.component';
import { CreateEditGroupComponent } from './groups/create-edit-group/create-edit-group.component';
import { ListGroupsComponent } from './groups/list-groups/list-groups.component';
import { UnsavedGuard } from './helper/guards/unsaved.guard';
import { NotFoundComponent } from './helper/not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './users/profile/profile.component';
import { RolesComponent } from './roles/roles.component';
import { SettingsComponent } from './settings/settings.component';
import { CreateEditUserComponent } from './users/create-edit-user/create-edit-user.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
import { ListPushNotificationsComponent } from './notifications/push/list-push-notifications/list-push-notifications.component';
import { CreateEditPushNotificationComponent } from './notifications/push/create-edit-push-notification/create-edit-push-notification.component';
import { ListResultsComponent } from './forms/list-results/list-results.component';
import { InstallComponent } from './settings/install/install.component';
import { ListReportsComponent } from './analytics/reports/list-reports/list-reports.component';
import { EventsByOsComponent } from './analytics/reports/events-by-os/events-by-os.component';
import { ListFieldsComponent } from './crm/fields/list-fields/list-fields.component';
import { CreateEditFieldComponent } from './crm/fields/create-edit-field/create-edit-field.component';
import { ListCustomersComponent } from './crm/customers/list-customers/list-customers.component';
import { CreateEditCustomerComponent } from './crm/customers/create-edit-customer/create-edit-customer.component';
import { ListContactsComponent } from './crm/contacts/list-contacts/list-contacts.component';
import { CreateEditContactsComponent } from './crm/contacts/create-edit-contacts/create-edit-contacts.component';
import { OverviewTerritoriesComponent } from './crm/territories/overview-territories/overview-territories.component';
import { CreateEditTerritoryComponent } from './crm/territories/create-edit-territory/create-edit-territory.component';
import { ListProductsComponent } from './crm/products/list-products/list-products.component';
import { CreateEditProductComponent } from './crm/products/create-edit-product/create-edit-product.component';
import { CreateEditCallComponent } from './crm/calls/create-edit-call/create-edit-call.component';
import { ListCallComponent } from './crm/calls/list-call/list-call.component';
import { CreateEditViewsComponent } from './crm/views/create-edit-views/create-edit-views.component';
import { ListViewsComponent } from './crm/views/list-views/list-views.component';
import { HelpStartComponent } from './user-doc/help-start/help-start.component';
import { ListConflictsComponent } from './crm/conflict/list-conflicts/list-conflicts.component';
import { ListLanguagesComponent } from './localization/list-languages/list-languages.component';
import { CreateEditLanguageComponent } from './localization/create-edit-language/create-edit-language.component';
import { FileUploadListComponent } from './crm/file-upload/file-upload-list/file-upload-list.component';
import { CalendarComponent } from './calendar/calendar.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'analytics-user']}},
  { path: 'login', component: LoginComponent, data: { hideHeader: true, hideSideNav: true } },
  { path: 'category', canActivate: [AuthGuard],  data: { roles: ['admin', 'category-editor']}, children: [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    { path: 'list', component: ListCategoriesComponent},
    { path: 'list:query', component: ListCategoriesComponent},
    { path: 'create', component: CreateEditCategoryComponent, canDeactivate: [UnsavedGuard]},
    { path: 'edit/:id', component: CreateEditCategoryComponent, canDeactivate: [UnsavedGuard]},    
  ]},
  { path: 'documents', canActivate: [AuthGuard], data: { roles: ['admin', 'document-editor']}, children: [    
    { path: 'list/:catId', component: ListDocumentsComponent},
    { path: 'create/:catId', component: CreateEditDocumentComponent, canDeactivate: [UnsavedGuard]},
    { path: 'create/:catId/:formId', component: CreateEditDocumentComponent, canDeactivate: [UnsavedGuard]},
    { path: 'edit/:id', component: CreateEditDocumentComponent, canDeactivate: [UnsavedGuard]},
  ]},
  { path: 'forms', canActivate: [AuthGuard], data: { roles: ['admin', 'form-editor']}, children: [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    { path: 'list', component: ListFormsComponent},
    { path: 'create', component: CreateEditFormsComponent, canDeactivate: [UnsavedGuard]},
    { path: 'edit/:id', component: CreateEditFormsComponent, canDeactivate: [UnsavedGuard]},
    { path: 'results/:id', component: ListResultsComponent }
  ]},
  { path: 'groups', canActivate: [AuthGuard], data: { roles: ['admin', 'group-editor']}, children: [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    { path: 'list', component: ListGroupsComponent},
    { path: 'create', component: CreateEditGroupComponent, canDeactivate: [UnsavedGuard]},
    { path: 'edit/:id', component: CreateEditGroupComponent, canDeactivate: [UnsavedGuard]}
  ]},
  { path: 'roles', canActivate: [AuthGuard], data: { roles: ['admin', 'group-editor', 'user-editor']}, component: RolesComponent},
  { path: 'user', canActivate: [AuthGuard], data: { roles: ['admin', 'user-editor']}, children: [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    { path: 'list', component: ListUsersComponent},
    { path: 'create', component: CreateEditUserComponent, canDeactivate: [UnsavedGuard]},
    { path: 'edit/:id', component: CreateEditUserComponent, canDeactivate: [UnsavedGuard]}
  ]},
  { path: 'analytics', canActivate: [AuthGuard], data: { roles: ['admin', 'analytics-user']}, children: [
    { path: 'reports', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListReportsComponent},
      { path: 'events-by-os', component: EventsByOsComponent}
    ]}
  ]},
  { path: 'notifications', canActivate: [AuthGuard], data: { roles: ['admin', 'notification-editor']}, children: [
    { path: 'push', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListPushNotificationsComponent },
      { path: 'create', component: CreateEditPushNotificationComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditPushNotificationComponent, canDeactivate: [UnsavedGuard]},
    ]}
  ]},
  { path: 'crm', children: [
    { path: 'field',  canActivate: [AuthGuard], data: { roles: ['admin', 'field-editor']}, children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListFieldsComponent},
      { path: 'create', component: CreateEditFieldComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditFieldComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'customer', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListCustomersComponent},
      { path: 'create', component: CreateEditCustomerComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditCustomerComponent, canDeactivate: [UnsavedGuard]},
      { path: 'conflict/:conflictId', component: CreateEditCustomerComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'contact', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListContactsComponent},
      { path: 'create', component: CreateEditContactsComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditContactsComponent, canDeactivate: [UnsavedGuard]},
      { path: 'conflict/:conflictId', component: CreateEditContactsComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'product', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListProductsComponent},
      { path: 'create', component: CreateEditProductComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditProductComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'call', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListCallComponent},
      { path: 'create/:customerId/:contactId', component: CreateEditCallComponent, canDeactivate: [UnsavedGuard]},
      { path: 'create/:customerId', component: CreateEditCallComponent, canDeactivate: [UnsavedGuard]},
      { path: 'create', component: CreateEditCallComponent, canDeactivate: [UnsavedGuard]},      
      { path: 'edit/:id', component: CreateEditCallComponent, canDeactivate: [UnsavedGuard]},
      { path: 'conflict/:conflictId', component: CreateEditCallComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'territory',  canActivate: [AuthGuard], data: { roles: ['admin', 'crm-editor']}, children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full'},
      { path: 'overview', component: OverviewTerritoriesComponent},
      { path: 'create', component: CreateEditTerritoryComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditTerritoryComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'view', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListViewsComponent},
      { path: 'create', component: CreateEditViewsComponent, canDeactivate: [UnsavedGuard]},
      { path: 'edit/:id', component: CreateEditViewsComponent, canDeactivate: [UnsavedGuard]},
      { path: '**', component: NotFoundComponent }
    ]},   
    { path: 'conflict',  canActivate: [AuthGuard], data: { roles: ['admin', 'crm-editor']}, children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: ListConflictsComponent},
      { path: '**', component: NotFoundComponent }
    ]},
    { path: 'file', children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list', component: FileUploadListComponent},
      { path: '**', component: NotFoundComponent }
    ]},  
    { path: '**', component: NotFoundComponent }   
    ]
  },
  { path: 'language',  canActivate: [AuthGuard], data: { roles: ['admin', 'language-editor']}, children: [
    { path: '', redirectTo: 'list', pathMatch: 'full'},
    { path: 'list', component: ListLanguagesComponent},
    { path: 'create', component: CreateEditLanguageComponent, canDeactivate: [UnsavedGuard]},
    { path: 'edit/:id', component: CreateEditLanguageComponent, canDeactivate: [UnsavedGuard]},
    { path: '**', component: NotFoundComponent }
  ]},
  { path: 'calendar', canActivate: [AuthGuard], data: { roles: ['user', 'crm-editor', 'admin']}, component: CalendarComponent},
  { path: 'branding', canActivate: [AuthGuard], data: { roles: ['admin', 'branding-editor']}, component: BrandingComponent},
  { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent},
  { path: 'settings', canActivate: [AuthGuard], data: { roles: ['admin', 'settings-editor']}, component: SettingsComponent},
  { path: 'install', component: InstallComponent, data: { hideHeader: true, hideSideNav: true }},
  { path: 'eventlog', canActivate: [AuthGuard], data: { roles: ['admin']}, component: EventlogComponent},
  { path: 'no-permission', component: NoPermissionComponent},
  { path: 'help', component: HelpStartComponent},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]

})
export class AppRoutingModule { }
