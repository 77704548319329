import { Directive } from '@angular/core';

@Directive({
  selector: '[track-scroll]',
  host: {'(window:scroll)': 'track($event)'},
})
export class ScrollDirective {

  constructor() { }

  track($event) {
    //console.debug("Scroll Event", $event);
  }
}
