<!-- audit.component.html -->

  <div>
    <ng-container *ngIf="hasTrackableFields; else noTrackableFields">
    <p *ngIf="!auditLogs || !auditLogs.length">{{localization.dictionary.auditLogging.noLogsAvailable}}</p>
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrLoading]="loading" #datagridRef>
      <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="auditHidden['fieldName']">
          {{localization.dictionary.auditLogging.changedFieldName}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="fieldLabelFilter" [(clrDgFilterOpen)]="fieldLabelFilter.open">
          <multiselect-filter #fieldLabelFilter [name]="localization.dictionary.field.name" [property]="'field.id'" [(filter)]="filters['field']" [display]="'label'"
          [query]="'crm/field/filter'" [toggle]="false">
      </multiselect-filter>
      </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="auditHidden['oldValue']">
          {{localization.dictionary.auditLogging.oldValue}}
        </ng-template>
          <clr-dg-filter [clrDgFilter]="oldValueFilter" [(clrDgFilterOpen)]="oldValueFilter.open">
          <string-filter #oldValueFilter [property]="'oldValue'" [(filter)]="filters['oldValue']"></string-filter>
          </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="auditHidden['newValue']">
          {{localization.dictionary.auditLogging.newValue}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="newValueFilter" [(clrDgFilterOpen)]="newValueFilter.open">
          <string-filter #newValueFilter [property]="'newValue'" [(filter)]="filters['newValue']"></string-filter>
      </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="auditHidden['changedBy']">
          {{localization.dictionary.auditLogging.changedBy}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
          <multiselect-filter #userFilter [name]="localization.dictionary.user.name" [property]="'user.id'" [(filter)]="filters['user']"
              [query]="'user/filter'" [toggle]="false">
          </multiselect-filter>
        </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column
        [clrDgSortBy]="'timestamp'"
        [(clrDgSortOrder)]="sorting['timestamp']">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="auditHidden['timestamp']">
          {{localization.dictionary.auditLogging.dateOfModification}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="timestampFilter" [(clrDgFilterOpen)]="timestampFilter.open">
          <date-filter #timestampFilter [property]="'timestamp'" [(filterFrom)]="filters['timestampFrom']"
              [(filterTo)]="filters['timestampTo']"></date-filter>
        </clr-dg-filter>
      </clr-dg-column>

      <clr-dg-row
        *ngFor="let log of auditLogs"
        [clrDgItem]="log"
        [clrDgDetailOpenLabel]="'Open details for ' + log.fieldName"
        [clrDgDetailCloseLabel]="'Close details for ' + log.fieldName"
      >
        <clr-dg-cell>{{ log.field.label }}</clr-dg-cell>
          <clr-dg-cell>{{ log.field.type === 'BOOLEAN' ? localizeBoolean(log.oldValue === 'true') : log.oldValue }}</clr-dg-cell>
          <clr-dg-cell>{{ log.field.type === 'BOOLEAN' ? localizeBoolean(log.newValue === 'true') : log.newValue }}</clr-dg-cell>
        <clr-dg-cell>{{ log.user?.name }}</clr-dg-cell>
        <clr-dg-cell>{{ log.timestamp | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}</clr-dg-cell>
      

        <clr-dg-row-detail *clrIfExpanded="false">
          <div class="clr-row" style="flex-direction: column;">
            <div [innerHTML]="localization.dictionary.auditLogging.changesMade.replace('${oldValue}', makeStrong(log.oldValue)).replace('${newValue}', makeStrong(log.newValue)).replace('${when}', log.timestamp | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector )"></div>
            <div [innerHTML]="localization.dictionary.auditLogging.changeByWhichUser.replace('${userWhoChangedValue}', makeStrong(log.user.name))"></div>
          </div>
        </clr-dg-row-detail>
      </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="total">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.auditLogging.auditLogPlural)}}</clr-dg-page-size>
          <span>
            {{localization.dictionary.auditLogging.auditLogsTotal
                .replace('${first}', '' + (pagination.firstItem + 1))
                .replace('${last}', '' + (pagination.lastItem + 1))
                .replace('${total}', pagination.totalItems == 1? localization.dictionary.auditLogging.auditLogSingular : '' + pagination.totalItems)
                .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.auditLogging.auditLogSingular : localization.dictionary.auditLogging.auditLogPlural)}}
          </span>
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </ng-container>
    <ng-template #noTrackableFields>
      <p>{{trackableFieldsMessage}}</p>
    </ng-template>
  </div>
  