<clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">
    <a *ngIf="auth.hasRole(['admin', 'analytics-user'])" clrVerticalNavLink routerLinkActive="active" routerLink="dashboard">
        <clr-icon shape="home" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.sidenav.home}}
    </a>

    <!-- Kategorien -->
    <clr-vertical-nav-group *ngIf="auth.hasRole(['admin', 'document-editor', 'category-editor'])"
        routerLinkActive="active">
        <clr-icon shape="tree-view" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.category.name}}
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <ng-container *ngIf="auth.hasRole(['admin', 'document-editor'])">
                <a *ngFor="let cat of server.categories" clrVerticalNavLink [routerLink]="'/documents/list/' + cat.id"
                    routerLinkActive="active">
                    <clr-icon [attr.shape]="cat.icon"></clr-icon>
                    {{ cat.name }}
                </a>
            </ng-container>
            <a *ngIf="server.categories.length === 0 && auth.hasRole(['admin', 'category-editor'])" clrVerticalNavLink
                routerLink="category">
                {{localization.dictionary.category.noCategories}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'category-editor'])" clrVerticalNavLink class="border-top"
                routerLink="category" routerLinkActive="active">
                <clr-icon shape="note"></clr-icon>
                {{localization.dictionary.category.editCategories}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>

    <!-- Formulare -->
    <a *ngIf="auth.hasRole(['admin', 'form-editor']) && settings.featureActiveList['formActive']" clrVerticalNavLink
        routerLinkActive="active" routerLink="forms">
        <clr-icon shape="form" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.form.name}}
    </a>

    <!-- CRM -->
    <clr-vertical-nav-group *ngIf="settings.featureActiveList['crmActive']"
        routerLinkActive="active">
        <clr-icon shape="data-cluster" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.sidenav.crm}}
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a clrVerticalNavLink routerLink="crm/customer" routerLinkActive="active">
                <clr-icon shape="building"></clr-icon>
                {{localization.dictionary.customer.name}}
            </a>
            <a clrVerticalNavLink routerLink="crm/contact" routerLinkActive="active">
                <clr-icon shape="user"></clr-icon>
                {{localization.dictionary.contact.name}}
            </a>
            <a clrVerticalNavLink routerLink="crm/call" routerLinkActive="active">
                <clr-icon shape="talk-bubbles"></clr-icon>
                {{localization.dictionary.call.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'crm-editor'])" clrVerticalNavLink routerLink="crm/product" routerLinkActive="active">
                <clr-icon shape="briefcase"></clr-icon>
                {{localization.dictionary.product.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'crm-editor'])" clrVerticalNavLink routerLink="crm/territory" routerLinkActive="active">
                <clr-icon shape="map"></clr-icon>
                {{localization.dictionary.territory.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'crm-editor'])" clrVerticalNavLink routerLink="crm/conflict" routerLinkActive="active">
                <div class="conflict-flex-box"><div class="flex-box-second"><clr-icon shape="form"></clr-icon>
                &nbsp;{{localization.dictionary.conflict.name}}</div>
                <div ><span class="badge badge-warning" *ngIf="conflictCount > 0">{{conflictCount}}<span class="clr-sr-only" ></span></span></div>
            </div>
            </a>
            <a *ngIf="auth.hasRole(['admin', 'crm-editor'])" clrVerticalNavLink routerLink="crm/field" routerLinkActive="active">
                <clr-icon shape="add-text"></clr-icon>
                {{localization.dictionary.field.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'crm-editor'])" clrVerticalNavLink routerLink="crm/view" routerLinkActive="active">
                <clr-icon shape="eye"></clr-icon>
                {{localization.dictionary.view.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'crm-editor'])" clrVerticalNavLink routerLink="crm/file" routerLinkActive="active">
                <clr-icon shape="upload-cloud"></clr-icon>
                {{localization.dictionary.file.name}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>

    <a *ngIf="settings.featureActiveList['crmActive']" clrVerticalNavLink routerLinkActive="active" routerLink="calendar">
        <clr-icon shape="calendar" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.sidenav.calendar}}
    </a>

    <!-- Benutzerverwaltung -->
    <clr-vertical-nav-group *ngIf="auth.hasRole(['admin', 'user-editor', 'role-editor', 'group-editor'])"
        routerLinkActive="active">
        <clr-icon shape="employee-group" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.sidenav.userManagement}}
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a *ngIf="auth.hasRole(['admin', 'user-editor'])" clrVerticalNavLink routerLinkActive="active"
                routerLink="user">
                <clr-icon shape="id-badge" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.user.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'group-editor'])" clrVerticalNavLink routerLinkActive="active"
                routerLink="groups">
                <clr-icon shape="users" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.group.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'group-editor', 'user-editor'])" clrVerticalNavLink routerLinkActive="active"
                routerLink="roles">
                <clr-icon shape="key" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.role.name}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>

    <!-- Analyse -->
    <clr-vertical-nav-group
        *ngIf="auth.hasRole(['admin', 'analytics-user']) && settings.featureActiveList['analyticsActive']"
        routerLinkActive="active">
        <clr-icon shape="line-chart" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.sidenav.analysis}}
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a *ngIf="auth.hasRole(['admin', 'analytics-user']) && settings.featureActiveList['analyticsActive']"
                clrVerticalNavLink routerLinkActive="active" routerLink="analytics/reports">
                <clr-icon shape="details" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.report.name}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>

    <!-- Benachrichtigungen-->
    <clr-vertical-nav-group
        *ngIf="auth.hasRole(['admin', 'notification-editor']) && (settings.featureActiveList['pushActive'] || settings.featureActiveList['mailActive'])"
        routerLinkActive="active">
        <clr-icon shape="bell" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.sidenav.notifications}}
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a clrVerticalNavLink routerLinkActive="active" routerLink="notifications/push"
                *ngIf="settings.featureActiveList['pushActive']">
                <clr-icon shape="chat-bubble" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.push.name}}
            </a>
            <a clrVerticalNavLink routerLinkActive="active" routerLink="notifications/mail"
                *ngIf="settings.featureActiveList['mailActive']">
                <clr-icon shape="envelope" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.sidenav.emailAccounts}}
            </a>
            <a clrVerticalNavLink routerLinkActive="active" routerLink="notifications/mailTemplate"
                *ngIf="settings.featureActiveList['mailActive']">
                <clr-icon shape="ruler-pencil" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.sidenav.emailTemplates}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>



    <clr-vertical-nav-group *ngIf="auth.hasRole(['admin', 'settings-editor', 'branding-editor', 'language-editor'])"
        routerLinkActive="active">
        <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
        {{localization.dictionary.general.settings}}
        <clr-vertical-nav-group-children *clrIfExpanded="true">
            <a *ngIf="auth.hasRole(['admin', 'settings-editor'])" clrVerticalNavLink routerLinkActive="active" routerLink="settings">
                <clr-icon shape="file-settings" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.general.general}}
            </a>
            <a *ngIf="auth.hasRole(['admin', 'language-editor'])" clrVerticalNavLink routerLinkActive="active" routerLink="language">
                <clr-icon shape="language" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.language.name}}
            </a>
            <a clrVerticalNavLink routerLinkActive="active" routerLink="branding"
                *ngIf="auth.featureIsActivated('brandingActive') && auth.hasRole(['admin', 'branding-editor'])">
                <clr-icon shape="paint-roller" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.branding.name}}
            </a>
            <a *ngIf="auth.hasRole(['admin'])" clrVerticalNavLink routerLinkActive="active" routerLink="eventlog">
                <clr-icon shape="event" clrVerticalNavIcon></clr-icon>
                {{localization.dictionary.event.name}}
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>

</clr-vertical-nav>
