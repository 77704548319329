<h2>{{ localization.dictionary.user.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected"
    (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="this.selected = []">
        {{localization.dictionary.user.username}}
        <clr-dg-filter [clrDgFilter]="usernameFilter" [(clrDgFilterOpen)]="usernameFilter.open">
            <string-filter #usernameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'email'" [(clrDgSortOrder)]="sorting['email']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['email']">
            {{localization.dictionary.user.email}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="emailFilter" [(clrDgFilterOpen)]="emailFilter.open">
            <string-filter #emailFilter [property]="'email'" [(filter)]="filters['email']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'formOfAddress'" [(clrDgSortOrder)]="sorting['formOfAddress']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['formOfAddress']">
            {{localization.dictionary.user.formOfAddress}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="formFilter" [(clrDgFilterOpen)]="formFilter.open">
            <multiselect-filter #formFilter [property]="'formOfAddress'" [name]="localization.dictionary.user.formOfAddress" [array]="['mr', 'ms', 'other']"
                [(filter)]="filters['form']" [localizationKey]="'userFormOfAddress'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- *1 fullName visibil -->
    <clr-dg-column [clrDgSortBy]="'fullName'" [(clrDgSortOrder)]="sorting['fullName']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['fullName']">
            {{localization.dictionary.general.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'fullName'" [(filter)]="filters['fullName']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- *2 First and lastName visibil -->
    <clr-dg-column [clrDgSortBy]="'firstName'" [(clrDgSortOrder)]="sorting['firstName']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['firstName']">
            {{localization.dictionary.user.firstName}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="firstNameFilter" [(clrDgFilterOpen)]="firstNameFilter.open">
            <string-filter #firstNameFilter [property]="'firstName'" [(filter)]="filters['firstName']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'lastName'" [(clrDgSortOrder)]="sorting['lastName']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['lastName']">
            {{localization.dictionary.user.lastName}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="secondNameFilter" [(clrDgFilterOpen)]="secondNameFilter.open">
            <string-filter #secondNameFilter [property]="'lastName'" [(filter)]="filters['lastName']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'groups.name'"
     [(clrDgSortOrder)]="sorting['groups']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['groups']">
            {{localization.dictionary.group.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="groupsFilter" [(clrDgFilterOpen)]="groupsFilter.open">
            <multiselect-filter #groupsFilter [name]="localization.dictionary.group.name" [property]="'groups'" [query]="'group'"
                [(filter)]="filters['groups']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'fullRoles'" [(clrDgSortOrder)]="sorting['roles']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['roles']">
            {{localization.dictionary.role.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="rolesFilter" [(clrDgFilterOpen)]="rolesFilter.open">
            <multiselect-filter #rolesFilter [name]="localization.dictionary.role.name" [property]="'fullRoles'" [query]="'roles'"
                [(filter)]="filters['roles']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="setting.featureActiveList['crmActive']" [clrDgSortBy]="'fullTerritories'" [(clrDgSortOrder)]="sorting['territories']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['territories']" (clrDgHiddenChange)="refresh()">
            {{localization.dictionary.territory.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="territoriesFilter" [(clrDgFilterOpen)]="territoriesFilter.open">
            <multiselect-filter #territoriesFilter [name]="localization.dictionary.territory.name" [property]="'fullTerritories'" [query]="'crm/territory'"
                [(filter)]="filters['territories']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'lastLogin'" [(clrDgSortOrder)]="sorting['lastLogin']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['lastLogin']">
            {{localization.dictionary.userList.lastLogin}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="lastLoginFilter" [(clrDgFilterOpen)]="lastLoginFilter.open">
            <date-filter #lastLoginFilter [property]="'lastLogin'" [(filterFrom)]="filters['loginFrom']"
                [(filterTo)]="filters['loginTo']">
            </date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'lastSync'" [(clrDgSortOrder)]="sorting['lastSync']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['lastSync']">
            {{localization.dictionary.userList.lastSync}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="lastSyncFilter" [(clrDgFilterOpen)]="lastSyncFilter.open">
            <date-filter #lastSyncFilter [property]="'lastSync'" [(filterFrom)]="filters['syncFrom']"
                [(filterTo)]="filters['syncTo']">
            </date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="auth.hasRole(['admin'])" [clrDgSortBy]="'serviceAccount'" [(clrDgSortOrder)]="sorting['serviceAccount']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['serviceAccount']">
            {{localization.dictionary.user.serviceAccount}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="serviceAccountFilter" [(clrDgFilterOpen)]="serviceAccountFilter.open">
            
            <boolean-filter #serviceAccountFilter [(filter)]="filters['serviceAccount']" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [property]="'serviceAccount'" ></boolean-filter>

        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="createdFilter"
                        [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter  #createdFilter
                            [property]="'createdAt'" 
                            [(filterFrom)]="filters['createdAtFrom']"
                            [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'" [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="updatedFilter"
                        [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter  #updatedFilter
                            [property]="'updatedAt'" 
                            [(filterFrom)]="filters['updatedAtFrom']"
                            [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'" [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="deletedFilter"
                        [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter  #deletedFilter
                            [property]="'deletedAt'" 
                            [(filterFrom)]="filters['deletedAtFrom']"
                            [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>    


    <clr-dg-row *ngFor="let user of users" [clrDgItem]="user" [clrDgSelectable]="columnChange || editable(user) && deletable(user)" [class.deleted]="user.deletedAt" [class.noedit]="!editable(user)">
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{ user.id }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{ user.name }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{ user.email }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{ localization.dictionary.userFormOfAddress[user.formOfAddress] }}</clr-dg-cell>
        
        <!-- *1 FullName visible -->
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{ user.firstName + " " + user.lastName }}</clr-dg-cell>
        
        <!-- *2 First and LastName visible -->
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{ user.firstName}}</clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">{{user.lastName }}</clr-dg-cell>

      
        <clr-dg-cell class="labels">
            <ng-container *ngFor="let group of user.groups">
                <span
                    [routerLink]="['/groups/edit/' + group.id]" 
                    class="label label-light-blue" 
                    [pTooltip]="group.description" 
                    tooltipPosition="top"
                    *ngIf="group.description">
                    {{ group.name }}
                </span>
            </ng-container>            
        </clr-dg-cell>
        <clr-dg-cell class="labels" [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]" >
            <span 
               *ngFor="let role of user.fullRoles" 
               class="label" 
               [ngClass]="(role.direct) ? 'label-light-blue' : 'label-purple'"
               [pTooltip]="role?.groupNames
                        ? (localization.dictionary.roleDescription[role.name] || '') + '\n' + localization.dictionary.list['ofGroup' + (role.groupNames.length > 1 ? 's' : '')] + role.groupNames.join(', ')
                        : localization.dictionary.roleDescription[role.name]"
               tooltipPosition="top">
               {{ role.name }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="setting.featureActiveList['crmActive']" class="labels" [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]" >
            <span *ngFor="let territory of user.fullTerritories"
                class="label"
                [ngClass]="(territory?.direct) ? 'label-light-blue' : 'label-purple'"
                [pTooltip]="territory?.groupNames
                    ? (territory?.description || '') + '\n' + localization.dictionary.list['ofGroup' + (territory.groupNames.length > 1 ? 's' : '')] + territory.groupNames.join(', ')
                    : territory?.description"
                tooltipPosition="top">
                {{ territory?.name }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">
            {{ user.lastLogin | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">
            {{ user.lastSync | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="auth.hasRole(['admin'])" [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">
            {{ user.serviceAccount? localization.dictionary.general.yes : localization.dictionary.general.no}}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">
            {{ user.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">
            {{ user.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="!editable(user)? null : ['/user/edit/' + user.id]">
            {{ user.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>

        <clr-dg-cell *ngIf="editable(user)" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon 
                shape="pencil" 
                [routerLink]="['/user/edit/' + user.id]"
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', user.name)"
                tooltipPosition="left">
            </clr-icon>
            <clr-icon 
                *ngIf="deletable(user)"
                [attr.shape]="user.isActive ? 'ban' : 'success-standard'"
                (click)="activeToggleUser(user)"
                [pTooltip]=" user.isActive? localization.dictionary.list.deactivateComponentEntry.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', user.name) : localization.dictionary.list.activateComponentEntry.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', user.name)"
                tooltipPosition="left">
            </clr-icon>
            <clr-icon 
                *ngIf="deletable(user)"
                shape="trash" 
                (click)="toDelete = [user]" 
                [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', user.name)"
                tooltipPosition="left">
            </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="!editable(user)" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon 
                *ngIf="user.deletedAt"
                shape="backup-restore" 
                (click)="openRestoreModal(user)" 
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', user.name)"
                tooltipPosition="left">
            </clr-icon>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.user.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.user.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.user.nameSingular : localization.dictionary.user.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>


<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.userList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.user.nameSingular).replace('${entryName}', selectedUser?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        {{localization.dictionary.userList.restoreModalDescription}}
        <app-list-selector (selectedChanged)="selectedUser.groups = $event" [preLoaded]="selectedUser?.groups" [label]="localization.dictionary.group.name" [query]="'group'"></app-list-selector>
        <app-list-selector (selectedChanged)="selectedUser.roles = $event" [preLoaded]="selectedUser?.roles" [label]="localization.dictionary.role.name" [query]="'roles'"></app-list-selector>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/user/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.user.nameSingular) }}</button>
            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['user-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted" (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.user.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>