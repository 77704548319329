import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { View } from '../view.model';
import { CrmService } from '../../crm.service';

@Component({
  selector: 'app-list-views',
  templateUrl: './list-views.component.html',
  styleUrls: ['./list-views.component.css']
})
export class ListViewsComponent implements OnInit, OnDestroy, DatagridFunctions{
  views: View[];
  selected: View[] = [];
  toDelete: View[];
  loading = true;
  saving = false;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  }
  state: ClrDatagridStateInterface;

  showDeleted = false;
  selectedView: View;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  queryArray = [
    'customer',
    'contact',
    'call'
  ] 



  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(
    public server: ServerService, 
    public auth: AuthService, 
    public localization: LocalizationService,
    private crm: CrmService,
  ) {}

  ngOnInit(): void {
    const filter = localStorage.getItem('view-filters');
    const sorting = localStorage.getItem('view-sorting');
    const hidden = localStorage.getItem('view-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
  }

  ngOnDestroy() {
    localStorage.setItem('view-filters', JSON.stringify(this.filters));
    localStorage.setItem('view-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('view-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('view-deleted', 'true')
    else
      localStorage.removeItem('view-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const view = await this.server.get('crm/view/' + (this.showDeleted ? 'all' : '') + query);
    this.total = view.count;
    this.views = plainToClass(View, view.rows);
    this.loading = false;
  }
  
  async loadViews() {
    this.showDeleted = !!localStorage.getItem('view-deleted');
    const res = await this.server.get('crm/view/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.views = plainToClass(View, res.rows);
  }

  selectionChange(views: View[]) {
    if (views.length > 0) {
      this.order = false;
    }
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('crm/view/')
    }
    this.order = !this.order;
  }

  async openRestoreModal(view: View) {
    this.selectedView = view;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('crm/view/restore', this.selectedView);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.view.nameSingular).replace("${entryName}", this.selectedView.name)));
    this.refresh();
    this.restoreModal = false;
    this.crm.init();
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const viewIds = this.toDelete.map((view) => view.id);
    try {
      await this.server.delete('crm/view?toDelete=' + viewIds);
    } catch(e) {
      this.toDelete = undefined;
      this.selected = [];
      await this.refresh();
      throw new HttpErrorResponse(e) 
    }
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}", 
      this.toDelete.length == 1 ? this.localization.dictionary.view.nameSingular : this.localization.dictionary.view.name
    )));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
    this.crm.init();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }
  
  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  hidChange() {
    if(this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(()=>{
      this.columnChange = false
    })
  }

  getType(type: string){
    return this.localization.dictionary[type].nameSingular
    /* let index = this.queryArray.indexOf(type)
    return this.displayArray[index]; */
  }
}