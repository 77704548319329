import { Component, DoCheck, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Customer } from '../customer.model';
import { Contact } from '../../contacts/contact.model';
import { Conflict } from '../../conflict/conflict.model';
import { User } from 'src/app/users/user.model';
import { FieldValue } from '../../fields/field-value.model';
import { View } from '../../views/view.model';
import { ServerService } from 'src/app/server.service';
import { CrmService } from '../../crm.service';
import { HelperService } from 'src/app/helper/helper.service';
import { BrandingService } from 'src/app/branding/branding.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { AmselError } from 'src/app/helper/error/amsel-error.model';

@Component({
  selector: 'app-add-customer-contact',
  templateUrl: './add-customer-contact.component.html',
  styleUrls: ['./add-customer-contact.component.css']
})
export class AddCustomerContactComponent extends PreventUnload implements OnInit, DoCheck, OnDestroy {


  private forms: ClrForm[]
  @ViewChildren(ClrForm) set contentForms(content: QueryList<ClrForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined      
      if (!this.forms || this.forms.length == 0)
        this.forms = content.toArray();
      else
        this.forms.push(...content.toArray());
    } else {
      if (!this.forms || this.forms.length == 0)
        this.forms = [];
    }
  }
  private formDatas: NgForm[];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = content.toArray();
      else
        this.formDatas.push(...content.toArray());
    } else {
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = [];
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  firstTabInvalid = false;

  @Input() toAdd: Customer;
  @Input() createContact: EventEmitter<null>;
  @Output() createdContact = new EventEmitter<Contact>();
  contact: Contact;
  contactSnapshot: Contact;
  conflict: Conflict;
  conflictContact: Contact;
  conflicts = {};
  fieldConflictsToCheck = [];
  conflictsSearched = false;
  conflictId: string;
  customerConflict: { value: Customer[], user: User, date: Date };
  conflictChoices = {};
  tempFieldValues = {};
  tempCustomers: Customer[];

  loading = true;
  sameDV: FieldValue[] = [];
  change: boolean;

  customerIds: string[];

  views: View[];
  activeView: View;

  firstTab = true;
  secondTab = false;
  invalid = false;
  customerInvalid = false;
  collapse = {};

  name: string = null;

  positions = {};
  tempPosition: string;
  editPositionMode = false;

  constructor(public server: ServerService,
    public router: Router,
    public crm: CrmService,
    public helper: HelperService,
    public branding: BrandingService,
    public localization: LocalizationService) {
    super(localization);
  }

  async ngOnInit(): Promise<void> {
    this.createContact.subscribe((create) => {
      this.validateAndSave()
    })
    this.toAdd = plainToClass(Customer, this.toAdd);
    this.toAdd.contacts.forEach(contact => {
      contact.customers = [];
    });
    this.contact = new Contact();

    this.customerIds = this.contact.customers.map(customer => customer.id);
    this.loading = false;

    await this.crm.setActiveViewsFromStorage(['contact']);
    this.views = this.crm.views.contact;
    this.activeView = this.crm.activeViews.contact;
    
    for (let section of this.activeView.sections) {
      this.collapse[section.id] = false;
    }
  }

  ngOnDestroy() {
    localStorage.setItem('contact-active-view', JSON.stringify(this.activeView));
  }

  ngDoCheck() {
    if (this.invalid && this.firstTabInvalid && this.firstTab && this.forms && this.formDatas) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();
        if (formData.invalid) {
          this.collapse[formData.name] = false;
        }
      }
      this.invalid = false;
      for (let form of this.forms) {
        form.markAsTouched();
      }
    }
    if (this.firstTab && this.formDatas) {
      this.firstTabInvalid = false;
      for (let form of this.formDatas) {
        if (!form.valid && !form.disabled) {
          this.firstTabInvalid = true;
        }
      }
    }
      
  }

  addCustomForms(forms: ClrForm[]) {
    if (!this.forms || this.forms.length == 0)
      this.forms = [];
    this.forms.push(...forms);
  }

  addCustomFormDatas(formDatas: NgForm[]) {
    if (!this.formDatas)
      this.formDatas = [];
    this.formDatas.push(...formDatas);
    console.log(this.formDatas);
  }

  setQueryParams(){
    let obj = {};
    for (let dv of this.sameDV) {
      obj[dv.field.name] = dv.value;
    }
    return obj;
  }

  openList(){
    this.dirty = false;
  }

  checkDV(event: [string, FieldValue[]]) {
    if (event && event[0] == '+1') {
      event[1] = event[1].filter(ev => !this.sameDV.find(dv => ev.id == dv['id']));
      this.sameDV.push(...event[1]);
    } else if (event && event[0] == '-1') {
      this.sameDV = this.sameDV.filter(dv => dv['id'] != event[1][0]?.id);
    }
  }

  async validateAndSave() {
    this.contact.customers = [this.toAdd];
    this.ngDoCheck();
    if (!this.firstTabInvalid && this.fieldConflictsToCheck.every(con => !!this.conflictChoices[con])) {
      if (this.contact.customers.length > 0 && (!this.customerConflict || this.conflictChoices['customers'])) {
        await this.save();
      }
      else {
        this.secondTab = true;
        this.invalid = true;
        this.customerInvalid = true;
      }
    }
    else {
      this.firstTab = true;
      this.invalid = true;
    }
  }

  async save() {
    this.dirty = false;
    this.loading = true;
    this.saving = true;

    console.log(this.contact);
    let resp = await this.server.post('crm/contact', this.contact);
    this.contact.id = resp.id;

    this.server.addAlert(new AmselError(
      undefined,
      'success',
      this.localization.dictionary.toastr.successSaved
      .replace('${componentName}', this.localization.dictionary.contact.nameSingular)
      .replace('${entryName}', this.crm.getDisplayValue(this.contact))
    ));
    this.saving = false;
    this.createdContact.next(this.contact);
  } 

}
