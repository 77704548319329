<h2>{{ localization.dictionary.territory.name}}</h2>

<clr-tabs>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.territoryOverview.treeView}}</button>
        <ng-template [(clrIfActive)]="firstTab">
            <clr-tab-content *clrIfActive>
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                <div *ngIf="!loading" #scrollWrapper class="scroll-wrapper">
                    <div class="zoom-wrapper">
                        <div [style.transform]="'scale(' + zoom + '%)'" fxLayout="row wrap" fxLayoutAlign="center center" class="org-wrapper">
                        <div style="margin-top: 0.2rem;" fxFlex *ngFor="let tree of trees">
                            <p-organizationChart [value]="tree" 
                                selectionMode="multiple" 
                                [selection]="selectedNodes" 
                                (onNodeSelect)="selectionChangeTree($event, true)" 
                                (onNodeUnselect)="selectionChangeTree($event, false)"
                                [preserveSpace]="false" 
                                (onNodeCollapse)="selectedNodes = []">
                                <ng-template let-node pTemplate="default">
                                    <clr-icon shape="pencil" [routerLink]="['/crm/territory/edit/' + node.data.id]" class="node-edit"></clr-icon>
                                    <div style="font-weight:bold">{{node.label}}</div>
    
                                    <ng-container *ngIf="detail">
                                        <div style="margin-top: 0.2rem;">{{node.data.description}}</div>

                                        <div style="margin-top: 0.2rem;">
                                            <hr style="opacity: 0.5;">
                                            {{localization.dictionary.customer.name}}: {{ territoryCounts[node.data.id].directly}} ({{territoryCounts[node.data.id].all + ' ' + localization.dictionary.legendTranslation.TOTAL}})
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </p-organizationChart>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <app-spinner *ngIf="loading"></app-spinner>
            </clr-tab-content>
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.territoryOverview.listView}}</button>
        <clr-tab-content *clrIfActive>
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>

            <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected"
                (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
                <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.territory.name) }}</clr-dg-placeholder>
                <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
                    (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
                        Id
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
                        <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'name'"
                    [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="this.selected = []">
                    {{localization.dictionary.general.name}}
                    <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
                        <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'description'"
                    [(clrDgSortOrder)]="sorting['description']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['description']">
                        {{localization.dictionary.general.description}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="descriptionFilter" [(clrDgFilterOpen)]="descriptionFilter.open">
                        <string-filter #descriptionFilter [property]="'description'"
                            [(filter)]="filters['description']">
                        </string-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'parent.name'"
                    [(clrDgSortOrder)]="sorting['parent']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['parent']">
                        {{localization.dictionary.territory.parentTerritory}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="parentFilter" [(clrDgFilterOpen)]="parentFilter.open">
                        <multiselect-filter #parentFilter [name]="localization.dictionary.territory.name" [property]="'parent.id'" [toggle]="false" [query]="'crm/territory/allParents'"
                            [(filter)]="filters['parent']"></multiselect-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'children.name'"
                    [(clrDgSortOrder)]="sorting['children']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['children']">
                        {{localization.dictionary.territoryOverview.childTerritories}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="childFilter" [(clrDgFilterOpen)]="childFilter.open">
                        <multiselect-filter #childFilter [name]="localization.dictionary.territory.name" [property]="'children'" [query]="'crm/territory/allChilds'"
                            [(filter)]="filters['children']"></multiselect-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'groups.name'" [(clrDgSortOrder)]="sorting['groups']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['groups']">
                        {{localization.dictionary.group.name}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="groupsFilter" [(clrDgFilterOpen)]="groupsFilter.open">
                        <multiselect-filter #groupsFilter [name]="localization.dictionary.group.name" [property]="'groups'" [query]="'group'"
                            [(filter)]="filters['groups']"></multiselect-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'users.name'"
                    [(clrDgSortOrder)]="sorting['users']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['users']">
                        {{localization.dictionary.user.name}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
                        <multiselect-filter #userFilter [name]="localization.dictionary.user.name" [property]="'fullUsers'" [query]="'user/filter'"
                            [(filter)]="filters['users']"></multiselect-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <!-- <clr-dg-column [clrDgSortBy]="'customers.fieldValues.' + crm.sortField.customer"
                    [(clrDgSortOrder)]="sorting['customers']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['customers']">
                        {{localization.dictionary.customer.name}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="customersFilter" [(clrDgFilterOpen)]="customersFilter.open">
                        <multiselect-filter #customersFilter [name]="localization.dictionary.customer.name" [property]="'customers'" [query]="'crm/customer/filter'"
                            [(filter)]="filters['customers']" [customFields]="true"></multiselect-filter>
                    </clr-dg-filter>
                </clr-dg-column> -->
                <clr-dg-column [clrDgSortBy]="'createdAt'"
                    [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
                        {{localization.dictionary.list.created}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
                        <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                            [(filterTo)]="filters['createdAtTo']"></date-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column [clrDgSortBy]="'updatedAt'"
                    [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
                        {{localization.dictionary.list.updated}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
                        <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                            [(filterTo)]="filters['updatedAtTo']"></date-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
                    [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
                    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
                        {{localization.dictionary.list.deleted}}
                    </ng-template>
                    <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
                        <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                            [(filterTo)]="filters['deletedAtTo']"></date-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

                <clr-dg-row *ngFor="let territory of territories" [clrDgItem]="territory" [clrDgSelectable]="!territory.deletedAt && territoryCounts[territory.id]['directly'] == 0"
                    [class.deleted]="territory.deletedAt">
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">{{ territory.id }}</clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">{{ territory.name }}
                    </clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">{{ territory.description }}
                    </clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        <div class="tooltip-container" *ngIf="territory.parent">
                            <span
                                class="label label-light-blue"
                                [pTooltip]="territory.parent.description"
                                [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]"
                                tooltipPosition="top">
                                {{ territory.parent.name }}
                            </span>
                        </div>
                    </clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        <div class="tooltip-container" *ngFor="let child of territory.children">
                            <span
                                class="label label-light-blue"
                                [pTooltip]="child.description"
                                [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]"
                                tooltipPosition="top">
                                {{ child.name }}
                            </span>
                        </div>
                    </clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        <div class="tooltip-container" *ngFor="let group of territory.groups">
                            <span
                                class="label label-light-blue"
                                [pTooltip]="group.name"
                                [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]"
                                tooltipPosition="top">
                                {{ group.name }}
                            </span>
                        </div>
                    </clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        <div class="tooltip-container" *ngFor="let user of territory.fullUsers">
                            <div
                                class="label" [ngClass]="user?.direct ? 'label-light-blue' : 'label-purple'"
                                [pTooltip]="user?.groupNames
                                    ? user.firstName + ' ' + user.lastName + '\n' + localization.dictionary.list['ofGroup' + (user.groupNames.length > 1 ? 's' : '')] + user.groupNames.join(', ')
                                    : user.firstName + ' ' + user.lastName"
                                [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]"
                                tooltipPosition="top">
                                {{ user.name }}
                            </div>
                        </div>
                    </clr-dg-cell>
                    <!-- <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        <span *ngFor="let customer of territory.customers" class="label label-light-blue" >
                            <app-display-name [model]="customer"></app-display-name>
                        </span>
                    </clr-dg-cell> -->
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        {{ territory.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
                    </clr-dg-cell>
                    <clr-dg-cell [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        {{ territory.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
                    </clr-dg-cell>
                    <clr-dg-cell *ngIf="showDeleted" [routerLink]="territory.deletedAt? null : ['/crm/territory/edit/' + territory.id]">
                        {{ territory.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
                    </clr-dg-cell>

                    <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
                        <clr-icon *ngIf="!territory.deletedAt"
                            shape="pencil"
                            [routerLink]="['/crm/territory/edit/' + territory.id]"
                            [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.territory.nameSingular).replace('${entryName}', territory.name)"
                            tooltipPosition="left">
                        </clr-icon>

                        <clr-icon *ngIf="!territory.deletedAt"
                            shape="trash"
                            (click)="toDelete = [territory]"
                            [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.territory.nameSingular).replace('${entryName}', territory.name)"
                            tooltipPosition="left">
                        </clr-icon>

                        <clr-icon *ngIf="territory.deletedAt"
                            shape="backup-restore"
                            (click)="openRestoreModal(territory)"
                            [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.territory.nameSingular).replace('${entryName}', territory.name)"
                            tooltipPosition="left">
                        </clr-icon>

                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
                    <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.territory.name)}}</clr-dg-page-size>
                        <span>
                            {{localization.dictionary.list.componentsTotal
                                .replace('${first}', '' + (pagination.firstItem + 1))
                                .replace('${last}', '' + (pagination.lastItem + 1))
                                .replace('${total}', pagination.totalItems == 1? localization.dictionary.territory.one : '' + pagination.totalItems)
                                .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.territory.nameSingular : localization.dictionary.territory.name)}}
                        </span>
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
           
        </clr-tab-content>
    </clr-tab>

    <ng-template #actionBar>
        <div fxLayout="row" [fxLayoutAlign]="firstTab ? 'space-between flex-start' : 'space-between flex-end'">
            <clr-dg-action-bar>
                <button class="btn btn-primary" routerLink="/crm/territory/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.territory.nameSingular) }}</button>
                <button *ngIf="!firstTab && state && (state.filters || state.sort)" class="btn btn-outline"
                    (click)="resetFilters()">
                    {{ localization.dictionary.button.resetFilters }}
                </button>
                <button class="btn btn-danger-outline"
                    *ngIf="(!firstTab && selected.length > 0) || (firstTab && selectedNodes.length > 0)"
                    (click)="toDelete = firstTab? selectedNodes : selected">{{localization.dictionary.button.delete}}</button>
            </clr-dg-action-bar>
            <div *ngIf="firstTab" fxLayout="column" fxLayoutAlign="space-between flex-end">
                <clr-dg-action-bar>
                    <div class="btn-group" 
                            [pTooltip]="localization.dictionary.territoryOverview.foldUnfold"
                            tooltipPosition="left">
                        <button class="btn btn-outline" (click)="expandCollapseAll(true)">
                            <clr-icon shape="angle-double" flip="vertical"></clr-icon>
                        </button>
                        <button class="btn btn-outline" (click)="expandCollapseAll(false)">
                            <clr-icon shape="angle-double"></clr-icon>
                        </button>
                    </div>
                    <div class="btn-group" 
                            [pTooltip]="localization.dictionary.territoryOverview.increaseDecrease"
                            tooltipPosition="left">
                        <button class="btn btn-outline" 
                                (mousedown)="applyZoom('in')" 
                                (mouseup)="applyZoom('stop')"  
                                (mouseleave)="applyZoom('stop')">
                            <clr-icon shape="zoom-in"></clr-icon>
                        </button>
                        <button class="btn btn-outline" 
                                (mousedown)="applyZoom('out')" 
                                (mouseup)="applyZoom('stop')"  
                                (mouseleave)="applyZoom('stop')">
                            <clr-icon shape="zoom-out"></clr-icon>
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-toggle-container class="clr-toggle-right detail-toggle">
                    <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle name="detail" [(ngModel)]="detail"/>
                        <label>{{localization.dictionary.territoryOverview.hideDetails}}</label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>
            </div>
            <clr-toggle-container class="clr-toggle-right" *ngIf="!firstTab && auth.hasRole(['crm-editor', 'admin'])">
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                        (ngModelChange)="toggleDeleted()" />
                    <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.territory.name)}}</label>
                </clr-toggle-wrapper>
            </clr-toggle-container>
        </div>
    </ng-template>
</clr-tabs>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.territory.nameSingular).replace('${entryName}', selectedTerritory?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        <!-- Wählen Sie die Gruppen und Rollen aus, mit denen der Benutzer wiederhergestellt werden soll:
<app-list-selector (selectedChanged)="selectedTerritory.groups = $event" [preLoaded]="selectedTerritory?.groups" [label]="'Gruppen'" [query]="'group'"></app-list-selector>
<app-list-selector (selectedChanged)="selectedTerritory.roles = $event" [preLoaded]="selectedTerritory?.roles" [label]="'Rollen'" [query]="'roles'"></app-list-selector> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{localization.dictionary.button.restore}}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.territoryOverview.deleteModalDescription}}</p>
        <p *ngIf="deleteDisabled()" style="font-weight: bold">{{localization.dictionary.territoryOverview.cannotDeleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="deleteDisabled()" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>