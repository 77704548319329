import { Expose, Transform, Type, plainToClass } from "class-transformer";
import { User } from "src/app/users/user.model";
import { Contact } from "../../contacts/contact.model";


export class InteractionCustomer {
    @Expose()
    id: string;

    @Expose()
    date: Date;

    @Expose()
    type: 'call'| 'answeredForm';

    @Expose()
    value: any;

    @Expose()
    name: string;

    @Expose()
    @Type( () => User)
    users: User[];

    @Expose()
    @Type( () => Contact)
    contacts: Contact[];
}

