import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { Setting } from './setting.model';
import { SettingService } from './setting.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  settings: Setting[];
  categories: string[] = [];
  loading = false;

  constructor(private server: ServerService,
              private settingService: SettingService,
              private titleService: Title,
              public localization: LocalizationService,
              public authService: AuthService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.categories = await this.server.get('setting/categories') as unknown as string[];
    const res = await this.server.get('setting');
    this.settings = plainToClass(Setting, res.rows);
    this.loading = false;
  }

  async save() {
    this.loading = true;
    this.titleService.setTitle(this.settings.find( (setting) => setting.selector === 'appName').value);
    await this.server.put('setting', this.settings);
    this.settingService.settings = this.settings;
    await this.settingService.update();
    this.settingService.emitChangeEvent();
    this.loading = false;
  }

}
