import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { ServerService } from 'src/app/server.service';
import { Language } from '../language.model';
import { LocalizationService } from '../localization.service';
import { ExcelService } from 'src/app/excel.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-languages',
  templateUrl: './list-languages.component.html',
  styleUrls: ['./list-languages.component.css']
})
export class ListLanguagesComponent implements OnInit {

  languages: Language[];
  toDelete: Language[];
  loading = true;
  saving = false;
  selected: Language[] = [];
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  }

  isExcelFile: boolean;
  allowedFiles = '.xls,.xlsx';
  loadingExcel = false;
  openWarning: Language;

  state: ClrDatagridStateInterface



  showDeleted = false;
  selectedLanguage: Language;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(public localization: LocalizationService,
    public server: ServerService,
    public auth: AuthService,
    public excelService: ExcelService,
    private router: Router) {
      this.excelService.deleteFile();
  }

  ngOnInit(): void {
    const filter = localStorage.getItem('language-filters');
    const sorting = localStorage.getItem('language-sorting');
    const hidden = localStorage.getItem('language-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }

  }

  ngOnDestroy() {
    localStorage.setItem('language-filters', JSON.stringify(this.filters));
    localStorage.setItem('language-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('language-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('language-deleted', 'true')
    else
      localStorage.removeItem('language-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const language = await this.server.get('language/' + (this.showDeleted ? 'all' : '') + query);
    this.total = language.count;
    this.languages = plainToClass(Language, language.rows);
    this.loading = false;
  }

  async loadLanguages() {
    this.showDeleted = !!localStorage.getItem('language-deleted');
    const res = await this.server.get('language/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.languages = plainToClass(Language, res.rows);
  }

  selectionChange(languages: Language[]) {
    if (languages.length > 0) {
      this.order = false;
    }
  }

  goToCreate(event: any) {
    this.excelService.getFile(event);
    if (this.excelService.file) {
      this.router.navigate(['/language/create/']);
    }
  }


  checkDictionary(event: any, language: Language){
    if (language.dictionaries && language.dictionaries.filter(dict => dict.label && dict.label.length != 0).length > 0) {
      this.openWarning = language;
      return;
    } else {
      this.goToEdit(event, language);
    }
  }

  goToEdit(event: any, language: Language) {
    const res = this.excelService.getFile(event);
    if (this.excelService.file) {
      this.router.navigate(['/language/edit/' + language.id]);
    }
  }

  cancelImport(){
    delete this.openWarning;
    // document.getElementById('fileExcel').nodeValue = '';
    this.loadingExcel = false;
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('language/')
    }
    this.order = !this.order;
  }

  async openRestoreModal(language: Language) {
    this.selectedLanguage = language;
    this.restoreModal = true
  }


  async restore() {
    await this.server.put('language/restore', this.selectedLanguage);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.language.nameSingular).replace("${entryName}", this.selectedLanguage.name)));
    this.refresh();
    this.refresh();
    this.restoreModal = false;
    this.localization.notDelete.next(this.languages);
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const langIds = this.toDelete.map((lang) => lang.id);
    await this.server.delete('language?toDelete=' + langIds);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
      this.localization.dictionary.language['name' + (this.toDelete.length == 1 ? 'Singular' : '')])));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
    this.localization.languagesDeleted.next(langIds);
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  editable(language: Language, deleted = true) {
    return (deleted ? !language.deletedAt : true)
  }

  deletable(language: Language) {
    return (language.id != '13bdf866-eb10-4794-a19e-dadc87a4365b' && language.id != '3fcfd0e6-cd71-4a6e-a5bd-54c25588792e');
  }


  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

}
