<div fxLayout="row" fxLayoutGap="2rem" fxFill *ngIf="!loading">
    <div fxFlex="25">
        <h2>{{localization.dictionary.general.settings}}</h2>
        <button class="btn btn-primary" (click)="saveTheme()">{{localization.dictionary.button.save}}</button>
        <button class="btn btn-outline" (click)="reset()">{{localization.dictionary.button.reset}}</button>
        <div class="accordion-wrapper">
            <clr-accordion clrAccordionMultiPanel="true">
                <clr-accordion-panel *ngFor="let category of themeMapping; let i = index" [(clrAccordionPanelOpen)]="open[i]">
                    <clr-accordion-title>{{localization.dictionary.themeMapping[category.selector]}}</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>

                        <ng-container *ngFor="let setting of category.settings; let i = index">
                            <hr *ngIf="i>0">
                            <!-- color -->
                            <div *ngIf="setting.type === 'color'" class="clr-form-control color" [id]="setting.selector" [class.no-trans]="picker.open">
                                <label class="clr-control-label" [for]="setting.selector + 'picker'">{{localization.dictionary.themeMapping[setting.selector]}}</label>
                                <div class="clr-control-container">
                                  <div class="clr-input-wrapper">
                                    <input #picker
                                        [id]="setting.selector + 'picker'"
                                        autocorrect="off"
                                        (colorPickerOpen)="picker.open = true"
                                        (colorPickerClose)="picker.open = false"
                                        [(colorPicker)]="theme[setting.selector]"
                                        (colorPickerChange)="previewChange(setting.preview)"
                                        [cpAlphaChannel]="'disabled'"
                                        [style.background]="theme[setting.selector]"/>
                                      <clr-icon shape="undo"
                                        [pTooltip]="localization.dictionary.branding.resetColor"
                                        tooltipPosition="bottom"
                                        (click)="theme[setting.selector] = resetTheme[setting.selector]; picker.open = false">
                                      </clr-icon>
                                  </div>
                                </div>
                            </div>
                            <!-- <clr-input-container *ngIf="setting.type === 'color'">
                                <label> {{ setting.description }} <clr-icon shape="undo" (click)="theme[setting.selector]=resetTheme[setting.selector]; picker.open = false"></clr-icon></label>
                                <input clrInput #picker [(colorPicker)]="theme[setting.selector]" [style.background]="theme[setting.selector]" [cpAlphaChannel]="'disabled'" readonly/>
                            </clr-input-container> -->
                            <!-- blur -->
                            <div *ngIf="setting.type === 'blur'" class="clr-form-control color" [id]="setting.selector" [class.no-trans]="picker.open">
                                <label class="clr-control-label" [for]="setting.selector + 'picker'">{{localization.dictionary.themeMapping[setting.selector]}}</label>
                                <div class="clr-control-container">
                                  <div class="clr-input-wrapper">
                                    <input #picker
                                        [id]="setting.selector + 'picker'"
                                        autocorrect="off"
                                        (colorPickerOpen)="picker.open = true"
                                        (colorPickerClose)="picker.open = false"
                                        [colorPicker]="theme[setting.selector]"
                                        (colorPickerChange)="theme[setting.selector] = $event + ' 0% 0% no-repeat padding-box'; previewChange(setting.preview)"
                                        [cpAlphaChannel]="'disabled'"
                                        [style.background]="theme[setting.selector]"/>
                                      <clr-icon shape="undo"
                                        [pTooltip]="localization.dictionary.branding.resetColor"
                                        tooltipPosition="bottom"
                                        (click)="theme[setting.selector] = resetTheme[setting.selector]; picker.open = false">
                                      </clr-icon>
                                  </div>
                                </div>
                            </div>
                            <!-- <clr-input-container *ngIf="setting.type === 'blur'">
                                <label> {{ setting.description }} <clr-icon shape="undo" (click)="theme[setting.selector]=resetTheme[setting.selector]; picker.open = false"></clr-icon></label>
                                <input clrInput #picker [colorPicker]="theme[setting.selector]" (colorPickerChange)="theme[setting.selector] = $event + ' 0% 0% no-repeat padding-box'" [style.background]="theme[setting.selector]" [cpAlphaChannel]="'disabled'" readonly/>
                            </clr-input-container> -->
                            <!-- Images-->
                            <div *ngIf="setting.type === 'image'" class="clr-form-control upload" [id]="setting.selector">
                                <label class="clr-control-label">{{localization.dictionary.themeMapping[setting.selector]}}</label>
                                <div class="clr-control-container">
                                  <div class="clr-file-wrapper">
                                    <div class="img-preview" [style.background-image]="theme[setting.selector]"></div>
                                    <label [for]="setting.selector + 'picker'" class="clr-control-label"><span class="btn btn-sm" (click)="uploadImage(setting)">{{ (theme[setting.selector]) ? localization.dictionary.button.edit : localization.dictionary.button.browse}}</span></label>
                                    <clr-icon shape="undo"
                                      [pTooltip]="localization.dictionary.branding.resetImage"
                                      tooltipPosition="bottom"
                                      (click)="theme[setting.selector] = resetTheme[setting.selector]">
                                    </clr-icon>
                                    <clr-icon *ngIf="setting.selector == '--amsel-background-image'"
                                          shape="window-close"
                                          [pTooltip]="localization.dictionary.branding.deleteImage"
                                          tooltipPosition="bottom"
                                          (click)="theme[setting.selector] = null">
                                    </clr-icon>

                                    <input  [id]="setting.selector + 'picker'" [name]="setting.selector" (change)="uploadImage(setting)" placeholder="Enter value here" class="clr-file" />

                                  </div>
                                </div>
                            </div>
                        </ng-container>


                    </clr-accordion-content>
                </clr-accordion-panel>
            </clr-accordion>
        </div>

    </div>

    <div fxFlex="75">
        <app-branding-preview *ngIf="serverService.categories" [activePage]="activePage" [theme]="theme" (jumpTo)="jumpTo($event)"></app-branding-preview>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>

