<form clrForm (change)="change.emit()" #formData="ngForm" class="icon-offset">
    <ng-container *ngFor="let field of fields">
        <div class="single-input-wrapper" [class.edit]="unlocked[field.name]">
            <clr-icon *ngIf="multi" (click)="toggleLock(field)" [attr.shape]="unlocked[field.name] ? 'undo' : 'pencil'"></clr-icon>
            <clr-input-container #input *ngIf="field.type == 'TEXT'">
                <label>{{ field.label }}{{ field.SectionField.mandatory ? '*' : '' }}</label>
                <input clrInput [ngModel]="model[field.name]? model[field.name][0]?.value : ''" maxlength="100"
                    (ngModelChange)="setInput(field, $event);" [name]="field.name" [required]="field.SectionField.mandatory"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])" [pattern]="field.pattern" />
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.createEdit.invalidInput}}</clr-control-error>
            </clr-input-container>
            <clr-textarea-container #input *ngIf="field.type == 'TEXTAREA'">
                <label>{{ field.label }}{{ field.SectionField.mandatory ? '*' : '' }}</label>
                <textarea clrTextarea [ngModel]="model[field.name]? model[field.name][0]?.value : ''" maxlength="250"
                    (ngModelChange)="setInput(field, $event)" [name]="field.name" [required]="field.SectionField.mandatory"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                    [pattern]="field.pattern" ></textarea>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.createEdit.invalidInput}}</clr-control-error>
            </clr-textarea-container>


            <clr-radio-container #input *ngIf="field.type === 'CHOICE'">
                <div class="description" #description>{{ field.description }}</div>
                <label *ngIf="allowedToEdit">{{ field.label }}{{ (field.mandatory) ? '*' : '' }} <clr-icon *ngIf="crm.multiUpdateOpen == false" shape="pencil" class="plus" (click)="openFieldOptionModal(field.id)"></clr-icon></label>
                <label *ngIf="!allowedToEdit">{{ field.label }}{{ (field.mandatory) ? '*' : '' }}</label>
                <clr-radio-wrapper #radio  *ngFor="let oa of field.fieldOptions ; let j = index">
                    <input type="radio" clrRadio [name]="oa.id" [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                        [required]="field.SectionField.mandatory" [value]="oa.label" (ngModelChange)="setChoiceInput(field, oa, radio, $event)"
                        [ngModel]="model[field.name]? model[field.name][0]?.value : null"/>
                    <label>{{ oa.label }} </label>
                </clr-radio-wrapper>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-radio-container>

            <!-- <clr-combobox-container *ngIf="false">
                <label>{{ field.label }}{{ field.SectionField.mandatory ? '*' : '' }}</label>
                <clr-combobox #combobox [ngModel]="model[field.name]? model[field.name][0]?.fieldOption: null"
                    (ngModelChange)="setChoiceInput(field, $event, combobox)" [name]="field.name"
                    [disabled]="field.SectionField.readOnly && !notReadonly[field.name]"
                    [required]="field.SectionField.mandatory">
                    <clr-options>
                        <clr-option *clrOptionItems="let option of field.fieldOptions; field: 'label'" [clrValue]="option">
                            {{ option.label }}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" ngModel
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" ngModel
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-combobox-container> -->

            <clr-toggle-container #input *ngIf="field.type == 'BOOLEAN'">
                <label>{{ field.label }}</label>
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [name]="field.name" [value]="true"
                        [ngModel]="model[field.name]? model[field.name][0]?.value == 'true' : false"
                        (ngModelChange)="setBooleanInput(field, $event)" [disabled]="(field.SectionField.readOnly && !editOverride) || (multi && !unlocked[field.name])" />
                </clr-toggle-wrapper>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no
                                }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-toggle-container>

            <clr-select-container #input *ngIf="field.type == 'SELECTION'">
              <label *ngIf="allowedToEdit">{{ field.label }}{{ (field.mandatory) ? '*' : '' }} <clr-icon *ngIf="crm.multiUpdateOpen == false" shape="pencil" class="plus" (click)="openFieldOptionModal(field.id)"></clr-icon></label>
              <label *ngIf="!allowedToEdit">{{ field.label }}{{ (field.mandatory) ? '*' : '' }}</label>
                <select #select clrSelect [name]="field.name"
                    [ngModel]="model[field.name]? model[field.name][0]?.fieldOption?.label : null"
                    (ngModelChange)="setSelectioneInput(field, $event, select)"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                    [required]="field.SectionField.mandatory" >
                    <option *ngFor="let option of field.fieldOptions">
                        {{ option.label }}
                    </option>
                </select>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-select-container>

            <clr-input-container #input *ngIf="field.type == 'NUMBER'">
                <label>{{ field.label }}{{ field.SectionField.mandatory ? '*' : '' }}</label>
                <input clrInput [ngModel]="model[field.name]? model[field.name][0]?.value : ''" maxlength="50"
                    (ngModelChange)="setInput(field, $event)" [name]="field.name"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                    [required]="field.SectionField.mandatory"
                    [pattern]="'^\\d+$'"  />
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }} ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                <clr-control-error *clrIfError="'pattern'">{{ localization.dictionary.fieldCreateEdit.onlyNumbers}} </clr-control-error>
              </clr-input-container>

            <clr-date-container #input *ngIf="field.type == 'DATE'">
                <label>{{ field.label }}{{ field.SectionField.mandatory ? '*' : '' }}</label>
                <input type="date" clrDate [name]="field.name" [ngModel]="getDateInput(field)"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                    (ngModelChange)="setDateInput(field, $event)" [required]="field.SectionField.mandatory"  />
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
                                ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-date-container>

            <clr-checkbox-container #input *ngIf="field.type == 'CHECKBOX'">
                <label>{{ field.label }}{{ (field.SectionField.mandatory && (!model[field.name] || model[field.name].length
                    < 1)) ? '*' : '' }}</label>
                <clr-checkbox-wrapper *ngFor="let option of field.fieldOptions">
                    <label>{{option.label}}</label>
                    <input type="checkbox" #checkbox clrCheckbox [name]="field.name + option.label"
                        [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                        [required]="field.SectionField.mandatory && (!model[field.name] || model[field.name].length < 1)"
                        [ngModel]="getCheckboxInput(field, option)"
                        (ngModelChange)="setCheckboxInput(field, option,$event)"  />
                </clr-checkbox-wrapper>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }}
                                ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-checkbox-container>



            <clr-combobox-container #input *ngIf="field.type == 'MULTIPLE_CHOICE'">
                <label *ngIf="!allowedToEdit">{{ field.label }}{{ (field.SectionField.mandatory && (!model[field.name] || model[field.name].length
                    < 1)) ? '*' : '' }}</label>
                    <label *ngIf="allowedToEdit">{{ field.label }}{{ (field.SectionField.mandatory && (!model[field.name] || model[field.name].length
                      < 1)) ? '*' : '' }}<clr-icon *ngIf="crm.multiUpdateOpen == false" shape="pencil" class="plus" (click)="openFieldOptionModal(field.id)"></clr-icon></label>
                <clr-combobox #combobox [clrMulti]="true" [ngModel]="getMultiChoiceInput(field)"
                    (ngModelChange)="setMultiChoiceInput(field, $event, combobox)" [name]="field.name"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                    [required]="field.SectionField.mandatory && (!model[field.name] || model[field.name].length < 1)">
                    <ng-container *clrOptionSelected="let selected" >
                        {{selected.label}}
                    </ng-container>
                    <clr-options>
                        <clr-option *clrOptionItems="let option of filteredFieldOptions[field.name]; field: 'label'"
                            [clrValue]="option">
                            {{ option.label }}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="multiFieldInput[field.name] = null; onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) }}
                                ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="multiFieldInput[field.name] = null; onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-combobox-container>

            <clr-input-container #input *ngIf="field.type == 'TIME'">
                <label>{{ field.label }}{{ field.SectionField.mandatory ? '*' : '' }}</label>
                <input #test type="time" clrInput [name]="field.name" [ngModel]="getTimeInput(field)"
                    (ngModelChange)="setTimeInput(field, $event)" [required]="field.SectionField.mandatory"
                    [disabled]="(field.SectionField.readOnly && !notReadonly[field.name] && !editOverride) || (multi && !unlocked[field.name])"
                    [pattern]="field.name == 'u_start_time'? maxPattern : (field.name == 'u_end_time'? minPattern : null)"  />
                <clr-control-helper *ngIf="conflicts[field.name]">
                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                    <clr-radio-container class="conflict">
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 1"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'model')" />
                            <label>{{ displayFieldValue(tempFieldValues[field.name], field.name) | date: localization.dictionary.dateFormat.time: undefined : localization.language.selector }}
                                ({{localization.dictionary.createEdit.current}})</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" required clrRadio [value]="field.name + 'conflict' + 2"
                                [name]="field.name + 'conflict'" [(ngModel)]="choices[field.name]"
                                (ngModelChange)="onConflictChange(field.name, 'conflict')" />
                            <label>{{ displayFieldValue(conflicts[field.name].value) | date: localization.dictionary.dateFormat.time: undefined : localization.language.selector }}
                                ({{conflicts[field.name].user.name}},
                                {{conflicts[field.name].date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                        </clr-radio-wrapper>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-radio-container>
                </clr-control-helper>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                <clr-control-error *clrIfError="'pattern'">{{ field.name == 'u_start_time'?
                    localization.dictionary.callCreateEdit.startBeforeEnd :
                    localization.dictionary.callCreateEdit.endAfterStart}}</clr-control-error>
            </clr-input-container>

            <clr-input-container
                *ngIf="!['TEXT','TEXTAREA','CHOICE', 'BOOLEAN', 'SELECTION', 'NUMBER', 'DATE', 'MULTIPLE_CHOICE', 'CHECKBOX', 'TIME' ].includes(field.type)">
                <label>{{ field.label }} (Placeholder)</label>
                <input placeholder="Ist noch falsch" clrInput
                    [ngModel]="model[field.name]? model[field.name][0]?.value : ''"
                    (ngModelChange)="setInput(field, $event)" [name]="field.name" size="45"
                    [required]="field.SectionField.mandatory" />
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-input-container>

        </div>
    </ng-container>
</form>

<!-- Modal for adding another Option for the selection/Multiselect/Choice Fields -->
<div *ngIf="addOption && currentField && this.crm.multiUpdateOpen == false">
<div class="modal">
  <div class="modal-dialog fadeDown in" role="dialog" aria-hidden="true">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" (click)="closeModal()">
              <cds-icon shape="close"></cds-icon>
          </button>
          <h3 class="modal-title">{{localization.dictionary.createEdit.addFieldOptionTitle}}</h3>
      </div>
      <div class="modal-body">

          <p>{{localization.dictionary.createEdit.addFieldOptionDescription}}</p>

        <form clrForm class="icon-offset option-input">
          <div cdkDropList (cdkDropListDropped)="drop($event)" cdkScrollable class="content-wrapper">
        <div cdkDragData="fieldOption" *ngFor="let fieldOption of fieldSnapshot.fieldOptions; let i = index"
            style="position: relative;" cdkDrag>
            <div class="cdk-placeholder" *cdkDragPlaceholder style="max-width: 80%;"></div>
            <div *cdkDragPreview style="width: 20%; display: flex; justify-content: space-around;">
                {{localization.dictionary.fieldCreateEdit.answer}} {{i + 1}}
                <!-- <clr-icon shape="cursor-move" style="margin-left: 1rem;" cdkDragHandle></clr-icon>
                <clr-icon *ngIf="fieldSnapshot.fieldOptions.length > 1"
                    style="margin-left: 1rem; margin-right: 1rem;" shape="trash" (click)="deleteOption(i)">
                </clr-icon> -->
                <div>{{fieldOption.label}}</div>


            </div>
            <div class="fieldInputOption">
                <clr-input-container >
                    <label style="display: flex; justify-content: flex-start;padding-left: 2vw;min-width: 10rem;">
                        <div style="display: flex; flex-wrap: wrap">
                            <div style="white-space: nowrap; margin-right: 1rem; min-width: 5vw;">
                              {{localization.dictionary.fieldCreateEdit.answer}} {{i + 1}}
                            </div>
                            <span style="display: flex; flex-wrap: nowrap ">
                                <clr-icon shape="cursor-move" cdkDragHandle>
                                </clr-icon>
                                <div *ngIf="fieldSnapshot.fieldOptions?.length == 1"
                                    style="height: 16px; width: 16px">
                                </div>
                                <clr-icon *ngIf="fieldSnapshot.fieldOptions.length > 1"
                                    style="margin-left: 1rem;" shape="trash"
                                    (click)="deleteOption(i)"></clr-icon>
                            </span>
                        </div>
                    </label>

                    <input required
                        clrInput [(ngModel)]="fieldSnapshot.fieldOptions[i].label"
                        type="text" [name]="'option' + fieldSnapshot.fieldOptions[i].id" maxlength="100">
                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                </clr-input-container>
              </div>
            </div>
        </div>

    <div class="clr-form-control clr-row">
        <div class="clr-control-container clr-control-label clr-col-12 clr-col-md-2"></div>
        <button class="btn btn-sm btn-outline" style="margin-left: 0.6rem;"
        (click)="createOption(fieldSnapshot.fieldOptions ? fieldSnapshot.fieldOptions?.length : 0)">
        {{localization.dictionary.button.add}}
        </button>
    </div>

      <div class="modal-footer">
          <button class="btn btn-outline" type="button" (click)="closeModal()">{{localization.dictionary.button.cancel}}</button>
          <button class="btn btn-primary" type="button" (click)="saveModalChanges()">{{localization.dictionary.button.save}}</button>
      </div>
    </form>
    </div>
      </div>
  </div>
</div>
<div class="modal-backdrop fade in" aria-hidden="true"></div>
</div>
