<ol class="list">
    <h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
        <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
        <li>Vergeben Sie einen aussagekräftigen Titel, z.B. „Neue Dokumente“ und ergänzen Sie die Nachricht, z.B.“ Es sind neue Dokumente verfügbar. Bitte synchronisieren Sie kurzfristig die Daten in der App.“.</li>
        <li>Beim Update-Typ können Sie zwischen „Ohne“ und „Kategorie-Typ“ auswählen. Bei „Kategorie-Typ“ öffnet sich bei den Empfängern der Nachricht zusätzlich ein Fenster, in dem man direkt synchronisieren kann. Beim Typ „Ohne“ erscheint nur die Nachricht.</li>
    <h4 style="position: relative; right: 1.1em;">Empfänger auswählen</h4>
        <li>Wählen Sie anschließend die Empfänger aus. Dies können Gruppen aber auch einzelne Benutzer sein.</li>
</ol>
<ol class="list">
    <h4 style="position: relative; right: 1.1em;">Versenden einer Push-Nachricht </h4>
        <li>Klicken Sie in der entsprechenden Zeile auf das Brief-Symbol.</li>
        <li>Es öffnet sich ein neues Fenster. Klicken Sie auf den Button „Senden“, um den Versand der Nachricht zu bestätigen.</li>
</ol>
