import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-view-create',
  templateUrl: './de-view-create.component.html'
})
export class DeViewCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
