import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GridsterConfig } from 'angular-gridster2';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { SettingService } from '../../settings/setting.service';
import { Dashboard } from '../dashboard.model';
import { WidgetInterface } from '../widgets/widget.interface';
import { Widget } from '../widgets/widget.model';
import { ModalService } from './diagram-popup/modal-data.service';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {
  @ViewChildren('widgetComp') widgetComponents: QueryList<WidgetInterface>;

  options: GridsterConfig;

  dashboard: Dashboard;
  dashboardSelection: Dashboard;
  dashboards: Dashboard[];
  dashboardsLoading = false;
  currentDashboardId: string;
  widgets: Widget[];
  kpis: number[] = [];

  fromDate: Date;
  fromDateValidate: string;
  toDate: Date;
  toDateValidate: string;

  edit = false;

  constructor(private server: ServerService, 
    private settings: SettingService, 
    public localization: LocalizationService,
    public modalService: ModalService) { }

  async ngOnInit(): Promise<void> {
    await this.loadDashboards();
    //const widgets = await this.server.get('widgets');
    //this.widgets = plainToClass(Widget, widgets.rows);

    this.options = {      
      minCols: 4,
      maxCols: 4,
      fixedRowHeight: 125,
      gridType: 'verticalFixed',
      displayGrid: 'onDrag&Resize',
      addEmptyRowsCount: 2,
      disableScrollHorizontal: true,
      enableBoundaryControl: true,
      setGridSize: true,
      pushItems: true,
      pushResizeItems: true,
      
      //swap: true,
      draggable: {
        delayStart: 0,
        enabled: false,
        ignoreContent: false,
        dragHandleClass: 'drag-handler',
        dropOverItems: true,
        
      },
      resizable: {
        enabled: false
      }
    };    
    this.kpis = await this.server.get('tracking/kpis') as any;

  }

  async loadDashboards(name?: string) {
    this.dashboardsLoading = true;
    const dashboards = await this.server.get('analytics/dashboard');
    this.dashboards = plainToClass(Dashboard, dashboards) as any;
    
    // remove CRM Dashboard if CRM inactive
    if (!this.settings.featureActiveList['crmActive']) {
      this.dashboards = this.dashboards.filter(dash => dash.id != 'a617e2fd-817a-4715-9bc8-772995fb89c1') 
    } 
    
    
    if (localStorage.getItem('amsel-currentDashboardId')) {
      this.dashboard = this.dashboards.find( (dashb) => dashb.id == localStorage.getItem('amsel-currentDashboardId'));
      this.currentDashboardId = localStorage.getItem('amsel-currentDashboardId');
    }
    if (!this.dashboard && this.dashboards[0]) {
      this.setDashboard(this.dashboards[0]);
    }
    this.dashboardsLoading = false; 
  }

  setDashboard(dashboard: Dashboard) {
    if (dashboard) {
      this.dashboard = dashboard;
      this.currentDashboardId = dashboard.id;
      localStorage.setItem('amsel-currentDashboardId', this.currentDashboardId);
    } 
  }

  async refresh(from?: Date, to?: Date) {
    this.fromDate = from;
    this.toDate = to;
    await this.update();
  }

  async update() {
   // set time to 0:00 for fromDate
    if (this.fromDate) {
      this.fromDate.setHours(0);
      this.fromDate.setMinutes(0);
      this.fromDate.setSeconds(0); 
    }
    // set time to 23:59 for toDate
    if (this.toDate) {
      this.toDate.setHours(23);
      this.toDate.setMinutes(59);
      this.toDate.setSeconds(59);
    } 
    // Refresh KPIs
    let query = new URLSearchParams();
    if (this.fromDate) query.append('from', this.fromDate.toISOString());
    if (this.toDate) query.append('to', this.toDate.toISOString());
    this.kpis = await this.server.get('tracking/kpis?' + query.toString()) as any;
    // Refresh widgets with new dates
    this.widgetComponents.forEach( (widget) => {
      widget.refresh(this.fromDate, this.toDate);
    })
  }

  /**
   * Clears input and updates without 
   */
   clear() {
    this.fromDate = undefined;
    this.toDate = undefined;
    this.update();
  }

  async test() {
    //console.log(this.dashboard.widgets);
    const test = await this.server.put('analytics/dashboard', this.dashboard);
  }

}
