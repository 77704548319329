<h3>Listen Funktionen</h3>
<p>Jede Liste innerhalb der amSel biete möglichkeiten mit den dargestellten Einträge zu arbeiten. Diese Funktionen sind für alle Listen gleich.</p>
<h4>Spalten Filtern</h4>
<ul class="list">
    <li>Sie können in jeder Spalte der Liste, Filter spezifisch für diese Spalte einstellen</li>
    <li>Klicken Sie dazu auf das „<cds-icon solid shape="filter-grid"></cds-icon>“ Icon neben dem Spalten namen</li>
    <li>Je nach Inhalt des Spalten können Sie nun eine Auswahl treffen oder einen Text suchen</li>
</ul>
<h4>Zeige gelöschte Einträge</h4>
<ul class="list">
    <li>An der oberen rechten Ecke der Liste befindet sich ein Schalter um gelöschte Einträge ein- oder aus-zublenden.</li>
    <li>Mit dieser Funktion können Sie sich zuvor geschlöchte Einträge anzeigen lassen.</li>
    <li>In den Aktionen können Sie gelösche Einträge mit dem Icon „ <clr-icon shape="backup-restore"></clr-icon>“ wiederherstellen.</li>
</ul>
<h4>{{localization.dictionary.list.chooseColumns}}</h4>
<!-- <h4>Spalten Anzeigen oder Ausblenden</h4> -->
<ul class="list">
    <li>An der unteren linken Ecke der Liste befindet sich eine Schaltfläche um Spalten ein- oder aus-zublenden.</li>
    <li>Wählen die gewünschte Spalten mit der Checkbox aus, bzw. ab.</li>
    <!-- <li>Mit der Schaltfläche „ <span style="color: #57c8ea; font-weight: 500; font-size: 0.55rem;">ALLE AUSWÄHLEN</span> “ aktivieren Sie alle Spalten.</li> -->
    <li>Mit der Schaltfläche „ALLE AUSWÄHLEN“ aktivieren Sie alle Spalten.</li>
</ul>
<h4>Anzahl der Ergebnisse anpassen</h4>
<ul class="list">
    <li>An der unteren rechten Ecke der Liste befindet sich eine Schaltfläche um die maximale Anzahl der Ergebnisse fest zu legen</li>
    <li>Klicken Sie auf den Pfeil neben der aktuellen Anzahl und wählen Sie ein neues Maximum.</li>
</ul>
