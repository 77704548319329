import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClrDatagridFilter, ClrDatagridFilterInterface, ClrSide } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { Call } from 'src/app/crm/calls/call.model';
import { Contact } from 'src/app/crm/contacts/contact.model';
import { CrmService } from 'src/app/crm/crm.service';
import { Customer } from 'src/app/crm/customers/customer.model';
import { ServerResponse, ServerService } from 'src/app/server.service';
import { Field } from '../../../crm/fields/field.model';
import { LocalizationService } from '../../../localization/localization.service';
import { GivenAnswer } from 'src/app/forms/given-answer.model';
import { OfferedAnswer } from 'src/app/forms/offered-answer.model';

@Component({
  selector: 'multiselect-filter',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css']
})


export class MultiselectComponent implements ClrDatagridFilterInterface<any>, OnChanges {

  selectedItems: boolean[] = []
  availableList: any[] = []

  @Input() toggle: boolean = true;
  @Input() name: string
  @Input() property: string;
  @Input() query: string;
  @Input() array: string[];
  @Input() localizationKey: string;
  @Input() crmCore: boolean;
  @Input() display: string = "name";
  @Input() customFields: boolean;
  @Input() searchBy: string = "id";
  @Input() confirm = false
  @Input() filter = {value: [], toggle: true, toggleNeeded: false};
  @Output() filterChange = new EventEmitter<{value: any[], toggle: boolean, toggleNeeded: boolean}>()
  changes = new Subject<any>();
  open = false
  andToggle = true

  value = { value: [], type: "multiAnd" }

  constructor(
    private filterContainer: ClrDatagridFilter,
    private server: ServerService,
    private crm: CrmService,
    public localization: LocalizationService
  ) {
    this.filterContainer.smartPosition.side = ClrSide.BEFORE;
    this.filterContainer.setFilter(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "filter") {
          if (!this.filter) {
            this.selectedItems = [];
            this.value.value = undefined;
            this.filter = {value: [], toggle: this.andToggle, toggleNeeded: undefined}
          }
          if (typeof this.filter == 'string') {
            this.filter = JSON.parse(this.filter as any);
          }
          if (Array.isArray(this.filter)) {
            this.filter = {value: this.filter, toggle: this.andToggle, toggleNeeded: undefined};
          }
          if (!this.filter.value || this.filter.value.length == 0) {
            this.value.value = undefined;
          }
          this.filter.toggleNeeded = this.toggle && !this.andToggle;
          this.value.value = this.filter.value;
          this.andToggle = this.filter.toggle;
          this.andOrChange(this.andToggle, false);
        }
        if ((propName == "array" && this.array) || (propName == "toggle" && !this.toggle)) {
          this.value.type = "multiOr"
          this.andToggle = false
          this.andOrChange(this.andToggle, false);
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.query && !this.array)
      this.query = this.property
    if (this.query) {
      if (this.query.includes('field/byId')) {
        const res = await this.server.get(this.query);
        const field = plainToClass(Field, res);
        this.availableList = field.fieldOptions;
        this.display = 'label';
      } else if (this.query.includes('offeredAnswers/byId')) {
        const res = await this.server.getUnwrapped<OfferedAnswer[]>(this.query);
        const offeredAnwers = plainToClass(OfferedAnswer, res);
        this.availableList = offeredAnwers;
        this.display = 'value';
      } else {
        const res = await this.server.get(this.query) as ServerResponse;
        if (this.property == 'contacts' || this.property == 'contact.id') {
          this.availableList = plainToClass(Contact, res.rows)
        } else if (this.property == 'customers' || this.property == 'customer.id') {
          this.availableList = plainToClass(Customer, res.rows);
        } else if (this.property == 'calls' || this.property == 'call.id') {
          this.availableList = plainToClass(Call, res.rows);
        } else {
          this.availableList = res.rows;
        }

      }

    } else {
      this.availableList = this.array;
    }
    this.loadSelectedItems();
  }

  onClick(reset?: boolean) {
    if (reset) {
      this.filter = {value: [], toggle: false, toggleNeeded: this.toggle && !this.andToggle};
      this.loadSelectedItems();
    }
    this.value.value = this.filter?.value ? [...this.filter.value] : this.filter?.value
    this.filterChange.emit(this.filter)
    this.changes.next('')
    this.open = false
  }

  loadSelectedItems() {
    for (let i = 0; i < this.availableList.length; i++) {
      this.selectedItems[i] = !!this.filter.value.includes(this.availableList[i][this.searchBy]);
    }
  }

  andOrChange(event: boolean, triggerChange = true) {
    if (event) {
      this.value.type = "multiAnd"
      this.property = this.property.replace("." + this.searchBy, "")
    }
    else {
      this.value.type = "multiOr"
      if (!/(fieldValues|givenAnswers)\./.test(this.property) && !this.array) {
        this.property = this.property.replace("." + this.searchBy, "") + "." + this.searchBy
      }
    }
    if (this.filter && typeof this.filter != 'string') {
      this.filter.toggle = event;
      this.filter.toggleNeeded = this.toggle && !this.andToggle;
    }
    if (triggerChange) {
      this.onChanged()
    }
  }

  onChanged() {
    const output = [];
    for (let i = 0; i < this.availableList.length; i++) {
      if (this.selectedItems[i] === true) output.push(this.array ? this.availableList[i] : this.availableList[i][this.searchBy])
    }
    this.filter.value = output;
    if (!this.confirm) {
      this.value.value = this.filter?.value ? [...this.filter.value] : this.filter?.value
      this.filterChange.emit(this.filter)
      this.changes.next('')
    }
  }

  isActive(): boolean {
    return this.confirm ? this.value.value && this.value.value.length > 0 : this.filter?.value && this.filter?.value.length > 0
  }

  accepts() {
    return true;
  }

  getDisplayName(item: any) {
    if (this.customFields) {
      return this.crm.getDisplayValue(item);
    }
    if (this.crmCore) {
      return this.localization.dictionary[item].nameSingular;
    }
    if (this.localizationKey) {
      return this.localization.dictionary[this.localizationKey][item];
    }
    if (this.array) {
      return item;
    }
    if (/^user(s)$/.test(this.property.replace('.id', ''))) {
      return item[this.display] + ` (${item.firstName} ${item.lastName})`;
    }
    return item[this.display];
  }
}