import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-customer-create',
  templateUrl: './de-customer-create.component.html'
})
export class DeCustomerCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
