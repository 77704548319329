import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class CategoryDocument {  

    @Expose()
    order: number;

    @Expose()
    @Type(() => Date)
    deletedAt: Date;

}