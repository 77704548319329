import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ClrCombobox } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { Field } from 'src/app/crm/fields/field.model';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { QuestionType } from '../../question.model';

@Component({
  selector: 'app-ref-selector',
  templateUrl: './ref-selector.component.html',
  styleUrls: ['./ref-selector.component.css']
})
export class RefSelectorComponent implements OnInit {
  @Input() questionType: QuestionType;
  @Input() table: 'customer' | 'contact' = 'customer';
  @Input() field: Field;

  @Output() changed: EventEmitter<{table: string, field: Field}> = new EventEmitter();

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  asyncFields$: Subject<Field[]> = new Subject();

  loading = false;

  constructor(private server: ServerService,
    public localization: LocalizationService) { }

  ngOnInit(): void {
  }

  async onChange(ev: 'customer' | 'contact') {
  }

  async fetchFields(ev = '') {
    const testField = new Field();
    testField.name = 'Test';
    let query = '?'
    if (this.table) {      
      query += 'appliesTo' + this.table[0].toUpperCase() + this.table.substring(1) + '={"type": "boolean", "value": "true"}';
    } else {
      return;
    }
    if (ev) {
      query += '&label=' + ev;
    } 
    // if (this.questionType) {
    //   query += '&type=' + this.questionType;
    // }
    const res = await this.server.get('crm/field/onlyOptions' + query); //table=' + this.table 
    const fields = plainToClass(Field, res.rows)
    this.asyncFields$.next(fields);
  }

}
