import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function fileNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
       
        if (control.value) {
            const nameRe = /^(?!^(PRN|AUX|CLOCK\$|NUL|CON|COM\d|LPT\d|\..*)(\..+)?$)[^\x00-\x1f\\?*:\"|<>]+$/;
            const forbidden = !nameRe.test(control.value);
            return forbidden ? {forbiddenFileName: {value: control.value}} : null;
        } else {
            return null;
        }
        
    };
  }