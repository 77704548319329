<clr-icon *ngIf="isActive()" class="refresh-filter is-solid" shape="filter-off" (click)="onClick(true)"></clr-icon>
<form>
    <clr-input-container [class.active]="document.activeElement == searchFrom" *ngIf="activeFrom">
        <label>{{localization.dictionary.filter.startTime}}</label>
        <input clrInput type="time" [(ngModel)]="modelFrom" (ngModelChange)="onChange()" width="unset" [max]="modelTo"
            (keyup.enter)="open = false" #searchFrom id='searchFrom' name="from" autocomplete="off" />
    </clr-input-container>
    <clr-icon *ngIf="activeFrom" class="fake-calendar" shape="clock"></clr-icon>
    <clr-input-container  [class.active]="document.activeElement == searchTo" *ngIf="activeTo" #toContainer>
        <label>{{localization.dictionary.filter.endTime}}</label>

        <input clrInput type="time" [(ngModel)]="modelTo" (ngModelChange)="onChange()" [min]="modelFrom"
            (keyup.enter)="open = false" #searchTo id='searchTo' name="to" autocomplete="off" />
        

    </clr-input-container>
    
    <clr-icon *ngIf="activeTo" class="fake-calendar" shape="clock"></clr-icon>
    <button *ngIf="confirm" type="submit" class="btn btn-primary btn-sm" (click)="onClick()">{{localization.dictionary.button.confirm}}</button>
</form>