import { EventType } from "./event-type.type";

export class DefaultEvent {
    public date: Date; 
    public type: EventType;        
    public msg: string;
    public origin: string;
    public name: string;
    public table: string;
    public username: string = 'System';
}