<h2>{{ localization.dictionary.product.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected"
    (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.product.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['name']">
            {{localization.dictionary.general.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'description'" [(clrDgSortOrder)]="sorting['description']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['Beschreibung']">
            {{localization.dictionary.general.description}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="descriptionFilter" [(clrDgFilterOpen)]="descriptionFilter.open">
            <string-filter #descriptionFilter [property]="'description'" [(filter)]="filters['description']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'itemNumber'" [(clrDgSortOrder)]="sorting['itemNumber']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['itemNumber']">
            {{localization.dictionary.general.itemNumber}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="itemNumberFilter" [(clrDgFilterOpen)]="itemNumberFilter.open">
            <string-filter #itemNumberFilter [property]="'itemNumber'" [(filter)]="filters['itemNumber']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'unit'" [(clrDgSortOrder)]="sorting['unit']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['unit']">
            {{localization.dictionary.general.unit}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="unitFilter" [(clrDgFilterOpen)]="unitFilter.open">
            <string-filter #unitFilter [property]="'unit'" [(filter)]="filters['unit']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'price'" [(clrDgSortOrder)]="sorting['price']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['price']">
            {{localization.dictionary.general.price}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="priceFilter" [(clrDgFilterOpen)]="priceFilter.open">
            <number-filter #priceFilter [property]="'price'" [(filter)]="filters['price']"></number-filter>
        </clr-dg-filter>
    </clr-dg-column>
    
     <clr-dg-column [clrDgSortBy]="'customers.fieldValues.' + crm.sortField.customer" [(clrDgSortOrder)]="sorting['customers']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['customers']">
            {{localization.dictionary.customer.name}}
        </ng-template>
        <!-- <clr-dg-filter [clrDgFilter]="customersFilter" [(clrDgFilterOpen)]="customersFilter.open">
            <multiselect-filter #customersFilter [name]="localization.dictionary.customer.name" [customFields]="true" [property]="'customers'" [query]="'crm/customer/filter'"
                [(filter)]="filters['customers']"></multiselect-filter>
        </clr-dg-filter> -->
    </clr-dg-column> 

    <clr-dg-column [clrDgSortBy]="'active'" [(clrDgSortOrder)]="sorting['active']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['active']">
            {{localization.dictionary.general.active}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="activeFilter" [(clrDgFilterOpen)]="activeFilter.open">
            <boolean-filter #activeFilter [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no" [property]="'active'" [(filter)]="filters['active']"></boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column >
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['picture']">
            {{localization.dictionary.general.preview}}
        </ng-template>
        
    </clr-dg-column>


    <clr-dg-column [clrDgSortBy]="'documents.name'" [(clrDgSortOrder)]="sorting['documents']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['documents']">
            {{localization.dictionary.document.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="documentsFilter" [(clrDgFilterOpen)]="documentsFilter.open">
            <multiselect-filter #documentsFilter [name]="localization.dictionary.document.name" [property]="'documents'" [query]="'document/filter'"
                [(filter)]="filters['documents']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column> 

    <clr-dg-column [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="createdFilter"
                        [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter  #createdFilter
                            [property]="'createdAt'" 
                            [(filterFrom)]="filters['createdAtFrom']"
                            [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'" [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="updatedFilter"
                        [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter  #updatedFilter
                            [property]="'updatedAt'" 
                            [(filterFrom)]="filters['updatedAtFrom']"
                            [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'" [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="deletedFilter"
                        [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter  #deletedFilter
                            [property]="'deletedAt'" 
                            [(filterFrom)]="filters['deletedAtFrom']"
                            [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>


    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>


    <clr-dg-row *ngFor="let product of products" [clrDgItem]="product" [class.deleted]="product.deletedAt">
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ product.id }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ product.name }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ product.description }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ product.itemNumber }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ product.unit }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ product.price }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">
            <span *ngFor="let customer of product.customers"
                        class="label label-light-blue">
                <app-display-name [model]="customer" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">{{ (product.active)? localization.dictionary.general.yes : localization.dictionary.general.no }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]" class="picture" [style.background-image]="product.picture"></clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">
            <div *ngFor="let document of product.documents" class="tooltip-container">
                <span class="label label-light-blue"
                      [pTooltip]="document.description"
                      tooltipPosition="top">
                    {{ document.name }}
                </span>
            </div>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">
            {{ product.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="['/crm/product/edit/' + product.id]">
            {{ product.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="['/crm/product/edit/' + product.id]">
            {{ product.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <div *ngIf="!product.deletedAt" class="tooltip-container">
                <clr-icon shape="pencil"
                    [routerLink]="['/crm/product/edit/' + product.id]"
                    [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.product.nameSingular).replace('${entryName}', product.name)"
                    tooltipPosition="left">
                </clr-icon>
            </div>
            
            <div *ngIf="!product.deletedAt" class="tooltip-container">
                <clr-icon shape="trash"
                    (click)="toDelete = [product]"
                    [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.product.nameSingular).replace('${entryName}', product.name)"
                    tooltipPosition="left">
                </clr-icon>
            </div>
            
            <div *ngIf="product.deletedAt" class="tooltip-container">
                <clr-icon shape="backup-restore"
                    (click)="openRestoreModal(product)"
                    [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.product.nameSingular).replace('${entryName}', product.name)"
                    tooltipPosition="left">
                </clr-icon>
            </div>
            
        </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.product.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.product.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.product.nameSingular : localization.dictionary.product.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.productList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{ localization.dictionary.button.delete }}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.product.nameSingular).replace('${entryName}', selectedProduct?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        <!-- Wählen Sie die Gruppen und Rollen aus, mit denen der Benutzer wiederhergestellt werden soll:
        <app-list-selector (selectedChanged)="selectedProduct.groups = $event" [preLoaded]="selectedProduct?.groups" [label]="'Gruppen'" [query]="'group'"></app-list-selector>
        <app-list-selector (selectedChanged)="selectedProduct.roles = $event" [preLoaded]="selectedProduct?.roles" [label]="'Rollen'" [query]="'roles'"></app-list-selector> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="importModal" [clrModalStaticBackdrop]="false">
    <h3 class="modal-title">{{localization.dictionary.button.excelImport}}</h3>
    <div class="modal-body
    ">
        <p>{{localization.dictionary.product.productImport}}</p>
        <div   class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="onDownloadExcel();">{{ localization.dictionary.button.template }}</button>
            <button type="button" class="btn btn-primary" (click)="openInitialTemplateImport()">{{ localization.dictionary.button.import }}</button>
        </div>
    </div>
</clr-modal>
<input type="file" id="initialTempImp"  name="initialExcel" accept=".xlsx"
placeholder="Enter value here" class="clr-file"/>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/crm/product/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.product.nameSingular) }}</button>

            <!-- <button class="btn btn-primary" [disabled]="total === 0 || loading" (click)="onDownloadExcel()">
                {{ localization.dictionary.button.excelExport }}
            </button> -->

            <button class="btn btn-primary" [disabled]="loading" (click)="importModal = true">
                {{ localization.dictionary.button.import }}
            </button>

            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>            
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{ localization.dictionary.button.delete }}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['crm-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted" (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.product.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>