import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Customer } from '../../customers/customer.model';
import { FileUploadService } from '../file-upload-service';
import { FileUpload } from '../file-upload.model';
import { AuthService } from '../../../auth/auth.service';
import { ServerService } from '../../../server.service';
import { ErrorService } from '../../../helper/error/error.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent implements OnInit {
  @Output() uploaded = new EventEmitter<boolean>();
  @Input() mode;
  @ViewChild('fileInput') fileInput: ElementRef;
  isOpen = false;
  customerId: string;
  callId: string;
  allowedFiles = ['image/png', 'image/jpeg', 'application/pdf', 'text/plain', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  dirty = false;

  file: File;
  filename: string;
  fileType: string;
  fileExtension: string;
  desc: string;

  uploading = false;

  private uFile: FileUpload;

  constructor(
          public localization: LocalizationService,
          private fus: FileUploadService,
          private auth: AuthService,
          private server: ServerService,
          private errorService: ErrorService
  ) { 
    this.fus.open.subscribe((componentId: string) => {
      this.isOpen = true;
      if(this.mode == 'customer'){
        this.customerId = componentId;
      } else {
        this.callId = componentId;
      }
      
    });
  }

  ngOnInit(): void {
  }

  onUpload(files: FileList) {
    this.file = files[0];
    if(!this.dirty){
      this.filename = files[0].name.slice(0, files[0].name.lastIndexOf('.'));
      this.fileExtension = files[0].name.slice(files[0].name.lastIndexOf('.') + 1);
    }

    this.fileType = files[0].type;
  }

  async upload() {
    this.uFile = {
      id: null,
      filename: this.filename,
      desc: this.desc,
      fileType: this.fileType,
      fileExtension: this.fileExtension,
      userId: this.auth.user.id,
      customerId: this.customerId,
      customer: undefined,
      callId: this.callId,
      call: undefined
    };
    let formData: FormData = new FormData();
    formData.append('uFile', JSON.stringify(this.uFile))
    formData.append('file', this.file);
    this.uploading = true;
    try{
      await this.server.post('crm/file-upload/' + this.mode + '?sync=false', formData);
    } catch(err){
      this.errorService.handleError(err);
    }    
    this.uploaded.emit(true);
    this.uploading = false;
    formData.delete('file');
    this.clear();
  }

  clear() {
    this.isOpen = false;
    this.customerId = null;
    this.callId = null;
    this.dirty = false;
    this.file = null
    this.filename = null;
    this.fileType = null;
    this.fileExtension = null;
    this.desc = null;
    this.uFile = null;
    this.fileInput.nativeElement.value = '';
  }

  isAllowedType() {
    if(this.allowedFiles.includes(this.fileType)){
      return true;
    } else {
      return false;
    }
  }

}
