import { FieldOption } from "../crm/fields/field-option.model";
import { Question } from "./question.model";

export class OfferedAnswer {
    id: string;   
    fieldOptionId: string;
    fieldOption: FieldOption;
    questionId: string;
    question: Question;
    value: string;
    order: number;
    disabled: boolean;  
    new: boolean;  
    constructor(isNew = false) {
        this.new = isNew;
    }
}