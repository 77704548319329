import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ErrorService } from '../helper/error/error.service';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { SettingService } from '../settings/setting.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  

  constructor(public settings: SettingService, private errorService: ErrorService, private server: ServerService,
    public localization: LocalizationService, public auth: AuthService) { }

  async ngOnInit(): Promise<void> {
  }

  async ngAfterViewInit() {
    //console.log(this.widgetComponents);
  }
}
