import { Exclude, Expose, Type } from "class-transformer";
import { FieldOption } from "./field-option.model";
import { Field } from "./field.model";

Exclude()
export class FieldValue {
    @Expose()
    id: string;

    @Expose()
    fieldTableValue: string;

    @Expose()
    fieldId: string;

    @Expose()
    @Type(() => Field)
    field: Field;

    @Expose()
    fieldOptionId: string;

    @Expose()
    @Type(() => FieldOption)
    fieldOption: FieldOption;

    @Expose()
    value: string;
}