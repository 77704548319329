import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class SectionField { 

    constructor() {
        this.order = 0;
    }
    
    @Expose()
    id: string;
    @Expose()
    sectionId: string;
    @Expose()
    fieldId: string;
    @Expose()
    order: number = 0;
    @Expose()
    mandatory: boolean;
    @Expose()
    readOnly: boolean;

}