<form *ngIf="active" clrForm>
    <clr-select-container>
        <label>
            {{localization.dictionary.formCreateEdit.check}}                    
        </label>                    
        
        <select [disabled]="question.refField" clrSelect name="inputtype" [(ngModel)]="tempInput" (ngModelChange)="setInputType()" required>
          <option [value]="'NONE'">{{ localization.dictionary.pattern.NONE }}</option>
          <option [value]="'NAME'">{{ localization.dictionary.pattern.NAME }}</option>
          <option [value]="'EMAIL'">{{ localization.dictionary.pattern.MAIL }}</option>
          <option [value]="'POSTALCODE'">{{ localization.dictionary.pattern.POSTALCODE }}</option>
          <option [value]="'PHONENUMBER'">{{ localization.dictionary.pattern.PHONENUMBER }}</option>
          <option [value]="'CUSTOM'" *ngIf="question.refField">{{ localization.dictionary.pattern.CUSTOM }}</option>
          <!--
          <option *ngFor="let inType of inputTypes| keyvalue" [value]="inType.key">
              {{ inType.key }}
          </option>
          -->
        </select>
        <clr-control-helper>
            
            <ng-container [ngSwitch]="tempInput">
                <span *ngSwitchCase="'NONE'">
                    {{localization.dictionary.formCreateEdit.check}}
                </span>
                <span *ngSwitchCase="'NAME'">
                    {{localization.dictionary.formCreateEdit.checkName}}      
                </span>
                <span *ngSwitchCase="'EMAIL'">
                    {{localization.dictionary.formCreateEdit.checkMail}}      
                </span>
                <span *ngSwitchCase="'POSTALCODE'">
                    {{localization.dictionary.formCreateEdit.checkZip}}      
                </span>
                <span *ngSwitchCase="'PHONENUMBER'">
                    {{localization.dictionary.formCreateEdit.checkPhone}}      
                </span>
                <span *ngSwitchDefault> {{ localization.dictionary.fieldCreateEdit.customRegularExpression }} </span>
            </ng-container>
        </clr-control-helper>
        <!--<clr-control-error>This field is required!</clr-control-error>-->
      </clr-select-container>
      <clr-input-container *ngIf="tempInput == 'CUSTOM'">
        <input disabled clrInput type="text"
            name="customRegEx" [(ngModel)]="question.refField.pattern" maxlength="50"/>
    </clr-input-container>
</form>
<div *ngIf="!active" class="show-answers">
    {{localization.dictionary.formCreateEdit.check}}: {{ (question.inputType) ? localization.dictionary.pattern[question.inputType] : localization.dictionary.pattern.NONE }}
</div>