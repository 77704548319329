import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Role } from "src/app/roles/role.model";
import { User } from "src/app/users/user.model";
import { Call } from "../calls/call.model";
import { Contact } from "../contacts/contact.model";
import { Customer } from "../customers/customer.model";



export class Conflict {
    @Expose()
    id: string;

    @Expose()
    @Type(() => Date)
    date: Date;

    @Expose()
    @Type(() => Customer)
    customer: Customer;

    @Expose()
    @Type(() => Call)
    call: Call;

    @Expose()
    @Type(() => Contact)
    contact: Contact;

    @Expose()
    user: User;

    @Expose()
    json: string;


    deletedAt: any;

    get url(): string {
         if (this.customer) {
            return '/crm/customer/conflict/' + this.id
         }
         if (this.call) {
            return '/crm/call/conflict/' + this.id
         } 
         if (this.contact) {
            return '/crm/contact/conflict/' + this.id
         }  
    };

}