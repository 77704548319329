import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { SettingService } from 'src/app/settings/setting.service';
import { AuthService } from '../../../auth/auth.service';
import { ExcelService } from '../../../excel.service';
import { DatagridFunctions } from '../../../helper/datagrid-functions.class';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { Contact } from '../../contacts/contact.model';
import { CrmService } from '../../crm.service';
import { Field } from '../../fields/field.model';
import { View } from '../../views/view.model';
import { Customer } from '../customer.model';
import { ActivatedRoute } from '@angular/router';
import { AuditService } from 'src/app/audit.service';
import { TooltipDirective } from 'src/app/tooltip.directive';

@Component({
  selector: 'app-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrls: ['./list-customers.component.css']
})
export class ListCustomersComponent implements OnInit, OnDestroy, DatagridFunctions {
 
  contactType = Contact;
  customers: Customer[];
  selected: Customer[] = [];
  toDelete: Customer[];
  toDeleteContacts: Contact[];
  auditLogModalOpen = false;
  entityId: string;
  entityType = 'Customer';
  loading = true;
  saving = false;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  prefiltered: boolean = false;
  hidden: { [column: string]: boolean } = {
    id: true,
    createdAt: true,
    updatedAt: true,
    createdBy: true,
    updatedBy: true,
    deletedBy: true,
  }
  state: ClrDatagridStateInterface;
  exportState: ClrDatagridStateInterface;
  showDeleted = false;
  selectedCustomer: Customer;
  restoreModal = false;
  exportModal = false;
  importModal = false;
  columnChange = false;
  columnChanged = false;
  deleteFilters = false;
  selectedCustomerView: View = null;
  selectedContactView: View = null;

  views: View[] = [];
  contactViews: View[] = [];
  activeView: View;
  contactView: View;
  sectionFields: Field[];
  filterFrom = new Date();
  filterTo = new Date();
  loadingModal = false;

  multiUpdate = false;
  multiModel = {};

  tooltipElement: HTMLElement;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  constructor(public server: ServerService,
    public auth: AuthService,
    public setting: SettingService,
    public helper: HelperService,
    public crm: CrmService,
    public excelService: ExcelService,
    public localization: LocalizationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    const dgOptions = this.crm.getDatagridOptions('customer');
    this.filters = dgOptions.filters;
    this.hidden = dgOptions.hidden;
    this.sorting = dgOptions.sorting;
    if (this.route.queryParams['_value'] && Object.keys(this.route.queryParams['_value']).length > 0) {
      this.prefiltered = true;
      this.filters = this.route.queryParams['_value'];
    }
    this.views = this.crm.views.customer;
    this.contactViews = this.crm.views.contact;
    this.crm.setActiveViewsFromStorage(['customer']);
    this.crm.setActiveViewsFromStorage(['contact']);
    this.activeView = this.crm.activeViews.customer;
    this.contactView = this.crm.activeViews.contact;
    this.activeViewChange();
  }

  activeViewChange(ev?) {
    this.sectionFields = [];
    this.cdr.detectChanges();
    if (ev) {
      this.activeView = ev;
    }
    if (!this.activeView) {
      this.activeView = this.views[0];
    }
    if (!this.contactView) {
      this.contactView = this.contactViews[0];
    }
    for (let section of this.activeView.sections) {
      if (section.fields.length > 0) {
        this.sectionFields.push(...section.fields);
      }
    }
    this.crm.activeViews.customer = this.activeView;
  }

  ngOnDestroy() {
    if (!this.prefiltered) {
      localStorage.setItem('customer-filters', JSON.stringify(this.filters));
      localStorage.setItem('customer-sorting', JSON.stringify(this.sorting));
      localStorage.setItem('customer-columns', JSON.stringify(this.hidden));
    }
    if (this.activeView) {
      localStorage.setItem('customer-active-view', JSON.stringify(this.activeView));
    }
    if (this.showDeleted)
      localStorage.setItem('customer-deleted', 'true')
    else
      localStorage.removeItem('customer-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    if (state.page.from == -1 || state.page.to == -1) {
      state.page.from = 0;
      state.page.to = 20;
      state.page.size = 20;
    }

    this.state = state;
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const customer = await this.server.get('crm/customer/' + (this.showDeleted ? 'all' : '') + query);
    this.total = customer.count;
    this.customers = plainToClass(Customer, customer.rows);
    this.loading = false;
  }

  selectionChange(customers: Customer[]) {
    if (customers.length > 0) {
      this.order = false;
    }
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('crm/customer/')
    }
    this.order = !this.order;
  }

  async openRestoreModal(customer: Customer) {
    this.selectedCustomer = customer;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('crm/customer/restore', this.selectedCustomer);
    /*     Kunde erfolgreich wiederhergestellt */
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.customer.nameSingular).replace("${entryName}", this.crm.getDisplayValue(this.selectedCustomer))));
    this.refresh();
    this.refresh();
    this.restoreModal = false;
  }

  async onDelete(customers: Customer[]) {
    const customerIds = customers.map((cus) => cus.id);
    const res = await this.server.get('crm/contact/getOrphans?ids=' + customerIds);
    this.toDeleteContacts = plainToClass(Contact, res.rows);
    this.toDelete = customers;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const customerIds = this.toDelete.map((customer) => customer.id);
    await this.server.delete('crm/customer?toDelete=' + customerIds);
    if (this.toDeleteContacts && this.toDeleteContacts.length > 0) {
      this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.customerList.successDeletedCusAndCon));
    } else {
      this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
        this.toDelete.length == 1 ? this.localization.dictionary.customer.nameSingular : this.localization.dictionary.customer.name)));
      this.refresh();
    }
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
  }

  async resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    delete this.state.sort;
    this.deleteFilters = true;
    await this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  async checkExportType(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.exportState = state;
    this.exportState.page.size = 0;
    if (this.exportState.filters) {
      this.exportModal = true;
    } else {
      await this.downloadExcel("?skip=0&limit=0");
    }
  }

  async downloadExcel(query?: string) {
    this.loading = true;
    this.exportModal = false;
    if (!query) {
      query = this.server.buildQueryFromGrid(this.exportState);
    }
    await this.crm.downloadExcel('customer', query, this.showDeleted);
    this.loading = false;
  }

  downloadInitialExcelTemplate() {
    this.crm.downloadExcelTemplate('view', [this.selectedCustomerView, this.selectedContactView]);
  }

  async onImportInitialExcel(event: Event) {

    this.loading = true;
    let views = [this.selectedCustomerView, this.selectedContactView];

    let answer = await this.excelService.importInitialXLSX(event, views);
    this.loadingModal = false;
    this.refresh()

  }

  openInitialTemplateImport() {
    const fileInput = document.getElementById('initialTempImp') as HTMLInputElement;
    fileInput.click();
    fileInput.onchange = (e) => {
      this.loadingModal = true;
      this.importModal = false;
      this.onImportInitialExcel(e);
    }
  }

  openAuditLogModal(entityId: string) {
    this.entityId = entityId;
    this.auditLogModalOpen = true;
  }
}
