<form clrForm #formData="ngForm" (click)="dirtyChanged.emit(true)">
    <clr-checkbox-container (change)="onChanged($event)" [class.clr-form-control-disabled]="disabled || availableList.length < 1">
        <label>{{ label }}{{ required ? '*' : '' }}</label>
        <clr-checkbox-wrapper >
          <input type="checkbox" clrCheckbox [name]="'selectAll'" [value]="'all'" (change)="selectAll()" [indeterminate]="state === 'partial'"
           [checked]="state === 'all'" [disabled]="disabled || availableList.length < 1"/>
          <label class="select-all">{{(state=='all'? localization.dictionary.listSelector.unselectAllLabel : localization.dictionary.listSelector.selectAllLabel).replace('${label}', label)}}</label>
        </clr-checkbox-wrapper>

        <clr-checkbox-wrapper *ngIf="availableList.length < 1" hidden>
          <input type="checkbox" clrCheckbox [name]="'item0'" value="item0" ngModel [required]="required"/>
          <label>
            Nothing to see
          </label>
        </clr-checkbox-wrapper>
        
        <clr-checkbox-wrapper *ngFor="let item of availableList; let i = index">
          <input type="checkbox" clrCheckbox [name]="'item' + i" [value]="item" [(ngModel)]="selectedItems[i]" 
          [required]="required && state == 'none'" [disabled]="disabled || availableList.length < 1"/>
          <label>
            <span
                [pTooltip]="item?.description || (item?.firstName && item?.lastName ? item?.firstName + ' ' + item?.lastName : null)"
                [tooltipPosition]="'right'">
                {{ displayFields ? crm.getDisplayValue(item) : (attrToShow == 'fullName' ? item.firstName + ' ' + item.lastName : item[attrToShow]) }}
            </span>
         
          </label>
        </clr-checkbox-wrapper>
        <clr-control-helper *ngIf="conflict">
          <div>{{localization.dictionary.conflict.manageConflict}}</div>
          <clr-radio-container class="conflict">
              <clr-radio-wrapper>
                  <input type="radio" required clrRadio [value]="label + 'conflict' + 1"
                      [name]="label + 'conflict'" [(ngModel)]="conflictChoices['label']"
                      (ngModelChange)="updateSelected(tempList); conflictChoicesChange.emit(conflictChoices)" />
                  <label>{{ displayList(tempList, true) }} ({{localization.dictionary.createEdit.current}})</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                  <input type="radio" required clrRadio [value]="label + 'conflict' + 2"
                      [name]="label + 'conflict'" [(ngModel)]="conflictChoices['label']"
                      (ngModelChange)="updateSelected(conflict.value); conflictChoicesChange.emit(conflictChoices)" />
                  <label>{{ displayList(conflict.value)}}
                      ({{conflict.user.name}},
                      {{conflict.date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
              </clr-radio-wrapper>
              <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
          </clr-radio-container>
      </clr-control-helper>
        <clr-control-error *clrIfError="'required'">{{localization.dictionary.listSelector.requiredInput.replace('${none}', availableList.length < 1? localization.dictionary.listSelector.noneForLabel.replace('${label}', label) : '')}}</clr-control-error>
      </clr-checkbox-container>         
</form>