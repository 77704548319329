import { Component, OnInit, ViewChild } from '@angular/core';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { ServerService } from 'src/app/server.service';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Language } from '../language.model';
import { LocalizationService } from '../localization.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ClrForm } from '@clr/angular';
import { Table } from 'primeng/table';
import { HelperService } from 'src/app/helper/helper.service';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/excel.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../settings/setting.service';
import { Dictionary } from '../dictionary.model';

@Component({
  selector: 'app-create-edit-language',
  templateUrl: './create-edit-language.component.html',
  styleUrls: ['./create-edit-language.component.css']
})
export class CreateEditLanguageComponent extends PreventUnload implements OnInit {

  status: any;
  progress: number;



  loading = true;
  id: string;
  basicLanguageId: string;
  baselanguageName: string;
  languages: Language[] = [];
  language = new Language();
  dictionaries: Dictionary[];
  mode: 'edit' | 'create';
  invalid = false;
  errTemp = {};
  loadingExcel = false;
  downloading = false;
  file: File;
  result: any[];
  isExcelFile: boolean;
  errors: AmselError[] = [];
  showMissing: boolean = false;
  noMissing: boolean = false;
  openWarning: boolean = false;
  dictReload = false;
  allowedFiles = '.xlsx';
  missMatch = false;



  collapse: boolean[] = [
    false,
    false,
    false,
    false
  ]

  tags = [
    { label: '-', value: '' },
    { label: 'APP', value: 'APP' },
    { label: 'WEB', value: 'WEB' },
    { label: 'SHARED', value: 'SHARED' }
  ]

  @ViewChild(ClrForm) form: ClrForm;
  private formData: NgForm;

  @ViewChild('formData', { static: false }) set content(content: NgForm) {
    if (content) { // initially setter gets called with undefined      
      this.formData = content;
    }
  }

  constructor(public localization: LocalizationService,
    private route: ActivatedRoute,
    public server: ServerService,
    public helper: HelperService,
    public setting: SettingService,
    private toastrService: ToastrService,
    private excelService: ExcelService,
  ) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.mode = (params.id) ? 'edit' : 'create';
        this.id = params.id;
        if (this.mode === 'edit') {
          this.language = plainToClass(Language, await this.server.get('language/byId/' + params.id));
        } else if (this.mode === 'create') {
          this.language = new Language();
          this.dirty = true;
        }
        this.languages = this.localization.languages.filter((lang => lang.id != this.language.id));
        const selectedLanguage = this.languages.find(lang => this.localization.language.id == lang.id) || this.languages[0]
        this.basicLanguageId = selectedLanguage.id;
        this.baselanguageName = selectedLanguage.name;
        this.changeUnload();
        await this.changeBaseLanguage(this.basicLanguageId);
        this.setProgressBar();
        if (this.excelService.file) {
          this.importExcel(this.excelService.file)
        }
        this.loading = false;
        this.excelService.deleteFile();
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(() => {
        this.changeUnload();
      })
    );
    this.sortDicts();
  }

  ngOnInit(): void { }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('language');
    } else {
      this.changeUnloadEdit('language', this.language.name);
    }
  }

  getClass(string: string) {
    if (window.innerWidth < 1000) {
      return string + '-column';
    } else {
      return string + '-row';
    }
  }

  async changeBaseLanguage(basicLanguageId: string) {
    const base = plainToClass(Language, await this.server.get('language/byId/' + basicLanguageId));
    this.baselanguageName = base.name;
    this.dictReload = true;
    if (this.mode == 'edit') {
     this.language.dictionaries.forEach(dict => {
        dict["baseLanguage"] = base.dictionaries.find(baseDict =>
            baseDict.element == dict.element && baseDict.languageCategoryId == dict.languageCategoryId).label
        });
    } else {
      this.language.dictionaries = base.dictionaries.map(baseDict => {
        const baseDictCopy = { ...baseDict };
        delete baseDictCopy.id;
        delete baseDictCopy.label;
        delete baseDictCopy.languageId;
        return {
          baseLanguage: baseDict.label,
          label: this.language.dictionaries.find(dict =>
            baseDict.element == dict.element && baseDict.languageCategoryId == dict.languageCategoryId)?.label || ''
          , ...baseDictCopy
        }
      });
    }
    this.dictionaries = this.language.dictionaries.filter(dict => !this.setting.featureExcludes.includes(dict.languageCategory.feature));
    if (this.showMissing) {
      this.sortDicts()
    }
    this.dictReload = false;
  }

  /*  Excel-Export */



  /*   Tabelle-Export */

  /* onExportTable() {
    const table = document.getElementById('tableId') as HTMLTableElement;
    let fileName = this.localization.dictionary.language.new;
    if (this.language.name && this.language.name.length > 0) {
      fileName = this.language.name;
    }
    this.excelService.exportExcel(table, fileName);
  } */

  async onDownloadExcel() {
    let buffer = await this.excelService.downloadExcelLanguage(this.language, this.baselanguageName);
    let fileName = this.localization.dictionary.language.new;
    if (this.language.name && this.language.name.length > 0) {
      fileName = this.language.name;
    }
    this.excelService.downloadExcel(buffer, fileName + '.xlsx');
  }



  /*  Excel-Import */

  async onImportExcel(event: any) {

    this.loadingExcel = true;
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      this.loadingExcel = false;
      throw new Error('Cannot use multiple files');
    }
    let file = await this.excelService.getFile(event);
    if (this.progress > 0 ) {
      this.openWarning = true;
      this.file = file;
      return;
    }
    this.importExcel(file);

  }

  cancelImport(){
    this.openWarning = false; 
    delete this.file;
    // document.getElementById('fileExcel').nodeValue = '';
    this.loadingExcel = false;
  }

  
  importExcel(file: File = this.file, headers: string[] = ['category', 'element', 'base', 'label', 'tag']) {

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];     
      this.result = XLSX.utils.sheet_to_json(ws, { blankrows: false, header: headers }) as any[];
      try {
        let count = 0;
        for (let dict of this.dictionaries) {
          const resultDict = this.result.find((res) => res.element == dict.element && dict.languageCategory.name == res.category);
          if (resultDict) {
            dict.label = resultDict?.label || '';
          } if (dict.label == '' || !resultDict?.label) {
            count++;
          }
        }
        this.loadingExcel = false;
        this.setProgressBar();
        this.sortDicts();
        if (count > 0) {
          this.server.addAlert(new AmselError(undefined,
            'warning',
            this.localization.dictionary.language.warningExcel
              .replace('${entryCount}', '' + count)));
          this.missMatch = true;
        } else {
          this.toastrService.success(this.localization.dictionary.language.excelSuccess);
        }

      }
      catch (e) {
        console.error(e);
        this.loadingExcel = false;
        this.toastrService.error(this.localization.dictionary.language.errorExcel);
      }
    }
  }

  ec(r: any, c: any) {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }

  keydown(event){
    event.key == 'Enter' ? this.sortDicts() : '';
  }

  sortDicts(){
    this.dictReload = true;
    if (this.showMissing) {
      this.dictionaries = this.dictionaries.filter(dic => dic.label == '');
      this.dictionaries.length > 0 ? this.noMissing = false : this.noMissing = true;
    } else {
      this.dictionaries = this.language.dictionaries.filter(dict => !this.setting.featureExcludes.includes(dict.languageCategory.feature));
    }
    if (!this.dictionaries.find(dict => dict.label == '')) {
      this.noMissing = true;
      this.dictionaries = this.language.dictionaries.filter(dict => !this.setting.featureExcludes.includes(dict.languageCategory.feature));
    } else {
      this.noMissing = false;
    }
    this.dictReload = false; 
  }


  /*  SET Progress Bar*/
  setProgressBar() {
    if (!this.showMissing) {
      const dicLeng = this.dictionaries.length;
      const filled = this.dictionaries.filter(dict => dict.label && dict.label.length != 0).length;
      this.progress = Math.round((filled / dicLeng) * 100);
    } else {
      const oldDict = this.language.dictionaries.filter(dict => !this.setting.featureExcludes.includes(dict.languageCategory.feature));
      const dicLeng = oldDict.length;
      const filled = oldDict.filter(dict => dict.label && dict.label.length != 0).length;
      this.progress = Math.floor((filled/dicLeng) * 100);
    }
  }


  changeColor() {
    if (this.progress >= 66) {
      return 'green';
    } else if (this.progress >= 33) {
      return 'orange';
    } else if (this.progress >= 0 && this.progress <= 4) {
      return 'redRed';
    } else {
      return 'red';
    }
  }

  ngDoCheck() {
    if (this.form && this.formData && this.invalid) {
      this.formData.form.markAsDirty();
      this.formData.form.markAsTouched();
      this.form.markAsTouched();
      this.invalid = false;
    }
    if (Object.keys(this.errTemp).length > 0 && this.formData && Object.keys(this.formData.form.controls).length > 0) {
      this.formData.form.markAsDirty();
      this.form.markAsTouched();
      this.markFieldsAsInvalid(this.errTemp);
      this.errTemp = {};
    }
  }

  deactivate(language: Language) {
    return (language.id == '13bdf866-eb10-4794-a19e-dadc87a4365b' || language.id == '3fcfd0e6-cd71-4a6e-a5bd-54c25588792e');
  }

  clear(table: Table) {
    table.clear();
  }

  async validateAndSave() {
    if (this.language.name && this.language.selector.length > 0)
      await this.save();
    else {
      this.invalid = true;
      this.collapse[0] = false;
    }
  }

  async save() {
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    try {
      if (this.mode === 'create') {
        await this.server.post('language', this.language);

      } else {
        await this.server.put('language', this.language);
      }
      this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successSaved
          .replace('${componentName}', this.localization.dictionary.language.nameSingular)
          .replace('${entryName}', this.language.name)));
      this.saving = false;
      this.server.back();
    } catch (err) {
      this.loading = false;
      this.saving = false;
      this.markFieldsAsInvalid(err.error?.fields);
      throw new HttpErrorResponse(err);
    }
    this.localization.languageModified.next(this.language);
  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      this.formData.form.markAsDirty();
      if (this.form) this.form.markAsTouched();
      for (let field of Object.keys(errors)) {
        if (this.formData && this.formData.form.controls[field]) {
          this.formData.form.controls[field].markAsDirty();
          this.formData.form.controls[field].setErrors(errors[field]);
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  }
}
