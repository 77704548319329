<h2>{{localization.dictionary.general.settings}}</h2>

<div class="card" *ngIf="!loading">
    <ng-container *ngFor="let cat of categories">
        <div class="card-header">
        {{ localization.dictionary.generalSettings[cat.toLowerCase() + 'SettingsTitle'] }}
        </div>
        <div class="card-block">
            <div class="card-text">
                <form clrForm>
                    <ng-container *ngFor="let setting of settings">

                        <ng-container *ngIf="setting.category == cat">
                          <clr-input-container *ngIf="setting.type === 'TIME'">
                            <label>{{localization.dictionary.generalSettings[setting.selector]}}</label>
                            <input type="time" [(ngModel)]="setting.value" clrInput step="3000000" [ngModelOptions]="{standalone: true}">
                          </clr-input-container>

                          <clr-date-container *ngIf="setting.type === 'DATE'">
                            <label>{{localization.dictionary.generalSettings[setting.selector]}}</label>
                            <input type="date" [(ngModel)]="setting.value" clrDate [ngModelOptions]="{standalone: true}">
                          </clr-date-container>

                            <clr-input-container *ngIf="setting.type === 'STRING' || setting.type === 'NUMBER'">
                                <label>{{localization.dictionary.generalSettings[setting.selector]}}</label>
                                <input
                                    [type]="setting.type === 'STRING' ? 'text' : 'number'"
                                    [(ngModel)]="setting.value" clrInput [name]="setting.name" maxlength="100"
                                    [readOnly]="!authService.hasRole(setting.roleNames)">
                            </clr-input-container>

                            <clr-select-container *ngIf="setting.type === 'BOOLEAN'">
                                <label>{{localization.dictionary.generalSettings[setting.selector]}}</label>
                                <select clrSelect [name]="setting.selector" [(ngModel)]="setting.value" [disabled]="!authService.hasRole(setting.roleNames)">
                                    <option [ngValue]="'true'" [selected]="(setting.value === 'true')">{{localization.dictionary.general.yes}}</option>
                                    <option [ngValue]="'false'" [selected]="(setting.value === 'false')">{{localization.dictionary.general.no}}</option>
                                </select>
                            </clr-select-container>

                            <p class="card-text setting-desc">
                            {{localization.dictionary.generalSettings[setting.selector + 'Description']}}
                            </p>
                        </ng-container>
                    </ng-container>
                </form>
            </div>
        </div>
    </ng-container>
    <div stickyFooter class="card-footer">
        <button class="btn btn-primary" (click)="save()">{{localization.dictionary.button.save}}</button>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>
