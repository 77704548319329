<ol class="list">
<h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Vergeben Sie einen aussagekräftigen Namen für das neue Formular. </li>
    <li>Ergänzen Sie eine Beschreibung, wofür das Formular verwendet wird, z.B. zur Dokumentation von Kundenterminen.</li>
    <li>Als Typ können Sie „Frei“ oder „Bestandsdaten“ auswählen. Beim Typ „Bestandsdaten“ muss das CRM-Modul freigeschaltet sein, da dieser Typ auf Kundendaten zugreift. Bei Typ frei erfolgt keine weitere Zuordnung.</li>
    <li>Die Felder "Veröffentlicht" und "Aktiv" zeigen den aktuellen Status und können hier nicht bearbeitet werden.</li>
<h4 style="position: relative; right: 1.1em;">Fragen hinzufügen</h4>
    <li>Um die gewünschten Felder dem Formular zuzuordnen klicken Sie auf das Plus-Zeichen.</li>
    <li>Es öffnet sich eine Auswahlliste von möglichen Feldtypen. Alle Felder außer „Abschnitt“ können als Pflichtfelder definiert werden. Zur Aktivierung klicken Sie auf den Schalter.</li>
    <li>Bei der Erstellung eines neuen Feldes ist grundsätzlich der Name des Feldtyps in der Bezeichnung eingetragen, z.B. „Auswahlliste“. Dieser muss dann mit Ihrem gewünschten Text überschrieben werden.</li>
    <li>Zum Abspeichern der einzelnen Felder klicken Sie auf den Button „Bestätigen“.</li>
<h4 style="position: relative; right: 1.1em;">Aktionen</h4>
    <li>Nach Auswahl aller benötigten Felder klicken Sie auf den Button „Erstellen“.</li>
    <li>Um das fertige Formular für den App-Nutzer sichtbar zu machen, muss das Formular nach der Erstellung veröffentlicht werden. Hierzu klicken Sie in der Eingabemaske auf den Button „Veröffentlichen“. Anschließend muss das Formular noch einer Kategorie zugeordnet werden (siehe Kategorien > Zuordnung von Dokumenten).</li>
</ol>

<p><span style="font-weight: bold; font-size: larger; color: var(--danger);">Bitte beachten: </span>Ein veröffentlichtes Formular kann nicht mehr verändert werden!</p>

<ol class="list">
    <h4 style="position: relative; right: 1.1em;">Formular nach veröffentlichung anpassen</h4>
        <li>Um Änderungen vorzunehmen können Sie eine Kopie des Formulars erstellen, in dem Sie in der Eingabemaske auf den Button „Als Vorlage neu“ klicken.</li>
        <li>In der Kopie nehmen Sie dann die entsprechenden Änderungen vor und speichern und veröffentlichen das neue Formular.</li>
        <li>Anschließend ordnen Sie das neue Formular der Kategorie zu.</li>
        <li>Das alte Formular setzen Sie inaktiv, indem Sie in der Übersicht der Formulare bei dem entsprechenden Dokument auf den durchgestrichenen Kreis klicken und es deaktivieren.</li>
</ol>
<h3>Übersicht Feldtypen</h3>
<p style="margin-top: 0.6rem"><span style="font-weight: bold; color: var(--warning);">Hinweis: </span>Nach Speicherung des Formulars können einzelne Felder durch Anklicken des Stift-Symbols noch geändert bzw. ergänzt werden.</p>
<h4><clr-icon shape="add-text"></clr-icon> Referenzfeld</h4>
<p>Das Referenzfeld nimmt Bezug auf die Daten von Kunden oder Ansprechpartnern und ist nur in Verbindung mit dem CRM-Modul aktiv. Das Feld wird auch nur dann in der Auswahl angezeigt, wenn Sie als Typ in den Basis Informationen „Bestandsdaten“ ausgewählt haben. Das Referenzfeld erfordert in der App keine Eingaben, sondern dient nur zur Anzeige der Bestandsdaten. Sie können es z.B. für Serviceprotokolle nutzen, um die Stammdaten des Kunden auf dem Formular anzeigen zu lassen.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Referenzfeld“ mit Ihrem Text.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Wählen Sie eine der beiden Tabellen aus (Kunde oder Ansprechpartner).</li>
    <li>Wählen Sie anschließend das entsprechende Feld aus, das angezeigt werden soll.</li>
    <li>Klicken Sie auf den Button „Bestätigen“ zum Abspeichern der Daten.</li>
</ol>

<h4><clr-icon shape="add-text"></clr-icon> Abschnitt</h4>
<p>Dieser Feldtyp ermöglicht die Unterteilung des Formulars in mehrere Bereiche, um z.B. das Formular in verschiedene Themenbereiche einzuteilen. Die Abschnitte unterteilen das Formular in der App in mehrere Eingabeseiten. Ohne die Unterteilung kann der App-Nutzer sich in der Ansicht des Formulars nach unten durchscrollen.</p>
<ol class="list">
    <li>Ändern Sie die Überschrift des Abschnittes.</li>
    <li>Ergänzen Sie optional eine Beschreibung zum Abschnitt.</li>
    <li>Klicken Sie auf den Button „Bestätigen“ zum Abspeichern der Daten.</li>
</ol>

<h4><clr-icon shape="unknown-status"></clr-icon> Frage</h4>
<p>Über diesen Typ können Sie z.B. eine Umfrage starten. Es kann hier jedoch im Formular in der App später nur eine Antwort ausgewählt werden.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Frage“ mit Ihrer Frage.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Klicken Sie auf das Textfeld „Neue Antwort eingeben“ und tragen Sie hier eine Antwortmöglichkeit ein. Um weitere Antwortmöglichkeiten vorzugeben, klicken Sie auf den Button „+ Neue Antwort eingeben“ und tragen diese ein. Dies wiederholen Sie, bis Sie alle Antworten eingegeben haben.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“ zum Abspeichern der Daten.</li>
</ol>

<h4><clr-icon shape="check-circle"></clr-icon> Checkbox</h4>
<p>Bei diesem Typ können mehrere Antwortmöglichkeiten vorgegeben und auch später in der App ausgewählt werden, z.B. um eine Checkliste zur Überprüfung der Schritte bei einer Installation zu erstellen.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Checkbox“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Klicken Sie auf das Textfeld „Neue Antwort eingeben“ und tragen Sie hier eine Auswahlmöglichkeit ein. Um weitere Auswahlmöglichkeiten vorzugeben, klicken Sie auf den Button „+ Neue Antwort eingeben“ und tragen diese ein. Dies wiederholen Sie, bis Sie alle Auswahlmöglichkeiten eingegeben haben.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“.</li>
</ol>

<h4><clr-icon shape="checkbox-list"></clr-icon> Mehrfachauswahl</h4>
<p>Bei diesem Typ können mehrere Antwortmöglichkeiten vorgegeben und auch später in der App aus einer Auswahlliste ausgewählt werden.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Mehrfachauswahl“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Klicken Sie auf das Textfeld „Neue Antwort eingeben“ und tragen Sie hier eine Auswahlmöglichkeit ein. Um weitere Antwortmöglichkeiten vorzugeben, klicken Sie auf den Button „+ Neue Antwort eingeben“ und tragen diese ein. Dies wiederholen Sie, bis Sie alle Auswahlmöglichkeiten eingegeben haben.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“.</li>
</ol>

<h4><clr-icon shape="view-list"></clr-icon> Auswahlliste</h4>
<p>Bei diesem Typen können Sie mehre Antworten vorgeben, die Ihnen in einer Auswahlliste angezeigt werden. Es kann hier jedoch im Formular in der App später nur eine Antwort ausgewählt werden.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Auswahlliste“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Klicken Sie auf das Textfeld „Neue Antwort eingeben“ und tragen Sie hier eine Auswahlmöglichkeit ein. Um weitere Auswahlmöglichkeiten vorzugeben, klicken Sie auf den Button „+ Neue Antwort eingeben“ und tragen diese ein. Dies wiederholen Sie, bis Sie alle Auswahlmöglichkeiten eingegeben haben.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="note"></clr-icon> Texteingabe</h4>
<p>Bei diesem Feldtyp handelt es sich um ein Feld zur Freitexteingabe, bei denen die Korrektheit der eingegebenen Werte anhand von Parametern, z.B. Format E-Mail-Adresse oder Postleitzahl, überprüft werden können.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Texteingabe“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Soll hier z.B. im Formular eine Postleitzahl eingegeben werden, wählen Sie in der Auswahlliste Überprüfung „Postleitzahl“ aus. Soll keine Überprüfung der eingegebenen Daten stattfinden, wählen Sie hier „Keine“ aus.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="details"></clr-icon> Texteingabe lang</h4>
<p>Hier handelt es sich ebenfalls um ein Feld zur Freitexteingabe, dessen Format aber nicht überprüft wird. Dieses Feld kann z.B. für Anmerkungen durch den App-Nutzer genutzt werden.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Texteingabe (lang)“ mit Ihrem Text. </li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="number-list"></clr-icon> Zahl</h4>
<p>Dieses Feld kann für die Eingabe von nummerischen Werten genutzt werden, z.B. bei der Abfrage einer benötigten Anzahl vom Kunden.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Zahl“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="check"></clr-icon> Ja/Nein</h4>
<p>Dieser Typ kann für eine einfache Abfrage genutzt werden, z.B. ob Interesse an weiteren Unterlagen in der Akquise Phase besteht oder ob ein Servicevertrag abgeschlossen wurde.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Ja/Nein“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="slider"></clr-icon> Bereich</h4>
<p>Mit diesem Typen kann man eine einfache Skalierung auf einer Achse mittels Slider vornehmen. Hiermit kann u.a. eine Bewertung vorgenommen werden, z.B. zur Kundenzufriedenheit.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Bereich“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Wählen Sie die Art der Anzeige aus. „Slider“ zeigt einen Zahlenstrahl an. „Smilies“ zeigt ergänzend zum Zahlenstrahl 3 Smilies an.</li>
    <li>Passen Sie ggfs. die Werte für die untere und obere Grenze der Skala sowie die Schrittgröße an.</li>
    <li>Um die Achsenbeschriftung anzupassen, aktivieren Sie den Regler durch Anklicken. Nun können Sie die Beschriftung für die untere, mittlere und obere Grenze anpassen.</li>
    <li>In der Vorschau können Sie sich die aktuelle Konfiguration ansehen.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="highlighter"></clr-icon> Unterschrift</h4>
<p>Bei diesem Feldtyp können Sie ein Unterschriftsfeld ergänzen, z.B. zur Bestätigung des Kunden nach durchgeführtem Service. Die Unterschrift erfolgt auf dem Tablet des App-Nutzers.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Unterschrift“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="event"></clr-icon> Datum</h4>
<p>Das Datumsfeld ermöglicht die Auswahl eines Datums mit Hilfe eines Kalenders.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Datum“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Aktivieren Sie ggfs. über den Regler die Funktion „Pflichtfeld“.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<h4><clr-icon shape="text"></clr-icon> Beschreibung</h4>
<p>Das Datumsfeld ermöglicht die Auswahl eines Datums mit Hilfe eines Kalenders.</p>
<ol class="list">
    <li>Überschreiben Sie den Namen „Beschreibung“ mit Ihrem Text.</li>
    <li>Ergänzen Sie optional unter „Beschreibung“ relevante Informationen zu diesem Feld.</li>
    <li>Anschließend klicken Sie auf den Button „Bestätigen“</li>
</ol>

<!-- <h4>Zum Speichern der erstellten Kategorie klicken Sie auf<button class="btn btn-primary btn-help">Erstellen</button></h4> -->
