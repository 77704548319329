<div *ngIf="!dashboardsLoading; else dashboardLoading" class="dashboard-wrapper">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">    
        <ng-container *ngIf="!edit; else editMode">
            <h2>
                {{ dashboard.name }}
            </h2>
            <!-- <clr-icon (click)="edit = !edit" shape="pencil"></clr-icon> -->  
        </ng-container>    
         
        <ng-template #editMode>
            <input *ngIf="edit" clrInput [(ngModel)]="dashboard.name">   
            <clr-icon (click)="edit = !edit" shape="check"></clr-icon> 
        </ng-template>

        <!-- <clr-icon shape="switch"></clr-icon> -->
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">        
        <select clrSelect name="dashboards" [(ngModel)]="dashboard" (ngModelChange)="setDashboard($event)">
            <option *ngFor="let dashb of dashboards" [ngValue]="dashb">{{ dashb.name }}</option>
        </select>
        <!-- <clr-combobox-container>
            <clr-combobox 
                [ngModel]="dashboardSelection" 
                (ngModelChange)="setDashboard($event)" 
                (clrInputChange)="loadDashboards($event)" 
                (clrOpenChange)="loadDashboards()" 
                [clrLoading]="dashboardsLoading" 
                name="dashboards" 
                placeholder="Dashboard auswählen">
              <clr-options>
                <clr-option *clrOptionItems="let dashb of dashboards; field:'name'" [clrValue]="dashb">
                    {{ dashb.name }}
                </clr-option>
                
              </clr-options>
            </clr-combobox>
        </clr-combobox-container> -->
        <!-- <clr-dropdown>
            <clr-icon shape="plus-circle" *clrDropdownTrigger></clr-icon>
            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                <div *ngFor="let widget of widgets" clrDropdownItem>
                    {{ widget.name }}
                </div>
            </clr-dropdown-menu>
        </clr-dropdown>   -->   
    </div>    
   
    <app-filter-from-to (update)="refresh($event.from, $event.to)" [disabled]="edit"></app-filter-from-to>
    
    <gridster [options]="options" style="max-width: 90%; background-color: transparent;">
        
        <gridster-item [item]="widget" *ngFor="let widget of dashboard.widgets" style="background-color: transparent;">
            <!-- Count Widgets -->
            <app-widget-count 
                *ngIf="widget.name == 'count-pres'"
                [text]="(fromDate || toDate) ? localization.dictionary.dashboard.documentsCreatedInPeriod : localization.dictionary.dashboard.activeDocument "
                [value]="kpis[1]"
                #widgetComp>
            </app-widget-count>
    
            <app-widget-count 
                *ngIf="widget.name == 'count-user'"
                [text]="(fromDate || toDate) ? localization.dictionary.dashboard.usersActiveInThePeriod : localization.dictionary.dashboard.activeUser"
                [value]="kpis[0]"
                #widgetComp>
            </app-widget-count>
    
            <app-widget-count 
                *ngIf="widget.name == 'count-user-activity'"
                [text]="(fromDate || toDate) ? 'Ø' + localization.dictionary.dashboard.actionsPerUserInThePeriod : 'Ø'+ localization.dictionary.dashboard.actionPerUser"
                [value]="kpis[3]"
                #widgetComp>
            </app-widget-count>
    
            <app-widget-count 
                *ngIf="widget.name == 'count-form'"
                [text]="(fromDate || toDate) ? localization.dictionary.dashboard.formsObtainedInThePeriod : localization.dictionary.dashboard.formsObtained"
                [value]="kpis[2]"
                #widgetComp>
            </app-widget-count>
    
            <!-- Andere Widgets -->
            <app-widget-user-interaction             
                *ngIf="widget.name == 'user-interaction'" 
                #widgetComp>
            </app-widget-user-interaction>
            <app-widget-mail             
                *ngIf="widget.name == 'mail'" 
                #widgetComp>
            </app-widget-mail>
            <app-widget-presentation             
                *ngIf="widget.name == 'presentation'" 
                #widgetComp>
            </app-widget-presentation>
            <app-widget-login             
                *ngIf="widget.name == 'login'"  
                #widgetComp>
            </app-widget-login>
            <app-widget-crm-customer-by-territory             
                *ngIf="widget.name == 'crm-customer-by-territory'"  
                #widgetComp>
            </app-widget-crm-customer-by-territory>
            <app-widget-crm-call-by-product
                *ngIf="widget.name == 'crm-call-by-product'"
                #widgetComp
            ></app-widget-crm-call-by-product>
            <app-widget-crm-call-by-territory
                *ngIf="widget.name == 'crm-call-by-territory'"
                #widgetComp
            ></app-widget-crm-call-by-territory>
            <app-widget-crm-call-by-user
                *ngIf="widget.name == 'crm-call-by-user'"
                #widgetComp
            ></app-widget-crm-call-by-user>
        </gridster-item>
    </gridster>
</div>

<ng-template #dashboardLoading>
    <app-spinner></app-spinner>
</ng-template>

<app-diagram-popup></app-diagram-popup>