import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Question, QuestionInputType } from '../question.model';
import { OfferedAnswer } from '../offered-answer.model'
import { mapIcons } from '../map-icons';
import { Field } from 'src/app/crm/fields/field.model';
import { BrandingService } from 'src/app/branding/branding.service';
import { LocalizationService } from 'src/app/localization/localization.service';

@Component({
  selector: 'app-widget-question',
  templateUrl: './widget-question.component.html',
  styleUrls: ['./widget-question.component.css']
})
export class WidgetQuestionComponent implements OnInit, OnChanges {

  @Input() question: Question;
  @Input() index: number;
  @Input() active: boolean;
  @Input() sectionIndex: number;
  @Input() sectionTotal: number;
  @Input() published: boolean;
  @Input() formType: 'open' | 'optin ' | 'customer';
 
  @Output() deactivate: EventEmitter<boolean> = new EventEmitter();
  @Output() changed: EventEmitter<{id: string, valid: boolean}> = new EventEmitter();
  @Output() deleteQuestion: EventEmitter<boolean> = new EventEmitter();

  valid = true;
  fieldReference = false;
  field: Field;

  mapIcons = mapIcons;
  inputTypes = QuestionInputType;
  
  constructor(public branding: BrandingService,
    public localization: LocalizationService) { }

  ngOnInit(): void {
    this.fieldReference = (this.question?.refField) ? true : false;
    if (!this.question.new) {
      this._isValid();
    }
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "active" && !this.active) {
          this._isValid();
        }
      }
    }
  }
  
  deactivated() {
    this._isValid()
    this.active = false;
    this.deactivate.emit(false);
  }

  toggleDisabled() {
    this.question.disabled = !this.question.disabled;
    this.deactivated();
    this.onChange();
  }

  onChange(event?) {    
    this._isValid();
    this.changed.emit({id: this.question.id, valid: this.valid});
  }

  setRefField(event: {table: 'customer' | 'contact', field: Field}) {
    this.question.refField = event.field;
    this.question.refFieldId = event.field?.id;
    this.question.refTable = event.table;
    this.question.name = event.field.label;
    this.question.type = event.field.type;
    for(let i = 0; i < event.field.fieldOptions?.length; i++ ){
      this.question.offeredAnswers[i] = new OfferedAnswer;
      this.question.offeredAnswers[i].value = event.field.fieldOptions[i].label;
      this.question.offeredAnswers[i].order = event.field.fieldOptions[i].order;
      this.question.offeredAnswers[i].fieldOption = event.field.fieldOptions[i];
      this.question.offeredAnswers[i].fieldOptionId = event.field.fieldOptions[i].id;
    }
    this.question.reference = true;
  }

  _isValid() {
    this.valid = true;
    // check name
    if (!['DESCRIPTION', 'SECTION'].includes(this.question.type) && !this.question.name) {
      this.valid = false;
    }
    // check offered answers
    if (['CHOICE', 'MULTIPLE_CHOICE', 'CHECKBOX', 'SELECTION'].includes(this.question.type) && this.question.offeredAnswers.filter(off => !off.disabled && off.value && off.value.length > 0).length === 0) {
      this.valid = false;
    }
    // check description
    if (['DESCRIPTION'].includes(this.question.type) && !this.question.description) {
      this.valid = false;
    }
  }

  // brandingColor(){
  //   if(this.branding.darkMode){
  //     return {'backgroundcolor'}
  //   }
  // }
  
}
