import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { ServerService } from 'src/app/server.service';
import { Category } from '../category.model';
import { Document } from '../../documents/document.model';
import { LocalizationService } from 'src/app/localization/localization.service';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.css']
})
export class ListCategoriesComponent implements OnInit, DatagridFunctions {
  categories: Category[];
  toDelete: Category[];
  selected: Category[] = [];
  showDeleted = false;
  selectedCategory: Category;
  orphans: Document[];
  saving = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  loading = true;
  total: number;
  order: boolean;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    groups: true,
    createdAt: true,
    updatedAt: true
  };
  state: ClrDatagridStateInterface

  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(private server: ServerService, public auth: AuthService,
    public localization: LocalizationService) {
  }

  ngOnInit(): void {
    const filter = localStorage.getItem('cat-filters');
    const sorting = localStorage.getItem('cat-sorting');
    const hidden = localStorage.getItem('cat-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
    this.showDeleted = !!localStorage.getItem('cat-deleted');

  }

  ngOnDestroy() {
    localStorage.setItem('cat-filters', JSON.stringify(this.filters));
    localStorage.setItem('cat-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('cat-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('cat-deleted', 'true')
    else
      localStorage.removeItem('cat-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    if (state.filters) {
      this.order = false;
    }
    this.state = state
    this.loading = true;
    const query = this.server.buildQueryFromGrid(state)
    const res = await this.server.get('category/' + (this.showDeleted ? 'all' : 'withGroups') + query);
    this.total = res.count;
    this.categories = plainToClass(Category, res.rows);
    this.loading = false;
  }

  async loadCategories() {
    const res = await this.server.get('category/' + (this.showDeleted ? 'all' : 'withGroups'));
    this.total = res.count;
    this.categories = plainToClass(Category, res.rows);
  }

  selectionChange(categories: Category[]) {
    if (categories.length > 0) {
      this.order = false;
    }
  }

  async selectDelete(cats: Category[]) {
    const res = await this.server.get('category/orphansOf?parents=' + cats.map(cat => cat.id));
    if (res.count > 0)
      this.orphans = plainToClass(Document, res.rows)
    else
      this.orphans = undefined
    this.toDelete = cats;
  }

  async toggleDeleted() {
    this.order = false;
    this.selected = []
    await this.refresh();
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
      this.refresh();
    } else {
      this.server.getCategories();
    }
    this.order = !this.order;
  }

  openRestoreModal(cat: Category) {
    this.selectedCategory = cat;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('category/restore', this.selectedCategory);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.category.nameSingular).replace("${entryName}", this.selectedCategory.name)));
    this.refresh()
    this.server.getCategories();
    this.restoreModal = false;
  }

  async drop(event: CdkDragDrop<Category[]>) {
    this.loading = true;
    this.saving = true;
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.categories.length; i++) {
      this.categories[i].order = i;
      await this.server.put('category', this.categories[i]);
    }
    this.saving = false;
    this.loading = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true
    const catIds = this.toDelete.map((cat) => cat.id);
    await this.server.delete('category?toDelete=' + catIds);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}", this.toDelete.length == 1 ? this.localization.dictionary.category.nameSingular : this.localization.dictionary.category.name)));
    this.saving = false;
    this.toDelete = undefined;
    this.selected = [];
    this.refresh();
    await this.server.getCategories();
    this.loading = false;
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters
    this.refresh(this.state)
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

}
