<h2>{{ localization.dictionary.category.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid class="bound" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" cdkDropList
    [cdkDropListDisabled]="!order" (cdkDropListDropped)="drop($event)" [(clrDgSelected)]="selected"
    (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.category.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'order'" [(clrDgSortOrder)]="sorting['order']"
        (clrDgSortedChange)="selected = []; order = false">
        {{localization.dictionary.categoryDocumentList.order}}
        <clr-dg-filter [clrDgFilter]="orderFilter" [(clrDgFilterOpen)]="orderFilter.open">
            <number-filter #orderFilter [property]="'order'" [(filter)]="filters['order']" [offset]="-1">
            </number-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']"
        (clrDgSortedChange)="selected = []; order = false">
        {{localization.dictionary.general.name}}
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'description'"
        [(clrDgSortOrder)]="sorting['description']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['description']">
            {{localization.dictionary.general.description}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="descriptionFilter" [(clrDgFilterOpen)]="descriptionFilter.open">
            <string-filter #descriptionFilter [property]="'description'" [(filter)]="filters['description']">
            </string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'isActive'"
        [(clrDgSortOrder)]="sorting['isActive']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['isActive']">
            {{localization.dictionary.general.active}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="isActiveFilter" [(clrDgFilterOpen)]="isActiveFilter.open">
            <boolean-filter #isActiveFilter [property]="'isActive'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"
                [(filter)]="filters['isActive']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'inMainMenu'"
        [(clrDgSortOrder)]="sorting['inMainMenu']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['inMainMenu']">
            {{localization.dictionary.category.inMainMenu}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="inMainMenuFilter" [(clrDgFilterOpen)]="inMainMenuFilter.open">
            <boolean-filter #inMainMenuFilter [property]="'inMainMenu'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"
                [(filter)]="filters['inMainMenu']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'groups.name'"
        [(clrDgSortOrder)]="sorting['groups']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['groups']">
            {{localization.dictionary.group.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="groupsFilter" [(clrDgFilterOpen)]="groupsFilter.open">
            <multiselect-filter #groupsFilter [name]="localization.dictionary.group.name" [property]="'groups'" [query]="'group'"
                [(filter)]="filters['groups']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="selected = []; order = false">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column> {{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let cat of categories" cdkDrag [class.deleted]="cat.deletedAt" [clrDgItem]="cat"
        [clrDgSelectable]="columnChange || !cat.deletedAt" cdkDragBoundary=".bound">
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            <clr-icon *ngIf="order" shape="cursor-move" class="cursor-grab" cdkDragHandle></clr-icon>
            {{ cat.deletedAt? '' : cat.order + 1 }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ cat.id }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            <clr-icon [attr.shape]="cat.icon"></clr-icon>
            {{ cat.name }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ cat.description }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ (cat.isActive) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ (cat.inMainMenu) ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell class="labels">
            <ng-container *ngFor="let group of cat.groups">
                <span [pTooltip]="group.description"
                            tooltipPosition="top"
                            [routerLink]="cat.deletedAt ? null : ['/groups/edit/' + group.id]"
                            class="label label-light-blue">
                        {{ group.name }}
                </span>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ cat.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ cat.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="cat.deletedAt? null : ['/category/edit/' + cat.id]">
            {{ cat.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="!cat.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.category.nameSingular).replace('${entryName}', cat.name)"
                        tooltipPosition="left"
                        shape="pencil"
                        [routerLink]="['/category/edit/' + cat.id]">
            </clr-icon>
            
            <clr-icon [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.category.nameSingular).replace('${entryName}', cat.name)"
                        tooltipPosition="left"
                        shape="trash"
                        (click)="selectDelete([cat])">
            </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="cat.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.category.nameSingular).replace('${entryName}', cat.name)"
                tooltipPosition="left"
                shape="backup-restore"
                (click)="openRestoreModal(cat)">
            </clr-icon>
        </clr-dg-cell>
        
        <div *cdkDragPlaceholder class="grid-drag-placeholder">

        </div>
        <div *cdkDragPreview class="grid-drag-preview" fxLayoutAlign="start center" fxLayoutGap="1rem">
            <clr-icon shape="cursor-move"></clr-icon>
            <clr-icon [attr.shape]="cat.icon"></clr-icon>
            <span>{{ cat.name }}</span>
        </div>
    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.category.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.category.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.category.nameSingular : localization.dictionary.category.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title"> {{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.categoryList.deleteModalDescription}}</p>
        <ng-container *ngIf="orphans">
            <p>{{localization.dictionary.categoryList.followingDocuments}}</p>
            <ul>
                <li *ngFor="let orphan of orphans">{{orphan.name}}: {{orphan.description}}</li>
            </ul>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}} </button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">
        {{ localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.category.nameSingular).replace('${entryName}', selectedCategory?.name) }}
    </h3>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{localization.dictionary.button.restore}}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/category/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.category.nameSingular) }}</button>
            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)"
                (click)="resetFilters()">{{localization.dictionary.button.resetFilters}}</button>
            <button class="btn btn-outline"
                *ngIf="!showDeleted && categories?.length > 1 && selected.length === 0 && !state.filters"
                (click)="toggleOrder()">{{ (!order) ? localization.dictionary.button.arrange : localization.dictionary.button.closeArrange}}</button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="selectDelete(selected)">{{localization.dictionary.button.delete}}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['category-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                    (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.category.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>