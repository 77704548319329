import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
export class Widget {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    description: string;

    @Expose()
    active: boolean;

    // start der client spezifischen user infos
    @Expose()
    @Transform( (val, obj) => obj.DashboardWidget?.cols)
    cols: number;

    @Expose()
    @Transform( (val, obj) => obj.DashboardWidget?.rows)
    rows: number;

    @Expose()
    @Transform( (val, obj) => obj.DashboardWidget?.x)
    x: number;

    @Expose()
    @Transform( (val, obj) => obj.DashboardWidget?.y)
    y: number;   

    // Ende der client spezifischen user infos
}