import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Group } from "src/app/groups/group.model";
import { User } from "src/app/users/user.model";

export enum PushUpdateType {
    NONE = 'NONE',
    APP = 'APP',
    CATEGORY = 'CATEGORY'
}

@Exclude()
export class PushNotification {
    @Expose()
    id: string;

    @Expose()
    title: string;

    @Expose()
    msg: string;

    @Expose()
    updateType: PushUpdateType = PushUpdateType.NONE;

    @Expose()
    categoryId: string;

    @Expose()
    @Type( () => Date)
    sentAt: Date;

    @Expose()
    @Transform( (value) =>  (value) ? value : [] )
    @Type( () => Group)
    groups: Group[] = [];

    @Expose()
    @Transform( (value) =>  (value) ? value : [] )
    @Type( () => User)
    users: User[] = [];

    @Expose()
    @Type( () => Date)
    createdAt: Date;

    @Expose()
    @Type( () => Date)
    updatedAt: Date;

    @Expose()
    @Type( () => Date)
    deletedAt: Date;

    @Expose()
    get userCount() {
        let outArr = this.users.map( (user) => user.id );
        for (let grp of this.groups) {
            let userIds = (grp.users) ? grp.users.map( (user) => user.id) : [];
            outArr.push(...userIds)
        }
        return [...new Set(outArr)].length;
    }
}