import { HttpErrorResponse } from '@angular/common/http';
import { Component, DoCheck, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { HelperService } from 'src/app/helper/helper.service';
import { Role } from 'src/app/roles/role.model';
import { ServerService } from 'src/app/server.service';
import { User } from '../user.model';
import { v4 } from 'uuid';
import { Territory } from 'src/app/crm/territories/territory.model';
import { SettingService } from 'src/app/settings/setting.service';
import { patternList } from '../../crm/fields/pattern-list';
import { LocalizationService } from '../../localization/localization.service';

@Component({
  selector: 'app-create-edit-category',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.css']
})
export class CreateEditUserComponent extends PreventUnload implements OnInit, DoCheck {
  user = new User();
  userRole: Role;
  mode: 'edit' | 'create' = 'create';
  loading = true;
  emailPattern = patternList.email;
  namePattern = patternList.name;
  firstTab = true;
  invalid = false;
  errTemp = {};
  v4 = v4;
  territories: Territory[];
  collapse: boolean[] = [
    false,
    false,
    false,
    false
  ]


  @ViewChild(ClrForm) form: ClrForm;
  private formData: NgForm;

  @ViewChild('formData', { static: false}) set content(content: NgForm) {
    if(content) { // initially setter gets called with undefined      
      this.formData = content;      
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }
 
  constructor(public server: ServerService,
              public setting: SettingService,  
              private route: ActivatedRoute, 
              public helper: HelperService,
              public auth: AuthService,
              public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params)=> {
        this.mode = (!params.id) ? 'create' : 'edit';
        if (this.mode === 'edit') {
          const id = this.route.snapshot.params.id;
          const user = await this.server.get('user/byId/' + id);
          this.user = plainToClass(User, user);
        } else {
          const role = await this.server.get('user/defaultRole');
          this.userRole = plainToClass(Role, role);
          this.user.roles.push(this.userRole);
          this.dirty = true;
        }
        this.changeUnload();
        this.loading = false;
        const res = await this.server.get('crm/territory/forUser');
        this.territories = plainToClass(Territory, res.rows);
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );
  }

  async ngOnInit(): Promise<void> {
    
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('user');
    } else {
      this.changeUnloadEdit('user', this.user.name);
    }
  }

  ngDoCheck() {
    if (this.form && this.formData && this.invalid) {
      this.formData.form.markAsDirty();
      this.formData.form.markAsTouched();  
      this.form.markAsTouched();
      this.invalid = false;
    }
    if (Object.keys(this.errTemp).length > 0 && this.formData && Object.keys(this.formData.form.controls).length > 0) {
      this.formData.form.markAsDirty(); 
      this.form.markAsTouched();
      this.markFieldsAsInvalid(this.errTemp);
      
      this.errTemp = {};     
    } 
  }

  loadTestUser() {
    this.user.name = 'admin',
    this.user.email = 'test@test.de';
    this.user.formOfAddress = 'Herr',
    this.user.firstName = 't';
    this.user.lastName = 't';
    this.user.password = 't';
    this.form.markAsTouched();
  }
/* 
  test() {
  } */

  filterSelect(arr1, arr2?) {
    if (!arr2 || !arr1)
      return arr1
    return arr1.filter(element => !arr2.map(value => value.id).includes(element.id))
  }

  async validateAndSave() {
    if (this.user.name && this.user.firstName && this.user.lastName && this.user.email && this.user.formOfAddress &&
      ((this.mode === 'create' && this.user.password) || this.mode === 'edit'))
      await this.save();
    else {
      this.firstTab = true;   
      this.invalid = true;
      this.collapse[0] = false;
    }
  }

  async save() {
    if (this.formData.invalid) {
      return;
    }
    this.dirty = false;
    this.loading = true;   
    this.saving = true;
    try {
      if (this.mode === 'create') {
        await this.server.post('user', this.user);
      } else {
        await this.server.put('user', this.user);
      }   
      this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successSaved
      .replace('${componentName}', this.localization.dictionary.user.nameSingular)
      .replace('${entryName}', this.user.name)));
      this.saving = false;
      this.server.back();
    } catch(err) {
        this.loading = false;
        this.saving = false;
        this.firstTab = true;
        this.markFieldsAsInvalid(err.error?.fields);
        throw new HttpErrorResponse(err);
      }   
  }

  async impersonate() {
    const resp = await this.server.get('user/byId/' + this.user.id);
    const user = plainToClass(User, resp);
    this.auth.startImpersonation(user);
  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      this.formData.form.markAsDirty(); 
      if (this.form) this.form.markAsTouched();
      for (let field of Object.keys(errors)) {  
        if (this.formData && this.formData.form.controls[field]) {
          this.formData.form.controls[field].markAsDirty();
          this.formData.form.controls[field].setErrors(errors[field]);          
        } else {
          this.errTemp = { [field]: errors[field]}
        }                   
      }     
    }    
  }

}
