import { Inject, Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Category } from './categories/category.model';
import { plainToClass } from 'class-transformer';
import { ClrDatagridStateInterface } from '@clr/angular';
import * as sha1 from 'js-sha1';
import { Setting } from './settings/setting.model';
import * as fileSaver from 'file-saver';
import { Document } from './documents/document.model';
import { AmselError } from './helper/error/amsel-error.model';
import { ToastrService } from 'ngx-toastr';
import { FileUpload } from './crm/file-upload/file-upload.model';

export type ServerResponse<Type = any> = {
  count: number,
  rows: Type[]
};

export type ResponseWrapper<Type, Wrap> = Wrap extends true ? ServerResponse<Type> : NoInfer<Type>;

export type NoInfer<Type> = [Type][Type extends any ? 0 : never];

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  headers: HttpHeaders = new HttpHeaders();
  categories: Category[] = [];
  uri: string = '';
  errors: AmselError[] = []

  constructor(private http: HttpClient, 
              private _location: Location,
              @Inject(Injector) private readonly injector: Injector
              ) {
  }

  /**
   * GET rest call to the backend
   * @param path Path of the rest call
   * @template Type Expected return type of the rest call
   * @template Wrap Boolean for whether to wrap the type in a ServerResponse or not
   * @return {ServerResponse<Type> | Type} The response of the rest call
   */
  async get<Type = any, Wrap extends boolean = true>(path: string): Promise<ResponseWrapper<Type, Wrap>> {
    return this.http.get<ResponseWrapper<Type, Wrap>>(this.uri + path, { headers: this.headers, withCredentials: true }).toPromise();
  }

  /**
   * Unwrapped GET rest call to the backend
   * @param path Path of the rest call
   * @template Type Expected return type of the rest call
   * @return {Type} The response of the rest call
   */
  async getUnwrapped<Type = any>(path: string): Promise<NoInfer<Type>> {
    return this.get<Type, false>(path);
  }

  async post<Type = any>(path: string, body: any): Promise<Type> {
    return await this.http.post<Type>(this.uri + path, body, { headers: this.headers, withCredentials: true }).toPromise();
  }

  async put<Type = any>(path: string, body: any): Promise<Type> {
    return this.http.put<Type>(this.uri + path, body, { headers: this.headers, withCredentials: true }).toPromise();
  }

  async delete<Type = any>(path: string): Promise<Type> {
    return this.http.delete<Type>(this.uri + path, { headers: this.headers, withCredentials: true }).toPromise();
  }

  async download(path: string): Promise<Blob> {
    try {
      const download = await this.http.get(this.uri + path, { headers: this.headers, withCredentials: true, responseType: 'blob' }).toPromise();
      return download;
    } catch (err) {
      return null;
    }
  }

  async downloadUFile(uFile: FileUpload) {
    const blob = await this.download('crm/file-upload/download/' + uFile.id);
    fileSaver.saveAs(blob, uFile.filename + '.' + uFile.fileExtension);
  }

  async documentDownload(document: Document, deleted = false) {
    const blob = await this.download(`document/get${deleted? 'Deleted': ''}File/` + document.id);
    // this.server.compareHashes(blob, '112cdff7e87f71fbc352eac3997adbeda9bd64cc');
    fileSaver.saveAs(blob, document.name + document.filename.slice(-4));
  }

  async excelDownload(blob, filename) {
    fileSaver.saveAs(blob, filename + '.xlsx');
  }


  // für Client interessant
  async compareHashes(blob: Blob, hash: string): Promise<boolean> {
    const bufferHash = sha1.create();
    const arrayBuffer = await blob.arrayBuffer();
    bufferHash.update(arrayBuffer);
    return (bufferHash.hex() === hash);
  }

  async getCategories() {
    const res = await this.get<Category>('category/byOrder');
    this.categories = plainToClass(Category, res.rows);
  }

  queryBuilder(filters) {
    const querystring = '?' + new URLSearchParams(filters).toString();
    return querystring
  }

  buildQueryFromGrid(state: ClrDatagridStateInterface) {
    let filters: { [prop: string]: string } = {};
    if (state.filters) {
      for (let filter of state.filters) {
        let { property, value } = <{ property: string, value: any }>filter;
        if (value)
          filters[property] = value instanceof Object ? JSON.stringify(value) : value;
        if (filter.value2) {
          let { value2 } = <{ value2: any }>filter;
          filters.filters = filters.filters || JSON.stringify([])
          const filterArray = [...JSON.parse(filters.filters)]
          filterArray.push(value2)
          filters.filters = JSON.stringify(filterArray)
        }
      }
    }
    if (state.sort) {
      filters['sortBy'] = state.sort.by as string;
      filters['sortDir'] = (state.sort.reverse) ? 'DESC' : 'ASC';
    }
    if (state.page) {
      filters['skip'] = ((state.page.current - 1) * state.page.size).toString();
      filters['limit'] = state.page.size.toString();
    }
    return '?' + new URLSearchParams(filters).toString();
  }

  addAlert(alert: AmselError) {
    if (alert.type === 'success') {
      this.toastr.success(alert.msg);
    } else if (alert.type === 'info') {
      this.toastr.info(alert.msg);
    } else {
      this.errors.unshift(alert);
    }
  }

  dismissAllAlerts() {
    this.errors = [];
  }
/* 
  async loadSettings() {
    const res = await this.get<Setting>('setting');
    this.settings = plainToClass(Setting, res.rows);
  } */

  back() {
    this._location.back();
  }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  getConflictCountObservable(): EventSource {
    return new EventSource(this.uri + 'crm/conflict/sse');
  }

}
