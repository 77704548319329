
<ol class="list">
<h4 style="position: relative; right: 1.1em;">Stammdaten</h4>
    <li>Die Pflichtfelder sind mit einem * gekennzeichnet.</li>
    <li>Geben Sie die entsprechenden Stammdaten ein, z.B. Firma, Adresse und Kontaktdaten des Unternehmens.</li>
<h4 style="position: relative; right: 1.1em;">Gebiet zuordnen</h4>
    <li>Unter dem nächsten Punkt ordnen Sie ein Gebiet zu, z.B. Nordrhein-Westfalen (siehe CRM > Gebiete).</li>
<h4 style="position: relative; right: 1.1em;">Produkt zuordnen</h4>
    <li>Bei bereits bestehenden Kunden können Sie anschließend Produkte zuordnen. Dies hilft Ihnen später Auswertungen zu machen, wenn Sie Kunden mit bestimmten Produkten identifizieren wollen, z.B. um sie über Produktneuheiten zu informieren.</li>
    <br>
    <li>Über den Button „Erstellen“ speichern Sie den Datensatz ab. Sie haben alternativ auch die Möglichkeit über den Button „Speichern & Call erstellen“ den Datensatz abzuspeichern und direkt in die Eingabemaske „Einsätze“ zu wechseln, um hier einen Termin zu erstellen.</li>
    <br>

</ol>
<h4>Ansprechpartner</h4>
    <p>Über den Reiter Ansprechpartner können Sie dem Kunden einen bereits angelegten Ansprechpartner zuordnen.</p>
<ol class="list">  
<br>
    <li>Scrollen Sie runter zum Punkt „Ansprechpartner zuordnen“.</li>
    <li>Anschließend wählen Sie den bereits angelegten Ansprechpartner aus, indem Sie z.B. bei der Spalte Nachnamen die Filterfunktion nutzen. Klicken Sie hierzu auf das Trichter-Symbol und geben Sie Ihren Suchbegriff ein. Anschließend klicken Sie auf das + in der letzten Spalte. Es öffnet sich ein Dialogfeld, in dem Sie die Position des Ansprechpartners im Unternehmen eingeben können.</li>
    <li>Zum Speichern klicken Sie auf den Button „Hinzufügen“.</li>

</ol>