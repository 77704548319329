<!-- <h4>Neues Produkt erstellen</h4> -->
<ol class="list">
 <h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet.</li>  
    <li>Tragen Sie den (Produkt-)Namen ein und ergänzen Sie eine Beschreibung.</li>
    <li>Unter Vorschau können Sie dem Produkt ein Bild zuordnen.</li>
<h4 style="position: relative; right: 1.1em;">Dokumente zuordnen</h4>  
    <li>Im nächsten Abschnitt ordnen Sie dem Produkt die relevanten Unterlagen zu, z.B. Datenblatt, Broschüre, etc.</li>
    <li>Wenn Sie das Produkt einem Kunden zuordnen wollen, wechseln Sie zum Reiter „Kunden“. Anschließend wählen Sie den Kunden aus, indem Sie z.B. bei der Spalte Name die Filterfunktion nutzen. Klicken Sie hierzu auf das Trichter-Symbol und geben Sie Ihren Suchbegriff ein. Anschließend klicken Sie auf das + in der letzten Spalte.</li>
    <li>Zum Speichern klicken Sie auf den Button „Erstellen“.</li>

</ol>