import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-actions-upload',
  templateUrl: './de-actions-upload.component.html'
})
export class DeActionsUploadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
