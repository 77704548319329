import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerService } from 'src/app/server.service';
import { LocalizationService } from '../../localization/localization.service';
import { AmselError } from './amsel-error.model';


@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
  public errors: AmselError[] = [];

  constructor(private server: ServerService, private localization: LocalizationService) { }

  async handleError(error) {
    // RuntimeError
    if (error instanceof Observable) {
      await error.toPromise();
    }
    if (error.rejection && error.rejection instanceof ErrorEvent) {
      // client-side error
      //amselError.type = 'danger';
      //amselError.msg = error.error.message;
      console.error(error);
      //this.server.errors.push(amselError);
    } else if (error instanceof ErrorEvent) {
      console.error(error);
    } else if (error.rejection && error.rejection instanceof HttpErrorResponse) {
      // server-side error
      this.processHttpError(error.rejection.error);
    } else if (error.error && error.error.statusCode) {
      // server-side error
      this.processHttpError(error.error);
    } else if (error instanceof HttpErrorResponse) {
      this.processHttpError(error.error);
    } else if (error.toString().includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      // We ignore this error
    } else {
      console.error(error);
    }
  }

  processHttpError(err) {
    let error = new AmselError();
    if (err) {
      if (err.statusCode === 0 || err.status === 0) {
        error.type = 'warning';
        error.msg = this.localization.dictionary.toastr.tooManyRequests;
      } else if (err.statusCode === 400) {
        error.type = 'warning';
        if (err.message = true && this.server.errors.length > 1) {
          error.msg = err.message.join(',');
        } else {
          this.server.errors.length = 1;
          error.msg = this.localization.dictionary.toastr.badRequest;
        }
      } else if (err.statusCode === 401) {
        error.type = 'warning';
        error.msg = this.localization.dictionary.toastr.userUnauthorised;
      } else if (err.statusCode === 403) {
        error.type = 'warning';
        error.msg = 'Unauthorisiert' //this.localization.dictionary.toastr.unauthorised;
      } else if (err.statusCode === 404) {
        error.type = 'warning';
        error.msg = this.localization.dictionary.toastr.pageNotFound;
      } else if (err.statusCode === 901 || 420) {
        error.type = 'warning';
        error.msg = err.msg;
      } else {
        error.type = 'danger';
        error.msg = this.localization.dictionary.toastr.unknownError
      }
    } else {
      error.type = 'danger';
      error.msg = this.localization.dictionary.toastr.unknownError
    }
    this.server.errors.unshift(error);
  }

  addError(error: AmselError) {
    this.server.errors.unshift(error);
  }

  closeError(id: string) {
    this.errors = this.errors.filter((err) => err.id !== id);
  }

}
