import { Component, DoCheck, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { PreventUnload } from '../../../helper/guards/unsaved.guard';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { Field } from '../../fields/field.model';
import { Section } from '../section.model';
import { View } from '../view.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SectionField } from '../section-field.model';
import { AmselIcon, LocalizationService } from 'src/app/localization/localization.service';
import { CrmService } from '../../crm.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-create-edit-views',
  templateUrl: './create-edit-views.component.html',
  styleUrls: ['./create-edit-views.component.css']
})
export class CreateEditViewsComponent extends PreventUnload implements OnInit, DoCheck {

  @ViewChildren(ClrForm) form: ClrForm[];
  private formData: NgForm[];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content) { // initially setter gets called with undefined
      this.formData = content.toArray();
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  /* @ViewChildren(ClrForm) clrSectionForm: ClrForm;
  private sectionFormData: NgForm[];

  @ViewChildren('sectionFormData') set sectionContent(sectionContent: NgForm[]) {
    if (sectionContent) { // initially setter gets called with undefined
      this.sectionFormData = sectionContent;
    }
  }  */

  typeChoice: "customer" | "call" | "contact";
  fieldDirty = false;
  view: View = new View();
  fullFields: Field[];
  filteredFields: Field[] = [];
  sections: Section[] = []
  mode: 'edit' | 'create';
  loading = true;
  editNumber: number;
  typeModal = false;
  blurred = false;
  errTemp = {};
  invalid = false;
  missingDV = false;

  constructor(public server: ServerService,
              private route: ActivatedRoute,
              public router: Router,
              public helper: HelperService,
              private crm: CrmService,
              public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.mode = (params.id) ? 'edit' : 'create';
        if (this.mode === 'edit') {
          this.view = plainToClass(View, await this.server.get('crm/view/byId/' + params.id));
          this.sections = this.view.sections;
          this.sections.sort((n1,n2) => {
            if (n1.order > n2.order){
              return 1;
            }
            if (n1.order < n2.order){
              return -1;
            }
            return 0;
          });  
          for(let section of this.sections){
            section.currentIcon = this.localization.amselIconList.find( (icon) => icon.element === section.icon);
            section.fields.sort((n1,n2) => {
              if (n1.SectionField.order > n2.SectionField.order){
                return 1;
              }
              if (n1.SectionField.order < n2.SectionField.order){
                return -1;
              }
              return 0;
            });  
          }
          const res = await this.server.get('crm/view/fields/' + this.view.id + '/' + this.view.type);
          let res2 = plainToClass(Field, res) as unknown as Field[];
          this.filteredFields = res2;
          for (let field of this.filteredFields) {
            if (!field.SectionField) {
              field.SectionField = new SectionField();
            }
    
          }
        } else {
          this.view = new View();
          this.view.type = 'customer'
          this.newSection();
          this.dirty = true;
        }
        this.changeUnload();
        this.loading = false;
      })
    );
  }

  async ngOnInit(): Promise<void> {
    if (this.mode === 'create') {
      const res = await this.server.get('crm/field');
      this.fullFields = plainToClass(Field, res.rows, { enableImplicitConversion: true });
      for (let field of this.fullFields) {
        if (!field.SectionField) {
          field.SectionField = new SectionField();
        }

      }
      this.filterFields(this.view.type);
    }

    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
        for (let section of this.sections) {
          section.currentIcon = this.localization.amselIconList.find(icon => icon.element == section.currentIcon.element);
        }
      })
    );
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('view');
    } else {
      this.changeUnloadEdit('view', this.view.name);
    }
  }

  ngDoCheck() {
    if (this.form && this.formData && this.invalid) {
      for (let data of this.formData) {
        data.form.markAsDirty();
        data.form.markAsTouched();
      }
      for (let f of this.form) {
        f.markAsTouched();
      }
      this.invalid = false;
    }
    /* if (Object.keys(this.errTemp).length > 0 && this.formData && Object.keys(this.formData.form.controls).length > 0) {
      this.formData.form.markAsDirty();
      this.form.markAsTouched();
      this.markFieldsAsInvalid(this.errTemp);

      this.errTemp = {};
    } */
  }

  async validateAndSave() {
    let validSections = true;
    for(let section of this.sections){
      if(section.fields.length < 1){
        section.invalid = true;
        validSections = false;
      }
      if(section.name === "" || section.name === undefined){
        section.invalidName = true;
        validSections = false;
      }
      if(section.description === "" || section.description === undefined){
        validSections = false;
      }
      if(section.currentIcon === undefined || section.icon === undefined){
        validSections = false;
      }
    }
    for(let field of this.filteredFields){
      if(field.display){
        this.invalid = true;
        this.missingDV = true;
        this.server.addAlert(new AmselError(undefined, 'warning', this.localization.dictionary.viewCreateEdit.warningDisplayVaue));
      }
    };
    if (this.view.name && this.view.description && this.view.type && this.sections && validSections && !this.invalid)
      await this.save();
    else {
      this.invalid = true;
    }
  }

  async save() {
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    this.view.sections = this.sections;
    for (let i = 0; i < this.view.sections.length; i++) {
      if (this.view.sections[i].currentIcon) {
        this.view.sections[i].icon = this.view.sections[i].currentIcon.element;
      }
      for(let field of this.view.sections[i].fields){
        if(field.display){
          field.SectionField.mandatory = true;
          field.SectionField.readOnly = false;
        }
      }
      this.view.sections[i].order = i;
      for (let k = 0; k < this.view.sections[i].fields.length; k++) {
        /* if (!this.view.sections[i].fields[k].SectionField) {
          this.view.sections[i].fields[k].SectionField = new SectionField();
        } */
        this.view.sections[i].fields[k].SectionField.order = k;
      }
    }
    this.crm.setDisplayFields();
    if (this.mode === 'create') {
      await this.server.post('crm/view', this.view);
    } else {
      await this.server.put('crm/view', this.view);
    }
    this.server.addAlert(new AmselError(undefined, 'success',
    this.localization.dictionary.toastr.successSaved
    .replace('${componentName}', this.localization.dictionary.view.nameSingular)
    .replace('${entryName}', this.view.name)));
    this.saving = false;
    await this.crm.setViews();
    await this.crm.setActiveViewsFromStorage();
    this.router.navigate(['/crm/view/list']);
  }

  /* markFieldsAsInvalid(errors: Object) {
    if (errors) {
      this.formData.form.markAsDirty();
      if (this.form) this.form.markAsTouched();
      for (let field of Object.keys(errors)) {
        if (this.formData && this.formData.form.controls[field]) {
          this.formData.form.controls[field].markAsDirty();
          this.formData.form.controls[field].setErrors(errors[field]);
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  } */

  changeType(value) {
  }

  filterFields(viewType: "customer" | "call" | "contact") {
    this.view.type = viewType;
    this.filteredFields = [];
    for (let field of this.fullFields) {
      if (this.view.type === 'customer') {
        if (field.appliesToCustomer) {
          this.filteredFields.push(field);
        }
      } else if (this.view.type === 'contact') {
        if (field.appliesToContact) {
          this.filteredFields.push(field);
        }
      } else if (this.view.type === 'call') {
        if (field.appliesToCall) {
          this.filteredFields.push(field);
        }
      } else {
      }
    }
  }

  checkChange(event) {
    if (this.fieldDirty) {
      this.typeChoice = this.view.type;
      this.view.type = event;
      this.typeModal = true;
    } else {
      this.filterFields(event);
    }
  }

  newType() {
    this.sections = [];
    this.newSection();
    this.fieldDirty = false;
    this.filterFields(this.view.type);
    this.typeModal = false;
  }


  drop(event: CdkDragDrop<string[]>, section?: Section) {
    if(section){
      section.invalid = false;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.dirty = true;
      this.fieldDirty = true;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.filteredFields.sort((n1,n2) => {
      if (n1.name > n2.name){
        return 1;
      }
      if (n1.name < n2.name){
        return -1;
      }
      return 0;
    });
    for(section of this.sections){
      if(section.fields.length === 0){
        section.invalid = true;
      }
    }

  }

  newSection(index?) {
    let newSection = new Section();
    newSection.name = this.localization.dictionary.viewCreateEdit.newSection;
    newSection.fields = [];
    if (index != undefined) {
      this.fieldDirty = true;
      this.sections.splice(index + 1, 0, newSection);
    } else {
      this.sections.push(newSection);
    }
    this.editNumber = this.sections.indexOf(newSection);
  }

  deleteSection(index) {
    if(this.editNumber === index){
      this.editNumber = undefined;
    }
    this.filteredFields.push(...this.sections[index].fields);
    this.sections.splice(index, 1);
  }

  moveSection(dir, index) {
    if (this.editNumber === index + dir) {
      this.editNumber = index;
    }
    if (this.editNumber === index) {
      this.editNumber = index + dir;
    }
    let tempSection = this.sections[index + dir];
    this.sections[index + dir] = this.sections[index];
    this.sections[index] = tempSection;
  }

  editMode(clickedItem) {
    if (clickedItem === this.editNumber) {
      this.editNumber = undefined;
    } else {
      this.editNumber = clickedItem;
    }
  }

  removeField(section: Section, field: Field) {
    field.SectionField.mandatory = false;
    field.SectionField.readOnly = false;
    let pos = section.fields.indexOf(field);
    this.filteredFields.push(field);
    section.fields.splice(pos, 1);
    if(section.fields.length === 0){
      section.invalid = true;
    }
  }

  async update(section: Section) {
    this.loading = true;
    this.fieldDirty = true;
    this.editNumber = undefined;
    this.loading = false;
  }

  checkValid(section: Section, ev: string, combo: ClrCombobox<AmselIcon>) {
    if (!ev ) {
      section.icon = undefined;
      combo.triggerValidation();
    } else {
      section.icon = this.localization.amselIconList.find( (icon) => icon.label == ev)?.label;
    }
  }

}
