import { Component,  HostListener, OnDestroy, OnInit,  ViewChild} from '@angular/core';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { SettingService } from 'src/app/settings/setting.service';
import { User } from '../user.model';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit, OnDestroy, DatagridFunctions {
  users: User[];
  selected: User[] = [];
  toDelete: User[];
  loading = true;
  saving = false;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    email: true,
    formOfAddress: true,
    lastLogin: true,
    lastSync: true,
    createdAt: true,
    updatedAt: true,
    serviceAccount: true,
    territories: false,
  }
  state: ClrDatagridStateInterface

  showDeleted = false;
  selectedUser: User;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(public server: ServerService, public auth: AuthService, public helper: HelperService, public setting: SettingService,
     public localization: LocalizationService) {
  }

  ngOnInit(): void {
    const filter = localStorage.getItem('user-filters');
    const sorting = localStorage.getItem('user-sorting');
    const hidden = localStorage.getItem('user-columns');
    if (filter) {      
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
  }

  ngOnDestroy() {
    localStorage.setItem('user-filters', JSON.stringify(this.filters));
    localStorage.setItem('user-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('user-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('user-deleted', 'true')
    else
      localStorage.removeItem('user-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const user = await this.server.get('user/' + (this.showDeleted ? 'all/' : '') + (!this.setting.featureActiveList['crmActive'] || this.hidden.territories && !this.showDeleted ? 'withoutTerritories' : '') + query);
    this.total = user.count;
    this.users = plainToClass(User, user.rows);
    this.loading = false;
  }

  async loadUsers() {
    this.showDeleted = !!localStorage.getItem('user-deleted');
    const res = await this.server.get('user/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.users = plainToClass(User, res.rows);
  }

  async activeToggleUser(user: User) {
    user.isActive = !user.isActive;
    await this.server.post('user/deactivate', user);
  }
  selectionChange(users: User[]) {
    if (users.length > 0) {
      this.order = false;
    }
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('user/')
    }
    this.order = !this.order;
  }

  async openRestoreModal(user: User) {
    this.selectedUser = user;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('user/restore', this.selectedUser);
    this.server.addAlert(new AmselError(undefined, 'success',  this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.user.name).replace("${entryName}", this.selectedUser.name)));
    this.refresh();
    this.refresh();
    this.restoreModal = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const userIds = this.toDelete.map((user) => user.id);
    await this.server.delete('user?toDelete=' + userIds);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}", 
    this.localization.dictionary.user['name' + (this.toDelete.length == 1 ? 'Singular' : '')])));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }
  
  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  editable(user: User, deleted = true) {
    //nicht admin darf keine serviceAccounts bearbeiten
    if(user.serviceAccount && !this.auth.hasRole(['admin'])){
      return false; 
    }

    return (deleted ? !user.deletedAt : true) && user.name != 'admin' && (!user.fullRoles.find(role => role.name == 'admin') || this.auth.hasRole(['admin']))
  }

  deletable(user: User) {
    return user.id != this.auth.user.id && user.id != this.auth.impersonater?.id
  }

  hidChange() {
    if(this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(()=>{
      this.columnChange = false
    })
  }
}
