import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { BrandingService } from 'src/app/branding/branding.service';
import { Form } from 'src/app/forms/form.model';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { HelperService } from 'src/app/helper/helper.service';
import { ServerService } from 'src/app/server.service';
import { FieldOption } from '../field-option.model';
import { Field } from '../field.model';
import { View } from '../../views/view.model';
import { patternList } from '../pattern-list';
import { LocalizationService } from 'src/app/localization/localization.service';
import { CrmService } from '../../crm.service';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { SectionField } from '../../views/section-field.model';

@Component({
  selector: 'app-create-edit-field',
  templateUrl: './create-edit-field.component.html',
  styleUrls: ['./create-edit-field.component.css']
})
export class CreateEditFieldComponent extends PreventUnload implements OnInit, DoCheck {
  @ViewChild(ClrForm) form: ClrForm;
  @ViewChild(UntypedFormGroup) formGroup: UntypedFormGroup;
  @ViewChild(FormData) formData: NgForm;
  field: Field;
  fieldSnapshot: Field;
  forms: Form[] = [];
  views: View[] = [];
  // formsLinked: Form[] = [];
  recreateForm: boolean[] = [];
  mode: 'edit' | 'create';
  loading = true;
  catLoading = false;
  categories$ = new Subject();
  // fieldOptions: FieldOption[] = [];
  publishedForms: Form[] = [];
  unpublishedForms: Form[] = [];
  invalid = false;
  editCategories = false;
  editName = false;
  duplicate = false;
  editOptions = false;
  sureToEdit = false;
  createNewForm = false;
  fieldType: string;
  fieldViews: View[] = [];
  fieldForm: Form[] = [];
  toggleDV = true;
  fieldOptionSnapshot;
  validRegEx: boolean;
  tempRegEx: string = 'none';
  collapse: boolean[] = [
    false
  ]
  fOChanged = false;
  viewList: View[];
  openViewModal = false;
  selectViews = false;
  selectedView: View;

  patterns = {
    NONE: '',
    NAME: patternList.name,
    MAIL: patternList.email,
    POSTALCODE: patternList.postal_code,
    PHONENUMBER: patternList.phonenumber,
    CUSTOM: ''
  }

  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public router: Router,
    public crm: CrmService,
    public helper: HelperService,
    public branding: BrandingService,
    public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.loading = true;
        this.mode = (params.id) ? 'edit' : 'create';
        if (this.mode === 'edit') {
          this.field = plainToClass(Field, await this.server.get('crm/field/byId/' + params.id));
          this.fieldSnapshot = JSON.parse(JSON.stringify(this.field));
          this.fieldOptionSnapshot = JSON.parse(JSON.stringify(this.field.fieldOptions));
          this.fieldType = this.field.type;
          this.field.fieldOptions.sort((a, b) => a.order - b.order);
          if (this.field.pattern) {
            for (let patternKey in this.patterns) {
              if (this.patterns[patternKey] == this.field.pattern) {
                this.tempRegEx = patternKey;
              }
            }
            if (this.tempRegEx == 'none') {
              this.tempRegEx = 'custom';
            }
          }
          if (this.field.questions) {
            // this.sureToEdit = true;
          }
        } else {
          this.field = new Field();
          this.dirty = true;
        }
        this.changeUnload();
        const res = await this.server.get('crm/view/byFieldId/' + params.id);
        this.fieldViews = plainToClass(View, res.rows);
        const res2 = await this.server.get('form/byFormFieldId/' + params.id);
        this.fieldForm = plainToClass(Form, res2.rows);
        this.loading = false;
        /* for (let view of this.fieldViews) {
          for (let section of view.sections) {
            for (let field of section.fields) {
              if (!field.SectionField.mandatory || field.SectionField.readOnly) {
                this.toggleDV = false;
              }
            }
          }
        } */
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );
  }

  async ngOnInit(): Promise<void> {

  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('field');
    } else {
      this.changeUnloadEdit('field', this.field.name);
    }
  }

  ngDoCheck() {
    if (this.invalid && this.form)
      this.form.markAsTouched()
  }

  async save() {
    if (this.mode === 'create') {
      this.viewSelection();
    } else {
      this.dirty = false;
      this.loading = true;
      this.saving = true;
      await this.server.put('crm/field', this.field);
      this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successSaved
        .replace('${componentName}', this.localization.dictionary.field.nameSingular)
        .replace('${entryName}', this.field.label)));
    }
    if (this.mode == 'edit') {
      this.server.back();
      this.saving = false;
      this.crm.init();
    }
  }

  createAnswers(i: number) {
    this.field.fieldOptions[i] = new FieldOption();
    this.field.fieldOptions[i].label = 'Antwort ' + (i + 1);
    this.field.fieldOptions[i].order = i ;
  }

  async calcName() {
    if (!this.editName && this.mode !== 'edit') {
      this.field.name = 'u_' + this.field.label.toLowerCase().trim().replace(/(\s|[^[a-z0-9])+/g, '_');
      const validRes = await this.server.get('crm/field/byName/' + this.field.name);
      if (validRes !== null) {
        this.duplicate = true;
      } else {
        this.duplicate = false;
      }
    } else {
      this.duplicate = false;
    }
    this.field.label = this.field.label.replace(/ +/g, ' ');

  }

  setPattern() {
    if (this.tempRegEx != 'custom') {
      this.field.pattern = this.patterns[this.tempRegEx];
    } else {
      this.field.pattern = '';
    }
  }

  resetPattern() {
    this.tempRegEx = 'none';
    this.field.pattern = '';
    if (this.field.type != 'CHOICE' && this.field.type != 'MULTIPLE_CHOICE' && this.field.type != 'SELECTION' && this.field.type != 'CHECKBOX') {
      this.field.fieldOptions = [];
    }
    if(this.field.type == 'BOOLEAN' || this.field.type == 'DATE' || this.field.type == 'TIME' ) {
      this.field.display = false;
    }
  }

  fieldOptionChanged(event, i: string, fieldOptions: FieldOption[]) {
    fieldOptions[i].touched = true;
    this.fOChanged = true;
  }

  /**
   * Überprüft, ob bestimmte Bedingungen ein Löschen des Feldes verhindern
   * @returns boolean,
   */
  canBeDeleted() {
    if (this.field.name == 'u_date' || this.field.name == 'u_email') {
      return false;
    }
    return true;
  }

  delete(i: number) {
    this.field.fieldOptions.splice(i, 1);
    if (i < this.field.fieldOptions.length) {
      for (let j = 0; j < this.field.fieldOptions.length; j ++) {
        this.field.fieldOptions[j].order = j;
      }
    }
  }

  async drop(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.field.fieldOptions, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.field.fieldOptions.length; i++) {
      this.field.fieldOptions[i].order = i;
    }
  }

  answerValid() {
    if ((this.field.type === 'CHOICE' || this.field.type === 'MULTIPLE_CHOICE' || this.field.type === 'SELECTION' || this.field.type === 'CHECKBOX') && this.field.fieldOptions.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  async formValid(formData: NgForm) {
    if (this.mode == 'edit' && this.fieldForm.length > 0 && this.field.questions && this.field.questions.length > 0 && (this.fOChanged || JSON.stringify(this.field.fieldOptions) != JSON.stringify(this.fieldOptionSnapshot))) {
      for (let form of this.fieldForm) {
        if (form.published) {
          this.publishedForms.push(form);
        } else {
          this.unpublishedForms.push(form);
        }
      }
      this.sureToEdit = true;
      return;
    }
    if (this.checkViewChanges()) {
      if (this.mode === 'create') {
        // check duplicating field-label
        const validRes = await this.server.get('crm/field/byName/' + this.field.name);
        if (validRes !== null) {
          this.duplicate = true;
          return;
        }
      }
      if (formData.valid && this.answerValid()) {
        this.save();
      } else if (!formData.valid) {
        this.collapse[0] = false;
        this.form.markAsTouched();
        // this.formGroup.setErrors({'required': true});
      } else if (!this.answerValid()) {
        this.server.addAlert(new AmselError(undefined, 'warning', this.localization.dictionary.field.fieldCreateEdit));
      }
    }
  }

  async viewSelection() {
    this.views = [];
    let fieldTypes: string[] = [];
    if (this.field.appliesToCall) {
      fieldTypes.push('call');
    }
    if (this.field.appliesToContact) {
      fieldTypes.push('contact');
    }
    if (this.field.appliesToCustomer) {
      fieldTypes.push('customer');
    }
    for (let type of fieldTypes) {
      const res = await this.server.get('crm/view/type/' + type);
      this.views.push(...plainToClass(View, res.rows));
    }
    this.selectViews = true;
  }

  async addField(editView: boolean){
    const resField = await this.server.post('crm/field', this.field);
    this.field = plainToClass(Field, resField);
    if (resField) {
      this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successSaved
        .replace('${componentName}', this.localization.dictionary.field.nameSingular)
        .replace('${entryName}', this.field.label)));
    }
    this.field.SectionField = new SectionField();
    this.field.SectionField.sectionId = this.selectedView.sections[this.selectedView.sections.length -1].id;
    this.field.SectionField.fieldId = this.field.id;
    this.field.SectionField.order = this.selectedView.sections[this.selectedView.sections.length -1].fields.length;
    this.selectedView.sections[this.selectedView.sections.length -1].fields.push(this.field);
    const resView = await this.server.put('crm/view/', this.selectedView);
    if (resView) {
      this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.fieldCreateEdit.successfullAttached));
    }
    this.saving = true;
    this.crm.init();
    this.dirty = false;
    this.saving = false;
    this.saving = false;
    this.dirty = false;
    if (editView) {
      this.router.navigateByUrl('crm/view/edit/' + this.selectedView.id);
    } else {
      this.server.back();
    }
  }

  unsureToEdit() {
    this.sureToEdit = false;
    this.fOChanged = false;
    this.publishedForms = [];
    this.unpublishedForms = [];
    this.saving = false;

  }

  closeViewModal() {
    delete this.viewList;
    this.openViewModal = false;
    this.field.appliesToCall = this.fieldSnapshot.appliesToCall;
    this.field.appliesToContact = this.fieldSnapshot.appliesToContact;
    this.field.appliesToCustomer = this.fieldSnapshot.appliesToCustomer;
  }

  checkViewChanges() {
    this.viewList = [];
    if (this.mode == 'edit') {
      if (this.fieldSnapshot.appliesToCall && !this.field.appliesToCall) {
        this.viewList.push(...this.fieldViews.filter(view => view.type == 'call'));
      }
      if (this.fieldSnapshot.appliesToContact && !this.field.appliesToContact) {
        this.viewList.push(...this.fieldViews.filter(view => view.type == 'contact'));
      }
      if (this.fieldSnapshot.appliesToCustomer && !this.field.appliesToCustomer) {
        this.viewList.push(...this.fieldViews.filter(view => view.type == 'customer'));
      }
    }
    if (this.viewList.length == 0) {
      return true
    } else {
      this.openViewModal = true;
      return false;
    }
  }

  async saveChanges() {
    const res = await this.server.put('crm/field', this.field);
    this.server.addAlert(new AmselError(undefined, 'success',  this.localization.dictionary.toastr.successSaved.replace("${componentName}", this.localization.dictionary.field.nameSingular).replace('${entryName}', this.field.label)));
    const resField = plainToClass(Field, res);
    let forms = this.publishedForms.concat(this.unpublishedForms);
    try {
      await this.server.put('form/copyForm/' + resField.id, forms);
      this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successEdited.replace("${componentName}", this.publishedForms.length < 2 ? this.localization.dictionary.form.nameSingular : this.localization.dictionary.form.name)));
    } catch (e) {
      console.error(e);
      this.server.addAlert(new AmselError(undefined, 'danger', this.localization.dictionary.formCreateEdit.danger));
    }
    this.unsureToEdit();
    this.crm.setFields();
    this.server.back();
    this.dirty = false;
  }
}
