<h2>{{localization.dictionary.general.preview}}</h2>
<clr-tabs>
    <clr-tab>
        <button #main (focus)="main.blur()" clrTabLink>{{localization.dictionary.branding.homePage}}</button>
        <ng-template [(clrIfActive)]="activePage[0]">
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button #cat (focus)="cat.blur()" clrTabLink>{{localization.dictionary.branding.categoryView}}</button>
        <ng-template [(clrIfActive)]="activePage[1]">
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button #pop (focus)="pop.blur()" clrTabLink>{{localization.dictionary.branding.popUp}}</button>
        <ng-template [(clrIfActive)]="activePage[2]">
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button #log (focus)="log.blur()" clrTabLink>{{localization.dictionary.branding.loginView}}</button>
        <ng-template [(clrIfActive)]="activePage[3]">
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button #form (focus)="form.blur()" clrTabLink>{{localization.dictionary.form.nameSingular}}</button>
        <ng-template [(clrIfActive)]="activePage[4]">
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button #table (focus)="table.blur()" clrTabLink>{{localization.dictionary.general.table }}</button>
        <ng-template [(clrIfActive)]="activePage[5]">
        </ng-template>
    </clr-tab>
</clr-tabs>
<div class="ipad-bg" fxLayoutAlign="center center"
    [ngStyle]="(theme['--clr-p1-color']) ? {'color': theme['--clr-p1-color']}: {}" style="--clr-btn-primary-bg-color: {{theme['--clr-btn-primary-bg-color']}};
        --clr-btn-primary-color: {{theme['--clr-btn-primary-color']}};
        --clr-btn-primary-border-color: {{theme['--clr-btn-primary-border-color']}};
        --clr-btn-default-outline-color: {{theme['--clr-btn-default-outline-color']}};
        --clr-btn-default-outline-border-color: {{theme['--clr-btn-default-outline-border-color']}};
        --clr-btn-primary-hover-bg-color: {{theme['--clr-btn-primary-hover-bg-color']}};
        --clr-btn-default-outline-hover-bg-color: {{theme['--clr-btn-default-outline-hover-bg-color']}};
        --clr-btn-default-outline-hover-color: {{theme['--clr-btn-default-outline-hover-color']}};
        --clr-forms-focused-color: {{theme['--clr-forms-focused-color']}};
        --clr-forms-text-color: {{theme['--clr-forms-text-color']}};
        --clr-forms-border-color: {{theme['--clr-forms-border-color']}};
        --clr-vertical-nav-icon-active-color: {{theme['--clr-vertical-nav-icon-active-color']}};
        --clr-vertical-nav-bg-color: {{theme['--clr-vertical-nav-bg-color']}};
        --clr-vertical-nav-hover-bg-color: {{theme['--clr-vertical-nav-hover-bg-color']}};
        --clr-h3-color: {{theme['--clr-h3-color']}}; --clr-vertical-nav-active-bg-color: {{theme['--clr-vertical-nav-active-bg-color']}};
        --clr-header-font-color: {{theme['--clr-header-font-color']}}; --clr-header-6-bg-color: {{theme['--clr-header-6-bg-color']}};
        --clr-header-hover-font-color: {{theme['--clr-header-hover-font-color']}}; --clr-modal-font-color: {{theme['--clr-modal-font-color']}};
        --clr-login-background-color: {{theme['--clr-login-background-color']}}; 
        ">
    <div class="ipad-content" fxLayout="column">
        <div *ngIf="!activePage[3] && !activePage[4]" class="ipad-header" fxLayout="row"
            fxLayoutAlign="space-between center" [ngStyle]="{'background-color': theme['--clr-header-6-bg-color']}">
            <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-6-bg-color', page: 1})"></div>
            <div class="ipad-header-logo" [style.background-image]="theme['--amsel-header-logo-bg']">
                <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-header-logo-bg', page: 1})"></div>
            </div>
            <div class="ipad-header-mid">
                <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>
                <span [ngStyle]="{'color': theme['--clr-header-font-color']}">
                    {{ time | date: localization.dictionary.dateFormat.headerDate : undefined : localization.language.selector }}
                    <b>{{ time | date: localization.dictionary.dateFormat.headerTime : undefined : localization.language.selector }}</b>
                </span>
            </div>
            <div class="ipad-header-right" fxLayout="row" fxLayoutAlign="space-around center">
                <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <clr-icon shape="search" [ngStyle]="{'color': theme['--clr-header-font-color']}"></clr-icon>
                    <input class="inherit-placeholder" [ngStyle]="{'color': theme['--clr-header-font-color']}"
                        autocomplete="off" id="search-input" type="text" name="input" [placeholder]="localization.dictionary.header.browseDocs"
                        readonly>
                </div>

                <clr-icon shape="wifi" [ngStyle]="{'color': theme['--clr-header-font-color']}"></clr-icon>
                <clr-icon shape="logout" [ngStyle]="{'color': theme['--clr-header-font-color']}"></clr-icon>
            </div>
        </div>
        <div class="ipad-main" fxLayout="row" [style.min-height]="activePage[3] || activePage[4] ? '100%' : null"
            [style.background-image]="theme['--amsel-background-image']" [style.background-color]="theme['--amsel-background-color']">
            <div *ngIf="!activePage[3] && !activePage[4]" class="ipad-nav" fxLayout="column"
                [ngStyle]="{'background-color':  theme['--clr-vertical-nav-bg-color']}" fxFlex="25">
                <div class="ipad-nav-item border-bottom" fxLayout="row" fxLayoutAlign="end"
                    [ngStyle]="{'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                    <clr-icon shape="angle-double" dir="left"
                        [ngStyle]="{'color': theme['--clr-vertical-nav-icon-fill']}"></clr-icon>
                </div>
                <div class="ipad-nav-item border-bottom" fxLayout="row" fxLayoutAlign="start center" [ngStyle]="(activePage[0] || activePage[2])
                                ? {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color'], 'background-color': theme['--clr-vertical-nav-active-bg-color']}
                                : {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: activePage[0]? '--clr-vertical-nav-active-bg-color' : '--clr-vertical-nav-bg-color', page: 2})"></div>
                    <clr-icon class="ipad-nav-item-icon icon-hover" shape="home" [ngStyle]="(activePage[0] || activePage[2]) 
                                ? {'color': theme['--clr-vertical-nav-icon-active-color']}
                                : {'color': theme['--clr-vertical-nav-icon-fill']}"
                                (click)="jumpTo.emit({selector: activePage[0]? '--clr-vertical-nav-icon-active-color' : '--clr-vertical-nav-icon-fill', page: 2})"></clr-icon>
                    <span class="nav-text icon-hover" [ngStyle]="{'color': theme['--clr-h3-color']}"
                        (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})">{{ localization.dictionary.sidenav.home }}</span>
                </div>
                <ng-container
                    *ngIf="serverService.categories && serverService.categories.length > 0 && !serverService.categories.every(someFunction); else noNavCats">
                    <ng-container *ngFor="let cat of serverService.categories; let i = index">
                        <div *ngIf="cat.inMainMenu" class="ipad-nav-item" fxLayout="row" fxLayoutAlign="start center"
                            [ngStyle]="(activePage[1] && cat.id == previewCategory.id)
                                        ? {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color'], 'background-color': theme['--clr-vertical-nav-active-bg-color']}
                                        : {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: activePage[1] && cat.id == previewCategory.id? '--clr-vertical-nav-active-bg-color' : '--clr-vertical-nav-bg-color', page: 2})"></div>
                            <clr-icon class="ipad-nav-item-icon icon-hover" [attr.shape]="cat.icon" [ngStyle]="(activePage[1] && cat.id == previewCategory.id) 
                                        ? {'color': theme['--clr-vertical-nav-icon-active-color']}
                                        : {'color': theme['--clr-vertical-nav-icon-fill']}"
                                        (click)="jumpTo.emit({selector: (activePage[1] && cat.id == previewCategory.id)? '--clr-vertical-nav-icon-active-color' : '--clr-vertical-nav-icon-fill', page: 2})"></clr-icon>
                            <span class="nav-text icon-hover" [ngStyle]="{'color': theme['--clr-h3-color']}"
                                (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})">{{ cat.name }}</span>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="settingService.featureActiveList['crmActive']">
                    <div class="ipad-nav-item border-bottom"
                    [ngStyle]="{'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}"></div>
                    <ng-container *ngFor="let cat of crmCategories; let i = index">
                        <div *ngIf="cat" class="ipad-nav-item" fxLayout="row" fxLayoutAlign="start center" [ngStyle]="activePage[5] && i == 0
                            ? {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color'], 'background-color': theme['--clr-vertical-nav-active-bg-color']}
                            : {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: activePage[5] && i == 0 ? '--clr-vertical-nav-active-bg-color' : '--clr-vertical-nav-bg-color', page: 2})"></div>
                            <clr-icon class="ipad-nav-item-icon icon-hover" [attr.shape]="cat.icon" [ngStyle]="activePage[5] && i == 0
                                ? {'color': theme['--clr-vertical-nav-icon-active-color']}
                                : {'color': theme['--clr-vertical-nav-icon-fill']}"
                                        (click)="jumpTo.emit({selector: activePage[5] && i == 0 ? '--clr-vertical-nav-icon-active-color' : '--clr-vertical-nav-icon-fill', page: 2})"></clr-icon>
                            <span class="nav-text icon-hover" [ngStyle]="{'color': theme['--clr-h3-color']}"
                                (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})">{{ cat.name }}</span>
                        </div>
                    </ng-container>
                </ng-container>

                

                <ng-template #noNavCats>
                    
                    <div class="ipad-nav-item" fxLayout="row" fxLayoutAlign="start center" [ngStyle]="(activePage[1])
                                    ? {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color'], 'background-color': theme['--clr-vertical-nav-active-bg-color']}
                                    : {'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: activePage[1]? '--clr-vertical-nav-active-bg-color' : '--clr-vertical-nav-bg-color', page: 2})"></div>
                        <clr-icon class="ipad-nav-item-icon icon-hover" shape="document" [ngStyle]="(activePage[1]) 
                                    ? {'color': theme['--clr-vertical-nav-icon-active-color']}
                                    : {'color': theme['--clr-vertical-nav-icon-fill']}"
                                    (click)="jumpTo.emit({selector: activePage[1]? '--clr-vertical-nav-icon-active-color' : '--clr-vertical-nav-icon-fill', page: 2})"></clr-icon>
                        <span class="nav-text icon-hover" [ngStyle]="{'color': theme['--clr-h3-color']}"
                            (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})">{{localization.dictionary.category.nameSingular}} 1</span>
                    </div>
                </ng-template>

                <div class="ipad-nav-item border-bottom"
                    [ngStyle]="{'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}"></div>
                <div class="ipad-nav-item border-bottom" fxLayoutAlign="start center"
                    [ngStyle]="{'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-vertical-nav-bg-color', page: 2})"></div>
                    <clr-icon class="ipad-nav-item-icon icon-hover" shape="sync"
                        [ngStyle]="{'color': theme['--clr-vertical-nav-icon-fill']}"
                        (click)="jumpTo.emit({selector: '--clr-vertical-nav-icon-fill', page: 2})"></clr-icon>
                    <span class="nav-text icon-hover" [ngStyle]="{'color': theme['--clr-h3-color']}"
                        (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})">{{localization.dictionary.button.sync}}</span>
                </div>
                <div class="ipad-nav-item border-bottom" fxLayoutAlign="start center"
                    [ngStyle]="{'border-color': theme['--clr-vertical-nav-trigger-divider-border-color']}">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-vertical-nav-bg-color', page: 2})"></div>
                    <clr-icon class="ipad-nav-item-icon icon-hover" shape="help"
                        [ngStyle]="{'color': theme['--clr-vertical-nav-icon-fill']}"
                        (click)="jumpTo.emit({selector: '--clr-vertical-nav-icon-fill', page: 2})"></clr-icon>
                    <span class="nav-text icon-hover" [ngStyle]="{'color': theme['--clr-h3-color']}"
                        (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})">{{localization.dictionary.sidenav.help}}</span>
                </div>
            </div>
            <div *ngIf="activePage[3]" class="ipad-nav" fxLayout="column" style="position: relative;"
                [ngStyle]="{'background-color':  theme['--clr-login-background-color'], 'color':  theme['--clr-p1-color']}"
                fxLayoutAlign="center center" fxFlex="25">
                <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-login-background-color', page: 3})"></div>
                <div class="login-nav">
                    <div style="position: relative;">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-p1-color', page: 3})"></div>
                        <div>{{localization.dictionary.general.welcomeToThe}}</div>
                        <div style="font-size: 1vw; margin-bottom: 1.5vw;">{{ settingService.title }}</div>
                    </div>
                    <div class="fake-input">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-forms-text-color', page: 3})"></div>
                        <span style="opacity: 0.5; z-index: -1;">{{localization.dictionary.user.username}}</span>
                    </div>
                    <div class="fake-input" style="margin-bottom: 1vw;">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-forms-text-color', page: 3})"></div>
                        <span style="opacity: 0.5; z-index: -1;">{{localization.dictionary.user.password}}</span>
                        <clr-icon shape="eye-hide"></clr-icon>
                    </div>
                    <button class="btn btn-primary fake-modal-button" disabled
                        style="width: 100%; color: #666; background-color: #ccc; border: none; pointer-events: none;">{{localization.dictionary.button.login}}</button>
                </div>
            </div>

            <div class="ipad-main-content" [fxFlex]="!activePage[4] ? 75 : 100" fxLayout="column"
                [fxLayoutAlign]="!activePage[4]? 'flex-start center' : 'space-around center'">
                <div *ngIf="!activePage[4]" class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-background-image', page: 0})"></div>
                <div *ngIf="activePage[1]" class="searchbar" fxLayout="row" fxLayoutAlign="space-around center">
                    <div class="letter" *ngFor="let letter of alphabet; let i = index"
                        [ngClass]=" (activeLetters[i]) ? '' : 'disabled'" fxLayoutAlign="center center">
                        {{ letter }}
                    </div>
                    <div class="sorting" fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ localization.dictionary.documentList.sortBy }}</span>
                        <clr-icon shape="circle-arrow" dir="up"></clr-icon>
                    </div>
                </div>
                <div *ngIf="activePage[3]" fxFlexAlign="flex-end" style="position: relative;"
                    [ngStyle]="{'color': theme['--amsel-login-icon-fill']}">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-login-icon-fill', page: 3})"></div>
                    <clr-icon shape="resize"></clr-icon>
                    <clr-icon shape="times" fxFlexOffset="0.5vw"></clr-icon>
                </div>
                <div *ngIf="!activePage[1] && !activePage[4] && !activePage[5]"
                    [style.background-image]="theme['--amsel-logo-background-image']" class="ipad-logo"
                    [style.margin-top]="activePage[3]? '20%' : '5%'" [style.width]="activePage[3]? '80%' : '50%'"
                    [style.height]="activePage[3]? '40%' : '20%'">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-logo-background-image', page: 0})"></div>
                </div>
                <div *ngIf="activePage[0] || activePage[2]" class="ipad-blur-box"
                    [ngStyle]="{'background': theme['--amsel-blur-box-bg-color']}" fxLayout="row"
                    [fxLayoutAlign]="(serverService.categories.length - serverService.categories.filter(someFunction).length) < 5 ? 'space-around flex-start' : 'flex-start flex-start'"
                    fxLayoutGap="0.3rem">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-blur-box-bg-color', page: 0})"></div>
                    <ng-container
                        *ngIf="serverService.categories && serverService.categories.length > 0 && !serverService.categories.every(someFunction); else noMainCats">
                        <ng-container *ngFor="let cat of serverService.categories">
                            <div *ngIf="cat.inMainMenu" class="cat-icon-name" fxLayout="column" fxLayoutAlign="center center">
                                <div class="parent-hover child-enabler"></div>
                                <clr-icon   class="icon-hover"
                                            [attr.shape]="cat.icon"
                                            [ngStyle]="{'color': theme['--clr-icon-fill']}"
                                            (click)="jumpTo.emit({selector: '--clr-icon-fill', page: 0})">
                                </clr-icon>
                                <div class="cat-name"
                                    [ngStyle]="{'color': theme['--amsel-document-item-font-color']}"
                                    (click)="jumpTo.emit({selector: '--amsel-document-item-font-color', page: 0})">{{ cat.name }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-template #noMainCats>
                        <div style="position: relative;" fxLayout="column" fxLayoutAlign="center center">
                            <div class="parent-hover child-enabler"></div>
                            <clr-icon class="icon-hover" shape="document" [ngStyle]="{'color': theme['--clr-icon-fill']}"
                                (click)="jumpTo.emit({selector: '--clr-icon-fill', page: 0})"></clr-icon>
                            <div class="cat-name" [ngStyle]="{'color': theme['--amsel-document-item-font-color']}"
                                (click)="jumpTo.emit({selector: '--amsel-document-item-font-color', page: 0})">{{localization.dictionary.category.nameSingular}} 1</div>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="activePage[1]" class="doc-container" fxLayout="column" fxLayoutAlign="flex-start center"
                    fxLayoutGap="0.3vw">
                    <ng-container
                        *ngIf="previewCategory.documents && previewCategory.documents.length > 0; else noDocs">
                        <div class="ipad-blur-boxes"
                            [ngStyle]="{'background': theme['--amsel-blur-box-inactive-bg-color']}" fxLayout="row"
                            fxLayoutAlign="flex-start center">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-blur-box-inactive-bg-color', page: 0})"></div>
                            <clr-icon shape="document" [ngStyle]="{'color': theme['--clr-icon-fill']}"></clr-icon>
                            <div fxLayout="column" fxLayoutAlign="center flex-start"
                                [ngStyle]="{'color': theme['--amsel-document-item-font-color']}">
                                <div class="doc-name">{{ localization.dictionary.brandingPreview.inactiveDoc }}</div>
                                <div class="doc-description">{{ localization.dictionary.brandingPreview.previewInactive }}</div>
                            </div>
                        </div>
                        <div *ngFor="let document of previewCategory.documents" class="ipad-blur-boxes"
                            [ngStyle]="{'background': theme['--amsel-blur-box-bg-color']}" fxLayout="row"
                            fxLayoutAlign="flex-start center">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-blur-box-bg-color', page: 0})"></div>
                            <clr-icon   [attr.shape]="document.icon"
                                        class="icon-hover"
                                        [ngStyle]="{'color': theme['--clr-icon-fill'], 'z-index': 1}"
                                        (click)="jumpTo.emit({selector: '--clr-icon-fill', page: 0})">
                            </clr-icon>
                            <div fxLayout="column" fxLayoutAlign="center flex-start"
                                class="boxes-text"
                                [ngStyle]="{'color': theme['--amsel-document-item-font-color']}">
                                <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-document-item-font-color', page: 0})"></div>
                                <div class="doc-name">{{ document.name }}</div>
                                <div class="doc-description">{{ document.description }}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noDocs>
                        <div class="ipad-blur-boxes"
                            [ngStyle]="{'background': theme['--amsel-blur-box-inactive-bg-color']}" fxLayout="row"
                            fxLayoutAlign="flex-start center">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-blur-box-inactive-bg-color', page: 0})"></div>
                            <clr-icon shape="document" [ngStyle]="{'color': theme['--clr-icon-fill']}"></clr-icon>
                            <div fxLayout="column" fxLayoutAlign="center flex-start"
                                [ngStyle]="{'color': theme['--amsel-document-item-font-color']}">
                                <div class="doc-name">{{ localization.dictionary.brandingPreview.inactiveDoc }}</div>
                                <div class="doc-description">{{ localization.dictionary.brandingPreview.previewInactive }}</div>
                            </div>
                        </div>
                        <div class="ipad-blur-boxes"
                            [ngStyle]="{'background': theme['--amsel-blur-box-bg-color']}" fxLayout="row"
                            fxLayoutAlign="flex-start center">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-blur-box-bg-color', page: 0})"></div>
                            <clr-icon   shape="document"
                                        class="icon-hover"
                                        [ngStyle]="{'color': theme['--clr-icon-fill'], 'z-index': 1}"
                                        (click)="jumpTo.emit({selector: '--clr-icon-fill', page: 0})">
                            </clr-icon>
                            <div fxLayout="column" fxLayoutAlign="center flex-start"
                                class="boxes-text"
                                [ngStyle]="{'color': theme['--amsel-document-item-font-color']}">
                                <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-document-item-font-color', page: 0})"></div>
                                <div class="doc-name">{{ localization.dictionary.brandingPreview.firstDoc }}</div>
                                <div class="doc-description">{{ localization.dictionary.brandingPreview.initialStatus }}</div>
                            </div>
                        </div>
                        <div class="ipad-blur-boxes"
                            [ngStyle]="{'background': theme['--amsel-blur-box-bg-color']}" fxLayout="row"
                            fxLayoutAlign="flex-start center">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-blur-box-bg-color', page: 0})"></div>
                            <clr-icon   shape="document"
                                        class="icon-hover"
                                        [ngStyle]="{'color': theme['--clr-icon-fill'], 'z-index': 1}"
                                        (click)="jumpTo.emit({selector: '--clr-icon-fill', page: 0})">
                            </clr-icon>
                            <div fxLayout="column" fxLayoutAlign="center flex-start"
                                class="boxes-text"
                                [ngStyle]="{'color': theme['--amsel-document-item-font-color']}">
                                <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-document-item-font-color', page: 0})"></div>
                                <div class="doc-name">{{ localization.dictionary.brandingPreview.secondDoc }}</div>
                                <div class="doc-description">{{ localization.dictionary.brandingPreview.revisedStatus }}</div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                

                <div *ngIf="activePage[4]" fxFill fxLayout="column" fxLayoutAlign="flex-start center"
                    [ngStyle]="{'background': theme['--clr-login-background-color'], 'color': theme['--clr-p1-color']}"
                    style="overflow: hidden;">
                    <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--clr-login-background-color', page: 3})"></div>
                    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" class="form-header">
                        <div style="padding-left: 0.4vw; min-height: 50%; width: 100%;">
                            <div [style.background-image]="theme['--amsel-logo-background-image']" class="form-logo">
                                <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--amsel-logo-background-image', page: 0})"></div>
                            </div>
                        </div>
                        <div fxFlex="50" fxLayout="column" fxLayoutAlign="center" style="margin: 0 0.4vw; line-height: normal; position: relative;">
                            <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--clr-p1-color', page: 3})"></div>
                            <div style="font-size: 1vw;">{{ localization.dictionary.brandingPreview.optinTitle }}</div>
                            <div>{{ localization.dictionary.brandingPreview.optinSubtitle }}</div>
                        </div>
                    </div>
                    <form class="clr-form clr-form-horizontal fake-form" style="width: 100%;" autocomplete="off"
                        (mouseenter)="labelvor.setAttribute('for', 'vor'); labelnach.setAttribute('for', 'vor'); labelemail.setAttribute('for', 'vor');">
                        <div fxLayout="row" style="width: 100%;">
                            <clr-input-container style="width: 34%; z-index: 1;">
                                <label style="width: 5.25vw; font-size: 0.7vw; position: relative;" #labelvor
                                    [ngStyle]="{'color': theme['--clr-forms-border-color']}">
                                    <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--clr-forms-border-color', page: 3})"></div>
                                    {{ localization.dictionary.user.firstName }}
                                </label>
                                <input clrInput class="inherit-placeholder fake-focus"
                                    [placeholder]="localization.dictionary.user.firstName" #vorname
                                    style="width: 5vw; font-size: 0.7vw; height: 1.2vw;"
                                    [ngStyle]="{'color': theme['--clr-forms-text-color']}"
                                    (click)="vorname.blur(); jumpTo.emit({selector: '--clr-forms-focused-color', page: 3})" />
                            </clr-input-container>
                        </div>
                        <div fxLayout="row" style="width: 100%; padding-bottom: 0.2vw;"
                            [ngStyle]="{'border-bottom': '1px solid ' + theme['--clr-forms-border-color']}">
                            <clr-input-container style="width: 34%; z-index: 1;">
                                <label style="width: 5.25vw; font-size: 0.7vw; position: relative;" #labelnach
                                    [ngStyle]="{'color': theme['--clr-forms-border-color']}">
                                    <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--clr-forms-border-color', page: 3})"></div>
                                    {{ localization.dictionary.user.lastName }}
                                </label>
                                <input clrInput autocomplete="off" type="search" class="inherit-placeholder"
                                    [placeholder]="localization.dictionary.user.lastName" name="name" #nachname
                                    style="width: 5vw; font-size: 0.7vw; height: 1.2vw;"
                                    [ngStyle]="{'color': theme['--clr-forms-text-color']}"
                                    (click)="nachname.blur(); jumpTo.emit({selector: '--clr-forms-text-color', page: 3})" />
                            </clr-input-container>
                        </div>
                        <div fxLayout="row" style="width: 100%; padding-bottom: 0.2vw;"
                            [ngStyle]="{'border-bottom': '1px solid ' + theme['--clr-forms-border-color']}">
                            <clr-input-container style="width: 34%; z-index: 1;">
                                <label style="width: 5.25vw; font-size: 0.7vw; position: relative;" #labelemail
                                    [ngStyle]="{'color': theme['--clr-forms-border-color']}">
                                    <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--clr-forms-border-color', page: 3})"></div>
                                    {{ localization.dictionary.user.email }}
                                </label>
                                <input clrInput autocomplete="off" type="search" class="inherit-placeholder"
                                    [placeholder]="localization.dictionary.user.email" name="name" #email
                                    style="width: 5vw; font-size: 0.7vw; height: 1.2vw;"
                                    [ngStyle]="{'color': theme['--clr-forms-text-color']}"
                                    (click)="email.blur(); jumpTo.emit({selector: '--clr-forms-text-color', page: 3})" />
                            </clr-input-container>
                        </div>
                        <div fxLayout="row" style="width: 100%;">
                            <clr-input-container style="width: 100%">
                                <label fxLayout="flex-start" style="width: 46%; font-size: 0.7vw; position: relative;"
                                    [ngStyle]="{'color': theme['--clr-forms-border-color']}">
                                    <div class="parent-hover" style="z-index: 1;" (click)="jumpTo.emit({selector: '--clr-forms-border-color', page: 3})"></div>
                                    <div style="min-width: 5.2vw;">{{ localization.dictionary.formQuestionType.SIGNATURE }}</div>
                                    <div style="height: 4.1vw">
                                        <canvas style="height: 4vw; margin: 0 0.5%; width: 9vw;"
                                            [ngStyle]="{'border': '1px solid ' + theme['--clr-forms-border-color']}"></canvas>
                                    </div>
                                </label>
                            </clr-input-container>
                        </div>
                        <div fxLayout="row" style="width: 100%; padding-bottom: 0.2vw; z-index: 1;">
                            <div fxFlex="16.66666667">&nbsp;</div>
                            <div style="z-index: 1">
                                <button class="btn fake-modal-button form-delete-btn" #deletebtn
                                    (click)="deletebtn.blur(); jumpTo.emit({selector: '--clr-forms-text-color', page: 3})">
                                    {{ localization.dictionary.formViewer.deleteSig }}
                                </button>
                            </div>
                        </div>
                        <div class="fake-footer" fxLayoutAlign="space-between center"
                            [ngStyle]="{'border-top': '1px solid ' + theme['--clr-forms-border-color']}">
                            <div class="fake-page" style="line-height: normal;"
                                (click)="jumpTo.emit({selector: '--clr-p1-color', page: 3})">{{ localization.dictionary.formViewer.page }} 1/1</div>
                            <button class="btn btn-primary fake-modal-button" #sendbtn
                            (click)="sendbtn.blur(); jumpTo.emit({selector: '--clr-btn-primary-color', page: 5})">{{ localization.dictionary.button.send }}</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="activePage[0] || activePage[2]" class="ipad-sec-cat" fxLayout="row" fxLayoutGap="1vw">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--amsel-sub-icon-fill', page: 0})"></div>
                    <ng-container
                        *ngIf="serverService.categories && serverService.categories.length > 0 && serverService.categories.some(someFunction); else noSubCats">
                        <ng-container *ngFor="let cat of serverService.categories">
                            <div *ngIf="!cat.inMainMenu;">
                                <clr-icon [attr.shape]="cat.icon" [ngStyle]="{'color': theme['--amsel-sub-icon-fill']}">
                                </clr-icon>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-template #noSubCats>
                        <div>
                            <clr-icon shape="attachment" [ngStyle]="{'color': theme['--amsel-sub-icon-fill']}">
                            </clr-icon>
                        </div>
                    </ng-template>

                    <div>
                        <clr-icon shape="sync" [ngStyle]="{'color': theme['--amsel-sub-icon-fill']}"></clr-icon>
                    </div>
                </div>
                <div *ngIf="activePage[5]" style="width: 95%; overflow: hidden" >

                    <div class="caption">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-6-bg-color', page: 1})"></div>

                        <div class="table-name">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>

                            <clr-icon shape="building" style="padding-right: 0.1vw;"></clr-icon>
                            <div>{{ localization.dictionary.customer.name }}</div>
                        </div>
                        <div class="table-dropdown">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>

                            <div class="choose-columns">{{ localization.dictionary.list.chooseColumns }}</div>
                            <clr-icon shape="angle down" class="default-color"></clr-icon>
                        </div>
                        <div class="table-buttons" style="padding-right: 0.2vw;">

                            <button class="btn icon-dropdown" #viewDrop
                            (click)="viewDrop.blur(); jumpTo.emit({selector: '--clr-header-hover-font-color', page: 1})">
                                <clr-icon shape="eye" class="eye-icon-dropdown"></clr-icon>
                                <!-- TODO: Henning new Selector -->
                                <clr-icon shape="angle down" style="font-size: 0.8vw;" class="eye-icon-dropdown"></clr-icon>
                                



                            </button>
                            <!-- <button #filterbtn pButton type="button" (click)="filterbtn.blur(); jumpTo.emit({selector: '--clr-btn-default-outline-color', page: 5})"
                            class="p-button-sm p-button-outlined icon-button color-not-filled">
    
                                <i class="pi pi-filter-slash small-icon" ></i>
                            </button> -->

                            <button class="btn fake-modal-button icon-button" #noFilter
                                (click)="noFilter.blur(); jumpTo.emit({selector: '--clr-header-hover-font-color', page: 1})">
                                <i class="pi pi-filter-slash small-icon" ></i>

                            </button>

                            <!-- <button pButton type="button" #addbtn (click)="addbtn.blur(); jumpTo.emit({selector: '--clr-btn-primary-color', page: 5})"
                            class="p-button-sm icon-button color-filled" >

                                <i class="pi pi-plus small-icon" ></i>

                            </button> -->

                            <button class="btn btn-primary fake-modal-button icon-button" #addBtn
                                (click)="addBtn.blur(); jumpTo.emit({selector: '--clr-header-hover-font-color', page: 1})">
                                <i class="pi pi-plus small-icon" ></i>

                            </button>

                        </div>
                    </div>
                    <table class="table-row">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: 'table', page: 2})"></div>

                            <tr>

                                <th style="line-height: normal;">
                                    <i class="pi pi-stop" style="font-size: 0.6vw; background-color: rgb(30,30,30); color: rgb(188,188,188);"> </i>
                                </th>
                                <th>
                                    <div>
                                        {{localization.dictionary.general.name}}
                                    <clr-icon  shape="two-way-arrows left"></clr-icon>
                                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>

                                    </div>
                                </th>
                                <th>
                                    
                                    <div>
                                        {{localization.dictionary.contact.name}}
                                    <clr-icon shape="two-way-arrows left"  ></clr-icon>
                                    <clr-icon shape="filter"></clr-icon>
                                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>

                                    </div>
                                </th>
                                <th>
                                    <div>
                                        {{localization.dictionary.territory.nameSingular}}
                                    <clr-icon shape="filter"></clr-icon>
                                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>

                                    </div>
                                </th>
                                <th>
                                    <div>
                                        {{localization.dictionary.general.date}}
                                    <clr-icon shape="two-way-arrows left"></clr-icon>
                                    <clr-icon shape="filter"></clr-icon>
                                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>

                                    </div>
                                </th>
                            </tr>
                    
                        
                            <tr *ngFor="let row of tableData">
                                <td style="line-height: normal;">
                                    <i class="pi pi-stop" style="font-size: 0.6vw; background-color: rgb(30,30,30); color: rgb(188,188,188);"> </i>
                                </td>
                                <td>
                                    <div>{{ row.name }}
                                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>
                                    </div>
                                </td>
                                <td>
                                    <div >{{ row.contacts }}
                                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>
                                        </div>
                                </td>
                                <td>
                                    <div >{{ row.territory }}
                                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>
                                        </div>
                                </td>
                                <td>
                                    <div>{{ row.date }}
                                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-h3-color', page: 2})"></div>
                                        </div>
                                </td>
                            </tr>

                        
                    </table>
                    <div class="footer-table">
                        <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-6-bg-color', page: 1})"></div>

                        <div class="diver">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>

                            {{localization.dictionary.list.componentsTotal
                                .replace('${first}', '1')
                                .replace('${last}', '5')
                                .replace('${total}', '14')
                                .replace('${componentName}', localization.dictionary.customer.name)}}
                        </div>
                        <div class="page-slider">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>

                            <clr-icon shape="angle left" class="default-color"></clr-icon>

                            <div style="position: relative; color: var(--clr-header-hover-font-color)">
                                <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-hover-font-color', page: 1})"></div>
                                1
                            </div>
                            <div>2</div>
                            <div>3</div>

                            <clr-icon shape="angle right" class="default-color"></clr-icon>
                        </div>
                        <div class="table-dropdown">
                            <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-header-font-color', page: 1})"></div>

                            <div>5</div>
                            <clr-icon shape="angle down" class="default-color"></clr-icon>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div *ngIf="activePage[2]" class="fake-modal-wrapper">
            <div class="fake-modal"
                [ngStyle]="{'background-color': theme['--clr-modal-bg-color'], 'color': theme['--clr-modal-title-color']}">
                <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-modal-bg-color', page: 4})"></div>
                <div class="fake-close">
                    <clr-icon shape="times"></clr-icon>
                </div>
                <div class="fake-modal-title">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-modal-title-color', page: 4})"></div>
                    {{ localization.dictionary.synchronization.syncNow }}
                </div>
                <div class="fake-modal-text">
                    <div class="parent-hover" (click)="jumpTo.emit({selector: '--clr-modal-font-color', page: 4})"></div>
                    {{ localization.dictionary.synchronization.lastSyncLongAgo }}
                </div>
                <div style="align-self: flex-end; position: relative;">
                    <div class="parent-hover child-enabler"></div>
                    <button class="btn fake-modal-button" #abortbtn
                        (click)="abortbtn.blur(); jumpTo.emit({selector: '--clr-btn-default-outline-color', page: 5})">{{ localization.dictionary.button.cancel }}</button>
                    <button class="btn btn-primary fake-modal-button" #confirmbtn
                        (click)="confirmbtn.blur(); jumpTo.emit({selector: '--clr-btn-primary-color', page: 5})">{{ localization.dictionary.button.confirm }}</button>
                </div>
            </div>
        </div>
    </div>
</div>