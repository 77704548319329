import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function germanDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value) {
            const regExp = /([0-9]{1,2}).([0-9]{1,2}).([0-9]{4})/;
            const wrongDateFormat = !regExp.test(control.value);        
            return wrongDateFormat ? {wrongDateFormat: {value: control.value}} : null;
        } else {
            return null;
        }
    };
  }