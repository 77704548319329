import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { Call } from 'src/app/crm/calls/call.model';
import { Contact } from 'src/app/crm/contacts/contact.model';
import { CrmService } from 'src/app/crm/crm.service';
import { Customer } from 'src/app/crm/customers/customer.model';
import { ServerResponse, ServerService } from 'src/app/server.service';
import { User } from 'src/app/users/user.model';
import { Field } from '../../crm/fields/field.model';
import { LocalizationService } from '../../localization/localization.service';

/**
 * Generic component for list selection
 * @Input() 
 * @input query 
 */
@Component({
  selector: 'app-list-selector',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.css']
})

export class ListSelectorComponent implements OnInit, OnChanges {
  availableList: any[] = [];
  selectedItems: boolean[] = [];
  state: 'all' | 'none' | 'partial' = 'none';
  tempList: any[];

  /** any[] of elements already selected (if any) */
  @Input() preLoaded: any[] = [];
  /** string of which api endpoint to query to get all possible entries */
  @Input() query: string;
  /** name of the attribute which to show in the list selector */
  @Input() attrToShow: string = 'name';
  /** property to match the selections to */
  @Input() attrToMatch: string = 'id';
  /** label name of the form element */
  @Input() label: string;
  /** exclude the admin Role */
  @Input() excludeAdmin = false;
  /** event emitter which emits the list of selected elements */
  @Output() selectedChanged = new EventEmitter<any[]>();

  @Input() property: string;
  
  @Input() displayFields: Field[];

  @Input() required: boolean;
  @Input() disabled = false;

  @Input() conflict: {value: any, user: User, date: Date};
  @Input() conflictChoices = {};
  @Output() conflictChoicesChange: EventEmitter<any> = new EventEmitter<any>();
  
  @Output() customForms: EventEmitter<ClrForm[]> = new EventEmitter<ClrForm[]>()
  @Output() customFormDatas: EventEmitter<NgForm[]> = new EventEmitter<NgForm[]>()

  @Output() dirtyChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(ClrForm) set contentForms(content: ClrForm[]) {
    if (content && content.length > 0) { // initially setter gets called with undefined      
      this.customForms.emit(content);
    }
  }

  @ViewChildren('formData') set content(content: NgForm[]) {
    if (content && content.length > 0) { // initially setter gets called with undefined      
      this.customFormDatas.emit(content);
    }
  }

  constructor(private server: ServerService, 
              private auth: AuthService,
              public crm: CrmService,
              public localization: LocalizationService) { }

  async ngOnInit(): Promise<void> {
    //await this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "query" && this.query) {
          this.refresh();
        }
        if (propName == "preLoaded" && this.preLoaded) {
          this.loadSelectedItems();
        }
      }
    }
  }

  async refresh(queryOverride?: string) {
    if (queryOverride) {
      this.query = queryOverride;
    }
    const res = await this.server.get(this.query) as ServerResponse;
    if (res.rows) {
      if (this.property == 'contacts' || this.property == 'contact.id') {
        this.availableList = plainToClass(Contact, res.rows)
      } else if (this.property == 'customers' || this.property == 'customer.id') {
        this.availableList = plainToClass(Customer, res.rows);
      } else if (this.property == 'calls' || this.property == 'call.id') {
        this.availableList = plainToClass(Call, res.rows);
      } else {
        this.availableList = res.rows;
      }
    } else {
      this.availableList = [];
    }
    
    if (this.excludeAdmin && !this.auth.hasRole(['admin'])) {
      if (this.query == 'roles') {
        this.availableList = this.availableList.filter(role => role.name != this.auth.adminName)
      }
      if (this.query == 'group') {
        this.availableList = this.availableList.filter(group => !group.roles || !group.roles.find(role => role.name == this.auth.adminName))
      }
    }
    this.loadSelectedItems();
    this.onChanged();
  }

  loadSelectedItems() {
    for (let i = 0; i < this.availableList.length; i++) {
      if (this.preLoaded && this.preLoaded.find((item) => this.availableList[i][this.attrToMatch]
       === item[this.attrToMatch])) {
        this.selectedItems[i] = true;
      } else {
        this.selectedItems[i] = false;
      }
    }
    this._checkState();
  }

  onChanged(ev?) {
    const output = [];
    for (let i = 0; i < this.availableList.length; i++) {
      if (this.selectedItems[i] === true) output.push(this.availableList[i])
    }
    this._checkState();
    this.selectedChanged.emit(output);
  }

  selectAll() {
    if (this.state === 'none' || this.state === 'partial') {
      this._setAll(true);
      this.state = 'all';
    } else {
      this._setAll(false);
      this.state = 'none';
    }
  }

  updateSelected(selected: any[]) {
    this.preLoaded = selected;
    this.loadSelectedItems();
  }

  _setAll(bool: boolean) {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.selectedItems[i] = bool;
    }
  }

  _checkState() {
    const inclTrue = this.selectedItems.includes(true);
    const inclFalse = this.selectedItems.includes(false);
    if (inclTrue && inclFalse) {
      this.state = 'partial';
    } else if (inclTrue) {
      this.state = 'all';
    } else {
      this.state = 'none';
    }
  }

  getDisplayName(item: any) {
    if (this.displayFields.length == 0)
      return item.id;
    else {
      let displayValue = '';
      for (let display of this.displayFields) {
        const displayName = typeof display == 'string' ? display : display.name
        if (item[displayName]) {
          for (let field of item[displayName])
            displayValue += field.value + ' '
        }
      }
      return displayValue.trimEnd();
    }
  }

  displayList(list: any[], current?: boolean) {
    if(!list) {
      this.tempList = JSON.parse(JSON.stringify(this.preLoaded));
      list = this.preLoaded;
    }
    let displayList = [];
    for (let i = 0; i < this.availableList.length; i++) {
      if (list && list.find((item) => this.availableList[i][this.attrToMatch] === item[this.attrToMatch])) {
        displayList.push(this.availableList[i])
      }
    }
    return displayList.map((value) => value[this.attrToShow]? value[this.attrToShow] : this.getDisplayName(value)).join(', ')
  }

}
