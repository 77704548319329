import { Component, HostListener, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LocalizationService } from '../../localization/localization.service';
import { Unsubscribable } from '../unsubscribable/unsubscribable';

@Component({
  template: ''
})
export abstract class PreventUnload extends Unsubscribable {
  saving = false
  dirty = false;
  unloadText = this.localization.dictionary.createEdit.unloadDefault;
  
  constructor(public localization: LocalizationService) {
    super();
  }

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    if (this.dirty || this.saving) {
      $event.returnValue = true;
    }
  }

  changeUnloadNew(component: string){
    this.unloadText = this.localization.dictionary.createEdit.unloadTextNew
      .replace('${theNewComponent}', this.localization.dictionary[component].theNew);
  }

  changeUnloadEdit(component: string, name: string){
    this.unloadText = this.localization.dictionary.createEdit.unloadTextEdit
      .replace('${componentDative}', this.localization.dictionary[component].dative)
      .replace('${entryName}', name);
  }

  canDeactivate() {
    let result = confirm(this.unloadText);
    if(!result){
      window.history.pushState((window.history.state), "");
    }
    return result;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedGuard implements CanDeactivate<PreventUnload> {
  canDeactivate(component: PreventUnload) {    
    return !component.saving ? (!component.dirty || component.canDeactivate()) : false;
  }
  
}
