import { Expose, Type } from "class-transformer";

export class MetaData {
    @Expose()
    @Type(() => Date)
    createdAt?: Date;

    @Expose()
    @Type(() => Date)
    updatedAt?: Date;

    @Expose()
    @Type(() => Date)
    deletedAt?: Date;
}