<clr-tabs>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.general.general}}</button>
        <ng-template [(clrIfActive)]="firstTab">
            <clr-tab-content *clrIfActive>
                <div class="card" *ngIf="!loading">
                    <div class="card-header space-between-flex">
                        <div fxLayout="column">
                           {{ helper.getCardHeader(mode, 'customer', name) }}
                        </div>

                        <clr-dropdown>
                            <button class="dropdown-toggle" clrDropdownTrigger>
                                <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                                <clr-icon shape="caret down" size="20"></clr-icon>
                            </button>
                            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                                <label class="dropdown-header" aria-hidden="true">{{localization.dictionary.view.name}}</label>
                                <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                                <div *ngFor="let view of views" (click)="activeView=view; crm.activeViewChanged(view, 'customer')" clrDropdownItem>
                                    <clr-icon [ngStyle]="{'visibility': (view === activeView) ? 'visible' : 'hidden' }"
                                        style="margin-right: 0.3rem" shape="check"></clr-icon>
                                    {{ view.name }}
                                </div>
                            </clr-dropdown-menu>
                        </clr-dropdown>

                    </div>
                    <div class="card-block" *ngFor="let section of activeView?.sections">
                        <div class="card-title collapse-card">
                            <span>
                                <clr-icon [attr.shape]="section.icon" class="clr-icon-heading"
                                    role="img"></clr-icon>
                                <span>{{ section.name }}</span>
                                <p class="card-text icon-offset">
                                    {{ section.description }}
                                </p>
                            </span>
                            <clr-icon shape="angle" class="arrow" [dir]="!collapse[section['id']] ? 'down' : 'left'"
                                (click)="collapse[section['id']] = !collapse[section['id']]"></clr-icon>
                        </div>
                        <div [hidden]="collapse[section['id']]" class="card-text">
                            <app-custom-input [model]="customer" [modelSnapshot]="customerSnapshot" [modelString]="'customer'" [fields]="section.fields" (change)="dirty = true"
                                [(choices)]="conflictChoices" (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)"
                                [conflicts]="conflicts" (sameDV)="checkDV($event)">
                            </app-custom-input>
                        </div>
                    </div>
                    <div class="card-block">
                        <div class="card-title collapse-card">
                            <span>
                                <clr-icon shape="map" class="clr-icon-heading"></clr-icon>
                                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.territory.nameSingular)}}</span>
                                <p class="card-text icon-offset">
                                    {{localization.dictionary.customerCreateEdit.assignTerDescription}}
                                </p>
                            </span>
                            <clr-icon shape="angle" class="arrow" [dir]="!collapse['territory'] ? 'down' : 'left'"
                                (click)="collapse['territory'] = !collapse['territory']"></clr-icon>
                        </div>
                        <div [hidden]="collapse['territory']" class="card-text">
                            <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                                <clr-combobox-container>
                                    <label>{{localization.dictionary.territory.nameSingular}}*</label>

                                    <clr-combobox [(ngModel)]="customer.territory" name="territory" [required]="territoryRequired">
                                        <clr-options>
                                            <clr-option *clrOptionItems="let territory of territories; field:'name'"
                                                [clrValue]="territory">
                                                <div [pTooltip]="territory.description" 
                                                    tooltipPosition="right">
                                                    {{ territory.name }}
                                                </div>
                                            </clr-option>
                                        </clr-options>
                                    </clr-combobox>

                                    <clr-control-helper *ngIf="territoryConflict">
                                        <div>{{localization.dictionary.conflict.manageConflict}}</div>
                                        <clr-radio-container class="conflict">
                                            <clr-radio-wrapper>
                                                <input type="radio" required clrRadio [value]="'territoryconflict' + 1"
                                                    [name]="'territoryconflict'" [(ngModel)]="conflictChoices['territory']"
                                                    (ngModelChange)="onConflictChangeTerritory(tempTerritory, 'model')" />
                                                <label>{{ displayTerritoryConflict(tempTerritory) }} ({{localization.dictionary.createEdit.current}})</label>
                                            </clr-radio-wrapper>
                                            <clr-radio-wrapper>
                                                <input type="radio" required clrRadio [value]="'territoryconflict' + 2"
                                                    [name]="'territoryconflict'" [(ngModel)]="conflictChoices['territory']"
                                                    (ngModelChange)="onConflictChangeTerritory(territoryConflict.value, 'conflict')" />
                                                <label>{{ displayTerritoryConflict(territoryConflict.value) }}
                                                    ({{territoryConflict.user?.name}},
                                                    {{territoryConflict.date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                                            </clr-radio-wrapper>
                                            <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                        </clr-radio-container>
                                    </clr-control-helper>
                                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                </clr-combobox-container>
                            </form>
                        </div>
                    </div>
                    <div class="card-block">
                        <div class="card-title collapse-card">
                            <span>
                                <clr-icon shape="briefcase" class="clr-icon-heading"></clr-icon>
                                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.product.nameSingular)}}</span>
                                <p class="card-text icon-offset">
                                    {{localization.dictionary.customerCreateEdit.assignProdDescription}}
                                </p>
                            </span>
                            <clr-icon shape="angle" class="arrow" [dir]="!collapse['products'] ? 'down' : 'left'"
                                (click)="collapse['products'] = !collapse['products']"></clr-icon>
                        </div>
                        <div [hidden]="collapse['products']" class="card-text icon-offset">
                            <app-list-selector [excludeAdmin]="true"
                            (selectedChanged)="customer.products= $event" [conflict]="productConflict" [(conflictChoices)]="conflictChoices"
                                [preLoaded]="customer.products" [label]="localization.dictionary.product.name" [query]="'crm/product'">
                            </app-list-selector>
                        </div>

                    </div>

                    <div stickyFooter class="card-footer">
                        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                    </div>
                </div>
            </clr-tab-content>
        </ng-template>        
    </clr-tab>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.contact.name}}</button>
        <ng-template [(clrIfActive)]="secondTab">
            <clr-tab-content *clrIfActive>
                <div class="card" *ngIf="!loading">
                    <div class="card-header">{{ helper.getCardHeader(mode, 'customer', name) }}</div>
                    <div class="card-block">
                        <div class="card-title">
                            <clr-icon shape="user" class="clr-icon-heading is-solid"></clr-icon>
                            <span>{{localization.dictionary.createEdit.assignedComponent.replace('${componentName}', localization.dictionary.contact.name)}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.customerCreateEdit.deleteModalDescriptionCon}}
                            </p>
                        </div>
                        <div class="card-text">
                            <app-sublist-contacts [conflict]="contactConflict" [current]="customer.id || mode == 'create'" [dataIds]="contactIds" [positions]="positions"
                                (selectedContact)="removeContact($event)" (editContact)="toAdd = $event.contact;
                                 tempPosition = $event.position; editPositionMode = true"
                                 (createCall)="router.navigate(['/crm/call/create/' + customer.id + '/' + $event.id])"></app-sublist-contacts>
                            <form *ngIf="contactConflict" clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                                <div class="clr-form-control list-conflict">
                                    <div class="clr-subtext-wrapper">
                                        <clr-control-helper>
                                            <div>{{localization.dictionary.conflict.manageConflict}}</div>
                                            <clr-radio-container class="conflict">
                                                <clr-radio-wrapper>
                                                    <input type="radio" required clrRadio [value]="'contactconflict' + 1"
                                                        [name]="'contactconflict'" [(ngModel)]="conflictChoices['contacts']"
                                                        (ngModelChange)="onConflictChangeContact(tempContacts, 'model')" />
                                                    <label>{{ displayContactConflict(tempContacts) }} ({{localization.dictionary.createEdit.current}})</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input type="radio" required clrRadio [value]="'contactconflict' + 2"
                                                        [name]="'contactconflict'" [(ngModel)]="conflictChoices['contacts']"
                                                        (ngModelChange)="onConflictChangeContact(contactConflict?.value, 'conflict')" />
                                                    <label>{{ displayContactConflict(contactConflict.value) }}
                                                        ({{contactConflict.user?.name}},
                                                        {{contactConflict.date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }})</label>
                                                </clr-radio-wrapper>
                                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                            </clr-radio-container>
                                        </clr-control-helper>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card-block">
                        <div class="card-title" style="display: flex; justify-content: space-between;">
                            <div>
                                <clr-icon shape="user" class="clr-icon-heading"></clr-icon>
                                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.contact.name)}}</span>
                                <p class="card-text icon-offset">
                                    {{localization.dictionary.customerCreateEdit.assignModalDescriptionCust}}
                                </p>
                            </div>
                            <div>
                                <button class="btn btn-outline" (click)="contactModal = true">
                                    {{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.contact.nameSingular) }}
                                </button>
                            </div>

                        </div>
                        <div class="card-text">
                            <app-sublist-contacts [current]="false" [dataIds]="contactIds"
                                (selectedContact)="toAdd = $event; tempPosition = positions[toAdd.id]" ></app-sublist-contacts>
                        </div>
                    </div>
                    <div stickyFooter class="card-footer">
                        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                    </div>
                </div>
    
            </clr-tab-content>
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.interaction.name}}</button>
        <clr-tab-content *clrIfActive="interactionActiv">
            <div class="card" *ngIf="!loading">
                <div class="card-header">{{ helper.getCardHeader(mode, 'customer', name) }}</div>
                <div class="card-block">
                    <div class="card-title">
                        <clr-icon shape="two-way-arrows" class="clr-icon-heading is-solid"></clr-icon>
                        <span>{{localization.dictionary.interaction.last}}</span>
                        <p class="card-text icon-offset">
                            {{localization.dictionary.interaction.showRecentInteraction}}
                        </p>
                    </div>
                    <div class="card-text">
                        <app-interaction-customer [customerId]="customer.id"></app-interaction-customer>
                    </div>
                </div>
            </div>
            <div stickyFooter class="card-footer">
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab *ngIf="mode === 'edit' && customer">
        <button clrTabLink>{{ localization.dictionary.file.name}}</button>
        <clr-tab-content *clrIfActive="fileUploadActive">
            <div class="card">
                <div class="card-header">{{ helper.getCardHeader(mode, 'customer', name) }}</div>
                <div class="card-block">
                    <div class="card-title">
                        <clr-icon shape="file-group" class="clr-icon-heading is-solid"></clr-icon>
                        <span>{{ localization.dictionary.file.linked }}</span>
                        <p class="card-text icon-offset">
                            {{ localization.dictionary.file.linkedAddition.replace('${mode}', localization.dictionary.customer.dative) }}
                        </p>
                    </div>
                    <div class="card-text">
                        <app-file-upload-list [mode]="'customer'" [customerId]="customer.id"></app-file-upload-list>
                    </div>
                </div>

            </div>

            <div stickyFooter class="card-footer">
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab *ngIf="mode === 'edit' && customer">
        <button clrTabLink>{{ localization.dictionary.auditLogging.auditLogPlural}}</button>
        <clr-tab-content *clrIfActive="auditActive">
            <div class="card">
                <div class="card-header">{{ localization.dictionary.auditLogging.changeHistory }}</div>
                <div class="card-block">
                    <app-audit [entityId]="customer.id" [entityType]="'Customer'"></app-audit>
                </div>
            </div>
            <div stickyFooter class="card-footer">
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
            </div>
        </clr-tab-content>
    </clr-tab>

    <app-spinner *ngIf="loading"></app-spinner>

    <ng-template #actionBar>
        <button class="btn btn-primary" (click)="validateAndSave()">{{ ( mode === 'create' ) ? localization.dictionary.button.create :
            localization.dictionary.button.save }}</button>
        <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
        <!-- Call erstellen -->
        <button class="btn btn-success btn-outline" (click)="validateAndSave(true)">
            {{ localization.dictionary.button.saveAndCall }}
        </button>
        <button class="btn btn-outline" (click)="contactModal = true">
            {{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.contact.nameSingular) }}
        </button>
        <!--<button class="btn btn-success btn-outline" [routerLink]="['/crm/call/create/' + customer.id]">{{ helper.getCardHeader('create', 'call', '') }}</button>-->
    </ng-template>
</clr-tabs>


<clr-modal [(clrModalOpen)]="toAdd">
    <h3 class="modal-title">{{localization.dictionary.customerCreateEdit.cusPosition}}</h3>
    <div class="modal-body">
        <form clrForm>
            <clr-input-container>
                <label>{{localization.dictionary.customerContact.position}}</label>
                <input clrInput [(ngModel)]="tempPosition" name="position" maxlength="100"/>
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button *ngIf="!editPositionMode" type="button" class="btn btn-primary" (click)="addContact(toAdd)">{{localization.dictionary.button.add}}</button>
        <button *ngIf="editPositionMode" type="button" class="btn btn-primary" (click)="changeContactPosition(toAdd)">{{localization.dictionary.button.ok}}</button>
        <button type="button" class="btn btn-outline" (click)="toAdd = undefined; tempPosition = undefined; editPositionMode=false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="contactModal" clrModalSize="lg" [clrModalClosable]="false">
    <h3 class="modal-title">{{localization.dictionary.createEdit.createComponent.replace('${componentName}', localization.dictionary.contact.nameSingular) }}</h3>
    <div class="modal-body">
        <app-add-customer-contact *ngIf="contactModal" [toAdd]="customer" [createContact]="createContact" (createdContact)="newContact($event)"></app-add-customer-contact>
    </div>
    <div class="modal-footer">
        <button *ngIf="!editPositionMode" type="button" class="btn btn-primary" (click)="createContact.next(null)">{{localization.dictionary.button.save}}</button>
        <button type="button" class="btn btn-outline" (click)="toAdd = undefined; tempPosition = undefined; editPositionMode=false" (click)="contactModal=false">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

