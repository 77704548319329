import { Component, DoCheck, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { BrandingService } from '../../../branding/branding.service';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { PreventUnload } from '../../../helper/guards/unsaved.guard';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { Conflict } from '../../conflict/conflict.model';
import { CrmService } from '../../crm.service';
import { CustomerContact } from '../../customers/customer-contact.model';
import { Customer } from '../../customers/customer.model';
import { View } from '../../views/view.model';
import { Contact } from '../contact.model';
import { FieldValue } from '../../fields/field-value.model';
import { User } from '../../../users/user.model';

@Component({
  selector: 'app-create-edit-contacts',
  templateUrl: './create-edit-contacts.component.html',
  styleUrls: ['./create-edit-contacts.component.css']
})
export class CreateEditContactsComponent extends PreventUnload implements OnInit, DoCheck, OnDestroy {

  private forms: ClrForm[]
  @ViewChildren(ClrForm) set contentForms(content: QueryList<ClrForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined      
      if (!this.forms || this.forms.length == 0)
        this.forms = content.toArray();
      else
        this.forms.push(...content.toArray());
    } else {
      if (!this.forms || this.forms.length == 0)
        this.forms = [];
    }
  }
  private formDatas: NgForm[];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = content.toArray();
      else
        this.formDatas.push(...content.toArray());
    } else {
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = [];
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  firstTabInvalid = false;

  toAdd: Customer;
  contact: Contact;
  contactSnapshot: Contact;
  conflict: Conflict;
  conflictContact: Contact;
  conflicts = {};
  fieldConflictsToCheck = [];
  conflictsSearched = false;
  conflictId: string;
  customerConflict: { value: Customer[], user: User, date: Date };
  conflictChoices = {};
  tempFieldValues = {};
  tempCustomers: Customer[];
  mode: 'edit' | 'create' | 'conflict';
  loading = true;
  sameDV: FieldValue[] = [];
  change: boolean;

  customerIds: string[];

  views: View[];
  activeView: View;

  firstTab = true;
  secondTab = false;
  auditActive = false;
  invalid = false;
  customerInvalid = false;
  collapse = {};

  name: string = null;

  positions = {};
  tempPosition: string;
  editPositionMode = false;

  callFromContactModal = false;
  selectedCustomerId;

  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public router: Router,
    public crm: CrmService,
    public helper: HelperService,
    public branding: BrandingService,
    public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        if (params.id) {
          this.mode = 'edit';
        } else if (params.conflictId) {
          this.mode = 'conflict'
          this.conflictId = params.conflictId;
        } else {
          this.mode = 'create'
        }
        if (this.mode === 'edit') {
          this.contact = plainToClass(Contact, await this.server.get('crm/contact/byId/' + params.id));
        } else if (this.mode === 'conflict') {
          this.conflict = await this.server.get('crm/conflict/byId/' + params.conflictId) as any;
          this.contact = plainToClass(Contact, this.conflict.contact);
          this.contactSnapshot = plainToClass(Contact, this.conflict.contact);
          this.conflictContact = plainToClass(Contact, JSON.parse(this.conflict.json))
        } else if (this.mode === 'create') {
          this.contact = new Contact();
          this.dirty = true;
        }
        if (/edit|conflict/.test(this.mode)) {
          for (let customer of this.contact.customers) {
            if (customer.CustomerContact?.position) {
              this.positions[customer.id] = customer.CustomerContact.position;
            }
          }
        }
        this.customerIds = this.contact.customers.map(customer => customer.id);
        this.changeUnload();
        this.loading = false;
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    )
  }

  async ngOnInit(): Promise<void> {
    await this.crm.setActiveViewsFromStorage(['contact']);
    this.views = this.crm.views.contact;
    this.activeView = this.crm.activeViews.contact;
    
    for (let section of this.activeView.sections) {
      this.collapse[section.id] = false;
    }
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('contact');
    } else {
      this.changeUnloadEdit('contact', this.crm.getDisplayValue(this.contact));
    }
  }

  activeViewChanged(view: View){

  }

  ngOnDestroy() {
    localStorage.setItem('contact-active-view', JSON.stringify(this.activeView));
  }

  ngDoCheck() {
    if (!this.conflictsSearched && this.mode == 'conflict' && this.contact && this.crm.fields.contact) {
      for (let key of this.crm.fields.contact.map((field) => field.name)) {
        let conflictFound = false;
        if (this.contact[key] && this.conflictContact[key]) {
          if (this.contact[key].length !== this.conflictContact[key].length) {
            conflictFound = true;
          } else if (this.contact[key].length == 1 && this.contact[key][0].value !== this.conflictContact[key][0].value) {
            conflictFound = true;
          } else {
            for (let fieldValue of this.contact[key]) {
              if (!this.conflictContact[key].find(conValue => conValue.fieldOptionId == fieldValue.fieldOptionId)) {
                conflictFound = true;
              }
            }
          }
        } else if (!this.contact[key] && this.conflictContact[key]) {
          conflictFound = true;
        }
        if (conflictFound) {
          this.conflicts[key] = { value: this.conflictContact[key], user: this.conflict.user, date: this.conflict.date };
          this.fieldConflictsToCheck.push(key);
        }
      }
      let customerConflictFound = false;
      if (this.contact.customers && this.conflictContact.customers) {
        if (this.contact.customers.length !== this.conflictContact.customers.length) {
          customerConflictFound = true;
        } else {
          for (let customer of this.contact.customers) {
            if (!this.conflictContact.customers.find(cusValue => cusValue.id == customer.id && cusValue.CustomerContact.position == customer.CustomerContact.position)) {
              customerConflictFound = true;
            }
          }
        }
      } else if (!this.contact.customers && this.conflictContact.customers) {
        customerConflictFound = true;
      }
      if (customerConflictFound) {
        this.customerConflict = { value: this.conflictContact.customers, user: this.conflict.user, date: this.conflict.date };
      }
      this.conflictsSearched = true;
    }
    if (!this.name && (this.mode === 'edit' || this.mode == 'conflict') && this.contact && this.crm.fields.contact) {
      this.name = this.crm.getDisplayValue(this.contact);
      this.changeUnloadEdit('contact', this.name);
    }
    if (this.invalid && this.firstTabInvalid && this.firstTab && this.forms && this.formDatas) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();
        if (formData.invalid) {
          this.collapse[formData.name] = false;
        }
      }
      this.invalid = false;
      for (let form of this.forms) {
        form.markAsTouched();
      }
    }
    if (this.firstTab && this.formDatas) {
      this.firstTabInvalid = false;
      for (let form of this.formDatas) {
        if (!form.valid && !form.disabled) {
          this.firstTabInvalid = true;
        }
      }
    }
      
  }

  addCustomForms(forms: ClrForm[]) {
    if (!this.forms || this.forms.length == 0)
      this.forms = [];
    this.forms.push(...forms);
  }

  addCustomFormDatas(formDatas: NgForm[]) {
    if (!this.formDatas)
      this.formDatas = [];
    this.formDatas.push(...formDatas);
  }

  addCustomer(customer: Customer) {
    this.positions[customer.id] = this.tempPosition;
    if (!customer.CustomerContact) {
      customer.CustomerContact = new CustomerContact();
    }
    customer.CustomerContact.position = this.tempPosition;
    this.tempPosition = undefined;
    this.contact.customers.push(customer);
    this.customerIds = this.contact.customers.map(customer => customer.id);
    this.customerInvalid = false;
    this.toAdd = undefined;
  }

  changeCustomerPosition(customer?: Customer) {
    this.positions[customer.id] = this.tempPosition;
    const findCus = this.contact.customers.find(cuss => customer.id == cuss.id);
    findCus.CustomerContact.position = this.tempPosition;
    this.tempPosition = undefined;
    this.editPositionMode = undefined;
    this.toAdd = undefined;
  }

  setQueryParams(){
    let obj = {};
    for (let dv of this.sameDV) {
      obj[dv.field.name] = dv.value;
    }
    return obj;
  }

  openList(){
    this.dirty = false;
  }

  checkDV(event: [string, FieldValue[]]) {
    if (event && event[0] == '+1') {
      event[1] = event[1].filter(ev => !this.sameDV.find(dv => ev.id == dv['id']));
      this.sameDV.push(...event[1]);
    } else if (event && event[0] == '-1') {
      this.sameDV = this.sameDV.filter(dv => dv['id'] != event[1][0]?.id);
    }
  }

  removeCustomer(customer: Customer) {
    this.contact.customers = this.contact.customers.filter(contactCustomer => contactCustomer.id != customer.id);
    this.customerIds = this.contact.customers.map(customer => customer.id);
    if (this.customerIds.length < 1) {
      this.customerInvalid = true;
    }
  }

  async validateAndSave(createCall?: boolean) {
    this.ngDoCheck();
    if (!this.firstTabInvalid && this.fieldConflictsToCheck.every(con => !!this.conflictChoices[con])) {
      if (this.contact.customers.length > 0 && (!this.customerConflict || this.conflictChoices['customers'])) {
        await this.save(createCall);
      }
      else {
        this.secondTab = true;
        this.invalid = true;
        this.customerInvalid = true;
      }
    }
    else {
      this.firstTab = true;
      this.invalid = true;
    }
  }

  async save(createCall?: boolean) {
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    if (this.mode === 'create') {
      let res = await this.server.post('crm/contact', this.contact);
      this.contact = plainToClass(Contact, res);
    } else {
      await this.server.put('crm/contact', this.contact);
    }
    if (this.mode === 'conflict') {
      await this.server.delete('crm/conflict?toDelete=' + this.conflictId);
    }
    this.server.addAlert(new AmselError(
      undefined,
      'success',
      this.localization.dictionary.toastr.successSaved
      .replace('${componentName}', this.localization.dictionary.contact.nameSingular)
      .replace('${entryName}', this.crm.getDisplayValue(this.contact))
    ));
    this.saving = false;
    this.loading = false;
    if (!createCall) {
      this.server.back();
    } else {
      if(this.contact.customers.length == 0){
      this.router.navigateByUrl('/crm/call/create/' + this.contact.customers[0].id + '/' + this.contact.id);
      } else {
        this.callFromContactModal = true;
      }
    }
  }

  displayCustomerConflict(customers: Customer[]) {
    if (!customers) {
      this.tempCustomers = plainToClass(Customer, this.contactSnapshot.customers);
      customers = this.contactSnapshot.customers;
    }
    return customers.map(cus => this.crm.getDisplayValue(cus) + (cus.CustomerContact?.position?.replace(/^(.*)$/, ' ($1)') ?? '')).join(', ');
  }

  onConflictChangeCustomer(customers: Customer[], type: 'conflict' | 'model') {
    let clone: Customer[];
    if (type == 'conflict') {
      clone = JSON.parse(JSON.stringify(customers));
    } else {
      clone = JSON.parse(JSON.stringify(this.tempCustomers));
    }
    this.positions = {};
    for (let cus of clone) {
      if (cus.CustomerContact?.position) {
        this.positions[cus.id] = cus.CustomerContact.position;
      }
    }
    this.contact.customers = clone;
    this.customerIds = this.contact.customers.map(cus => cus.id);
  }
}