import { Expose, Type } from "class-transformer";
import { AnsweredForm } from "src/app/forms/answered-form.model";
import { Call } from "../calls/call.model";
import { Contact } from "../contacts/contact.model";
import { FieldValue } from "../fields/field-value.model";
import { Product } from "../products/product.model";
import { Territory } from "../territories/territory.model";
import { CustomerContact } from "./customer-contact.model";
import { FileUpload } from "../file-upload/file-upload.model";

export class Customer {
    @Expose()
    id: string;

    @Expose()
    lastInteraction: Date;

    @Expose()
    nextInteraction: Date;

    @Expose()
    @Type( () => Contact)
    contacts: Contact[] = [];

    @Expose()
    @Type( () => Call)
    calls: Call[] = [];

    @Expose()
    @Type( () => FieldValue)
    fieldValues: FieldValue[];

    @Expose()
    @Type( () => Territory)
    territory: Territory;

    @Expose()
    @Type( () => Product)
    products: Product[] = [];

    @Expose()
    @Type( () => AnsweredForm)
    answeredForms: AnsweredForm[] = [];

    @Expose()
    @Type( () => FileUpload)
    fileUploads: FileUpload[] = [];

    @Expose()
    @Type( () => CustomerContact)
    CustomerContact: CustomerContact = new CustomerContact();
}

