
<h2>{{localization.dictionary.customer.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid *ngIf="sectionFields && sectionFields.length > 0" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading"
    [(clrDgSelected)]="selected" (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.customer.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column *ngFor="let customField of sectionFields"
        [clrDgSortBy]="'fieldValues.' + customField.id"
        [(clrDgSortOrder)]="sorting[customField.name]" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden[customField.name]">
            {{customField.label}}
        </ng-template>

        <clr-dg-filter
            *ngIf="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX' || customField.type == 'CHOICE' || customField.type === 'SELECTION'"
            [clrDgFilter]="multiselectFilter" [(clrDgFilterOpen)]="multiselectFilter.open">
            <multiselect-filter #multiselectFilter [name]="customField.label" [searchBy]="'label'"
                [property]="'fieldValues.' + customField.id" [query]="'crm/field/byId/' + customField.id"
                [(filter)]="filters[customField.name]"
                [toggle]="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX'">
            </multiselect-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'NUMBER'" [clrDgFilter]="numberFilter"
            [(clrDgFilterOpen)]="numberFilter.open">
            <number-filter #numberFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [offset]="0"></number-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'BOOLEAN'" [clrDgFilter]="booleanFilter"
            [(clrDgFilterOpen)]="booleanFilter.open">
            <boolean-filter #booleanFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"></boolean-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TEXT' || customField.type == 'TEXTAREA'" [clrDgFilter]="stringFilter"
            [(clrDgFilterOpen)]="stringFilter.open">
            <string-filter #stringFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]"></string-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'DATE'" [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </date-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TIME'" [clrDgFilter]="timeFilter"
            [(clrDgFilterOpen)]="timeFilter.open">
            <time-filter #timeFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </time-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'territory.name'"
        [(clrDgSortOrder)]="sorting['territory']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['territory']">
            {{localization.dictionary.territory.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="territoryFilter" [(clrDgFilterOpen)]="territoryFilter.open">
            <multiselect-filter #territoryFilter [property]="'territory.id'" [name]="localization.dictionary.territory.name"
                [query]="'crm/territory'" [toggle]="false" [(filter)]="filters['territory']">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'contacts.fieldValues.' + crm.sortField.contact"
        [(clrDgSortOrder)]="sorting['contacts']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['contacts']">
            {{localization.dictionary.contact.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="contactFilter" [(clrDgFilterOpen)]="contactFilter.open">
            <combobox-filter #contactFilter [property]="'contacts'" [name]="localization.dictionary.contact.name"
                [customFields]="true" [query]="'crm/contact/combobox/' + contactView.id" [type]="contactType"
                [(filter)]="filters['contacts']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'cusCon.position'"
        [(clrDgSortOrder)]="sorting['positions']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['positions']">
            {{localization.dictionary.customerContact.assignedPositions}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="positionFilter" [(clrDgFilterOpen)]="positionFilter.open">
            <string-filter #positionFilter [property]="'cusCon.position'" [(filter)]="filters['positions']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'products.name'"
        [(clrDgSortOrder)]="sorting['products']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['products']">
            {{localization.dictionary.product.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="productFilter" [(clrDgFilterOpen)]="productFilter.open">
            <multiselect-filter #productFilter [name]="localization.dictionary.product.name" [property]="'products'"
                [query]="'crm/product/filter'" [(filter)]="filters['products']"></multiselect-filter>

        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'lastInteraction'"
    [(clrDgSortOrder)]="sorting['lastInteraction']" (clrDgSortedChange)="this.selected = []">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['lastInteraction']">
        {{localization.dictionary.list.lastInteraction}}
    </ng-template>
    <clr-dg-filter [clrDgFilter]="lastInteractionFilter" [(clrDgFilterOpen)]="lastInteractionFilter.open">
        <date-filter #lastInteractionFilter [property]="'lastInteraction'" [(filterFrom)]="filters['lastInteractionFrom']"
            [(filterTo)]="filters['lastInteractionTo']"></date-filter>
    </clr-dg-filter>
</clr-dg-column>
<clr-dg-column [clrDgSortBy]="'nextInteraction'"
[(clrDgSortOrder)]="sorting['nextInteraction']" (clrDgSortedChange)="this.selected = []">
<ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['nextInteraction']">
    {{localization.dictionary.list.nextInteraction}}
</ng-template>
<clr-dg-filter [clrDgFilter]="nextInteractionFilter" [(clrDgFilterOpen)]="nextInteractionFilter.open">
  <date-filter #nextInteractionFilter [property]="'nextInteraction'" [(filterFrom)]="filters['nextInteractionFrom']"
      [(filterTo)]="filters['nextInteractionTo']"></date-filter>
</clr-dg-filter>
</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.createdAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updatedAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deletedAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>


    <clr-dg-column [clrDgSortBy]="'createdBy'"
    [(clrDgSortOrder)]="sorting['createdBy']" (clrDgSortedChange)="this.selected = []">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdBy']">
        {{localization.dictionary.list.createdBy}}
    </ng-template>
    <clr-dg-filter [clrDgFilter]="createdByFilter" [(clrDgFilterOpen)]="createdByFilter.open">
      <string-filter #createdByFilter [property]="'createdBy'"
      [(filter)]="filters['createdBy']"></string-filter>
    </clr-dg-filter>
</clr-dg-column>

<clr-dg-column [clrDgSortBy]="'updatedBy'"
[(clrDgSortOrder)]="sorting['updatedBy']" (clrDgSortedChange)="this.selected = []">
<ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedBy']">
    {{localization.dictionary.list.updatedBy}}
</ng-template>
<clr-dg-filter [clrDgFilter]="updatedByFilter" [(clrDgFilterOpen)]="updatedByFilter.open">
  <string-filter #updatedByFilter [property]="'updatedBy'"
  [(filter)]="filters['updatedBy']"></string-filter>
</clr-dg-filter>
</clr-dg-column>

<clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedBy'"
[(clrDgSortOrder)]="sorting['deletedBy']" (clrDgSortedChange)="this.selected = []">
<ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedBy']">
    {{localization.dictionary.list.deletedBy}}
</ng-template>
<clr-dg-filter [clrDgFilter]="deletedByFilter" [(clrDgFilterOpen)]="deletedByFilter.open">
  <string-filter #deletedByFilter [property]="'deletedBy'"
  [(filter)]="filters['deletedBy']"></string-filter>
</clr-dg-filter>
</clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let customer of customers" [clrDgItem]="customer" [clrDgSelectable]="!customer.deletedAt"
        [class.deleted]="customer.deletedAt">
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">{{ customer.id }}
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let customField of sectionFields"
            [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            <ng-container *ngFor="let fieldValue of customer[customField.name]; let i = index">
                <ng-container *ngIf="customField.type != 'TIME' && customField.type != 'DATE' && customField.type != 'BOOLEAN'">
                    <span
                        [class.deleted-option]="customField?.fieldOptions && customField.fieldOptions.length > 0 && !fieldValue.fieldOption">
                        {{(fieldValue.value || '') + (i < customer[customField.name].length - 1? ',' : '' )}} </span>
                </ng-container>
                <ng-container *ngIf="customField.type == 'BOOLEAN'">
                    {{ fieldValue.value == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no }}
                </ng-container>
                <ng-container *ngIf="customField.type == 'TIME'">
                    {{ fieldValue.value | date: localization.dictionary.dateFormat.time : undefined : localization.language.selector }}
                </ng-container>
                <ng-container *ngIf="customField.type == 'DATE'">
                    {{ fieldValue.value | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
                </ng-container>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            <div *ngIf="customer.territory">
                <span class="label label-light-blue"
                    [pTooltip]="customer.territory.description"
                    tooltipPosition="top">
                    {{ customer.territory.name }}
                </span>
            </div>
        </clr-dg-cell>

        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            <div *ngFor="let contact of customer.contacts" class="tooltip-container">
                <span class="label label-light-blue"
                    [pTooltip]="contact?.CustomerContact?.position"
                    tooltipPosition="top">
                    <app-display-name [model]="contact" [prePostFix]="''"></app-display-name>
                </span>
            </div>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            <div *ngFor="let contact of customer.contacts">
                <ng-container *ngIf="contact.CustomerContact.position">
                    <span class="label label-purple"
                        [pTooltip]="localization.dictionary.customerContact.ofContact + crm.getDisplayValue(contact) + '' "
                        tooltipPosition="top">
                        {{ contact.CustomerContact.position }}
                    </span>
                </ng-container>
            </div>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="setting.featureActiveList['crmActive']"
            [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            <span *ngFor="let product of customer.products"
                class="label label-light-blue"
                [pTooltip]="product.description"
                tooltipPosition="top">
                {{ product.name }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
          {{ customer.lastInteraction | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
          {{ customer.nextInteraction | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            {{ customer.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            {{ customer.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted"
            [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            {{ customer.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
          {{ customer.createdBy }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
            {{ customer.updatedBy }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted"
        [routerLink]="customer.deletedAt? null : ['/crm/customer/edit/' + customer.id]">
        {{ customer.deletedBy }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="!customer.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="pencil"
                [routerLink]="['/crm/customer/edit/' + customer.id]"
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.customer.nameSingular).replace('${entryName}',crm.getDisplayValue(customer))"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon
                shape="two-way-arrows"
                [pTooltip]="localization.dictionary.interaction.last"
                tooltipPosition="left"
                [routerLink]="['/crm/customer/edit/' + customer.id]"
                [state]="{ tab: 'interactions'}">
            </clr-icon>

            <clr-icon
                shape="talk-bubbles"
                [routerLink]="['/crm/call/create/' + customer.id]"
                [pTooltip]="helper.getCardHeader('create', 'call', '')"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon
                shape="file"
                [pTooltip]="localization.dictionary.list.showAuditLogs"
                tooltipPosition="left"
                (click)="openAuditLogModal(customer.id)">
            </clr-icon>

            <clr-icon
                shape="trash"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry
                    .replace('${componentName}', localization.dictionary.customer.nameSingular)
                    .replace('${entryName}', crm.getDisplayValue(customer))"
                tooltipPosition="left"
                (click)="onDelete([customer])">
            </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="customer.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon 
                shape="backup-restore"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.customer.nameSingular).replace('${entryName}', crm.getDisplayValue(customer))"
                tooltipPosition="left"
                (click)="openRestoreModal(customer)">
            </clr-icon>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.customer.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.customer.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.customer.nameSingular : localization.dictionary.customer.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body" *ngIf="toDeleteContacts?.length > 0">
        {{ localization.dictionary.customerList.followingContacts }}
        <ul>
            <li *ngFor="let contact of toDeleteContacts">
                <app-display-name [model]="contact"></app-display-name>
                {{ localization.dictionary.customerList.ofCustomers }} (<span *ngFor="let customer of contact.customers; let i = index">
                <app-display-name  [model]="customer"></app-display-name>{{(i < contact.customers.length - 1 ? ',' : '')}}
               </span>
               )
            </li>
        </ul> {{ localization.dictionary.customerList.willBeDeleted }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{ localization.dictionary.button.delete }}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title" *ngIf="selectedCustomer">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.customer.nameSingular).replace('${entryName}',  crm.getDisplayValue(selectedCustomer))}}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        {{ localization.dictionary.customerList.deleteModalDescription }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="importModal" [clrModalStaticBackdrop]="false">
    <h3 class="modal-title">{{ localization.dictionary.excel.selectViews }}</h3>
    <div class="modal-body">
        <clr-select-container>
            <label for="cust">{{localization.dictionary.excel.customerView}}</label>
            <select clrSelect name="options" [(ngModel)]="selectedCustomerView" >
                <option *ngFor="let view of views" [ngValue]="view">{{view.name}}</option>
            </select>
        </clr-select-container>
        <clr-select-container>
            <label for="cust">{{localization.dictionary.excel.contactView}}</label>
            <select clrSelect name="options" [(ngModel)]="selectedContactView">
                <option *ngFor="let view of contactViews" [ngValue]="view">{{view.name}}</option>
            </select>
        </clr-select-container>
            <p>{{localization.dictionary.excel.templateImportDescription}}</p>
            <div   class="modal-footer">
                <button type="button" [disabled]="!(selectedCustomerView && selectedContactView)" class="btn btn-outline" (click)="downloadInitialExcelTemplate();">{{ localization.dictionary.button.template }}</button>
                <button type="button" [disabled]="!(selectedCustomerView && selectedContactView)" class="btn btn-primary" (click)="openInitialTemplateImport()">{{ localization.dictionary.button.import }}</button>
            </div>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="loadingModal" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
    <h3 class="modal-title">{{localization.dictionary.excel.loadingHeader}}</h3>
    <div class="modal-body" style="overflow: hidden;">
        <p style="margin-top: 0;">{{localization.dictionary.excel.loading}}</p>
        <div style="width: 100%; height: 2rem; display: flex; justify-content: center; align-items: end;">
            <p style="margin-top: 0;">
                <span class="spinner spinner-inline">
                    Loading...
                </span>
                <span>
                    Loading...
                </span>
            </p>
        </div>
    </div>

</clr-modal>

<input type="file" id="initialTempImp"  name="initialExcel" accept=".xlsx"
placeholder="Enter value here" class="clr-file"/>

<clr-modal [(clrModalOpen)]="exportModal">
    <h3 class="modal-title">{{localization.dictionary.list.excelModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.list.excelModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="downloadExcel();">{{localization.dictionary.button.excelWithFilter}}</button>
        <button type="button" class="btn btn-outline" (click)="downloadExcel('?skip=0&limit=0')">{{localization.dictionary.button.excelWithoutFilter}}</button>
    </div>
</clr-modal>


<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/crm/customer/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.customer.nameSingular) }}</button>
            <button class="btn btn-primary" [disabled]="total === 0 || loading" (click)="checkExportType()">
                {{ localization.dictionary.button.excelExport }}
            </button>
            <button class="btn btn-primary" [disabled]="loading" (click)="importModal = true">
                {{ localization.dictionary.button.excelImport }}
            </button>
            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-outline" *ngIf="selected.length > 1"
                (click)="crm.multiUpdateOpen = true">{{localization.dictionary.button.updateMultiple}}</button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="onDelete(selected)">{{ localization.dictionary.button.delete }}</button>
        </clr-dg-action-bar>
        <div fxLayout="row" fxLayoutAlign="flex-start flex-end">
            <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['crm-editor', 'admin'])">
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                        (ngModelChange)="toggleDeleted()" />
                    <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.customer.name)}}</label>
                </clr-toggle-wrapper>
            </clr-toggle-container>
            <clr-dropdown>
                <button class="view dropdown-toggle" clrDropdownTrigger>
                    <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                    <clr-icon shape="caret down" size="20"></clr-icon>
                </button>
                <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                    <label class="dropdown-header" aria-hidden="true">{{localization.dictionary.view.name}}</label>
                    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                    <div *ngFor="let view of views" (click)="activeView = view; activeViewChange(view)"  clrDropdownItem>
                        <clr-icon [ngStyle]="{'visibility': (view.id === activeView.id) ? 'visible' : 'hidden' }"
                            style="margin-right: 0.3rem" shape="check"></clr-icon>
                        {{ view.name }}
                    </div>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </div>

</ng-template>

<clr-modal [(clrModalOpen)]="auditLogModalOpen" clrModalSize="xl">
    <h3 class="modal-title">{{localization.dictionary.auditLogging.changeHistory}}</h3>
    <div class="modal-body">
        <app-audit [entityId]="entityId" [entityType]="entityType"></app-audit>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="auditLogModalOpen=false;">{{localization.dictionary.button.ok}}</button>
    </div>
</clr-modal>

<app-multi-update-crm type="customer" [(multiUpdateOpen)]="crm.multiUpdateOpen" (multiUpdateOpenChange)="multiModel = {}" (rowsChange)="refresh()" [(rows)]="selected" [model]="multiModel" [activeView]="activeView"></app-multi-update-crm>
