import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';
import { patternList } from '../../../crm/fields/pattern-list';
import { Question, QuestionInputType } from '../../question.model';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  @Input() active: boolean;
  @Input() question: Question;
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  tempInput: QuestionInputType;

  patterns = {
    NONE: '',
    NAME: patternList.name,
    EMAIL: patternList.email,
    POSTALCODE: patternList.postal_code,
    PHONENUMBER: patternList.phonenumber,
    CUSTOM: ''
  }

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
    if(this.question.inputType) {
      this.tempInput = this.question.inputType;
    }else if (this.question.refField?.pattern) {
      for (let patternKey in QuestionInputType) {
        if (this.patterns[patternKey] == this.question.refField.pattern) {
          this.tempInput = patternKey as QuestionInputType;
        }
      }
      if (!this.tempInput) {            
        this.tempInput = QuestionInputType.CUSTOM;
      }
    }
  }

  setInputType() {
    if (this.tempInput === 'NONE') {
      this.question.inputType = undefined;
    } else {
      this.question.inputType = this.tempInput;
    }
    this.changed.emit(true);
  }
}
