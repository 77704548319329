import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Unsubscribable } from '../../../helper/unsubscribable/unsubscribable';
import { OfferedAnswer } from '../../offered-answer.model';
import { Question, QuestionType } from '../../question.model';

@Component({
  selector: 'app-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.css']
})
export class ChoiceComponent extends Unsubscribable implements OnInit {
  @Input() active: boolean;
  @Input() type: QuestionType;
  @Input() question: Question;
  @Input() fieldReference: boolean;
  @Input() published: boolean;
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  answers: OfferedAnswer[] = [];

  @ViewChildren('answerInput') inputFields: QueryList<ElementRef>;

  constructor(public localization: LocalizationService) { 
    super();
  }

  ngOnInit(): void {
    if (this.question) {
      this.answers = this.question?.offeredAnswers;
    }

  }

  ngAfterViewInit() {

    // focus latest input field after new answer creation
    this.subscriptions.add(
      this.inputFields.changes.subscribe(c => {
        if (c && c.last) {
          c.last.nativeElement.focus();
          this.changed.emit(true);
        }
      })
    );
  }

  addNewAnswer() {
    let oA = new OfferedAnswer(true);
    oA.value = '';
    oA.order = this.answers.length;
    this.answers.push(oA);
  }

  async drop(event: CdkDragDrop<OfferedAnswer[]>) {
    moveItemInArray(this.answers, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.answers.length; i++) {
      this.answers[i].order = i;
    }
  }

  delete(i) {
    this.answers.splice(i, 1);
  }

}
