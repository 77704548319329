<clr-icon *ngIf="isActive()" class="refresh-filter is-solid" shape="filter-off" (click)="onClick(true)"></clr-icon>
<clr-checkbox-container (change)="onChanged()">
    <label>{{this.localization.dictionary.filter.searched.replace("${name}",name)}}</label>
    <clr-checkbox-wrapper *ngFor="let item of availableList; let i = index">
        <input type="checkbox" clrCheckbox [name]="'item' + i" [value]="array? item : item[searchBy]"
            [(ngModel)]="selectedItems[i]" />
        <label>{{ getDisplayName(item) }}</label>
    </clr-checkbox-wrapper>
</clr-checkbox-container>
<clr-toggle-container *ngIf="!array && toggle">
    <label>{{this.localization.dictionary.filter.allRequired.replace("${name}", name)}}</label>
    <clr-toggle-wrapper>
        <input type="checkbox" clrToggle name="options" value="AndOr" [(ngModel)]="andToggle"
            (ngModelChange)="andOrChange($event)" />
        <label>{{ andToggle? localization.dictionary.general.yes : localization.dictionary.general.no}}</label>
    </clr-toggle-wrapper>
</clr-toggle-container>
<button *ngIf="confirm" class="btn btn-primary btn-sm" (click)="onClick()">{{localization.dictionary.button.confirm}}</button>