import { Exclude, Expose, Type } from "class-transformer";
import { MetaData } from "src/app/helper/meta-data.class";
import { Customer } from "../customers/customer.model";

@Exclude()
export class Product extends MetaData {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    description: string;

    @Expose()
    itemNumber: string;

    @Expose()
    price: number;

    @Expose()
    unit: string;

    @Expose()
    active: boolean;

    @Expose()
    picture: string;

    @Expose()
    @Type( () => Customer)
    customers: Customer[] = [];

    @Expose()
    @Type( () => Document)
    documents: Document[] = [];

}