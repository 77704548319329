import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "./auth.service";

export const BYPASS_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshingInProgress = false;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private endingImpersonation = false;


  constructor(
    private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(BYPASS_INTERCEPTOR) === true) {
      return next.handle(req);
    }      
    const userJSON = localStorage.getItem('amsel-user');
    const user = this.authService.impersonater ? this.authService.user : (userJSON ? JSON.parse(userJSON) : undefined);
    const accessToken = (user) ? user.accessToken : undefined;

    return next.handle(this.addAuthorizationHeader(req, accessToken)).pipe(
      catchError(err => {

        // in case of 401 http error
        if (err instanceof HttpErrorResponse && err.status === 401) {

          // get refresh tokens
          const refreshToken = user ? user.refreshToken : undefined;
          // if there are tokens then send refresh token request
          if (refreshToken && accessToken) {
            return this.refreshToken(req, next);
          }

          // otherwise logout and redirect to login page
          return this.logoutAndRedirect(err);
        }

        // in case of 403 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err.status === 403) {
          // logout and redirect to login page
          return this.logoutAndRedirect(err);
        }
        // if error has status neither 401 nor 403 then just return this error          
        return throwError(err);
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return request;
  }

  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    if (this.authService.impersonater) {
      this.endingImpersonation = true;
      this.authService.endImpersonation()
      setTimeout(() => this.endingImpersonation = false, 1000)
    }
    else if (!this.endingImpersonation)
      this.authService.logout();

    return throwError(err);
  }

  /**
   * 
   * @param request 
   * @param next 
   * @returns 
   */
  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
        this.refreshingInProgress = true;
        this.accessTokenSubject.next(null);
  
        return this.authService.refreshToken().pipe(
          switchMap((res) => {
            this.refreshingInProgress = false;
            this.accessTokenSubject.next(res.access_token);
            // repeat failed request with new token
            return next.handle(this.addAuthorizationHeader(request, res.access_token));
          })
        );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          this.refreshingInProgress = false;
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, token));
        }));
    }
  }
}
