import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ClrDatagrid, ClrDatagridStateInterface } from "@clr/angular";
import { FileUpload } from "../file-upload.model";
import { LocalizationService } from "../../../localization/localization.service";
import { ServerService } from "../../../server.service";
import { plainToClass } from "class-transformer";
import { Customer } from "../../customers/customer.model";
import { AuthService } from "src/app/auth/auth.service";
import { FileUploadService } from "../file-upload-service";
import { AmselError } from "../../../helper/error/amsel-error.model";
import { CrmService } from "../../crm.service";
import { View } from "../../views/view.model";
import { Call } from "../../calls/call.model";
import { ErrorService } from "../../../helper/error/error.service";

@Component({
  selector: "app-file-upload-list",
  templateUrl: "./file-upload-list.component.html",
  styleUrls: ["./file-upload-list.component.css"]
})
export class FileUploadListComponent implements OnInit, OnChanges {
  @Input() mode: string = 'default';
  @Input() customerId: string;
  @Input() callId: string;
  customerType = Customer;
  callType = Call;
  componentId: string;
  customerView: View;
  callView: View;
  uFiles: FileUpload[];
  selectedUFiles: FileUpload[] = [];
  toDelete: FileUpload[];
  loading: boolean;
  saving = false;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    email: true,
    formOfAddress: true,
    lastLogin: true,
    createdAt: false,
    updatedAt: true,
    serviceAccount: true
  };
  state: ClrDatagridStateInterface;

  showDeleted = false;
  selectedFile: FileUpload;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  @ViewChild("datagridRef") datagrid: ClrDatagrid;
  deleteFilters: boolean;

  queryArray = [
    'pdf',
    'png',
    'jpg',
    'jpeg',
    'txt',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx'
  ]

  constructor(
    public localization: LocalizationService,
    private server: ServerService,
    public auth: AuthService,
    public fus: FileUploadService,
    public crm: CrmService,
    private errorService: ErrorService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.customerView = this.crm.activeViews.customer;
    this.callView = this.crm.activeViews.call;
    this.componentId = this.mode == "customer" ? this.customerId : this.mode == "call" ? this.callId : '1';
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "customerId" || propName == "callId") {
          this.resetFilters();
        }
      }
    }
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = (<any>this.datagrid)?.stateProvider?.state;
    } if (!state) {
      return;
    }
    if (state.page?.from == -1 || state.page?.to == -1) {
      state.page.from = 0;
      state.page.to = 10;
      state.page.size = 10;
    }
    this.state = state;
    this.loading = true;
    try{
      let query = this.server.buildQueryFromGrid(state);
      const file = await this.server.get<FileUpload>(`crm/file-upload/${this.mode}/${this.componentId}` + query);
      this.total = file.count;
      this.uFiles = plainToClass(FileUpload, file.rows);
    } catch(err){
      this.errorService.handleError(err);
    }  
    this.loading = false;    
  }

  async resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid?.columns?.find(column => column.sorted);
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    if (this.state) {
      delete this.state.filters;
      delete this.state.sort;
    }
    this.deleteFilters = true;
    await this.refresh(this.state);
  }

  async loadFiles() {
    this.loading = true;
    this.showDeleted = !!localStorage.getItem("uFile-deleted");
    try{
      let res = JSON.stringify(await this.server.get("crm/file-upload/" + this.mode + "/" + this.componentId));
      this.uFiles = JSON.parse(res);
      this.total = this.uFiles.length;
    } catch (err){
      this.errorService.handleError(err);
    }
    
    this.loading = false;
  }

  selectionChange(uFiles: FileUpload[]) {
    this.selectedUFiles = uFiles;
  }

  hidChange() {
    if (this.columnChanged) return;
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false;
    });
  }

  async getFile(uFile: FileUpload) {
    this.server.downloadUFile(uFile);
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const fileIds = this.toDelete.map(file => file.id);
    try{
      await this.server.delete("crm/file-upload/" + this.mode + "?toDelete=" + fileIds);
    } catch (err) {
      this.errorService.handleError(err);
    }
    this.server.addAlert(
      new AmselError(
        undefined,
        "success",
        this.localization.dictionary.toastr.successDeleted.replace(
          "${componentName}",
          this.toDelete.length == 1
            ? this.localization.dictionary.file.nameSingular
            : this.localization.dictionary.file.name
        )
      )
    );
    this.toDelete = undefined;
    this.selectedUFiles = [];
    this.saving = false;
    this.refresh();
  }
}
