import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { TrackingService } from '../../tracking.service';
import { EventType } from '../../trackingData.model';
import { WidgetInterface } from '../widget.interface';
import { curveBasis } from 'd3-shape';
import { BrandingService } from 'src/app/branding/branding.service';
import { ModalService } from '../../dashboards/diagram-popup/modal-data.service';

@Component({
  selector: 'app-widget-user-interaction',
  templateUrl: './widget-user-interaction.component.html',
  styleUrls: ['./widget-user-interaction.component.css']
})
export class WidgetUserInteractionComponent implements OnInit, WidgetInterface {
  @ViewChild('chart') chart: ElementRef;

  loading = false;  
  id: string;
  isOpen = false;

  data = [];
  curve = curveBasis;
  isDarkMode: boolean;
  widgetTitel: string;

  constructor(private tracking: TrackingService, 
              public helper: HelperService,
              private brandingService: BrandingService,
              public localization: LocalizationService,
              public modalService: ModalService) {
                this.helper.loading$.subscribe(({ id, loading }) => {
                  if (id === 'userInteraction') {
                    this.loading = loading;
                  }
                });
               }

  async ngOnInit(): Promise<void> {
    await this.refresh();
    this.isDarkMode = this.brandingService.darkMode;
    this.widgetTitel = this.localization.dictionary.dashboard.userActivities;
  }

  async refresh(from?: Date, to?: Date) {  
    this.loading = true;  
    this.data = [];
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
    const groupedEvents = await this.tracking.getGroupedEvents('?' + query.toString()) as Object;
    
    for (let action in EventType) { 
      if (action == 'TOTAL') {
        continue;
      }        
      let series = [];
      for (let month of Object.keys(groupedEvents)) { 
               
        let countEl = groupedEvents[month].find( (ev) => ev.eventType == action );
        let seriesEl = {
          name: month,
          value: 0
        }
        if (countEl) {
          seriesEl.value = countEl.count;
        }  
        series.push(seriesEl);      
      }
      this.data.push({
        name: this.localization.dictionary.legendTranslation[action],
        series: series.sort( (a, b) => a.name <= b.name ? -1 : 1)
      })
    }
    // Ersetzen nach Sortierung
    for (let data of this.data) {
      for (let series of data.series) {
        series.name = this.helper.getDateStringShort(series.name);
      }
    }
    this.loading = false;
  }

}
