<div class="card widget-card" style="margin-top: 0 !important; max-height: 100%;">
  <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
    <span>{{localization.dictionary.dashboard.last10UserSync}}</span>    
  </div>
  <div class="card-block">
    <div class="card-text"> 
      <ng-container *ngIf="!loading; else spinner">
        <table class="table table-compact" *ngIf="trackingData.length > 0; else noData">
          <thead>
            <tr>
              <th class="left">{{localization.dictionary.general.date}}</th>
              <th class="left">{{localization.dictionary.user.nameSingular}}</th>
              <th class="left">{{localization.dictionary.dashboard.device}}</th>
              <th class="left">{{localization.dictionary.dashboard.action}}</th>
              <th class="left">{{localization.dictionary.dashboard.status}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tracking of trackingData">
              <td class="left">{{ tracking.date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}</td>
              <td class="left" [class.deleted]="tracking.user.deletedAt">{{ tracking.user.name + (tracking.user.deletedAt? ' (' + localization.dictionary.list.deleted + ')' : '') }}</td>
              <td class="left">{{ deenum(tracking.deviceType) }}</td>
              <td class="left">{{ tracking.eventType }}</td>
              
                  <ng-container *ngIf="tracking.value === 'SUCCESS' else sync">
                      <td class="left success">
                          <clr-icon [attr.shape]="'success'"></clr-icon>
                          {{localization.dictionary.dashboard.successfully}}
                      </td>
                  </ng-container>
                  <ng-template #sync>
                      <td class="left failure">
                          <clr-icon [attr.shape]="'times'"></clr-icon>
                          {{localization.dictionary.dashboard.failed}}
                      </td>
                  </ng-template>            
            </tr>          
          </tbody>
        </table>
      </ng-container>
      
      <ng-template #spinner>
        <app-spinner></app-spinner>
      </ng-template>

      <ng-template #noData>
        <app-no-data></app-no-data>
      </ng-template>
    </div>
  </div>
</div>

