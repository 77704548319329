<div class="card" [ngClass]="data.length > 0 ? 'diagram' : ''" fxLayout="column" fxLayoutAlign="flex-start" >
    <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{localization.dictionary.dashboardInteractions.interactionsByOsTotal.replace('${total}', '' + total)}}</span>
    </div>
    <div class="card-block">
      <div class="card-text">
        <app-filter-from-to (update)="refresh($event.from, $event.to)"></app-filter-from-to>
        <ng-container *ngIf="!loading; else spinner">
          <ngx-charts-bar-vertical-2d
            class="widget-text"
            [legend]="true"
            [legendTitle]="''"           
            [results]="data"            
            [xAxis]="true"
            [yAxis]="true"       
            >
          </ngx-charts-bar-vertical-2d>
        </ng-container>

        <ng-template #noData>
          <app-no-data></app-no-data>
        </ng-template>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </div>
    </div>
    <div [ngStyle]="data.length > 0 ? {'visibility' : 'visible'} : {'visibility' : 'hidden'}" class="card-footer">
      <app-footer-widgets  [chart]="chart"></app-footer-widgets>
    </div>
</div>
