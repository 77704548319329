import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../localization/localization.service';

@Component({
  selector: 'app-help-forms-create',
  templateUrl: './help-forms-create.component.html',
  styleUrls: ['../help.css']
})
export class HelpFormsCreateComponent implements OnInit {

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
  }

}
