import { Expose, Transform, Type } from "class-transformer";
import { Group } from "../../groups/group.model";
import { User } from "../../users/user.model";
import { Customer } from "../customers/customer.model";

export class Territory {
    @Expose()
    id: string;
    @Expose()
    baseId: string;
    @Expose()
    name: string;
    @Expose()
    description: string;
    @Expose()
    parent: Territory;
    @Expose()
    children: Territory[] = [];
    @Expose()
    users: User[];

    @Expose()
    @Transform((val, obj) => {
        let result: User[] = [];
        obj.groups?.forEach(group => {
            if (!group.deletedAt)
                group.users?.forEach(user => {
                    const index = result.find(value => value.name == user.name)
                    if (index)
                        index.groupNames.push(group.name)
                    else {
                        user.groupNames = [group.name]
                        result.push(user)
                    }
                })
        })
            obj.users = obj.users?.filter(user => !result.find(fullUser => fullUser.name == user.name))
            obj.users?.forEach(user => user.direct = true);
            return result.concat(obj.users)
    })
    fullUsers: User[];

    @Expose()
    groups: Group[];
    @Expose()
    @Type( () => Customer)
    customers: Customer[];
    @Expose()
    direct?:boolean;
    @Expose()
    groupNames?: string[];
}