<div>
    <div class="card" *ngIf="!loading">
        <div class="card-header">
            {{ helper.getCardHeader(mode, 'user', user.name) }}
            <!--
                        <button class="btn btn-sm" (click)="loadTestUser()">lade debug</button> 
                        
                        <button class="btn btn-sm" (click)="test()">lade debug</button>
                        -->
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.createEdit.baseInformationDescription.replace('${componentDative}', localization.dictionary.user.dative)}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                (click)="collapse[0] = !collapse[0]"></clr-icon>
            </div>
            <div [hidden]="collapse[0]" class="card-text">
                <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset" autocomplete="off">
                    <clr-input-container>
                        <label>{{localization.dictionary.user.username + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="user.name" name="name" autocomplete="nopeName"
                            pattern='\S*' required maxlength="100"/>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error *clrIfError="'duplicate'">{{localization.dictionary.userCreateEdit.duplicateUsername}}
                        </clr-control-error>
                        <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.userCreateEdit.noSpacesUsername}}
                        </clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{localization.dictionary.userCreateEdit.emailAddress + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="user.email" name="email" autocomplete="nopeEmail"
                            [pattern]="emailPattern" required maxlength="100"/>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <!-- <clr-control-error *clrIfError="'email'">Eingabe muss eine gültige E-Mail sein
                                    </clr-control-error> -->
                        <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.userCreateEdit.validEmail}}
                        </clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{localization.dictionary.user.password + '*'}}</label>
                        <input clrInput type="password" [(ngModel)]="user.password" name="password"
                            autocomplete="new-password" [required]="mode === 'create'" maxlength="100"/>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error *clrIfError="'minlength'">{{localization.dictionary.userCreateEdit.passwordRequirements}}
                        </clr-control-error>
                    </clr-input-container>
                    
                    <clr-select-container>
                        <label>{{localization.dictionary.user.formOfAddress + '*'}}</label>
                        <select clrSelect [(ngModel)]="user.formOfAddress" name="formofaddress" required>
                            <option value="mr">
                                <span style="margin-left: 0.2rem">{{localization.dictionary.userFormOfAddress.mr}}</span>
                            </option>
                            <option value="ms">
                                <span style="margin-left: 0.2rem">{{localization.dictionary.userFormOfAddress.ms}}</span>
                            </option>
                            <option value="other">
                                <span style="margin-left: 0.2rem">{{localization.dictionary.userFormOfAddress.other}}</span>
                            </option>
                        </select>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-select-container>

                    <clr-input-container>
                        <label>{{localization.dictionary.user.firstName + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="user.firstName" name="fistName"
                            autocomplete="nopeFirstName" [pattern]="namePattern" required maxlength="100"/>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.userCreateEdit.validName}}
                        </clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{localization.dictionary.user.lastName + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="user.lastName" name="lastName"
                            autocomplete="nopeLastName" [pattern]="namePattern" required maxlength="100"/>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.userCreateEdit.validName}}
                        </clr-control-error>
                    </clr-input-container>

                    <clr-checkbox-container *ngIf="auth.hasRole(['admin'])">
                        <label>{{localization.dictionary.user.serviceAccount}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" [(ngModel)]="user.serviceAccount" name="serviceAccount" clrCheckbox/>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </form>
            </div>
        </div>

        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="users" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.group.name)}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.userCreateEdit.assignGroupsDescription}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[1] ? 'down' : 'left'"
                (click)="collapse[1] = !collapse[1]"></clr-icon>
            </div>
            <div [hidden]="collapse[1]" class="card-text icon-offset">
                <app-list-selector [excludeAdmin]="true" (selectedChanged)="user.groups = $event"
                    [preLoaded]="user.groups" [label]="localization.dictionary.group.name" [query]="'group'">
                </app-list-selector>
            </div>
        </div>


        <div *ngIf="setting.featureActiveList['crmActive']" class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="map" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.territory.name)}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.userCreateEdit.assignTerritoriesDescription}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[2] ? 'down' : 'left'"
                (click)="collapse[2] = !collapse[2]"></clr-icon>
            </div>
            <div [hidden]="collapse[2]" class="card-text icon-offset">
                <app-list-selector [excludeAdmin]="true" (selectedChanged)="user.territories = $event"
                    [preLoaded]="user.territories" [label]="localization.dictionary.territory.name" [query]="'crm/territory/forUser'">
                </app-list-selector>
            </div>

        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="key" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.role.name)}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.userCreateEdit.assignRolesDescription}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[3] ? 'down' : 'left'"
                (click)="collapse[3] = !collapse[3]"></clr-icon>
            </div>
            <div [hidden]="collapse[3]" class="card-text icon-offset">
                <app-list-selector [excludeAdmin]="true" (selectedChanged)="user.roles = $event"
                    [preLoaded]="user.roles" [label]="localization.dictionary.role.name" [query]="'roles'"></app-list-selector>
            </div>
        </div>
        <div stickyFooter class="card-footer">
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>
        </div>
    </div>
</div>

    <app-spinner *ngIf="loading"></app-spinner>

    <ng-template #actionBar>
        <button class="btn btn-primary" type="submit" (click)="validateAndSave()" [disabled]="loading">
            <span>{{ ( mode=== 'create' ) ? localization.dictionary.button.create : localization.dictionary.button.save}}</span>
        </button>
        <button *ngIf="mode === 'edit' && auth.hasRole(['admin']) && auth.user.id != user.id" class="btn btn-outline"
            (click)="impersonate()">{{localization.dictionary.userCreateEdit.impersonate}}</button>
        <button type="button" class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
    </ng-template>