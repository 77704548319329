<div class="card" *ngIf="!loading">
    <div class="card-header">
        {{ helper.getCardHeader(mode, 'category', category.name) }}
    </div>
    <div class="card-block">
        <div class="card-title collapse-card">
            <span>
                <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.categoryCreateEdit.baseInformationDescription}}
                </p>
            </span>
            <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                (click)="collapse[0] = !collapse[0]"></clr-icon>
        </div>
        <div [hidden]="collapse[0]" class="card-text">
            <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                <clr-input-container>
                    <label>{{localization.dictionary.general.name}}*</label>
                    <input clrInput type="text" [(ngModel)]="category.name" name="name" required fileNameValidator maxlength="100" />
                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    <clr-control-error *clrIfError="'forbiddenFileName'">{{localization.dictionary.documentCreateEdit.invalidName}}</clr-control-error>
                </clr-input-container>
                <clr-textarea-container>
                    <label>{{localization.dictionary.general.description}}*</label>
                    <textarea clrTextarea [(ngModel)]="category.description" name="description" required></textarea>
                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                </clr-textarea-container>

                <clr-combobox-container>
                    <label fxLayout="row" fxLayoutAlign="space-between center">Icon* <clr-icon *ngIf="currentIcon"
                            [attr.shape]="currentIcon?.element"></clr-icon></label>
                    <clr-combobox #combobox [(ngModel)]="currentIcon" (clrInputChange)="checkValid($event, combobox)" name="icon" [required]="iconRequired">
                        <clr-options>
                            <clr-option *clrOptionItems="let icon of localization.amselIconList; field: 'label'" [clrValue]="icon">
                                <clr-icon [attr.shape]="icon.element" role="img"></clr-icon>
                                <span style="margin-left: 0.2rem">{{ icon.label }}</span>
                            </clr-option>
                        </clr-options>
                    </clr-combobox>
                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                </clr-combobox-container>
                <clr-checkbox-container>
                    <label>{{localization.dictionary.general.active}}</label>
                    <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox [value]="true" name="active"
                            [(ngModel)]="category.isActive" />
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <clr-checkbox-container>
                    <label>{{localization.dictionary.category.inMainMenu}}</label>
                    <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox [value]="true" name="inMainMenu"
                            [(ngModel)]="category.inMainMenu" />
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </form>
        </div>
    </div>
    <div class="card-block">
        <div class="card-title collapse-card">
            <span style="width: 90%;">
                <clr-icon shape="users" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.group.name)}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.categoryCreateEdit.assignGroupsDescription}}
                    <!-- <i>{{localization.dictionary.categoryCreateEdit.assignGroupsNotice}}</i> -->
                </p>
            </span>
            <clr-icon shape="angle" class="arrow" [dir]="!collapse[1] ? 'down' : 'left'"
                (click)="collapse[1] = !collapse[1]"></clr-icon>
        </div>
        <div [hidden]="collapse[1]" class="card-text icon-offset">
            <app-list-selector (selectedChanged)="category.groups = $event" [preLoaded]="category.groups"
                [label]="localization.dictionary.group.name" [query]="'group'"></app-list-selector>
        </div>
    </div>
    <div stickyFooter class="card-footer">
        <button class="btn btn-primary" (click)="formData.valid ? save() : unfold() ">{{ ( mode === 'create' )
            ? localization.dictionary.button.create : localization.dictionary.button.save }}</button>
        <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>
