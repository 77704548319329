import { Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { ErrorService } from 'src/app/helper/error/error.service';
import { ServerService } from 'src/app/server.service';
import { LocalizationService } from '../../localization/localization.service';
import { User } from '../user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  oldPassword: string;
  newPassword: string;
  loading = true;

  constructor(public auth: AuthService, public server: ServerService, private errorService: ErrorService, public localization: LocalizationService) { }

  async ngOnInit(): Promise<void> {
    const user = await this.server.get('user/profile');    
    this.auth.user = plainToClass(User, user);
    this.loading = false;
  }

  async save() {
    this.loading = true;
    const userUpdated = await this.server.put(`user/profile/${this.oldPassword}`, this.auth.user);
    this.server.addAlert(new AmselError(undefined, 'success',
    this.localization.dictionary.toastr.successSaved
    .replace('${componentName}', this.localization.dictionary.header.deleteModalDescription)
    .replace('${entryName}', this.auth.user.name)));
    if (userUpdated) {
      this.oldPassword = '';
      this.newPassword = '';
    } else {
      // this.errorService.errors.push(new AmselError())
    }
    this.loading = false;
  }

  isValid(): boolean {
    let valid = false;
    if (this.auth.user.password && this.oldPassword && this.auth.user.password === this.newPassword) {
      valid = true;
    } 
    return valid;
  }

}
