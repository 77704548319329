import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { AuthService } from '../../../auth/auth.service';
import { ExcelService } from '../../../excel.service';
import { DatagridFunctions } from '../../../helper/datagrid-functions.class';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { CrmService } from '../../crm.service';
import { Field } from '../../fields/field.model';
import { View } from '../../views/view.model';
import { Contact } from '../contact.model';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../customers/customer.model';

@Component({
  selector: 'app-list-contacts',
  templateUrl: './list-contacts.component.html',
  styleUrls: ['./list-contacts.component.css']
})
export class ListContactsComponent implements OnInit, OnDestroy, DatagridFunctions {
  customerType = Customer;
  contacts: Contact[];
  selected: Contact[] = [];
  toDelete: Contact[];
  loading = true;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  prefiltered: boolean = false;
  hidden: {[column: string]: boolean} = {
    id: true,
    createdAt: true,
    updatedAt: true,
    createdBy: true,
    updatedBy: true,
    deletedBy: true,
  }
  state: ClrDatagridStateInterface;
  exportState: ClrDatagridStateInterface;
  saving = false;

  showDeleted = false;
  selectedContact: Contact;
  restoreModal = false;

  exportModal = false;

  columnChange = false;
  columnChanged = false;

  customerViews: View[];
  views: View[];
  activeView: View;
  customerView: View;
  sectionFields: Field[];

  multiUpdate = false;
  multiModel = {};

  auditLogModalOpen = false;
  entityId: string;
  entityType = 'Contact';

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(public server: ServerService,
              public auth: AuthService,
              public helper: HelperService,
              public crm: CrmService,
              public excelService: ExcelService,
              public localization: LocalizationService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    const dgOptions = this.crm.getDatagridOptions('contact');
    this.filters = dgOptions.filters;
    this.hidden = dgOptions.hidden;
    this.sorting = dgOptions.sorting;
    if (this.route.queryParams['_value'] && Object.keys(this.route.queryParams['_value']).length > 0) {
      this.prefiltered = true;
      this.filters = this.route.queryParams['_value'];
    }
    this.crm.setActiveViewsFromStorage(['contact']);
    this.views = this.crm.views.contact;
    this.customerViews = this.crm.views.customer;
    this.activeView = this.crm.activeViews.contact;
    this.customerView = this.crm.activeViews.customer;
    this.activeViewChange();
  }

  activeViewChange(ev?) {
    this.sectionFields = [];
    this.cdr.detectChanges();
    if (ev){
      this.activeView = ev;
    }
    if (!this.activeView){
      this.activeView = this.views[0];
    }
    if (!this.customerView){
      this.customerView = this.customerViews[0];
    }
    for (let section of this.activeView.sections){
      if (section.fields.length > 0){
        this.sectionFields.push(...section.fields);
      }
    }
    this.crm.activeViews.contact = this.activeView;
  }

  ngOnDestroy() {
    if (!this.prefiltered) {
      localStorage.setItem('contact-filters', JSON.stringify(this.filters));
      localStorage.setItem('contact-sorting', JSON.stringify(this.sorting));
      localStorage.setItem('contact-columns', JSON.stringify(this.hidden));
    }
    if (this.activeView) {
      localStorage.setItem('contact-active-view', JSON.stringify(this.activeView));
    }
    if (this.showDeleted)
      localStorage.setItem('contact-deleted', 'true')
    else
      localStorage.removeItem('contact-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const contact = await this.server.get('crm/contact/' + (this.showDeleted ? 'all' : '') + query);
    this.total = contact.count;
    this.contacts = plainToClass(Contact, contact.rows);
    this.loading = false;
  }

  async loadContacts() {
    this.showDeleted = !!localStorage.getItem('contact-deleted');
    const res = await this.server.get('crm/contact/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.contacts = plainToClass(Contact, res.rows);
  }

  selectionChange(contacts: Contact[]) {
    if (contacts.length > 0) {
      this.order = false;
    }
  }

  async toggleOrder() {
    if (!this.order) {
      this.sorting['order'] = ClrDatagridSortOrder.ASC;
      this.refresh();
    } else {
      this.server.get('crm/contact/')
    }
    this.order = !this.order;
  }

  async openRestoreModal(contact: Contact) {
    this.selectedContact = contact;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('crm/contact/restore', this.selectedContact);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.contact.nameSingular).replace("${entryName}", this.crm.getDisplayValue(this.selectedContact))));
    this.refresh();
    this.refresh();
    this.restoreModal = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const contactIds = this.toDelete.map((contact) => contact.id);
    await this.server.delete('crm/contact?toDelete=' + contactIds);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
     this.toDelete.length == 1 ? this.localization.dictionary.contact.nameSingular :  this.localization.dictionary.contact.name)));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  async checkExportType(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.exportState = state;
    this.exportState.page.size = 0;
    if (this.exportState.filters) {
      this.exportModal = true;
    } else {
      await this.downloadExcel("?skip=0&limit=0");
    }
  }

  async downloadExcel(query?: string) {
    this.loading = true;
    this.exportModal = false;
    if (!query) {
      query = this.server.buildQueryFromGrid(this.exportState);
    }
    await this.crm.downloadExcel('contact', query, this.showDeleted);
    this.loading = false;
  }

  openAuditLogModal(entityId: string) {
    this.entityId = entityId;
    this.auditLogModalOpen = true;
  }

}
