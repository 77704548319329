import { Component, DoCheck, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Category } from 'src/app/categories/category.model';
import { HelperService } from 'src/app/helper/helper.service';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { ServerResponse, ServerService } from 'src/app/server.service';
import { Document } from '../document.model';
import { Form } from 'src/app/forms/form.model';
import { DocumentType } from '../documenttype';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { SettingService } from 'src/app/settings/setting.service';
import { AmselIcon, LocalizationService } from '../../localization/localization.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';



@Component({
  selector: 'app-create-edit-document',
  templateUrl: './create-edit-document.component.html',
  styleUrls: ['./create-edit-document.component.css'],

})
export class CreateEditDocumentComponent extends PreventUnload implements OnInit, DoCheck {

  document: Document;
  documentTypes = DocumentType;
  selectedCats = [];
  allowedFiles = '.zip,.pdf,.mov,.mp4';
  file: File;
  mode: 'edit' | 'create';
  currentIcon: AmselIcon;
  previousIcon: AmselIcon;
  loading = true;
  downloading = false;
  forms: Form[] = [];
  collapse: boolean[] = [
    false,
    false,
    false,
    false,
    false,
  ]

  firstTab = true;
  invalid = false;

  @ViewChild(ClrForm) form: ClrForm;

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public setting: SettingService,
    public helper: HelperService,
    public localization: LocalizationService,
    private auth: AuthService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.mode = (params.catId) ? 'create' : 'edit';
        if (this.mode === 'edit') {
          this.document = plainToClass(Document, await this.server.get('document/includeCats/' + params.id));         
          this.currentIcon = this.localization.amselIconList.find((icon) => icon.element === this.document.icon);

        } else {
          const res = await this.server.get('category/byId/' + params.catId) as ServerResponse;
          const category = plainToClass(Category, res.rows)
          this.document = new Document(category, category[0].groups);
          this.dirty = true;
          this.currentIcon = this.localization.amselIconList.find((icon) => icon.element === category[0].icon);

        }
        this.changeUnload();
        this.loading = false;
      })
    );
  }

  async ngOnInit(): Promise<void> {
    const res = await this.server.get('form/published');
    this.forms = plainToClass(Form, res.rows);

    this.subscriptions.add(
      this.localization.languageChanged.subscribe(() => {
        this.changeUnload();
        this.currentIcon = this.localization.amselIconList.find(icon => icon.element == this.currentIcon.element);
      })
    );

    this.subscriptions.add(
      this.route.queryParamMap.subscribe(async queryParams => {
        const formId = queryParams.get('formId');
        if (formId) {
          this.document.type = DocumentType.FORM;
          const form = await this.server.getUnwrapped<Form>(`form/byId/${formId}`);
          if (form) {
            this.document.name = form.name;
            this.document.description = form.description;
            this.document.form = form;
          }
        }
      })
    );
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('document');
    } else {
      this.changeUnloadEdit('document', this.document.name);
    }
  }

  ngDoCheck() {
    if (this.invalid && this.form)
      this.form.markAsTouched()
  }

  validate() {
    return (this._validateFile() && this._validateFields() && this._validateCategories() && this._validateName());
  }

  validateAndSave() {
    if (this._validateFile() && this._validateFields() && this._validateCategories() && this._validateName()) {
      this.save();
    } else {
      this.invalid = true
      this.firstTab = true
    }
  }

  _validateName(): boolean {
    const nameRe = /^(?!^(PRN|AUX|CLOCK\$|NUL|CON|COM\d|LPT\d|\..*)(\..+)?$)[^\x00-\x1f\\?*:\";|/]+$/;
    return nameRe.test(this.document.name);
  }

  _validateFile(): boolean {
    if (this.document.type === DocumentType.FILE && (this.file || this.document.filename)) return true;
    if (this.document.type === DocumentType.FORM && this.document.form) return true;
    return false;
  }

  _validateFields(): boolean {
    if (this.document.name && this.currentIcon && this.document.description) return true;
    return false;
  }

  _validateCategories(): boolean {
    if (this.document.categories && this.document.categories.length > 0) return true;
    return false;
  }

  async save() {
    this.dirty = false;
    this.saving = true;
    this.loading = true;
    let formData: FormData = new FormData();
    if (this.currentIcon) {
      this.document.icon = this.currentIcon.element;
    }    
    formData.append('documentString', JSON.stringify(this.document))
    if (this.file) {
      formData.append('file', this.file);
    }

    if (this.mode === 'create') {
      await this.server.post('document/create', formData);
    } else {
      await this.server.put('document', formData);
    }
    if (this.document.isActive && this.document.form && !this.document.form.isActive){
      await this.server.put('form/reactivate/' +  this.document.formId, this.document.form);
    }
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successSaved
        .replace('${componentName}', this.localization.dictionary.document.nameSingular)
        .replace('${entryName}', this.document.name)));
    this.saving = false;
    this.server.back();
  }

  async upload(files: FileList) {
    this.file = files[0];
  }

  async download() {
    this.downloading = true;
    await this.server.documentDownload(this.document);
    this.downloading = false;
  }

  isPdf(): boolean {
    if ((this.file && this.file.name.includes('.pdf')) || (this.document.filename && this.document.filename.includes('.pdf'))) {
      return true;
    }
    return false;
  }

  changeIcon(ev) {
    if (ev) {
      this.previousIcon = this.currentIcon;
      this.currentIcon = undefined;
    } else if (!this.currentIcon) {
      this.currentIcon = this.previousIcon;
    }

  }

  canActivate(): boolean {
    if (!this.auth.hasRole(['admin', 'form-editor']) && (!this.document.form || !this.document.form.isActive)){
      return false;
    }
    return true;
  }
}
