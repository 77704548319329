import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LocalizationService } from '../localization/localization.service';

type HelpInfo = {
  componentName: string,
  display: "modal" | "sidebar" | "off"
}

@Injectable({
  providedIn: 'root'
})
export class UserHelpService {
  /**
   * Neue Hilfen müssen in der show-topic Komponente angelegt werden.
   * Neue Hilfe Sprachen müssen in der help-start Komponente angelegt werden.
   * 
   * Das helpWanted-Subject wird genutzt um die Hilfe auf zu rufen. 
   * Es kann von außerhalb des helpService aufgerufen werden um die Hilfe zu öffnen.
   */

  public helpWanted = new Subject<HelpInfo>();
  public isActive = false;
  private componentName: string;

  constructor(private router: Router, private localization: LocalizationService) {
    // Aktualisiert die componentName wenn sich die Route / URL ändert.
    router.events.subscribe( (ev) => {
      if (this.isActive && ev instanceof NavigationEnd) {
        this._buildComponentName()
      }
    })
    // Ruft die Hilfe auf wenn das helpWanted-Subject ein Event auslöst.
    this.helpWanted.subscribe( (info) => {
      this.isActive = info.display === "off" ? false : true;
      if(info.componentName) this.componentName = info.componentName;
      else this._buildComponentName();
    });
  }

  getComponentName() {
    return this.componentName;
  }

  // Erstellt den Namen des Components aus der URL -> amsel.de/documents/list -> de-documents-list
  private _buildComponentName() {
    let pathArr: string[] = this.router.url.replace('/', '').split('/');
    for(let path in pathArr) {
      if( /\d/.test(pathArr[path]) ) {
        pathArr.splice(parseInt(path), 1);
      }
    }
    this.componentName = this.localization.language.selector + '-' + pathArr.join('-')
  }
}
