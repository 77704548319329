import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { HelperService } from 'src/app/helper/helper.service';
import { ServerService } from 'src/app/server.service';
import { SettingService } from 'src/app/settings/setting.service';
import { LocalizationService } from '../../localization/localization.service';
import { Group } from '../group.model';

@Component({
  selector: 'app-create-edit-group',
  templateUrl: './create-edit-group.component.html',
  styleUrls: ['./create-edit-group.component.css']
})
export class CreateEditGroupComponent extends PreventUnload implements OnInit, DoCheck {
  loading = true;
  group: Group = new Group();
  mode: 'create' | 'edit' = 'create';
  dirty: boolean;

  firstTab = true;
  invalid = false;
  collapse: boolean[] = [
    false,
    false,
    false,
    false
  ]

  @ViewChild(ClrForm) form: ClrForm;

  constructor(public server: ServerService, 
              private route: ActivatedRoute,
              public helper: HelperService,
              public setting: SettingService,
              public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params)=> {
        this.mode = (params.id) ? 'edit' : 'create';
        if (this.mode === 'edit') {
          const id = params.id;   
          const group = await this.server.get('group/byId/' + id);
          this.group = plainToClass(Group, group);
        } else {
          this.dirty = true;
        }
        this.changeUnload();
        this.loading = false;
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );
  }

  async ngOnInit(): Promise<void> {
    
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('group');
    } else {
      this.changeUnloadEdit('group', this.group.name);
    }
  }

  ngDoCheck() {
    if (this.invalid && this.form)
      this.form.markAsTouched()
  }

  validateAndSave() {
    if (this.group.name && this.group.description)
      this.save()
    else {
      this.invalid = true;
      this.firstTab = true;
      this.collapse[0] = false;
    }
  }

  async save() {
    this.loading = true;
    this.saving = true;
    this.dirty = false;
    if (this.mode === 'create') {          
      await this.server.post('group', this.group);  
    } else {
      await this.server.put('group', this.group);
    }
    this.server.addAlert(new AmselError(undefined, 'success',
    this.localization.dictionary.toastr.successSaved
    .replace('${componentName}', this.localization.dictionary.group.nameSingular)
    .replace('${entryName}', this.group.name)));
    this.saving = false;
    this.server.back();
  }
}
