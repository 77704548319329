<h4>Basis Informationen</h4>
<ol class="list">
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Vergeben Sie einen aussagekräftigen Namen für das Dokument.</li>
    <li>Fügen Sie anschließend eine Beschreibung hinzu, die das Dokument genauer bezeichnet. Hiernach kann später auch gesucht bzw. gefiltert werden.</li>
    <li>Beim „Typ“ des Dokumentes haben Sie die Auswahl zwischen „Datei“ oder „Formular“. Bei der Auswahl Datei können Sie bereits erstellte Dokumente auswählen. Klicken Sie hierfür auf „Durchsuchen“ und wählen Sie das gewünschte Dokument aus, das auf Ihrem PC oder auf Ihrem Server abgelegt ist. Beim Typ „Formular“ können Sie in der Dropdownliste ein bereits erstelltes Formular auswählen.</li>
    <li>Anschließend können Sie ein Icon für das Dokument zuordnen. Wenn Sie keines auswählen, erhält das Dokument automatisch das Icon „Ordner“</li>
    <li>Sie können zusätzlich die Dokumentenansicht auswählen, die sinnvoll für die Ansicht des Dokumentes ist. Sie haben die Auswahl zwischen „Untereinander“, „Einzelseite“ oder „Buchansicht“. Die „Buchansicht“ empfehlen wir nur bei Dokumenten im Hochformat. Sollten Sie keine Ansicht auswählen wird automatisch die Ansicht „Untereinander“ verwendet.</li>
<h4 style="position: relative; right: 1.1em;">Dieses Feld erscheint nur, wenn Sie als Typ "Datei" auswählen</h4>  
    <li>Um das Dokument in der App sichtbar zu machen, setzen Sie einen Haken bei „Aktiv“.</li>
    <li>Wenn Sie bei „Teilen/Mailen“ einen Haken setzen, wird die Freigabe erteilt, das Dokument mit anderen Personen per E-Mail-Versand zu teilen. Sollte das Dokument oder Formular nur firmenintern verwendet werden dürfen, sollte hier kein Haken gesetzt werden.</li>

<h4 style="position: relative; right: 1.1em;">Kategorien zuordnen</h4>
    <li>Unter dem Punkt „Kategorien zuordnen“ können Sie dem Dokument weitere Kategorien zuordnen.</li>
<h4 style="position: relative; right: 1.1em;">Produkte zuordnen</h4>
    <li>Unter dem Punkt „Produkte zuordnen“ verknüpfen Sie das Dokument mit Produkt/en, so dass sie ihnen direkt zugeordnet sind. </li>
<h4 style="position: relative; right: 1.1em;">Gruppen zuordnen</h4>
    <li>Bei der Anlage der Kategorie haben Sie bereits eine oder mehrere Gruppen zugeordnet. Beim Punkt „Gruppen zuordnen“ können Sie weitere Gruppen zuordnen, die eine Leseberechtigung für das Dokument erhalten sollen.</li>
<h4 style="position: relative; right: 1.1em;">Benutzer zuordnen</h4>
    <li>Beim letzten Punkt in der Eingabemaske „Benutzer zuordnen“ können Sie das Dokument zusätzlich für einzelne Benutzer freigeben, die nicht in den bereits zugeordneten Gruppen sind.</li>
<h4 style="position: relative; right: 1.1em;">Aktionen</h4>
    <li>Zum Speichern des erstellten Dokuments klicken Sie auf Erstellen</li>

</ol>
