import { Component, OnInit } from '@angular/core';
import { UserHelpService } from '../../user-help.service';
import { LocalizationService } from '../../../localization/localization.service';

@Component({
  selector: 'app-en-help',
  templateUrl: './en-help.component.html',
  styleUrls: ['./en-help.component.css']
})
export class EnHelpComponent implements OnInit {

  constructor(public userHelp: UserHelpService, public localization: LocalizationService) { }

  ngOnInit(): void {
  }

  openTopic(topic: string) {
    this.userHelp.helpWanted.next({componentName: topic, display: 'sidebar'});
  }

}
