import { Component, DoCheck, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Group } from '../../../groups/group.model';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { PreventUnload } from '../../../helper/guards/unsaved.guard';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { User } from '../../../users/user.model';
import { Territory } from '../territory.model';

@Component({
  selector: 'app-create-edit-territory',
  templateUrl: './create-edit-territory.component.html',
  styleUrls: ['./create-edit-territory.component.css']
})
export class CreateEditTerritoryComponent extends PreventUnload implements OnInit, DoCheck {

  @ViewChild(ClrForm) form: ClrForm;
  private formData: NgForm;

  @ViewChild('formData', { static: false }) set content(content: NgForm) {
    if (content) { // initially setter gets called with undefined      
      this.formData = content;
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  territory: Territory;
  possibleParents: Territory[];
  users: User[];
  groups: Group[];
  mode: 'edit' | 'create';
  loading = true;

  errTemp = {};

  firstTab = true;
  invalid = false;
  collapse : boolean[] = [
    false, 
    false, 
    false, 
    false
  ]

  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public helper: HelperService,
    public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        this.mode = (params.id) ? 'edit' : 'create';
        if (this.mode === 'edit') {
          this.territory = plainToClass(Territory, await this.server.get('crm/territory/byId' + params.id), {excludeExtraneousValues: true});
        } else {
          this.territory = new Territory();
          this.dirty = true;
        }
        await this.loadParents();
        this.changeUnload();
        this.loading = false;
        const res = await this.server.get('user/filter');
        this.users = plainToClass(User, res.rows);
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );
  }

  async ngOnInit(): Promise<void> {
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('territory');
    } else {
      this.changeUnloadEdit('territory', this.territory.name);
    }
  }

  ngDoCheck() {
    if (this.form && this.formData && this.invalid) {
      this.formData.form.markAsDirty();
      this.formData.form.markAsTouched();
      this.form.markAsTouched();
      this.invalid = false;
    }
    if (Object.keys(this.errTemp).length > 0 && this.formData && Object.keys(this.formData.form.controls).length > 0) {
      this.formData.form.markAsDirty();
      this.form.markAsTouched();
      this.markFieldsAsInvalid(this.errTemp);

      this.errTemp = {};
    }
  }

  async loadParents() {
    this.possibleParents = plainToClass(Territory, (await this.server.get('crm/territory/parents/' + this.territory?.id + '?children=' + this.territory.children.map(child=>child.id))).rows);
    if (!this.possibleParents.find(parent => parent.id == this.territory?.parent?.id))
      this.territory.parent = undefined;
  }

  async validateAndSave() {
    if (this.territory.name && this.formData.valid)
      await this.save();
    else {
      this.firstTab = true;
      this.invalid = true;
      this.collapse[0] = false;
    }
  }

  async save() {
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    if (this.mode === 'create') {
      await this.server.post('crm/territory', this.territory);
    } else {
      await this.server.put('crm/territory', this.territory);
    }
    this.server.addAlert(new AmselError(undefined, 'success',
    this.localization.dictionary.toastr.successSaved
    .replace('${componentName}', this.localization.dictionary.territory.nameSingular)
    .replace('${entryName}', this.territory.name)));
    this.saving = false;
    this.server.back();
  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      this.formData.form.markAsDirty();
      if (this.form) this.form.markAsTouched();
      for (let field of Object.keys(errors)) {
        if (this.formData && this.formData.form.controls[field]) {
          this.formData.form.controls[field].markAsDirty();
          this.formData.form.controls[field].setErrors(errors[field]);
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  }

}
