<form clrForm *ngIf="active">
    <clr-select-container>
        <label>{{localization.dictionary.formRange.style}}</label>
        <select clrSelect name="inputtype" [(ngModel)]="question.inputType" required (ngModelChange)="changeOptions()">
          <option [value]="'SLIDER'">{{ localization.dictionary.formRangeType.SLIDER}}</option>
          <option [value]="'SMILEY'">{{ localization.dictionary.formRangeType.SMILEY}}</option>
        </select>
    </clr-select-container>
    <clr-input-container>
        <label>{{localization.dictionary.formRange.lowerLimit}}</label>
        <input clrInput type="number" name="floor" [(ngModel)]="options.floor" (ngModelChange)="changeOptions()" [max]="options.ceil - options.step" maxlength="50">
    </clr-input-container>
    <clr-input-container>
        <label>{{localization.dictionary.formRange.upperLimit}}</label>
        <input clrInput type="number" name="ceil" [(ngModel)]="options.ceil" (ngModelChange)="changeOptions()" [min]="options.floor + options.step" maxlength="50">
    </clr-input-container>
    <clr-input-container>
        <label>{{localization.dictionary.formRange.step}}</label>
        <input clrInput type="number" name="step" [(ngModel)]="options.step" (ngModelChange)="changeOptions()" [min]="1" maxlength="50">
    </clr-input-container>
    <!--
    <clr-toggle-container>
        <label>Skala umkehren</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle name="reverse" value="false" [(ngModel)]="options.rightToLeft" (ngModelChange)="changeOptions()"/>          
        </clr-toggle-wrapper>
        <clr-control-helper>Zeigt die Skala von rechts (niedrigster Wert) nach links (höchster Wert) an</clr-control-helper>
    </clr-toggle-container>
    -->
    <clr-toggle-container>
        <label>{{localization.dictionary.formRange.axisLabel}}</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle name="customsteps" value="false" [(ngModel)]="customStepsToggle" (ngModelChange)="changeOptions()"/>          
        </clr-toggle-wrapper>
        <clr-control-helper>{{localization.dictionary.formRange.infoTextEnterOnAxis}}</clr-control-helper>
    </clr-toggle-container>
    <div fxLayout="column" *ngIf="customStepsToggle" style="margin-left: 5rem;">
        <clr-input-container >
            <label>{{localization.dictionary.formRange.lowerLimit}}</label>  
            <input type="text" clrInput [(ngModel)]="customSteps[0]" name="floordesc" (ngModelChange)="changeOptions()" maxlength="100">                  
        </clr-input-container>
        <clr-input-container >
            <label>{{localization.dictionary.formRange.center}}</label>  
            <input type="text" clrInput [(ngModel)]="customSteps[1]" name="middesc" (ngModelChange)="changeOptions()" [disabled]="(valueLegendArr.length % 2 === 0)" maxlength="100">
            <clr-control-helper *ngIf="(valueLegendArr.length % 2 === 0)">
                {{localization.dictionary.formRange.warningMustBeOdd}}
            </clr-control-helper>                  
        </clr-input-container>
        <clr-input-container >
            <label>{{localization.dictionary.formRange.upperLimit}}</label>  
            <input type="text" clrInput [(ngModel)]="customSteps[2]" name="ceildesc" (ngModelChange)="changeOptions()" maxlength="100">                  
        </clr-input-container>
    </div>
    
</form>
<div fxLayout="column" [ngClass]="(options.stepsArray) ? 'legend-shown' : ''">
    <p class="p4" *ngIf="active" style="margin-bottom: 1rem; padding-left: 0.4rem;">{{localization.dictionary.general.preview}}</p>
    <div *ngIf="question.inputType === qit.SMILEY" fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 1rem; padding: 0rem 2.3rem;">
        <clr-icon size="48" shape="sad-face"></clr-icon>
        <clr-icon size="48" shape="neutral-face"></clr-icon>
        <clr-icon size="48" shape="happy-face"></clr-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="slider-legend" style=" padding: 0 3rem;" #sliderLegend>
        <div class="slider-tick" *ngFor="let tick of steps; let i = index">
            {{ tick }}
        </div>
    </div>
   
    <!-- [ngStyle]="{'left': 'calc(' + _testCalcLeft(i) + ' + 0.9rem)'}" -->
    <p-slider [(ngModel)]="testValue" [min]="options.floor" [max]="options.ceil"
     [step]="options.step" #slider style=" padding: 0 3rem;" [class]="options.getSelectionBarColor ? options.getSelectionBarColor(testValue)  : null"></p-slider>
    <div *ngIf="customStepsToggle"  fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 1rem; padding: 0 2.5rem;">
        <ng-container *ngFor="let step of customSteps; let i = index">
            <div *ngIf="(i == 1 && valueLegendArr.length % 2 !== 0) || i != 1">{{step}}</div>            
        </ng-container>
        
    
    </div>
    
    <!-- <ngx-slider *ngIf="options" [(value)]="testValue" [options]="options" style="width: 88%; align-self: center;" (userChange)="test($event)" #slider></ngx-slider> -->
    <!--
    <div fxLayout="row" fxLayoutAlign="space-between" style="width: 98%; align-self: center;">
        <div *ngFor="let step of customSteps">
            {{ step }}
        </div>
    </div>
    -->
</div>

