import { Exclude, Expose } from "class-transformer";
import { Category } from "../categories/category.model";
import { Document } from '../documents/document.model';
import { User } from "../users/user.model";
import { v4 } from 'uuid';

export enum EventType {
    LOGIN = 'LOGIN',
    SYNCHRONIZATION = 'SYNCHRONIZATION',
    SLIDE_OPENED = 'SLIDE_OPENED',
    PRESENTATION = 'PRESENTATION',
    MAIL_SENT = 'MAIL_SENT',
    NOTIFICATION_INTERACTION = 'NOTIFICATION_INTERACTION',
    TOTAL = 'TOTAL'
}

export enum DeviceType {
    IOS = 'IOS',
    WIN = 'WIN',
    ANDROID = 'ANDROID',
    LINUX = 'LINUX',
    WEB = 'WEB',
    DEMO = 'DEMO'
}

@Exclude()
export class TrackingData {

    @Expose()
    id: string = v4();

    @Expose()
    eventType: EventType;

    @Expose()
    value?: string;

    @Expose()
    duration?: number;

    @Expose()
    document?: Document;

    @Expose()
    category?: Category;

    @Expose()
    user: User;

    @Expose()
    deviceType: DeviceType;

    @Expose()
    date: Date;
}