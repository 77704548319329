<clr-alerts>
    <clr-alert [clrAlertAppLevel]="true" [clrAlertType]="error.type" [clrAlertClosable]="error.closable" *ngFor="let error of server.errors" (clrAlertClosedChange)="onClose(error.id)">
        <clr-alert-item>
            <div class="alert-text">{{ error.msg }}</div>
            <div class="alert-actions">
                <button *ngIf="server.errors.length > 1" (click)="server.dismissAllAlerts()" class="btn alert-action">{{ localization.dictionary.toastr.closeAll }}</button>
            </div>
            
        </clr-alert-item>
    </clr-alert>
</clr-alerts>

