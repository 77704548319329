<clr-tabs>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.general.general}}</button>
        <ng-template [(clrIfActive)]="firstTab">
            <clr-tab-content *clrIfActive>
                <div class="card" *ngIf="!loading">
                    <div class="card-header">
                        {{ helper.getCardHeader(mode, 'product', product.name) }}
                    </div>
                    <div class="card-block">
                        <div class="card-title collapse-card">
                            <span>
                                <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                                <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                                <p class="card-text icon-offset">
                                    {{localization.dictionary.productCreateEdit.baseInformationDescription}}
                                </p>
                            </span>
                            <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                                (click)="collapse[0] = !collapse[0]"></clr-icon>
                        </div>
                        <div [hidden]="collapse[0]" class="card-text">
                            <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                                <clr-input-container>
                                    <label>{{localization.dictionary.general.name}}*</label>
                                    <input clrInput type="text" [(ngModel)]="product.name" name="name" required maxlength="100" />
                                    <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                </clr-input-container>

                                <clr-textarea-container>
                                    <label>{{localization.dictionary.general.description}}</label>
                                    <textarea clrTextarea [(ngModel)]="product.description" name="description" maxlength="150" rows="3"></textarea>
                                </clr-textarea-container>


                                <clr-input-container>
                                    <label>{{localization.dictionary.general.itemNumber}}</label>
                                    <input clrInput type="text" [(ngModel)]="product.itemNumber" name="itemNumber"
                                        maxlength="150" />
                                </clr-input-container>

                                <clr-input-container>
                                    <label>{{localization.dictionary.general.unit}}</label>
                                    <input clrInput type="text" [(ngModel)]="product.unit" name="unit"
                                        maxlength="150" />
                                </clr-input-container>

                                <clr-input-container>
                                    <label>{{localization.dictionary.general.price}}</label>
                                    <input clrInput type="text" min="0" [(ngModel)]="productPrice" name="price" pattern="^\d{0,8}([.,]\d{0,2})?$"/>
                                    <clr-control-error *clrIfError="'pattern'">{{localization.dictionary.createEdit.invalidInput}}</clr-control-error>
                                </clr-input-container>

                                <div class="clr-form-control upload">
                                    <label style="padding: unset;" class="clr-control-label">{{localization.dictionary.general.preview}}</label>
                                    <div class="clr-control-container">
                                        <div class="clr-file-wrapper">
                                            <div *ngIf="product.picture" class="img-preview"
                                                [style.background-image]="product.picture"></div>
                                            <!-- <label style="padding: unset; margin-right: 2vw;" 
                                                [for]="product.picture + 'picker'"> --><button class="btn btn-sm" 
                                                (click)="uploadImage()">{{
                                                    (product.picture) ? localization.dictionary.button.edit :
                                                    localization.dictionary.button.browse }}</button><!-- </label> -->

                                            <div *ngIf="product.picture">
                                                <clr-icon shape="times"
                                                          (click)="product.picture = ''"
                                                          [pTooltip]="localization.dictionary.productCreateEdit.deletePic"
                                                          [tooltipPosition]="'bottom'"
                                                          class="icon-style">
                                                </clr-icon>
                                            </div>

                                            <!-- <input type="file" [id]="product.picture + 'picker'"
                                                [name]="product.picture" (change)="imageChange($event.target.files)"
                                                placeholder="Enter value here" class="clr-file"
                                                [accept]="allowedFiles" [size]="50"/> -->
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card-block">
                        <div class="card-title collapse-card">
                            <span>
                                <clr-icon shape="file" class="clr-icon-heading"></clr-icon>
                                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.document.name)}}</span>
                                <p class="card-text icon-offset">
                                    {{localization.dictionary.productCreateEdit.assignDocumentsToProduct}}
                                </p>
                            </span>
                            <clr-icon shape="angle" class="arrow" [dir]="!collapse[1] ? 'down' : 'left'"
                                (click)="collapse[1] = !collapse[1]"></clr-icon>
                        </div>
                        <div [hidden]="collapse[1]" class="card-text icon-offset">
                            <app-list-selector [excludeAdmin]="true" (selectedChanged)="product.documents= $event"
                                [preLoaded]="product.documents" [label]="localization.dictionary.document.name" [query]="'document'">
                            </app-list-selector>
                        </div>

                    </div>
                    <div stickyFooter class="card-footer">
                        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                    </div>
                </div>
            </clr-tab-content>
        </ng-template>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.customer.name}}</button>
        <ng-template [(clrIfActive)]="secondTab">
            <clr-tab-content *clrIfActive>
                <div class="card" *ngIf="!loading">
                    <div class="card-header">
                        {{ helper.getCardHeader(mode, 'product', product.name) }}
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            <clr-icon shape="building" class="clr-icon-heading is-solid"></clr-icon>
                            <span>{{localization.dictionary.productCreateEdit.assignedCustomers}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.productCreateEdit.removeCustomersFromProduct}}
                            </p>
                            <p class="card-text icon-offset" *ngIf="this.invalid && this.product.customers.length == 0">
                                <clr-control-error
                                    [style.color]="branding.darkMode? '#f55047' : 'var(--clr-forms-invalid-color)'">
                                    {{localization.dictionary.contact.associatingRequired}}</clr-control-error>
                            </p>
                        </div>
                        <div class="card-text">
                            <app-sublist-customers [current]="true" [dataIds]="customerIds"
                                (selectedCustomer)="removeCustomer($event)"></app-sublist-customers>
                        </div>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            <clr-icon shape="building" class="clr-icon-heading"></clr-icon>
                            <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.customer.name)}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.productCreateEdit.assignCustomersToProduct}}
                            </p>
                        </div>
                        <div class="card-text">
                            <app-sublist-customers [current]="false" [dataIds]="customerIds"
                                (selectedCustomer)="addCustomer($event)"></app-sublist-customers>
                        </div>
                    </div>
                    <div stickyFooter class="card-footer">
                        <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                    </div>
                </div>

            </clr-tab-content>
        </ng-template>
    </clr-tab>
    <!--
    <clr-tab>
        <button clrTabLink>Ansprechpartner</button>
        <clr-tab-content *clrIfActive>
            <div class="card" *ngIf="!loading">
                <div class="card-header">
                    {{ helper.getCardHeader(mode, 'Produkt', product.name, "m") }}
                </div>
                <div class="card-block">
                    <div class="card-title">
                        <clr-icon shape="user" class="clr-icon-heading is-solid"></clr-icon>
                        <span>Zugeordnete Ansprechpartner</span>
                        <p class="card-text icon-offset">
                            Wählen Sie die Ansprechpartner aus, die vom Produkt entfernt werden sollen.
                        </p>
                    </div>
                    <div class="card-text">
                        <app-sublist-contacts [current]="true" [dataIds]="contactIds" (selectedContact)="removeContact($event)"></app-sublist-contacts>
                    </div>
                </div>
                <div class="card-block">
                    <div class="card-title">
                        <clr-icon shape="user" class="clr-icon-heading"></clr-icon>
                        <span>Ansprechpartner zuordnen</span>
                        <p class="card-text icon-offset">
                            Wählen Sie die Ansprechpartner aus, die zum Produkt zugeordnet werden sollen.
                        </p>
                    </div>
                    <div class="card-text">
                        <app-sublist-contacts [current]="false" [dataIds]="contactIds" (selectedContact)="addContact($event)"></app-sublist-contacts>
                    </div>
                </div>
                <div class="card-footer">
                    <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                </div>
            </div>

        </clr-tab-content>
    </clr-tab>
-->
    <app-spinner *ngIf="loading"></app-spinner>

    <ng-template #actionBar>
        <button class="btn btn-primary" (click)="formData.valid ? save() : unfold()">{{ ( mode === 'create' ) ?
            localization.dictionary.button.create :
            localization.dictionary.button.save }}</button>
        <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
    </ng-template>