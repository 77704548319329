<h2>{{localization.dictionary.language.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected"
    (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="this.selected = []">
        {{localization.dictionary.language.label}}
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'selector'" [(clrDgSortOrder)]="sorting['selector']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['selector']">
            {{localization.dictionary.language.selector}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="selectorFilter" [(clrDgFilterOpen)]="selectorFilter.open">
            <string-filter #selectorFilter [property]="'selector'" [(filter)]="filters['selector']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'" [(clrDgSortOrder)]="sorting['updatedAt']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'" [(clrDgSortOrder)]="sorting['deletedAt']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let language of languages; let i = index" [clrDgItem]="language"
        [clrDgSelectable]="(columnChange || editable(language)) && deletable(language)"
        [class.deleted]="language.deletedAt" [class.noedit]="!editable(language)">
        <clr-dg-cell [routerLink]="!editable(language)? null : ['/language/edit/' + language.id]">{{ language.id
            }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(language)? null : ['/language/edit/' + language.id]">{{ language.name
            }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(language)? null : ['/language/edit/' + language.id]">{{ language.selector
            }}</clr-dg-cell>

        <clr-dg-cell [routerLink]="!editable(language)? null : ['/language/edit/' + language.id]">
            {{ language.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(language)? null : ['/language/edit/' + language.id]">
            {{ language.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="!editable(language)? null : ['/language/edit/' + language.id]">
            {{ language.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>


        <clr-dg-cell *ngIf="editable(language)" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="pencil"
                [routerLink]="['/language/edit/' + language.id]"
                [pTooltip]="localization.dictionary.language.editComponent.replace('${entryName}', language.name)"
                tooltipPosition="left"
                class="tooltip-trigger">
            </clr-icon>

            <clr-icon
                shape="download"
                *ngIf="editable(language)"
                (click)="excelService.downloadExcelLanguageFromList(language.id)"
                [pTooltip]="localization.dictionary.language.download.replace('${entryName}', language.name)"
                tooltipPosition="left"
                class="tooltip-trigger">
            </clr-icon>
            <!-- Hochladen-Import Action Icon-->
            <div class="div" *ngIf="editable(language)">
                <label [for]="'file-edit' + language.id" class="clr-control-label" fxLayout="row">
                    <clr-icon
                        shape="upload"
                        [pTooltip]="localization.dictionary.language.upload.replace('${entryName}', language.name)"
                        tooltipPosition="left"
                        class="tooltip-trigger"
                    ></clr-icon>
                </label>
                <input
                    type="file"
                    [id]="'file-edit' + language.id"
                    (change)="checkDictionary($event, language)"
                    [accept]="allowedFiles"
                    placeholder="Enter value here"
                    class="clr-file"
                    [disabled]="loadingExcel"
                />
                <clr-control-error class="clr-subtext" *ngIf="isExcelFile === false">
                    Datei erforderlich
                </clr-control-error>
            </div>

            <span
                *ngIf="!language.deletedAt && deletable(language)"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.language.nameSingular).replace('${entryName}', language.name)"
                tooltipPosition="left"
                class="tooltip-trigger">
                <clr-icon shape="trash" (click)="toDelete = [language]"></clr-icon>
            </span>

        </clr-dg-cell>
        <clr-dg-cell *ngIf="!editable(language)" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <span
                *ngIf="language.deletedAt"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.language.nameSingular).replace('${entryName}', language.name)"
                tooltipPosition="left"
                class="tooltip-trigger"
            >
                <clr-icon shape="backup-restore" (click)="openRestoreModal(language)"></clr-icon>
            </span>

        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size
                [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}',
                localization.dictionary.language.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                .replace('${first}', '' + (pagination.firstItem + 1))
                .replace('${last}', '' + (pagination.lastItem + 1))
                .replace('${total}', pagination.totalItems == 1? localization.dictionary.language.one : '' +
                pagination.totalItems)
                .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.language.nameSingular
                :
                localization.dictionary.language.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>


<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.language.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger"
            (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline"
            (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.language.nameSingular).replace('${entryName}', selectedLanguage?.name) }}</h3>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{
            localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{
            localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar style="display: flex;">
            <button class="btn btn-primary" routerLink="/language/create">{{
                localization.dictionary.button.addComponent.replace('${componentName}',
                localization.dictionary.language.nameSingular) }}</button>
            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>

            <!-- Hochladen-Import Button -->
            <label for="file-create" class="clr-control-label upload-label upload-label-extended" fxLayout="row" 
                fxLayoutGap="1rem">
                <span class="btn btn-primary no-margin" [class.btn-danger]="isExcelFile === false">
                    {{localization.dictionary.button.excelImport}}
                </span>
            </label>
            <input type="file" id="file-create" name="file-create" [accept]="allowedFiles" (change)="goToCreate($event)"
                placeholder="Enter value here" class="clr-file" [disabled]="loadingExcel" />
            <clr-control-error class="clr-subtext" *ngIf="isExcelFile === false">
                Datei erforderlich
            </clr-control-error>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['language-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                    (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}',
                    localization.dictionary.language.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="openWarning">
    <h3 class="modal-title" >{{localization.dictionary.language.overwriteTranslations}}</h3>
    <div class="modal-body" >
        {{localization.dictionary.language.importData}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="goToEdit($event, openWarning)">{{localization.dictionary.general.yes}}</button>
        <button type="button" class="btn btn-outline" (click)="cancelImport()">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>