import { Field } from "../fields/field.model";
import { v4 } from 'uuid';
import { AmselIcon } from "../../localization/localization.service";

export class Section {
    id: string = v4();
    name: string;
    description: string;
    icon: string;
    order: number;
    fields: Field[];
    invalid = false;
    invalidName = false;
    currentIcon: AmselIcon;
}