import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Category } from '../category.model';
import { ServerService } from 'src/app/server.service';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { HelperService } from 'src/app/helper/helper.service';
import { ClrCombobox, ClrForm } from '@clr/angular';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { NgForm } from '@angular/forms';
import { LocalizationService, AmselIcon } from 'src/app/localization/localization.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-create-edit-category',
  templateUrl: './create-edit-category.component.html',
  styleUrls: ['./create-edit-category.component.css']
})
export class CreateEditCategoryComponent extends PreventUnload implements OnInit {
  category = new Category();
  currentIcon: AmselIcon;
  mode: 'edit' | 'create' = 'create';
  loading = false;
  collapse: boolean[] = [
    false,
    false
  ]
  invalid = false;
  blurred = false;

  iconRequired = false;

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  @ViewChild(ClrForm) form: ClrForm;
  @ViewChild('formData') formData: NgForm;
  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public helper: HelperService,
    public localization: LocalizationService) {
    super(localization);
  }

  unfold() {
    this.form.markAsTouched();
    this.collapse[0] = false;
  }

  async ngOnInit(): Promise<void> {
    const path = this.route.snapshot.url[0].path;
    if (path === 'edit') {
      const id = this.route.snapshot.params.id;
      this.mode = 'edit';
      this.loading = true;
      const cat = await this.server.get('category/byId/' + id);
      this.category = plainToClass(Category, cat.rows[0]);
      this.currentIcon = this.localization.amselIconList.find((icon) => icon.element === this.category.icon);

    } else {
      this.dirty = true;
    }
    this.loading = false;
    this.changeUnload();
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
        this.currentIcon = this.localization.amselIconList.find(icon => icon.element == this.currentIcon.element);
      })
    );
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('category');
    } else {
      this.changeUnloadEdit('category', this.category.name);
    }
  }

  async save() {
    this.iconRequired = true;
    if (!this.category.icon) {
      this.unfold()
      return;
    }
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    if (this.currentIcon) {
      this.category.icon = this.currentIcon.element;
    }
    if (this.mode === 'create') {
      this.category.order = this.server.categories.length;
      await this.server.post('category', this.category);
      await this.server.getCategories();
    } else {
      await this.server.put('category', this.category);
    }
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successSaved
        .replace('${componentName}', this.localization.dictionary.category.nameSingular)
        .replace('${entryName}', this.category.name)));

    this.saving = false;
    this.server.back();
  }

  checkValid(ev: string, combo: ClrCombobox<AmselIcon>) {
    if (!ev) {
      this.currentIcon = undefined;
      //this.invalid = true;
      combo.triggerValidation();
    } else {
      this.category.icon = this.localization.amselIconList.find((icon) => icon.label == ev)?.label;
    }
  }

}
