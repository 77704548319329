<div fxLayout="column">
    <h2>{{localization.dictionary.report.name}}</h2>

    <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="2rem">
        <!-- Nach OS -->
        <div class="card" fxFlex="98" fxFlex.gt-sm="48" fxFlex.gt-md="31" fxFlex.gt-lg="23" routerLink="/analytics/reports/events-by-os">
            <div class="card-header">
                {{localization.dictionary.dashboardInteractions.interactionsByOS}}
            </div>
            <div class="card-block">
                <div class="card-text" fxLayout="row" fxLayoutAlign="center center">
                    <clr-icon shape="bar-chart" size="256"></clr-icon>
                </div>
            </div>
        </div>

        <!-- Präsis nach Anzahl -->
        <!-- <div class="card" fxFlex="98" fxFlex.gt-sm="48" fxFlex.gt-md="31" fxFlex.gt-lg="23">
            <div class="card-header">
                {{localization.dictionary.dashboard.requestsForPresentationByFrequency}}
            </div>
            <div class="card-block">
                <div class="card-text" fxLayout="row" fxLayoutAlign="center center">
                    <clr-icon shape="bar-chart" size="256"></clr-icon>
                </div>
            </div>
        </div> -->

        <!-- Slides nach Anzahl -->
        <!-- <div class="card" fxFlex="98" fxFlex.gt-sm="48" fxFlex.gt-md="31" fxFlex.gt-lg="23">
            <div class="card-header" fxLayout="row" fxLayoutAlign="center center">
                {{localization.dictionary.dashboard.requestsForPageByFrequency}}
            </div>
            <div class="card-block">
                <div class="card-text" fxLayout="row" fxLayoutAlign="center center">
                    <clr-icon shape="bar-chart" size="256"></clr-icon>
                </div>
            </div>
        </div> -->

        <!--  -->
        <!-- <div class="card" fxFlex="98" fxFlex.gt-sm="48" fxFlex.gt-md="31" fxFlex.gt-lg="23">
            <div class="card-header">
                {{localization.dictionary.dashboard.requestsForPresentationByFrequency}}
            </div>
            <div class="card-block">
                <div class="card-text" fxLayout="row" fxLayoutAlign="center center">
                    <clr-icon shape="pie-chart" size="256"></clr-icon>
                </div>
            </div>
        </div> -->
        <!-- CRM Customer by Territory -->
        <!--
        <div class="card" fxFlex="98" fxFlex.gt-sm="48" fxFlex.gt-md="31" fxFlex.gt-lg="23">
            <div class="card-header">
                Aufrufe Präsentationen nach Häufigkeit
            </div>
            <div class="card-block">
                <div class="card-text" fxLayout="row" fxLayoutAlign="center center">
                    <clr-icon shape="pie-chart" size="256"></clr-icon>
                </div>
            </div>
        </div>
        -->

    </div>

</div>
