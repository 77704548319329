import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-actions-add',
  templateUrl: './de-actions-add.component.html'
})
export class DeActionsAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
