<div class="card widget-card" style="margin-top: 0 !important;" fxLayout="column">
    <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{localization.dictionary.dashboard.top}} {{ (total > 0) ? total : ''}} {{localization.dictionary.dashboard.documentsMailDelivery}}</span>
    </div>
    <div class="card-block widget" fxFill>
      <div class="card-text widget" >
        <ng-container *ngIf="!loading; else spinner">
          <ngx-charts-bar-vertical
            class="widget-text"
            [legend]="false"
            [legendTitle]="''"
            [results]="data"
            [xAxis]="true"
            [yAxis]="true"
            #chart
            >
          </ngx-charts-bar-vertical>
        </ng-container>

        <ng-template #noData>
          <app-no-data></app-no-data>
        </ng-template>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </div>
    </div>

  <div class="card-footer">
    <app-footer-widgets [chart]="chart" [widgetTitle]="localization.dictionary.dashboard.documentsMailDelivery" [data]="data" widgetName="mail"></app-footer-widgets>
  </div>
</div>
