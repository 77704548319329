import { Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocalizationService } from '../../../localization/localization.service';
import { Unsubscribable } from '../../unsubscribable/unsubscribable';

@Component({
  selector: 'string-filter',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.css']
})
export class StringComponent extends Unsubscribable implements ClrDatagridFilterInterface<any>, OnChanges, DoCheck {

  @Input() property: string;
  @Input() confirm = false
  @Input() filter: string;
  @Output() filterChange = new EventEmitter<string>()
  changes = new Subject<any>();
  open = false
  focus = true

  textQueryChanged: Subject<string> = new Subject<string>();

  value: string;

  @ViewChild("search") inputRef: ElementRef<HTMLInputElement>

  constructor(private filterContainer: ClrDatagridFilter,
    public localization: LocalizationService ) {
    super();
    filterContainer.setFilter(this);
    this.subscriptions.add(
      this.textQueryChanged
        .pipe(debounceTime(500))
        .subscribe(() => {
          this.value = this.filter
          this.filterChange.emit(this.filter)
          this.changes.next('')
        })
    );
  }

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.open && this.focus && this.inputRef) {
      this.focus = false
      setTimeout(() => this.inputRef.nativeElement.focus())
    }
    if (!this.open && !this.focus) {
      this.focus = true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "filter" && !this.filter) {
          this.value = undefined
        }
        if (propName == "filter" && this.filter) {
          this.value = this.filter
        }
      }
    }
  }

  onChange() {
    if (this.confirm)
      return
    this.textQueryChanged.next('')
  }

  onClick(reset?: boolean) {
    if (reset)
      this.filter = undefined
    this.value = this.filter
    this.filterChange.emit(this.filter)
    this.changes.next('')
    this.open = false;
    return false
  }

  isActive(): boolean {
    return (this.confirm ? this.value && this.value.length > 0 : this.filter && this.filter.length > 0);
  }
  accepts(): boolean {
    return true
  }
}
