import { Component, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { Section } from '../../views/section.model';
import { View } from '../../views/view.model';
import { Field } from '../field.model';
import { CrmService } from '../../crm.service';

@Component({
  selector: 'app-list-fields',
  templateUrl: './list-fields.component.html',
  styleUrls: ['./list-fields.component.css']
})
export class ListFieldsComponent implements OnInit, DatagridFunctions {
  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  loading = false;
  toDelete: Field[];
  referencedFields: Field[] = [];

  total: number;

  selected: Field[] = [];
  selectedField: Field;
  fieldViews: View[] = [];

  state: ClrDatagridStateInterface;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  };
  columnChange = false;
  showDeleted = false;
  restoreModal = false;
  sureToDelete = false;

  fields: Field[] = [];

  toDeleteSections: Section[];

  constructor(private server: ServerService,
    public helper: HelperService,
    public auth: AuthService,
    public localization: LocalizationService,
    private crm: CrmService
  ) {

  }

  ngOnInit(): void {
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state;
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const res = await this.server.get('crm/field' + ((this.showDeleted == true) ? '/allFields/' : '') + query);
    this.total = res.count;
    this.fields = plainToClass(Field, res.rows);
    this.loading = false;
    this.crm.setViews();
  }

  referencedForms() {
    this.loading = true;
    this.toDelete.forEach(field => {
      if (field.questions.length > 0) {
        this.referencedFields.push(field);
      }
    }
    )
    this.loading = false;
  }

  async onDelete(fields: Field[]) {
    const fieldIds = fields.map((fiel) => fiel.id);
    const res = await this.server.get('crm/field/getOrphans?ids=' + fieldIds);
    this.toDeleteSections = plainToClass(Section, res) as any;
    this.toDelete = fields;
    this.referencedForms();
  }


  async delete() {
    this.loading = true;
    const fieldIds = this.toDelete.map((doc) => doc.id);
    fieldIds.forEach(async (id) => await this.server.put('form/deleteLinks/' + id, id));

    await this.server.delete('crm/field?toDelete=' + fieldIds);
    if (this.toDeleteSections && this.toDeleteSections.length > 0) {
      this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successDeleted
          .replace('${componentName}', this.localization.dictionary.general.and
            .replace('${first}', this.localization.dictionary.field['name' + (this.toDelete.length == 1 ? 'Singular' : '')])
            .replace('${second}', this.localization.dictionary.viewCreateEdit['section' + (this.toDeleteSections.length == 1 ? '' : 's')])
          )
        //  `Sektion wird auch mit Felder gelöscht`
      ));
    } else {
      this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successDeleted
          .replace('${componentName}',
            this.toDelete.length == 1 ? this.localization.dictionary.field.nameSingular : this.localization.dictionary.field.name)
        // `Felder erfolgreich gelöscht`
      ));
    }
    this.toDelete = undefined;
    this.selected = [];
    this.referencedFields = [];
    this.refresh();
    this.crm.init();
  }

  hidChange() {

  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters
    this.refresh(this.state)
  }

  openRestoreModal(field: Field) {
    this.selectedField = field;
    this.restoreModal = true;
  }

  async restore() {
    await this.server.put('crm/field/restore', { fieldString: JSON.stringify(this.selectedField) });
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successRestored
        .replace('${componentName}', this.localization.dictionary.field.nameSingular)
        .replace('${entryName}', this.selectedField.name)
      // `Feld erfolgreich wiederhergestellt`
    ));
    this.refresh();
    this.restoreModal = false;
    this.crm.init();
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

}
