import { Expose, Type } from "class-transformer";
import { User } from "src/app/users/user.model";
import { Document } from "../../documents/document.model";
import { Contact } from "../contacts/contact.model";
import { Customer } from "../customers/customer.model";
import { Product } from "../products/product.model";

export class Call {

  @Expose()
  id: string;

  @Expose()
  @Type( () => Customer)
  customer: Customer;

  @Expose()
  customerId: string

  @Expose()
  @Type( () => User)
  users: User[] = [];

  @Expose()
  createdByUser: string;

  @Expose()
  @Type( () => Contact)
  contacts: Contact[] = [];

  @Expose()
  @Type( () => Product)
  products: Product[] = []; 

  @Expose()
  @Type( () => Document)
  documents: Document[] = [];

  deletedAt: any;

}  