import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Unsubscribable } from '../../../helper/unsubscribable/unsubscribable';
import { TrackingService } from '../../tracking.service';

@Component({
  selector: 'app-events-by-os',
  templateUrl: './events-by-os.component.html',
  styleUrls: ['./events-by-os.component.css']
})
export class EventsByOsComponent extends Unsubscribable implements OnInit {
  @ViewChild('chart') chart: ElementRef;

  loading = false;
  total = 0;

  barChartLabels = [];
  enumOrder = ['LOGIN', 'SYNCHRONIZATION', 'PRESENTATION', 'SLIDE_OPENED', 'MAIL_SENT'];

  from: Date;
  to: Date;

  data = [];

  constructor(private tracking: TrackingService, public helper: HelperService,
    public localization: LocalizationService) {
      super();
      this.subscriptions.add(
        this.localization.languageChanged.subscribe(()=> {
          this.refresh();
        })
      );
    }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }

  initValues() {
    this.total = 0;
    this.data = [];
    
    this.barChartLabels = [
      this.localization.dictionary.dashboard.login,
      this.localization.dictionary.legendTranslation.SYNCHRONIZATION,
      this.localization.dictionary.dashboard.presentation,
      this.localization.dictionary.dashboard.openedPage,
      this.localization.dictionary.dashboard.mailSent
    ];

    for (let label of this.barChartLabels) {
      this.data.push({
        name: label,
        series: [
          {
            name: this.localization.dictionary.dashboardInteractions.interactionsByIOS,
            value: 0
          },
          {
            name: this.localization.dictionary.dashboardInteractions.interactionsByAndroid,
            value: 0
          },
          {
            name: this.localization.dictionary.dashboardInteractions.interactionsByWindows,
            value: 0
          }
        ]
      })
    }
  }

  async refresh(from?: Date, to?: Date) {
    this.loading = true;
    this.initValues();
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
    const data = await this.tracking.getEventsByOs('?' + query.toString()) as any;
    for (let j = 0; j < data.length; j++) {
      for (let i = 0; i < this.enumOrder.length; i++) {
        const event = data[j].count.find( (event) => event.eventType === this.enumOrder[i]);
        if (event && event.count) {
          this.data[i].series[j].value = event.count;
          this.total += event.count as number;
        }

      }
    } 
    this.loading = false;
  }

}
