<app-spinner *ngIf="loading; else card"></app-spinner>

<ng-template #card>
    <div class="card">
        <div class="card-header">
            {{ (push) ? helper.getCardHeader(mode, 'push', push.title) : '' }}
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                  <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.createEdit.baseInformationDescription.replace('${componentDative}', localization.dictionary.push.dative)}}
                </p>  
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                    (click)="collapse[0] = !collapse[0]"></clr-icon>
            </div>
            <div [hidden]="collapse[0]" class="card-text">
                <form clrForm (change)="dirty = true" *ngIf="push" class="icon-offset">
                    <clr-input-container>
                        <label>{{localization.dictionary.push.title + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="push.title" name="title" required maxlength="100"/>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-input-container>
                    <clr-textarea-container>
                        <label>{{localization.dictionary.push.message + '*'}}</label>
                        <textarea clrTextarea [(ngModel)]="push.msg" name="msg" required maxlength="250"></textarea>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-textarea-container>
                    <clr-select-container>
                        <label>{{localization.dictionary.push.updateType}}</label>
                        <select clrSelect name="updateType" [(ngModel)]="push.updateType" required>
                            <option [value]="pushUpdateTypes.NONE">{{localization.dictionary.pushType[pushUpdateTypes.NONE]}}</option>                        
                            <option [value]="pushUpdateTypes.CATEGORY">{{localization.dictionary.pushType[pushUpdateTypes.CATEGORY]}}</option>
                        </select>
                    </clr-select-container>
                    <clr-select-container *ngIf="push.updateType === pushUpdateTypes.CATEGORY">
                        <label>{{localization.dictionary.category.name}}</label>
                        <select clrSelect name="categoryId" [(ngModel)]="push.categoryId" required>
                            <option *ngFor="let cat of categories" [value]="cat.id">{{ cat.name }}</option>
                        </select>
                    </clr-select-container>
                    <clr-input-container *ngIf="mode !== 'create'">
                        <label>{{localization.dictionary.push.lastDispatch}}</label>
                        <input clrInput type="text" name="sentAt" [attr.value]="(push.sentAt) ? helper.formatDate(push.sentAt) : localization.dictionary.pushList.notSent" readonly />
                    </clr-input-container>
                </form>
            </div>
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                   <clr-icon shape="tree-view" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.pushCreateEdit.selectReceiver}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.pushCreateEdit.selectReceiverDescription}}
                </p> 
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[1] ? 'down' : 'left'"
                    (click)="collapse[1] = !collapse[1]"></clr-icon>
            </div>
            <div [hidden]="collapse[1]" class="card-text icon-offset">
                <app-list-selector *ngIf="push && push.groups" [preLoaded]="push.groups" [query]="'group'"
                 [required]="push.groups.length == 0 && push.users.length == 0" (customForms)="addCustomForms($event)" 
                 (customFormDatas)="addCustomFormDatas($event)"
                 (dirtyChanged)="dirty = $event"
                    [label]="localization.dictionary.group.name" (selectedChanged)="push.groups = $event;"> 
                </app-list-selector>
                <app-list-selector *ngIf="push && push.users" [preLoaded]="push.users" [query]="'user/filter?sortBy=name'"
                 [required]="push.groups.length == 0 && push.users.length == 0" (customForms)="addCustomForms($event)" 
                 (customFormDatas)="addCustomFormDatas($event)"
                 (dirtyChanged)="dirty = $event"
                    [label]="localization.dictionary.user.name" (selectedChanged)="push.users = $event;" #userList>
                </app-list-selector>
            </div>
        </div>
        <div stickyFooter class="card-footer">
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>
        </div>
    </div>
</ng-template>

<!--
    ACTION BAR
-->
<ng-template #actionBar>
    <button class="btn btn-primary" (click)="validateAndSave()">
        {{ ( mode=== 'create' ) ? localization.dictionary.button.create : localization.dictionary.button.save}}
    </button>
    <button class="btn btn-primary" (click)="validateAndSave(true)">
        {{ localization.dictionary.button.saveAndStay }}
    </button>
    <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
    <button class="btn btn-outline" (click)="validateAndSend()" [disabled]="mode === 'create' || dirty === true || push?.userCount == 0">
        {{ localization.dictionary.button.send }}
    </button>
</ng-template>

<app-send-modal *ngIf="sendModal" [sendModal]="sendModal" [push]="push" (sendPush)="send()" (abort)="sendModal = false">
</app-send-modal>