import { Component, Input, OnInit} from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ModalService } from '../dashboards/diagram-popup/modal-data.service';

@Component({
  selector: 'app-footer-widgets',
  templateUrl: './footer-widgets.component.html',
  styleUrls: ['./footer-widgets.component.css']
})
export class FooterWidgetsComponent implements OnInit {
   @Input() chart;
   @Input() widgetName: string;
   @Input() data: any;
   @Input() widgetTitle: string;
  
  loading = false;
  isDarkMode = false;
  isOpen = false;
  
  constructor(public helper: HelperService,
    public localization: LocalizationService,
    public modalService: ModalService) {
    }
     
  ngOnInit() {
  }

  
  async openChart() {
    if (this.chart) {
      this.modalService.openModal(this.chart, this.widgetTitle, this.data, this.widgetName);
    }
  }
}

