import { Exclude, Expose, Type } from 'class-transformer';

export class CustomerContact {  

    @Expose()
    position: string = '';

    @Expose()
    @Type(() => Date)
    deletedAt: Date;

}

