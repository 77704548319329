<clr-modal [(clrModalOpen)]="sendModal">
    <h3 class="modal-title">{{ localization.dictionary.push.sendModalTitle }}</h3>
    <div class="modal-body">
        <clr-alert *ngIf="push.sentAt" clrAlertType="info" [clrAlertClosable]="false">
            <clr-alert-item>{{ localization.dictionary.push.sendModalAlert.replace('${sentAt}', push.sentAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector) }}</clr-alert-item>
        </clr-alert>
        <p>{{ localization.dictionary.push.sendModalToUsers.replace('${title}', push.title) }}</p>
        <br/>
        <ul>            
            <li *ngFor="let user of push.users">
                {{ user.name + ' - (' + user.firstName + ' ' + user.lastName + ')' }}
            </li>
        </ul>

        <p>{{ localization.dictionary.group.name }}:</p>
        <ul>
            <li *ngFor="let group of push.groups">
                {{ group.name }}
            </li>
        </ul>
        <p>{{ localization.dictionary.push.sendModalContent }}</p>
        <span style="font-style: italic;">
            {{ push.msg }}
        </span>
        <p *ngIf="push.updateType === pushUpdateTypes.APP">
            {{ localization.dictionary.push.sendModalAppUpdate }}
        </p>
        <p *ngIf="push.updateType === pushUpdateTypes.CATEGORY">
            {{ localization.dictionary.push.sendModalSync }}
        </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="abort.emit(true)">{{ localization.dictionary.button.cancel }}</button>
      <button type="button" class="btn btn-primary" (click)="sendPush.emit(true)">{{ localization.dictionary.button.send }}</button>
    </div>
  </clr-modal>