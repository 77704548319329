<div class="drag-item" cdkDragBoundary=".drag-boundary" cdkDrag>
    <div class="drag-placeholder" *cdkDragPlaceholder></div>
    <div *ngIf="question.type === 'SECTION'" class="section-tab">
        <h4>{{localization.dictionary.formCreateEdit.section}} {{ sectionIndex }} {{localization.dictionary.dashboard.timeFrom}} {{ sectionTotal }}</h4>
    </div>
    <div *ngIf="question.type === 'SECTION'" class="section"></div>
    
    <div class="card" style="margin-bottom: 0; margin-top: 0;" [ngClass]="(active) ? 'active' : 'inactive'" [ngSwitch]="question.type">
        <clr-alert 
            *ngIf="!valid" 
            clrAlertType="danger" 
            [clrAlertClosable]="false"
            [clrAlertSizeSmall]="true">
            {{localization.dictionary.createEdit.invalidInput}}
        </clr-alert>
        <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
            <div class="question-name" fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="start center">
                <div style="position: relative;"
                    [pTooltip]="question.reference 
                        ? localization.dictionary.formQuestionType[question.type] + ' (Referenzfeld)' 
                        : localization.dictionary.formQuestionType[question.type]"
                    [tooltipPosition]="'bottom'">
                    <clr-icon
                        size="32"
                        [attr.shape]="mapIcons[question.type]">
                    </clr-icon>
                    <clr-icon
                        [ngStyle]="!branding.darkMode 
                            ? {'background-color': 'white', 'color': '#21333B', 'outline': '1.5px solid white'} 
                            : {'background-color': '#21333B', 'color': 'white', 'outline': '1.5px solid #21333B'}"
                        class="reference-overlapping"
                        *ngIf="question.reference"
                        size="18"
                        [attr.shape]="mapIcons['REFERENCE']">
                    </clr-icon>
                </div>
                
                <input
                    *ngIf="active" 
                    class="clr-input" 
                    [(ngModel)]="question.name" 
                    name="name" 
                    style="font-size: .9rem; margin-top: 0; width: 24rem; padding-left: 0;" 
                    (ngModelChange)="onChange()"
                    autofocus
                    autocomplete="off">
                <h4 [class.disabled]="question.disabled" *ngIf="!active" style="padding-bottom: 0.05rem;">{{question.name}}</h4>
            </div> 
            
            <!--
                Top-Right tools (edit, close)
            -->
            <div fxLayoutGap="1rem" fxLayout="row">
                <clr-icon
                    size="24"
                    shape="check"
                    (click)="deactivated()"
                    [pTooltip]="localization.dictionary.button.confirm"
                    [tooltipPosition]="'bottom'"
                    *ngIf="active">
                </clr-icon>

                <clr-icon
                    size="24"
                    shape="pencil"
                    (click)="active = true"
                    [pTooltip]="localization.dictionary.button.edit"
                    [tooltipPosition]="'bottom'"
                    *ngIf="!active">
                </clr-icon>

                <clr-icon
                    size="24"
                    shape="cursor-move"
                    cdkDragHandle
                    [pTooltip]="localization.dictionary.formCreateEdit.move"
                    [tooltipPosition]="'bottom'">
                </clr-icon>
            </div>
        </div>
        <div *ngIf="active || !question.disabled" class="card-block">
            <div class="card-text">
                <form clrForm *ngIf="active">
                    <clr-textarea-container style="margin-top: 0 !important;">
                        <label> {{localization.dictionary.general.description}} {{ (question.type !== 'DESCRIPTION') ? '(optional)' : ''}}</label>
                        <textarea clrTextarea [(ngModel)]="question.description" name="description"
                            [attr.required]="(question.type === 'DESCRIPTION')" (ngModelChange)="onChange()" maxlength="250">
                        </textarea>
                    </clr-textarea-container>
                    <clr-toggle-container *ngIf="!['SECTION', 'DESCRIPTION'].includes(question.type)">
                        <label>{{localization.dictionary.formCreateEdit.mandatoryField}}</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="mandatory" [value]="true"
                                [(ngModel)]="question.mandatory" (ngModelChange)="onChange()" />
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-toggle-container
                        *ngIf="formType === 'customer' && !['SECTION', 'DESCRIPTION'].includes(question.type) && question.reference">
                        <label>{{localization.dictionary.formCreateEdit.reference}}</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="reference" [disabled]="true" [value]="true"
                                [(ngModel)]="question.reference" (ngModelChange)="onChange()" />
                        </clr-toggle-wrapper>                        
                    </clr-toggle-container>
                    <clr-toggle-container
                        *ngIf="formType === 'customer' && !['SECTION', 'DESCRIPTION'].includes(question.type) && question.reference">
                        <label>{{localization.dictionary.formCreateEdit.readOnly }}</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="readOnly" [value]="false"
                                [(ngModel)]="question.readOnly" (ngModelChange)="onChange()" />
                        </clr-toggle-wrapper>                    
                    </clr-toggle-container>
                </form>
                <div *ngIf="!active" fxLayout="column" class="show-answers">
                    <div fxLayout="row">
                        <span *ngIf="question.description" class="description">{{ question.description }}</span>
                        <span class="no-description" *ngIf="!question.description">
                            {{localization.dictionary.formCreateEdit.noDescription}}
                        </span>
                    </div>
                    <div fxLayout="row">
                        <span *ngIf="question.mandatory" class="mandatory">  {{localization.dictionary.formCreateEdit.mandatoryField}}</span>
                    </div>
                    <div fxLayout="row">
                        <span *ngIf="question.readOnly" class="mandatory">  {{localization.dictionary.formCreateEdit.readOnly}}</span>
                    </div>
                </div>

            </div>
        </div>
        <ng-container *ngIf="active || !question.disabled">
            <div class="card-block" *ngSwitchCase="'CHOICE'">
                <app-choice [question]="question" [type]="'CHOICE'" [active]="active"
                    (changed)="onChange($event)" [fieldReference]="fieldReference"[published]="published" ></app-choice>
            </div>
            <div class="card-block" *ngSwitchCase="'CHECKBOX'">
                <app-choice [question]="question" [type]="'CHECKBOX'" [active]="active"
                    (changed)="onChange($event)" [fieldReference]="fieldReference"[published]="published" ></app-choice>
            </div>
            <div class="card-block" *ngSwitchCase="'MULTIPLE_CHOICE'">
                <app-choice  [question]="question" [type]="'MULTIPLE_CHOICE'"
                    [active]="active" (changed)="onChange($event)"[published]="published" ></app-choice>
            </div>
            <div class="card-block" *ngSwitchCase="'SELECTION'">
                <app-choice  [question]="question" [type]="'SELECTION'"
                    [active]="active" (changed)="onChange($event)"[published]="published" ></app-choice>
            </div>
            <div *ngSwitchCase="'SIGNATURE'">
            </div>
            <div class="card-block" *ngSwitchCase="'TEXT'">
                <app-text *ngIf="!fieldReference" [question]="question" [active]="active" (changed)="onChange($event)">
                </app-text>
            </div>
            <div class="card-block" *ngSwitchCase="'RANGE'">
                <app-range [question]="question" [active]="active" (changed)="onChange($event)"></app-range>
            </div>
            <div *ngSwitchCase="'TEXTAREA'">
    
            </div>
            <div *ngSwitchCase="'DESCRIPTION'">
            </div>
            <div class="card-block" *ngSwitchCase="'REFERENCE'">
                <app-ref-selector [table]="question.refTable" [field]="question.refField" [questionType]="question.type"
                    (changed)="setRefField($event)"></app-ref-selector>
            </div>
        </ng-container>

        <div *ngIf="active" class="card-footer">
            <button class="btn btn-outline" (click)="deactivated()">{{localization.dictionary.button.confirm}}</button>
            <button *ngIf="question.disabled || (published && !question.new)" [class]="'btn btn-' + (question.disabled ? 'primary' : 'danger')" (click)="toggleDisabled()">{{localization.dictionary.formCreateEdit[question.disabled ? 'activate' : 'deactivate']}}</button>
            <button *ngIf="!published || question.new" class="btn btn-danger" (click)="deleteQuestion.emit()">{{localization.dictionary.button.delete}}</button>
        </div>
    </div>
</div>