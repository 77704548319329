import { Injectable } from '@angular/core';
import * as filesaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import { Language } from 'src/app/localization/language.model';
import { plainToClass } from 'class-transformer';
import { ServerService } from './server.service';
import { LocalizationService } from './localization/localization.service';
import { SettingService } from './settings/setting.service';
import { View } from './crm/views/view.model';
import { Field } from './crm/fields/field.model';
import { QuestionType } from './forms/question.model';
import { ErrorService } from './helper/error/error.service';
import { AmselError } from './helper/error/amsel-error.model';
import { Product } from './crm/products/product.model';

class ExcelError {
  message: 'fileMissmatch' | 'despiteErrors' | 'mandatoryValue' | 'failedValidation' | 'duplicate';
  line?: number;
  field?: string;
  fieldValue?: string;
  count?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  file: File;
  result: any[];
  
  constructor(
    private localization: LocalizationService,
    private server: ServerService,
    private setting: SettingService,
    private errorService: ErrorService
  ) { }

  async downloadExcel(excel: any, filename: string) {
    filesaver.saveAs(new Blob([excel]), filename + '.xlsx' );
  }

  async getFile(event: any): Promise<File> {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    this.file = target.files[0];
    return this.file;
  }

  async deleteFile(): Promise<void> {
    this.file = undefined;
  }

  async exportExcel(table: HTMLTableElement, fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Language');
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  async downloadExcelLanguageFromList(languageId) {
    let baseLanguageId = this.localization.language.id
    if (baseLanguageId == languageId) {
      baseLanguageId = this.localization.languages.find(lang => lang.id != languageId)?.id
    }
    const language = plainToClass(Language, await this.server.get('language/byId/' + languageId));
    const base = plainToClass(Language, await this.server.get('language/byId/' + baseLanguageId));
    language.dictionaries.filter(dict => !this.setting.featureExcludes.includes(dict.languageCategory.feature));
    language.dictionaries = language.dictionaries.map(dict => {
      return {
        baseLanguage: base.dictionaries.find(baseDict =>
          baseDict.element == dict.element && baseDict.languageCategoryId == dict.languageCategoryId)?.label || ''
        , ...dict
      }
    });
    let buffer = await this.downloadExcelLanguage(language, base.name);
    this.downloadExcel(buffer, language.name);    
  }

  async downloadExcelLanguage(language: Language, baselanguageName: string): Promise<ExcelJS.Buffer> {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet();
    let columns = [];
    let dataHeader = /* ['category', 'element', 'base', 'label', 'tag']; */
      [this.localization.dictionary.language.category, this.localization.dictionary.language.element,
      baselanguageName, language.name?.length > 0 ? language.name : this.localization.dictionary.language.new,
      this.localization.dictionary.language.tag]
    /*   add header */
    for (let key of dataHeader) {
      columns.push({ header: key, key: key });
    }
    worksheet.columns = columns;
    worksheet.getRow(1).eachCell(function (cell, colNumber) {
      if (cell.value) {
        worksheet.getRow(1).getCell(colNumber).font = { bold: true, color: { argb: 'FFFFFFFF' } };
        worksheet.getRow(1).getCell(colNumber).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF2C3E50' } };
      }
    });
    /* add rows */
    const dictionaries = language.dictionaries.filter(dict => !this.setting.featureExcludes.includes(dict.languageCategory.feature))
    for (let element of dictionaries.sort((a, b) => a.languageCategory.name < b.languageCategory.name ? -1 : 1)) {
      let dataRows = [element.languageCategory.name, element["element"], element["baseLanguage"], element?.label, element["tag"]];
      worksheet.addRow(dataRows);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  }

  async downloadInitialExcel(headers: string[]): Promise<ExcelJS.Buffer> {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet();
    let columns = [];
    let dataHeader = headers;
    /*   add header */
    for (let key of dataHeader) {
      columns.push({ header: key, key: key });
    }
    worksheet.columns = columns;
    worksheet.getRow(1).eachCell(function (cell, colNumber) {
      if (cell.value) {
        worksheet.getRow(1).getCell(colNumber).font = { bold: true, color: { argb: 'FFFFFFFF' } };
        worksheet.getRow(1).getCell(colNumber).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF2C3E50' } };
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  }

  async importInitialXLSX(evt: any, views: View[]): Promise<string>{
    let errors: ExcelError[] = [];

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1){
      throw new Error('Cannot use multiple files');
    } 
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    let promise: Promise<any> =  new Promise((resolve, reject) => {
      reader.onload = async (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        //Array with Rows of the Excel File includes null values excludes empty rows
        const excelData =  await XLSX.utils.sheet_to_json(ws, { header: 1,blankrows: false, defval: null }) as any[];

        //Get Timezone Offset to convert Time Format
        let timezoneOffsetMinutes = this.getTimezoneOffset();
        let languageId = this.localization.language.id;            
            errors = await this.server.post(`crm/excelImport/initialImport`, {data: excelData, views,languageId, timezoneOffsetMinutes});
          
          //last Error is the info message
          if(errors[0].message){
              for(let i = errors.length - 2; i >= 0; i--){
                this.errorService.addError(new AmselError(undefined, 'warning', this.localization.dictionary.excel[errors[i].message].replace('${line}', ''+errors[i].line).replace('${field}', errors[i].field).replace('${fieldValue}', errors[i].fieldValue).replace('${count}', ''+errors[i].count) ));
              }
              this.errorService.addError(new AmselError(undefined, 'info', this.localization.dictionary.excel.despiteErrors.replace('${count}', ''+errors[errors.length-1].count)) );
              reject();
          } else {
            resolve(new AmselError(undefined, 'success', (this.localization.dictionary.excel.imported.replace('${importedCount}', ''+excelData.length))));
          }     
        };
    });

    const finished = await promise.then(
        (result: AmselError) => {
          this.errorService.addError(result);          
          return 'success';
        },
        (error) => {
          return 'error';
        }
      )
    return finished;
  }
  
  async importXLSXExtrafields(evt: any, view: View): Promise<string> {
    let fields: Field[] = [];
    for(let section of view.sections){
      for(let field of section.fields){
        fields.push(field);
      }
    }
    if(view.type == 'customer'){
      //TODO
      //Hardcoded muss in dieser Reihenfolge stehen improvement nötig
      let products = new Field();
      products.label = 'Products';
      products.type = QuestionType.PRODUCT;
      fields.push(products);
      
      let territory = new Field();
      territory.label = 'Territory';
      territory.type = QuestionType.TERRITORY;
      fields.push(territory);
      
      let customer = new Field();
      customer.label = 'Contacts';
      customer.type = QuestionType.CONTACT;
      fields.push(customer);

    } else if (view.type == 'contact') {
        let customers = new Field();
        customers.label = 'Customers';
        customers.type = QuestionType.CUSTOMER;
        fields.push(customers);
    }
    return this.importXLSX(evt, view, fields)
  }


  async importXLSX(evt: any, view: View, fields: Field[]): Promise<string> {
    
    let errors: string[] = [];

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1){
      throw new Error('Cannot use multiple files');
    } 
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    let promise: Promise<any> =  new Promise((resolve, reject) => {
      reader.onload = async (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        //Array with Rows of the Excel File includes null values excludes empty rows
        const excelData =  await XLSX.utils.sheet_to_json(ws, { header: 1,blankrows: false, defval: null }) as any[];
          if(excelData[0].length != fields.length){
            this.errorService.addError(new AmselError(undefined, 'warning', 'The Excel File does not match the View'));
            reject();
            return;
            
          }
          try{
            //Get Timezone Offset to convert Time Format
            let timezoneOffsetMinutes = this.getTimezoneOffset();
            errors = await this.server.post(`crm/excelImport/import/${view.type}`, {data: excelData, view: view, timezoneOffsetMinutes});
          } catch(e) {            
            this.errorService.addError(new AmselError(undefined, 'warning', e.error.message));
            reject();
            return;
          }
          if(typeof errors[0] == 'string'){
              for(let e of errors){
                this.errorService.addError(new AmselError(undefined, 'warning', e));
              }
              reject();
          }else{
            resolve(new AmselError(undefined, 'success', (this.localization.dictionary.excel.imported.replace('${importedCount}', ''+(excelData.length-1)))));
          }
          
        };
    });

    const finished = await promise.then(
        (result: AmselError) => {
          this.errorService.addError(result);          
          return 'success';
        },
        (error) => {
          return 'error';
        }
      );   
    return finished;
  }

  getTimezoneOffset(): number{
    const now = new Date();
    let timezoneOffsetMinutes: number = now.getTimezoneOffset();    
    if(timezoneOffsetMinutes > 0){
      timezoneOffsetMinutes -= 60;
    }else{
      timezoneOffsetMinutes += 60;
    }
    
    return timezoneOffsetMinutes;
  }

  async importProducts(evt: any): Promise<string>{
    let errors: string[] = [];

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1){
      throw new Error('Cannot use multiple files');
    } 
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    let promise: Promise<any> =  new Promise((resolve, reject) => {
      reader.onload = async (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        //Array with Rows of the Excel File includes null values excludes empty rows
        const excelData =  await XLSX.utils.sheet_to_json(ws, { header: 1,blankrows: false, defval: null }) as any[];
        
        //Remove the header
        excelData.shift();
        let row = 0;
        for (const data of excelData) {
          row++;
          let product = new Product();
          if(data[0] == '' || data[0] == undefined){
            errors.push(this.localization.dictionary.product.missingName + ' (' + this.localization.dictionary.excel.row + ' ' + row + ')');
          } else if ( data[4] && /^\d{1,8}([.,]\d{0,2})?$/.test(data[4]) == false) {
            errors.push(this.localization.dictionary.product.wrongPrice + ' (' + this.localization.dictionary.excel.row + ' ' + row + ')');
          } else {
            if(  /[,]/.test(data[4])){
              data[4] = data[4].toString().replace(',', '.');
            }
            product.name = data[0];
            product.description = data[1];
            product.itemNumber = data[2];
            product.unit = data[3];
            product.price = data[4];   
            try {
              await this.server.post('crm/product', product);
            } catch (err) {
              errors.push(err.msg || err.message)
            }
          }
        }         
        
        for(let error of errors){
          this.errorService.addError(new AmselError(undefined, 'warning', error));
        }   
        if (errors.length == excelData.length) {
          reject();
        }
        resolve(new AmselError(undefined, 'success', (this.localization.dictionary.excel.imported.replace('${importedCount}', '' + (excelData.length - errors.length)))));
      };
    });

    const finished = await promise.then(
        (result: AmselError) => {
          this.errorService.addError(result);          
          return 'success'
        },
        (error) => {
          return 'error';
        }
      )
    return finished;
  }

}

