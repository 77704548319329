<ng-container *ngTemplateOutlet="basic"></ng-container>

<app-spinner *ngIf="loading"></app-spinner>


<!--
    BASIS INFORMATIONEN
-->
<ng-template #basic>
    <div class="card" *ngIf="!loading">
        <div class="card-header">
            <span>{{ helper.getCardHeader(mode, 'document', document.name) }}</span>
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.documentCreateEdit.baseInformationDescription}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[0]? 'down' : 'left'"
                    (click)="collapse[0] = !collapse[0]"></clr-icon>
            </div>
            <div [hidden]="collapse[0]
            " class="card-text">
                <form clrForm enctype="multipart/form-data" (change)="dirty = true" #formData="ngForm"
                    class="icon-offset">
                    <clr-input-container>
                        <label>{{localization.dictionary.general.name + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="document.name" name="name" required fileNameValidator
                            maxlength="100" #name="ngModel" />
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error
                            *clrIfError="'forbiddenFileName'">{{localization.dictionary.documentCreateEdit.invalidName}}</clr-control-error>
                    </clr-input-container>

                    <clr-textarea-container>
                        <label>{{localization.dictionary.general.description + '*'}}</label>
                        <textarea clrTextarea [(ngModel)]="document.description" name="description" required
                            maxlength="250"></textarea>
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-textarea-container>

                    <clr-select-container>
                        <label>{{localization.dictionary.type.nameSingular + '*'}}</label>
                        <select clrSelect name="type" [(ngModel)]="document.type" required>
                            <option [value]="documentTypes.FILE">
                                {{localization.dictionary.documentType[documentTypes.FILE]}}</option>
                            <option [value]="documentTypes.FORM">
                                {{localization.dictionary.documentType[documentTypes.FORM]}}</option>
                        </select>
                    </clr-select-container>

                    <clr-combobox-container *ngIf="document.type === documentTypes.FORM">
                        <label>{{localization.dictionary.form.nameSingular + '*'}}</label>
                        <clr-combobox [(ngModel)]="document.form" name="form">
                            <clr-options>
                                <clr-option *clrOptionItems="let form of forms; field:'name'" [clrValue]="form">
                                    <div [pTooltip]="form.description"
                                                [tooltipPosition]="'right'"
                                                >
                                            {{ form.name }}
                                    </div>
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                    </clr-combobox-container>

                    <div class="clr-form-control upload" *ngIf="document.type === documentTypes.FILE">
                        <label class="clr-control-label upload-label">{{localization.dictionary.documentCreateEdit.file
                            + '*'}}</label>
                        <div class="clr-control-container">
                            <div class="clr-file-wrapper clr-error">
                                <label for="file" class="clr-control-label upload-label upload-label-extended"
                                    fxLayout="row" fxLayoutGap="1rem">
                                    <span class="btn btn-sm"
                                        [class.btn-danger]="!file && !document.filename && invalid">{{ (file ||
                                        document.filename) ? localization.dictionary.button.change :
                                        localization.dictionary.button.browse}}</span>
                                    <button *ngIf="mode === 'edit' && document.filename" class="btn btn-sm"
                                        (click)="download()">
                                        <clr-spinner *ngIf="downloading" [clrInline]="true"></clr-spinner>
                                        {{localization.dictionary.button.download}}
                                    </button>
                                </label>
                                <input type="file" id="file" name="file" [accept]="allowedFiles"
                                    (change)="upload($event.target.files)" placeholder="Enter value here"
                                    class="clr-file" />
                                <span *ngIf="file">
                                    <clr-icon shape="pdf" *ngIf="file.name.includes('.pdf')"></clr-icon>
                                    <clr-icon shape="file-zip" *ngIf="file.name.includes('.zip')"></clr-icon>
                                    {{ file.name }}
                                </span>
                                <clr-control-error class="clr-subtext" *ngIf="!file && !document.filename && invalid">
                                    {{localization.dictionary.documentCreateEdit.requiredFile}}</clr-control-error>
                            </div>
                        </div>
                    </div>
                    <clr-combobox-container>
                        <label fxLayout="row"
                            fxLayoutAlign="space-between center">{{localization.dictionary.createEdit.icon}}<clr-icon
                                *ngIf="currentIcon" [attr.shape]="currentIcon?.element"></clr-icon></label>
                        <clr-combobox [(ngModel)]="currentIcon" name="icon" required
                            (clrOpenChange)="changeIcon($event)">
                            <clr-options>
                                <clr-option *clrOptionItems="let icon of localization.amselIconList; field: 'label'"
                                    [clrValue]="icon">
                                    <clr-icon [attr.shape]="icon.element" role="img"></clr-icon>
                                    <span style="margin-left: 0.2rem">{{ icon.label }}</span>
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-combobox-container>
                    <clr-select-container *ngIf="isPdf()">
                        <label>{{localization.dictionary.document.view}}</label>
                        <select clrSelect name="viewType" [(ngModel)]="document.viewType">
                            <option selected value="multiple">{{localization.dictionary.pdfType.multiple}}</option>
                            <option value="single">{{localization.dictionary.pdfType.single}}</option>
                            <option value="book">{{localization.dictionary.pdfType.book}}</option>
                        </select>
                    </clr-select-container>

                    <clr-checkbox-container>
                        <label>{{localization.dictionary.general.active}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox [value]="true" name="isActive"
                                [(ngModel)]="document.isActive" [disabled]="!canActivate()" />
                        </clr-checkbox-wrapper>
                        <clr-control-helper *ngIf="!canActivate()">{{localization.dictionary.documentCreateEdit.noActiveForm}}</clr-control-helper>
                        <clr-control-helper *ngIf="canActivate() && document.form && !document.form.isActive">{{localization.dictionary.documentCreateEdit.reactivateForm}}</clr-control-helper>
                    </clr-checkbox-container>
                    
                    <clr-checkbox-container *ngIf="isPdf()">
                        <label>{{localization.dictionary.document.shareMail}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox [value]="true" name="shareable"
                                [(ngModel)]="document.shareable" />
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <clr-input-container *ngIf="mode === 'edit'">
                        <label>{{localization.dictionary.documentCreateEdit.lastUpdated}}</label>
                        <input clrInput type="text" name="updatedAt"
                            [attr.value]="helper.formatDate(document.updatedAt)" readonly />
                    </clr-input-container>
                </form>
            </div>
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="tree-view" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}',
                        localization.dictionary.category.name)}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.documentCreateEdit.assignCategoriesDescription}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[1]? 'down' : 'left'"
                    (click)="collapse[1] = !collapse[1]"></clr-icon>
            </div>
            <div [hidden]="collapse[1]" class="card-text icon-offset collapse"
                [class.invalid]="invalid && (!document.categories || document.categories.length == 0)">
                <app-list-selector *ngIf="document.categories" [preLoaded]="document.categories"
                    [query]="'category?sortBy=order'" [label]="localization.dictionary.category.name" [required]="true"
                    (selectedChanged)="document.categories = $event"></app-list-selector>
                <clr-control-error *ngIf="invalid && (!document.categories || document.categories.length == 0)">
                    {{localization.dictionary.documentCreateEdit.selectCategory}}
                </clr-control-error>
            </div>
        </div>
        <div class="card-block" *ngIf="setting.featureActiveList['crmActive']">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="briefcase" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}',
                        localization.dictionary.product.name)}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.documentCreateEdit.assignProductsDescription}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[2]? 'down' : 'left'"
                    (click)="collapse[2] = !collapse[2]"></clr-icon>
            </div>
            <div [hidden]="collapse[2]" class="card-text icon-offset"
                [class.invalid]="invalid && (!document.products || document.products.length == 0)">
                <app-list-selector [preLoaded]="document.products" [query]="'crm/product'"
                    [label]="localization.dictionary.product.name"
                    (selectedChanged)="document.products = $event"></app-list-selector>
            </div>
        </div>


        <!--
    GRUPPEN UND BENUTZER
-->

        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="users" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}',
                        localization.dictionary.group.name)}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.documentCreateEdit.assignGroupsDescription}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[3]? 'down' : 'left'"
                    (click)="collapse[3] = !collapse[3]"></clr-icon>
            </div>
            <div [hidden]="collapse[3]" class="card-text icon-offset">
                <app-list-selector (selectedChanged)="document.groups = $event" [preLoaded]="document.groups"
                    [label]="localization.dictionary.group.name" [query]="'group'"></app-list-selector>
            </div>
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="id-badge" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}',
                        localization.dictionary.user.name)}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.documentCreateEdit.assignUsersDescription}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[4]? 'down' : 'left'"
                    (click)="collapse[4] = !collapse[4]"></clr-icon>
            </div>
            <div [hidden]="collapse[4]" class="card-text icon-offset">
                <app-list-selector (selectedChanged)="document.users = $event" [preLoaded]="document.users"
                    [label]="localization.dictionary.user.name" [query]="'user/filter?sortBy=name'"></app-list-selector>
            </div>
        </div>
        <div stickyFooter class="card-footer">
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>
        </div>
    </div>
</ng-template>

<!--
    ACTION BAR
-->
<ng-template #actionBar>
    <button class="btn btn-primary" (click)="validateAndSave()" [disabled]="!validate()">
        {{ ( mode=== 'create' ) ? localization.dictionary.button.create : localization.dictionary.button.save}}
    </button>
    <button class="btn btn-primary" *ngIf="document.formId"
                [routerLink]="['/forms/results/' + document.formId]">{{localization.dictionary.formResult.name}}</button>
    <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
</ng-template>

