import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-territory',
  templateUrl: './de-territory.component.html'
})
export class DeTerritoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
