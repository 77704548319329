<ol class="list">
    <h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
        <li>Alle Pflichtfelder sind mit einem * gekennzeichnet.</li>
        <li>Beim Punkt „Label“ geben Sie die Bezeichnung für das Feld ein, z.B. Anrede.</li>
        <li>Der Name im nächsten Feld wird automatisch generiert.</li>
        <li>Anschließend ordnen Sie eine oder mehrere Ansichten zu, in denen das Feld verwendet werden soll.</li>
        <li>Bei „Typ“ können Sie zwischen mehreren Feldtypen wählen (siehe hierzu Formulare > Übersicht Feldtypen).</li>
        <li>Beim Punkt „Überprüfung“ können Sie einen Wert festlegen, der die Eingaben des Benutzers auf Richtigkeit überprüft (z.B. bei der Auswahl -Mail werden die Bestandteile einer E-Mail-Adresse überprüft).</li>
        <li>Um das Feld zur Auswahl freizugeben, schieben Sie den Regler auf grün (=aktiv). Nicht aktive Felder werden in den Ansichten nicht angezeigt.</li>
        <li>Wenn Sie beim Feld „Anzeigename“ den Schieber auf grün setzen, wird der Wert dieses Feldes in den Übersichten angezeigt.</li>
        <li>Zum Speichern klicken Sie auf den Button „Erstellen“.</li>
    </ol>