<h2>{{ localization.dictionary.form.name}}</h2>

<div fxLayout="row" fxLayoutAlign="space-between flex-end">
    <clr-dg-action-bar>
        <button class="btn btn-primary" routerLink="/forms/create">
            {{localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.form.nameSingular)}}
        </button>
        <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">
            {{localization.dictionary.button.resetFilters}}
        </button>
        <button class="btn btn-danger-outline" *ngIf="selected.length > 0" (click)="selectDelete(selected)">
            {{localization.dictionary.button.delete}}
        </button>
    </clr-dg-action-bar>
    <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['form-editor', 'admin'])">
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                (ngModelChange)="toggleDeleted()" />
            <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.form.name)}}</label>
        </clr-toggle-wrapper>
    </clr-toggle-container>
</div>

<clr-datagrid class="bound" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" cdkDropList
    [(clrDgSelected)]="selected" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.form.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
          Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']"
        (clrDgSortedChange)="this.selected = []">
        {{localization.dictionary.general.name}}
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="form-description" [clrDgSortBy]="'description'"
        [(clrDgSortOrder)]="sorting['description']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['description']">
            {{localization.dictionary.general.description}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="descriptionFilter" [(clrDgFilterOpen)]="descriptionFilter.open">
            <string-filter #descriptionFilter [property]="'description'" [(filter)]="filters['description']">
            </string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'type'"
        [(clrDgSortOrder)]="sorting['type']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['type']">
            {{localization.dictionary.type.nameSingular}}
        </ng-template>
        <!--    
                Info:
                dem multiselect-filter kann bei Bedarf die Option optin erneut hinzugefügt werden
                aktuell entfernt, da Funtkion noch nicht implementiert 
        -->
        <clr-dg-filter [clrDgFilter]="viewTypeFilter" [(clrDgFilterOpen)]="viewTypeFilter.open">
            <multiselect-filter #viewTypeFilter
                [name]="localization.dictionary.type.name"
                [property]="'type'"
                [(filter)]="filters['viewType']"
                [array]="['open', 'customer']"
                [localizationKey]="'formType'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="form-published" [clrDgSortBy]="'published'"
        [(clrDgSortOrder)]="sorting['published']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['published']">
            {{localization.dictionary.form.published}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="publishedFilter"
                        [(clrDgFilterOpen)]="publishedFilter.open">
            <date-filter  #publishedFilter
                            [property]="'published'"
                            [(filterFrom)]="filters['publishedFrom']"
                            [(filterTo)]="filters['publishedTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'isActive'"
        [(clrDgSortOrder)]="sorting['isActive']" (clrDgSortedChange)="selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['isActive']">
            {{localization.dictionary.general.active}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="isActiveFilter" [(clrDgFilterOpen)]="isActiveFilter.open">
            <boolean-filter #isActiveFilter [property]="'isActive'" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"
                [(filter)]="filters['isActive']">
            </boolean-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['documents']">
            {{localization.dictionary.formList.associatedDoc}}
        </ng-template>
    </clr-dg-column>
    <clr-dg-column class="form-published" [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="form-published" [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" class="form-published" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>  {{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let form of forms" [clrDgItem]="form" [ngClass]="!form.isActive ? 'inactive' : ''"
        [clrDgSelectable]="columnChange || !form.deletedAt" [class.deleted]="form.deletedAt">
        <clr-dg-cell [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.id }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.name }}
        </clr-dg-cell>
        <clr-dg-cell class="form-description" [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.description }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.type + ' (' + translateType(form.type) + ')' }}
        </clr-dg-cell>
        <clr-dg-cell class="form-published" [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.published | date: localization.dictionary.dateFormat.full: undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell class="form-description" [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.isActive ? localization.dictionary.general.yes : localization.dictionary.general.no }}
        </clr-dg-cell>
        <clr-dg-cell class="labels">
            <ng-container *ngFor="let doc of form.documents">
                <span class="label label-light-blue" *ngIf="!doc.deletedAt"
                    [routerLink]="['/documents/edit/' + doc.id]">{{ doc.name }}</span>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell class="form-published" [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.createdAt | date: localization.dictionary.dateFormat.full: undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell class="form-published" [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.updatedAt | date: localization.dictionary.dateFormat.full: undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" class="form-published"
            [routerLink]="form.deletedAt? null : ['/forms/edit/' + form.id]">
            {{ form.deletedAt | date: localization.dictionary.dateFormat.full: undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="!form.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="line-chart" 
                [class.disabled]="!form.published" 
                [routerLink]="['/forms/results/' + form.id]"
                [pTooltip]="localization.dictionary.formList.viewResults"
                tooltipPosition="left">
            </clr-icon>
            
            <clr-icon
                shape="pencil"
                [routerLink]="['/forms/edit/' + form.id]"
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.form.nameSingular).replace('${entryName}', form.name)"
                tooltipPosition="left">
            </clr-icon>
            
            <clr-icon
                [attr.shape]="form.isActive?'ban':'success-standard'"
                (click)="form.isActive?selectInactive([form]):reactivate(form)"
                [class.disabled]="!form.published"
                [pTooltip]="localization.dictionary.formList.deactivateEntry.replace('${entryName}', form.name)"
                tooltipPosition="left">
            </clr-icon>
            
            <clr-icon
                shape="trash"
                (click)="selectDelete([form])"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.form.nameSingular).replace('${entryName}', form.name)"
                tooltipPosition="left">
            </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="form.deletedAt" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="line-chart"
                [class.disabled]="!form.published"
                [routerLink]="['/forms/results/' + form.id]"
                [pTooltip]="localization.dictionary.formList.viewResults"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon
                shape="backup-restore"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry
                    .replace('${componentName}', localization.dictionary.form.nameSingular)
                    .replace('${entryName}', form.name)"
                tooltipPosition="left"
                (click)="openRestoreModal(form)">
            </clr-icon>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.form.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.form.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.form.nameSingular : localization.dictionary.form.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<ng-template #one>
    <span>{{localization.dictionary.form.oneFormInTotal}}</span>
</ng-template>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.formList.deleteModalDescription}} </p>
        <ng-container *ngIf="orphans.length > 0">
            <p>{{localization.dictionary.formList.followingDocuments}}</p>
            <ul>
                <li *ngFor="let orphan of orphans">{{orphan.name}}: {{orphan.description}}</li>
            </ul>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline"
            (click)="toDelete = undefined; this.orphans = []">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="setInactive">
    <h3 class="modal-title">{{localization.dictionary.formList.deactivateModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.formList.deactivateModalDescription}}
        <ng-container *ngIf="orphans.length > 0">
            <p> {{localization.dictionary.formList.deactivateFollowingDocuments}}</p>
            <ul>
                <li *ngFor="let orphan of orphans">{{orphan.name}}: {{orphan.description}}</li>
            </ul>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="inactivate()">{{localization.dictionary.button.deactivate}}</button>
        <button type="button" class="btn btn-outline"
            (click)="setInactive = undefined; this.orphans = []">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.form.nameSingular).replace('${entryName}', selectedForm?.name) }}</h3>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{localization.dictionary.button.restore}}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>
