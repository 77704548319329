import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { ServerService } from 'src/app/server.service';
import { AnsweredForm } from '../answered-form.model';
import { Form } from '../form.model';
import { Question, QuestionType } from '../question.model';
import { HelperService } from 'src/app/helper/helper.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { chartColors } from 'src/app/analytics/chart-colors';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Unsubscribable } from '../../helper/unsubscribable/unsubscribable';
import html2canvas from 'html2canvas';
import { CrmService } from 'src/app/crm/crm.service';
import { Contact } from 'src/app/crm/contacts/contact.model';
import { Customer } from 'src/app/crm/customers/customer.model';
import { View } from 'src/app/crm/views/view.model';

@Component({
  selector: 'app-list-results',
  templateUrl: './list-results.component.html',
  styleUrls: ['./list-results.component.css']
})
export class ListResultsComponent extends Unsubscribable implements OnInit {
  contactType = Contact;
  customerType = Customer;
  form: Form;
  loading = false;
  saving = false;
  selected: AnsweredForm[] = [];
  total = 0;
  state: ClrDatagridStateInterface;
  ignoreTypes: QuestionType[] = [QuestionType.DESCRIPTION, QuestionType.SECTION];
  diagramTypes: QuestionType[] = [QuestionType.CHOICE, QuestionType.MULTIPLE_CHOICE, QuestionType.NUMBER, QuestionType.RANGE, QuestionType.BOOLEAN, QuestionType.CHECKBOX, QuestionType.SELECTION];
  listTypes: QuestionType[] = [QuestionType.TEXT, QuestionType.TEXTAREA, QuestionType.EMAIL, QuestionType.SELECTION, QuestionType.DATE, QuestionType.TIME, ...this.diagramTypes];
  cummAnswers = {};
  colors = JSON.parse(JSON.stringify(chartColors.dark));
  questionDetail: Question;
  toDelete: AnsweredForm[];
  customerQuestions: Question[] = [];
  contactQuestions: Question[] = [];
  nonRefQuestions: Question[] = [];
  questions = [];
  barChartOptions = {
    legend: {
      labels: {
        fontColor: '#ffffff'
      }
    },
    /* scales: {
      yAxes: [
        {
          ticks: {
            min: 0
          }
        }]
    }, */
    plugins: {
      datalabels: {
        color: '#ffffff',
        font: {
          size: 32
        }
      }
    }
  }

  pieChartOptions = {
    legend: {
      labels: {
        fontColor: '#ffffff'
      }
    },
    plugins: {
      datalabels: {
        color: '#ffffff',
        font: {
          size: 32
        }
      }
    }
  }

  filters = {};
  sorting = {};
  sortBy = {};
  hidden: { [column: string]: boolean } = {}

  contactViews: View[];
  contactView: View;
  customerViews: View[];
  customerView: View;

  routeQueryAF = '';
  
  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  constructor(private route: ActivatedRoute, public server: ServerService, private helper: HelperService,
    public localization: LocalizationService, private crm: CrmService) {
    super();
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        if (params.id) {
          const res = await this.server.get('form/results/' + params.id);
          this.form = plainToClass(Form, res);
          this.updateData()
          this.filterQuestions();
        }
      })
    );
    this.subscriptions.add(
      this.route.queryParamMap.subscribe(async paramMap =>{
        if (paramMap.keys.length > 0){
          this.filters ={};
          paramMap.keys.forEach(key =>{
            if(key === 'answeredFormId'){
              this.routeQueryAF = paramMap.get(key);
            }
            else{
              this.filters[key] = paramMap.get(key);
            }
          })
        }

      })
    ) 

  }

  ngOnInit(): void {
    this.contactViews = this.crm.views.contact;
    this.contactView = this.crm.activeViews.contact;
    this.customerViews = this.crm.views.customer;
    this.customerView = this.crm.activeViews.customer;
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    if (state.page.from == -1 || state.page.to == -1) {
      state.page.from = 0;
      if (!state.page.size) {
        state.page.size = 20;
      }
      state.page.to = state.page.size;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const res = await this.server.get('form/resultsOnly/' + this.form.id + '/' + query);
    this.form.answeredForms = plainToClass(AnsweredForm, res.rows) as any;
    this.total = res.count;
    this.loading = false;
    this.datagrid.dataChanged();
  }

  filterQuestions() {
    this.customerQuestions = [];
    this.contactQuestions = [];
    this.nonRefQuestions = [];
    for (let question of this.form.questions.sort( (a, b) => a.order - b.order )) {
      if (question.reference && question.refTable == 'customer') {
        this.customerQuestions.push(question);
      } else if (question.reference && question.refTable === 'contact') {
        this.contactQuestions.push(question);
      } else if (!question.reference && question.type != 'SECTION') {
        this.nonRefQuestions.push(question);
      }
    }
    let customer = { name: this.localization.dictionary.customer.nameSingular, type: 'CUSTOMER' };
    let contact = { name: this.localization.dictionary.contact.nameSingular, type: 'CONTACT' };
    let tempQuestions = [];
    tempQuestions.push(customer, ...this.customerQuestions, contact, ...this.contactQuestions,...this.nonRefQuestions, );
    if (this.form.type != 'customer') {
      tempQuestions = tempQuestions.filter(question => question.type != 'CUSTOMER');
      tempQuestions = tempQuestions.filter(question => question.type != 'CONTACT');
    }
    tempQuestions = tempQuestions.filter(question => !this.ignoreTypes.includes(question.type))
    this.questions = tempQuestions;
    this.datagrid?.dataChanged()
  }


  async excelExport() {
    this.loading = true;
    //let query = this.server.buildQueryFromGrid(state) 
    try {
      const blob = await this.server.download('form/excelExport/' + this.form.id + '/' + this.localization.language.id);
      const dateString = this.helper.formatDate(new Date(), false);
      await this.server.excelDownload(blob, dateString + ' ' + this.localization.dictionary.formResultList.resultOf.replace('${formName}', this.form.name));
      this.loading = false;
    } catch (err) {
      console.error(err)
    }
  }

  updateData() {
    for (let question of this.form.questions) {
      this.cummAnswers[question.id] = this.form.getCummulatedAnswersByQuestion(question.id);
      
    }
  }

  // auf Performance prüfen!
  getAverage(questionId: string): number {
    if (this.cummAnswers[questionId].length === 0) {
      return 0;
    }
    let sum = 0;
    let divideBy = 0;
    for (let answer of this.cummAnswers[questionId]) {
      sum += parseInt(answer.name);
      divideBy += answer.value;
    }
    return sum / divideBy;
  }

  getMinMaxAvg(questionId: string) {
    let output = {min: 0, max: 0, avg: 0}
    if (this.cummAnswers[questionId].length === 0) {
      return output;
    }
    let sum = 0;
    let min, max;
    let divideBy = 0;
    for (let answer of this.cummAnswers[questionId]) {   
      let value = parseInt(answer.name);   
      sum += parseInt(answer.name);
      divideBy += answer.value;
      if (!min || min > value) {
        min = value;
      }
      if (!max || max < value) {
        max = value;
      }
    }
    output.min = min;
    output.max = max;
    output.avg = sum / divideBy;

    return output;
  }

  async copyToClipboard(questionId: string) {
    const chart = document.getElementById('chart-' + questionId);
    const canvas = await html2canvas(chart)
    this.helper.copyCanvasToClipboard(canvas);
  }

  findQuestionById(questionId: string) {
    return this.form.questions.find((q) => q.id === questionId);
  }

  getQuestionSortBy(question: Question) {
    if (!this.sortBy[question.id || question.type] && question.type != 'SIGNATURE') {
      if (question.type == 'CONTACT') {
        this.sortBy[question.type] = 'contact.fieldValues.' + this.crm.sortField.contact;
      } else if (question.type == 'CUSTOMER') {
        this.sortBy[question.type] = 'customer.fieldValues.' + this.crm.sortField.customer;
      } else {
        this.sortBy[question.id] = 'givenAnswers.' + question.id;
      }
    }
    return this.sortBy[question.id || question.type];
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const afIds = this.toDelete.map((af) => af.id);
    await this.server.delete('form/answeredForms?toDelete=' + afIds);
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successDeleted.replace("${componentName}", this.localization.dictionary.formResult['name' + (this.toDelete.length == 1 ? 'Singular' : '')])));
    this.saving = false;
    this.toDelete = undefined;
    this.selected = [];
    this.refresh();
  }

  _test(question: Question) {
    this.questionDetail = question;
  }

  widgetFullTicks = this.helper.widgetFullTicks;
}
