import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../../localization/localization.service';
import { PushNotification, PushUpdateType } from '../push-notification.model';

@Component({
  selector: 'app-send-modal',
  templateUrl: './send-modal.component.html',
  styleUrls: ['./send-modal.component.css']
})
export class SendModalComponent implements OnInit {

  pushUpdateTypes = PushUpdateType;

  @Input() sendModal: boolean;
  @Input() push: PushNotification;
  @Output() sendPush: EventEmitter<boolean> = new EventEmitter();
  @Output() abort: EventEmitter<boolean> = new EventEmitter();

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
  }

}
