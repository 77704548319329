<ol class="list">
<h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Vergeben Sie einen aussagekräftigen Namen für die Gruppe.</li>
    <li>Ergänzen Sie eine Beschreibung, die Mitglieder dieser Gruppe näher beschreibt.</li>
<h4 style="position: relative; right: 1.1em;">Rollen zuordnen</h4>
    <li>Unter dem Punkt „Rollen zuordnen“ vergeben Sie die Berechtigung, die jeder Benutzer, der dieser Gruppe zugeordnet ist, erhält. Eine Übersicht der Rollen und der damit verbundenen Berechtigungen finden Sie im Hauptmenü unter „Benutzerverwaltung“ > „Rollen“.</li>
<h4 style="position: relative; right: 1.1em;">Gebiete zuordnen</h4>
    <li>Unter dem Punkt Gebiete zuordnen können Sie der Gruppe ein Gebiet zuordnen, z.B. wenn Sie eine Gruppe für Vertriebsmitarbeiter einer Region erstellen.</li>
<h4 style="position: relative; right: 1.1em;">Benutzer zuordnen</h4>
    <li>Abschließend weisen Sie der Gruppe die entsprechenden Benutzer zu.</li>
</ol>