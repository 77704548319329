import { Exclude, Expose, Type } from 'class-transformer';
import { CategoryDocument } from './category-document.model';
import { Document } from '../documents/document.model';
import { Group } from '../groups/group.model';

@Exclude()
export class Category {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    description: string;

    @Expose()
    icon: string;

    @Expose()
    order: number;

    @Expose()
    isActive: boolean = true;

    @Expose()
    inMainMenu: boolean = true;

    @Expose()
    documents: Document[] = [];

    @Expose()
    CategoryDocument: CategoryDocument = new CategoryDocument();

    @Expose()
    groups: Group[] = [];

    @Expose()
    @Type(() => Date)
    createdAt: Date;

    @Expose()
    @Type(() => Date)
    updatedAt: Date;

    @Expose()
    @Type(() => Date)
    deletedAt: Date;
}