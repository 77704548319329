import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { Group } from '../group.model';
import { DatagridFunctions } from 'src/app/helper/datagrid-functions.class';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ServerService, ServerResponse } from 'src/app/server.service';
import { plainToClass } from 'class-transformer';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { AuthService } from 'src/app/auth/auth.service';
import { HelperService } from 'src/app/helper/helper.service';
import { SettingService } from 'src/app/settings/setting.service';
import { LocalizationService } from 'src/app/localization/localization.service';


@Component({
  selector: 'app-list-groups',
  templateUrl: './list-groups.component.html',
  styleUrls: ['./list-groups.component.css']
})
export class ListGroupsComponent implements OnInit, OnDestroy, DatagridFunctions {
  loading = true;
  saving = false;
  groups: Group[] = [];
  selected: Group[] = [];
  toDelete: Group[];
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  };
  state: ClrDatagridStateInterface;
  order = false;
  total = 0;

  selectedGroup: Group;
  restoreModal = false;
  showDeleted = false;

  columnChange = false;
  columnChanged = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(private server: ServerService, public auth: AuthService, public helper: HelperService, public setting: SettingService,
    public localization: LocalizationService) {
  }

  async ngOnInit(): Promise<void> {
    this.showDeleted = !!localStorage.getItem('group-deleted');
    const res = await this.server.get('group/' + (this.showDeleted ? 'all' : 'includeRoles')) as ServerResponse;
    this.groups = plainToClass(Group, res.rows);
    this.total = res.count;
    const filter = localStorage.getItem('group-filters');
    const sorting = localStorage.getItem('group-sorting');
    const hidden = localStorage.getItem('group-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
  }

  ngOnDestroy() {
    localStorage.setItem('group-filters', JSON.stringify(this.filters));
    localStorage.setItem('group-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('group-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('group-deleted', 'true')
    else
      localStorage.removeItem('group-deleted')
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  toggleOrder?(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  drop?(event: CdkDragDrop<any[], any[]>): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async delete(): Promise<void> {
    this.loading = true;
    this.saving = true;
    const groupIds = this.toDelete.map((group) => group.id);
    await this.server.delete('group?toDelete=' + groupIds);
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successDeleted
        .replace('${componentName}', this.localization.dictionary.group['name' + (this.toDelete.length == 1 ? 'Singular' : '')])
      // `Gruppen erfolgreich gelöscht`
    ));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();
    this.loading = false;
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const res = await this.server.get('group/' + (this.showDeleted ? 'all' : 'includeRoles') + query) as ServerResponse;
    this.groups = plainToClass(Group, res.rows);
    this.total = res.count;
    this.loading = false
  }

  openRestoreModal(group: Group) {
    this.selectedGroup = group;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('group/restore', this.selectedGroup);
    this.server.addAlert(new AmselError(undefined, 'success',
      this.localization.dictionary.toastr.successRestored
        .replace('${componentName}', this.localization.dictionary.group.nameSingular)
        .replace('${entryName}', this.selectedGroup.name)
      // `Gruppe erfolgreich wiederhergestellt`
    ));
    this.refresh();
    this.restoreModal = false;
  }

  /* getRoleNames(group: Group): string {
    const roleArr = group.roles.map( (role) => role.name);
    return roleArr.join(', ');
  } */

  editable(group: Group, deleted = true) {
    return (this.auth.hasRole(['admin']) || !group.roles || !group.roles.find(role => role.name == this.auth.adminName)) && (deleted ? !group.deletedAt : true)
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

}
