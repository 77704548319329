import { Exclude, Expose, Type } from 'class-transformer';
import { Dictionary } from 'src/app/localization/dictionary.model';;

@Exclude()
export class Language {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    selector: string;

    @Expose()
    @Type( () => Dictionary)
    dictionaries: Dictionary[]= [];

    @Expose()
    @Type(() => Date)
    createdAt: Date;

    @Expose()
    @Type(() => Date)
    updatedAt: Date;

    @Expose()
    @Type(() => Date)
    deletedAt: Date;

    @Expose()
    active: boolean;
    

}