<h2>{{ localization.dictionary.group.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" 
    [clrDgLoading]="loading"     
    [(clrDgSelected)]="selected"
    #datagridRef>
    <clr-dg-column  [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="idFilter"
                        [(clrDgFilterOpen)]="idFilter.open">
            <string-filter  #idFilter
                            [property]="'name'" 
                            [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="this.selected = []">
        {{localization.dictionary.general.name}}
        <clr-dg-filter  [clrDgFilter]="nameFilter"
                        [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter  #nameFilter
                            [property]="'name'" 
                            [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'description'" [(clrDgSortOrder)]="sorting['description']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['description']">
            {{localization.dictionary.general.description}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="descriptionFilter"
                        [(clrDgFilterOpen)]="descriptionFilter.open">
            <string-filter  #descriptionFilter
                            [property]="'description'"
                            [(filter)]="filters['description']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>    
    <clr-dg-column  [clrDgSortBy]="'roles.name'" [(clrDgSortOrder)]="sorting['roles']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['roles']">
            {{localization.dictionary.role.name}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="rolesFilter"
                        [(clrDgFilterOpen)]="rolesFilter.open">
            <multiselect-filter #rolesFilter 
                                [name]="localization.dictionary.role.name"
                                [property]="'roles'"
                                [(filter)]="filters['roles']">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['users']">
            {{localization.dictionary.groupList.numberOfUsers}}
        </ng-template>
    </clr-dg-column>
    <clr-dg-column *ngIf="setting.featureActiveList['crmActive']" [clrDgSortBy]="'territories.name'" [(clrDgSortOrder)]="sorting['territories']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['territories']">
            {{localization.dictionary.territory.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="territoriesFilter" [(clrDgFilterOpen)]="territoriesFilter.open">
            <multiselect-filter #territoriesFilter [name]="localization.dictionary.territory.name" [property]="'territories'" [query]="'crm/territory'"
                [(filter)]="filters['territories']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'createdAt'" [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="createdFilter"
                        [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter  #createdFilter
                            [property]="'createdAt'" 
                            [(filterFrom)]="filters['createdAtFrom']"
                            [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column  [clrDgSortBy]="'updatedAt'" [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="updatedFilter"
                        [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter  #updatedFilter
                            [property]="'updatedAt'" 
                            [(filterFrom)]="filters['updatedAtFrom']"
                            [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'" [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter  [clrDgFilter]="deletedFilter"
                        [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter  #deletedFilter
                            [property]="'deletedAt'" 
                            [(filterFrom)]="filters['deletedAtFrom']"
                            [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let group of groups" [clrDgItem]="group" [clrDgSelectable]="columnChange || editable(group)" [class.deleted]="group.deletedAt" [class.noedit]="!editable(group)">
        <clr-dg-cell [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">            
            {{ group.id }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">            
            {{ group.name }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">
            {{ group.description }}
        </clr-dg-cell>
        <clr-dg-cell class="labels" [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">
            <div *ngFor="let role of group.roles" class="tooltip-container">
                <span
                    class="label label-light-blue"
                    [pTooltip]="localization.dictionary.roleDescription[role.name]"
                   
                    tooltipPosition="top">
                    {{ role.name }}
                </span>
            </div>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">
            {{ (group.users) ? group.users.length : 0 }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="setting.featureActiveList['crmActive']" class="labels" [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]" >
            <span 
                *ngFor="let territory of group.territories"
                class="label"
                [ngClass]="(!territory.direct) ? 'label-light-blue' : 'label-purple'"
                [pTooltip]="territory.description"
                tooltipPosition="top">
                {{ territory.name }}
            </span>   
           
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">
            {{ group.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">
            {{ group.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="!editable(group)? null : ['/groups/edit/' + group.id]">
            {{ group.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
       
        <clr-dg-cell *ngIf="editable(group)" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                shape="pencil"
                [routerLink]="['/groups/edit/' + group.id]"
                [pTooltip]="localization.dictionary.list.editComponentEntry
                    .replace('${componentName}', localization.dictionary.group.nameSingular)
                    .replace('${entryName}', group.name)"
                tooltipPosition="left">
            </clr-icon>
            
            <clr-icon shape="trash"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry
                .replace('${componentName}', localization.dictionary.group.nameSingular)
                .replace('${entryName}', group.name)"
                tooltipPosition="left"
                tooltipTrigger="click"
                (click)="toDelete = [group]">
            </clr-icon>
        </clr-dg-cell>     
        <clr-dg-cell *ngIf="!editable(group)" fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <span *ngIf="editable(group, false) && group.deletedAt"
                class="tooltip-wrapper"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.group.nameSingular).replace('${entryName}', group.name)"
                tooltipPosition="top">
             <clr-icon shape="backup-restore" (click)="openRestoreModal(group)"></clr-icon>
            </span>
        </clr-dg-cell>       
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.group.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.group.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.group.nameSingular : localization.dictionary.group.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.groupList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.group.nameSingular).replace('${entryName}', selectedGroup?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
        {{localization.dictionary.groupList.restoreModalDescription}}
        <app-list-selector (selectedChanged)="selectedGroup.roles = $event" [preLoaded]="selectedGroup?.roles" [label]="localization.dictionary.role.name" [query]="'roles'"></app-list-selector>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/groups/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.group.nameSingular) }}</button>
            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">{{ localization.dictionary.button.resetFilters }}</button>        
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0" (click)="toDelete = selected">{{ localization.dictionary.button.delete }}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['group-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted" (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.group.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>