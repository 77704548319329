<clr-modal [(clrModalOpen)]="isOpen" [clrModalSize]="'l'" [clrModalClosable]="!uploading">
    <h3 class="modal-title">{{ localization.dictionary.file.nameSingular }} {{ localization.dictionary.file.upload }}</h3>
    <div class="modal-body" >

        <form clrForm>            
            <div class="clr-form-control">
                <label class="clr-control-label upload-label clr-col-md-3" style="padding-left: 0px;">{{localization.dictionary.documentCreateEdit.file + '*'}}</label>
                <div class="clr-control-container clr-col-md-9">
                    <div class="clr-file-wrapper">
                        <label for="file" class="clr-control-label upload-label upload-label-extended" style="padding-left: 4px;">
                            <span class="btn btn-sm"
                                [class.btn-danger]="false">
                                {{ localization.dictionary.button.browse }}
                            </span>
                        </label>
                        <input type="file" id="file" name="file" [accept]="allowedFiles" [disabled]="uploading" required
                            (change)="onUpload($event.target.files)" placeholder="Enter value here"
                            class="clr-file" #fileInput/>
                        <clr-control-helper *ngIf="file && !isAllowedType()" style="padding-left: 4px; color: var(--danger);">
                            {{ localization.dictionary.file.unsupportedFileType }}
                        </clr-control-helper>
                        <span *ngIf="file" [style]="isAllowedType()?'padding-left: 4px;':'padding-left: 4px; color: var(--danger);'">
                            <clr-icon shape="pdf" *ngIf="file.type.includes('pdf')"></clr-icon>
                            <clr-icon shape="image" *ngIf="file.type.includes('image')"></clr-icon>
                            <clr-icon shape="text" *ngIf="file.type.includes('text') || file.type.includes('word')"></clr-icon>
                            <clr-icon shape="table" *ngIf="file.type.includes('excel') || file.type.includes('.sheet')"></clr-icon>
                            <clr-icon shape="pie-chart" *ngIf="file.type.includes('powerpoint') || file.type.includes('.presentation')"></clr-icon>
                            <clr-icon shape="file" *ngIf="!file.type.includes('image') && !file.type.includes('pdf') && !file.type.includes('text') && !file.type.includes('word') && !file.type.includes('excel') && !file.type.includes('.sheet') && !file.type.includes('powerpoint') && !file.type.includes('.presentation')"></clr-icon>
                            | {{ file.name }}
                        </span>    
                        <span *ngIf="uploading" class="spinner spinner-inline"></span>                    
                        <clr-control-error class="clr-subtext" *ngIf="!file" style="padding-left: 4px;"> {{localization.dictionary.documentCreateEdit.requiredFile}}</clr-control-error>
                    </div>
                </div>

            </div>

            <clr-input-container>
                <label class="clr-col-md-3"> {{ localization.dictionary.file.filename }}* </label>
                <input class="clr-col-md-9" clrInput name="name" [(ngModel)]="filename" (change)="dirty = true" required />                
            </clr-input-container>
  
            <clr-textarea-container>
                <label class="clr-col-md-3">{{ localization.dictionary.general.description }}</label>
                <textarea class="clr-col-md-9" clrTextarea [(ngModel)]="desc" name="description"></textarea>
                <clr-control-helper>{{localization.dictionary.file.optional}}</clr-control-helper>
            </clr-textarea-container>
  
        </form>
    </div>
    <div class="modal-footer">
      <button [disabled]="uploading" type="button" class="btn btn-outline" (click)="clear()">{{ localization.dictionary.button.close }}</button>
      <button type="button" class="btn btn-primary" [disabled]="!file || !isAllowedType() || uploading || !filename" (click)="upload()">{{ localization.dictionary.file.upload }}</button>
    </div>
</clr-modal>
