import { Injectable } from '@angular/core';
import { ServerService } from '../server.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private server: ServerService) { }

  async getTrackingData(query: string) {
    return await this.server.get('tracking' + query);
  }

  async getLoginAndSynch(query: string) {
    return await this.server.get('tracking/userSync' + query);
  }

  async getMailEvents(query: string) {
    return await this.server.get('tracking/mailEvents' + query);
  }

  async getGroupedEvents(query: string) {
    return await this.server.get('tracking/groupEventType' + query);
  }

  async getEventsByOs(query: string) {
    return await this.server.get('tracking/eventsByOs' + query);
  }

  async getCalls(query: string) {
    return await this.server.get('tracking/calls' + query);
  }

  async getCustomerCountByTerritory(query: string) {
    return await this.server.get('analytics/crm/customerByTerritory' + query);
  }

  async getCallsCountByUser(query: string) {
    return await this.server.get('analytics/crm/callsByUser' + query);
  }

  async getCallsCountByProduct(query: string) {
    return await this.server.get('analytics/crm/callsByProduct' + query);
  }

  async getCallsCountByTerritory(query: string) {
    return await this.server.get('analytics/crm/callsByTerritory' + query);
  }
}
