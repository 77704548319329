<h4>{{localization.dictionary.helpForms.topics}}</h4>
<ul class="help-ul help-topics">
    <li>{{localization.dictionary.form.name}}</li>
    <li>{{localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.field.name)}}</li>
</ul>
<h4>{{localization.dictionary.form.name}}</h4>
<p>
    {{localization.dictionary.helpForms.dynamicInfos}}
</p>
<ul class="help-ul">
    <li>{{localization.dictionary.helpForms.optinForms}}</li>
    <li>{{localization.dictionary.helpForms.surveys}}</li>
    <li>{{localization.dictionary.helpForms.orders}}</li>
    <li>{{localization.dictionary.helpForms.etc}}</li>
</ul>
<h4>{{localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.field.name)}}</h4>
<p>
    {{localization.dictionary.helpForms.atLeastOne}}
</p>
<clr-icon size="42" shape="plus-circle" style="margin-top: 1rem; margin-left: 2rem; cursor: default;"></clr-icon>
<p>
    {{localization.dictionary.helpForms.individualTypes}}
</p>

<h5>{{localization.dictionary.formCreateEdit.section}}</h5>
<img src="./assets/img/help/forms/section.PNG">
<p>
    {{localization.dictionary.helpForms.sectionAdds}}
</p>
<p>
    <ng-container *ngTemplateOutlet="description"></ng-container>
</p>
<h5>{{localization.dictionary.helpForms.question}}</h5>
<img src="./assets/img/help/forms/choice.PNG">
<p>

</p>

<ng-template #description>
    {{localization.dictionary.helpForms.thereby}}
</ng-template>

