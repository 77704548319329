export class Theme {
    /**
     * Default font color
     * @example '#fff'
     */
    '--clr-p1-color': string;
    /**
     * Default header color
     * @example '#000'
     */
    '--clr-h3-color': string;
    /**
     * Default icon color
     * @example '#fff'
     */
    '--clr-icon-fill': string;
    /**
     * Header background color 
     * @example 'rgba(27; 42; 50; 1)'
     */
    '--clr-header-6-bg-color': string;
    /**
     * Header font color
     * @example '#fff'
     */
    '--clr-header-font-color': string;
        /**
     * Header hover elements font color
     * @example '#fff'
     */
    '--clr-header-hover-font-color': string;
    /**
     * Vertical nav (left side menu) background color
     * @example 'rgba(0; 0; 0; 0.67)'
     */
    '--clr-vertical-nav-bg-color': string;
    /**
     * Vertical nav (left side menu) active background color
     * @example 'rgba(0; 0; 0; 0.25)'
     */
    '--clr-vertical-nav-active-bg-color': string;
    /**
     * Vertical nav (left side menu) hover background color
     * @example 'rgba(0; 0; 0; 0.25)'
     */
    '--clr-vertical-nav-hover-bg-color': string;
    /**
     * Vertical nav (left side menu) divider color
     * @example 'rgba(27; 42; 50; 1)'
     */
    '--clr-vertical-nav-trigger-divider-border-color': string;
    /**
     * Vertical nav (left side menu) divider width
     * @example '0.02rem'
     */
    '--clr-vertical-nav-trigger-divider-border-width': string;
    /**
     * Vertical nav (left side menu) default icon color
     * @example '#666'
     */
    '--clr-vertical-nav-icon-color': string;
    /**
     * Vertical nav (left side menu) icon active color
     * @example '#0072a3'
     */
    '--clr-vertical-nav-icon-active-color': string;
    /**
     * Vertical nav (left side menu) icon color
     * @example '#fff'
     */
    '--clr-vertical-nav-icon-fill': string;
    /**
     * Font color of forms (eg. search bar)
     * @example '#fff'
     */
    '--clr-forms-text-color': string;
    /**
     * Focused font color of form (eg. search bar)
     * @example '#0072a3'
     */
    '--clr-forms-focused-color': string;
    /**
     * Border color of forms (eg. search bar)
     * @example '#b3b3b3'
     */
    '--clr-forms-border-color': string;
    /**
     * Background color of tables
     * @example 'rgba(47; 62; 70; 1)'
     */
    '--clr-table-bgcolor': string;
    /**
     * Table header background color
     * @example 'rgba(27; 42; 50; 1)'
     */
    '--clr-thead-bgcolor': string;
    /**
     * Default table font color
     * @example '#fff'
     */
    '--clr-table-font-color': string;
    /**
     * Default table header font color
     * @example '#fff'
     */
    '--clr-thead-color': string;
    /**
     * Table border color
     * @example 'rgba(27; 42; 50; 1)'
     */
    '--clr-table-border-color': string;
    /**
     * Primary button background color
     * @example '#0072a3'
     */
    '--clr-btn-primary-bg-color': string;
    /**
     * Primary button border color
     * @example '#0072a3'
     */
    '--clr-btn-primary-border-color': string;
    /**
     * Primary button hover background color
     * @example '#00567a'
     */
    '--clr-btn-primary-hover-bg-color': string;
    /**
     * Outline button background color
     * @example '#0072a3'
     */
    '--clr-btn-default-outline-color': string;
    /**
     * Outline button hover background color
     * @example '#e3f5fc'
     */
    '--clr-btn-default-outline-hover-bg-color': string; 
    /**
     * Outline button hover font color
     * @example '#00567a'
     */
    '--clr-btn-default-outline-hover-color': string; 
    /**
     * Outline button border color
     * @example '#0072a3'
     */      
    '--clr-btn-default-outline-border-color': string;
    /**
     * Modal background color
     * @example 'rgba(27; 42; 50; 1)'
     */
    '--clr-modal-bg-color': string;
    /**
     * Modal title font color
     * @example '#fff'
     */
    '--clr-modal-title-color': string;
        /**
     * Modal font color
     * @example '#e5e5e5'
     */
    '--clr-modal-font-color': string;
    /**
     * Login background color
     * @example 'rgba(27; 42; 50; 1)'
     */
    '--clr-login-background-color': string;
    /**
     * Icon color of login screen icons
     * @example '#fff'
     */
    '--amsel-login-icon-fill': string;
    /**
     * Background image of login; home and document list
     * @example 'bg06.png'
     */
    '--amsel-background-image': string;
    /**
     * Background color of login; home and document list
     * @example '#fff'
     */
    '--amsel-background-color': string;
    /**
     * Logo to show on login and home screen
     * @example 'logo02.png'
     */
    '--amsel-logo-background-image': string;
    /**
     * Logo in header
     * @example "url('./assets/amsel_header_dark.png')"
     */
    '--amsel-header-logo-bg': string;
    /**
     * Blurred box background (e.g. document list)
     * @example 'rgba(255; 255; 255; 0.76) 0% 0% no-repeat padding-box;'
     */
    '--amsel-blur-box-bg-color': string;
    /**
     * Blurred box inactive background (e.g. document list pdf mailing = no pdf)
     * @example 'rgba(100; 100; 100; 0.76) 0% 0% no-repeat padding-box;'
     */
    '--amsel-blur-box-inactive-bg-color': string;
    /**
     * Document font color (document list)
     * @example 'rgba(27; 42; 50; 1)'
     */   
    '--amsel-document-item-font-color': string;

    /**
     * secondary color für Create Edit(Sidenav)
     * @example '#474545'
     */   
    '--clr-vertical-nav-secondary-color': string;
}