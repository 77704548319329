import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BrandingService } from '../branding/branding.service';
import { LocalizationService } from '../localization/localization.service';
import { SettingService } from '../settings/setting.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ServerService } from '../server.service';
import { UserHelpService } from '../user-doc/user-help.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logoutModal = false;
  langChange = false;

  oldSrc: string
  oldExist: boolean

  state: ClrDatagridStateInterface

  constructor(public settings: SettingService,
     public auth: AuthService, 
     public branding: BrandingService,
     public localization: LocalizationService,
     public server: ServerService,
     public userHelp: UserHelpService) {
    
  }

  ngOnInit(): void { 

   this.localization.languageModified.next(this.localization.language);
  }

  imageExists(src: string) {
    if (src != this.oldSrc) {
      this.oldSrc = src
      const img = new Image()
      img.src = src.replace("url(", "").substring(0, src.length - 2)
      if (img.width == 0)
        this.oldExist = false
      else
        this.oldExist = true
    }
    return this.oldExist
  }

}
