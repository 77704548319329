import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagrid } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { TreeNode } from 'primeng/api';
import { LocalizationService } from 'src/app/localization/localization.service';
import { AuthService } from '../../../auth/auth.service';
import { BrandingService } from '../../../branding/branding.service';
import { DatagridFunctions } from '../../../helper/datagrid-functions.class';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { HelperService } from '../../../helper/helper.service';
import { ServerService } from '../../../server.service';
import { CrmService } from '../../crm.service';
import { Territory } from '../territory.model';

@Component({
  selector: 'app-overview-territories',
  templateUrl: './overview-territories.component.html',
  styleUrls: ['./overview-territories.component.css']
})
export class OverviewTerritoriesComponent implements OnInit, OnDestroy, DatagridFunctions {
  territories: Territory[];
  trees: TreeNode[][];
  selected: Territory[] = [];
  selectedNodes: TreeNode[] = [];
  toDelete: Territory[] | TreeNode[];
  loading = true;
  saving = false;
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  }
  state: ClrDatagridStateInterface

  showDeleted = false;
  selectedTerritory: Territory;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  firstTab = true;

  detail = true;
  zoom = 100;
  zoomInterval: NodeJS.Timeout;

  territoryCounts = {};

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @ViewChild('scrollWrapper') scrollWrapper: ElementRef<HTMLDivElement>;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(
    public server: ServerService,
    public auth: AuthService,
    public helper: HelperService,
    public branding: BrandingService,
    public crm: CrmService,
    public localization: LocalizationService
    ) {
  }

  ngOnInit(): void {
    const filter = localStorage.getItem('territory-filters');
    const sorting = localStorage.getItem('territory-sorting');
    const hidden = localStorage.getItem('territory-columns');
    const zoom = localStorage.getItem('territory-zoom');
    const detail = localStorage.getItem('territory-detail');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
    if (zoom) {
      this.zoom = +zoom;
    }
    if (detail) {
      this.detail = detail == 'true';
    }
    this.loadTree();
    this.applyZoom();
    this.loading = false
  }

  ngOnDestroy() {
    localStorage.setItem('territory-filters', JSON.stringify(this.filters));
    localStorage.setItem('territory-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('territory-columns', JSON.stringify(this.hidden));
    localStorage.setItem('territory-zoom', '' + this.zoom);
    localStorage.setItem('territory-detail', '' + this.detail);
    if (this.showDeleted)
      localStorage.setItem('territory-deleted', 'true')
    else
      localStorage.removeItem('territory-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const territory = await this.server.get('crm/territory/' + (this.showDeleted ? 'all' : '') + query);
    this.total = territory.count;
    this.territories = plainToClass(Territory, territory.rows);
    this.loading = false;
  }

  async loadTree() {
    const counts = await this.server.getUnwrapped('crm/territory/countCustomers');
    this.territoryCounts = counts;
    const tree = plainToClass(Territory, (await this.server.get<Territory>('crm/territory/tree')).rows);
    this.trees = this.mapChildren(tree).sort((a, b) => b[0].data.count - a[0].data.count) as TreeNode[][]
    this.loading = false;
  }

  mapChildren(territories: Territory[], first = true, counter: { count: number } = null): TreeNode[][] | TreeNode[] {
    if (counter) {
      counter.count++
    }
    if (territories) {
      return territories.map(branch => {
        const { id, name, description, customers } = branch
        const data = { id, name, description, customers: customers?.length, count: first ? 0 : undefined }
        const res = { label: branch.name, expanded: true, data, children: this.mapChildren(branch.children, false, first ? data : counter) } as any
        if (first) {
          return [res]
        }
        return res
      });
    }
  }

  expandCollapseAll(expand: boolean, trees: TreeNode[][] | TreeNode[] = this.trees) {
    for (let tree of trees) {
      tree = Array.isArray(tree) ? tree[0] : tree;
      tree.expanded = expand;
      if (tree?.children) {
        this.expandCollapseAll(expand, tree.children);
      }
    }
    if (trees.length > 0 && Array.isArray(trees[0])) {
      this.selectedNodes = []
    }
  }

  async loadTerritorys() {
    this.showDeleted = !!localStorage.getItem('territory-deleted');
    const res = await this.server.get('crm/territory/' + (this.showDeleted ? 'all' : ''));
    this.total = res.rows.length;
    this.territories = plainToClass(Territory, res.rows);
  }

  selectionChange(territories: Territory[]) {
    if (territories.length > 0) {
      this.order = false;
    }
  }

  async openRestoreModal(territory: Territory) {
    this.selectedTerritory = territory;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('crm/territory/restore', this.selectedTerritory);
    this.server.addAlert(new AmselError(undefined, 'success', 
        this.localization.dictionary.toastr.successRestored
        .replace('${componentName}' , this.localization.dictionary.territory.nameSingular)
        .replace('${entryName}' , this.selectedTerritory.name)
    // `Gebiet erfolgreich wiederhergestellt`
    ));
    this.loadTree();
    this.refresh();
    this.restoreModal = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    let territoryIds: string[];
    if (this.firstTab)
      territoryIds = this.toDelete.map((tree) => tree.data.id);
    else
      territoryIds = this.toDelete.map((territory) => territory.id);
    await this.server.delete('crm/territory?toDelete=' + territoryIds);
    this.server.addAlert(new AmselError(undefined, 'success', 
    this.localization.dictionary.toastr.successDeleted
     .replace("${componentName}" ,  this.localization.dictionary.territory[territoryIds.length > 1 ? 'name' : 'nameSingular'])
    // `Gebiet erfolgreich gelöscht`
    ));
    this.toDelete = undefined;
    if (this.firstTab)
      this.selectedNodes = [];
    else
      this.selected = [];
    this.saving = false;
    this.loadTree();
    this.refresh();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  applyZoom(operation?: 'in' | 'out' | 'stop') {
    if (this.zoomInterval) {
      clearInterval(this.zoomInterval)
    }
    if (operation == 'stop') {
      this.zoomInterval = undefined;
      return;
    }
    this._applyZoom(operation);
    this.zoomInterval = setInterval(()=>{
      this._applyZoom(operation);
    }, 200)
  }

  private _applyZoom(operation?: 'in' | 'out') {
    if (operation) {
      if (operation == 'in' && this.zoom < 150) {
        this.zoom+=10;
      } else if (operation == 'out' && this.zoom >= 50){
        this.zoom-=10;
      }
    }
    if (this.scrollWrapper?.nativeElement) {
      this.scrollWrapper.nativeElement.style.overflow = 'hidden';
      this.scrollWrapper.nativeElement.style.overflow = 'auto';
    }
  }

  selectionChangeTree(event, selected?: boolean) {    
    if (selected) {
      // add      
      if (this.territoryCounts[event.node.data.id].directly == 0) {
        this.selectedNodes.push(event.node);
      } else {
        // kann nicht gelöscht werden
        this.selectedNodes = this.selectedNodes.filter( (el) => el.data.id != event.node.data.id );
        this.server.addAlert(new AmselError(undefined, 'info', 
          this.localization.dictionary.territoryOverview.cannotDeleteModalDescription
        ));
      }      
    } else {
      // remove
      this.selectedNodes = this.selectedNodes.filter( (el) => el.data.id != event.node.data.id );      
    }
  }

  deleteDisabled(): boolean {
    if (this.toDelete) {
      let toDelete = this.toDelete[0]['data'] || this.toDelete[0];
      return (this.territoryCounts[toDelete.id].directly > 0);
    }
    return true;
  }

}
