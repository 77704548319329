<div *ngIf="!loading" class="install-wrapper" fxLayout="column" fxLayoutAlign="center center" [fxLayoutGap]="enterprise? '8rem' : '2rem'" [style.background-image]="style.bg" fxFill>
    <ng-container *ngIf="enterprise; else qrOnly">
        <div class="install-logo" [style.background-image]="style.logo">

        </div>
        <div class="install-box" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" [style.background]="style.boxBg" [style.color]="style.textColor" *ngIf="os !== 'unsupported'">
            <div>{{localization.dictionary.install.appAndVersion.replace('${version}', appVersion).replace('${appName}', appName) }}</div>        
            <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
        <div class="install-box" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" [style.background]="style.boxBg" [style.color]="style.textColor" *ngIf="os === 'unsupported'">
            <clr-icon shape="sad-face" size="128"></clr-icon>        
            <div>{{localization.dictionary.install.notSupported}}</div>
           
        </div>
    </ng-container>
    <ng-template #qrOnly>
        <div class="install-logo" [style.background-image]="style.logo">

        </div>
        <div class="install-box" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="1rem" [style.background]="style.boxBg" [style.color]="style.textColor">
            <div *ngIf="os === 'windows'">{{localization.dictionary.install.appAndVersion.replace('${version}', appVersion).replace('${appName}', appName) }}</div>        
            <ng-container *ngTemplateOutlet="buttons"></ng-container>

            <br>
            <div>{{localization.dictionary.install.initializes.replace('${appName}', appName)}}</div>
            <img class="qr-code-inner" [src]="qrCodeSanitized">
            <div class="alternative">{{localization.dictionary.install.alternative}}</div>
            <div #tooltipToFocus tabindex="0" [pTooltip]="localization.dictionary.install.linkCopied"
                [tooltipPosition]="'top'"
                tooltipEvent="focus"
                class="tooltip-container">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="app-link">{{uri}}</div>
                    <button class="btn btn-primary copy-link" (click)="uriToClipboard(tooltipToFocus)">
                        {{localization.dictionary.install.copyLink}}
                    </button>
                    <button class="btn copy-link back-btn" routerLink="/dashboard">{{localization.dictionary.button.back}}</button>
                </div>
            </div>
        </div>
    </ng-template>    
</div>


<ng-template #buttons>
    <a *ngIf="os === 'ipados'" [href]="sanitizer.bypassSecurityTrustUrl('itms-services://?action=download-manifest&url=' + origin + 'ipados/export_options.plist')">
        <button class="btn btn-primary install-btn" [ngStyle]="style.button">
            {{localization.dictionary.install.install}}
        </button>
    </a>
    <a *ngIf="os === 'windows'" href="./api/app">
        <button class="install-btn" [ngStyle]="style.button" >
            {{localization.dictionary.button.download}}
        </button>
    </a>
    <a *ngIf="os === 'android'" [href]="sanitizer.bypassSecurityTrustUrl(origin + 'android/amsel.apk')">
        <button class="install-btn" [ngStyle]="style.button" >
            {{localization.dictionary.button.download}}
        </button>
    </a>
</ng-template>

<app-spinner *ngIf="loading"></app-spinner>
<img *ngIf="!loading && enterprise" class="qr-code" [src]="qrCodeSanitized">