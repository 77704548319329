<clr-modal [clrModalClosable]="false" *ngIf="mode == 'modal'" [clrModalOpen]="mode == 'modal'" [clrModalSize]="'xl'">
    <h3 class="modal-title">{{ currentTopic?.title }}</h3>
    <div class="modal-body" >
      <!-- Lädt die Komponenten die für die Hilfe benötigt werden -->
      <ng-container *ngFor="let comp of components">
        <ng-container *ngComponentOutlet="comp"></ng-container>
      </ng-container>
      <h5>{{localization.dictionary.help.relatedTopics}}</h5>
      <ul class="help-ul help-topics">
        <li *ngFor="let relTop of relatedTopics" (click)="userHelp.helpWanted.next({componentName: relTop.selector, display: 'modal'}); openTopic();">
            {{ relTop.title }}
        </li>
      </ul>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="userHelp.helpWanted.next({componentName: null, display: 'off'})">{{localization.dictionary.button.ok}}</button>
    </div>
</clr-modal>

<div *ngIf="mode == 'sidebar'" class="doc-sidebar">
  <div style="display: flex; justify-content: space-between; align-items: flex-end;">
    <h2 style="margin-top: 0;">{{ currentTopic?.title }}</h2>
    <clr-icon shape="window-close" (click)="userHelp.helpWanted.next({componentName: null, display: 'off'})" size="32" style="align-self: center;"></clr-icon>
  </div>
  <div class="modal-body" >
    <!-- Lädt die Komponenten die für die Hilfe benötigt werden -->
    <ng-container *ngFor="let comp of components">
      <ng-container *ngComponentOutlet="comp"></ng-container>
    </ng-container>
    <h5 *ngIf="relatedTopics.length > 0">{{localization.dictionary.help.relatedTopics}}</h5>
    <ul class="help-ul help-topics" style="margin-bottom: 2rem;">
      <li *ngFor="let relTop of relatedTopics" (click)="userHelp.helpWanted.next({componentName: relTop.selector, display: 'sidebar'}); openTopic();">
          {{ relTop.title }}
      </li>
    </ul>
  </div>
</div>