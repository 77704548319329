import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-customer',
  templateUrl: './de-customer.component.html'
})
export class DeCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
