import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ServerService } from '../server.service';
import { Setting } from './setting.model';
import { HttpClient, HttpContext } from '@angular/common/http';
import { BYPASS_INTERCEPTOR } from '../auth/auth-interceptor';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  title = 'amSel';
  header = '';
  settingsChanged = new BehaviorSubject<boolean>(false);
  settings: Setting[] = [];

  featuresToCheck = ['formActive', 'analyticsActive', 'mailActive', 'pushActive', 'crmActive']
  featureActiveList = {};

  featureExcludes: string[];

  mode: 'dev' | 'prod' = 'dev';
  config = {};

  constructor(private server: ServerService, private http: HttpClient) {}

  async getBySelector(selector: string) {
    const res = await this.server.get('setting/bySelector/' + selector);
    return plainToClass(Setting, res);
  }

  async update() {
    const res = await this.server.get('setting');
    this.settings = plainToClass(Setting, res.rows);
    this.title = res.rows.find((setting) => setting.name === 'App Name').value;
    const headerRes = await this.server.get('branding/settingBySelector/--amsel-header-logo-bg');
    this.header = headerRes.rows[0].value;    
  }

  async emitChangeEvent() {
    this.settingsChanged.next(true);
  }
  
  updateFeatureActiveList() {    
    this.featureActiveList = {}
    this.featureExcludes = [];
    for (let feature of this.featuresToCheck) {
      const setting = this.settings.find((setting) => setting.selector === feature);
      this.featureActiveList[feature] = setting?.value == 'true';
      if (!this.featureActiveList[feature]) {
        if (feature == 'formActive') {
          this.featureExcludes.push('FORM')
        }
        if (feature == 'analyticsActive') {
          this.featureExcludes.push('ANALYTIC')
        }
        if (feature == 'mailActive') {
          this.featureExcludes.push('MAIL')
        }
        if (feature == 'pushActive') {
          this.featureExcludes.push('PUSH')
        }
        if (feature == 'crmActive') {
          this.featureExcludes.push('CRM')
        }
      }
    }
  }

  loadConfig() {
    return lastValueFrom(this.http.get('./assets/config/config.json', { context: new HttpContext().set(BYPASS_INTERCEPTOR, true) })).then( (conf) => {
      this.config = conf;
    }).catch( (err) => console.log(err));
  }

  getConfig(key: string) {
    return this.config[this.mode][key];
  }
}
