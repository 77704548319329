import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { ErrorService } from 'src/app/helper/error/error.service';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerResponse, ServerService } from 'src/app/server.service';
import { PushNotification } from '../push-notification.model';

@Component({
  selector: 'app-list-push-notifications',
  templateUrl: './list-push-notifications.component.html',
  styleUrls: ['./list-push-notifications.component.css']
})
export class ListPushNotificationsComponent implements OnInit, OnDestroy {
  loading = true;
  saving = false;
  pushes: PushNotification[] = [];

  state: ClrDatagridStateInterface;
  filters = {};
  sorting = {};
  selected: PushNotification[] = [];
  toDelete: PushNotification[];
  total = 0;
  sendModal = false;
  pushToSend: PushNotification;

  showDeleted = false;
  selectedPush: PushNotification;
  restoreModal = false;

  hidden = {
    id: true,
    updateType: true,
    groups: true,
    createdAt: true,
    updatedAt: true,
  };  

  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  @HostListener('clr-dg-column-toggle') showHideColumnToggle;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  constructor(public server: ServerService,
              public helper: HelperService,
              public errorService: ErrorService ,
              public auth: AuthService,
              public localization: LocalizationService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.showDeleted = !!localStorage.getItem('push-deleted');
    const res = await this.server.get('notification/push/' + (this.showDeleted? 'all' : ''))
    this.pushes = plainToClass(PushNotification, res.rows);    
    this.total = res.count;
    const filter = localStorage.getItem('push-filters');
    const sorting = localStorage.getItem('push-sorting');
    const hidden = localStorage.getItem('push-columns');
    if(filter) {
      this.filters = JSON.parse(filter)
    }
    if(sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if(hidden) {
      this.hidden = JSON.parse(hidden)
    }
  }

  ngOnDestroy() {
    localStorage.setItem('push-filters', JSON.stringify(this.filters));
    localStorage.setItem('push-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('push-columns', JSON.stringify(this.hidden));
    if(this.showDeleted)
      localStorage.setItem('push-deleted', 'true')
    else 
      localStorage.removeItem('push-deleted')
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters
    this.refresh(this.state)
  }  

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const res = await this.server.get('notification/push/' + (this.showDeleted? 'all' : '') + query) as ServerResponse;
    console.log(res);
    this.pushes = plainToClass(PushNotification, res.rows);
    this.total = res.count;
    this.loading = false
  }

  async send() {
    this.loading = true;
    this.saving = true;
    try {
      const pushState = await this.server.put('notification/push/send/' + this.pushToSend.id, {});    
      if (pushState.successCount > 0 && pushState.failureCount == 0) {
        this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.pushToastr.sendSucessAlert));
      } else if (pushState.successCount > 0 && pushState.failureCount > 0) {
        this.server.addAlert(new AmselError(undefined, 'info', this.localization.dictionary.pushToastr.sendWarningAlert.replace('${count}', pushState.failureCount)));
      } else {
        this.server.addAlert(new AmselError(undefined, 'warning', this.localization.dictionary.pushToastr.sendFailureAlert));
      }      
    } catch(err) {
      this.errorService.handleError(err);
    } finally {
      this.pushToSend = undefined;
      this.sendModal = false;
      this.saving = false;
      await this.refresh();
    }   
    
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  openRestoreModal(push: PushNotification) {
    this.selectedPush = push;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('notification/push/restore', this.selectedPush);
    this.server.addAlert(new AmselError(undefined, 'success', 
      this.localization.dictionary.toastr.successRestored
        .replace("${componentName}", this.localization.dictionary.push.nameSingular ).replace("${entryName}", this.selectedPush.title)
    // `Push-Nachricht erfolgreich wiederhergestellt`
    ));
    this.refresh();
    this.restoreModal = false;
  }

  async delete(): Promise<void> {
    this.loading = true;
    this.saving = true;
    const pushIds = this.toDelete.map( (push) => push.id);
    await this.server.delete('notification/push?toDelete=' + pushIds);
    this.server.addAlert(new AmselError(undefined, 'success', 
    this.localization.dictionary.toastr.successDeleted
    .replace("${componentName}", this.localization.dictionary.push['name' + (this.toDelete.length == 1 ? 'Singular' : '')])
    // `Push-Nachrichten erfolgreich gelöscht`
    ));
    this.toDelete = undefined;
    this.selected = [];
    this.saving = false;
    this.refresh();        
    this.loading = false;
  }
  columnChange = false;
  columnChanged = false;

  hidChange() {
    if(this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(()=>{
      this.columnChange = false
    })
  }
}
