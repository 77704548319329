import { Exclude, Expose } from 'class-transformer';
import { Dictionary } from 'src/app/localization/dictionary.model';;

@Exclude()
export class LanguageCategory {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    feature: string;

    @Expose()
    dictionaries: string;

    @Expose()
    dictionary: Dictionary[]= [];

}