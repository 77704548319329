
<ol class="list">

<h4 style="position: relative; right: 1.1em;">Stammdaten</h4>
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet.</li>
    <li>Erfassen Sie das Thema des Einsatzes und ergänzen Sie ggfs. Inhalte. Diese können Sie auch nach dem Termin nachtragen bzw. ergänzen.</li>
    <li>Tragen Sie die (geplante) Start- und Endzeit des Einsatzes ein.</li>
    <li>Im Anschluss an den erfolgten Termin können Sie eine Folgeaktivität eintragen.</li>
<h4 style="position: relative; right: 1.1em;">Kunden und Ansprechpartner</h4>
    <li>Im nächsten Abschnitt wählen Sie den entsprechenden Kunden aus, indem Sie den Namen der Firma eingeben und auf den entsprechenden Datensatz in der Auswahlliste klicken. Anschließend wird das Feld für den Ansprechpartner eingeblendet. Bitte klicken Sie zur Auswahl auf die entsprechende Box vor dem Ansprechpartner.</li>
<h4 style="position: relative; right: 1.1em;">Produkte und Dokumente</h4>
    <li>Anschließend können Sie dem Einsatz noch Produkte und Dokumente zuordnen.</li>
    <br>
    <li>Zum Speichern klicken Sie auf den Button „Erstellen“.</li>

</ol>