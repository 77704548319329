import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {
  @ViewChild('sigPad') sigPad;
  context;
  isDrawing = false;

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.context = this.sigPad.nativeElement.getContext('2d');
    this.context.strokeStyle = '#000';
  }

  onMouseDown(ev) {
    this.isDrawing = true;
    const coords = this._relativeCoords(ev);
    this.context.moveTo(coords.x, coords.y);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(ev) {
    this.isDrawing = false;
  }

  onMouseMove(ev) {
    if (this.isDrawing) {
      const coords = this._relativeCoords(ev);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private _relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    this.context.clearRect(0, 0, this.sigPad.nativeElement.width, this.sigPad.nativeElement.height);
    this.context.beginPath();
  }

  save() {
    const img = this.sigPad.nativeElement.toDataURL("image/png");
  }

}
