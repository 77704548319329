<div class="card widget-card" style="margin-top: 0 !important;" fxLayout="column">    
    <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{localization.dictionary.dashboard.userActivities}}</span>        
    </div>
    <div class="card-block widget">
      <div class="card-text widget">    
        <ng-container *ngIf="!loading; else spinner">
          <ngx-charts-line-chart            
            [legend]="false"
            [legendTitle]="''"           
            [xAxis]="true"
            [yAxis]="true"           
            [timeline]="true"
            [results]="data"            
            #chart
            class="widget-text"
            [ngClass]="{'dark-mode-legend': isDarkMode, 'light-mode-legend': !isDarkMode}"
            >
          </ngx-charts-line-chart>         
        </ng-container>        

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>

        <ng-template #noData>
          <app-no-data></app-no-data>
        </ng-template>
      </div>
    </div>
    <div class="card-footer">
       <app-footer-widgets [chart]="chart" [widgetTitle]="localization.dictionary.dashboard.userActivities" [data]="data" widgetName="userInteraction"></app-footer-widgets> 
    </div>  
</div>