import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Question, QuestionInputType } from '../../question.model';
import { Options } from 'ngx-slider-v2';
import { Slider } from 'primeng/slider';
import { LocalizationService } from 'src/app/localization/localization.service';


@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.css']
})
export class RangeComponent implements OnInit {
  @ViewChild('slider') slider: Slider;
  @ViewChild('sliderLegend') sliderLegend: ElementRef;
  @Input() active: boolean;
  @Input() question: Question;
  @Output() changed: EventEmitter<boolean> = new EventEmitter();
  options: Options = { floor: 0, ceil: 10, step: 1, showTicks: true, showTicksValues: true, rightToLeft: false };
  testValue = 0;

  qit = QuestionInputType;
  customStepsToggle = false;
  customSteps: string[] = ['Trifft nicht zu', 'Trifft teilweise zu', 'Trifft voll zu'];
  valueLegendArr = [];
  steps: number[] = [];

  constructor( public localization: LocalizationService) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (!this.question.range) {
      this.question.range = JSON.stringify(this.options);
    }
    if (!this.question.inputType) {
      this.question.inputType = QuestionInputType.SLIDER;
    }
    this.options = JSON.parse(this.question.range);
    this._generateTickArray();
  }

  changeOptions() {
    if (this.question.inputType === QuestionInputType.SMILEY) {
      this.options.showSelectionBar = true;
      this.options.getSelectionBarColor = (value: number): string => {
        if (value < (this.options.ceil / 3)) {
          return 'red';
        } else if (value < (this.options.ceil * 2 / 3) && value >= (this.options.ceil / 3)) {
          return 'orange';
        } else if (value >= (this.options.ceil * 2 / 3)) {
          return 'green';
        }
      }
    } else {
      delete this.options.showSelectionBar;
    }
    if (this.customStepsToggle) {
      this.generateTickArray();
    } else {
      delete this.options.stepsArray;
    }
    if (this.options.floor > (this.options.ceil - this.options.step)) {
      this.options.floor = this.options.ceil - this.options.step;
    }
    if (this.options.ceil < (this.options.floor + this.options.step)) {
      this.options.ceil = this.options.floor + this.options.step;
    }
    if (this.options.step < 1) this.options.step = 1;

    this._generateTickArray();
    /* const newOptions: Options = Object.assign({}, {});
    newOptions.ceil = this.options.ceil + 0;
    newOptions.floor = this.options.floor + 0;
    newOptions.step = this.options.step + 0;
    newOptions.showTicks = true; 
    newOptions.showTicksValues = true; 
    newOptions.rightToLeft = false;
    this.options = newOptions; */
    this.question.range = JSON.stringify(this.options);
    //this.options = JSON.parse(JSON.stringify(newOptions));
    //this.slider.options = this.options; 

    this.changed.emit(true);
  }

  _generateTickArray() {
    let count = (this.options.ceil - this.options.floor) / this.options.step + 1;
    this.steps = [];
    for (let i = 0; i < count; i++) {
      this.steps.push(this.options.floor + this.options.step * i);
    }
  }

  _testCalcLeft(i: number) {
    const percentage = ((100 / this.steps.length + 1) * i);
    return this.sliderLegend.nativeElement.clientWidth * percentage / 100 + 'px';
  }

  generateTickArray() {
    const ceil = this.options.ceil;
    const step = this.options.step;
    let currentStep = this.options.floor;
    let i = 0;
    this.valueLegendArr = [];
    while (currentStep <= ceil) {
      this.valueLegendArr.push({
        value: currentStep,
      })

      currentStep += step;
      i++;
    }
    const middle = Math.floor(this.valueLegendArr.length / 2);
    this.valueLegendArr[0].legend = this.customSteps[0];
    this.valueLegendArr[middle].legend = (this.valueLegendArr.length % 2 !== 0) ? this.customSteps[1] : '';
    this.valueLegendArr[this.valueLegendArr.length - 1].legend = this.customSteps[2];
    this.options.stepsArray = this.valueLegendArr;
  }

  test(ev) {
  }
}
