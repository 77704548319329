import { Component, DoCheck, EventEmitter, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrCombobox, ClrForm, ClrTabLink } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { User } from 'src/app/users/user.model';
import { AmselError } from '../../../helper/error/amsel-error.model';
import { PreventUnload } from '../../../helper/guards/unsaved.guard';
import { HelperService } from '../../../helper/helper.service';
import { LocalizationService } from '../../../localization/localization.service';
import { ServerService } from '../../../server.service';
import { Conflict } from '../../conflict/conflict.model';
import { Contact } from '../../contacts/contact.model';
import { CrmService } from '../../crm.service';
import { FieldValue } from '../../fields/field-value.model';
import { Field } from '../../fields/field.model';
import { Product } from '../../products/product.model';
import { Territory } from '../../territories/territory.model';
import { View } from '../../views/view.model';
import { CustomerContact } from '../customer-contact.model';
import { Customer } from '../customer.model';
import { FileUpload } from '../../file-upload/file-upload.model';

@Component({
  selector: 'app-create-edit-customer',
  templateUrl: './create-edit-customer.component.html',
  styleUrls: ['./create-edit-customer.component.css']
})
export class CreateEditCustomerComponent extends PreventUnload implements OnInit, DoCheck, OnDestroy {

  private forms: ClrForm[]
  @ViewChildren(ClrForm) set contentForms(content: QueryList<ClrForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined      
      if (!this.forms || this.forms.length == 0)
        this.forms = content.toArray();
      else
        this.forms.push(...content.toArray());
    } else {
      if (!this.forms || this.forms.length == 0)
        this.forms = [];
    }
  }
  private formDatas: NgForm[];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = content.toArray();
      else
        this.formDatas.push(...content.toArray());
    } else {
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = [];
    }
  }

  @ViewChildren(ClrCombobox) set comboBlur(content: QueryList<ClrCombobox<any>>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      for (let box of content.toArray()) {
        box.textbox.nativeElement.addEventListener('blur', () => box.triggerValidation())
      }
    }
  }

  @ViewChildren(ClrTabLink) tabLinks: QueryList<ClrTabLink>;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }

  customer: Customer;
  customerSnapshot: Customer;
  fileUpload: FileUpload;
  contactIds: string[];
  territories: Territory[];
  views: View[];
  activeView: View;
  mode: 'edit' | 'create' | 'conflict';
  loading = true;
  firstTabInvalid = false;
  customInputs = {}
  tempPosition: string;
  conflict: Conflict;
  conflictId: string;
  conflictCustomer: Customer;
  conflicts = {};
  conflictsSearched = false;
  conflictChoices = {};
  fieldConflictsToCheck = [];
  territoryConflict: { value: Territory, user: User, date: Date };
  contactConflict: { value: Contact[], user: User, date: Date };
  productConflict: { value: Product[], user: User, date: Date };
  tempTerritory: Territory;
  tempContacts: Contact[];
  errTemp = {};
  firstTab = true;
  secondTab = false;
  invalid = false;
  name: string = null;
  collapse = {};
  toAdd: Contact;
  positions = {};
  editPositionMode = false;
  sameDV: FieldValue[] = [];
  territoryRequired = false;
  contactModal = false;

  contact: Contact;
  contactSnapshot: Contact;
  createContact = new EventEmitter<null>();
  // Enthält neu erstellten Kontackte, die gelöscht werden wenn die Seite verlassen wird ohne zu speichern
  createdContacts: Contact[] = [];

  interactionActiv = false;
  fileUploadActive = false;
  auditActive = false;
  startTab: string;

  entityId: string;
  entityType = 'Customer';

  constructor(public server: ServerService,
              private route: ActivatedRoute,
              public helper: HelperService,
              public crm: CrmService,
              public router: Router,
              public localization: LocalizationService) {
    super(localization);
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        if (params.id) {
          this.mode = 'edit';
        } else if (params.conflictId) {
          this.mode = 'conflict'
          this.conflictId = params.conflictId;
        } else {
          this.mode = 'create'
        }
        if (this.mode === 'edit') {
          this.customer = plainToClass(Customer, await this.server.get('crm/customer/byId/' + params.id));
          this.fileUpload = plainToClass(FileUpload, await this.server.get('crm/file-upload/customer/' + params.id));
          this.name = this.crm.getDisplayValue(this.customer);
          for (let contact of this.customer.contacts) {
            if (contact.CustomerContact?.position) {
              this.positions[contact.id] = contact.CustomerContact.position;
            }
          }
        } if (this.mode === 'conflict') {
          this.conflict = await this.server.get('crm/conflict/byId/' + params.conflictId) as any;
          this.customer = plainToClass(Customer,this.conflict.customer);
          this.customerSnapshot = plainToClass(Customer, this.conflict.customer);
          this.conflictCustomer = plainToClass(Customer, JSON.parse(this.conflict.json));
        }
        else if (this.mode === 'create') {
          this.customer = new Customer();
          this.dirty = true;
        }
        this.contactIds = this.customer.contacts.map(contact => contact.id)
        this.changeUnload();
        this.loading = false;
      })
    );
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(()=> {
        this.changeUnload();
      })
    );
    this.subscriptions.add(
      this.route.queryParamMap.subscribe(async paramMap =>{
        if (paramMap.keys.length > 0){
          paramMap.keys.forEach(key =>{
            if(key === 'tab'){
              if(paramMap.get(key) === 'interaction'){
                this.interactionActiv = true;
              }
              
            }
          })
        }}));

    const currentNavigation = this.router.getCurrentNavigation();
    this.startTab = currentNavigation?.extras.state?.tab; 
  }

  async ngOnInit(): Promise<void> {
    const res = await this.server.get('crm/territory/forUser');
    this.territories = plainToClass(Territory, res.rows);
    this.crm.setActiveViewsFromStorage(['customer']);
    this.views = this.crm.views.customer;
    this.activeView = this.crm.activeViews.customer;
    let display = [];
    this.activeView.sections.forEach(section => display.push([...section.fields.filter(field => field.display)]));
    for (let section of this.activeView.sections) {
      this.collapse[section.id] = false;
    }
    this.collapse['territory'] = false;
    this.collapse['products'] = false;
    this.collapse['ufiles'] = false;
  }

  ngAfterViewInit() {
    if (this.startTab === 'interactions') {
      this.interactionActiv = true;
    }
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('customer');
    } else {
      this.changeUnloadEdit('customer', this.crm.getDisplayValue(this.customer));
    }
  }

  async ngOnDestroy() {
    localStorage.setItem('customer-active-view', JSON.stringify(this.activeView));
    // Zerstört alle neu erstellten Kontakte, wenn die Seite verlassen wird ohne zu speichern
    let contactIds = this.createdContacts.map(contact => contact.id);
    let query = new URLSearchParams();
    query.append('toDestroy', contactIds.join(','));
    let resp = await this.server.delete('crm/contact/destroy?' + query.toString());
  }

  ngDoCheck() {
    if (!this.conflictsSearched && this.mode == 'conflict' && this.customer && this.conflictCustomer) {
      for (let key of this.crm.fields.customer.map((field) => field.name)) {
        let conflictFound = false;
        if (this.customer[key] && this.conflictCustomer[key]) {
          if (this.customer[key].length !== this.conflictCustomer[key].length) {
            conflictFound = true;
          } else if (this.customer[key].length == 1 && this.customer[key][0].value !== this.conflictCustomer[key][0].value) {
            conflictFound = true;
          } else {
            for (let fieldValue of this.customer[key]) {
              if (!this.conflictCustomer[key].find(conValue => conValue.fieldOptionId == fieldValue.fieldOptionId)) {
                conflictFound = true;
              }
            }
          }
        } else if (!this.customer[key] && this.conflictCustomer[key]) {
          conflictFound = true;
        }
        if (conflictFound) {
          this.conflicts[key] = { value: this.conflictCustomer[key], user: this.conflict.user, date: this.conflict.date };
          this.fieldConflictsToCheck.push(key);
        }
      }

      let productConflictFound = false;
      if (this.customer.products && this.conflictCustomer.products) {
        if (this.customer.products.length !== this.conflictCustomer.products.length) {
          productConflictFound = true;
        } else {
          for (let product of this.customer.products) {
            if (!this.conflictCustomer.products.find(conValue => conValue.id == product.id)) {
              productConflictFound = true;
            }
          }
        }
      } else if (!this.customer.products && this.conflictCustomer.products) {
        productConflictFound = true;
      }
      if (productConflictFound) {
        this.productConflict = { value: this.conflictCustomer.products, user: this.conflict.user, date: this.conflict.date };
      }
      let contactConflictFound = false;
      if (this.customer.contacts && this.conflictCustomer.contacts) {
        if (this.customer.contacts.length !== this.conflictCustomer.contacts.length) {
          contactConflictFound = true;
        } else {
          for (let contact of this.customer.contacts) {
            if (!this.conflictCustomer.contacts.find(conValue => conValue.id == contact.id && conValue.CustomerContact.position == contact.CustomerContact.position)) {
              contactConflictFound = true;
            }
          }
        }
      } else if (!this.customer.contacts && this.conflictCustomer.contacts) {
        contactConflictFound = true;
      }
      if (contactConflictFound) {
        this.contactConflict = { value: this.conflictCustomer.contacts, user: this.conflict.user, date: this.conflict.date };
      }
      if (this.customer["territoryId"] != this.conflictCustomer.territory?.id) {
        this.territoryConflict = { value: this.conflictCustomer.territory, user: this.conflict.user, date: this.conflict.date };

      }

      this.conflictsSearched = true;
    }
    if (!this.name && (this.mode === 'edit' || this.mode == 'conflict') && this.customer) {
      this.name = this.crm.getDisplayValue(this.customer);
      this.changeUnloadEdit('customer', this.name);
    }
    if (this.invalid && this.firstTab && this.forms && this.formDatas) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();

        if (formData.invalid) {
          this.collapse[formData.name] = false;
        }
        if (!this.customer.territory) {
          this.collapse['territory'] = false;
        }
        for (let form of this.forms) {
          form.markAsTouched();
        }
        this.invalid = false;
      }
      if (Object.keys(this.errTemp).length > 0 && this.formDatas?.length > 0 && this.formDatas.find(formData => Object.keys(formData.form.controls).length > 0)) {
        for (let formData of this.formDatas) {
          formData.form.markAsDirty();
        }
        for (let form of this.forms) {
          form.markAsTouched();
        }
        this.markFieldsAsInvalid(this.errTemp);
        this.errTemp = {};
      }
    }
    if (this.firstTab && this.formDatas) {
      this.firstTabInvalid = false;
      for (let form of this.formDatas) {
        if (!form.valid && !form.disabled) {
          this.firstTabInvalid = true;
        }
      }
    }
      
  }

  addContact(contact: Contact) {
    this.positions[contact.id] = this.tempPosition;
    if (!contact.CustomerContact) {
      contact.CustomerContact = new CustomerContact();
    }
    contact.CustomerContact.position = this.tempPosition;
    this.tempPosition = undefined;
    this.customer.contacts.push(contact);
    this.contactIds = this.customer.contacts.map(contact => contact.id)
    this.toAdd = undefined;
  }


  changeContactPosition(contact: Contact) {
    this.positions[contact.id] = this.tempPosition;
    const findCont = this.customer.contacts.find(cons => contact.id == cons.id);
    findCont.CustomerContact.position = this.tempPosition;
    this.tempPosition = undefined;
    this.editPositionMode = false;
    this.toAdd = undefined;
  }

  removeContact(contact: Contact) {
    if (this.createdContacts.some(c => c.id == contact.id)) {
      let query = new URLSearchParams();
      query.append('toDestroy', contact.id);
      this.server.delete('crm/contact/destroy?' + query.toString());
    }
    this.customer.contacts = this.customer.contacts.filter(customerContact => customerContact.id != contact.id);
    this.contactIds = this.customer.contacts.map(contact => contact.id)
  }

  openList() {
    this.dirty = false;
  }

  addCustomForms(forms: ClrForm[]) {
    if (!this.forms || this.forms.length == 0)
      this.forms = [];
    this.forms.push(...forms);
  }

  addCustomFormDatas(formDatas: NgForm[]) {
    if (!this.formDatas)
      this.formDatas = [];
    this.formDatas.push(...formDatas);
  }

  async validateAndSave(createCall?: boolean) {
    this.territoryRequired = true;
    if (!this.firstTabInvalid && this.customer.territory && (!this.territoryConflict || this.conflictChoices['territory']) &&
      (!this.contactConflict || this.conflictChoices['contacts']) && this.fieldConflictsToCheck.every(con => !!this.conflictChoices[con])) {
      await this.save(createCall);
    }
    else {
      if (!this.firstTabInvalid && (!this.territoryConflict || this.conflictChoices['territory']) && this.contactConflict && !this.conflictChoices['contacts']) {
        this.secondTab = true;
      } else {
        this.firstTab = true;
      }
      this.invalid = true;
    }
  }
  
  checkDV(event: [string, FieldValue[]]) {
    if (event && event[0] == '+1') {
      event[1] = event[1].filter(ev => !this.sameDV.find(dv => ev.id == dv['id']));
      this.sameDV.push(...event[1]);
    } else if (event && event[0] == '-1') {
      this.sameDV = this.sameDV.filter(dv => dv['id'] != event[1][0].id);
    }
  }

  setQueryParams() {
    let obj = {};
    for (let dv of this.sameDV) {
      obj[dv.field.name] = dv.value;
    }
    return obj;
  }

  setInput(field: Field, value: string) {
    if (this.customer[field.name] && this.customer[field.name].length > 0) {
      this.customer[field.name][0].value = value
    } else {
      const fieldValue = new FieldValue()
      fieldValue.field = field;
      fieldValue.fieldId = field.id;
      fieldValue.value = value;
      this.customer[field.name] = [fieldValue]
    }
  }

  async save(createCall?: boolean) {
    this.dirty = false;
    this.loading = true;
    this.saving = true;
    this.createdContacts = [];
    
    if (this.mode === 'create') {
      let res = await this.server.post('crm/customer', this.customer);
      this.customer = plainToClass(Customer, res);
    } else {
      await this.server.put('crm/customer', this.customer);
    }
    if (this.mode == 'conflict') {
      await this.server.delete('crm/conflict?toDelete=' + this.conflictId)
    }
    this.server.addAlert(new AmselError(
      undefined,
      'success',
      this.localization.dictionary.toastr.successSaved
        .replace('${componentName}', this.localization.dictionary.customer.nameSingular)
        .replace('${entryName}', this.crm.getDisplayValue(this.customer))
    ));
    this.saving = false;
    if (!createCall) {
      this.server.back();
    } else {
      // Verhindert, dass beim Erstellen eines Anrufs auf die Customer create Seite zurückgeleitet wird
      let refresh = window.location.protocol + '//' + window.location.host + '/crm/customer/edit/' + this.customer.id;
      window.history.replaceState(null, '',refresh)
      this.router.navigateByUrl('/crm/call/create/' + this.customer.id)
    }
  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
      }
      if (this.forms.length > 0)
        for (let form of this.forms) {
          form.markAsTouched();
        }
      for (let field of Object.keys(errors)) {
        if (this.formDatas.length > 0) {
          const controlFormDatas = this.formDatas.filter(formData => formData.form.controls[field])
          for (let controlFormData of controlFormDatas) {
            controlFormData.form.controls[field].markAsDirty();
            controlFormData.form.controls[field].setErrors(errors[field]);
          }
        } else {
          this.errTemp = { [field]: errors[field] }
        }
      }
    }
  }

  //Umgang mit Konflikten 

  displayTerritoryConflict(territory: Territory) {
    if (!territory) {
      this.tempTerritory = JSON.parse(JSON.stringify(this.customerSnapshot.territory));
      territory = this.customer.territory;
    }
    return territory?.name

  }

  displayContactConflict(contacts: Contact[]) {
    if (!contacts) {
      this.tempContacts = plainToClass(Contact, this.customerSnapshot.contacts);
      contacts = this.customerSnapshot.contacts;
    }
    return contacts.map(con => this.crm.getDisplayValue(con) + (con.CustomerContact?.position?.replace(/^(.*)$/, ' ($1)') ?? '')).join(', ')

  }

  onConflictChangeTerritory(territory: Territory, type: 'conflict' | 'model') {
    let clone: Territory;
    if (type == 'conflict') {
      clone = JSON.parse(JSON.stringify(territory));
    } else {
      clone = JSON.parse(JSON.stringify(this.tempTerritory));
    }
    this.customer.territory = clone;
  }

  onConflictChangeContact(contacts: Contact[], type: 'conflict' | 'model') {
    let clone: Contact[];
    if (type == 'conflict') {
      clone = JSON.parse(JSON.stringify(contacts));
    } else {
      clone = JSON.parse(JSON.stringify(this.tempContacts));
    }
    this.positions = {};
    for (let con of clone) {
      if (con.CustomerContact?.position) {
        this.positions[con.id] = con.CustomerContact.position;
      }
    }
    this.customer.contacts = clone;
    this.contactIds = this.customer.contacts.map(con => con.id);
  }

  newContact(contact: Contact) {
    this.dirty = true;
    this.contactModal = false;
    if (contact){
      this.toAdd = contact;
      this.createdContacts.push(contact);
    }
  }

}
