import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from '../../../localization/localization.service';
import { TrackingService } from '../../tracking.service';
import { WidgetInterface } from '../widget.interface';
import { ModalService } from '../../dashboards/diagram-popup/modal-data.service';
import { View } from 'src/app/crm/views/view.model';
import { HelperService } from 'src/app/helper/helper.service';

@Component({
  selector: 'app-widget-crm-call-by-product',
  templateUrl: './widget-crm-call-by-product.component.html',
  styleUrls: ['./widget-crm-call-by-product.component.css']
})
export class WidgetCrmCallByProductComponent implements OnInit, WidgetInterface {
  @ViewChild('chart') chart: ElementRef;
 
  loading: boolean;
  id: string;
  isOpen = false;

  from: Date;
  to: Date;

  data = [];

  constructor(private tracking: TrackingService, 
    public localization: LocalizationService,
    public modalService: ModalService,
    public helper: HelperService) { 
      this.helper.loading$.subscribe(({ id, loading }) => {
        if (id === 'crmCallByProduct') {
          this.loading = loading;
        }
      });
    
    }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  } 

  async refresh(from?: Date, to?: Date) {
    this.loading = true;
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
    const res = await this.tracking.getCallsCountByProduct('?' + query.toString()) as any;
    this.data = [];
    for (let data of res) {
      this.data.push({
        name: data.name,
        value: data.count
      });
    }
    this.loading = false;
  }

  round(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

}
