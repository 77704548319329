<div class="card widget-card" style="margin-top: 0 !important;" fxLayout="column">
    <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{localization.dictionary.crmDashboard.callByProductDashboard}}</span>
    </div>
    <div class="card-block widget" fxFill>
      <div class="card-text widget" fxLayout="column">
        <ng-container *ngIf="!loading; else spinner">
          <ngx-charts-bar-vertical
            class="widget-text"
            [legend]="false"
            [legendTitle]="''"           
            [results]="data" 
            #chart           
            [xAxis]="true"
            [yAxis]="true"  
            [yAxisTickFormatting]="round"          
            >
          </ngx-charts-bar-vertical>
        </ng-container>
        <ng-template #noData>
          <app-no-data></app-no-data>
        </ng-template>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>

      </div>
    </div>
    <div class="card-footer">
      <app-footer-widgets [chart]="chart" [widgetTitle]="localization.dictionary.crmDashboard.callByProductDashboard" [data]="data" widgetName="callByProduct"></app-footer-widgets>
    </div>
</div>
