import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, map, merge } from 'rxjs';
import { ServerService } from 'src/app/server.service';


@Injectable({
  providedIn: 'root'
})

export class ModalService {
  public widget = new Subject<any>();
  
  data = [];
  
  docName: string;
  docId: string;
  from: Date;
  to: Date;

  loading = false;
  presentationData: {document: {name: string, id: string}, duration: number, value?: string}[];

  yTicks = [];

  constructor(public server: ServerService) {}

  openModal(chart: any, title: string, data: any, widgetName: string) {
    this.widget.next({
      chart: chart,
      title: title,
      data: data,
      widgetName: widgetName
    })
  }

  closeModal(chart: any, title: string, data: any) {
    this.widget.next(undefined);
  }
}
