export enum DeviceType {
    IOS = 'IOS',
    WIN = 'WIN',
    ANDROID = 'ANDROID',
    LINUX = 'LINUX',
    WEB = 'WEB'
}

export class Session {
    id: string;
    deviceType: DeviceType;
    token: string;
    refreshToken: string;
    validTo: Date;
    updatedAt: Date;
}