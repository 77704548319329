<div *ngIf="active && !question.reference; else showAnswers" fxLayout="column" fxLayoutGap="1rem" cdkDropList (cdkDropListDropped)="drop($event)" class="default-wrapper" cdkScrollable>
    <div *ngFor="let answer of answers; let i = index" fxLayout="row" fxLayoutAlign="start center" cdkDrag>
        <clr-icon shape="cursor-move" style="margin-right: 1rem;" cdkDragHandle></clr-icon>
        <ng-container [ngTemplateOutlet]="questionTypes" [ngTemplateOutletContext]="{j: i}"></ng-container>
            
        <clr-input-container >
            <input [class.disabled]="answer.disabled"
                clrInput 
                type="text" 
                [(ngModel)]="answer.value" 
                (ngModelChange)="changed.emit(true)"
                [placeholder]="localization.dictionary.formCreateEdit.enterNewAnswer" 
                [ngClass]="(type === 'CHOICE') ? 'radio' : ''" 
                [name]="'answer' + i"
                autocomplete="off"
                [disabled]="fieldReference"
                #answerInput
                maxlength="100"  
                [disabled]="published && !answer.new"            
                >  
        </clr-input-container>
        
        <clr-icon *ngIf="(!published || answer.new) && answers.length > 1" shape="trash" style="margin-left: 2rem;" (click)="delete(i)"></clr-icon>
        <clr-icon *ngIf="published && !answer.new && answers.length > 1" [attr.shape]="answer.disabled ? 'success-standard' : 'ban'" style="margin-left: 2rem;" (click)="answer.disabled = !answer.disabled; changed.emit(true)"></clr-icon>
    </div>

    <button *ngIf="!question?.reference" class="btn btn-sm btn-outline btn-icon" style="width: 11rem" (click)="addNewAnswer()" [disabled]="fieldReference">
        <clr-icon shape="plus-circle" class="plus-circle"></clr-icon>
        <span style="margin-left: 0.2rem;">{{localization.dictionary.formCreateEdit.enterNewAnswer}}</span>
    </button>  
</div>

<ng-template #showAnswers>

    <div fxLayout="column" class="show-answers">
        <ng-container *ngFor="let answer of answers; let j = index">
            <div fxLayout="row" *ngIf="!answer.disabled" style="padding-right: 2rem">      
                <ng-container [ngTemplateOutlet]="questionTypes" [ngTemplateOutletContext]="{j: j}"></ng-container>       
                <span class="answer" [ngClass]="(type === 'CHOICE') ? 'radio' : ''">{{ answer.value}}</span>               
            </div>
        </ng-container>
        
    </div>
</ng-template>

<ng-template #questionTypes let-j="j">

    <input *ngIf="type === 'MULTIPLE_CHOICE'" type="checkbox" clrCheckbox disabled/>
    <input *ngIf="type === 'CHOICE'" type="radio" clrRadio disabled/>
    <div *ngIf="type === 'SELECTION'" class="selection">
        {{ j + 1 }}.
    </div>
    
</ng-template>