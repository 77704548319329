import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { regexValidator } from './regex.validator';

@Directive({
  selector: '[regexValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: RegexDirective, multi: true}]
})
export class RegexDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {    
    return regexValidator()(control);
  }
}
