<ng-container *ngTemplateOutlet="actionBar"></ng-container>
<clr-datagrid [clrDgLoading]="loading" (clrDgRefresh)="refresh($event)" #datagridRef>
    <clr-dg-column [clrDgSortBy]="'name'"
    [(clrDgSortOrder)]="sorting['name']" (clrDgSortedChange)="this.selected = []"> {{localization.dictionary.general.name}} 
        <clr-dg-filter [clrDgFilter]="stringFilter" [(clrDgFilterOpen)]="stringFilter.open">
        <string-filter #stringFilter [property]="'name'"
            [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'type'"
    [(clrDgSortOrder)]="sorting['type']" (clrDgSortedChange)="this.selected = []">
        {{localization.dictionary.type.nameSingular}}
        <clr-dg-filter [clrDgFilter]="typeFilter" [(clrDgFilterOpen)]="typeFilter.open">
            <multiselect-filter #typeFilter [name]="localization.dictionary.type.name" [property]="'type'" [toggle]="false" [array]="queryArray" [crmCore]="true"
                [(filter)]="filters['type']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'contacts'"
    [(clrDgSortOrder)]="sorting['contacts']" (clrDgSortedChange)="this.selected = []">
    <ng-template clrDgHideableColumn >
        {{localization.dictionary.contact.name}}
    </ng-template>
    <clr-dg-filter [clrDgFilter]="contactFilter" [(clrDgFilterOpen)]="contactFilter.open">
        <combobox-filter #contactFilter [property]="'contacts'" [name]="localization.dictionary.contact.name"
            [customFields]="true" [query]="'crm/contact/combobox/' + contactView.id" [type]="contactType"
            [(filter)]="filters['contacts']">
        </combobox-filter>
    </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'users'"
    [(clrDgSortOrder)]="sorting['users']" (clrDgSortedChange)="this.selected = []"> {{localization.dictionary.user.nameSingular}} 
        <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
            <combobox-filter #userFilter [property]="'users'" [name]="localization.dictionary.user.username"
                [customFields]="true" [query]="'user/combobox'" [type]="userType"
                [(filter)]="filters['users']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'date'"
    [(clrDgSortOrder)]="sorting['timeStamp']" (clrDgSortedChange)="this.selected = []"> {{localization.dictionary.general.date}} 
        <clr-dg-filter [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'timeStamp'"
                [(filterFrom)]="filters['dateFrom']" [(filterTo)]="filters['dateTo']">
            </date-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-row *ngFor="let interaction of interactions" (click)="onRowSelected(interaction)">
        <clr-dg-cell> {{ interaction.name }} </clr-dg-cell>
        <clr-dg-cell>
            <span *ngIf="interaction.type == 'call'">
                {{localization.dictionary.call.nameSingular}}
            </span>
            <span *ngIf="interaction.type == 'answeredForm'">
                {{localization.dictionary.form.nameSingular}}
            </span>
        </clr-dg-cell>
        <clr-dg-cell>
            <span *ngFor="let contact of interaction.contacts"class="label label-light-blue">
                <app-display-name [model]="contact" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>
        <clr-dg-cell>
            <span *ngFor="let user of interaction.users"
                class="label label-light-blue"
                [pTooltip]="user.firstName + ' ' + user.lastName"
                tooltipPosition="top">
                {{user.name}}
            </span>
        </clr-dg-cell>
        <clr-dg-cell> 
            {{ interaction.date | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
    </clr-dg-row>   
</clr-datagrid>


<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
        </clr-dg-action-bar>
    </div>
</ng-template>