import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router  
  ) {}

  canActivate(    
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().then( (authenticated) => {
      const hasRoles = this.authService.hasRole(route.data.roles);
      if (authenticated && hasRoles) {
        // eingeloggt und passende Rollen
        return true;
      } else if (!authenticated) {
        // nicht eingeloggt
        this.router.navigate(['/login']);
      } else {
        // eingeloggt, aber nicht die passenden Berechtigungen
        this.router.navigate(['/no-permission']);
      }      
      return false;
    })
  }
  
}
