<div>
    <div class="card" *ngIf="!loading">
        <div class="card-header space-between-flex">
            {{ helper.getCardHeader(mode, 'language', language.name) }}
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                    <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                    <p class="card-text icon-offset">
                        {{localization.dictionary.createEdit.baseInformationDescription.replace('${componentDative}',
                        localization.dictionary.language.dative)}}
                    </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                    (click)="collapse[0] = !collapse[0]"></clr-icon>
            </div>
            <div [hidden]="collapse[0]" class="card-text">
                <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset" autocomplete="off">
                    <clr-input-container>
                        <label>{{localization.dictionary.language.label + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="language.name" name="name" autocomplete="nopeLab"
                            required maxlength="100" />
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{localization.dictionary.language.selector + '*'}}</label>
                        <input clrInput type="text" [(ngModel)]="language.selector" name="selector"
                            autocomplete="nopeSel" pattern='\S*' required maxlength="5" />
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-input-container>
                    <clr-checkbox-container>
                        <label>{{localization.dictionary.general.active}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="active" [(ngModel)]="language.active"
                                [disabled]="deactivate(language)" />
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </form>
            </div>
        </div>
        <div class="card-block card-cutout">
            <div class="card" *ngIf="!loading">
                <div class="card-header">
                    <div>{{localization.dictionary.language.translate}}</div>
                </div>
                <div class="card-block">
                    <form clrForm #formData="ngForm" class="icon-offset card-text " [ngClass]="getClass('form')" >
                        <clr-select-container [ngClass]="getClass('container')">
                            <label>{{localization.dictionary.language.basicLanguage}}</label>
                            <select clrSelect [(ngModel)]="basicLanguageId" (ngModelChange)="changeBaseLanguage($event)"
                                name="basicLanguage" *ngIf="languages">
                                <option *ngFor="let language of languages" [value]="language.id">
                                    <span style="margin-left: 0.2rem">{{language.name}}</span>
                                </option>
                            </select>
                        </clr-select-container>
                        <clr-toggle-container [ngClass]="getClass('container')" *ngIf="!noMissing">
                            <clr-toggle-wrapper>
                              <input type="checkbox" [(ngModel)]="showMissing" (ngModelChange)="sortDicts()" clrToggle value="option1" name="showMissing" />
                              <label>{{ localization.dictionary.language.showMissing }}</label>
                            </clr-toggle-wrapper>
                            <clr-control-helper>{{ showMissing ? localization.dictionary.language.onlyMissing : localization.dictionary.language.allDisplayed }}</clr-control-helper>
                          </clr-toggle-container>
                    </form>
        
                    <p-table #dt2 [value]="dictionaries" [scrollable]="true" [loading]="dictReload" scrollHeight="78vh"
                        rowGroupMode="subheader" groupRowsBy="languageCategory.name"
                        [globalFilterFields]="['languageCategory.name','baseLanguage','label', 'tag']">
        
                        <ng-template pTemplate="caption">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                        placeholder={{localization.dictionary.language.searchKeyword}} />
                                </span>
                                <label style="width: 14rem">{{localization.dictionary.language.progress}}</label>
                                <div class="progressBar-container">
                                    <p-progressBar [class]="changeColor()" [value]="progress"></p-progressBar>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-languages.id>
                            <tr *ngIf="languages">
                                <th>{{localization.dictionary.language.category}}</th>
                                <th>{{baselanguageName}}</th>
                                <th>{{language.name?.length > 0 ? language.name : localization.dictionary.language.new }}</th>
                                <th>{{localization.dictionary.language.tag}}</th>
                            </tr>
                            <tr class="oneFilter">
                                <th> <p-columnFilter matchMode="contains" type="text" field="languageCategory.name"
                                        maxlength="100"></p-columnFilter></th>
                                <th>
                                    <p-columnFilter matchMode="contains" type="text" field="baseLanguage"
                                        maxlength="100"></p-columnFilter>
                                </th>
                                <th>
                                    <p-columnFilter matchMode="contains" type="text" field="label"
                                        maxlength="100"></p-columnFilter>
                                </th>
                                <th> <p-columnFilter matchMode="equals" field="tag" [showMenu]="true" class="procentBar">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="tags" (onChange)="filter($event.value)">
                                                <ng-template let-option pTemplate="item">
                                                    <span>{{option.label}}</span>
                                                </ng-template>
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="groupheader" let-dictionary>
                            <tr pRowGroupHeader>
                                <td colspan="5">
                                    <span class="font-bold ml-2">{{dictionary.languageCategory.name}}</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-dictionary let-editing="editing">
                            <tr [ngStyle]="{'border': dictionary.label == '' && missMatch ? '2px solid #ffcd36' : ''}">
                                <td>
                                    <span class="image-text"></span>
                                </td>
                                <td>
                                    {{dictionary.baseLanguage}}
                                </td>
                                <td [pEditableColumn]="dictionary.label" pEditableColumnField="label" class="invalidCell">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" class="p-inputtext-sm" (focusout)="sortDicts()" (keydown)="keydown($event)" [(ngModel)]="dictionary.label"
                                                (ngModelChange)="setProgressBar(); dirty = true" maxlength="50">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{dictionary?.label}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{dictionary.tag}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">{{localization.dictionary.language.emptymessage}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        
        <div stickyFooter class="card-footer" style="display: flex;">
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>

        </div>
    </div>

    <!--     Subheader Grouping -->


    

    <app-spinner *ngIf="loading"></app-spinner>

    <ng-template #actionBar>
            <button class="btn btn-primary" type="submit" (click)="validateAndSave()" [disabled]="loading">
                <span>{{ ( mode=== 'create' ) ? localization.dictionary.button.create :
                    localization.dictionary.button.save}}</span>
            </button>
            <button type="button" class="btn btn-outline"
                (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
            <button type="button" class="btn btn-primary"
                (click)="onDownloadExcel();">{{localization.dictionary.button.excelExport}}</button>
            <label for="file" class="clr-control-label upload-label upload-label-extended" fxLayout="row"
                fxLayoutGap="1rem">
                <span class="btn btn-primary" [class.btn-danger]="isExcelFile === false">
                    {{localization.dictionary.button.excelImport}}
                </span>
            </label>
            <input type="file" id="file" name="fileExcel" [accept]="allowedFiles" (change)="onImportExcel($event)"
                placeholder="Enter value here" class="clr-file" [disabled]="loadingExcel" />
            <clr-control-error class="clr-subtext" *ngIf="isExcelFile === false">
                {{localization.dictionary.language.requiredFile}}
            </clr-control-error>

    </ng-template>


</div>


<clr-modal [(clrModalOpen)]="openWarning">
    <h3 class="modal-title" >{{localization.dictionary.language.overwriteTranslations}}</h3>
    <div class="modal-body" >
        {{localization.dictionary.language.importData}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="importExcel(); this.openWarning = false">{{localization.dictionary.general.yes}}</button>
        <button type="button" class="btn btn-outline" (click)="cancelImport()">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>