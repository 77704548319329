import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { LocalizationService } from "../../../localization/localization.service";
import { CrmService } from "../../crm.service";
import { plainToClass } from "class-transformer";
import { InteractionCustomer } from "./interaction-customer.model";
import { ServerService } from "../../../server.service";
import { Call } from "../../calls/call.model";
import { Router } from "@angular/router";
import { AnsweredForm } from "../../../forms/answered-form.model";
import { Contact } from "../../contacts/contact.model";
import { View } from "../../views/view.model";
import { ClrDatagrid, ClrDatagridStateInterface } from "@clr/angular";
import { User } from "src/app/users/user.model";

@Component({
  selector: "app-interaction-customer",
  templateUrl: "./interaction-customer.component.html",
  styleUrls: ["./interaction-customer.component.css"]
})
export class InteractionCustomerComponent implements OnInit {
  queryArray =['form','call'];
  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  @Input() customerId: string;
  selected: InteractionCustomer[] = [];
  contactType = Contact;
  userType = User;
  sorting = {};
  filters = {};
  hidden: { [column: string]: boolean } = {
    id: true,
    createdAt: true,
    updatedAt: true
  }
  contactViews: View[];
  contactView: View;
  customerViews: View[];
  customerView: View;
  state: ClrDatagridStateInterface;
  total = 0;
  

  interactions: InteractionCustomer[]; // This is an array of interactions
  loading = true;
  

  constructor(
    public localization: LocalizationService,
    public crm: CrmService,
    public server: ServerService,
    public router: Router
  ) {}

  ngOnInit(): void {
    let refresh = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.history.replaceState(null, '',refresh);
    this.contactViews = this.crm.views.contact;
    this.contactView = this.crm.activeViews.contact;
    this.customerViews = this.crm.views.customer;
    this.customerView = this.crm.activeViews.customer;
    this.refresh();
  }


  async refresh(state?: ClrDatagridStateInterface) {    
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }  
    this.state = state;
    let query = await this.server.buildQueryFromGrid(state);
    this.loading = true;
    
    const inter = await this.server.get("crm/customer/interaction/" + this.customerId + query);
    
    this.total = inter.count;
    this.interactions = plainToClass(InteractionCustomer, inter.rows);
        
    this.loading = false;    
  }



  /**
   * Handles the selection and routing of a row in the interaction-customer component.
   * 
   * @param interaction - The selected InteractionCustomer object.
   */
  onRowSelected(interaction: InteractionCustomer){
    let refresh = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + 'tab=interaction';
    window.history.replaceState(null, '',refresh)

    if(interaction.type === "call"){
      this.router.navigateByUrl("/crm/call/edit/" + interaction.value.id );
    }else if(interaction.type === "answeredForm"){
      this.router.navigateByUrl("/forms/results/" + interaction.value.formId + '?answeredFormId=' + interaction.value.id + '&customer=["' + this.customerId+'"]');
    }
  }

  async resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    delete this.state.sort;
    await this.refresh(this.state);
  }

}
