<p>Im Bereich Gebiete können Sie die Verkaufsgebiete Ihres Unternehmens verwalten, z.B. auf Länderebene oder heruntergebrochen auf Bundesländer oder Regionen (z.B. Süd). Die Ansicht der bestehenden Gebiete kann in Baumansicht oder Listenansicht erfolgen.</p>

<ol class="liste">
<h4>Anlage von Gebieten</h4>    
    <li>Klicken Sie im Hauptmenü unter dem Punkt „CRM“ auf „Gebiete“ und wählen Sie eine der beiden Ansichten. Um eine Übersicht der bereits bestehenden Gebietsstruktur zu erhalten, empfehlen wir die Baumansicht.</li>
    <li>Zur Anlage eines neuen Gebietes klicken Sie auf den Button „Gebiet hinzufügen“.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>

<ol class="liste">
<h4>Bearbeitung von Gebieten</h4>    
    <li>Klicken Sie im Hauptmenü unter dem Punkt „CRM“ auf „Gebiete“ und wählen Sie eine der beiden Ansichten. Um eine Übersicht der bereits bestehenden Gebietsstruktur zu erhalten, empfehlen wir die Baumansicht.</li>
    <li>A. Zur Bearbeitung eines Gebietes, in der Baumansicht, klicken Sie auf die Bearbeitungs-Aktion des gewünschten Gebietes. <br>
        B. Zur Bearbeitung eines Gebietes, in der Listenansicht, wählen Sie das gewünschte Gebiet in der Liste aus. Alternativ können Sie sich über Aktionen, die Bearbeitung auswählen.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
</ol>

<ol class="liste">
    <h4>Löschen von Gebieten</h4>    
        <li>Um ein Gebiet zu löschen, klicken Sie in der Baumansicht auf den entsprechenden Kasten. Neben dem Button „Gebiet hinzufügen“ erscheint der Button „Löschen“. Klicken Sie auf diesen Button und bestätigen Sie anschließend den Löschvorgang in dem neu geöffneten Fenster, in dem Sie auf den Button „Löschen“ klicken.</li>
        <li>In der Listenansicht klicken Sie auf das Papierkorb-Symbol in der letzten Spalte und bestätigen Sie den Löschvorgang im Pop up-Fenster, indem Sie auf den Button „Löschen“ klicken.</li>
    </ol>