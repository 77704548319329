import { Component, Input, OnInit } from '@angular/core';
import { CrmService } from 'src/app/crm/crm.service';

@Component({
  selector: 'app-display-name',
  templateUrl: './display-name.component.html',
  styleUrls: ['./display-name.component.css']
})
export class DisplayNameComponent implements OnInit {

  @Input() model: any;
  /* @Input() displayFields: Field[];
   */
  @Input() prePostFix = '';
  @Input() seperator = '';
  /* @Input() table: string;
  varName : string; */
  displayValue: string;

  constructor(private crm: CrmService) { }

  ngOnInit(): void {
    if (this.model) {
      this.displayValue = this.crm.getDisplayValue(this.model);
    }
  }
}
