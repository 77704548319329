import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, Type } from '@angular/core';
import { ClrDatagridFilter, ClrDatagridFilterInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Contact } from 'src/app/crm/contacts/contact.model';
import { CrmService } from 'src/app/crm/crm.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { Unsubscribable } from '../../unsubscribable/unsubscribable';

@Component({
  selector: 'combobox-filter',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.css']
})
export class ComboboxComponent extends Unsubscribable implements ClrDatagridFilterInterface<any>, OnInit {

  @Input() toggle: boolean = true;
  @Input() name: string;
  @Input() type: ClassType<any>;
  @Input() property: string;
  @Input() query: string;
  @Input() array: string[];
  @Input() localizationKey: string;
  @Input() confirm = false;
  @Input() filter = {value: [], toggle: true, toggleNeeded: false};
  @Input() crmCore: boolean;
  @Input() display: string = "name";
  @Input() customFields: boolean;
  @Input() searchBy: string = "id";

  @Output() filterChange = new EventEmitter<{value: any[], toggle: boolean, toggleNeeded: boolean}>();
  changes = new Subject<any>();
  comboboxQueryChanged: Subject<string> = new Subject<string>();

  open = false
  andToggle = true;
  value = { value: [], type: "multiAnd" };
  availableList: any[] = [];
  selectedList: any[] = [];
  loading = false;

  constructor(private filterContainer: ClrDatagridFilter,
      private server: ServerService,
      private crm: CrmService,
      public localization: LocalizationService) {
    super();
    this.filterContainer.setFilter(this);
    this.subscriptions.add(this.comboboxQueryChanged.pipe(debounceTime(500))
      .subscribe(async (event) => {  
        this.availableList = [];
        if (event?.length > 2) {
          await this.getAvailableList(event);
        }
      }));
      
      
  }

  isActive(): boolean {
    return this.confirm ? this.value.value && this.value.value.length > 0 : this.filter?.value && this.filter?.value.length > 0
  }

  accepts() {
    return true;
  }

  getDisplayName(item: any) {
    if (this.customFields) {
      return this.crm.getDisplayValue(item);
    }
    if (this.crmCore) {
      return this.localization.dictionary[item].nameSingular;
    }
    if (this.localizationKey) {
      return this.localization.dictionary[this.localizationKey][item];
    }
    if (this.array) {
      return item;
    }
    return item[this.display];
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == "filter") {
          if (!this.filter) {
            this.value.value = undefined;
            this.filter = {value: [], toggle: this.andToggle, toggleNeeded: undefined}
          }
          if (typeof this.filter == 'string') {
            this.filter = JSON.parse(this.filter as any);
          }
          if (Array.isArray(this.filter)) {
            this.filter = {value: this.filter, toggle: this.andToggle, toggleNeeded: undefined};
          }
          if (!this.filter.value || this.filter.value.length == 0) {
            this.value.value = undefined;
          }
          this.filter.toggleNeeded = this.toggle && !this.andToggle;
          this.value.value = this.filter.value;
          this.andToggle = this.filter.toggle;
          this.andOrChange(this.andToggle, false);
          if (this.filter.value.length > 0) {
            this.loadOldFilter();
          }
          
        }
        if ((propName == "array" && this.array) || (propName == "toggle" && !this.toggle)) {
          this.andToggle = false
          this.andOrChange(this.andToggle, false);
        }
      }
    }
  }

  andOrChange(event: boolean, triggerChange = true) {
    if (event) {
      this.value.type = "multiAnd"
      this.property = this.property.replace("." + this.searchBy, "")
    }
    else {
      this.value.type = "multiOr"
      if (!this.property.includes('fieldValues.')) {
        this.property = this.property.replace("." + this.searchBy, "") + "." + this.searchBy
      }
    }
    if (this.filter && typeof this.filter != 'string') {
      this.filter.toggle = event;
      this.filter.toggleNeeded = this.toggle && !this.andToggle;
    }
    if (triggerChange) {
      this.onChanged()
    }
  }

  onChanged() {
    const output = this.selectedList? this.selectedList.map(sel => this.array ? sel : sel[this.searchBy]) : undefined;
    this.filter.value = output;
    if (!this.confirm) {
      this.value.value = this.filter?.value ? [...this.filter.value] : this.filter?.value
      this.filterChange.emit(this.filter)
      this.changes.next('')
    }
  }

  onClick(reset?: boolean) {
    if (reset) {
      this.filter = {value: [], toggle: this.andToggle, toggleNeeded: this.toggle && !this.andToggle}
      this.selectedList = undefined;
    }
    this.value.value = this.filter?.value ? [...this.filter.value] : this.filter?.value
    this.filterChange.emit(this.filter)
    this.changes.next('')
    this.open = false
  }

  async getAvailableList(searchInput?: string) {
    const params = this.selectedList?.length > 0 ? '?excludeIds=' + this.selectedList.map(sel => sel.id).join(',') : '?';
    this.availableList = plainToClass(this.type, await this.server.getUnwrapped<any[]>(this.query + (searchInput ? '/' + searchInput : '') + params + '&limit=10'));
  }

  selectionChanged(ev?) {
    setTimeout(()=>this.open = true);
    this.onChanged();
  }

  async loadOldFilter() {
    const params = '?ids=' + this.filter.value.join(',');
    this.selectedList = plainToClass(this.type, await this.server.getUnwrapped<any[]>(this.query + params));
  }

  ngOnInit(): void {
  }

}
