import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  open = new Subject<string>();
  
  constructor(
        
  ) { }
}
