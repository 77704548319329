import { Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from 'src/app/localization/localization.service';
import { TrackingService } from '../../tracking.service';
import { TrackingData } from '../../trackingData.model';
import { WidgetInterface } from '../widget.interface';

@Component({
  selector: 'app-widget-login',
  templateUrl: './widget-login.component.html',
  styleUrls: ['./widget-login.component.css']
})
export class WidgetLoginComponent implements OnInit, WidgetInterface {
  

  trackingData: TrackingData[] = [];
  total: number = 0;
  loading = false;

  constructor(private tracking: TrackingService,
    public localization: LocalizationService) { }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }

  /**
   * Refreshes Widget
   * @param from 
   * @param to 
   */
  async refresh(from?: Date, to?: Date) {
    this.loading = true;
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
    const res = await this.tracking.getLoginAndSynch('?' + query.toString());
    this.trackingData = plainToClass(TrackingData, res.rows);
    this.total = res.count;
    this.loading = false;
  }

  deenum(device: string) {
    switch (device) {
      case 'IOS':
        return 'iOS';
      case 'WIN':
        return 'Windows';
      case 'ANDROID':
        return 'Android';
      case 'LINUX':
        return 'Linux';
      case 'DEMO':
        return 'Demo';
    }
  }

}
