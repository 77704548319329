import {  Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
@Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
tooltipElement: HTMLElement;
@Input('appTooltip') tooltipText: string = '';
@Input() tooltipTrigger: 'hover';
routerEventSubscription: Subscription;

constructor(private el: ElementRef, private renderer: Renderer2) {
}

@HostListener('mouseenter') onMouseEnter() {
  if (!this.tooltipElement) {
    this.showTooltip();
  }
}

@HostListener('mouseleave') onMouseLeave() {
  if(this.tooltipElement){
  this.hideTooltip();
 }
}

@HostListener('click') onMouseClick() {
  this.hideTooltip();
}

 // Hide the tooltip if click on talk-bubbles 
// @HostListener('document:click', ['$event']) 
// onDocumentClick(event: MouseEvent) {
//   const clickedElement = event.target as HTMLElement;
//   const clickedInsideTooltip = this.el.nativeElement.contains(clickedElement);
//   const clickedOnTalkBubblesIcon = clickedElement.classList.contains('talk-bubbles');

//   if (!clickedInsideTooltip || clickedOnTalkBubblesIcon) {
//     this.hideTooltip();
//     event.stopPropagation();
//   }
// }

// @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
//   const clickedOnTalkBubblesIcon = (event.target as HTMLElement).classList.contains('talk-bubbles');
//   if (clickedOnTalkBubblesIcon) {
//     this.hideTooltip();
//   }
// }

showTooltip() {
this.tooltipElement = this.renderer.createElement('span');
this.tooltipElement.innerHTML = this.tooltipText;
this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
this.renderer.appendChild(document.body, this.tooltipElement);

 
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltipElement.getBoundingClientRect();

    const arrow = this.renderer.createElement('span');
    this.renderer.setStyle(arrow, 'position', 'absolute');
    this.renderer.setStyle(arrow, 'width', '0');
    this.renderer.setStyle(arrow, 'height', '0');

    switch (this.tooltipPosition) {
      case 'top':
        this.renderer.setStyle(this.tooltipElement, 'top', `${hostPos.top - tooltipPos.height - 25}px`);
        this.renderer.setStyle(this.tooltipElement, 'left', `${hostPos.left + (hostPos.width - tooltipPos.width) / 2}px`);
        this.renderer.setStyle(this.tooltipElement, 'transform-origin', 'bottom center');
        //Arroy styling for top
        this.renderer.setStyle(arrow, 'border-left', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-right', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-top', '6px solid #333');
        this.renderer.setStyle(arrow, 'top', '100%');
        this.renderer.setStyle(arrow, 'left', '50%');
        this.renderer.setStyle(arrow, 'transform', 'translateX(-50%)');
        break;

      case 'bottom':
        this.renderer.setStyle(this.tooltipElement, 'top', `${hostPos.bottom + 25}px`);
        this.renderer.setStyle(this.tooltipElement, 'left', `${hostPos.left + (hostPos.width - tooltipPos.width) / 2}px`);
        this.renderer.setStyle(this.tooltipElement, 'transform-origin', 'top center');
        //Arroy styling for bottom
        this.renderer.setStyle(arrow, 'border-left', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-right', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-bottom', '6px solid #333');
        this.renderer.setStyle(arrow, 'bottom', '100%');
        this.renderer.setStyle(arrow, 'left', '50%');
        this.renderer.setStyle(arrow, 'transform', 'translateX(-50%)');
        break;

      case 'left':
        this.renderer.setStyle(this.tooltipElement, 'top', `${hostPos.top + (hostPos.height - tooltipPos.height) / 2}px`);
        this.renderer.setStyle(this.tooltipElement, 'left', `${hostPos.left - tooltipPos.width - 10}px`);
        this.renderer.setStyle(this.tooltipElement, 'transform-origin', 'right center');
        //Arroy styling for left
        this.renderer.setStyle(arrow, 'border-top', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-bottom', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-left', '6px solid #333');
        this.renderer.setStyle(arrow, 'top', '50%');
        this.renderer.setStyle(arrow, 'right', '-6px');
        this.renderer.setStyle(arrow, 'transform', 'translateY(-50%)');
        break;

      case 'right':
        this.renderer.setStyle(this.tooltipElement, 'top', `${hostPos.top + (hostPos.height - tooltipPos.height) / 2}px`);
        this.renderer.setStyle(this.tooltipElement, 'left', `${hostPos.right + 10}px`);
        this.renderer.setStyle(this.tooltipElement, 'transform-origin', 'left center');
        //Arroy styling for right
        this.renderer.setStyle(arrow, 'border-top', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-bottom', '6px solid transparent');
        this.renderer.setStyle(arrow, 'border-right', '6px solid #333');
        this.renderer.setStyle(arrow, 'top', '50%');
        this.renderer.setStyle(arrow, 'left', '-6px');
        this.renderer.setStyle(arrow, 'transform', 'translateY(-50%)');
        break;
    }

    // Appending arrow to tooltip
    this.renderer.appendChild(this.tooltipElement, arrow);

    // Styling for the tooltip
    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'background-color', '#333');
    this.renderer.setStyle(this.tooltipElement, 'color', '#fff');
    this.renderer.setStyle(this.tooltipElement, 'padding', '5px 10px');
    this.renderer.setStyle(this.tooltipElement, 'border-radius', '7px');
    this.renderer.setStyle(this.tooltipElement, 'font-size', '13px');
    this.renderer.setStyle(this.tooltipElement, 'z-index', '1000');
    this.renderer.setStyle(this.tooltipElement, 'box-shadow', '0px 2px 6px rgba(0, 0, 0, 0.2)');
    this.renderer.setStyle(this.tooltipElement, 'transition', 'opacity 0.3s ease');
    // this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
}

hideTooltip() {
if(this.tooltipElement){
  this.renderer.removeChild(document.body, this.tooltipElement);
  this.tooltipElement = null;
}
 }

}
