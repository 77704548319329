<div class="wrapper">
  <div class="topbar">
      <div class="col-md-4" style="display: flex; align-items: center;">
        <div class="btn btn-primary" (click)="addModalOpen = true">{{ localization.dictionary.calendar.addEvent }}</div>
        <clr-icon shape="employee" style="width: 32px; height: 32px; margin: 0 .5rem" (click)="currentUser = auth.user; onUserChange()"></clr-icon>
          <clr-combobox-container style="margin: 0;">
            <!-- <label fxLayout="row" fxLayoutAlign="space-between center">Nutzer* <clr-icon shape="employee"></clr-icon></label> -->
            <clr-combobox #combobox [(ngModel)]="currentUser" (ngModelChange)="onUserChange()">
                <clr-options>
                    <clr-option *clrOptionItems="let user of users; field: 'name'" [clrValue]="user">
                        <span style="margin-left: 0.2rem">{{ user.name }}</span>
                    </clr-option>
                </clr-options>
            </clr-combobox>
            <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
        </clr-combobox-container>

      </div>
      <div style="display: flex; justify-content: center; align-items: center;">
        <clr-icon class="arrow" shape="play down" 
          mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"></clr-icon>
        <h3 style="margin: 0;" mwlCalendarToday [(viewDate)]="viewDate">{{ viewDate | calendarDate:(view + 'ViewTitle'):local.language.selector }}</h3>
        <clr-icon class="arrow" shape="play up" 
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"></clr-icon>
      </div>      
      <div class="col-md-4" style="display: flex; justify-content: flex-end;">
        <div class="btn-group" style="margin: 0;">
          <div
            class="btn btn-outline-secondary"
            [ngClass]="{ 'btn-primary' : view === CalendarView.Month }"
            (click)="setView(CalendarView.Month)"
          >
            {{ localization.dictionary.calendar.month }}
          </div>
          <div
            class="btn btn-outline-secondary"
            [ngClass]="{ 'btn-primary' : view === CalendarView.Week }"
            (click)="setView(CalendarView.Week)"
          >
            {{ localization.dictionary.calendar.week }}
          </div>
          <div
            class="btn btn-outline-secondary"
            [ngClass]="{ 'btn-primary' : view === CalendarView.Day }"
            (click)="setView(CalendarView.Day)"
          >
            {{ localization.dictionary.calendar.day }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div [ngSwitch]="view" class="cal-wrapper">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [weekStartsOn]="1"
        [locale]="language"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent($event.event)"
        (eventTimesChanged)="eventTimesChanged($event)" 
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [weekStartsOn]="1"
        [locale]="language"
        (eventClicked)="handleEvent($event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [locale]="language"
        (eventClicked)="handleEvent($event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div>
  </div>

<!-- Das Modal zur Event erstellung / aktualisierung -->
<clr-modal [(clrModalOpen)]="addModalOpen" [clrModalClosable]="false">
  <h3 class="modal-title">{{ this.crrEvent ? localization.dictionary.calendar.editEvent : localization.dictionary.calendar.newEvent }}</h3>
  <div class="modal-body">
    <form clrForm (ngSubmit)="onCreate(form)" #form="ngForm">

      <clr-input-container>
        <label for="title">{{ localization.dictionary.calendar.eventTitle }}</label>
        <input clrInput [placeholder]="localization.dictionary.calendar.eventTitle" name="title" required [(ngModel)]="addTitle"/>
      </clr-input-container>

      <clr-input-container>
        <label for="description">{{ localization.dictionary.calendar.eventDescription }}</label>
        <textarea clrInput [placeholder]="localization.dictionary.calendar.eventDescription" name="description" [(ngModel)]="addDescription"></textarea>
      </clr-input-container>

      <clr-control-container>
        <label class="clr-control-label" for="allDay">{{ localization.dictionary.calendar.eventAllDay }}</label>
        <input name="allDay" type="checkbox" clrToggle name="allDay" [(ngModel)]="allDay"/>
      </clr-control-container>

      <clr-date-container class="dateInputContainer">
          <label class="clr-control-label" for="addStartDate">{{ localization.dictionary.calendar.eventStart }}</label>
          <input type="date" clrDate [(clrDate)]="addStartDate" required name="addStartDate" [(ngModel)]="addStartDate"
            [attr.format]="localization.dictionary.dateFormat.date"#searchFrom autocomplete="off" [max]="maxDate()"/>
          <input clrInput type="time"/>
      </clr-date-container>

      <input [disabled]="allDay" clrInput type="time" name="addStartTime" [(ngModel)]="addStartTime"/>

      <clr-date-container class="dateInputContainer">
        <label class="clr-control-label" for="addEndDate">{{ localization.dictionary.calendar.eventEnd }}</label>
        <input type="date" clrDate [(clrDate)]="addEndDate" name="addEndDate" [(ngModel)]="addEndDate"
          [attr.format]="localization.dictionary.dateFormat.date" #searchFrom autocomplete="off" [min]="minDate()"/>
        <input clrInput type="time" />
      </clr-date-container>

      <input [disabled]="allDay" clrInput type="time" name="addEndTime" [(ngModel)]="addEndTime"/>

      <!-- 
      Schöner aber Kaputt weil CLR scheiße
      <clr-input-container>
        <label for="date">Ende</label>
        <input clrInput type="date" name="date" [(ngModel)]="addEndDate" />
        <input clrInput type="time" name="end" [(ngModel)]="addEndTime" />
      </clr-input-container> -->

      <div class="modal-footer">
        <button *ngIf="crrEvent" type="button" class="btn btn-danger" (click)="deleteEvent()">{{ localization.dictionary.button.delete }}</button>
        <button type="button" class="btn btn-outline" (click)="addModalOpen = false; form.reset(); crrEvent = null">{{ localization.dictionary.button.cancel }}</button>
        <button type="button" class="btn btn-primary" type="submit" [disabled]="!form.valid">{{ localization.dictionary.button.save }}</button>
      </div>
    </form>
  </div>

</clr-modal>

