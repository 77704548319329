<div class="card widget-card" style="margin-top: 0 !important;" fxLayout="column">
    <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{localization.dictionary.crmDashboard.callByUserDashboard}}</span>
    </div>
    <div class="card-block widget" fxFill>
      <div class="card-text widget" fxLayout="column">
        <ng-container *ngIf="!loading; else spinner">
          <ngx-charts-pie-chart            
            [results]="data"
            [legend]="false"
            [legend]="false"
            [labels]="false"
            [doughnut]="false"
            [legendTitle]="''"
            #chart
            class="widget-text"            
            >
          </ngx-charts-pie-chart>
        </ng-container>
        <ng-template #noData>
          <app-no-data></app-no-data>
        </ng-template>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>

      </div>
    </div>
    <div class="card-footer">
      <app-footer-widgets [chart]="chart" [widgetTitle]="localization.dictionary.crmDashboard.callByUserDashboard" [data]="data"  widgetName="callByUser"></app-footer-widgets>
    </div>
</div>
