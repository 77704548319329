import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-call-edit',
  templateUrl: './de-call-edit.component.html',
})
export class DeCallEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
