import { Exclude, Expose, Type } from 'class-transformer';
import { Language } from 'src/app/localization/language.model';
import { LanguageCategory } from './languageCategory.model';

@Exclude()
export class Dictionary {
    @Expose()
    id: string;

    @Expose()
    element: string;

    @Expose()
    label: string;

    @Expose()
    selector: string;

    @Expose()
    language: Language[] = [];

    @Expose()
    languageId: string;

    @Expose()
    @Type( () => LanguageCategory)
    languageCategory: LanguageCategory;

    @Expose()
    languageCategoryId: string;

    @Expose()
    tag: string;

}