import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { Document } from '../../documents/document.model';
import { Form } from '../form.model';

@Component({
  selector: 'app-list-forms',
  templateUrl: './list-forms.component.html',
  styleUrls: ['./list-forms.component.css']
})
export class ListFormsComponent implements OnInit {
  forms: Form[];
  total = 0;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  };
  loading = false;
  saving = false;
  selected: Form[] = [];
  toDelete: Form[];
  setInactive: Form[];
  state: ClrDatagridStateInterface;
  selectedForm: Form;

  orphans: Document[] = []

  showDeleted = false;
  restoreModal = false;

  columnChange = false;
  columnChanged = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  @HostListener('window:beforeunload', ['$event'])
  async onPageUnload($event: BeforeUnloadEvent) {
    this.ngOnDestroy();
  }


  constructor(private server: ServerService, public auth: AuthService,
    public localization: LocalizationService) {
    const filter = localStorage.getItem('form-filters');
    const sorting = localStorage.getItem('form-sorting');
    const hidden = localStorage.getItem('form-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
  }

  async ngOnInit(): Promise<void> {
    this.showDeleted = !!localStorage.getItem('form-deleted');
    /* const res = await this.server.get('form' + (this.showDeleted? '/all' : '')) as ServerResponse;
    this.forms = plainToClass(Form, res.rows);
    //this.forms.sort((a, b) => Number(b.isActive) - Number(a.isActive));
    this.total = res.count; */
  }

  ngOnDestroy() {
    localStorage.setItem('form-filters', JSON.stringify(this.filters));
    localStorage.setItem('form-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('form-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('form-deleted', 'true')
    else
      localStorage.removeItem('form-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const res = await this.server.get('form' + (this.showDeleted ? '/all' : '') + query);
    this.total = res.count;
    this.forms = plainToClass(Form, res.rows);
    //this.forms.sort((a, b) => Number(b.isActive) - Number(a.isActive));
    this.loading = false;
  }

  resetFilters() {
    //this.filters = { isActive: 'true' };
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    if (this.state.filters) {
      delete this.state.filters;
      //this.state.filters = this.state.filters.filter(filter => filter.property == 'isActive' && filter.value.value == 'true')
    } else {
      //this.state.filters = [{property: 'isActive', value: {value: 'true', type: 'boolean'}}];
    }
    this.refresh(this.state)
  }

  checkNoIsActiveFilter() {
    if (!this.state) {
      return false;
    }
    if (this.state.sort) {
      return true;
    }
    return !this.state.filters || this.state.filters.some(filter => filter.property != 'isActive' || !filter || filter.value.value != 'true');
  }

  async selectDelete(forms: Form[]) {
    forms.forEach(form => this.orphans.push(...form.documents.filter(doc => !this.orphans.find(orph => orph.id == doc.id))))
    this.toDelete = forms;
  }

  selectInactive(forms: Form[]) {
    forms.forEach(form => this.orphans.push(...form.documents.filter(doc => !this.orphans.find(orph => orph.id == doc.id))));
    this.setInactive = forms;
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }

  async openRestoreModal(form: Form) {
    this.selectedForm = form;
    this.restoreModal = true
  }

  async restore() {
    await this.server.put('form/restore', this.selectedForm);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successRestored.replace("${componentName}", this.localization.dictionary.form.name).replace("${entryName}", this.selectedForm.name)));
    this.refresh();
    this.restoreModal = false;
  }

  async delete() {
    this.loading = true;
    this.saving = true;
    const formIds = this.toDelete.map((form) => form.id);
    try {
      await this.server.delete('form?toDelete=' + formIds);
      this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
        this.toDelete.length == 1 ? this.localization.dictionary.form.nameSingular : this.localization.dictionary.form.name)));
    } catch (err) {
      this.loading = false;
      throw new HttpErrorResponse(err);
    } finally {
      this.toDelete = undefined;
      this.selected = [];

    }
    this.saving = false;
    this.refresh();
  }

  async inactivate() {
    await this.server.put('form/setInactive/' + true + '', this.setInactive[0]);
    this.setInactive = undefined;
    this.selected = [];
    this.refresh();
  }

  async reactivate(form: Form) {
    console.log(form);
    await this.server.put('form/reactivate/' +  form.id, form);
    this.selected = [];
    this.refresh();
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  translateType(type: string) {
    return this.localization.dictionary.formType[type]
  }

}
