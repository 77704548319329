<div class="card" *ngIf="!loading">
    <div class="card-header">
        {{localization.dictionary.userCreateEdit.profileOf.replace('${userName}', auth.user.name)}}
    </div>
    <div class="card-block">
      <div class="card-text">
        <form clrForm>
            <clr-input-container>
                <label>{{localization.dictionary.user.username}}</label>
                <input clrInput type="text" [(ngModel)]="auth.user.name" name="name" disabled maxlength="100" />
            </clr-input-container>
            <clr-input-container>
                <label>{{localization.dictionary.user.email}}</label>
                <input clrInput type="text" [(ngModel)]="auth.user.email" name="email" disabled maxlength="100" />
            </clr-input-container>
            <clr-input-container>
                <label>{{localization.dictionary.user.formOfAddress}}</label>
                <input clrInput type="text" [(ngModel)]="auth.user.formOfAddress" name="formOfAddress" disabled maxlength="100" />
            </clr-input-container>
            <clr-input-container>
                <label>{{localization.dictionary.user.firstName}}</label>
                <input clrInput type="text" [(ngModel)]="auth.user.firstName" name="firstName" disabled maxlength="100" />
            </clr-input-container>
            <clr-input-container>
                <label>{{localization.dictionary.user.lastName}}</label>
                <input clrInput type="text" [(ngModel)]="auth.user.lastName" name="lastName" disabled maxlength="100" />
            </clr-input-container>
            <clr-input-container>
                <label>{{localization.dictionary.userCreateEdit.newPassword}}</label>
                <input clrInput type="password" [(ngModel)]="auth.user.password" name="password" maxlength="100"/>
            </clr-input-container>
            <clr-input-container *ngIf="auth.user.password">
                <label>{{localization.dictionary.userCreateEdit.confirmNewPassword}}</label>
                <input clrInput type="password" [(ngModel)]="newPassword" name="newpassword" required maxlength="100"/>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-input-container>
            <clr-input-container *ngIf="auth.user.password">
                <label>{{localization.dictionary.userCreateEdit.oldPassword}}</label>
                <input clrInput type="password" [(ngModel)]="oldPassword" name="oldPassword" required maxlength="100"/>
                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-input-container>
            <div class="clr-form-control clr-form-control-disabled" *ngIf="auth.user.groups.length > 0">
                <label class="clr-control-label custom-label" [style.paddingLeft]="0">{{localization.dictionary.group.name}}</label>
                <div class="clr-control-container clr-col-md-10 clr-col-12" [style.paddingLeft]="'0.2rem'" ng-reflect-ng-class="clr-col-md-10 clr-col-12">
                    <div class="clr-input-wrapper" fxLayout="row" fxLayoutGap=".5rem">
                        <span class="label label-light-blue" *ngFor="let group of auth.user.groups">
                            {{ group.name }}
                        </span>
                        
                    </div>
                </div>
                
            </div>
        </form>
      </div>
    </div>
    <div stickyFooter class="card-footer">
      <button class="btn btn-primary" (click)="save()" [disabled]="!this.isValid()">{{localization.dictionary.button.save}}</button>
      <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
    </div>
  </div>

<app-spinner *ngIf="loading"></app-spinner>