import { Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from '../localization/localization.service';
import { ServerService } from '../server.service';
import { Role } from './role.model';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  roles: Role[] = [];
  loading = false;

  constructor(private server: ServerService, public localization: LocalizationService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const res = await this.server.get('roles');
    this.roles = plainToClass(Role, res.rows).sort((a, b) => {
      if(/user/.test(a.name) && /user/.test(b.name)) {
        return b.name > a.name ? 1 : -1
      }
      return a.name.includes('-editor') ? 1 : (b.name.includes('editor') ? -1 : 0)
    });
    this.loading = false;
  }

}
