import { Component, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridPagination, ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { LocalizationService } from '../localization/localization.service';
import { ExcelService } from '../excel.service';
import { ServerService } from '../server.service';
import { DefaultEvent } from './default-event.model';
import { EventType } from './event-type.type';

@Component({
  selector: 'app-eventlog',
  templateUrl: './eventlog.component.html',
  styleUrls: ['./eventlog.component.css']
})
export class EventlogComponent implements OnInit {
  loading = true;
  filters = {};
  sorting = {};
  events: DefaultEvent[];
  total = 0;
  state: ClrDatagridStateInterface;
  exportState: ClrDatagridStateInterface;
  pagination: ClrDatagridPagination;
  descSort: ClrDatagridSortOrder;

  exportModal = false;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;
  

  constructor(private server: ServerService, public localization: LocalizationService, private excelService: ExcelService) { }

  ngOnInit(): void {
    this.descSort = ClrDatagridSortOrder.DESC;
  }

  async refresh(state: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state)
    const res = await this.server.get('event-system' + query);
    this.events = plainToClass(DefaultEvent, res.rows);
    this.total = res.count;
    this.loading = false;
    this.datagrid.resize();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters
    this.refresh(this.state)
  }

  getClass(type: EventType) {
    switch (type) {
      case EventType.ERROR: 
        return 'error';
      case EventType.WARNING:
        return 'warning';
      default: 
        return '';
    }
  }

  async checkExportType(state?: ClrDatagridStateInterface){
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.exportState = state;
    this.exportState.page.current = 1;
    this.exportState.page.from = 0;
    this.exportState.page.to = 999;
    this.exportState.page.size = 1000;
    if(this.exportState.filters && this.total <= 1000){
      await this.downloadExcel("?skip=0&limit=1000");
    } else {
      this.exportModal = true;
    }
  }

  async downloadExcel(query?: string){
    if(!query){
      query = this.server.buildQueryFromGrid(this.exportState);
    }
    let buffer = await this.server.download('event-system/excel/' + this.localization.language.id + query);
    this.excelService.downloadExcel(buffer, 'excel');
    this.exportModal = false;
  }
}
