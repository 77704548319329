import { Component, Input, OnInit } from '@angular/core';
import { WidgetInterface } from '../widget.interface';

@Component({
  selector: 'app-widget-calls',
  templateUrl: './widget-calls.component.html',
  styleUrls: ['./widget-calls.component.css']
})
export class WidgetCallsComponent implements OnInit, WidgetInterface {
  @Input() filtered: boolean;
  @Input() value = 0;
  @Input() text: string;
  @Input() endpoint: string;

  loading = false;

  constructor() { }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }

  refresh(from?: Date, to?: Date) {
    this.loading = true;
    let query = new URLSearchParams();
    if (from) query.append('from', from.toISOString());
    if (to) query.append('to', to.toISOString());
  }
}
