import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
//import { runCssVarsPolyfill } from '@clr/core';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {

  clrLinkRef: HTMLLinkElement;
  primeLinkRef: HTMLLinkElement;
  styleRef: HTMLStyleElement;
  darkMode = true;
  themChanged = new Subject<void>()
  themes = [
    { name: 'Light', query: 'clr-light', primeQuery: 'prime-light', href: undefined, primeHref: undefined },
    { name: 'Dark', query: 'clr-dark', primeQuery: 'prime-dark', href: undefined, primeHref: undefined }
  ];
  styles = [
    `:root{
      --inactive: rgb(211, 211, 211);
        --griddrag-placeholder: #e8e8e8;
        --griddrag-preview: #e8e8e8;
        --collapse-arrow: rgb(102, 102, 102);
        --section-field: #e8e8e8;
        --section-field-color: #666666;
        --section-field-color-hover: #000000;  
        --section-tab: #79c6e6; 
        --scrollbar-track-webkit-bs: inset 0 0 6px #ffffff;
        --scrollbar-track-box-shadow: inset 0 0 6px #ffffff;
        --scrollbar: #c8c8c8 ; 
        --scrollbar-thumb: #c8c8c8; 
        --scrollbar-track-background: #ffffff;
        --clr-card-bg-color: #ffffff;
        --clr-card-border-color: #dedede;
        --clr-option-disabled-bg-color: #ebebeb;
        --clr-option-disabled-color: #cccccc;
        --clr-thead-bgcolor: #fafafa;
        --clr-global-app-background: #fafafa;
        --clr-close-color--hover: black;
        --clr-close-color--normal: #8c8c8c;
        --clr-vertical-nav-bg-color: #E8E8E8;
        --clr-vertical-nav-accent-color: #C8C8C8;
        ----clr-btn-primary-bg-color: #0072a3
        --danger: #E02200;
        --warning: #FFB92E;
        --calendar-tooltip: #00364D;
        --calendar-hover: #CCCCCC;
        --calendar-active: #DADADA;
        --calendar-today: #ddd7ff;
        --calendar-border: #e0e0e0;
        --calendar-dotted: #e0e0e0;
        --calendar-out-opacity: 0.1;
        --calendar-event-title: rgb(102, 102, 102);
      }`,
    `:root{
      --inactive: rgb(75, 75, 75);
        --griddrag-placeholder: #1b2a32;
        --griddrag-preview: #21333b;
        --collapse-arrow: rgb(172, 186, 195);
        --section-field-color: #eaedf0;
        --section-field-color-hover: #ffffff;
        --section-field: #324f62;
        --section-tab: #324f62; 
        --scrollbar-track-webkit-bs: inset 0 0 6px rgba(0, 0, 0, 0.3);
        --scrollbar-track-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        --scrollbar-track-background: #1b2a32; 
        --scrollbar: #1b2a32; 
        --scrollbar-thumb: #0e161b;
        --clr-card-bg-color: #21333b;
        --clr-card-border-color: #0e161b;
        --clr-option-disabled-bg-color: #ebebeb;
        --clr-option-disabled-color: #cccccc;
        --clr-thead-bgcolor: #1b2a32;
        --clr-global-app-background: #1b2a32;
        --clr-vertical-nav-bg-color: #17242B;
        --clr-vertical-nav-accent-color: #232E34;
        --danger: #FF674C;
        --warning: #FFCF66;
        --clr-close-color--normal: #8c8c8c;
        --clr-close-color--hover: white;
        --clr-btn-primary-bg-color: #4aaed9
        --calendar-tooltip: #0E161B;
        --calendar-hover: #28404D;
        --calendar-active: rgb(50, 79, 98);
        --calendar-today: #202846;
        --calendar-border: #585858;
        --calendar-dotted: #3d3d3d;
        --calendar-out-opacity: 0.4;
        --calendar-event-title: rgb(172, 186, 195);
      }`
  ]

    constructor() { }

    async applyTheme() {
        let theme = this.themes[1];
        try {
            const stored = localStorage.getItem('theme');
            if (stored) {
                theme = JSON.parse(stored);
            }
        } catch (e) {
            // Nothing to do
        }
        for (let themeInit of this.themes) {
            themeInit.href = document.querySelector<HTMLLinkElement>('head > link[rel="stylesheet"][href^=' + themeInit.query + ']').href.replace(/.*\//, '');
            themeInit.primeHref = document.querySelector<HTMLLinkElement>('head > link[rel="stylesheet"][href^=' + themeInit.primeQuery + ']').href.replace(/.*\//, '');
        }


        this.darkMode = theme.name == 'Dark'
        this.clrLinkRef = document.createElement('link');
        this.clrLinkRef.rel = 'stylesheet';
        this.clrLinkRef.href = theme.href;
        this.primeLinkRef = document.createElement('link');
        this.primeLinkRef.rel = 'stylesheet';
        this.primeLinkRef.href = theme.primeHref;
        this.styleRef = document.createElement('style');
        this.styleRef.innerHTML = this.styles[this.darkMode ? 1 : 0]
        document.querySelector('head').appendChild(this.clrLinkRef);
        document.querySelector('head').appendChild(this.primeLinkRef);
        document.querySelector('head').appendChild(this.styleRef);
        //runCssVarsPolyfill()
        localStorage.setItem('theme', JSON.stringify(theme));
        await new Promise((resolve) => setTimeout(resolve, 200))
    }

    toggleTheme() {
        const storedTheme = localStorage.getItem('theme');
        const activeTheme = storedTheme && JSON.parse(storedTheme).name == 'Dark' ? 0 : 1;
        localStorage.setItem('theme', JSON.stringify(this.themes[activeTheme]));
        this.clrLinkRef.href = this.themes[activeTheme].href;
        this.primeLinkRef.href = this.themes[activeTheme].primeHref;
        this.styleRef.innerHTML = this.styles[activeTheme]
        this.darkMode = !this.darkMode;
        this.themChanged.next();
    }
}