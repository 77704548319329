<ol class="list">
<h4 style="position: relative; right: 1.1em;">Basis Informationen ändern</h4>
    <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
    <li>Um das Icon zu ändern, löschen Sie die aktuelle Auswahl im Eingabefeld. Anschließend können Sie ein neues Icon aus der Dropdownliste auswählen.</li>
    <li>Um die Kategorie auf der Startseite der App anzeigen zu lassen setzen Sie einen Haken bei „Aktiv“ und „Im Hauptmenü“. Zum Ausblenden der Kategorie entfernen Sie den Haken bei „Aktiv“. </li>
<h4 style="position: relative; right: 1.1em;">Gruppen zuordnen</h4>
    <li>Zum Schluss ordnen Sie der Kategorie noch eine oder mehrere Benutzergruppen zu, um die Kategorie für diese Gruppen in der App sichtbar zu machen. </li>
<h4 style="position: relative; right: 1.1em;">Aktionen</h4>
    <li>Nach Eingabe der Änderungen klicken Sie auf den Button „Speichern“.</li>
</ol>

<p><span style="font-weight: bold; font-size: larger; color: var(--danger);">Bitte beachten:</span>  Die Kategorie wird erst dann in der App sichtbar, wenn ihr auch mindestens ein Dokument zugeordnet wurde. </p>