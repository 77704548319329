<ol class="list">
    <h4 style="position: relative; right: 1.1em;">Basis Informationen</h4>
        <li>Alle Pflichtfelder sind mit einem * gekennzeichnet. </li>
        <li>Tragen Sie bei Namen die Sprache ein, z.B. Französisch und vergeben Sie beim Feld „Selektor“ ein Kürzel, z.B. fr.</li>
        <li>Um eine Sprache für alle verfügbar zu machen, setzen Sie den Haken bei „Aktiv“.</li>
        <li>Zum Abspeichern der neuen Sprache klicken Sie auf den Button „Erstellen“.</li>
</ol>
<ul class="list">
    <h4 style="position: relative; right: 1.1em;">Übersetzungen eingeben</h4>
    <p>Die Übersetzung können Sie auf 2 verschiedene Arten eingeben: </p>
        <li>Sie können den Excel Import nutzen. Hierfür starten Sie den Excel Export, indem Sie auf den entsprechenden Button klicken. Sie erhalten eine Liste der verwendeten Wörter, die Sie mit den Übersetzungen in der gewünschten Sprache ergänzen. Anschließend speichern Sie die Excel-Datei ab. Für den Import der Daten klicken Sie auf den Button „Excel Import“ und laden die vorbereitete Tabelle hoch, indem Sie die Datei auswählen und auf den Button „Öffnen“ klicken. Der automatische Upload startet.</li>
        <li>Sie können die Übersetzung aber auch direkt in der Eingabemaske eingeben. Hierfür wählen Sie den zu übersetzenden Begriff aus und klicken in der entsprechenden Zeile in der Spalte „Neue Sprache“. Es öffnet sich ein Eingabefeld. Hier geben Sie nun die Übersetzung ein.  </li>
    </ul>
