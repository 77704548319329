import { Component, DoCheck, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClrForm } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { Category } from 'src/app/categories/category.model';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { ErrorService } from 'src/app/helper/error/error.service';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { HelperService } from 'src/app/helper/helper.service';
import { ListSelectorComponent } from 'src/app/helper/list-selector/list-selector.component';
import { ServerService } from 'src/app/server.service';
import { User } from 'src/app/users/user.model';
import { LocalizationService } from '../../../localization/localization.service';
import { PushNotification, PushUpdateType } from '../push-notification.model';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-create-edit-push-notification',
  templateUrl: './create-edit-push-notification.component.html',
  styleUrls: ['./create-edit-push-notification.component.css']
})
export class CreateEditPushNotificationComponent extends PreventUnload implements OnInit, DoCheck {
  private forms: ClrForm[];
  @ViewChildren(ClrForm) set contentForms(content: QueryList<ClrForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined      
      if (!this.forms || this.forms.length == 0)
        this.forms = content.toArray();
      else
        this.forms.push(...content.toArray());
    } else {
      if (!this.forms || this.forms.length == 0)
        this.forms = [];
    }
  }

  private formDatas: NgForm[];

  @ViewChildren('formData') set content(content: QueryList<NgForm>) {
    if (content && content.length > 0) { // initially setter gets called with undefined
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = content.toArray();
      else
        this.formDatas.push(...content.toArray());
    } else {
      if (!this.formDatas || this.formDatas.length == 0)
        this.formDatas = [];
    }
  }

  push: PushNotification;
  pushUpdateTypes = PushUpdateType;

  users: User[] = [];
  categories: Category[] = [];

  invalid = false;

  mode: 'edit' | 'create';
  sendModal = false;
  loading = true;
  req = false;
  collapse: boolean[] = [
    false,
    false
  ]

  @ViewChild('userList') userList: ListSelectorComponent;

  constructor(public server: ServerService,
    private route: ActivatedRoute,
    public helper: HelperService,
    private errorService: ErrorService,
    public localization: LocalizationService
  ) {
    super(localization);

    this.route.params.subscribe(async (params) => {
      this.mode = (!params.id) ? 'create' : 'edit';
      if (this.mode === 'edit') {
        const res = await this.server.get('notification/push/byId/' + params.id);
        this.push = plainToClass(PushNotification, res);
      } else {
        this.push = new PushNotification();
        this.dirty = true;
      }
      this.changeUnload();
      this.loading = false;
    });
    this.subscriptions.add(
      this.localization.languageChanged.subscribe(() => {
        this.changeUnload();
      })
    );
  }

  async ngOnInit(): Promise<void> {
    const res = await this.server.get('user');
    this.users = plainToClass(User, res.rows);
    const resCats = await this.server.get('category');
    this.categories = plainToClass(Category, resCats.rows);
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('push');
    } else {
      this.changeUnloadEdit('push', this.push.title);
    }
  }

  async send() {
    try {
      this.sendModal = false;
      const pushState = await this.server.put('notification/push/send/' + this.push.id, {});
      if (pushState.successCount > 0 && pushState.failureCount == 0) {
        this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.pushToastr.sendSucessAlert));
      } else if (pushState.successCount > 0 && pushState.failureCount > 0) {
        this.server.addAlert(new AmselError(undefined, 'info', this.localization.dictionary.pushToastr.sendWarningAlert.replace('${count}', pushState.failureCount)));
      } else {
        this.server.addAlert(new AmselError(undefined, 'warning', this.localization.dictionary.pushToastr.sendFailureAlert));
      }           
      this.server.back();
    } catch (err) {
      this.errorService.handleError(err);
    }

  }

  async save(stay?: boolean) {
    this.loading = true;
    this.saving = true;
    try {
      if (this.mode === 'create') {
        await this.server.post('notification/push', this.push);
      } else {
        await this.server.put('notification/push', this.push);
      }
      this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successSaved
          .replace('${componentName}', this.localization.dictionary.push.nameSingular)
          .replace('${entryName}', this.push.title)
        // 'Push-Nachricht erfolgreich gespeichert!'
      ));
    } catch (err) {
      this.errorService.handleError(err);
    } finally {
      this.dirty = false;
      this.saving = false;
      
    }
    if (!stay) {
      this.server.back();
    }
    this.loading = false;
  }

  ngDoCheck() {
    if (this.invalid && this.forms && this.formDatas) {
      for (let formData of this.formDatas) {
        formData.form.markAsDirty();
        formData.form.markAllAsTouched();

        if (formData.invalid) {
          this.collapse[formData.name] = false;
        }
      }
      for (let form of this.forms) {
        form.markAsTouched();
      }
    }
    this.invalid = false;
  }

  addCustomForms(forms) {
    if (!this.forms)
      this.forms = [];
    this.forms.push(...forms);
  }

  addCustomFormDatas(formDatas) {
    if (!this.formDatas)
      this.formDatas = [];
    this.formDatas.push(...formDatas);
  }

  async validateAndSave(stay?: boolean) {
    if (this.isValid()) {
      await this.save(stay);
    }
    else {
      this.invalid = true;
      this.collapse[0] = false;
    }
  }

  async validateAndSend() {
    if (this.isValid()) {
      this.sendModal = true;
    }
    else {
      this.invalid = true;
    }
  }

  isValid(): boolean {
    if (this.push && this.push.title && this.push.msg && (this.push.users.length > 0 || this.push.groups.length > 0)) {
      return true;
    } else {
      return false;
    }
  }
}
