<div class="form-wrapper" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem" fxFlex="100"
    fxFlex.gt-lg="100">
    <div class="card" [hidden]="loading">
        <div class="card-header" (click)="activeQuestion = undefined" fxLayout="row"
            fxLayoutAlign="space-between center">
            <span>{{ helper.getCardHeader(mode, 'form', form.name) }}</span>
        </div>
        <div class="card-block" (click)="activeQuestion = undefined">
            <div class="card-title">
                <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.formCreateEdit.infoTextAboutFreeForms}}
                </p>
            </div>
            <div class="card-text">
                <form clrForm #formData="ngForm" (change)="dirty = true" class="icon-offset">
                    <clr-input-container>
                        <label>{{localization.dictionary.general.name}}*</label>
                        <input type="text" [(ngModel)]="form.name" name="name" clrInput required fileNameValidator
                            maxlength="100">
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error
                            *clrIfError="'notUnique'">{{localization.dictionary.formCreateEdit.nameAlreadyExists}}</clr-control-error>
                        <clr-control-error
                            *clrIfError="'forbiddenFileName'">{{localization.dictionary.documentCreateEdit.invalidName}}</clr-control-error>
                    </clr-input-container>
                    <clr-textarea-container>
                        <label>{{localization.dictionary.general.description}}*</label>
                        <textarea clrTextarea [(ngModel)]="form.description" name="description" required
                            maxlength="250"></textarea>
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-textarea-container>
                    <clr-select-container>
                        <label>{{localization.dictionary.type.nameSingular}}</label>
                        <select [disabled]="form.published || !settings.featureActiveList['crmActive']" clrSelect
                            name="options" [(ngModel)]="form.type" required>
                            <option value="open">{{localization.dictionary.formType.open}}</option>
                            <option value="customer" *ngIf="settings.featureActiveList['crmActive']">
                                {{localization.dictionary.formType.customer}}</option>
                            <option value="optin" disabled *ngIf="false">{{localization.dictionary.formType.optin}}
                            </option>
                        </select>
                        <clr-control-error
                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-select-container>
                    <clr-checkbox-container>
                        <label>{{localization.dictionary.form.published}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox name="published" disabled [value]="true"
                                [(ngModel)]="form.published" />
                            <label>{{ helper.formatDate(form.published) }}</label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <clr-checkbox-container>
                        <label>{{localization.dictionary.general.active}}</label>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox disabled name="active" [value]="true"
                                [(ngModel)]="form.isActive" />
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </form>
            </div>
        </div>

        <div class="card-block card-cutout">
            <div class="drag-boundary" cdkDropList (cdkDropListDropped)="drop($event)" fxLayout="column"
                fxLayoutAlign="start center" fxLayoutGap="0.6rem">
                <ng-container *ngIf="form.questions.length === 0" [ngTemplateOutlet]="addbutton"></ng-container>
                <ng-container *ngFor="let question of form.questions; let i = index">
                    <app-widget-question [attr.id]="'question' + i" [question]="question" [index]="i"
                        [active]="(i === activeQuestion)" [sectionTotal]="getSectionCount()"
                        [sectionIndex]="sectionIndizes[i]" [published]="form.published" [formType]="form.type"
                        style="width: 100%;" (click)="activate(i)" (deactivate)="deactivate()"
                        (changed)="formChanged($event)" (deleteQuestion)="deleteQuestion(i)"
                        [ngClass]="(i === activeQuestion) ? '' : 'cursor-pointer'" #field>
                    </app-widget-question>
                    <ng-container [ngTemplateOutlet]="addbutton" [ngTemplateOutletContext]="{i: i}"></ng-container>
                </ng-container>
            </div>

            <ng-template #addbutton let-i="i">
                <clr-dropdown>
                    <clr-icon shape="plus-circle" class="plus-circle" clrDropdownTrigger></clr-icon>
                    <clr-dropdown-menu clrPosition="right-bottom" *clrIfOpen>
                        <label
                            class="dropdown-header">{{localization.dictionary.button.addComponent.replace('${componentName}',
                            localization.dictionary.field.nameSingular)}}</label>
                        <!--
                        <div *ngFor="let type of questionType | keyvalue" (click)="addField(i + 1, type.key)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[type.key]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[type.key]}}
                        </div>
                        -->
                        <div *ngIf="form.type === 'customer'" (click)="addField(i + 1, questionType.REFERENCE)"
                            clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.REFERENCE]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.REFERENCE] }}
                        </div>

                        <div (click)="addField(i + 1, questionType.SECTION)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.SECTION]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.SECTION] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.CHOICE)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.CHOICE]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.CHOICE] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.CHECKBOX)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.CHECKBOX]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.CHECKBOX] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.MULTIPLE_CHOICE)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.MULTIPLE_CHOICE]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.MULTIPLE_CHOICE] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.SELECTION)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.SELECTION]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.SELECTION] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.TEXT)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.TEXT]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.TEXT] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.TEXTAREA)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.TEXTAREA]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.TEXTAREA] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.NUMBER)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.NUMBER]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.NUMBER] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.BOOLEAN)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.BOOLEAN]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.BOOLEAN] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.RANGE)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.RANGE]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.RANGE] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.SIGNATURE)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.SIGNATURE]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.SIGNATURE] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.DATE)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.DATE]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.DATE] }}
                        </div>
                        <div (click)="addField(i + 1, questionType.DESCRIPTION)" clrDropdownItem>
                            <clr-icon [attr.shape]="mapIcons[questionType.DESCRIPTION]"></clr-icon>
                            {{ localization.dictionary.formQuestionType[questionType.DESCRIPTION] }}
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </ng-template>
        </div>

        <div stickyFooter class="card-footer" (click)="activeQuestion = undefined">
            <button class="btn btn-outline" *ngIf="form.published"
                (click)="newAsTemplate()">{{localization.dictionary.formCreateEdit.newAsTemplate}}</button>
            <button class="btn btn-outline" *ngIf="!form.published" (click)="validateAndSave()">{{ (mode
                === 'create') ? localization.dictionary.button.create : localization.dictionary.button.save }}</button>
            <button *ngIf="!form.published; else test" class="btn btn-primary" (click)="modalPublish = true"
                [disabled]="!isFormValid || form.published || mode === 'create' || dirty || form.questions.length === 0">{{localization.dictionary.formCreateEdit.publish}}</button>
            <ng-template #test>
                <button class="btn btn-primary" (click)="isValid() && modalUpdate = true"
                    [disabled]="!dirty">{{localization.dictionary.formCreateEdit.update}}</button>
            </ng-template>
            <button class="btn btn-primary" *ngIf="form.published"
                [routerLink]="['/forms/results/' + form.id]">{{localization.dictionary.formResult.name}}</button>
            <button class="btn btn-outline" (click)="location.back()">{{localization.dictionary.button.cancel}}</button>
        </div>
    </div>






</div>

<clr-modal [(clrModalOpen)]="modalPublish">
    <h3 class="modal-title">{{localization.dictionary.formCreateEdit.reallyPublish}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.formCreateEdit.warningCannotEditForms}}</p>
        <div class="clr-form-control">
            <div class="clr-control-container">
                <div class="clr-toggle-wrapper"
                    [pTooltip]="categoryCount < 1 ? localization.dictionary.formCreateEdit.warningNoCategories : null"
                    [tooltipPosition]="'bottom'">
                    <input type="checkbox" id="withDocumentCreation" name="toggle-basic" value="withDocumentCreation"
                        class="clr-toggle" [(ngModel)]="withDocumentCreation" [disabled]="categoryCount < 1"/>
                    <label for="withDocumentCreation">{{localization.dictionary.formCreateEdit.checkboxCreateDocument}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline"
            (click)="modalPublish = false">{{localization.dictionary.button.cancel}}</button>
        <button *ngIf="!withDocumentCreation" type="button" class="btn btn-primary"
        [disabled]="!isFormValid" (click)="publishForm()">{{localization.dictionary.formCreateEdit.publish}}</button>
        <button *ngIf="withDocumentCreation" type="button" class="btn btn-primary"
        [disabled]="!isFormValid" (click)="publishFormWithDocument()">{{localization.dictionary.formCreateEdit.publish}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="modalUpdate">
    <h3 class="modal-title">{{localization.dictionary.formCreateEdit.reallyUpdate}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.formCreateEdit.warningUpdateImmediate}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline"
            (click)="modalUpdate = false">{{localization.dictionary.button.cancel}}</button>
        <button type="button" class="btn btn-primary"
            (click)="validateAndSave()">{{localization.dictionary.formCreateEdit.update}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="modalDocumentCategory">
    <h3 class="modal-title">{{localization.dictionary.links.categoryDocumentShort}}</h3>
    <div class="modal-body">
        <form clrForm>
            <clr-select-container>
                <label>{{localization.dictionary.links.selectCategory}}</label>
                <select clrSelect name="documentCategory" [(ngModel)]="documentCategory" required>
                    <option *ngFor="let category of documentCategories" [value]="category.id">{{category.name}}</option>
                </select>
                <clr-control-error
                    *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
            </clr-select-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline"
            (click)="modalDocumentCategory = false">{{localization.dictionary.button.cancel}}</button>
        <button type="button" class="btn btn-primary" [disabled]="!documentCategory"
            (click)="confirmPublishWithDocument()">{{localization.dictionary.formCreateEdit.publishDocument}}</button>
    </div>
</clr-modal>