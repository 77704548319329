<p>Im Menüpunkt Felder werden die Felder verwaltet, die im CRM zu den Ansichten „Kunden“, „Ansprechpartner“ und „Einsätze“ zugeordnet werden können.</p>
<h4>Neues Feld hinzufügen</h4>
<ol class="list">
    <li>Um ein neues Produkt anzulegen, klicken Sie im Menu auf „CRM“ und wählen den Punkt „Felder“ aus. Dann klicken Sie auf den Button „Feld hinzufügen“.</li>
    <li>Es öffnet sich ein Eingabefenster.</li>
</ol>
<h4>Ein Feld bearbeiten</h4> 
<ol class="list">
    <li>Um ein Feld zu bearbeiten, wählen Sie dieses in der Liste aus. Alternativ können Sie über Aktionen, die Bearbeitung auswählen.</li>
    <li>Es öffnet sich ein neues Eingabefenster.</li>
 </ol>