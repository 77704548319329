import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/server.service';
import { LocalizationService } from '../../localization/localization.service';
import { AmselError } from './amsel-error.model';
import { ErrorService } from './error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(public server: ServerService, public localization: LocalizationService) { }

  ngOnInit(): void {

  }

  onClose(id: string) {
    this.server.errors = this.server.errors.filter( (err) => err.id !== id );
  }

}
