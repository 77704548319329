import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';

@Component({
  selector: 'app-filter-from-to',
  templateUrl: './filter-from-to.component.html',
  styleUrls: ['./filter-from-to.component.css']
})
export class FilterFromToComponent implements OnInit {
  @ViewChild('fromDateForm') fromDateForm;
  @ViewChild('toDateForm') toDateForm;

  @Input() fromDate: Date;
  @Output('fromDate') fromDateOut = new EventEmitter<Date>();
  fromDateValidate: string;
  
  @Input() toDate: Date;
  @Output('toDate') toDateOut = new EventEmitter<Date>();
  toDateValidate: string;

  @Input() disabled: boolean;

  @Output() update = new EventEmitter<{from: Date, to: Date}>();  

  @Input() confirm = false
  open = false;
  value: { value: Date, type: string };

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
  }

  sendUpdate() {
    this.update.emit({from: this.fromDate, to: this.toDate});
  }

  clear() {
    this.fromDate = undefined;
    this.fromDateValidate = undefined;
    this.toDate = undefined;
    this.toDateValidate = undefined;


    this.fromDateForm.form.markAsPristine();
    this.fromDateForm.form.markAsUntouched();
    this.fromDateForm.form.updateValueAndValidity();

    this.toDateForm.form.markAsPristine();
    this.toDateForm.form.markAsUntouched();
    this.toDateForm.form.updateValueAndValidity();

    this.sendUpdate();
  }

  minDate () {
    try {
      return this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1).toString() + '-' + this.fromDate.getDate().toString();
    }
    catch {
      return '2000-01-01';
    }
  }

  maxDate () {
    try {
      return this.toDate.toISOString();
    }
    catch {
      return '';
    }
  }

}
