import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-de-call',
  templateUrl: './de-call.component.html',
})
export class DeCallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
