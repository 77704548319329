<h2>{{ localization.dictionary.call.name}}</h2>
<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid *ngIf="sectionFields && sectionFields.length > 0" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading"
    [(clrDgSelected)]="selected" (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.call.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngFor="let customField of sectionFields"
        [clrDgSortBy]="'fieldValues.' + customField.id"
        [(clrDgSortOrder)]="sorting[customField.name]" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden[customField.name]">
            {{ customField.label }}
        </ng-template>
        <clr-dg-filter
            *ngIf="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX' || customField.type == 'CHOICE' || customField.type === 'SELECTION'"
            [clrDgFilter]="multiselectFilter" [(clrDgFilterOpen)]="multiselectFilter.open">
            <multiselect-filter #multiselectFilter [name]="customField.label" [searchBy]="'label'"
                [property]="'fieldValues.' + customField.id" [query]="'crm/field/byId/' + customField.id"
                [(filter)]="filters[customField.name]"
                [toggle]="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX'">
            </multiselect-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'NUMBER'" [clrDgFilter]="numberFilter"
            [(clrDgFilterOpen)]="numberFilter.open">
            <number-filter #numberFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [offset]="0"></number-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'BOOLEAN'" [clrDgFilter]="booleanFilter"
            [(clrDgFilterOpen)]="booleanFilter.open">
            <boolean-filter #booleanFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"></boolean-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TEXT' || customField.type == 'TEXTAREA'" [clrDgFilter]="stringFilter"
            [(clrDgFilterOpen)]="stringFilter.open">
            <string-filter #stringFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]"></string-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'DATE'" [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </date-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TIME'" [clrDgFilter]="timeFilter"
            [(clrDgFilterOpen)]="timeFilter.open">
            <time-filter #timeFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </time-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'customer.fieldValues.' + crm.sortField.customer"
        [(clrDgSortOrder)]="sorting['customer']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['customer']">
            {{localization.dictionary.customer.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <combobox-filter #customerFilter [property]="'customer'" [name]="localization.dictionary.customer.name"
                [customFields]="true" [query]="'crm/customer/combobox/' + customerView.id" [type]="customerType" [toggle]="false"
                [(filter)]="filters['customer']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'contacts.fieldValues.' + crm.sortField.contact"
        [(clrDgSortOrder)]="sorting['contacts']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['contacts']">
            {{localization.dictionary.contact.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="contactFilter" [(clrDgFilterOpen)]="contactFilter.open">
            <combobox-filter #contactFilter [property]="'contacts'" [name]="localization.dictionary.contact.name"
                [customFields]="true" [query]="'crm/contact/combobox/' + contactView.id" [type]="contactType"
                [(filter)]="filters['contacts']">

            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'products.name'"
        [(clrDgSortOrder)]="sorting['products']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['products']">
            {{localization.dictionary.product.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="productFilter" [(clrDgFilterOpen)]="productFilter.open">
            <multiselect-filter #productFilter [name]="localization.dictionary.product.name" [property]="'products'" [(filter)]="filters['crm/products']"
                [query]="'crm/product/filter'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>


    <clr-dg-column [clrDgSortBy]="'documents.name'"
        [(clrDgSortOrder)]="sorting['documents']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['documents']">
            {{localization.dictionary.document.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="documentFilter" [(clrDgFilterOpen)]="documentFilter.open">
            <multiselect-filter #documentFilter [name]="localization.dictionary.document.name" [property]="'documents'"
                [query]="'document/filter'" [(filter)]="filters['documents']">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>


    <clr-dg-column [clrDgSortBy]="'users.name'" [(clrDgSortOrder)]="sorting['users']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['users']">
            {{localization.dictionary.user.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
            <multiselect-filter #userFilter [name]="localization.dictionary.user.name" [property]="'users'" [(filter)]="filters['crm/users']"
                [query]="'user/filter'">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>


    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.createdAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updatedAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deletedAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdBy'"
        [(clrDgSortOrder)]="sorting['createdBy']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdBy']">
            {{localization.dictionary.list.createdBy}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdByFilter" [(clrDgFilterOpen)]="createdByFilter.open">
        <string-filter #createdByFilter [property]="'createdBy'"
        [(filter)]="filters['createdBy']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'updatedBy'"
        [(clrDgSortOrder)]="sorting['updatedBy']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedBy']">
            {{localization.dictionary.list.updatedBy}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedByFilter" [(clrDgFilterOpen)]="updatedByFilter.open">
        <string-filter #updatedByFilter [property]="'updatedBy'"
        [(filter)]="filters['updatedBy']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedBy'"
        [(clrDgSortOrder)]="sorting['deletedBy']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedBy']">
            {{localization.dictionary.list.deletedBy}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedByFilter" [(clrDgFilterOpen)]="deletedByFilter.open">
        <string-filter #deletedByFilter [property]="'deletedBy'"
        [(filter)]="filters['deletedBy']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>
    
    <clr-dg-row *ngFor="let call of calls" [clrDgItem]="call" [clrDgSelectable]="!call.deletedAt"
        [class.deleted]="call.deletedAt">
        <clr-dg-cell [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">{{ call.id }}</clr-dg-cell>
        <clr-dg-cell *ngFor="let customField of sectionFields" [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
            <ng-container *ngFor="let fieldValue of call[customField.name]; let i = index">
                        <ng-container *ngIf="customField.type != 'TIME' && customField.type != 'DATE' && customField.type != 'BOOLEAN'">
                            <span [class.deleted-option]="customField?.fieldOptions && customField.fieldOptions.length > 0 && !fieldValue.fieldOption">
                                {{(fieldValue.value || '') + (i < call[customField.name].length - 1? ',' : '' )}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="customField.type == 'BOOLEAN'">
                            {{ fieldValue.value == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no }}
                        </ng-container>
                        <ng-container *ngIf="customField.type == 'TIME'">
                            {{ fieldValue.value | date: localization.dictionary.dateFormat.time : undefined : localization.language.selector }}
                        </ng-container>
                        <ng-container *ngIf="customField.type == 'DATE'">
                            {{ fieldValue.value | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
                        </ng-container>
                </ng-container>
        </clr-dg-cell>
        <clr-dg-cell >
            <span *ngIf="call?.customer"
                [pTooltip]="call.customer.deletedAt ? localization.dictionary.callList.userDeleted : null"
                tooltipPosition="top"
                [ngClass]="call.customer.deletedAt ? 'label label-grey' : 'label label-light-blue'">
                <app-display-name [model]="call.customer" [prePostFix]="''" [ngStyle]="{'color': call.customer.deletedAt ? 'grey' : null}"></app-display-name>
            </span>
        </clr-dg-cell>
        <clr-dg-cell>
            <span *ngFor="let contact of call.contacts"
                [pTooltip]="contact.deletedAt ? localization.dictionary?.callList?.contactDeleted : contact.description"
                tooltipPosition="top"
                class="label label-light-blue"
                [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
                <app-display-name [model]="contact" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>

        <clr-dg-cell>
            <span *ngFor="let product of call.products"
                    [pTooltip]="product?.description"
                    tooltipPosition="top"
                    [routerLink]="product.deletedAt ? null : ['/crm/product/edit/' + product.id]"
                    class="label label-light-blue">
                {{ product?.name }}
            </span>
        </clr-dg-cell>

        <clr-dg-cell>
            <span *ngFor="let document of call.documents"
                    [pTooltip]="document?.description"
                    tooltipPosition="top"
                    [routerLink]="document.deletedAt ? null : ['/documents/edit/' + document.id]"
                    class="label label-light-blue">
                {{ document?.name }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell >
            <span *ngFor="let user of call.users"
                    [pTooltip]="user?.firstName + ' ' + user?.lastName"
                    [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]"
                    tooltipPosition="top"
                    class="label label-light-blue">
                {{ user?.name }}
            </span>
        </clr-dg-cell>


        <clr-dg-cell [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
            {{ call.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
            {{ call.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
            {{ call.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
          {{ call.createdBy }}
      </clr-dg-cell>
      <clr-dg-cell [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
        {{ call.updatedBy }}
    </clr-dg-cell>
    <clr-dg-cell *ngIf="showDeleted"
    [routerLink]="call.deletedAt? null : ['/crm/call/edit/' + call.id]">
      {{ call.deletedBy }}
  </clr-dg-cell>

        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon *ngIf="!call.deletedAt"
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.call.nameSingular).replace('${entryName}', crm.getDisplayValue(call))"
                tooltipPosition="left"
                shape="pencil"
                [routerLink]="['/crm/call/edit/' + call.id]">
            </clr-icon>
            
            <clr-icon *ngIf="!call.deletedAt"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.call.nameSingular).replace('${entryName}', crm.getDisplayValue(call))"
                tooltipPosition="left"
                shape="trash"
                (click)="toDelete = [call]">
            </clr-icon>

            <clr-icon *ngIf="call.deletedAt"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.call.nameSingular).replace('${entryName}', crm.getDisplayValue(call))"
                tooltipPosition="left"
                shape="backup-restore"
                (click)="openRestoreModal(call)">
            </clr-icon>

            <clr-icon *ngIf="!call.deletedAt"
                [pTooltip]="localization.dictionary.list.showAuditLogs"
                tooltipPosition="left"
                shape="file"
                (click)="openAuditLogModal(call.id)">
            </clr-icon>

        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.call.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.call.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.call.nameSingular : localization.dictionary.call.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.callList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title" *ngIf="selectedCall">  {{ localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.call.nameSingular).replace('${entryName}', crm.getDisplayValue(selectedCall)) }}</h3>
    <div class="modal-body" *ngIf="restoreModal">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{localization.dictionary.button.restore}}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="exportModal">
    <h3 class="modal-title">{{localization.dictionary.list.excelModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.list.excelModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="downloadExcel();">{{localization.dictionary.button.excelWithFilter}}</button>
        <button type="button" class="btn btn-outline" (click)="downloadExcel('?skip=0&limit=0')">{{localization.dictionary.button.excelWithoutFilter}}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/crm/call/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.call.nameSingular) }}</button>

            <button [pTooltip]="total === 0 ? localization.dictionary.list.noDataToExport : null"
                    tooltipPosition="bottom"
                    class="btn btn-primary"
                    (click)="checkExportType()"
                    [disabled]="total === 0 || loading">
                {{ localization.dictionary.button.excelExport }}
            </button>

            <ng-container *ngIf="helper.checkInstanceSubdomain('pharmxpert')">
                <button class="btn btn-outline" (click)="downloadPharmxpert(true)" [disabled]="total===0 || loading">
                    Export (Kunden)
                </button>
                <button class="btn btn-outline" (click)="downloadPharmxpert(false)" [disabled]="total===0 || loading">
                    Export (Ansprechpartner)
                </button>
            </ng-container>

            <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-outline" *ngIf="selected.length > 1"
                (click)="multiUpdate = true">{{localization.dictionary.button.updateMultiple}}</button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>
        </clr-dg-action-bar>
        <div fxLayout="row" fxLayoutAlign="flex-start flex-end">
            <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['crm-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                    (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.call.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
        <clr-dropdown>
            <button class="view dropdown-toggle" clrDropdownTrigger>
                <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                <clr-icon shape="caret down" size="20"></clr-icon>
            </button>
            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                <label class="dropdown-header" aria-hidden="true">{{localization.dictionary.view.name}}</label>
                <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                <div *ngFor="let view of views" (click)="activeViewChange(view)"  clrDropdownItem>
                    <clr-icon [ngStyle]="{'visibility': (view.id === activeView.id) ? 'visible' : 'hidden' }"
                        style="margin-right: 0.3rem" shape="check"></clr-icon>
                    {{ view.name }}
                </div>
            </clr-dropdown-menu>
        </clr-dropdown>
        </div>

    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="auditLogModalOpen" clrModalSize="xl">
    <h3 class="modal-title">{{localization.dictionary.auditLogging.changeHistory}}</h3>
    <div class="modal-body">
        <app-audit [entityId]="entityId" [entityType]="entityType"></app-audit>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="auditLogModalOpen=false;">{{localization.dictionary.button.ok}}</button>
    </div>
</clr-modal>

<app-multi-update-crm type="call" [(multiUpdateOpen)]="multiUpdate" (multiUpdateOpenChange)="multiModel = {}" (rowsChange)="refresh()" [(rows)]="selected" [model]="multiModel" [activeView]="activeView"></app-multi-update-crm>

