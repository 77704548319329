<form clrForm>
  <div>
    {{localization.dictionary.formCreateEdit.pleaseSelectField}}
  </div>
    <clr-select-container>
        <label>{{localization.dictionary.general.table}}</label>
        <select clrSelect name="options" [(ngModel)]="table" required (ngModelChange)="onChange($event)">
            <option value="customer">{{localization.dictionary.customer.nameSingular}}</option>
            <option value="contact">{{localization.dictionary.contact.nameSingular}}</option>
            <!-- <option value="call">Besuche/Calls</option> -->
        </select>
    </clr-select-container>
    
    <clr-combobox-container>
        <label>{{localization.dictionary.field.nameSingular}}</label>
        <clr-combobox
          [(ngModel)]="field"
          (ngModelChange)="changed.emit({table: table, field: field})"
          name="fieldSelect"
          required
          [clrLoading]="loading"
          (clrInputChange)="fetchFields($event)"
          (clrOpenChange)="$event ? fetchFields() : null"
        >
          <ng-container *clrOptionSelected="let selected">{{selected?.label}}</ng-container>
          <clr-options>
            <clr-option *clrOptionItems="let field of asyncFields$ | async; field:'label'" [clrValue]="field">
              {{field.label}}
            </clr-option>
          </clr-options>
        </clr-combobox>
        <clr-control-error>{{localization.dictionary.general.mandatoryField}}</clr-control-error>
      </clr-combobox-container>
</form>
