import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { Form } from '../form.model';
import { Question, QuestionType } from '../question.model';
import { mapIcons } from '../map-icons';
import { HelperService } from 'src/app/helper/helper.service';
import { ServerService } from 'src/app/server.service';
import { plainToClass } from 'class-transformer';
import { ActivatedRoute, Router } from '@angular/router';
import { PreventUnload } from 'src/app/helper/guards/unsaved.guard';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { ClrForm } from '@clr/angular';
import { LocalizationService } from 'src/app/localization/localization.service';
import { SettingService } from 'src/app/settings/setting.service';
import { Category } from 'src/app/categories/category.model';
import { GivenAnswer } from '../given-answer.model';


@Component({
  selector: 'app-create-edit-forms',
  templateUrl: './create-edit-forms.component.html',
  styleUrls: ['./create-edit-forms.component.css']
})
export class CreateEditFormsComponent extends PreventUnload implements OnInit, DoCheck {
  @ViewChildren('field') fields: QueryList<any>;
  @ViewChild('formData') formData: NgForm;
  @ViewChild(ClrForm) clrform: ClrForm;

  loading = false;
  mode: 'create' | 'edit' = 'create';
  form: Form = new Form();
  questionType = QuestionType;
  activeQuestion: number;
  sectionIndizes = [];
  blockActivate = false;
  mapIcons = mapIcons;
  questionValidity = {};

  modalPublish = false;
  modalUpdate = false;
  modalDocumentCategory = false;
  invalid = false;
  withDocumentCreation = true;
  categoryCount = 0;
  documentCategories: Category[] = []; 
  documentCategory: string; 
  isFormValid: boolean = true;
  
  constructor(public helper: HelperService,
              private server: ServerService,
              public location: Location,
              private route: ActivatedRoute,
              private router: Router,
              public localization: LocalizationService,
              public settings: SettingService) {
                super(localization);
                this.subscriptions.add(
                  this.route.params.subscribe(async (params) => {
                    this.mode = (params.id) ? 'edit' : 'create';
                    if (this.mode === 'edit') {
                      this.form = plainToClass(Form, await this.server.get('form/includeQuestions/' + params.id));
                      this.dirty = false;
                    } else {
                      this.dirty = true;
                    }
                    this.changeUnload();
                    this.isFormValid = this.isValid();
                  })
                );
                this.subscriptions.add(
                  this.localization.languageChanged.subscribe(()=> {
                    this.changeUnload();
                  })
                );
              }

  ngOnInit(): void {
    this.fetchDocumentCategories();
  }

  async fetchDocumentCategories() {
    const response = await this.server.get('category/');
    this.documentCategories = plainToClass(Category, response.rows);
    const categoryNames = this.documentCategories.map(category => category.name);
    this.categoryCount = response.count;
    if(response.count < 1){
      this.withDocumentCreation = false;
    }
  }

  changeUnload() {
    if (this.mode == 'create') {
      this.changeUnloadNew('form');
    } else {
      this.changeUnloadEdit('form', this.form.name);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.fields.changes.subscribe(c => {
        if (c ) {
          c.forEach( (question) => {
            if (question.active) {
              let el = document.getElementById('question' + question.index);
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              })
            }
          })
        }
      })
    );
  }

  addField(i: number, type: QuestionType) {
    if (!i) i = 0;
    let question = new Question(type, true);
    question.name = this.localization.dictionary.formQuestionType[type];
    if (type === 'DESCRIPTION') {
      question.description = this.localization.dictionary.formCreateEdit.descriptionCustomize;
    }
    this.form.questions.splice(i, 0, question);
    this.activeQuestion = i;
    this.formChanged();
  }

  async drop(event: CdkDragDrop<Question[]>) {
    moveItemInArray(this.form.questions, event.previousIndex, event.currentIndex);
    if (this.activeQuestion === event.previousIndex) {
      this.activeQuestion = event.currentIndex;
    } else {
      this.activeQuestion = undefined;
    }
    this.formChanged();
  }

  // async publishForm() {
  //   this.activeQuestion = undefined;
  //   this.form.published = new Date();
  //   this.modalPublish = false;
  //   await this.save(true);
  //   this.location.back();
  // }

  async publishForm() {
    this.isFormValid = this.isValid();
    if(this.isFormValid) {
      this.activeQuestion = undefined;
      this.form.published = new Date();
      this.modalPublish = false;
      await this.save(true);
      this.location.back();
    } else {
      this.invalid = true;
    }
  }

  async publishFormWithDocument() {
    if(this.documentCategories.length == 1){
      this.documentCategory = this.documentCategories[0].id;
    }
    this.modalDocumentCategory = true;
  }

  async confirmPublishWithDocument() {
    this.activeQuestion = undefined;
    this.form.published = new Date();
    this.modalDocumentCategory = false;
    await this.save(true);
    this.router.navigate(['documents/create', this.documentCategory], {
      queryParams: {
        formId: this.form.id
      }
    });
  }

  activate(i) {
    if (!this.blockActivate && !this.form.published) {
      this.activeQuestion = i;
    }
    this.blockActivate = false;
  }

  deactivate() {
    this.activeQuestion = undefined;
    this.blockActivate = true;
  }

  async save(publish?: boolean) {
    if (!this.formData.valid) {
      this.formData.form.markAllAsTouched();            
      return;
    }
    this.loading = true;
    this.saving = true;
    let res;
    const resUnique = await this.server.get('form/unique/' + this.mode + '/' + this.form.id + '/' + this.form.name);
    if(resUnique){
      try {
        if (this.mode === 'create') {
          res = await this.server.post('form', this.form);
          this.mode = 'edit';
          this.form = plainToClass(Form, res);
          this.location.go('forms/edit/' + this.form.id);
        } else {
          res = await this.server.put('form', this.form);
          this.form = plainToClass(Form, res);
        }
        /* this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.toastr.successSaved
        .replace('${componentName}', this.localization.dictionary.form.nameSingular)
        .replace('${entryName}', this.form.name))); */
        this.server.addAlert(new AmselError(undefined, 'success',
        this.localization.dictionary.form.nameSingular + ` '${this.form.name}'  ${(publish) ? this.localization.dictionary.toastr.published : this.localization.dictionary.toastr.saved}`));
      } catch(err) {
        this.loading = false;
        this.markFieldsAsInvalid(err.error?.fields);
        throw new HttpErrorResponse(err);
      }
    } else{
      // this.server.addAlert(new AmselError(undefined, 'warning', 'Ein Formular mit demselben Namen existiert bereits!'));
      this.formData.form.controls.name.setErrors({ notUnique: true })
    }
    this.dirty = false;
    this.saving = false;
    this.loading = false;
  }

  markFieldsAsInvalid(errors: Object) {
    if (errors) {
      this.formData.form.markAsDirty();
      this.formData.form.markAsTouched();
      for (let field of Object.keys(errors)) {
        this.formData.form.controls[field]?.markAsDirty();
        this.formData.form.controls[field]?.setErrors(errors[field]);
      }
    }
  }

  deleteQuestion(i: number) {
    delete this.questionValidity[this.form.questions[i].id];
    this.form.questions.splice(i, 1);
  }

  getSectionCount(): number {
    let count = 0;
    this.sectionIndizes = [];
    for (let i=0; i < this.form.questions.length; i++) {
      if (this.form.questions[i].type === 'SECTION') {
        count++;
        this.sectionIndizes[i] = count;
      }
    }
    return count;
  }

  formChanged(event?: {id: string, valid: boolean}) {
    for (let i=0; i < this.form.questions.length; i++) {
      this.form.questions[i].order = i;
      for (let j=0; j < this.form.questions[i].offeredAnswers.length; j++) {
        this.form.questions[i].offeredAnswers[j].order = j;
      }
    }
    this.dirty = true;
    if (event && event.id) {
      this.questionValidity[event.id] = event.valid;
    }
  }

  newAsTemplate() {
    let oldForm: Form = Object.assign(this.form);
    oldForm.id = undefined;
    oldForm.name = '';
    oldForm.published = undefined;
    for (let question of oldForm.questions) {
      question.id = undefined;
      for (let oa of question.offeredAnswers) {
        oa.id = undefined;
      }
    }
    this.form = plainToClass(Form, oldForm);
    this.mode = 'create';
  }

  ngDoCheck() {
    if (this.invalid && this.clrform)
      this.clrform.markAsTouched()
  }

  // async validateAndSave() {
  //   if (this.isValid()) {
  //     await this.save();
  //   }
  //   else {
  //     this.invalid = true;
  //   }
  //   this.modalUpdate = false;
  // }



  isValid(): boolean {
    let valid = true;
    let objectKeys = Object.keys(this.questionValidity);
    if (objectKeys.length === 0) {
      for (let question of this.form.questions) {
        console.log(question)
        this.questionValidity[question.id] = question.name && question.name.trim() != '';
        if (this.questionValidity[question.id] && question.offeredAnswers.length > 0) {
          this.questionValidity[question.id] = question.offeredAnswers.every((answer) => answer.value && answer.value.trim() != '');
        } else if (question.offeredAnswers.length > 0) {
          this.questionValidity[question.id] = false;

        }
      }
      objectKeys = Object.keys(this.questionValidity);
    }
    console.log(this.questionValidity);
    for (let id of objectKeys) {
      if (!this.questionValidity[id]) {
        valid = false;
      }
    }
    if (!this.form.name || !this.form.description) valid = false;
    return valid;
  }

isNameAndDescriptionValid(): boolean {
  return this.form.name && this.form.name.trim() !== '' && this.form.description && this.form.description.trim() !== '';
}


async validateAndSave() {
  let valid = true;
  if (!this.isNameAndDescriptionValid()) {
      valid = false;
  }
  await this.save();
  this.isFormValid = this.isValid();
  this.modalUpdate = false;
}
 
}
