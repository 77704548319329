import { Question, QuestionType } from "src/app/forms/question.model";
import { MetaData } from "src/app/helper/meta-data.class";
import * as uuid from 'uuid';
import { FieldOption } from "./field-option.model";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { SectionField } from "../views/section-field.model";

Exclude()
export class Field extends MetaData {
    @Expose()
    id: string = uuid.v4();

    @Expose()
    name: string = 'u_';

    @Expose()
    label: string;

    @Expose()
    pattern: string;

    @Expose()
    isActive: boolean = true;

    @Expose()
    appliesToCall: boolean = false;

    @Expose()
    appliesToCustomer: boolean = false;

    @Expose()
    appliesToContact: boolean = false;

    @Expose()
    type: QuestionType = QuestionType.TEXT;
    
    @Expose()
    display: boolean = false;

    @Expose()
    categoryId: string;

    @Expose()
    //@Transform(( value, obj) => { if (!value ) value = new SectionField()})
    SectionField: SectionField = new SectionField();

    @Expose()
    fieldOptions: FieldOption[] = [];

    @Expose()
    questions: Question[] = [];

    @Expose()
    trackChanges: boolean = false;

}