import { Type } from "class-transformer";
import { Document } from "../documents/document.model";
import { MetaData } from "../helper/meta-data.class";
import { AnsweredForm } from "./answered-form.model";
import { GivenAnswer } from "./given-answer.model";
import { Question } from "./question.model";

export class Form extends MetaData {

    id: string;

    name: string;

    description: string;

    isActive: boolean = true;

    type: 'open' | 'optin' | 'customer' = 'open';

    createNew: boolean;
    
    @Type(() => Question)
    questions: Question[] = [];

    @Type(() => Document)
    documents: Document[] = [];

    @Type(() => Date)
    published: Date = null;

    @Type(() => AnsweredForm)
    answeredForms: AnsweredForm[];

    getCummulatedAnswersByQuestion(questionId: string): {name: string, value: any}[] {
        let answers: GivenAnswer[] = [];
        for (let answeredForm of this.answeredForms) {
            let foundAnswers = answeredForm.givenAnswers.filter( (answer) =>  answer.questionId === questionId);
            for (let foundAnswer of foundAnswers) {
                answers.push(foundAnswer);
            }            
        }        
        let answerValues = [];
        let answerMapping = {};
        for (let answer of answers) {
            let questionType = answer.question.type;
            // Sonderfälle bzgl. Formatierung
            if (questionType == 'DATE') {
                let changedValue = new Date(answer.value).toLocaleDateString();
                answerMapping[changedValue] = answerMapping[answer.value] + 1 || 1;
            } else if (questionType != 'SIGNATURE') {
                answerMapping[answer.value] = answerMapping[answer.value] + 1 || 1;
            }
            
        }
        for (let answer of Object.keys(answerMapping)) {
            answerValues.push({
                name: answer,
                value: answerMapping[answer]
            });            
        }        
        return answerValues;
    }    

    getCountOfGivenAnswers(questionId: string) {
        let count = 0;
        for (let answeredForm of this.answeredForms) {
            let foundAnswers = answeredForm.givenAnswers.filter( (answer) =>  answer.questionId === questionId);      
            count += foundAnswers.length; 
        }
        return count;
    }
}