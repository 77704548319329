import { Component, OnInit } from '@angular/core';
import { UserHelpService } from '../../user-help.service';
import { LocalizationService } from '../../../localization/localization.service';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-de-help',
  templateUrl: './de-help.component.html',
  styleUrls: ['./de-help.component.css']
})
export class DeHelpComponent implements OnInit {

  crmActive: boolean;
  formActive: boolean;
  pushActive: boolean;
  brandingActive: boolean;
  analyticsActive: boolean;

  constructor(public userHelp: UserHelpService, public localization: LocalizationService, public settingsService: SettingService) { }

  ngOnInit(): void {
    this.crmActive = this.settingsService.settings.find(setting => setting.selector === "crmActive").value === 'true' ? true : false;
    this.formActive = this.settingsService.settings.find(setting => setting.selector === "formActive").value === 'true' ? true : false;
    this.pushActive = this.settingsService.settings.find(setting => setting.selector === "pushActive").value === 'true' ? true : false;
    this.brandingActive = this.settingsService.settings.find(setting => setting.selector === "brandingActive").value === 'true' ? true : false;
    this.analyticsActive = this.settingsService.settings.find(setting => setting.selector === "analyticsActive").value === 'true' ? true : false;
  }

  openTopic(topic: string) {
    this.userHelp.helpWanted.next({componentName: topic, display: "modal"});
  }
}
