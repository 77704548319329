import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { Territory } from '../crm/territories/territory.model';
import { Group } from '../groups/group.model';
import { Role } from '../roles/role.model';
import { Session } from './session.model';

@Exclude()
export class User {
    @Expose()
    id: string;


    @Expose()
    name: string;

    @Expose()
    formOfAddress: string;

    @Expose()
    firstName: string;

    @Expose()
    lastName: string;

    @Expose()
    isActive: boolean;

    @Expose()
    @Type( () => Group)
    groups: Group[] = [];

    @Expose()
    @Type( () => Role)
    roles: Role[] = [];

    @Expose()
    @Transform((val, obj) => {
        if (obj.groups) {
            let result: Role[] = []
            obj.groups.forEach(group => {
                if (!group.deletedAt)
                    group.roles.forEach(role => {
                        const index = result.find(value => value.name == role.name)
                        if (index)
                            index.groupNames.push(group.name)
                        else {
                            role.groupNames = [group.name]
                            result.push(role)
                        }
                    })
            })
            obj.roles = obj.roles.filter(role => !result.find(fullRole => fullRole.name == role.name))
            obj.roles.forEach(role => role.direct = true)
            return result.concat(obj.roles)
        }        
    })
    fullRoles: Role[]

    @Expose()
    oldPassword: string;

    @Expose()
    password: string;

    @Expose()
    email: string;

    @Expose()
    serviceAccount: boolean;

    @Expose()
    @Type( () => Session)
    sessions: Session[];

    @Expose()
    @Type( () => Territory)
    territories: Territory[];

    

    @Expose()
    @Transform((val, obj) => {
        let result: Territory[] = [];
        if (obj.groups) {
            obj.groups.forEach(group => {
                if (!group.deletedAt && group.territories)
                    group.territories.forEach(territory => {
                        const index = result.find(value => value.name == territory.name)
                        if (index)
                            index.groupNames.push(group.name)
                        else {
                            territory.groupNames = [group.name]
                            result.push(territory)
                        }
                    })
            })
        }        
        
        if(obj.territories){
            obj.territories = obj.territories.filter(territory => !result.find(fullTerritory => fullTerritory.name == territory.name))
            obj.territories.forEach(territory => territory.direct = true);
            return result.concat(obj.territories);
        }
    })
    fullTerritories: Territory[];

    @Expose()
    @Transform((val, obj) => obj.access_token)
    accessToken: string;

    @Expose()
    @Transform((val, obj) => obj.refresh_token)
    refreshToken: string;

    hasRole(roles: string[]): boolean {
        let hasRole = false;
        for (let role of roles) {
            if (this.roles.find((userRole) => { userRole.name === role })) {
                hasRole = true;
            }
        }
        for (let group of this.groups) {
            if (group.hasRole(roles)) {
                hasRole = true;
            }
        }
        return hasRole;
    }

    @Expose()
    @Type(() => Date)
    lastLogin: Date;

    @Expose()
    @Type(() => Date)
    lastSync: Date;

    @Expose()
    groupNames?: string[];

    @Expose()
    @Type(() => Date)
    createdAt: Date;

    @Expose()
    @Type(() => Date)
    updatedAt: Date;

    @Expose()
    @Type(() => Date)
    deletedAt: Date;
}