<clr-icon *ngIf="isActive()" class="refresh-filter is-solid" shape="filter-off" (click)="onClick(true)"></clr-icon>
<form>
    <clr-input-container [class.active]="document.activeElement == searchFrom" *ngIf="activeFrom">
        <label>{{localization.dictionary.filter.startDate}}</label>
        <input clrInput type="date" [(ngModel)]="filterFrom" [attr.format]="localization.dictionary.dateFormat.date" (ngModelChange)="onChange()" width="unset" [max]="filterTo" (keyup.enter)="open = false" #searchFrom name="from" autocomplete="off" />
    </clr-input-container>
    <clr-icon *ngIf="activeFrom" style="cursor: pointer;" class="fake-calendar" shape="calendar"></clr-icon>
    <clr-input-container [class.active]="document.activeElement == searchTo" *ngIf="activeTo">
        <label>{{localization.dictionary.filter.endDate}}</label>
        <input clrInput type="date" [(ngModel)]="filterTo" (ngModelChange)="onChange()" [min]="filterFrom" (keyup.enter)="open = false" #searchTo name="to" autocomplete="off"/>
    </clr-input-container>
    <clr-icon *ngIf="activeTo" style="cursor: pointer;" class="fake-calendar" shape="calendar"></clr-icon>
    <button *ngIf="confirm" type="submit" class="btn btn-primary btn-sm" (click)="onClick()">{{localization.dictionary.button.apply}}</button>
</form>