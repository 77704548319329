
<div class="main-container" track-scroll>    
    <app-error></app-error>  
    <app-header *ngIf="!hideHeader"></app-header>
    <div class="content-container">
        <app-sidenav *ngIf="!hideSideNav"></app-sidenav>
        <div class="content-area" [ngClass]="(hideHeader && hideSideNav) ? 'no-padding' : ''" cdkScrollable >
            <router-outlet ></router-outlet>
        </div>
        <div class="help-bar" id="help-bar">
            <div class="user-doc-area" id="user-doc-area">
                <app-show-topic></app-show-topic>
            </div> 
        </div>       
    </div>
    <app-show-topic id="doc-modal"></app-show-topic>
</div>