<p>Im Bereich Ansichten können Sie die Felder definieren, deren Daten in den Eingabemasken Ansprechpartner, Kunde oder Kundenkontakte erfasst werden. Voreingestellt sind die Ansichten:
    <br>
    - Ansprechpartner-Standardansicht
    <br>
    - Kundenkontakte-Standardansicht
    <br>
    - Kunden-Standardansicht</p>
<h4>Neue Ansicht hinzufügen</h4>
<ol class="list">
    <li>Zur Anlage einer neuen Ansicht klicken Sie im Hauptmenu auf „CRM“ und dann auf den Punkt „Ansichten“.</li>
    <li>Anschließend klicken Sie auf den Button „Ansicht hinzufügen“.</li>
    <li>Es öffnet sich ein Eingabefenster.</li>
</ol>
<h4>Eine Ansicht anpassen</h4> 
<ol class="list">  
    <li>· Zum Anpassen einer Ansicht klicken Sie im Hauptmenü auf CRM und dann auf den Punkt „Ansichten“.</li>
    <li>· In der Übersicht der bestehenden Ansichten klicken Sie auf irgendeinen Punkt in der Zeile oder direkt auf das Stift-Symbol.</li>
    <li>Es öffnet sich ein neues Eingabemaske.</li>
</ol>