<div class="card widget-card" style="margin-top: 0 !important;" fxLayout="column">
    <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
        <span>{{localization.dictionary.dashboard.top}} {{ (total > 0) ? total : ''}} {{ (layer === 0) ? ((total > 1) ? localization.dictionary.document.namePlural : localization.dictionary.document.nameSingular) : localization.dictionary.dashboard.pagesOf + ' \'' + docName + '\'' + ' (' + localization.dictionary.dashboard.durationInMinutes + ')' }} </span>
        <clr-icon *ngIf="layer === 1" shape="undo" (click)="layer = 0; refresh(from, to)"></clr-icon>
    </div>
    <div class="card-block" fxFill>
      <div class="card-text" fxLayout="column">
        <ng-container *ngIf="!loading; else spinner">
          <p *ngIf="layer === 0"> {{localization.dictionary.dashboard.clickToGetInfoAboutIndividPages}}</p>
          <ngx-charts-pie-chart
            *ngIf="layer === 0"
            [results]="data"
            [legend]="false"
            [labels]="true"
            [doughnut]="false"
            [legendTitle]="''"
            #chart
            class="widget-text"
            (select)="drillDown($event)"
            [ngClass]="{'dark-mode': isDarkMode}"
            >
          </ngx-charts-pie-chart>

          <ngx-charts-bar-vertical
            *ngIf="layer === 1"
            class="widget-text"
            [results]="data"
            [showDataLabel]="true"
            [showGridLines]="false"
            [xAxis]="true"
            [yAxis]="true"
            [yAxisTicks]="yTicks"
            #chart
            >
          </ngx-charts-bar-vertical>

        </ng-container>
        <ng-template #noData>
          <app-no-data></app-no-data>
        </ng-template>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>

      </div>
    </div>
    <div class="card-footer">
      <app-footer-widgets [chart]="chart" 
      [widgetTitle]="localization.dictionary.dashboard.top + ' ' + ((total > 0) ? total : '') + ' ' + ((total > 1) ? localization.dictionary.document.namePlural : localization.dictionary.document.nameSingular) + ((layer === 0) ? '' : ' ' + localization.dictionary.dashboard.pagesOf + ' \'' + docName + '\'' + ' (' + localization.dictionary.dashboard.durationInMinutes + ')')" [data]="data" widgetName="presentation"></app-footer-widgets>
    </div>
</div>
