import { Type } from "class-transformer";
import { Field } from "../crm/fields/field.model";
import { OfferedAnswer } from "./offered-answer.model";
import { Territory } from "../crm/territories/territory.model";
//import { QuestionInputType } from '../../../../common/question-input-type.enum';

export enum QuestionType {
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    SIGNATURE = 'SIGNATURE',
    CHOICE = 'CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    SELECTION = 'SELECTION',
    NUMBER = 'NUMBER',
    RANGE = 'RANGE',
    DATE = 'DATE',
    TIME = 'TIME',
    DESCRIPTION = 'DESCRIPTION',
    SECTION = 'SECTION',
    EMAIL = 'EMAIL',
    BOOLEAN = 'BOOLEAN',
    CHECKBOX = 'CHECKBOX',
    REFERENCE = 'REFERENCE',
    CUSTOMER = 'CUSTOMER',
    CONTACT = 'CONTACT',
    TERRITORY = 'TERRITORY',
    PRODUCT = 'PRODUCT',


}

export enum QuestionInputType {    
    // Text Input (short)
    NAME = 'NAME',
    EMAIL = 'EMAIL',
    POSTALCODE = 'POSTALCODE',
    PHONENUMBER = 'PHONENUMBER',
    CUSTOM = 'CUSTOM',
    NONE = 'NONE',
    // Range input
    SLIDER = 'SLIDER',
    SMILEY = 'SMILEY'
}

export class Question {
    id: string;
    name: string;
    description: string;
    type: QuestionType;
    mandatory: boolean;
    reference: boolean = false;

    @Type(() => OfferedAnswer)
    offeredAnswers: OfferedAnswer[] = [];
    inputType: QuestionInputType;
    range: string;
    rangeLegend: string;
    refTable?: 'customer' | 'contact' | 'call';
    @Type(() => Field)
    refField: Field;
    refFieldId: string;
    order: number = 0;
    disabled: boolean;
    new: boolean;
    readOnly: boolean;

    constructor(qt?: QuestionType, isNew = false) {
        this.type = qt;
        if ((qt === 'CHOICE' || qt === 'MULTIPLE_CHOICE' || qt === 'SELECTION' || qt === 'CHECKBOX') && this.offeredAnswers.length === 0) {
            this.offeredAnswers.push(new OfferedAnswer(true));
        }
        this.new = isNew;
    }

    
}