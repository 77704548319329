import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[stickyFooter]'
})
export class StickyFooterDirective implements OnDestroy, OnInit {

  private observer: IntersectionObserver;
  private observedDiv: HTMLDivElement

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.observedDiv = document.createElement('div');
    this.el.nativeElement.parentElement.appendChild(this.observedDiv);
    this.el.nativeElement.classList.toggle("sticky-card-footer", true);
    this.observer = new IntersectionObserver(
      ([e]) => {
        this.el.nativeElement.classList.toggle("pinned", !e.isIntersecting);
        
      }
    );

    this.observer.observe(this.observedDiv);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.observedDiv);
    this.observedDiv.remove();
  }

}
