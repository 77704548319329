<h2>{{ localization.dictionary.view.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selected"
    (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.view.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'name'" [(clrDgSortOrder)]="sorting['name']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['name']">
            {{localization.dictionary.general.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'name'" [(filter)]="filters['name']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'description'" [(clrDgSortOrder)]="sorting['description']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['description']">
            {{localization.dictionary.general.description}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="descriptionFilter" [(clrDgFilterOpen)]="descriptionFilter.open">
            <string-filter #descriptionFilter [property]="'description'" [(filter)]="filters['description']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'type'" [(clrDgSortOrder)]="sorting['type']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['type']">
            {{localization.dictionary.type.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="typeFilter" [(clrDgFilterOpen)]="typeFilter.open">
            <multiselect-filter #typeFilter [name]="localization.dictionary.type.name" [property]="'type'" [toggle]="false" [array]="queryArray" [crmCore]="true"
                [(filter)]="filters['parent']"></multiselect-filter>
        </clr-dg-filter>
        <!-- <clr-dg-filter [clrDgFilter]="typeFilter" [(clrDgFilterOpen)]="typeFilter.open">
            <string-filter #typeFilter [property]="'type'" [(filter)]="filters['type']"></string-filter>
        </clr-dg-filter> -->
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let view of views" [clrDgItem]="view" [class.deleted]="view.deletedAt">
        <clr-dg-cell [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">{{ view.id }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">{{ view.name }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">{{ view.description }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">{{ view.type + " (" +getType(view.type) + ")" }}</clr-dg-cell>
        <clr-dg-cell [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">
            {{ view.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">
            {{ view.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="view.deletedAt? null : ['/crm/view/edit/' + view.id]">
            {{ view.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>

        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon *ngIf="!view.deletedAt"
                shape="pencil"
                [routerLink]="['/crm/view/edit/' + view.id]"
                [pTooltip]="localization.dictionary.list.editComponentEntry
                    .replace('${componentName}', localization.dictionary.view.nameSingular)
                    .replace('${entryName}', view.name)"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon *ngIf="!view.deletedAt"
                shape="trash"
                (click)="toDelete = [view]"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry
                    .replace('${componentName}', localization.dictionary.view.nameSingular)
                    .replace('${entryName}', view.name)"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon *ngIf="view.deletedAt"
                shape="backup-restore"
                (click)="openRestoreModal(view)"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry
                    .replace('${componentName}', localization.dictionary.view.nameSingular)
                    .replace('${entryName}', view.name)"
                tooltipPosition="left">
            </clr-icon>

        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.view.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.view.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.view.nameSingular : localization.dictionary.view.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.viewList.deleteModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 class="modal-title">{{ localization.dictionary.list.restoreModalTitle.replace('${componentName}', localization.dictionary.view.nameSingular).replace('${entryName}', selectedView?.name) }}</h3>
    <div class="modal-body" *ngIf="restoreModal"></div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{localization.dictionary.button.restore}}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{localization.dictionary.button.cancel}}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/crm/view/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.view.nameSingular) }}</button>
            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>
        </clr-dg-action-bar>
        <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['crm-editor', 'admin'])">
            <clr-toggle-wrapper>
                <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                    (ngModelChange)="toggleDeleted()" />
                <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.view.name)}}</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </div>
</ng-template>