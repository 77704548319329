export const chartColors = {
    'dark': [
        { 
          backgroundColor: [
            '#7a4758', '#2a6081', '#326c70', '#7a7145', '#707c82', '#506a75', '#c23a3d', '#358f8d', '#ad7c40', '#747c8a',
            '#7a4758', '#2a6081', '#326c70', '#7a7145', '#707c82', '#506a75', '#c23a3d', '#358f8d', '#ad7c40', '#747c8a',
            '#7a4758', '#2a6081', '#326c70', '#7a7145', '#707c82', '#506a75', '#c23a3d', '#358f8d', '#ad7c40', '#747c8a'
          ], 
          borderColor: [
            '#fe6384', '#36a2eb', '#4bc0c0', '#ffce56', '#e7e9ed', '#97bbcd', '#f7464a', '#46bfbd', '#fdb45c', '#949fb1',
            '#fe6384', '#36a2eb', '#4bc0c0', '#ffce56', '#e7e9ed', '#97bbcd', '#f7464a', '#46bfbd', '#fdb45c', '#949fb1',
            '#fe6384', '#36a2eb', '#4bc0c0', '#ffce56', '#e7e9ed', '#97bbcd', '#f7464a', '#46bfbd', '#fdb45c', '#949fb1'
          ],
          borderWidth: 3
        }
    ]
}