<div *ngIf="!loading">
    <div class="card">
        <div class="card-header">
            <span>{{ helper.getCardHeader(mode, 'view', view.name) }}</span>
        </div>
        <div class="card-block">
            <div class="card-title">
            </div>
            <div class="card-text">
                <form clrForm #formData="ngForm" (change)="dirty = true" class="icon-offset">
                    <clr-input-container>
                        <label>{{localization.dictionary.general.name}}*</label>
                        <input type="text" [(ngModel)]="view.name" name="name" clrInput required maxlength="100">
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                        <clr-control-error *clrIfError="'duplicate'">{{localization.dictionary.view.alreadyExists}}</clr-control-error>
                    </clr-input-container>
                    <clr-textarea-container>
                        <label>{{localization.dictionary.general.description}}*</label>
                        <textarea clrTextarea [(ngModel)]="view.description" name="description" required maxlength="250"></textarea>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-textarea-container>
                    <clr-select-container>
                        <label>{{localization.dictionary.type.nameSingular}}</label>
                        <select clrSelect name="options" [ngModel]="view.type" (ngModelChange)="checkChange($event)"
                            required [disabled]="mode == 'edit'">
                            <option value="customer">{{localization.dictionary.customer.nameSingular}}</option>
                            <option value="contact">{{localization.dictionary.contact.nameSingular}}</option>
                            <option value="call">{{localization.dictionary.call.nameSingular}}</option>
                        </select>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-select-container>
                </form>
                <!-- <div *ngIf="mode === 'create'" class="card-text icon-offset modeChoice">
                <button (click)="filterFields('customer')"
                    [ngStyle]="{'background-color': (view.type === 'customer') ? 'green' : '' }">Kunde</button>
                <button (click)="filterFields('contact')"
                    [ngStyle]="{'background-color': (view.type === 'contact') ? 'green' : '' }">Ansprechpartner</button>
                <button (click)="filterFields('call')"
                    [ngStyle]="{'background-color': (view.type === 'call') ? 'green' : '' }">Besuche/Call</button>
            </div> -->
            </div>
        </div>
        <div class="card-block card-cutout">
            <div cdkDropListGroup style="display: flex; width: 100%;">
                <div class="card fieldsContainer" *ngIf="!loading">
                    <div class="card-header">
                        {{localization.dictionary.field.name}}
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                        </div>
                        <div class="card-text" style="max-height: 80vh; overflow-y: auto;">
                            <div cdkDropList [cdkDropListData]="filteredFields" cdkDropListSortingDisabled class="filterList"
                                [ngStyle]="{'border': (filteredFields.length === 0) ? 'solid 1px black;' : 'none'}"
                                (cdkDropListDropped)="drop($event)">
                                <div  class="filterBox" *ngFor="let field of filteredFields" [ngStyle]="{'border' : (field.display && this.missingDV) ? '1px solid var(--clr-forms-invalid-color, #c21d00)' : 'none'}" cdkDrag>
                                    <div class="cdk-placeholder" *cdkDragPlaceholder></div>
                                    {{ field.display ? '*' : ''}} {{ field.label }} {{ field.display && missingDV ? '(' + localization.dictionary.fieldCreateEdit.displayValue + ')' : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 5%"></div>
                <div style="padding: 0 0.5rem 0 0.5rem; flex-grow: 1;">
                    <div *ngFor="let section of sections; let i = index">
        
                        <div class="clr-row">
                            <div class="card" [ngClass]="(editNumber === i) ? 'active' : 'inactive'">
                                <div class="card-header space-between-flex">
                                    <div *ngIf="editNumber != i" style="padding-bottom: 0.01rem;">
                                        <div>{{section.name}}</div>
                                        <div class="sectionErrorSub" *ngIf="(section.invalidName === true)">
                                            {{localization.dictionary.createEdit.requiredInput}}
                                        </div>
                                    </div>
                                    <div *ngIf="editNumber === i">
                                        <div>
                                            <input class="sectionInputField" class="clr-input" [(ngModel)]="section.name"
                                                (ngModelChange)="section.invalidName = false; dirty = true"
                                                [ngClass]="(section.invalidName === true) ? 'section-error' : ''" name="text"
                                                style="font-size: .9rem; margin-top: 0; padding-left: 0;" 
                                                maxlength="100">
                                            <clr-icon *ngIf="(section.invalidName === true)" shape="exclamation-circle"
                                                style="width: 0.9rem; height: auto; color: #f55047"></clr-icon>
                                        </div>
                                        <div class="sectionErrorSub" *ngIf="(section.invalidName === true)">{{localization.dictionary.createEdit.requiredInput}}
                                        </div>
                                    </div>
        
                                    <div class="iconwrapper">
                                        <clr-icon *ngIf="i != 0"
                                            shape="circle-arrow" 
                                            class="icon-style" 
                                            (click)="moveSection(-1, i)"
                                            [pTooltip]="localization.dictionary.viewCreateEdit.moveSectionUp"
                                            [tooltipPosition]="'left'">
                                        </clr-icon>
                                    
                                        <clr-icon *ngIf="i != (sections.length-1)"
                                            shape="circle-arrow" 
                                            dir="down" 
                                            class="icon-style"
                                            (click)="moveSection(1, i)"
                                            [pTooltip]="localization.dictionary.viewCreateEdit.moveSectionDown"
                                            [tooltipPosition]="'left'">
                                        </clr-icon>
        
                                        <clr-icon *ngIf="editNumber != i"
                                            shape="pencil" 
                                            class="icon-style"
                                            (click)="editMode(i)"
                                            [pTooltip]="localization.dictionary.viewCreateEdit.editSection"
                                            [tooltipPosition]="'left'">
                                        </clr-icon>
        
                                        <clr-icon *ngIf="editNumber === i"
                                            shape="check"
                                            class="icon-style"
                                            (click)="update(section)"
                                            [pTooltip]="localization.dictionary.viewCreateEdit.saveSection"
                                            [tooltipPosition]="'left'">
                                        </clr-icon>
                                        
                                        <clr-icon *ngIf="sections.length > 1"
                                            shape="trash"
                                            class="icon-style"
                                            (click)="deleteSection(i)"
                                            [pTooltip]="localization.dictionary.viewCreateEdit.deleteSection"
                                            [tooltipPosition]="'left'">
                                        </clr-icon>
        
                                        <clr-icon shape="plus-circle"
                                            class="icon-style"
                                            (click)="newSection(i)"
                                            [pTooltip]="localization.dictionary.viewCreateEdit.createNewSection"
                                            [tooltipPosition]="'left'">
                                        </clr-icon>
                                    </div>
                                </div>
                                <div class="card-block">
                                    <h5 class="card-title"></h5>
                                    <div class="card-text">
                                        <form clrForm #formData="ngForm" (change)="dirty = true">
                                            <clr-combobox-container  style="flex-wrap: nowrap; margin-top: 0 !important;">
                                                <label fxLayout="row" fxLayoutAlign="space-between center">{{localization.dictionary.createEdit.icon}}*<clr-icon *ngIf="section.currentIcon" [attr.shape]="section.currentIcon?.element"></clr-icon></label>
                                                <clr-combobox #combobox [(ngModel)]="section.currentIcon" name="icon" (clrInputChange)="checkValid(section, $event, combobox)" required>
                                                    <clr-options>
                                                        <clr-option *clrOptionItems="let icon of localization.amselIconList; field: 'label'" [clrValue]="icon">
                                                            <clr-icon [attr.shape]="icon.element" role="img"></clr-icon>
                                                            <span style="margin-left: 0.2rem">{{ icon.label }}</span>
                                                        </clr-option>
                                                    </clr-options>
                                                </clr-combobox>
                                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                            </clr-combobox-container>
                                            <clr-textarea-container style="flex-wrap: nowrap;">
                                                <label>{{localization.dictionary.general.description}}*</label>
                                                <textarea clrTextarea [(ngModel)]="section.description" name="description"
                                                    required maxlength="250"></textarea>
                                                <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}
                                                </clr-control-error>
                                            </clr-textarea-container>
                                        </form>
                                    </div>
                                </div>
                                <div class="card-block">
                                    <div cdkDropList [cdkDropListData]="section.fields" class="filterList"
                                        (cdkDropListDropped)="drop($event, section)"
                                        [ngStyle]="{'border': (section.fields.length === 0 && section.invalid === false) ? 'solid 1px black;' : (section.invalid === true ? 'solid 1px #f55047' : 'none')}">
                                        <div class="filterBox" *ngFor="let field of section.fields" cdkDrag>
        
                                            <div class="cdk-placeholder" *cdkDragPlaceholder></div>
                                            <div class="fieldBox">
                                                {{ field.display ? '*' : ''}} {{field.label }}
                                                <div class="iconwrapper">
                                                    <div *ngIf="field.SectionField.mandatory != true && field.type != 'BOOLEAN' && !field.display" class="tooltip-container">
                                                        <clr-icon shape="asterisk"
                                                            class="icon-style"
                                                            (click)="field.SectionField.mandatory = true"
                                                            [pTooltip]="localization.dictionary.viewCreateEdit.setFieldAsRequired"
                                                            [tooltipPosition]="'left'">
                                                        </clr-icon>
                                                    </div>
        
                                                    <div *ngIf="field.SectionField.mandatory === true || field.display" class="tooltip-container">
                                                        <clr-icon shape="asterisk"
                                                            class="icon-style is-solid"
                                                            [ngStyle]="field.display ? { 'opacity': '0.7', 'cursor': 'default' } : ''"
                                                            (click)="!field.display ? field.SectionField.mandatory = false : ''"
                                                            [pTooltip]="field.display ? localization.dictionary.viewCreateEdit.displayValuesAlwaysRequired : localization.dictionary.viewCreateEdit.setFieldAsOptional"
                                                            [tooltipPosition]="'left'">
                                                        </clr-icon>
                                                    </div>
        
                                                    <div *ngIf="field.SectionField.readOnly != true || field.display" class="tooltip-container">
                                                        <clr-icon shape="unlock"
                                                            class="icon-style"
                                                            [ngStyle]="field.display ? { 'opacity': '0.7', 'cursor': 'default' } : ''"
                                                            (click)="!field.display ? field.SectionField.readOnly = true : ''"
                                                            [pTooltip]="field.display ? localization.dictionary.viewCreateEdit.displayValuesNotReadOnly : localization.dictionary.viewCreateEdit.setFieldReadOnly"
                                                            [tooltipPosition]="'left'">
                                                        </clr-icon>
                                                    </div>
                                                    
                                                    <div *ngIf="field.SectionField.readOnly === true && !field.display" class="tooltip-container">
                                                        <clr-icon shape="lock"
                                                            class="icon-style"
                                                            (click)="field.SectionField.readOnly = false"
                                                            [pTooltip]="localization.dictionary.viewCreateEdit.resetWriteProtection"
                                                            [tooltipPosition]="'left'">
                                                        </clr-icon>
                                                    </div>
        
                                                    <div class="tooltip-container">
                                                        <clr-icon shape="times-circle"
                                                            class="icon-style"
                                                            (click)="removeField(section, field)"
                                                            [pTooltip]="localization.dictionary.viewCreateEdit.removeFieldFromSection"
                                                            [tooltipPosition]="'left'">
                                                        </clr-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sectionErrorSub" *ngIf="(section.invalid === true)">
                                        {{localization.dictionary.viewCreateEdit.oneFieldPerSection}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div stickyFooter class="card-footer">
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>
        </div>
    </div>
</div>

<ng-template #actionBar>
    <button class="btn btn-primary" (click)="validateAndSave()">{{ ( mode === 'create' ) ? localization.dictionary.button.create :
        localization.dictionary.button.save }}</button>
    <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
</ng-template>

<div class="modal" *ngIf="typeModal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" type="button"
                    (click)="view.type = typeChoice; typeModal = false;">
                    <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
                <h3 class="modal-title">{{localization.dictionary.viewCreateEdit.confirmTypeChange}}</h3>
            </div>
            <div class="modal-body">
                <p>{{localization.dictionary.viewCreateEdit.warningTypeChange}}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline" type="button"
                    (click)="view.type = typeChoice; typeModal = false;">{{localization.dictionary.button.cancel}}</button>
                <button class="btn btn-primary" type="button" (click)="newType()">{{localization.dictionary.button.ok}}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop" *ngIf="typeModal" aria-hidden="true"></div>


<app-spinner *ngIf="loading"></app-spinner>
