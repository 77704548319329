import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../localization/localization.service';
import { UserHelpService } from '../user-help.service';

@Component({
  selector: 'app-help-start',
  templateUrl: './help-start.component.html',
  styleUrls: ['./help-start.component.css']
})
export class HelpStartComponent implements OnInit {

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
  }
}
