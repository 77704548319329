import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { plainToClass } from 'class-transformer';
import { AuthService } from 'src/app/auth/auth.service';
import { AmselError } from 'src/app/helper/error/amsel-error.model';
import { HelperService } from 'src/app/helper/helper.service';
import { LocalizationService } from 'src/app/localization/localization.service';
import { ServerService } from 'src/app/server.service';
import { CrmService } from '../../crm.service';
import { Conflict } from '../conflict.model';
import { Call } from '../../calls/call.model';
import { Contact } from '../../contacts/contact.model';
import { Customer } from '../../customers/customer.model';
import { View } from '../../views/view.model';

@Component({
  selector: 'app-list-conflicts',
  templateUrl: './list-conflicts.component.html',
  styleUrls: ['./list-conflicts.component.css']
})
export class ListConflictsComponent implements OnInit {
  callType = Call;
  contactType = Contact;
  customerType = Customer;

  loading = true;
  conflicts: Conflict[];
  selected: Conflict[] = [];
  toDelete: Conflict[];
  total = 0;
  order = false;
  sorting = {};
  filters = {};
  hidden = {
    id: true,
    createdAt: true,
    updatedAt: true
  }
  state: ClrDatagridStateInterface;
  exportState: ClrDatagridStateInterface;

  showDeleted = false;
  columnChange = false;
  columnChanged = false;

  callViews: View[] = [];
  contactViews: View[] = [];
  customerViews: View[] = [];
  callView: View;
  contactView: View;
  customerView: View;

  @ViewChild('datagridRef') datagrid: ClrDatagrid;

  constructor(public helper: HelperService,
    public crm: CrmService,
    public auth: AuthService,
    public server: ServerService,
    public router: Router,
    public localization: LocalizationService) { }

  async ngOnInit(): Promise<void> {
    const filter = localStorage.getItem('call-filters');
    const sorting = localStorage.getItem('call-sorting');
    const hidden = localStorage.getItem('call-columns');
    if (filter) {
      this.filters = JSON.parse(filter)
    }
    if (sorting) {
      this.sorting = JSON.parse(sorting)
    }
    if (hidden) {
      this.hidden = JSON.parse(hidden)
    }
    this.callViews = this.crm.views.call;
    this.callView = this.crm.activeViews.call;
    this.contactViews = this.crm.views.contact;
    this.contactView = this.crm.activeViews.contact;
    this.customerViews = this.crm.views.customer;
    this.customerView = this.crm.activeViews.customer;
    if (!this.callView) {
      this.callView = this.callViews[0];
    }
    if (!this.contactView) {
      this.contactView = this.contactViews[0];
    }
    if (!this.customerView) {
      this.customerView = this.customerViews[0];
    }
  }

  ngOnDestroy() {
    localStorage.setItem('call-filters', JSON.stringify(this.filters));
    localStorage.setItem('call-sorting', JSON.stringify(this.sorting));
    localStorage.setItem('call-columns', JSON.stringify(this.hidden));
    if (this.showDeleted)
      localStorage.setItem('call-deleted', 'true')
    else
      localStorage.removeItem('call-deleted')
  }

  async refresh(state?: ClrDatagridStateInterface) {
    if (!state) {
      state = this.datagrid['stateProvider'].state;
    }
    this.state = state
    this.loading = true;
    let query = this.server.buildQueryFromGrid(state);
    const conflict = await this.server.get('crm/conflict/' + (this.showDeleted ? 'all' : '') + query);
    this.total = conflict.count;
    this.conflicts = plainToClass(Conflict, conflict.rows);
    this.loading = false;
  }

  selectionChange(conflicts: Conflict[]) {
    if (conflicts.length > 0) {
      this.order = false;
    }
  }

  hidChange() {
    if (this.columnChanged)
      return
    this.columnChange = true;
    this.columnChanged = true;
    setTimeout(() => {
      this.columnChange = false
    })
  }

  async delete() {
    this.loading = true;
    const conflictIds = this.toDelete.map((conflict) => conflict.id);
    await this.server.delete('crm/conflict?toDelete=' + conflictIds);
    this.server.addAlert(new AmselError(undefined, 'success', this.localization.dictionary.toastr.successDeleted.replace("${componentName}",
      this.toDelete.length == 1 ? this.localization.dictionary.conflict.nameSingular : this.localization.dictionary.conflict.name)));
    this.toDelete = undefined;
    this.selected = [];
    this.refresh();
  }

  resetFilters() {
    this.filters = {};
    this.sorting = {};
    const sortedColumn = this.datagrid.columns.find(column => column.sorted)
    if (sortedColumn) {
      sortedColumn.sorted = false;
    }
    delete this.state.filters;
    this.refresh(this.state);
  }

  async toggleDeleted() {
    this.selected = []
    await this.refresh();
  }


}
