
<p>Im Bereich Produkte werden alle Produkte des Unternehmens erfasst und verwaltet.</p>
<h4>Neues Produkt erstellen</h4>
<ol class="list">
    <li>Um ein neues Produkt anzulegen, klicken Sie im Menu auf „CRM“ und wählen den Punkt „Produkte“ aus. Dann klicken Sie auf den Button „Produkt hinzufügen“.</li>
    <li>Es öffnet sich ein Eingabefenster.</li>
</ol>
<h4>Produkt bearbeiten</h4>
<ol class="list">
    <li>Klicken Sie im Menü unter dem Punkt CRM auf „Produkte“.</li>
    <li>Wählen Sie in der Übersicht das Produkt aus, das Sie bearbeiten wollen, in dem Sie in die entsprechende Zeile klicken. Alternativ können Sie auch auf das Stift-Symbol am Ende der Zeile klicken.</li>
    <li>Es öffnet sich die Eingabemaske.</li>