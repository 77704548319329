<clr-tabs>
    <clr-tab>
        <button clrTabLink>{{localization.dictionary.call.name}}</button>
        <clr-tab-content>
            <div class="card" *ngIf="!loading">
                <div class="card-header space-between-flex">
                    {{ helper.getCardHeader(mode, 'call', name) }}
                    <clr-dropdown>
                        <button class="dropdown-toggle" clrDropdownTrigger>
                            <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                            <clr-icon shape="caret down" size="20"></clr-icon>
                        </button>
                        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                            <label class="dropdown-header" aria-hidden="true">{{localization.dictionary.view.name}}</label>
                            <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                            <div *ngFor="let view of views" (click)="activeView = view; crm.activeViewChanged(view, 'call')"
                                clrDropdownItem>
                                <clr-icon [ngStyle]="{'visibility': (view === activeView) ? 'visible' : 'hidden' }"
                                    style="margin-right: 0.3rem" shape="check"></clr-icon>
                                {{ view.name }}
                            </div>
                        </clr-dropdown-menu>
                    </clr-dropdown>
                </div>
                <div class="card-block" *ngFor="let section of activeView?.sections">
                    <div class="card-title collapse-card">
                        <span>
                            <clr-icon [attr.shape]="section.icon" class="clr-icon-heading" role="img"></clr-icon>
                            <span>{{ section.name }}</span>
                            <p class="card-text icon-offset">
                                {{ section.description }}
                            </p>
                        </span>
                        <clr-icon shape="angle" class="arrow" [dir]="!collapse[section['id']] ? 'down' : 'left'"
                            (click)="collapse[section['id']] = !collapse[section['id']]"></clr-icon>
                    </div>
                    <div [hidden]="collapse[section['id']]" class="card-text">
                        <app-custom-input [model]="call" [fields]="section.fields" (change)="dirty = true"
                            (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)"
                            [conflicts]="conflicts">
                        </app-custom-input>
                    </div>
                </div>
    
                <div class="card-block">
                    <div class="card-title collapse-card">
                        <span>
                            <clr-icon shape="employee-group" class="clr-icon-heading"></clr-icon>
                            <span>{{localization.dictionary.callCreateEdit.customerContact}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.callCreateEdit.contactPerson}}
                            </p>
                        </span>
                        <clr-icon shape="angle" class="arrow" [dir]="!collapse['customer'] ? 'down' : 'left'"
                            (click)="collapse['customer'] = !collapse['customer']"></clr-icon>
                    </div>
                    <div [hidden]="collapse['customer']" class="card-text">
                        <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                            <clr-combobox-container>
                                <label> {{localization.dictionary.customer.nameSingular}}*</label>
    
                                <clr-combobox #customerbox [ngModel]="selectedCustomerList"
                                    (ngModelChange)="customerbox.searchText = ''; customerChanged($event)" name="customer"
                                    [required]="callRequired" [clrLoading]="comboLoading"
                                    (clrInputChange)="customerQueryChanged.next($event)"
                                    (clrOpenChange)="customerQueryChanged.next('')" clrMulti="true">
                                    <ng-container *clrOptionSelected="let selected">
                                        <app-display-name style="cursor: pointer;" [model]="selected" [prePostFix]="''"
                                            (click)="router.navigate(['/crm/customer/edit/' + selected.id])"></app-display-name>
                                    </ng-container>
                                    <clr-options>
                                        <clr-option *ngFor="let customer of customers" [clrValue]="customer">
                                            <app-display-name [model]="customer" [prePostFix]="''"></app-display-name>
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
    
                                <clr-control-helper *ngIf="customerConflict">
                                    <div></div>
                                    <clr-radio-container class="conflict">
                                        <clr-radio-wrapper>
                                            <input type="radio" required clrRadio
                                                [value]="call.customer + 'customerconflict' + 1"
                                                [name]="call.customer + 'customerconflict'" ngModel
                                                (ngModelChange)="onConflictChange(tempCustomer, false)" />
                                            <label>
                                                <app-display-name
                                                    [model]="displayCustomerConflict(tempCustomer)"></app-display-name>
                                                (aktuell)</label>
                                        </clr-radio-wrapper>
                                        <clr-radio-wrapper>
                                            <input type="radio" required clrRadio
                                                [value]="call.customer + 'customerconflict' + 2"
                                                [name]="call.customer + 'customerconflict'" ngModel
                                                (ngModelChange)="onConflictChange(customerConflict.value, true)" />
                                            <label>
                                                <app-display-name [model]="displayCustomerConflict(customerConflict.value)"
                                                    [prePostFix]="''"></app-display-name>
                                                ({{customerConflict.user?.name}},
                                                {{customerConflict.date | date: localization.dictionary.dateFormat.full :
                                                undefined : localization.language.selector }})</label>
                                        </clr-radio-wrapper>
                                        <clr-control-error
                                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                    </clr-radio-container>
                                </clr-control-helper>
                                <clr-control-error
                                    *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
    
                            <app-list-selector *ngIf="call.customer && selectedCustomer" [property]="'contacts'"
                                [query]="'crm/contact/filter?customers.id='+selectedCustomer.id"
                                [conflict]="!customerConflict ? contactConflict : null" [displayFields]="crm.displayFields.contact" [attrToMatch]="'id'"
                                (selectedChanged)="call.contacts= $event" [preLoaded]=" !conflictAccepted
                          || !contactConflict ? initialContact : contactConflict.value"
                                [label]="localization.dictionary.contact.name" required="true"
                                (customForms)="addCustomForms($event)" (customFormDatas)="addCustomFormDatas($event)">
                            </app-list-selector>
                        </form>
                    </div>
                </div>
                <div class="card-block">
                    <div class="card-title collapse-card">
                        <span>
                            <clr-icon shape="view-cards" class="clr-icon-heading"></clr-icon>
                            <span>{{localization.dictionary.callCreateEdit.productsDocuments}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.callCreateEdit.presentedDocsProducts}}
                            </p>
                        </span>
                        <clr-icon shape="angle" class="arrow" [dir]="!collapse['product'] ? 'down' : 'left'"
                            (click)="collapse['products'] = !collapse['products']"></clr-icon>
                    </div>
                    <div [hidden]="collapse['products']" class="card-text ">
                        <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                            <clr-combobox-container>
                                <label>{{localization.dictionary.product.name}}</label>
                                <clr-combobox #prodbox (ngModelChange)="prodbox.searchText = ''; onChangeProduct($event)"
                                    [clrLoading]="comboLoading" [(ngModel)]="call.products" name="products" clrMulti="true"
                                    (clrInputChange)="getProducts($event)" (clrOpenChange)="$event ? getProducts() : null">
                                    <ng-container *clrOptionSelected="let selected">
                                        {{selected?.name}}
                                    </ng-container>
                                    <clr-options>
                                        <clr-option *clrOptionItems="let product of products" [clrValue]="product">
                                            {{ product.name }}
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
                                <clr-control-helper *ngIf="productConflict">
                                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                                    <clr-radio-container class="conflict">
                                        <clr-radio-wrapper>
                                            <input type="radio" required clrRadio [value]="'productconflict' + 1"
                                                [name]="'productconflict'" ngModel
                                                (ngModelChange)="onConflictChangeByProduct(call.products, false, 'model')" />
                                            <label>{{ displayProductConflict(tempProducts)}}
                                                ({{localization.dictionary.createEdit.current}})</label>
                                        </clr-radio-wrapper>
                                        <clr-radio-wrapper>
                                            <input type="radio" required clrRadio [value]="'productconflict' + 2"
                                                [name]="'productconflict'" ngModel
                                                (ngModelChange)="onConflictChangeByProduct(call.products, false, 'conflict')" />
                                            <label>{{ displayProductConflict(conflictCall.products) }}
                                                ({{productConflict.user.name}},
                                                {{productConflict.date | date: localization.dictionary.dateFormat.full :
                                                undefined : localization.language.selector }})</label>
                                        </clr-radio-wrapper>
                                        <clr-control-error
                                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                    </clr-radio-container>
                                </clr-control-helper>
                                <clr-control-error
                                    *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
    
                            <app-list-selector *ngIf="call.products && call.products.length > 0"
                                [conflict]="!productConflict ? documentConflict : null" #documentList
                                [query]="'document/byProductIds?ids='+documentQuery(call.products)" [attrToShow]="'name'"
                                (selectedChanged)="call.documents= $event"
                                [preLoaded]="call.documents ||  !conflictAccepted || !documentConflict ? initialDocument: documentConflict.value"
                                [label]="localization.dictionary.document.name" (customForms)="addCustomForms($event)"
                                (customFormDatas)="addCustomFormDatas($event)">
                            </app-list-selector>
    
                        </form>
                    </div>
                </div>
                <div class="card-block">
                    <div class="card-title collapse-card">
                        <span>
                            <clr-icon shape="users" class="clr-icon-heading"></clr-icon>
                            <span>{{localization.dictionary.user.name}}</span>
                            <p class="card-text icon-offset">
                                {{localization.dictionary.callCreateEdit.listOfUserByCall}}
                            </p>
                        </span>
                        <clr-icon shape="angle" class="arrow" [dir]="!collapse['users'] ? 'down' : 'left'"
                            (click)="collapse['users'] = !collapse['users']"></clr-icon>
                    </div>
                    <div [hidden]="collapse['users']" class="card-text">
                        <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                            <clr-combobox-container>
                                <label> {{localization.dictionary.user.name}}*</label>
                                <clr-combobox #userbox (ngModelChange)="userbox.searchText = ''; onChangeUser($event)"
                                    required [clrLoading]="comboLoading" [(ngModel)]="call.users" name="multiSelected"
                                    clrMulti="true" (clrInputChange)="getUsers($event)"
                                    (clrOpenChange)="$event ? getUsers() : null">
                                    <ng-container *clrOptionSelected="let selected">
                                        {{selected.name}}
                                    </ng-container>
                                    <clr-options>
                                        <clr-option *clrOptionItems="let user of users" [clrValue]="user">
                                            {{ user.name }}
                                        </clr-option>
                                    </clr-options>
                                </clr-combobox>
                                <clr-control-helper *ngIf="userConflict">
                                    <div>{{localization.dictionary.conflict.manageConflict}}</div>
                                    <clr-radio-container class="conflict">
                                        <clr-radio-wrapper>
                                            <input type="radio" required clrRadio [value]="'userconflict' + 1"
                                                [name]="'userconflict'" ngModel
                                                (ngModelChange)="onConflictChangeByUser(call.products, false, 'model')" />
                                            <label>{{ displayUserConflict(tempUsers)}}
                                                ({{localization.dictionary.createEdit.current}})</label>
                                        </clr-radio-wrapper>
                                        <clr-radio-wrapper>
                                            <input type="radio" required clrRadio [value]="'userconflict' + 2"
                                                [name]="'userconflict'" ngModel
                                                (ngModelChange)="onConflictChangeByUser(call.products, false, 'conflict')" />
                                            <label>{{ displayUserConflict(conflictCall.users) }}
                                                ({{userConflict.user.name}},
                                                {{userConflict.date | date: localization.dictionary.dateFormat.full :
                                                undefined : localization.language.selector }})</label>
                                        </clr-radio-wrapper>
                                        <clr-control-error
                                            *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                                    </clr-radio-container>
                                </clr-control-helper>
                                <clr-control-error
                                    *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                            </clr-combobox-container>
                        </form>
                    </div>
                </div>
                <div stickyFooter class="card-footer">
                    <ng-container *ngTemplateOutlet="actionBar"></ng-container>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab *ngIf="mode == 'edit' && call">
        <button clrTabLink>{{ localization.dictionary.file.name}}</button>
        <clr-tab-content *clrIfActive="fileUploadActive">
            <div class="card">
                <div class="card-header">{{ helper.getCardHeader(mode, 'call', name) }}</div>
                <div class="card-block">
                    <div class="card-title">
                        <clr-icon shape="file-group" class="clr-icon-heading is-solid"></clr-icon>
                        <span>{{ localization.dictionary.file.linked }}</span>
                        <p class="card-text icon-offset">
                            {{ localization.dictionary.file.linkedAddition.replace('${mode}', localization.dictionary.call.dative) }}
                        </p>
                    </div>
                    <div class="card-text">
                        <app-file-upload-list [mode]="'call'" [callId]="callIdHelper"></app-file-upload-list>
                    </div>
                </div>
            </div>
            <div stickyFooter class="card-footer">
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab *ngIf="mode == 'edit' && call">
        <button clrTabLink>{{ localization.dictionary.auditLogging.auditLogPlural}}</button>
        <clr-tab-content *clrIfActive="auditActive">
            <div class="card">
                <div class="card-header">{{ localization.dictionary.auditLogging.changeHistory }}</div>
                <div class="card-block">
                    <app-audit [entityId]="call.id" [entityType]="'Call'"></app-audit>
                </div>
            </div>
            <div stickyFooter class="card-footer">
                <ng-container *ngTemplateOutlet="actionBar"></ng-container>
            </div>
        </clr-tab-content>
    </clr-tab>

    <app-spinner *ngIf="loading"></app-spinner>

    <ng-template #actionBar>
        <button class="btn btn-primary" (click)="validateAndSave()">{{ ( mode === 'create' ) ?
            localization.dictionary.button.create :
            localization.dictionary.button.save }}</button>
        <button class="btn btn-primary" (click)="validateAndSave(true)">{{ ( mode === 'create' ) ?
            localization.dictionary.button.createAndStay :
            localization.dictionary.button.saveAndStay }}</button>
        <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
    </ng-template>

</clr-tabs>

