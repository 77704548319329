<div >
    <div class="card" *ngIf="!loading">
        <div class="card-header">
            {{ helper.getCardHeader(mode, 'territory', territory.name) }}
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                   <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.baseInformation}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.territoryCreateEdit.infoTerritoryCreation}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                (click)="collapse[0] = !collapse[0]"></clr-icon>
            </div>
            <div [hidden]="collapse[0]" class="card-text">
                <form clrForm (change)="dirty = true" #formData="ngForm" class="icon-offset">
                    <clr-input-container>
                        <label>{{localization.dictionary.general.name}}*</label>
                        <input clrInput type="text" [(ngModel)]="territory.name" name="name" required maxlength="100" />
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{localization.dictionary.general.description}}</label>
                        <input clrInput type="text" [(ngModel)]="territory.description" name="description" maxlength="150" />
                    </clr-input-container>
                    <clr-combobox-container>
                        <label>{{localization.dictionary.territory.parentTerritory}}</label>
                        <clr-combobox [(ngModel)]="territory.parent" name="territory">
                            <clr-options>
                                <clr-option *clrOptionItems="let territory of possibleParents; field:'name'"
                                    [clrValue]="territory">
                                    <div 
                                        [pTooltip]="territory.description" 
                                        [tooltipPosition]="'right'">
                                        {{ territory.name }}
                                    </div>
                                </clr-option>
                            </clr-options>
                        </clr-combobox>
                        <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
                    </clr-combobox-container>
                </form>
            </div>
        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                   <clr-icon shape="user" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.user.name)}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.territoryCreateEdit.assignUsersToTerritory}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[1] ? 'down' : 'left'"
                (click)="collapse[1] = !collapse[1]"></clr-icon>
            </div>
            <div [hidden]="collapse[1]" class="card-text icon-offset">
                <app-list-selector [excludeAdmin]="true" (selectedChanged)="territory.users= $event"
                    [preLoaded]="territory.users" [label]="localization.dictionary.user.name" [query]="'user/filter?sortBy=name'">
                </app-list-selector>
            </div>

        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                     <clr-icon shape="users" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.group.name)}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.territoryCreateEdit.assignGroupsToTerritory}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[2] ? 'down' : 'left'"
                (click)="collapse[2] = !collapse[2]"></clr-icon>
            </div>
            <div [hidden]="collapse[2]" class="card-text icon-offset">
                <app-list-selector [excludeAdmin]="true" (selectedChanged)="territory.groups= $event"
                    [preLoaded]="territory.groups" [label]="localization.dictionary.group.name" [query]="'group'">
                </app-list-selector>
            </div>

        </div>
        <div class="card-block">
            <div class="card-title collapse-card">
                <span>
                    <clr-icon shape="user" class="clr-icon-heading"></clr-icon>
                <span>{{localization.dictionary.territoryCreateEdit.assignSubordinateTerritory}}</span>
                <p class="card-text icon-offset">
                    {{localization.dictionary.territoryCreateEdit.selectTerritoryToSubordinate}}
                </p>
                </span>
                <clr-icon shape="angle" class="arrow" [dir]="!collapse[3] ? 'down' : 'left'"
                (click)="collapse[3] = !collapse[3]"></clr-icon>
            </div>
            <div [hidden]="collapse[3]" class="card-text icon-offset">
                <app-list-selector (selectedChanged)="territory.children = $event; this.loadParents()"
                    [preLoaded]="territory.children" [label]="localization.dictionary.territory.name"
                    [query]="'crm/territory/children/' + this.territory.parent?.id + '/' + this.territory.id">
                </app-list-selector>
            </div>
        </div>
        <div stickyFooter class="card-footer">
            <ng-container *ngTemplateOutlet="actionBar"></ng-container>
        </div>
    </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>

<ng-template #actionBar>
    <button class="btn btn-primary" (click)="validateAndSave()">{{ ( mode === 'create' ) ? localization.dictionary.button.create :
        localization.dictionary.button.save }}</button>
    <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
</ng-template>
