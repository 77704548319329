import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren, ViewContainerRef } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';
import { Topic } from '../topic.model';
import { UserHelpService } from '../user-help.service';
import { DeFormsCreateComponent } from '../german/de-forms-create/de-forms-create.component';
import { DeCatCreateComponent } from '../german/de-cat-create/de-cat-create.component';
import { DeCatEditComponent } from '../german/de-cat-edit/de-cat-edit.component';
import { DeCatLinkComponent } from '../german/de-cat-link/de-cat-link.component';
import { NavigationEnd, Router } from '@angular/router';
import { DeCatComponent } from '../german/de-cat/de-cat.component';
import { DeListComponent } from '../german/de-list/de-list.component';
import { DeHelpNotfoundComponent } from '../german/de-help-notfound/de-help-notfound.component';
import { DeActionsComponent } from '../german/de-actions/de-actions.component';
import { DeActionsEditComponent } from '../german/de-actions-edit/de-actions-edit.component';
import { DeActionsDeleteComponent } from '../german/de-actions-delete/de-actions-delete.component';
import { DeDocumentComponent } from '../german/de-document/de-document.component';
import { DeActionsDownloadComponent } from '../german/de-actions-download/de-actions-download.component';
import { DeActionsLinkComponent } from '../german/de-actions-link/de-actions-link.component';
import { DeActionsUnlinkComponent } from '../german/de-actions-unlink/de-actions-unlink.component';
import { DeDocumentCreateComponent } from '../german/de-document-create/de-document-create.component';
import { DeActionsOrderComponent } from '../german/de-actions-order/de-actions-order.component';
import { DeFormsComponent } from '../german/de-forms/de-forms.component';
import { DeActionsShowresultsComponent } from '../german/de-actions-showresults/de-actions-showresults.component';
import { DeActionsDeactivateComponent } from '../german/de-actions-deactivate/de-actions-deactivate.component';
import { DeUserComponent } from '../german/de-user/de-user.component';
import { DeUserCreateComponent } from '../german/de-user-create/de-user-create.component';
import { DeGroupsComponent } from '../german/de-groups/de-groups.component';
import { DeGroupsCreateComponent } from '../german/de-groups-create/de-groups-create.component';
import { DePushComponent } from '../german/de-push/de-push.component';
import { DeLanguageComponent } from '../german/de-language/de-language.component';
import { DeLanguageCreateComponent } from '../german/de-language-create/de-language-create.component';
import { DeActionsUploadComponent } from '../german/de-actions-upload/de-actions-upload.component';
import { DeBrandingComponent } from '../german/de-branding/de-branding.component';
import { DePushCreateComponent } from '../german/de-push-create/de-push-create.component';
import { DeCustomerComponent } from '../german/de-customer/de-customer.component';
import { DeCustomerCreateComponent } from '../german/de-customer-create/de-customer-create.component';
import { DeContactComponent } from '../german/de-contact/de-contact.component';
import { DeContactCreateComponent } from '../german/de-contact-create/de-contact-create.component';
import { DeCallComponent } from '../german/de-call/de-call.component';
import { DeCallCreateComponent } from '../german/de-call-create/de-call-create.component';
import { DeCallEditComponent } from '../german/de-call-edit/de-call-edit.component';
import { DeProductComponent } from '../german/de-product/de-product.component';
import { DeProductCreateComponent } from '../german/de-product-create/de-product-create.component';
import { DeProductEditComponent } from '../german/de-product-edit/de-product-edit.component';
import { DeTerritoryComponent } from '../german/de-territory/de-territory.component';
import { DeTerritoryCreateComponent } from '../german/de-territory-create/de-territory-create.component';
import { DeCustomerEditComponent } from '../german/de-customer-edit/de-customer-edit.component';
import { DeContactEditComponent } from '../german/de-contact-edit/de-contact-edit.component';
import { DeTerritoryEditComponent } from '../german/de-territory-edit/de-territory-edit.component';
import { DeConflictComponent } from '../german/de-conflict/de-conflict.component';
import { DeFieldComponent } from '../german/de-field/de-field.component';
import { DeFieldCreateComponent } from '../german/de-field-create/de-field-create.component';
import { DeViewComponent } from '../german/de-view/de-view.component';
import { DeViewCreateComponent } from '../german/de-view-create/de-view-create.component';
import { DeViewEditComponent } from '../german/de-view-edit/de-view-edit.component';
import { DeActionsAddComponent } from '../german/de-actions-add/de-actions-add.component';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-show-topic',
  templateUrl: './show-topic.component.html',
  styleUrls: ['../help.css', './show-topic.component.css']
})
export class ShowTopicComponent implements OnInit {
  @ViewChildren('container', { read: ViewContainerRef }) viewContainerRefs: QueryList<ViewContainerRef>;
  
  /**
   * Neue Hilfen müssen hier ergänzt werden. 
   * Der Key ist der Selector der aktiven Komponente, der übergeben oder aus der URL erstellt wird.
   * Das Value ist ein Topic-Objekt, das den Anzeigetitel des Topics, die zugehörigen Komponenten und den Selector enthält.
   * Aus dem Selector werden später verwandte Themen generiert, es macht also Sinn den Selector dem Key gleich zu setzen.
   */
  topics = {
    'de-forms-list': new Topic('Formulare', [DeFormsComponent, DeActionsAddComponent, DeActionsComponent, DeActionsShowresultsComponent, DeActionsEditComponent, DeActionsDeactivateComponent, DeActionsDeleteComponent, DeListComponent], 'de-forms-list', ['formActive']),
    'de-forms-create': new Topic('Formular erstellen', [DeFormsCreateComponent], 'de-forms-create', ['formActive']),
    'de-forms-edit': new Topic('Formular bearbeiten', [DeFormsCreateComponent], 'de-forms-edit', ['formActive']),
    'de-help-notfound': new Topic('Keine seitenspezifische Hilfe gefunden', [DeHelpNotfoundComponent], 'de-help-notfound'),
    'de-documents-list': new Topic('Dokumente', [DeDocumentComponent, DeActionsAddComponent, DeActionsOrderComponent, DeActionsComponent, DeActionsOrderComponent, DeActionsDownloadComponent, DeActionsLinkComponent, DeActionsUnlinkComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-documents-list'),
    'de-documents-create': new Topic('Dokument erstellen', [DeDocumentCreateComponent], 'de-documents-create'),
    'de-documents-edit': new Topic('Dokument bearbeiten', [DeDocumentCreateComponent], 'de-documents-edit'),
    'de-category-list': new Topic('Kategorien', [DeCatComponent, DeActionsAddComponent, DeActionsOrderComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-category-list'),
    'de-category-create': new Topic('Kategorie erstellen', [DeCatCreateComponent], 'de-category-create'),
    'de-category-edit': new Topic('Kategorie bearbeiten', [DeCatEditComponent], 'de-category-edit'),
    'de-category-link': new Topic('Dokumente in Kategorie ablegen', [DeCatLinkComponent], 'de-category-link'),
    'de-user-list': new Topic('Benutzer', [DeUserComponent, DeActionsAddComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeactivateComponent, DeActionsDeleteComponent, DeListComponent], 'de-user-list'),
    'de-user-create': new Topic('Benutzer erstellen', [DeUserCreateComponent], 'de-user-create'),
    'de-user-edit': new Topic('Benutzer bearbeiten', [DeUserCreateComponent], 'de-user-edit'),
    'de-groups-list': new Topic('Gruppen', [DeGroupsComponent, DeActionsAddComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-groups-list'),
    'de-groups-create': new Topic('Gruppe erstellen', [DeGroupsCreateComponent], 'de-groups-create'),
    'de-groups-edit': new Topic('Gruppe bearbeiten', [DeGroupsCreateComponent], 'de-groups-create'),
    'de-notifications-push-list': new Topic('Push-Nachrichten', [DePushComponent, DeActionsAddComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-notifications-push-list', ['pushActive']),
    'de-notifications-push-create': new Topic('Push-Nachricht erstellen', [DePushCreateComponent], 'de-notifications-push-create', ['pushActive']),
    'de-language-list': new Topic('Sprache', [DeLanguageComponent, DeActionsAddComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDownloadComponent, DeActionsUploadComponent, DeListComponent], 'de-language-list'),
    'de-language-create': new Topic('Sprache erstellen', [DeLanguageCreateComponent], 'de-language-create'),
    'de-language-edit': new Topic('Sprache bearbeiten', [DeLanguageCreateComponent], 'de-language-create'),
    'de-branding': new Topic('Branding', [DeBrandingComponent], 'de-branding', ['brandingActive']),
    'de-crm-customer-list': new Topic('Kunden', [DeCustomerComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-customer-list', ['crmActive']),
    'de-crm-customer-create': new Topic('Anlage eines Kunden', [DeCustomerCreateComponent], 'de-crm-customer-create', ['crmActive']),
    'de-crm-customer-edit': new Topic('Bearbeitung des Kunden', [DeCustomerEditComponent, DeCustomerCreateComponent], 'de-crm-customer-edit', ['crmActive']),
    'de-crm-contact-list': new Topic('Ansprechpartner', [DeContactComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-contact-list', ['crmActive']),
    'de-crm-contact-create': new Topic('Anlage eines Ansprechpartners', [DeContactCreateComponent], 'de-cr,-contact-create', ['crmActive']),
    'de-crm-contact-edit': new Topic('Bearbeitung des Ansprechpartners', [DeContactEditComponent, DeContactCreateComponent], 'de-crm-contact-edit', ['crmActive']),
    'de-crm-call-list': new Topic('Kundenkontakte', [DeCallComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-call-list', ['crmActive']),
    'de-crm-call-create': new Topic('Erstellung von Einsätzen', [DeCallCreateComponent], 'de-crm-call-create', ['crmActive']),
    'de-crm-call-edit': new Topic('Bearbeitung eines Einsatzes', [DeCallEditComponent, DeCallCreateComponent], 'de-crm-call-edit', ['crmActive']),
    'de-crm-product-list': new Topic('Produkte', [DeProductComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-product-list', ['crmActive']),
    'de-crm-product-create': new Topic('Anlage eines Produktes', [DeProductCreateComponent], 'de-crm-product-create', ['crmActive']),
    'de-crm-product-edit': new Topic('Bearbeitung eines Produktes', [DeProductEditComponent, DeProductCreateComponent], 'de-crm-product-edit', ['crmActive']),
    'de-crm-territory-overview': new Topic('Gebiete', [DeTerritoryComponent, DeListComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent], 'de-crm-territory-overview', ['crmActive']),
    'de-crm-territory-create': new Topic('Anlage von Gebieten', [DeTerritoryCreateComponent], 'de-crm-territory-create', ['crmActive']),
    'de-crm-territory-edit': new Topic('Gebiet Bearbeiten', [DeTerritoryEditComponent, DeTerritoryCreateComponent], 'de-crm-territory-edit', ['crmActive']),
    'de-crm-conflict-list': new Topic('Konflikte', [DeConflictComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-conflict-list', ['crmActive']),
    'de-crm-field-list': new Topic('Felder', [DeFieldComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-field-list', ['crmActive']),
    'de-crm-field-create': new Topic('Anlage von Feldern', [DeFieldCreateComponent], 'de-crm-field-create', ['crmActive']),
    'de-crm-view-list': new Topic('Ansichten', [DeViewComponent, DeActionsComponent, DeActionsEditComponent, DeActionsDeleteComponent, DeListComponent], 'de-crm-view-list', ['crmActive']),
    'de-crm-view-create': new Topic('Anlage einer neuen Ansicht', [DeViewCreateComponent], 'de-crm-view-create', ['crmActive']),
    'de-crm-view-edit': new Topic('Anpassung einer Ansicht', [DeViewEditComponent], 'de-crm-view-edit', ['crmActive']),
  }
  currentTopic: Topic;
  relatedTopics: Topic[] = [];
  mode: "modal" | "sidebar" | "off" = "off";
  // Die gespeicherten Komponenten werden im HTML gerendert
  components = [];

  constructor(public userHelp: UserHelpService,
              public localization: LocalizationService,
              private router: Router,
              private el: ElementRef,
              private settingsService: SettingService              
                ) {
    this.userHelp.helpWanted.subscribe( (val) => {
      if(val.display === "off" && this.mode === "sidebar") {
        // Delay für die Transition der Sidebar, ohne collabsed diese einfach
        setTimeout(() => {
          this.mode = val.display;
          this.openTopic();
        }, 250);
      }
      else{
        this.mode = val.display;
        this.openTopic();
      }
      // Fährt die Sidebar aus oder zeigt das Modal
      document.getElementById('help-bar').style.maxWidth = val.display === "sidebar"? '35%' : '0px';
      document.getElementById('doc-modal').style.display = val.display === "modal" ? 'block' : 'none';
    })
    // Aktualisiert die angezeigte Hilfe wenn sich die Route ändert
    router.events.subscribe( (ev) => {
      if (this.mode == "sidebar" && ev instanceof NavigationEnd) {
        this.openTopic();
      }
    })
  }

  ngOnInit(): void {
  }

  /**
   * Läd alle nötigen Komponenten für das aktuelle Topic in die Variable components.
   * @returns void
   */
  async openTopic() {
    // Prüft ob die aktuelle Komponenete in den Topics vorhanden ist, ansonsten wird die Notfound-Hilfe angezeigt
    this.components = [];
    if (this.topics.hasOwnProperty(this.userHelp.getComponentName())){
      this.currentTopic = this.topics[this.userHelp.getComponentName()];
    }
    else {
      this.currentTopic = this.topics['de-help-notfound'];
    }
    for (const component of this.currentTopic.components)
      this.components.push(component);
    this._genRelatedTopics();

    setTimeout(() => {
      const topElement = this.el.nativeElement.querySelector('.doc-sidebar');
      if (topElement) {
        topElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  /**
   * Vergleicht den Selector der aktuellen Hilfe mit den Selectoren aller Topics und generiert ein Array mit verwandten Themen.
   * Es wird nur nach gleichen Hauptthemen gesucht, nicht nach Unterthemen. 
   * de-documents-list und de-documents-edit sind verwandt, de-documents-list und de-category-list nicht.
   * @returns void
   */
  private _genRelatedTopics() {
    this.relatedTopics = [];
    if(this.currentTopic.selector === 'de-help-notfound') return;
    const componentNameArr = this.userHelp.getComponentName().split('-');
    for (let key of Object.keys(this.topics)) {
      const selectorArr = key.split('-');
      if (selectorArr[selectorArr.length - 2] === componentNameArr[selectorArr.length - 2] && selectorArr[selectorArr.length - 1] !== componentNameArr[selectorArr.length - 1]) {
        if (this.topics[key].neededModules){
          if (this.topics[key].neededModules.every(cur => this.settingsService.settings.find(setting => setting.selector === cur).value === 'true'))
          this.relatedTopics.push(this.topics[key]);
        }
        else
          this.relatedTopics.push(this.topics[key]);
      }
    }
  }
}
