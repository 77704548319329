export const ThemeMapping = [
  {
    selector: 'general',
    name: 'Allgemein',
    description: 'Grundsätzliche Farbgebung',
    settings: [
      { selector: '--amsel-background-image', description: 'Hintergrundbild', type: 'image', preview: [0, 1, 3] },
      { selector: '--amsel-background-color', description: 'Hintergrundfarbe', type: 'color', preview: [0, 1, 3] },
      { selector: '--amsel-logo-background-image', description: 'Logo Login & Homescreen', type: 'image', preview: [0, 3, 4] },
      { selector: '--amsel-blur-box-bg-color', description: 'Boxen Hintergrund', type: 'blur', preview: [0, 1] },
      { selector: '--amsel-blur-box-inactive-bg-color', description: 'Inaktive Boxen Hintergrund', type: 'blur', preview: [1] },
      { selector: '--amsel-document-item-font-color', description: 'Boxen Schriftfarbe', type: 'color', preview: [0, 1] },
      { selector: '--clr-icon-fill', description: 'Boxen Iconfarbe', type: 'color', preview: [0, 1] },
      { selector: '--amsel-sub-icon-fill', description: 'Unterkategorien Iconfarbe', type: 'color', preview: [0] }

    ]
  },
  {
    selector: 'header',
    name: 'Header',
    description: 'Leiste am oberen Bildschirmrand',
    settings: [
      { selector: '--clr-header-6-bg-color', description: 'Header Hintergrund', type: 'color', preview: [0, 1, 5] },
      { selector: '--clr-header-font-color', description: 'Header Schriftfarbe', type: 'color', preview: [0, 1, 5] },
      { selector: '--clr-header-hover-font-color', description: 'Header Elemente (Hover)', type: 'color', preview: [0, 1, 5] },
      { selector: '--amsel-header-logo-bg', description: 'Header Logo', type: 'image', preview: [0, 1, 5] },
    ]
  },
  {
    selector: 'sidenav',
    name: 'Seitenmenü',
    description: 'Navigation links',
    settings: [
      { selector: '--clr-vertical-nav-bg-color', description: 'Hintergrund', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-h3-color', description: 'Schriftfarbe', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-vertical-nav-secondary-color', description: 'secondary Color', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-vertical-nav-active-bg-color', description: 'Aktive Auswahl Hintergrund', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-vertical-nav-hover-bg-color', description: 'Hover Auswahl', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-vertical-nav-trigger-divider-border-color', description: 'Trennerfarbe', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-vertical-nav-icon-active-color', description: 'Aktive Iconfarbe', type: 'color', preview: [0, 1,5] },
      { selector: '--clr-vertical-nav-icon-fill', description: 'Iconfarbe', type: 'color', preview: [0, 1,5] },
    ]
  },
  {
    selector: 'loginAndForms',
    name: 'Login & Formulare',
    description: 'Eingabemasken',
    settings: [
      { selector: '--clr-login-background-color', description: 'Hintergrund', type: 'color', preview: [3, 4] },
      { selector: '--clr-p1-color', description: 'Titel Schriftfarbe', type: 'color', preview: [3, 4] },
      { selector: '--clr-forms-text-color', description: 'Eingabe Schriftfarbe', type: 'color', preview: [4, 3] },
      { selector: '--clr-forms-focused-color', description: 'Eingabe Farbe aktiv', type: 'color', preview: [4] },
      { selector: '--clr-forms-border-color', description: 'Label- & Kantenfarbe', type: 'color', preview: [4, 3] },
      { selector: '--amsel-login-icon-fill', description: 'Login Iconfarbe', type: 'color', preview: [3] },
    ]
  },
  /* {
      name: 'Tabellen',
      description: '',
      settings: [
          { selector:  '--clr-table-bgcolor', description: 'Tabellen Hintergrund', type: 'color', preview: 'main' },
          { selector:  '--clr-thead-bgcolor', description: 'Tabellenkopf Hintergrund', type: 'color', preview: 'main' },
          { selector:  '--clr-table-font-color', description: 'Tabellen Schriftfarbe', type: 'color', preview: 'main' },
          { selector:  '--clr-thead-color', description: 'Tabellenkopf Schriftfarbe', type: 'color', preview: 'main' },
          { selector:  '--clr-table-border-color', description: 'Tabellen Kantenfarbe', type: 'color', preview: 'main' },
      ]
  }, */

  {
    selector: 'popups',
    name: 'Popups',
    description: 'Benachrichtigungen',
    settings: [
      { selector: '--clr-modal-bg-color', description: 'Popup Hintergrund', type: 'color', preview: [2] },
      { selector: '--clr-modal-title-color', description: 'Popup Titel Schriftfarbe', type: 'color', preview: [2] },
      { selector: '--clr-modal-font-color', description: 'Popup Schriftfarbe', type: 'color', preview: [2] },

    ]

  },
  {
    selector: 'buttons',
    name: 'Buttons',
    description: 'Schaltflächen',
    settings: [
      { selector: '--clr-btn-primary-color', description: 'Haupt Button Schriftfarbe', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-primary-bg-color', description: 'Haupt Button Hintergrund', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-primary-border-color', description: 'Haupt Button Kantenfarbe', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-primary-hover-bg-color', description: 'Haupt Button Hintergrund (Hover)', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-default-outline-color', description: 'Rahmen Button Schriftfarbe', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-default-outline-border-color', description: 'Rahmen Button Kantenfarbe', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-default-outline-hover-bg-color', description: 'Rahmen Button Hintergrund (Hover)', type: 'color', preview: [2, 4] },
      { selector: '--clr-btn-default-outline-hover-color', description: 'Rahmen Button Schriftfarbe (Hover)', type: 'color', preview: [2, 4] },
    ]
  },
];



