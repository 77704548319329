<div>
  <div class="card" *ngIf="!loading">
    <div class="card-header">
      {{ helper.getCardHeader(mode, 'group', group.name) }}
    </div>
    <div class="card-block">
      <div class="card-title collapse-card">
        <span>
          <clr-icon shape="note" class="clr-icon-heading"></clr-icon>
        <span>{{localization.dictionary.createEdit.baseInformation}}</span>
        <p class="card-text icon-offset">
          {{localization.dictionary.createEdit.baseInformationDescription.replace('${componentDative}', localization.dictionary.group.dative)}}
        </p>
        </span>
        <clr-icon shape="angle" class="arrow" [dir]="!collapse[0] ? 'down' : 'left'"
                (click)="collapse[0] = !collapse[0]"></clr-icon>
      </div>
      <div [hidden]="collapse[0]" class="card-text">
        <form clrForm (change)="dirty = true" class="icon-offset">
          <clr-input-container>
            <label>{{localization.dictionary.general.name + '*'}}</label>
            <input clrInput type="text" [(ngModel)]="group.name" name="name" required maxlength="100"/>
            <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
          </clr-input-container>
          <clr-textarea-container>
            <label>{{localization.dictionary.general.description + '*'}}</label>
            <textarea clrTextarea [(ngModel)]="group.description" name="description" required maxlength="250"></textarea>
            <clr-control-error *clrIfError="'required'">{{localization.dictionary.createEdit.requiredInput}}</clr-control-error>
          </clr-textarea-container>
        </form>
      </div>
    </div>
    <div class="card-block">
      <div class="card-title collapse-card">
        <span>
          <clr-icon shape="key" class="clr-icon-heading"></clr-icon>
        <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.role.name)}}</span>
        <p class="card-text icon-offset">
          {{localization.dictionary.groupCreateEdit.assignRolesDescription}}
        </p>
        </span>
        <clr-icon shape="angle" class="arrow" [dir]="!collapse[1] ? 'down' : 'left'"
                (click)="collapse[1] = !collapse[1]"></clr-icon>
      </div>
      <div [hidden]="collapse[1]" class="card-text icon-offset">
        <app-list-selector [excludeAdmin]="true" *ngIf="group.roles" (selectedChanged)="group.roles = $event"
          [preLoaded]="group.roles" [label]="localization.dictionary.role.name" [query]="'roles'"></app-list-selector>
      </div>
    </div>
    <div *ngIf="setting.featureActiveList['crmActive']" class="card-block">
      <div class="card-title collapse-card">
        <span>
          <clr-icon shape="map" class="clr-icon-heading"></clr-icon>
        <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.territory.name)}}</span>
        <p class="card-text icon-offset">
          {{localization.dictionary.groupCreateEdit.assignTerritoriesDescription}}
        </p>
        </span>
        <clr-icon shape="angle" class="arrow" [dir]="!collapse[2] ? 'down' : 'left'"
                (click)="collapse[2] = !collapse[2]"></clr-icon>
      </div>
      <div [hidden]="collapse[2]" class="card-text icon-offset">
        <app-list-selector [excludeAdmin]="true" (selectedChanged)="group.territories = $event"
          [preLoaded]="group.territories" [label]="localization.dictionary.territory.name" [query]="'crm/territory'">
        </app-list-selector>
      </div>

    </div>
    <div class="card-block">
      <div class="card-title collapse-card">
        <span>
          <clr-icon shape="id-badge" class="clr-icon-heading"></clr-icon>
        <span>{{localization.dictionary.createEdit.assignComponent.replace('${componentName}', localization.dictionary.user.name)}}</span>
        <p class="card-text icon-offset">
          {{localization.dictionary.groupCreateEdit.assignUsersDescription}}
        </p>
        </span>
        <clr-icon shape="angle" class="arrow" [dir]="!collapse[3] ? 'down' : 'left'"
                (click)="collapse[3] = !collapse[3]"></clr-icon>
      </div>
      <div [hidden]="collapse[3]" class="card-text icon-offset">
        <app-list-selector (selectedChanged)="group.users = $event" [preLoaded]="group.users" [label]="localization.dictionary.user.name"
          [query]="'user/filter?sortBy=name'"></app-list-selector>
      </div>
    </div>
    <div stickyFooter class="card-footer">
      <ng-container *ngTemplateOutlet="actionBar"></ng-container>
    </div>
  </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>

<ng-template #actionBar>
  <button class="btn btn-primary" (click)="validateAndSave()">
    {{ ( mode=== 'create' ) ? localization.dictionary.button.create : localization.dictionary.button.save}}
  </button>
  <button class="btn btn-outline" (click)="server.back()">{{localization.dictionary.button.cancel}}</button>
</ng-template>