import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/localization/localization.service';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.css']
})
export class NoDataComponent implements OnInit {

  constructor(public localization: LocalizationService) { }

  ngOnInit(): void {
  }

}
