<button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
    {{ localization.dictionary.button.resetFilters }}
</button>
<clr-datagrid [class.conflict]="conflict" *ngIf="sectionFields && sectionFields.length > 0" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.contact.name) }}</clr-dg-placeholder>
    <clr-dg-column  *ngFor="let customField of sectionFields"
        [clrDgSortBy]="'fieldValues.' + customField.id"
        [(clrDgSortOrder)]="sorting[customField.name]" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden[customField.name]">
            {{customField.label}}
        </ng-template>
        <clr-dg-filter
            *ngIf="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX' || customField.type == 'CHOICE' || customField.type === 'SELECTION'"
            [clrDgFilter]="multiselectFilter" [(clrDgFilterOpen)]="multiselectFilter.open">
            <multiselect-filter #multiselectFilter [name]="customField.label" [searchBy]="'label'"
                [property]="'fieldValues.' + customField.id" [query]="'crm/field/byId/' + customField.id"
                [(filter)]="filters[customField.name]"
                [toggle]="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX'">
            </multiselect-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'NUMBER'" [clrDgFilter]="numberFilter"
            [(clrDgFilterOpen)]="numberFilter.open">
            <number-filter #numberFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [offset]="0"></number-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'BOOLEAN'" [clrDgFilter]="booleanFilter"
            [(clrDgFilterOpen)]="booleanFilter.open">
            <boolean-filter #booleanFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"></boolean-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TEXT' || customField.type == 'TEXTAREA'" [clrDgFilter]="stringFilter"
            [(clrDgFilterOpen)]="stringFilter.open">
            <string-filter #stringFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]"></string-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'DATE'" [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </date-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TIME'" [clrDgFilter]="timeFilter"
            [(clrDgFilterOpen)]="timeFilter.open">
            <time-filter #timeFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </time-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <!-- <clr-dg-column [clrDgSortBy]="'formOfAddress'" [(clrDgSortOrder)]="sorting['formOfAddress']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['formOfAddress']">
            Anrede
        </ng-template>
        <clr-dg-filter [clrDgFilter]="formFilter" [(clrDgFilterOpen)]="formFilter.open">
            <multiselect-filter #formFilter [property]="'formOfAddress'" [name]="'Anreden'" [array]="['Herr', 'Frau', 'Andere']"
                [(filter)]="filters['form']">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'fullName'" [(clrDgSortOrder)]="sorting['fullName']" (clrDgSortedChange)="this.selected = []">
        Name
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'fullName'" [(filter)]="filters['fullName']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column> -->
    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="current && positions" [clrDgSortBy]="'conCus.position'"
        [(clrDgSortOrder)]="sorting['position']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['position']">
            {{localization.dictionary.customerContact.position}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="positionFilter" [(clrDgFilterOpen)]="positionFilter.open">
            <string-filter #positionFilter [property]="'conCus.position'" [(filter)]="filters['positions']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let contact of contacts" [clrDgItem]="contact" clrDgSelectable="false" class="noedit">
        <clr-dg-cell *ngFor="let customField of sectionFields">
            <ng-container *ngFor="let fieldValue of contact[customField.name]; let i = index">
                <ng-container *ngIf="customField.type != 'TIME' && customField.type != 'DATE' && customField.type != 'BOOLEAN'">
                    {{(fieldValue.value || '') + (i < contact[customField.name].length - 1? ', ' : '' )}} </ng-container>
                        <ng-container *ngIf="customField.type == 'BOOLEAN'">
                            {{ fieldValue.value == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no }}
                        </ng-container>
                        <ng-container *ngIf="customField.type == 'TIME'">
                            {{ fieldValue.value | date: localization.dictionary.dateFormat.time : undefined : localization.language.selector }}
                        </ng-container>
                        <ng-container *ngIf="customField.type == 'DATE'">
                            {{ fieldValue.value | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
                        </ng-container>
                </ng-container>
        </clr-dg-cell>
        <!-- <clr-dg-cell>{{ contact.formOfAddress }}</clr-dg-cell>
        <clr-dg-cell>{{ contact.firstName + ' ' + contact.lastName }}</clr-dg-cell> -->
        <clr-dg-cell>
            {{ contact.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell>
            {{ contact.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="current && positions"> {{ positions[contact.id] }} </clr-dg-cell>

        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon
                [attr.shape]="current? 'minus' : 'plus'" (click)="selectedContact.emit(contact)"
                [pTooltip]="(current? localization.dictionary.customerContact.removeCustomerContact : localization.dictionary.customerContact.addCustomerContact).replace('${componentName}', localization.dictionary.contact.nameSingular).replace('${entryName}', crm.getDisplayValue(contact))"
                [tooltipPosition]="'left'">
            </clr-icon>
            <clr-icon *ngIf="current && positions"
                shape="pencil" (click)="editContact.emit({contact: contact, position: positions[contact.id]})"
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.contact.nameSingular).replace('${entryName}',crm.getDisplayValue(contact))"
                [tooltipPosition]="'left'">
            </clr-icon>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()" ></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.contact.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.contact.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.contact.nameSingular : localization.dictionary.contact.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>
