import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
    template: ''
  })
export abstract class Unsubscribable implements OnDestroy {
    subscriptions = new Subscription();

    constructor() {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
