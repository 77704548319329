import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { germanDateValidator } from './german-date.validator';

@Directive({
  selector: '[germanDateValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: GermanDateDirective, multi: true}]
})
export class GermanDateDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {    
    return germanDateValidator()(control);
  }

}
