<div class="card">
    <div class="card-header">amSel App – Benutzerhandbuch</div>
    <div class="card-block">
        <h4 class="card-title">Hilfethemen</h4>
        <div class="card-text">
            <clr-tree>
                
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="folder"></clr-icon>
                    Kategorien
                    <clr-tree-node>
                        <a (click)="openTopic('de-category-list')" class="clr-treenode-link">
                            Kategorien
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-category-create')" class="clr-treenode-link">
                            Kategorie erstellen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-category-link')" class="clr-treenode-link">
                            Dokumente einer Kategorie zuordnen
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
                
                <clr-tree-node [clrExpanded]="true" *ngIf="formActive">
                    <clr-icon shape="form"></clr-icon>
                    Formulare
                    <clr-tree-node>
                        <a (click)="openTopic('de-forms-list')" class="clr-treenode-link">
                            Formulare
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-forms-create')" class="clr-treenode-link">
                            Formulare erstellen
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
            
                <clr-tree-node [clrExpanded]="true" *ngIf="crmActive">
                    <clr-icon shape="data-cluster"></clr-icon>
                    CRM
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-customer-list')" class="clr-treenode-link">
                            Kunden
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-customer-create')" class="clr-treenode-link">
                            Kunden anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-contact-list')" class="clr-treenode-link">
                            Ansprechpartner
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-contact-create')" class="clr-treenode-link">
                            Ansprechpartner anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-call-list')" class="clr-treenode-link">
                            Kundenkontakte
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-call-create')" class="clr-treenode-link">
                            Kundenkontakte anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-product-list')" class="clr-treenode-link">
                            Produkte
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-product-create')" class="clr-treenode-link">
                            Produkte anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-territory-overview')" class="clr-treenode-link">
                            Gebiete
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-territory-create')" class="clr-treenode-link">
                            Gebiet anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-conflict-list')" class="clr-treenode-link">
                            Konflikte
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-field-list')" class="clr-treenode-link">
                            Felder
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-field-create')" class="clr-treenode-link">
                            Felder anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-view-list')" class="clr-treenode-link">
                            Ansichten
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-crm-view-create')" class="clr-treenode-link">
                            Ansicht anlegen
                        </a>
                    </clr-tree-node>
                </clr-tree-node>
            
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="employee-group"></clr-icon>
                    Benutzerverwaltung
                    <clr-tree-node>
                        <a (click)="openTopic('de-user-list')" class="clr-treenode-link">
                            Benutzer
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-user-create')" class="clr-treenode-link">
                            Benutzer anlegen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-groups-list')" class="clr-treenode-link">
                            Gruppen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-groups-create')" class="clr-treenode-link">
                            Gruppen anlegen
                        </a>
                    </clr-tree-node>
                </clr-tree-node>

                <clr-tree-node [clrExpanded]="true"  *ngIf="pushActive">
                    <clr-icon shape="bell"></clr-icon>
                    Benachrichtigungen
                    <clr-tree-node>
                        <a (click)="openTopic('de-notifications-push-list')" class="clr-treenode-link">
                            Push-Nachrichten
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-notifications-push-create')" class="clr-treenode-link">
                            Push-Nachrichten erstellen
                        </a>
                    </clr-tree-node>
                </clr-tree-node>

                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="cog"></clr-icon>
                    Einstellungen
                    <clr-tree-node>
                        <a (click)="openTopic('de-language-list')" class="clr-treenode-link">
                            Sprachen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-language-create')" class="clr-treenode-link">
                            Sprachen hinzufügen
                        </a>
                    </clr-tree-node>
                    <clr-tree-node>
                        <a (click)="openTopic('de-branding')" class="clr-treenode-link">
                            Branding
                        </a>
                    </clr-tree-node>
                </clr-tree-node>

            </clr-tree>
        </div>
    </div>
</div>