import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { fileNameValidator } from './file-name.validator';

@Directive({
  selector: '[fileNameValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: FileNameDirective, multi: true}]
})
export class FileNameDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {    
    return fileNameValidator()(control);
  }
}
