<h2>{{ localization.dictionary.conflict.name}}</h2>
<ng-container *ngTemplateOutlet="actionBar"></ng-container>
<clr-datagrid *ngIf="crm.fields.call" (clrDgRefresh)="refresh($event)" [(clrDgSelected)]="selected"
 [clrDgLoading]="loading" #datagridRef>
    <clr-dg-placeholder>{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.conflict.name) }}</clr-dg-placeholder>

    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'customer.fieldValues.' + crm.sortField.customer"
        [(clrDgSortOrder)]="sorting['customer']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['customer']">
            {{localization.dictionary.customer.nameSingular}}
        </ng-template>
        <!-- <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <multiselect-filter #customerFilter [property]="'customer.id'" [name]="localization.dictionary.customer.name"
                [customFields]="true" [query]="'crm/customer/filter'" [toggle]="false"
                [(filter)]="filters['customer']">
            </multiselect-filter>
        </clr-dg-filter> -->
        <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <combobox-filter #customerFilter [property]="'customer'" [name]="localization.dictionary.customer.name"
                [customFields]="true" [query]="'crm/customer/combobox/' + customerView.id" [type]="customerType" [toggle]="false"
                [(filter)]="filters['customer']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'contact.fieldValues.' + crm.sortField.contact"
        [(clrDgSortOrder)]="sorting['contact']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['contact']">
            {{localization.dictionary.contact.nameSingular}}
        </ng-template>

        <!-- <clr-dg-filter [clrDgFilter]="contactFilter" [(clrDgFilterOpen)]="contactFilter.open">
            <multiselect-filter #contactFilter [property]="'contact.id'" [name]="localization.dictionary.contact.name"
                [customFields]="true" [query]="'crm/contact/filter'"
                [(filter)]="filters['contact']" [toggle]="false">
            </multiselect-filter>
        </clr-dg-filter> -->
        <clr-dg-filter [clrDgFilter]="contactFilter" [(clrDgFilterOpen)]="contactFilter.open">
            <combobox-filter #contactFilter [property]="'contact'" [name]="localization.dictionary.contact.name"
                [customFields]="true" [query]="'crm/contact/combobox/' + contactView.id" [type]="contactType" [toggle]="false"
                [(filter)]="filters['contact']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'call.fieldValues.' + crm.sortField.call"
        [(clrDgSortOrder)]="sorting['call']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['call']">
            {{localization.dictionary.call.nameSingular}}
        </ng-template>
        <!-- <clr-dg-filter [clrDgFilter]="callFilter" [(clrDgFilterOpen)]="callFilter.open">
            <multiselect-filter #callFilter [property]="'call.id'" [name]="localization.dictionary.call.name"
                [customFields]="true" [query]="'crm/call/filter'" [toggle]="false"
                [(filter)]="filters['call']">
            </multiselect-filter>
        </clr-dg-filter> -->
        <clr-dg-filter [clrDgFilter]="callFilter" [(clrDgFilterOpen)]="callFilter.open">
            <combobox-filter #callFilter [property]="'call'" [name]="localization.dictionary.call.name"
                [customFields]="true" [query]="'crm/call/combobox/' + callView.id" [type]="callType" [toggle]="false"
                [(filter)]="filters['call']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column [clrDgSortBy]="'user.name'" [(clrDgSortOrder)]="sorting['user']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['user']">
            {{localization.dictionary.user.nameSingular}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="userFilter" [(clrDgFilterOpen)]="userFilter.open">
            <multiselect-filter #userFilter [name]="localization.dictionary.user.name" [property]="'user.id'" [(filter)]="filters['crm/user']"
                [query]="'user/filter'" [toggle]="false">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.created}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updated}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deleted}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let conflict of conflicts" [clrDgItem]="conflict" [clrDgSelectable]="!conflict.deletedAt"
        [class.deleted]="conflict.deletedAt">
        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [conflict.url]">{{ conflict.id }}
        </clr-dg-cell>

        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [conflict.url]">
            <span class="label label-light-blue" *ngIf="conflict.customer">
                <app-display-name [model]="conflict.customer" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>

        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [ conflict.url]">
            <span class="label label-light-blue" *ngIf="conflict.contact">
                <app-display-name [model]="conflict.contact" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>

        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [conflict.url]">
            <span class="label label-light-blue" *ngIf="conflict.call">
                <app-display-name [model]="conflict.call" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>

        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [conflict.url]">
            <span [pTooltip]="conflict.user?.firstName + ' ' + conflict.user?.lastName"
                tooltipPosition="top" class="label label-light-blue">
                {{ conflict.user?.name}}
            </span>
        </clr-dg-cell>


        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [conflict.url]">
            {{ conflict.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="conflict.deletedAt? null : [conflict.url]">
            {{ conflict.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted"
            [routerLink]="conflict.deletedAt? null : [conflict.url]">
            {{ conflict.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>


        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon *ngIf="!conflict.deletedAt" [pTooltip]="localization.dictionary.list.editComponent.replace('${componentName}', localization.dictionary.conflict.nameSingular)"
                tooltipPosition="left" shape="pencil" [routerLink]="conflict.deletedAt? null : [conflict.url]">
            </clr-icon>

            <clr-icon *ngIf="!conflict.deletedAt" [pTooltip]="localization.dictionary.conflict.deleteConflict"
                tooltipPosition="left" shape="trash" (click)="toDelete = [conflict]"></clr-icon>
        </clr-dg-cell>
    </clr-dg-row>


    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.conflict.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.user.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.user.nameSingular : localization.dictionary.user.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>


    


   <!--  <clr-modal [(clrModalOpen)]="restoreModal">
        <h3 class="modal-title">Besuche/Calls <app-display-name *ngIf="selectedCall" [model]="selectedCall"
                [displayFields]="helper.displayCallFields"></app-display-name> wiederherstellen</h3>
        <div class="modal-body" *ngIf="restoreModal">
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="restore()">Wiederherstellen</button>
            <button type="button" class="btn btn-outline" (click)="restoreModal = false;">Abbrechen</button>
        </div>
    </clr-modal>
 -->    
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
        <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
        <div class="modal-body">
            <p>{{localization.dictionary.conflict.deleteModalDescription}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="delete()">{{localization.dictionary.button.delete}}</button>
            <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{localization.dictionary.button.cancel}}</button>
        </div>
    </clr-modal>

    <ng-template #actionBar>
        <div fxLayout="row" fxLayoutAlign="space-between flex-end">
            <clr-dg-action-bar>
                
                <button class="btn btn-outline" *ngIf="state && (state.filters || state.sort)"
                    (click)="resetFilters()">{{localization.dictionary.button.resetFilters}}</button>
                <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                    (click)="toDelete = selected">{{localization.dictionary.button.delete}}</button>
            </clr-dg-action-bar>
            <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['crm-editor', 'admin'])">
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                        (ngModelChange)="toggleDeleted()" />
                    <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.conflict.name)}}</label>
                </clr-toggle-wrapper>
            </clr-toggle-container>
        </div>
    </ng-template>