<h2>{{localization.dictionary.contact.name}}</h2>

<ng-container *ngTemplateOutlet="actionBar"></ng-container>


<clr-datagrid *ngIf="sectionFields && sectionFields.length > 0" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading"
    [(clrDgSelected)]="selected" (clrDgSelectedChange)="selectionChange($event)" #datagridRef>
    <clr-dg-placeholder style="display: flex; justify-content:center!important; ">{{ localization.dictionary.list.noComponentsFound.replace('${componentName}', localization.dictionary.contact.name) }}</clr-dg-placeholder>
    <clr-dg-column [clrDgSortBy]="'id'" [(clrDgSortOrder)]="sorting['id']"
        (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['id']">
            Id
        </ng-template>
        <clr-dg-filter [clrDgFilter]="idFilter" [(clrDgFilterOpen)]="idFilter.open">
            <string-filter #idFilter [property]="'id'" [(filter)]="filters['id']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngFor="let customField of sectionFields"
        [clrDgSortBy]="'fieldValues.' + customField.id"
        [(clrDgSortOrder)]="sorting[customField.name]" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden[customField.name]">
            {{customField.label}}
        </ng-template>
        <clr-dg-filter
            *ngIf="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX' || customField.type == 'CHOICE' || customField.type === 'SELECTION'"
            [clrDgFilter]="multiselectFilter" [(clrDgFilterOpen)]="multiselectFilter.open">
            <multiselect-filter #multiselectFilter [name]="customField.label" [searchBy]="'label'"
                [property]="'fieldValues.' + customField.id" [query]="'crm/field/byId/' + customField.id"
                [(filter)]="filters[customField.name]"
                [toggle]="customField.type == 'MULTIPLE_CHOICE' || customField.type == 'CHECKBOX'">
            </multiselect-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'NUMBER'" [clrDgFilter]="numberFilter"
            [(clrDgFilterOpen)]="numberFilter.open">
            <number-filter #numberFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [offset]="0"></number-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'BOOLEAN'" [clrDgFilter]="booleanFilter"
            [(clrDgFilterOpen)]="booleanFilter.open">
            <boolean-filter #booleanFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]" [nameTrue]="localization.dictionary.general.yes" [nameFalse]="localization.dictionary.general.no"></boolean-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TEXT' || customField.type == 'TEXTAREA'" [clrDgFilter]="stringFilter"
            [(clrDgFilterOpen)]="stringFilter.open">
            <string-filter #stringFilter [property]="'fieldValues.' + customField.id"
                [(filter)]="filters[customField.name]"></string-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'DATE'" [clrDgFilter]="dateFilter"
            [(clrDgFilterOpen)]="dateFilter.open">
            <date-filter #dateFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </date-filter>
        </clr-dg-filter>

        <clr-dg-filter *ngIf="customField.type == 'TIME'" [clrDgFilter]="timeFilter"
            [(clrDgFilterOpen)]="timeFilter.open">
            <time-filter #timeFilter [property]="'fieldValues.' + customField.id"
                [(filterFrom)]="filters[customField.name + 'From']" [(filterTo)]="filters[customField.name + 'To']">
            </time-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <!-- <clr-dg-column [clrDgSortBy]="'formOfAddress'" [(clrDgSortOrder)]="sorting['formOfAddress']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['formOfAddress']">
            Anrede
        </ng-template>
        <clr-dg-filter [clrDgFilter]="formFilter" [(clrDgFilterOpen)]="formFilter.open">
            <multiselect-filter #formFilter [property]="'formOfAddress'" [name]="'Anreden'" [array]="['Herr', 'Frau', 'Andere']"
                [(filter)]="filters['form']">
            </multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'fullName'" [(clrDgSortOrder)]="sorting['fullName']" (clrDgSortedChange)="this.selected = []">
        Name
        <clr-dg-filter [clrDgFilter]="nameFilter" [(clrDgFilterOpen)]="nameFilter.open">
            <string-filter #nameFilter [property]="'fullName'" [(filter)]="filters['fullName']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column> -->
    <clr-dg-column [clrDgSortBy]="'customers.territory.name'"
        [(clrDgSortOrder)]="sorting['territory']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['territory']">
            {{localization.dictionary.territory.name}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="territoryFilter" [(clrDgFilterOpen)]="territoryFilter.open">
            <multiselect-filter #territoryFilter [property]="'customers.territory'" [name]="localization.dictionary.territory.name"
                [query]="'crm/territory'" [(filter)]="filters['territory']"></multiselect-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'customers.fieldValues.' + crm.sortField.customer"
        [(clrDgSortOrder)]="sorting['customers']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['customers']">
            {{localization.dictionary.customer.name}}
        </ng-template>
        <!-- <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <multiselect-filter #customerFilter [property]="'customers'" [name]="localization.dictionary.customer.name" [searchBy]="'id'"
                [customFields]="true" [query]="'crm/customer/filter'"
                [(filter)]="filters['customers']">
            </multiselect-filter>
        </clr-dg-filter> -->
        <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <combobox-filter #customerFilter [property]="'customers'" [name]="localization.dictionary.customer.name"
                [customFields]="true" [query]="'crm/customer/combobox/' + customerView.id" [type]="customerType"
                [(filter)]="filters['customer']">
            </combobox-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'conCus.position'"
        [(clrDgSortOrder)]="sorting['positions']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['positions']">
            {{localization.dictionary.customerContact.positions}}
        </ng-template>
        <!-- <clr-dg-filter [clrDgFilter]="customerFilter" [(clrDgFilterOpen)]="customerFilter.open">
            <multiselect-filter #customerFilter [property]="'customers'" [name]="localization.dictionary.customer.name" [searchBy]="'id'"
                [customFields]="true" [query]="'crm/customer/filter'"
                [(filter)]="filters['customers']">
            </multiselect-filter>
        </clr-dg-filter> -->
        <clr-dg-filter [clrDgFilter]="positionFilter" [(clrDgFilterOpen)]="positionFilter.open">
            <string-filter #positionFilter [property]="'conCus.position'" [(filter)]="filters['positions']"></string-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdAt'"
        [(clrDgSortOrder)]="sorting['createdAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdAt']">
            {{localization.dictionary.list.createdAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="createdFilter" [(clrDgFilterOpen)]="createdFilter.open">
            <date-filter #createdFilter [property]="'createdAt'" [(filterFrom)]="filters['createdAtFrom']"
                [(filterTo)]="filters['createdAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'updatedAt'"
        [(clrDgSortOrder)]="sorting['updatedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedAt']">
            {{localization.dictionary.list.updatedAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="updatedFilter" [(clrDgFilterOpen)]="updatedFilter.open">
            <date-filter #updatedFilter [property]="'updatedAt'" [(filterFrom)]="filters['updatedAtFrom']"
                [(filterTo)]="filters['updatedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedAt'"
        [(clrDgSortOrder)]="sorting['deletedAt']" (clrDgSortedChange)="this.selected = []">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedAt']">
            {{localization.dictionary.list.deletedAt}}
        </ng-template>
        <clr-dg-filter [clrDgFilter]="deletedFilter" [(clrDgFilterOpen)]="deletedFilter.open">
            <date-filter #deletedFilter [property]="'deletedAt'" [(filterFrom)]="filters['deletedAtFrom']"
                [(filterTo)]="filters['deletedAtTo']"></date-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'createdBy'"
    [(clrDgSortOrder)]="sorting['createdBy']" (clrDgSortedChange)="this.selected = []">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['createdBy']">
        {{localization.dictionary.list.createdBy}}
    </ng-template>
    <clr-dg-filter [clrDgFilter]="createdByFilter" [(clrDgFilterOpen)]="createdByFilter.open">
      <string-filter #createdByFilter [property]="'createdBy'"
      [(filter)]="filters['createdBy']"></string-filter>
    </clr-dg-filter>
</clr-dg-column>

<clr-dg-column [clrDgSortBy]="'updatedBy'"
[(clrDgSortOrder)]="sorting['updatedBy']" (clrDgSortedChange)="this.selected = []">
<ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['updatedBy']">
    {{localization.dictionary.list.updatedBy}}
</ng-template>
<clr-dg-filter [clrDgFilter]="updatedByFilter" [(clrDgFilterOpen)]="updatedByFilter.open">
  <string-filter #updatedByFilter [property]="'updatedBy'"
  [(filter)]="filters['updatedBy']"></string-filter>
</clr-dg-filter>
</clr-dg-column>

<clr-dg-column *ngIf="showDeleted" [clrDgSortBy]="'deletedBy'"
[(clrDgSortOrder)]="sorting['deletedBy']" (clrDgSortedChange)="this.selected = []">
<ng-template clrDgHideableColumn [(clrDgHidden)]="hidden['deletedBy']">
    {{localization.dictionary.list.deletedBy}}
</ng-template>
<clr-dg-filter [clrDgFilter]="deletedByFilter" [(clrDgFilterOpen)]="deletedByFilter.open">
  <string-filter #deletedByFilter [property]="'deletedBy'"
  [(filter)]="filters['deletedBy']"></string-filter>
</clr-dg-filter>
</clr-dg-column>
    <clr-dg-column>{{localization.dictionary.list.actions}}</clr-dg-column>

    <clr-dg-row *ngFor="let contact of contacts" [clrDgItem]="contact" [clrDgSelectable]="!contact.deletedAt" [class.deleted]="contact.deletedAt">
        <clr-dg-cell [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">{{ contact.id }}</clr-dg-cell>
        <clr-dg-cell *ngFor="let customField of sectionFields"
            [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
            <ng-container *ngFor="let fieldValue of contact[customField.name]; let i = index">
                <ng-container *ngIf="customField.type != 'TIME' && customField.type != 'DATE' && customField.type != 'BOOLEAN'">
                    <span
                        [class.deleted-option]="customField?.fieldOptions && customField.fieldOptions.length > 0 && !fieldValue.fieldOption">
                        {{(fieldValue.value || '') + (i < contact[customField.name].length - 1? ',' : '' )}} </span>
                </ng-container>
                <ng-container *ngIf="customField.type == 'BOOLEAN'">
                    {{ fieldValue.value == 'true' ? localization.dictionary.general.yes : localization.dictionary.general.no }}
                </ng-container>
                <ng-container *ngIf="customField.type == 'TIME'">
                    {{ fieldValue.value | date: localization.dictionary.dateFormat.time : undefined : localization.language.selector }}
                </ng-container>
                <ng-container *ngIf="customField.type == 'DATE'">
                    {{ fieldValue.value | date: localization.dictionary.dateFormat.date : undefined : localization.language.selector }}
                </ng-container>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="contact.deletedAt ? null : ['/crm/contact/edit/' + contact.id]">
            <span *ngFor="let territory of contact.territories"
                  [pTooltip]="territory?.description"
                  tooltipPosition="top"
                  class="label label-light-blue">
                {{ territory.name }}
            </span>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="contact.deletedAt ? null : ['/crm/contact/edit/' + contact.id]">
            <span *ngFor="let customer of contact.customers"
                  [pTooltip]="customer?.CustomerContact?.position"
                  tooltipPosition="top"
                  class="label label-light-blue">
                <app-display-name [model]="customer" [prePostFix]="''"></app-display-name>
            </span>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="contact.deletedAt ? null : ['/crm/contact/edit/' + contact.id]">
            <ng-container *ngFor="let customer of contact.customers">
                <span *ngIf="customer.CustomerContact.position"
                      pTooltip="{{ localization.dictionary.customerContact.ofCustomer + crm.getDisplayValue(customer) }}"
                      tooltipPosition="top"
                      class="label label-purple">
                    {{ customer.CustomerContact.position }}
                </span>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
            {{ contact.createdAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
            {{ contact.updatedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDeleted" [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
            {{ contact.deletedAt | date: localization.dictionary.dateFormat.full : undefined : localization.language.selector }}
        </clr-dg-cell>
        <clr-dg-cell [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
          {{ contact.createdBy }}
      </clr-dg-cell>
      <clr-dg-cell [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
        {{ contact.updatedBy }}
    </clr-dg-cell>
    <clr-dg-cell *ngIf="showDeleted"
    [routerLink]="contact.deletedAt? null : ['/crm/contact/edit/' + contact.id]">
      {{ contact.deletedBy }}
  </clr-dg-cell>

        <clr-dg-cell fxLayoutAlign="flex-start flex-start" fxLayoutGap="1rem">
            <clr-icon *ngIf="!contact.deletedAt"
                shape="pencil"
                [pTooltip]="localization.dictionary.list.editComponentEntry.replace('${componentName}', localization.dictionary.contact.nameSingular).replace('${entryName}',crm.getDisplayValue(contact))"
                tooltipPosition="left"
                [routerLink]="contact.deletedAt ? null : ['/crm/contact/edit/' + contact.id]">
            </clr-icon>

            <clr-icon *ngIf="!contact.deletedAt"
                shape="trash"
                (click)="toDelete = [contact]"
                [pTooltip]="localization.dictionary.list.deleteComponentEntry.replace('${componentName}', localization.dictionary.contact.nameSingular).replace('${entryName}', crm.getDisplayValue(contact))"
                tooltipPosition="left"
                >
            </clr-icon>

            <clr-icon *ngIf="contact.deletedAt"
                shape="backup-restore"
                (click)="openRestoreModal(contact)"
                [pTooltip]="localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.contact.nameSingular).replace('${entryName}', crm.getDisplayValue(contact))"
                tooltipPosition="left">
            </clr-icon>

            <clr-icon *ngIf="!contact.deletedAt"
                shape="file"
                (click)="openAuditLogModal(contact.id)"
                [pTooltip]="localization.dictionary.list.showAuditLogs"
                tooltipPosition="left">
            </clr-icon>
               
            <ng-template #noDisplay>{{contact.id}}</ng-template>

        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle (click)="hidChange()"></clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="20" [clrDgTotalItems]="total">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{localization.dictionary.list.componentsPerPage.replace('${componentName}', localization.dictionary.contact.name)}}</clr-dg-page-size>
            <span>
                {{localization.dictionary.list.componentsTotal
                    .replace('${first}', '' + (pagination.firstItem + 1))
                    .replace('${last}', '' + (pagination.lastItem + 1))
                    .replace('${total}', pagination.totalItems == 1? localization.dictionary.contact.one : '' + pagination.totalItems)
                    .replace('${componentName}', pagination.totalItems == 1 ? localization.dictionary.contact.nameSingular : localization.dictionary.contact.name)}}
            </span>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="toDelete">
    <h3 class="modal-title">{{localization.dictionary.list.deleteModalTitle}}</h3>
    <div class="modal-body">
        <p>{{ localization.dictionary.contactList.deleteModalDescription }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="delete()">{{ localization.dictionary.button.delete }}</button>
        <button type="button" class="btn btn-outline" (click)="toDelete = undefined">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="restoreModal">
    <h3 *ngIf="selectedContact" class="modal-title">{{ localization.dictionary.list.restoreComponentEntry.replace('${componentName}', localization.dictionary.contact.nameSingular).replace('${entryName}', crm.getDisplayValue(selectedContact)) }}
    </h3>
    <div class="modal-body" *ngIf="restoreModal">
        <!-- Wählen Sie die Gruppen und Rollen aus, mit denen der Benutzer wiederhergestellt werden soll:
        <app-list-selector (selectedChanged)="selectedContact.groups = $event" [preLoaded]="selectedContact?.groups" [label]="'Gruppen'" [query]="'group'"></app-list-selector>
        <app-list-selector (selectedChanged)="selectedContact.roles = $event" [preLoaded]="selectedContact?.roles" [label]="'Rollen'" [query]="'roles'"></app-list-selector> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="restore()">{{ localization.dictionary.button.restore }}</button>
        <button type="button" class="btn btn-outline" (click)="restoreModal = false;">{{ localization.dictionary.button.cancel }}</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="exportModal">
    <h3 class="modal-title">{{localization.dictionary.list.excelModalTitle}}</h3>
    <div class="modal-body">
        <p>{{localization.dictionary.list.excelModalDescription}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="downloadExcel();">{{localization.dictionary.button.excelWithFilter}}</button>
        <button type="button" class="btn btn-outline" (click)="downloadExcel('?skip=0&limit=0')">{{localization.dictionary.button.excelWithoutFilter}}</button>
    </div>
</clr-modal>

<ng-template #actionBar>
    <div fxLayout="row" fxLayoutAlign="space-between flex-end">
        <clr-dg-action-bar>
            <button class="btn btn-primary" routerLink="/crm/contact/create">{{ localization.dictionary.button.addComponent.replace('${componentName}', localization.dictionary.contact.nameSingular) }}</button>
            <button class="btn btn-primary" [disabled]="total === 0 || loading" (click)="checkExportType()">
                {{ localization.dictionary.button.excelExport }}
            </button>

            <button *ngIf="state && (state.filters || state.sort)" class="btn btn-outline" (click)="resetFilters()">
                {{ localization.dictionary.button.resetFilters }}
            </button>
            <button class="btn btn-outline" *ngIf="selected.length > 1"
                (click)="multiUpdate = true">{{localization.dictionary.button.updateMultiple}}</button>
            <button class="btn btn-danger-outline" *ngIf="selected.length > 0"
                (click)="toDelete = selected">{{ localization.dictionary.button.delete }}</button>
        </clr-dg-action-bar>
        <div fxLayout="row" fxLayoutAlign="flex-start flex-end">
           <clr-toggle-container class="clr-toggle-right" *ngIf="auth.hasRole(['crm-editor', 'admin'])">
                <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="deleted" [(ngModel)]="showDeleted"
                        (ngModelChange)="toggleDeleted()" />
                    <label>{{localization.dictionary.list.showDeletedComponent.replace('${componentName}', localization.dictionary.contact.name)}}</label>
                </clr-toggle-wrapper>
            </clr-toggle-container>
            <clr-dropdown>
                <button class="view dropdown-toggle" clrDropdownTrigger>
                    <clr-icon shape="eye" size="25" class="clr-icon-heading"></clr-icon>
                    <clr-icon shape="caret down" size="20"></clr-icon>
                </button>
                <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                    <label class="dropdown-header" aria-hidden="true">{{ localization.dictionary.view.name }}</label>
                    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                    <div *ngFor="let view of views" (click)="activeView = view; activeViewChange(view)"  clrDropdownItem>
                        <clr-icon [ngStyle]="{'visibility': (view.id === activeView.id) ? 'visible' : 'hidden' }"
                            style="margin-right: 0.3rem" shape="check"></clr-icon>
                        {{ view.name }}
                    </div>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>

    </div>
</ng-template>

<clr-modal [(clrModalOpen)]="auditLogModalOpen" clrModalSize="xl">
    <h3 class="modal-title">{{localization.dictionary.auditLogging.changeHistory}}</h3>
    <div class="modal-body">
        <app-audit [entityId]="entityId" [entityType]="entityType"></app-audit>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="auditLogModalOpen=false;">{{localization.dictionary.button.ok}}</button>
    </div>
</clr-modal>

<app-multi-update-crm type="contact" [(multiUpdateOpen)]="multiUpdate" (multiUpdateOpenChange)="multiModel = {}" (rowsChange)="refresh()" [(rows)]="selected" [model]="multiModel" [activeView]="activeView"></app-multi-update-crm>