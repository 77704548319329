import { Component, Input, OnInit } from '@angular/core';
import { WidgetInterface } from '../widget.interface';

@Component({
  selector: 'app-widget-count',
  templateUrl: './widget-count.component.html',
  styleUrls: ['./widget-count.component.css']
})
export class WidgetCountComponent implements OnInit, WidgetInterface {
  @Input() filtered: boolean;
  @Input() value = 0;
  @Input() text: string;
  @Input() endpoint: string;

  constructor() { }

  ngOnInit(): void {
  }

  refresh(from?: Date, to?: Date) {
    
  }

}
